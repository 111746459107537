import React from 'react'
import { leadTimeFormatter } from '#utils/leadTimeFormatter'
import NumericFormat from 'dpl/components/NumericFormat'
import { Stack, Typography } from 'dpl/core'
import { TRatesResponse } from '../../../types'
import { formatDropTrailer } from '../utils'

export interface ILaneInformationSubHeaderProps {
  laneInformation: Exclude<TRatesResponse, 'spotCostModel'>
}

export function LaneInformationSubHeader({ laneInformation }: ILaneInformationSubHeaderProps) {
  const {
    assignmentLeadTimeMinutes,
    creationLeadTimeMinutes,
    isTeam,
    organizationShipper,
    palletCount,
    stops,
    weight,
  } = laneInformation || {}

  const { name: organizationShipperName } = organizationShipper || {}

  const [originStop] = stops || []
  const destinationStop = stops[stops.length - 1]
  const { isDropTrailer: isPuDropTrailer } = originStop || {}
  const { isDropTrailer: isDelDropTrailer } = destinationStop || {}

  const formattedDropTrailer = formatDropTrailer(isPuDropTrailer, isDelDropTrailer)
  const formattedAssignmentLeadTime = leadTimeFormatter(assignmentLeadTimeMinutes ?? 0)
  const formattedCreationLeadTime = leadTimeFormatter(creationLeadTimeMinutes ?? 0)

  return (
    <Stack
      data-test='LaneInformationSubHeader'
      direction='row'
      alignItems='center'
      flexWrap='wrap'
      gap={0.5}
      divider={<span>·</span>}>
      {weight && (
        <Typography variant='subtitle2' color='textSecondary'>
          Weight: <NumericFormat value={weight} displayType='text' thousandSeparator=',' /> lbs
        </Typography>
      )}
      {organizationShipperName && (
        <Typography variant='subtitle2' color='textSecondary'>
          Customer: {organizationShipperName}
        </Typography>
      )}
      {formattedCreationLeadTime && (
        <Typography variant='subtitle2' color='textSecondary'>
          Creation Lead Time: {formattedCreationLeadTime}
        </Typography>
      )}
      {formattedAssignmentLeadTime && (
        <Typography variant='subtitle2' color='textSecondary'>
          Assignment Lead Time: {formattedAssignmentLeadTime}
        </Typography>
      )}
      {!!palletCount && (
        <Typography variant='subtitle2' color='textSecondary'>
          # of Pallets:{' '}
          <NumericFormat value={palletCount} displayType='text' thousandSeparator=',' />
        </Typography>
      )}
      {isTeam && (
        <Typography variant='subtitle2' color='textSecondary'>
          Team Required
        </Typography>
      )}
      {formattedDropTrailer && (
        <Typography variant='subtitle2' color='textSecondary'>
          Drop Trailer: {formattedDropTrailer}
        </Typography>
      )}
    </Stack>
  )
}
