import React, { ChangeEvent, memo, ReactNode } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { IRadioGroupProps, RadioGroup, IRadioGroupOption } from 'dpl/components/RadioGroup'

type IBaseControllerProps = ControllerProps<React.ComponentType>

type TIDProp = number | string | boolean | null

export interface IRadioOption {
  label: ReactNode
  id: TIDProp
  disabled?: boolean
}

export interface IControlledRadioGroupProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  RadioGroupProps: Omit<IRadioGroupProps, 'name' | 'onOptionChange'>
  onChange?: (newValue: IRadioGroupOption['value']) => void
}

const ControlledRadioGroup = memo<IControlledRadioGroupProps>(
  ({ RadioGroupProps, control, defaultValue = null, name, onChange: onChangeProp }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value: valueProp }) => {
          const changeHandler = (e: ChangeEvent<IRadioGroupOption>) => {
            if (onChangeProp) {
              onChangeProp(e.target.value)
            }
            onChange(e.target.value)
          }

          return (
            <RadioGroup
              name={name}
              {...RadioGroupProps}
              onOptionChange={changeHandler}
              checkedValue={valueProp}
            />
          )
        }}
      />
    )
  }
)

export default ControlledRadioGroup
