import React, { useMemo } from 'react'
import { IRuleTypeEnum } from '#types/rates'
import { IRadioGroupOption } from 'dpl/components/RadioGroup'
import { Typography, Box, Stack } from 'dpl/core'
import { useFlagsContext } from 'flags/src/FlagsContext'
import ControlledNumberField from 'forms/components/ControlledNumberField'
import ControlledRadioGroup from 'forms/components/ControlledRadioGroup'
import get from 'lodash/get'
import { RULE_TYPE_LABELS } from '../../../../../constants'
import { useAdjustmentRuleFormContext } from '../../../hooks'
import { TRateStrategySchema } from '../../../schema/RateStrategySchema'
import {
  MARGIN_ADDITIVE_PERCENTAGE_NAME,
  MARGIN_BASE_PERCENTAGE_NAME,
  MARGIN_RULE_TYPE_NAME,
} from '../constants'

const getMarginStrategyOptions = ({
  isDoNotBidRulesEnabled,
}: {
  isDoNotBidRulesEnabled: boolean
}): IRadioGroupOption[] => [
  {
    value: IRuleTypeEnum.base,
    label: RULE_TYPE_LABELS[IRuleTypeEnum.base],
    description: 'A multi-factor rule to use in place of the default premium percentage.',
    composed: true,
  },
  {
    value: IRuleTypeEnum.additive,
    label: RULE_TYPE_LABELS[IRuleTypeEnum.additive],
    description:
      'A one-factor rule used cumulatively with either the default premium percentage or a baseline rule.',
    composed: true,
  },
  ...(isDoNotBidRulesEnabled
    ? [
        {
          value: IRuleTypeEnum.doNotBid,
          label: RULE_TYPE_LABELS[IRuleTypeEnum.doNotBid],
          description:
            "Communicate to your team that you don't want anyone to bid on loads with these factors.",
          composed: true,
        },
      ]
    : []),
]

export interface IMarginStrategyInputsProps {
  marginRuleType: TRateStrategySchema['marginRuleType']
}

export function MarginStrategyInputs({ marginRuleType }: IMarginStrategyInputsProps) {
  const { isFlagEnabled } = useFlagsContext()
  const isDoNotBidRulesEnabled = isFlagEnabled('spot_rates_control_panel_rules_dnb')

  const marginStrategyOptions = useMemo(
    () => getMarginStrategyOptions({ isDoNotBidRulesEnabled }),
    [isDoNotBidRulesEnabled]
  )

  const { control, errors } = useAdjustmentRuleFormContext()
  const marginBasePercentageErrorMessage = get(errors, `${MARGIN_BASE_PERCENTAGE_NAME}.message`)
  const marginAddPercentageErrorMessage = get(errors, `${MARGIN_ADDITIVE_PERCENTAGE_NAME}.message`)

  return (
    <div>
      <Stack gap={3}>
        <Box>
          <ControlledRadioGroup
            name={MARGIN_RULE_TYPE_NAME}
            control={control}
            RadioGroupProps={{
              big: true,
              stacked: true,
              options: marginStrategyOptions,
              dataTest: 'MarginRuleType-RadioGroup',
            }}
          />
        </Box>
        {marginRuleType === IRuleTypeEnum.base && (
          <Stack gap={2}>
            <Typography variant='subtitle1'>Set your base rule percentage:</Typography>
            <Box maxWidth={150}>
              <ControlledNumberField
                name={MARGIN_BASE_PERCENTAGE_NAME}
                control={control}
                NumberFieldProps={{
                  label: '% Amount',
                  placeholder: '0%',
                  error: !!marginBasePercentageErrorMessage,
                  helperText: marginBasePercentageErrorMessage,
                  dataTest: 'MarginBase-PercentageInput',
                  NumericFormatProps: {
                    suffix: '%',
                    decimalScale: 0,
                  },
                }}
              />
            </Box>
          </Stack>
        )}
        {marginRuleType === IRuleTypeEnum.additive && (
          <Stack gap={2}>
            <Typography variant='subtitle1'>Set your additive rule percentage:</Typography>
            <Box maxWidth={150}>
              <ControlledNumberField
                name={MARGIN_ADDITIVE_PERCENTAGE_NAME}
                control={control}
                NumberFieldProps={{
                  label: '% Amount',
                  placeholder: '0%',
                  error: !!marginAddPercentageErrorMessage,
                  helperText: marginAddPercentageErrorMessage,
                  dataTest: 'MarginAdditive-PercentageInput',
                  NumericFormatProps: {
                    suffix: '%',
                    decimalScale: 0,
                  },
                }}
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </div>
  )
}
