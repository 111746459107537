import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { useAnalytics } from '#hooks'
import { usePermissionsContext } from 'auth/common/context'
import { SegmentedToggleButton } from 'dpl/components/SegmentedToggleButton'

const RATES_TOOL_OPTIONS = [
  { value: PROTECTED_PATHS.spotRatesTool, label: 'Spot' },
  { value: PROTECTED_PATHS.contractRatesTool, label: 'Contract' },
]

type TRatesToolPath =
  | typeof PROTECTED_PATHS.spotRatesTool
  | typeof PROTECTED_PATHS.contractRatesTool

interface RatesSegmentedToggleProps {
  currentTool: 'spotRatesTool' | 'contractRatesTool'
}

export function RatesSegmentedToggle({ currentTool }: RatesSegmentedToggleProps) {
  const { userPermissions } = usePermissionsContext()
  const hasBothRatesToolEnabled =
    userPermissions['rates_tool.read_app'] && userPermissions['contract_rates_tool.read_app']
  const navigate = useNavigate()
  const { trackEvent } = useAnalytics()

  if (!hasBothRatesToolEnabled) {
    return null
  }

  const toggleHandler = (_: any, value: TRatesToolPath) => {
    trackEvent('Contract Rates Tool', 'TOGGLE_SPOT_CONTRACT_SWITCH', {
      value: `navigate to ${value}`,
    })
    navigate(value)
  }

  return (
    <SegmentedToggleButton
      value={PROTECTED_PATHS[currentTool]}
      onChange={toggleHandler}
      options={RATES_TOOL_OPTIONS}
      dataTest='rates-segmented-toggle'
    />
  )
}
