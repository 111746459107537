import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from 'dpl/components/ButtonGroup'
import { ModalBase, IModalAction } from 'dpl/components/Modals'
import NumericFormat from 'dpl/components/NumericFormat'
import { Box, Typography, Divider, Button, FormHelperText, makeStyles, Collapse } from 'dpl/core'
import { useFlagsContext } from 'flags'
import ControlledCheckbox from 'forms/components/ControlledCheckbox'
import { ControlledSlider } from 'forms/components/ControlledSlider'
import ControlledSwitch from 'forms/components/ControlledSwitch'
import { AdvancedInputsSchema, advancedInputsSchema } from '../laneInformationFormSchema'
import { LeadTimeSection } from './LeadTimeSection'
import { getAdvancedInputsDefaultValues } from './utils'

interface AdvancedInputsProps {
  advancedInputs: AdvancedInputsSchema
  onClose: () => void
  isOpen: boolean
  onSubmit: (advancedInputs: AdvancedInputsSchema) => void
}

/**
 * Magic number to match spec 🪄✨
 */
const INPUT_MAX_WIDTH = '112px'

const useStyles = makeStyles(theme => ({
  switch: {
    transform: `translate(${theme.spacing(1.25)})`,
    '& label': {
      marginRight: 0,
    },
  },
}))

export function AdvancedInputsModal({
  advancedInputs,
  isOpen,
  onClose,
  onSubmit,
}: AdvancedInputsProps) {
  const classes = useStyles()
  const { isFlagEnabled } = useFlagsContext()
  const isUiImproveEnabled = isFlagEnabled('rates_ui_improvements')

  const { control, errors, handleSubmit, trigger, watch } = useForm<AdvancedInputsSchema>({
    resolver: yupResolver(advancedInputsSchema),
    defaultValues: getAdvancedInputsDefaultValues({ advancedInputs, isUiImproveEnabled }),
  })

  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'dismiss',
      action: onClose,
      ButtonProps: { variant: 'text' },
    },
    {
      label: 'Add Inputs',
      type: 'confirm',
      action: handleSubmit(fields => {
        const values = { ...fields }
        const { dropTrailerSwitch, palletSwitch } = fields || {}
        if (isUiImproveEnabled) {
          if (!dropTrailerSwitch) {
            values.dropTrailerDelivery = null
            values.dropTrailerPickup = null
          }
          if (!palletSwitch) {
            values.palletCount = null
          }
        }
        onSubmit(values)
        onClose()
      }),
    },
  ]

  const { assignmentLeadTime, creationLeadTime, dropTrailerSwitch, palletCount, palletSwitch } =
    watch([
      'creationLeadTime',
      'assignmentLeadTime',
      'dropTrailerSwitch',
      'palletCount',
      'palletSwitch',
    ])

  return (
    <ModalBase
      maxWidth='xs'
      fullWidth
      actions={actions}
      open={isOpen}
      title='Advanced Inputs'
      onClose={onClose}>
      <Typography variant='body2'>
        The more inputs you add, the more accurate your data will be.
      </Typography>
      <Box display='flex' flexDirection='column' gap={1.5} pt={3}>
        {isUiImproveEnabled ? (
          <LeadTimeSection control={control} errors={errors} trigger={trigger} />
        ) : (
          <>
            <Box display='flex' flexDirection='column' gap={1.5}>
              <Typography variant='subtitle1'>Creation Lead Time</Typography>
              <Box display='flex' alignItems='flex-start' justifyContent='flex-end' gap={1.5}>
                <Controller
                  control={control}
                  name='creationLeadTime.scale'
                  defaultValue={advancedInputs?.creationLeadTime?.scale ?? 'mins'}
                  render={({ onChange, value }) => (
                    <ButtonGroup
                      value={value}
                      onChange={(...args) => {
                        onChange(...args)
                        trigger('assignmentLeadTime')
                      }}
                      size='small'
                      dataTest='creationLeadTime.scale-buttonGroup'>
                      <Button value='days'>Days</Button>
                      <Button value='hours'>Hours</Button>
                      <Button value='mins'>Mins</Button>
                    </ButtonGroup>
                  )}
                />
                <Box maxWidth={INPUT_MAX_WIDTH}>
                  <Controller
                    control={control}
                    name='creationLeadTime.amount'
                    defaultValue={advancedInputs?.creationLeadTime?.amount ?? ''}
                    render={({ onChange, value }) => (
                      <NumericFormat
                        value={value}
                        label={`# of ${creationLeadTime?.scale}`}
                        type='tel'
                        decimalScale={0}
                        onValueChange={({ floatValue }) => {
                          onChange(floatValue ?? null)
                          trigger('assignmentLeadTime')
                        }}
                        InputLabelProps={{ shrink: true }}
                        dataTest='creationLeadTime.amount-input'
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box display='flex' flexDirection='column' gap={1.5}>
              <Typography variant='subtitle1'>Assignment Lead Time</Typography>
              <Box display='flex' alignItems='flex-start' justifyContent='flex-end' gap={1.5}>
                <Controller
                  control={control}
                  name='assignmentLeadTime.scale'
                  defaultValue={advancedInputs?.assignmentLeadTime?.scale ?? 'mins'}
                  render={({ onChange, value }) => (
                    <ButtonGroup
                      value={value}
                      onChange={(...args) => {
                        onChange(...args)
                        trigger('assignmentLeadTime')
                      }}
                      size='small'
                      dataTest='assignmentLeadTime.scale-buttonGroup'>
                      <Button value='days'>Days</Button>
                      <Button value='hours'>Hours</Button>
                      <Button value='mins'>Mins</Button>
                    </ButtonGroup>
                  )}
                />
                <Box maxWidth={INPUT_MAX_WIDTH}>
                  <Controller
                    control={control}
                    name='assignmentLeadTime.amount'
                    defaultValue={advancedInputs?.assignmentLeadTime?.amount ?? ''}
                    render={({ onChange, value }) => (
                      <NumericFormat
                        value={value}
                        label={`# of ${assignmentLeadTime?.scale}`}
                        type='tel'
                        decimalScale={0}
                        onValueChange={({ floatValue }) => {
                          onChange(floatValue ?? null)
                          trigger('assignmentLeadTime')
                        }}
                        InputLabelProps={{ shrink: true }}
                        dataTest='assignmentLeadTime.amount-input'
                        error={Boolean(errors.assignmentLeadTime)}
                        helperText={errors.assignmentLeadTime?.message}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
        <Divider />
        {isUiImproveEnabled ? (
          <Box display='flex' flexDirection='column' width='100%'>
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography variant='subtitle1'>Palletized</Typography>
              <Box className={classes.switch}>
                <ControlledSwitch
                  name='palletSwitch'
                  control={control}
                  SwitchProps={{
                    dataTest: 'pallet-switch',
                  }}
                />
              </Box>
            </Box>
            <Collapse in={Boolean(palletSwitch)} unmountOnExit>
              <Box mt={1.5} width='100%'>
                <ControlledSlider
                  control={control}
                  name='palletCount'
                  SliderProps={{
                    dataTest: 'pallets-slider',
                    min: 1,
                    max: 90,
                    valueLabelDisplay: 'auto',
                    label: `Pallet Count: (${palletCount})`,
                  }}
                />
              </Box>
            </Collapse>
          </Box>
        ) : (
          <Box display='flex' alignItems='center'>
            <Box flexGrow={1}>
              <Typography variant='subtitle1'>Palletized</Typography>
            </Box>
            <Box flexBasis={INPUT_MAX_WIDTH} flexShrink={0}>
              <Controller
                control={control}
                name='palletCount'
                defaultValue={advancedInputs.palletCount ?? ''}
                render={({ onChange, value }) => (
                  <NumericFormat
                    value={value}
                    placeholder='0'
                    label='# of Pallets'
                    thousandSeparator
                    decimalScale={0}
                    allowNegative={false}
                    type='tel'
                    onValueChange={({ floatValue }) => {
                      onChange(floatValue ?? null)
                    }}
                    error={Boolean(errors.palletCount)}
                    helperText={errors.palletCount?.message}
                    InputLabelProps={{ shrink: true }}
                    dataTest='pallets-input'
                  />
                )}
              />
            </Box>
          </Box>
        )}
        <Divider />
        <Box display='flex' alignItems='center'>
          <Box flexGrow={1}>
            <Typography variant='subtitle1'>Team Required</Typography>
          </Box>
          <Box
            flexBasis={INPUT_MAX_WIDTH}
            flexShrink={0}
            display='flex'
            justifyContent='flex-end'
            className={classes.switch}>
            <ControlledSwitch
              name='teamRequired'
              defaultValue={`${advancedInputs.teamRequired}` === 'true'}
              control={control}
              SwitchProps={{
                dataTest: 'teamRequired-switch',
              }}
            />
          </Box>
        </Box>
        <Divider />
        {!isUiImproveEnabled && (
          <>
            <Box display='flex' alignItems='center'>
              <Box flexGrow={1}>
                <Typography variant='subtitle1'>Drop Trailer: First Pickup</Typography>
              </Box>
              <Box
                flexBasis={INPUT_MAX_WIDTH}
                flexShrink={0}
                display='flex'
                justifyContent='flex-end'
                className={classes.switch}>
                <ControlledSwitch
                  name='dropTrailerPickup'
                  defaultValue={`${advancedInputs.dropTrailerPickup}` === 'true'}
                  control={control}
                />
              </Box>
            </Box>
            <Divider />
            <Box display='flex' alignItems='center'>
              <Box flexGrow={1}>
                <Typography variant='subtitle1'>Drop Trailer: Last Delivery</Typography>
              </Box>
              <Box
                flexBasis={INPUT_MAX_WIDTH}
                flexShrink={0}
                display='flex'
                justifyContent='flex-end'
                className={classes.switch}>
                <ControlledSwitch
                  name='dropTrailerDelivery'
                  defaultValue={`${advancedInputs.dropTrailerDelivery}` === 'true'}
                  control={control}
                />
              </Box>
            </Box>
            <Divider />
          </>
        )}

        {isUiImproveEnabled && (
          <Box>
            <Box display='flex' alignItems='center'>
              <Box flexGrow={1}>
                <Typography variant='subtitle1'>Drop Trailer</Typography>
              </Box>
              <Box
                flexBasis={INPUT_MAX_WIDTH}
                flexShrink={0}
                display='flex'
                justifyContent='flex-end'
                className={classes.switch}>
                <ControlledSwitch
                  name='dropTrailerSwitch'
                  defaultValue={`${advancedInputs.dropTrailerSwitch}` === 'true'}
                  control={control}
                  SwitchProps={{
                    dataTest: 'dropTrailerSwitch',
                  }}
                />
              </Box>
            </Box>
            {!!dropTrailerSwitch && (
              <>
                <Box display='flex' flexDirection='column' paddingLeft={2}>
                  <ControlledCheckbox
                    name='dropTrailerPickup'
                    control={control}
                    defaultValue={`${advancedInputs.dropTrailerPickup}` === 'true'}
                    CheckboxProps={{
                      label: 'First Pickup',
                      onChange: () => trigger('dropTrailerSwitch'),
                      dataTest: 'dropTrailerPickup-checkbox',
                    }}
                  />
                  <ControlledCheckbox
                    name='dropTrailerDelivery'
                    control={control}
                    defaultValue={`${advancedInputs.dropTrailerDelivery}` === 'true'}
                    CheckboxProps={{
                      label: 'Last Delivery',
                      onChange: () => trigger('dropTrailerSwitch'),
                      dataTest: 'dropTrailerDelivery-checkbox',
                    }}
                  />
                </Box>
                {errors?.dropTrailerSwitch?.message && (
                  <FormHelperText error>{errors?.dropTrailerSwitch?.message}</FormHelperText>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </ModalBase>
  )
}
