import {
  IAdjustmentRuleLineItem,
  IAdjustmentRuleLineItemKeyEnum,
  IAdjustmentRulesSectionStateEnum,
} from '../types'

interface IGetAdjustmentRuleSectionTitleArgs {
  numRules: number
  rulesState: IAdjustmentRulesSectionStateEnum
  limitRule?: IAdjustmentRuleLineItem
}

export const getAdjustmentRuleSectionTitle = ({
  limitRule,
  numRules,
  rulesState,
}: IGetAdjustmentRuleSectionTitleArgs) => {
  const numRulesLabel = `${limitRule ? numRules - 1 : numRules} Rule${numRules > 1 ? 's' : ''}`
  let rulesStateLabel =
    rulesState === IAdjustmentRulesSectionStateEnum.notApplied ? 'Not Applied' : 'Applied'
  if (rulesState === IAdjustmentRulesSectionStateEnum.overridden) rulesStateLabel = 'Overridden'

  const title = `${numRulesLabel} ${rulesStateLabel}`
  if (limitRule) {
    if (limitRule.key === IAdjustmentRuleLineItemKeyEnum.minPercentileLimit) {
      return `${title} (Capped at Min Percentile)`
    }
    if (limitRule.key === IAdjustmentRuleLineItemKeyEnum.maxPercentileLimit) {
      return `${title} (Capped at Max Percentile)`
    }
    return `${title} (Capped at Max Margin)`
  }
  return title
}
