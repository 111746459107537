import React, { PropsWithChildren, useCallback, useState } from 'react'
import { useDebounce } from 'react-use'
import { makeStyles } from '../../core/styles'
import Autocomplete, { IAutocompleteProps } from '../Autocomplete'

const DEBOUNCE_MS = 250

export interface IAutocompleteListProps<Value>
  extends Omit<
    IAutocompleteProps<Value>,
    'disableCloseOnSelect' | 'inputValue' | 'open' | 'onInputChange'
  > {
  /**
   * The function to call when the search term changes.
   * @param searchTerm The search term to filter the list by.
   * @returns {void}
   */
  onSearch?: (searchTerm: string) => void
}

const useAutocompleteListStyles = makeStyles(() => ({
  popupIndicator: {
    display: 'none',
  },
}))

export function Popper({ children }: PropsWithChildren<{}>) {
  return <div>{children}</div>
}

export function AutocompleteList<Value>({
  dataTest = 'AutocompleteList',
  onSearch,
  slotProps: slotPropsProp,
  ...props
}: IAutocompleteListProps<Value>) {
  const classes = useAutocompleteListStyles()

  const [searchTerm, setSearchTerm] = useState('')

  const changeHandler: IAutocompleteProps<Value>['onInputChange'] = useCallback(
    (_, value, reason) => {
      if (reason === 'reset') return
      setSearchTerm(value)
    },
    []
  )

  useDebounce(
    () => {
      onSearch?.(searchTerm)
    },
    DEBOUNCE_MS,
    [searchTerm]
  )

  return (
    <Autocomplete
      open
      dataTest={dataTest}
      disableCloseOnSelect
      inputValue={searchTerm}
      onInputChange={changeHandler}
      slotProps={{
        ...slotPropsProp,
        paper: { square: true, variant: 'elevation', elevation: 0 },
        popupIndicator: { className: classes.popupIndicator },
        popper: { component: Popper },
      }}
      {...props}
    />
  )
}
