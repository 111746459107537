import React from 'react'
import { DataGridAdvancedTextCell } from 'dpl'
import { Box, Typography, GridRenderCellParams } from 'dpl/core'
import { AdjustmentRuleCreatedBy } from './cells/AdjustmentRuleCreatedBy'
import { AdjustmentRuleFactor } from './cells/AdjustmentRuleFactor'
import { AdjustmentRuleOverflowMenu } from './cells/AdjustmentRuleOverflowMenu'
import { AdjustmentRuleStrategy } from './cells/AdjustmentRuleStrategy'
import { AdjustmentRuleTimeframe } from './cells/AdjustmentRuleTimeframe'
import { getFactorsCellValue } from './cells/utils/getFactorsCellValue/getFactorsCellValue'
import {
  IFactorCell,
  TAdjustmentRulesColumn,
  IAdjustmentRulesDataGrid,
  TAdjustmentRulesRow,
} from './types'

const REASON_COLUMN: TAdjustmentRulesColumn = {
  field: 'reason',
  headerName: 'Reason',
  flex: 1,
  minWidth: 150,
  renderCell: ({ row }) => {
    const { isRowDisabled = false, organizationAdjustmentRule, reason } = row || {}
    const { displayId } = organizationAdjustmentRule || {}
    return (
      <DataGridAdvancedTextCell topCaption={displayId}>
        <Typography variant='body2' color={isRowDisabled ? 'textSecondary' : 'textPrimary'}>
          {reason}
        </Typography>
      </DataGridAdvancedTextCell>
    )
  },
}

const ADJUSTMENT_STRATEGY_COLUMN: TAdjustmentRulesColumn = {
  field: 'adjustmentStrategy',
  headerName: 'Adjustment Strategy',
  minWidth: 150,
  renderCell: ({ row }) => <AdjustmentRuleStrategy {...row} />,
}

const FACTORS_COLUMN: TAdjustmentRulesColumn = {
  field: 'factors',
  headerName: 'Factors',
  flex: 1,
  minWidth: 150,
  maxWidth: 400,
  valueGetter: (_, { factors, organizationShipper }) =>
    getFactorsCellValue({ factors, organizationShipper }),
  renderCell: ({ row, value }: GridRenderCellParams<TAdjustmentRulesRow, IFactorCell[]>) => {
    const { isRowDisabled = false } = row || {}
    return (
      <Box display='flex' gap={1} flexWrap='wrap'>
        {value?.map(factor => (
          <AdjustmentRuleFactor key={factor.type} factor={factor} isRowDisabled={isRowDisabled} />
        ))}
      </Box>
    )
  },
}

const TIMEFRAME_COLUMN: TAdjustmentRulesColumn = {
  field: 'timeframe',
  headerName: 'Timeframe',
  flex: 1,
  minWidth: 150,
  renderCell: ({ row }) => <AdjustmentRuleTimeframe {...row} />,
}

const CREATED_BY_COLUMN: TAdjustmentRulesColumn = {
  field: 'createdByOrganizationUser',
  headerName: 'Created By',
  renderCell: ({ row }) => {
    return <AdjustmentRuleCreatedBy {...row} />
  },
}

const ACTION_COLUMN: TAdjustmentRulesColumn = {
  field: 'actions',
  headerName: '',
  type: 'custom',
  width: 50,
  renderCell: ({ row }) => <AdjustmentRuleOverflowMenu row={row} />,
}

export const ADJUSTMENT_RULES_DATA_GRID_COLUMNS: IAdjustmentRulesDataGrid['columns'] = [
  REASON_COLUMN,
  ADJUSTMENT_STRATEGY_COLUMN,
  FACTORS_COLUMN,
  TIMEFRAME_COLUMN,
  CREATED_BY_COLUMN,
]

export const ADJUSTMENT_RULES_DATA_GRID_COLUMNS_WITH_ACTIONS: IAdjustmentRulesDataGrid['columns'] =
  [...ADJUSTMENT_RULES_DATA_GRID_COLUMNS, ACTION_COLUMN]
