import React from 'react'
import { DatePickersProvider, QueryParamProvider } from 'dpl/providers'
import { ContractRFPDetailPage } from './ContractRFPDetailPage'

export function ContractRFPDetailPageContainer() {
  return (
    <QueryParamProvider>
      <DatePickersProvider>
        <ContractRFPDetailPage />
      </DatePickersProvider>
    </QueryParamProvider>
  )
}
