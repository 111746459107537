import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFieldArray, FormProvider, useWatch } from 'react-hook-form'
import { IQueryFetchContractRatesArgs } from '#types/graphqlTypes'
import { brandColors } from 'dpl'
import DraggableList, { DraggableListItem } from 'dpl/components/DraggableList'
import { Box, Button, Grid, makeStyles, Typography } from 'dpl/core'
import { IEquipment53Types, IVolumeTypes } from '../../types'
import { StopApptInputRow } from './components'
import { ContractAdvancedInputsToggle } from './components/ContractAdvancedInputs'
import { ContractDurationToggle } from './components/ContractDurationToggle'
import { ContractVolumeToggle } from './components/ContractVolumeToggle'
import { EquipmentToggle } from './components/EquipmentToggle'
import { DEFAULT_FLAT_VOLUME_PER_MONTH } from './constants'
import { useContractLaneInformationForm } from './hooks'
import { DEFAULT_CONTRACT_DURATION, IContractLaneInformationSchema, IStopSchema } from './schema'
import { mapSchemaToQuery } from './utils'
import { getUpdatedDynamicVolumePerMonth } from './utils/getUpdatedDynamicVolumePerMonth'

interface ContractLaneInformationFormProps {
  isLoading: boolean
  onSubmit: (data: IQueryFetchContractRatesArgs) => void
  /**
   * @default 'ContractLaneInformationForm'
   */
  dataTest?: string
}

const useContractLaneInformationFormStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('md')]: {
      border: 'none',
      boxShadow: 'none',
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(4),
      borderBottom: `1px solid ${brandColors.coolGray3}`,
      '& .MuiCardHeader-root, & .MuiCardContent-root': {
        padding: 0,
      },
    },
    marginBottom: theme.spacing(6),
  },
  stopAppt: {
    display: 'flex',
    gap: theme.spacing(2),
    flex: 1,
    flexDirection: 'column',
    '& > div': {
      flex: 1,
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  swapButton: {
    backgroundColor: brandColors.coolGray1,
    color: brandColors.coolGray5,
    ' & svg': {
      transform: 'rotate(90deg)',
    },
  },
  draggableStop: {
    // Targets drag icon
    '& > div:first-of-type': {
      [theme.breakpoints.down('md')]: {
        background: brandColors.coolGray1,
      },
    },
  },
}))

const FORM_DEFAULT_VALUES = {
  contractDuration: DEFAULT_CONTRACT_DURATION,
  volume: {
    volumeType: IVolumeTypes.flatVolume,
    flatVolumePerMonth: DEFAULT_FLAT_VOLUME_PER_MONTH,
    dynamicVolumePerMonth: [],
  },
  stops: [
    {
      location: null,
      type: 'pickup',
    },
    {
      location: null,
      type: 'delivery',
    },
  ],
  equipmentKey: IEquipment53Types.dryVan,
  temperature: {
    min: null,
    max: null,
  },
}

export function ContractLaneInformationForm({
  dataTest = 'ContractLaneInformationForm',
  isLoading,
  onSubmit,
}: ContractLaneInformationFormProps) {
  const classes = useContractLaneInformationFormStyles()

  const methods = useContractLaneInformationForm({
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldUnregister: false, // Need to maintain input state even when getting unmounted as stops are dragged and dropped
  })

  const { control, getValues, handleSubmit, setValue, trigger, watch } = methods || {}

  const {
    fields: stopFields,
    insert: insertStop,
    move: moveStops,
    remove: removeStop,
  } = useFieldArray<IStopSchema, 'rhfId'>({
    control,
    name: 'stops',
    keyName: 'rhfId',
  })
  const stopFieldsLength = stopFields?.length ?? 0

  const watchedStops = watch('stops', FORM_DEFAULT_VALUES.stops)
  const firstStopType = watchedStops?.[0]?.type
  const lastStopType = watchedStops?.[stopFieldsLength - 1]?.type

  const addStopHandler = useCallback(() => {
    const index = stopFieldsLength - 1
    insertStop(index, {
      location: null,
      type: 'pickup',
    })
  }, [insertStop, stopFieldsLength])

  const removeStopHandler = useCallback(
    index => {
      if (index === stopFieldsLength - 1) {
        // If removing the last stop, make sure the one before it is a delivery stop
        setValue(`stops[${index - 1}].type`, 'delivery')
      }
      removeStop(index)
    },
    [removeStop, setValue, stopFieldsLength]
  )

  const swapStopsHandler = useCallback(() => {
    const { stops } = getValues()
    const [{ location: origin }, { location: destination }] = stops || []
    setValue('stops[0].location', destination, { shouldValidate: true })
    setValue('stops[1].location', origin, { shouldValidate: true })
  }, [getValues, setValue])

  /**
   * This handles drag and drop updates
   */
  const moveStopRowHandler = useCallback(
    ({ sourceIndex, targetIndex }) => {
      moveStops(sourceIndex, targetIndex)
      trigger('stops')
    },
    [moveStops, trigger]
  )

  /**
   * Ensures first and last stop are always the correct type
   */
  useEffect(() => {
    const lastFieldIndex = stopFieldsLength - 1
    setValue('stops[0].type', 'pickup')
    setValue(`stops[${lastFieldIndex}].type`, 'delivery')
  }, [firstStopType, lastStopType, stopFieldsLength, setValue])

  const updateAdvancedInputs = (
    contractAdvancedInputs: IContractLaneInformationSchema['contractAdvancedInputs']
  ) => {
    setValue('contractAdvancedInputs', contractAdvancedInputs)
  }

  const generateHandler = useCallback(() => {
    handleSubmit(data => {
      onSubmit(mapSchemaToQuery({ formData: data }))
    })()
  }, [handleSubmit, onSubmit])

  const stopFieldsLabels = useMemo(() => {
    let pickupCount = 0
    let deliveryCount = 0
    const labels =
      watchedStops?.map(stop => {
        const { type } = stop || {}
        if (type === 'delivery') {
          deliveryCount += 1
          return `Delivery ${deliveryCount}`
        }
        pickupCount += 1
        return `Pickup ${pickupCount}`
      }) || []
    if (pickupCount === 1) labels[0] = 'Origin'
    if (deliveryCount === 1) labels[labels.length - 1] = 'Destination'
    return labels
  }, [watchedStops])

  const draggableListItems = useMemo(() => {
    return stopFields.map(field => ({ ...field, id: field.rhfId || '' }))
  }, [stopFields])

  // TODO: remove to separate component in dpl:
  interface IStopDragPreviewProps {
    location: IStopSchema['location'] | undefined
    label: string
  }

  function StopDragPreview({ label, location }: IStopDragPreviewProps) {
    const { city, postalCode, stateCode } = location || {}
    return (
      <Box paddingX={1} paddingY={0.5} borderRadius='2px' bgcolor={brandColors.white}>
        <Typography variant='button'>
          {label}
          {location && `: ${city}, ${stateCode}`}
          {postalCode && ` (${postalCode})`}
        </Typography>
      </Box>
    )
  }

  const { temperature } = useWatch<Pick<IContractLaneInformationSchema, 'temperature'>>({
    control,
    name: ['temperature'],
    defaultValue: FORM_DEFAULT_VALUES,
  })

  return (
    <FormProvider {...methods}>
      <Box data-test={dataTest} display='flex' flexDirection='column' gap={2} marginBottom={2}>
        <DraggableList onDrop={moveStopRowHandler} items={draggableListItems}>
          {draggableListItems.map((stop, index) => {
            const { id } = stop || {}
            const label = stopFieldsLabels[index]
            const currentStopValues = watchedStops?.[index]
            return (
              <DraggableListItem
                key={id}
                item={stop}
                index={index}
                className={classes.draggableStop}
                DraggableListItemPreview={
                  <StopDragPreview location={currentStopValues?.location} label={label} />
                }>
                <StopApptInputRow
                  stopField={stop}
                  index={index}
                  stopFieldsLength={stopFieldsLength}
                  onSwapStops={swapStopsHandler}
                  onAddStop={addStopHandler}
                  onRemoveStop={removeStopHandler}
                  stoplabel={label}
                />
              </DraggableListItem>
            )
          })}
        </DraggableList>
      </Box>
      <Grid container spacing={2} wrap='wrap' alignItems='center'>
        <Grid
          container
          item
          columnSpacing={2}
          rowSpacing={1}
          alignItems='flex-start'
          xs={12}
          sm={12}
          md>
          <Grid item xs='auto'>
            <Controller
              control={control}
              name='equipmentKey'
              render={({ onChange, value }) => (
                <EquipmentToggle
                  equipmentKey={value}
                  temperature={temperature}
                  onChange={({ equipmentKey, maxTemp, minTemp }) => {
                    setValue('temperature.min', minTemp)
                    setValue('temperature.max', maxTemp)
                    onChange(equipmentKey)
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs='auto'>
            <Controller
              control={control}
              name='contractDuration'
              render={({ onChange, value }) => {
                const changeHandler = (
                  contractDuration: IContractLaneInformationSchema['contractDuration']
                ) => {
                  const { volume } = getValues()
                  const { dynamicVolumePerMonth = [] } = volume || {}
                  const { contractEndDate, contractStartDate } = contractDuration

                  const updatedDynamicVolumePerMonth = getUpdatedDynamicVolumePerMonth({
                    contractEndDate,
                    contractStartDate,
                    dynamicVolumePerMonth,
                  })

                  setValue('volume.dynamicVolumePerMonth', updatedDynamicVolumePerMonth)
                  onChange(contractDuration)
                }
                return <ContractDurationToggle contractDuration={value} onChange={changeHandler} />
              }}
            />
          </Grid>
          <Grid item xs='auto'>
            <ContractVolumeToggle control={control} />
          </Grid>
          <Grid item xs='auto'>
            <ContractAdvancedInputsToggle
              control={control}
              updateAdvancedInputs={updateAdvancedInputs}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={2} minWidth={168}>
          <Button
            fullWidth
            onClick={generateHandler}
            data-test='generate-button'
            disabled={isLoading}>
            Generate Rate
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
