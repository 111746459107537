import _max from 'lodash/max'
import _min from 'lodash/min'
import { NORTH_AMERICA_BOUNDS } from '../constants'
import { IMapLane, IMapPosition, IMapRouteGroup, IMapStopProps } from '../types'

/**
 * Transfix HQ in New York
 */
const DEFAULT_INITIAL_POSITION = {
  lat: 40.75269,
  lon: -73.98962,
}

const MAX_LAT = NORTH_AMERICA_BOUNDS[0].lat
const MAX_LON = NORTH_AMERICA_BOUNDS[0].lon
const MIN_LAT = NORTH_AMERICA_BOUNDS[1].lat
const MIN_LON = NORTH_AMERICA_BOUNDS[1].lon

/**
 * Returns bounding box of all max/min coordinates in arrays
 */
export const getMapFitBounds = (
  mapLanes: IMapLane[] = [],
  mapPositions: IMapPosition[] = [{ coords: DEFAULT_INITIAL_POSITION }],
  mapStops: IMapStopProps[] = [],
  mapLines: IMapRouteGroup[] = []
): [[number, number], [number, number]] => {
  let allLat: (number | undefined)[] = []
  let allLon: (number | undefined)[] = []

  /**
   * Lanes
   */
  mapLanes.forEach(({ start, stop }) => {
    const { coords: coordsStart } = start || {}
    const { coords: coordsStop } = stop || {}

    const { lat: latStart, lon: lonStart } = coordsStart || {}
    const { lat: latStop, lon: lonStop } = coordsStop || {}

    allLon = [...allLon, lonStop, lonStart]
    allLat = [...allLat, latStart, latStop]
  })

  /**
   * Positions
   */
  mapPositions.forEach(({ coords }) => {
    const { lat, lon } = coords || {}
    allLon = [...allLon, lon]
    allLat = [...allLat, lat]
  })

  /**
   * Stops
   */
  mapStops.forEach(({ coords }) => {
    const { lat, lon } = coords || {}
    allLon = [...allLon, lon]
    allLat = [...allLat, lat]
  })

  /**
   * Routes
   */
  mapLines.forEach(({ route }) => {
    route.forEach(({ coords }) => {
      const { lat, lon } = coords || {}
      allLon = [...allLon, lon]
      allLat = [...allLat, lat]
    })
  })

  const maxLat = _max(allLat) ?? MAX_LAT
  const maxLon = _max(allLon) ?? MAX_LON
  const minLat = _min(allLat) ?? MIN_LAT
  const minLon = _min(allLon) ?? MIN_LON

  return [
    [minLon, minLat],
    [maxLon, maxLat],
  ]
}
