import React from 'react'
import { Navigate } from 'react-router-dom'
import { useHomeRouteHref } from '#hooks'
import { useAuthMember } from 'auth/b2b/hooks'
import { makeStyles } from 'dpl/core'
import { OnboardCard } from './components/OnboardCard'

const useOnboardStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(6),
    },
  },
}))

export function Onboard() {
  const classes = useOnboardStyles()
  const { member } = useAuthMember()
  const homeRouteHref = useHomeRouteHref()

  const { member_password_id: memberPasswordId } = member ?? {}

  // For now we are going to use the presence of a password to determine if a user has completed the onboarding process.
  if (memberPasswordId) {
    return <Navigate to={homeRouteHref} />
  }

  return (
    <div data-test='route-onboard' className={classes.root}>
      <OnboardCard />
    </div>
  )
}
