import React from 'react'
import { brandColors } from 'dpl'
import { TextField } from 'dpl/components'
import { FORMATS } from 'dpl/constants'
import { Box, Button, Grid, makeStyles, Typography } from 'dpl/core'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  monthButton: {
    width: '75%',
    padding: theme.spacing(1),
    borderRadius: 20,
    color: brandColors.coolGray8,
    '&:hover': {
      backgroundColor: brandColors.coolGray1,
    },
  },
  selectedMonth: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: brandColors.white,
  },
  rangeMonth: {
    backgroundColor: brandColors.skyBlue1,
    color: brandColors.skyBlue7,
    '&:hover': {
      backgroundColor: brandColors.coolGray1,
    },
  },
  startMonth: {
    color: brandColors.white,
  },
  endMonth: { color: brandColors.white },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

export interface IMonthRangePickerProps {
  startDate: string
  endDate: string
  onMonthClick: (date: string) => void
  months: string[]
  /**
   * @default 'MonthRangePicker'
   */
  dataTest?: string
}

export function MonthRangePicker({
  dataTest = 'MonthRangePicker',
  endDate,
  months,
  onMonthClick,
  startDate,
}: IMonthRangePickerProps) {
  const classes = useStyles()

  const formatMonthYear = (date: string) => moment(date).format(FORMATS.monthYearShort)

  const getDisplayValue = () => {
    if (startDate && endDate) {
      return `${formatMonthYear(startDate)} - ${formatMonthYear(endDate)}`
    }
    return startDate ? `${formatMonthYear(startDate)} -` : ''
  }

  const isInRange = (date: string) =>
    startDate &&
    endDate &&
    new Date(date) >= new Date(startDate) &&
    new Date(date) <= new Date(endDate)
  const isSelected = (date: string) => [startDate, endDate].some(d => d && date === d)

  const getMonthButtonClassName = (date: string) => {
    const classNames = [classes.monthButton]
    if (isSelected(date)) {
      classNames.push(classes.selectedMonth)
    }
    if (isInRange(date)) {
      classNames.push(classes.rangeMonth)
    }
    if (startDate === date) {
      classNames.push(classes.startMonth)
    }
    if (endDate === date) {
      classNames.push(classes.endMonth)
    }
    return classNames.join(' ')
  }

  const displayValue = getDisplayValue()

  return (
    <Box>
      <Typography variant='h4'>Select Custom Month Range</Typography>
      <Box maxWidth={300}>
        <TextField
          dataTest={`${dataTest}-input`}
          label='Months'
          value={displayValue}
          placeholder='Start Month – End Month'
          InputProps={{ readOnly: true }}
          sx={{ my: 3 }}
        />
      </Box>
      <Grid container spacing={2}>
        {months.map((date, index) => (
          <Grid item xs={12} sm={4} md={2.4} key={date}>
            <Button
              data-selected={isSelected(date)}
              data-range={isInRange(date)}
              data-test={`${dataTest}-button-${index}`}
              variant='text'
              onClick={() => onMonthClick(date)}
              className={getMonthButtonClassName(date)}>
              <Typography variant='subtitle1'>{formatMonthYear(date)}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
