import React, { useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { getLaneLiveAndDrop } from '#routes/contract-rfp/utils/utils'
import { IContractRfpVersionLaneRateRateTypes, IEquipmentKeys } from '#types/graphqlTypes'
import { brandColors, FormModal, INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import { makeStyles, Typography } from 'dpl/core'
import { LiveAndDropIcon, PriceIcon, ShipmentsIcon } from 'dpl/icons'
import { useFlagsContext } from 'flags'
import startCase from 'lodash/startCase'
import { calculateRate, isCustomRate } from '../EditLaneSideNav/components/Estimate/utils'
import { useEditLaneQuery } from '../EditLaneSideNav/graphql/EditLane'
import { EQUIPMENT_MAPPER } from '../LaneList/grid'
import { useDeleteLaneModal } from './useDeleteLaneModal'

export interface IDeleteModalProps {
  contractRfpVersionId: string
  externalId: string
  onClose: () => void
}

const useStyles = makeStyles(theme => ({
  laneInfo: {
    padding: theme.spacing(2),
    border: `1px solid ${brandColors.coolGray3}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  iconLabel: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  error: {
    margin: theme.spacing(2, 0),
  },
}))

export function DeleteLaneModal({ contractRfpVersionId, externalId, onClose }: IDeleteModalProps) {
  const classes = useStyles()
  const { isFlagEnabled } = useFlagsContext()
  const isDynamicVolumeEnabled = isFlagEnabled('rfp_dynamic_volume')
  const [apiError, setApiError] = useState('')
  const { data } = useEditLaneQuery({
    variables: {
      externalId,
      contractRfpVersionId,
    },
    fetchPolicy: 'network-only',
  })
  const { contractRfpVersionLanes } = data || {}
  const { nodes = [] } = contractRfpVersionLanes || {}
  const [lane] = nodes || []
  const { deleteRfpLane } = useDeleteLaneModal()

  const {
    calculatedMiles,
    customMiles,
    deliveryLocation,
    equipment,
    isDeliveryDropTrailer,
    isPickupDropTrailer,
    isVolumeDynamic,
    pickupLocation,
    rate,
    volumeFlatAmount,
    volumeFrequency,
    volumeTotalAmount,
  } = lane || {}
  const { carrierRate, fuelRatePerMile, marginAmount, marginPercentage, rateType, shipperRate } =
    rate || {}

  const { icon: EquipmentIcon, label: equipmentLabel } =
    EQUIPMENT_MAPPER[equipment?.key as IEquipmentKeys] || {}

  const isCustom = isCustomRate({
    shipperRate,
    fuelRate: fuelRatePerMile,
    marginAmount,
    rateType: rateType as IContractRfpVersionLaneRateRateTypes,
  })
  const { contractRate } = calculateRate({
    carrierRate,
    shipperRate,
    fuelRate: fuelRatePerMile,
    marginAmount,
    marginPercentage,
    miles: customMiles || calculatedMiles,
    rateType: rateType as IContractRfpVersionLaneRateRateTypes,
    strategy: isCustom ? 'custom' : 'calculated',
  })

  if (!lane) return null

  return (
    <FormModal
      open
      onClose={onClose}
      maxWidth='md'
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          ButtonProps: {
            variant: 'text',
          },
        },
        {
          label: 'Delete Lane',
          ButtonProps: {
            variant: 'contained',
            color: 'error',
          },
          async action() {
            const error = await deleteRfpLane(lane)

            if (error) {
              setApiError(error)
            } else {
              onClose()
            }
          },
        },
      ]}
      title={`Delete Lane #${externalId}`}>
      <div>
        {apiError && (
          <InlineMessage
            className={classes.error}
            type={INLINE_MESSAGE_VARIANTS.ALERT}
            message={apiError}
          />
        )}
        <Typography variant='body1'>
          Are you sure you want to permanently delete this lane? Once it is deleted, the lane will
          be removed from the current RFP version.
        </Typography>
        <div className={classes.laneInfo}>
          <Typography variant='subtitle1'>
            {pickupLocation?.city}, {pickupLocation?.stateCode} {pickupLocation?.postalCode} –{' '}
            {deliveryLocation?.city}, {deliveryLocation?.stateCode} {deliveryLocation?.postalCode}
          </Typography>
          <div className={classes.iconLabel}>
            <EquipmentIcon size='large' color='coolGray5' />
            <Typography variant='body2' color={brandColors.coolGray5}>
              {equipmentLabel}
            </Typography>
          </div>
          <div className={classes.iconLabel}>
            <LiveAndDropIcon size='large' color='coolGray5' />
            <Typography variant='body2' color={brandColors.coolGray5}>
              {getLaneLiveAndDrop(isPickupDropTrailer || false, isDeliveryDropTrailer || false)}
            </Typography>
          </div>
          <div className={classes.iconLabel}>
            <ShipmentsIcon size='large' color='coolGray5' />
            {isDynamicVolumeEnabled ? (
              <Typography variant='body2' color={brandColors.coolGray5}>
                {volumeTotalAmount ?? 0} Total (
                {isVolumeDynamic ? 'Dynamic' : volumeFlatAmount ?? 0}{' '}
                {volumeFrequency ? startCase(volumeFrequency) : ''})
              </Typography>
            ) : (
              <Typography variant='body2' color={brandColors.coolGray5}>
                {volumeFlatAmount} {volumeFrequency ? startCase(volumeFrequency) : ''}
              </Typography>
            )}
          </div>
          <div className={classes.iconLabel}>
            <PriceIcon size='large' color='coolGray5' />
            <Typography variant='body2' color={brandColors.coolGray5}>
              {contractRate
                ? numericFormatter(contractRate.toString(), {
                    thousandSeparator: ',',
                    decimalScale: 2,
                    fixedDecimalScale: true,
                    allowNegative: true,
                  })
                : '-'}
            </Typography>
          </div>
        </div>
      </div>
    </FormModal>
  )
}
