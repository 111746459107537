import { AutocompleteLocationSchema } from '#components/ControlledAutocompleteLocation/schema'
import { AutocompleteStateSchema } from '#components/ControlledAutocompleteState/schema'
import { IRegionsEnum } from '#routes/spot-rates-control-panel/types'
import * as yup from 'yup'
import { GEOGRAPHIC_TYPES } from '../../types'

export const LocationFactorSchema = yup.object({
  /**
   * Indicates if the location factor is being included in the adjustment rule
   * @example true
   * */
  includeFactor: yup.boolean(),
  /**
   * This value determines which list of location options the user should choose from.
   * This is required if `includeFactor` is true.
   * @example 'regional'
   */
  geographicType: yup
    .string<GEOGRAPHIC_TYPES>()
    .nullable()
    .when('includeFactor', {
      is: true,
      then: (schema: yup.Schema) => schema.required('You must select a geographic type.'),
    }),
  /**
   * Region value that user will select from a dropdown.
   * This is required when location factor is enabled and `geographicType` is `regional`.
   * @example 'southeast'
   */
  region: yup
    .string<IRegionsEnum>()
    .nullable()
    .when(['geographicType', 'includeFactor'], ([geographicType, includeFactor], schema) => {
      if (includeFactor && geographicType === GEOGRAPHIC_TYPES.regional) {
        return schema.required('You must select a region.')
      }
      return schema
    }),
  /**
   * State value that user will select from an autocomplete input.
   * This is required when location factor is enabled and `geographicType` is `state`.
   */
  state: AutocompleteStateSchema.nullable().when(
    ['geographicType', 'includeFactor'],
    ([geographicType, includeFactor], schema) => {
      if (includeFactor && geographicType === GEOGRAPHIC_TYPES.state) {
        return schema.required('You must select a state.')
      }
      return schema
    }
  ),
  /**
   * Array of 5 digit zip codes provided by the user via the autocomplete location field.
   * There must be at least one zip code provided if `geographicType` is `fiveDigitZipCodes`.
   * @example [{...}, {...}]
   * */
  fiveDigitZipCodes: yup
    .array()
    .of(AutocompleteLocationSchema)
    .when(['geographicType', 'includeFactor'], ([geographicType, includeFactor], schema) => {
      if (includeFactor && geographicType === GEOGRAPHIC_TYPES.fiveDigitZipCodes) {
        return schema.min(1, 'You must enter at least one ZIP code.')
      }
      return schema
    }),
})

export type TLocationFactorSchema = yup.InferType<typeof LocationFactorSchema>
