import * as yup from 'yup'

export const PillSelectSearchableOrganizationShipperSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
})

export type TPillSelectSearchableOrganizationShipperSchema = yup.InferType<
  typeof PillSelectSearchableOrganizationShipperSchema
>
