const TRANSFIX_ORG_NAME = 'Transfix'

interface IGetIsImaBotUserProps {
  name: string
  firstName: Nullable<string>
  lastName: Nullable<string>
}

export const getIsImaBotUser = ({ firstName, lastName, name }: IGetIsImaBotUserProps) => {
  return name === TRANSFIX_ORG_NAME && firstName === 'Ima' && lastName === 'BotToo'
}
