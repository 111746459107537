import React from 'react'
import { makeStyles } from 'dpl/core'
import { PageTemplate } from 'dpl/templates'

const useStyles = makeStyles(() => ({
  looker: {
    width: '100%',
    height: '700px',
    border: 'none',
  },
}))

export function ReportDetail() {
  const classes = useStyles()

  return (
    <PageTemplate pageTitle='Cost Model Training' PageBodyTitleProps={{ pageType: 'subPage' }}>
      <iframe
        className={classes.looker}
        title='looker'
        src='https://transfix.cloud.looker.com/embed/dashboards/TBD'
      />
    </PageTemplate>
  )
}
