import React from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  AutocompleteOrganizationShipper,
  AutocompleteOrganizationShipperProps,
} from '../AutocompleteOrganizationShipper'
import {
  EmbeddedCreateCustomerFormButton,
  IEmbeddedCreateCustomerFormButtonProps,
} from '../EmbeddedCreateCustomerForm'
import { OrganizationShipperSchema } from './organizationShipperSchema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<AutocompleteOrganizationShipperProps>,
  OrganizationShipperSchema
>

export interface ControlledAutocompleteOrganizationShipperProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteOrganizationShipperProps?: Omit<
    AutocompleteOrganizationShipperProps,
    'onChange' | 'value'
  >
  /**
   * If true, the embedded create customer form will be displayed.
   * @default false
   */
  enableCreate?: boolean
}

export function ControlledAutocompleteOrganizationShipper({
  AutocompleteOrganizationShipperProps,
  control,
  defaultValue,
  enableCreate = false,
  name,
}: ControlledAutocompleteOrganizationShipperProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        const changeHandler: AutocompleteOrganizationShipperProps['onChange'] = (_, newValue) => {
          onChange(newValue)
        }
        const createSuccessHandler: IEmbeddedCreateCustomerFormButtonProps['onSuccess'] =
          newShipper => {
            onChange(newShipper)
          }

        const clearOrganizationShipper = () => {
          onChange(null)
        }

        return (
          <div>
            <AutocompleteOrganizationShipper
              {...AutocompleteOrganizationShipperProps}
              onChange={changeHandler}
              value={value}
            />
            {enableCreate && (
              <EmbeddedCreateCustomerFormButton
                clearOrganizationShipper={clearOrganizationShipper}
                onSuccess={createSuccessHandler}
              />
            )}
          </div>
        )
      }}
    />
  )
}
