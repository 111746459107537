/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box, Grid, Typography } from 'dpl/core'
import { LaneArrowIcon } from 'dpl/icons'
import { DEFAULT_EMPTY_ACTIVITY_STRING } from '../components'

export interface IReorderedCarriersEventJson {
  carrier_order?: string[]
}
export const getReorderedCarriersActivityDetail = (rowEventJson: IReorderedCarriersEventJson) => {
  const { carrier_order: carrierOrder } = rowEventJson
  const [beforeCarrierOrder, afterCarrierOrder] = carrierOrder || [
    DEFAULT_EMPTY_ACTIVITY_STRING,
    DEFAULT_EMPTY_ACTIVITY_STRING,
  ]
  return (
    <Grid container padding={2}>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Carrier Order</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Box display='flex' alignItems='center'>
          <Box display='flex' flexDirection='column'>
            {beforeCarrierOrder.split(',').map((item, idx) => (
              <Typography key={`before${idx}-${item}`} variant='caption' color='textSecondary'>
                {item}
              </Typography>
            ))}
          </Box>
          <Typography variant='caption' color='textSecondary' ml={1} mr={1}>
            <LaneArrowIcon />
          </Typography>
          <Box display='flex' flexDirection='column'>
            {afterCarrierOrder.split(',').map((item, idx) => (
              <Typography key={`after${idx}-${item}`} variant='caption' color='textSecondary'>
                {item}
              </Typography>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
