import React from 'react'
import {
  GridActionsCellItem as MuiGridActionsCellItem,
  GridActionsColDef as MuiGridActionsColDef,
} from 'dpl/core'
import ArchiveIcon from 'dpl/icons/build/ArchiveIcon'
import EditIcon from 'dpl/icons/build/EditIcon'
import { IRgCarrierRateFragment } from 'routes/routing-guide/RoutingGuideLaneDetailsPage/graphql/RGCarrierRateFragment'
import { IRoutingGuideCarrierDataGridActionsColDef } from '../types'

/**
 * Click handlers for menu items
 */
interface IGetActionsUtilArgs {
  isRGCarrierRateEditEnabled: boolean
  onDeactivateClick: (carrierRate?: IRgCarrierRateFragment) => void
  onEditClick: (carrierRate?: IRgCarrierRateFragment) => void
}

const getActionsUtil = ({
  isRGCarrierRateEditEnabled,
  onDeactivateClick,
  onEditClick,
}: IGetActionsUtilArgs): IRoutingGuideCarrierDataGridActionsColDef['getActions'] =>
  function getActions({ id, row }) {
    const deactivateClickHandler = () => onDeactivateClick(row)
    const editClickHandler = () => onEditClick(row)
    return [
      ...(isRGCarrierRateEditEnabled
        ? [
            <MuiGridActionsCellItem
              key={`${id}-edit`}
              data-test='carrier-rate-edit-button'
              icon={<EditIcon size='large' color='coolGray8' />}
              label='Edit'
              onClick={editClickHandler}
              showInMenu
            />,
          ]
        : []),
      <MuiGridActionsCellItem
        key={`${id}-deactivate`}
        data-test='carrier-rate-deactivate-button'
        icon={<ArchiveIcon size='large' color='coolGray8' />}
        label='Deactivate'
        onClick={deactivateClickHandler}
        showInMenu
      />,
    ]
  }

export const getActionColumnDef = ({
  isRGCarrierRateEditEnabled,
  onDeactivateClick,
  onEditClick,
}: IGetActionsUtilArgs): MuiGridActionsColDef => {
  return {
    field: 'actions',
    type: 'actions',
    width: 50,
    getActions: getActionsUtil({ isRGCarrierRateEditEnabled, onDeactivateClick, onEditClick }),
  }
}
