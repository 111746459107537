import React from 'react'
import { ControlledCostPercentileSlider } from '#components/ControlledCostPercentileSlider'
import { IRuleTypeEnum } from '#types/rates'
import { IRadioGroupOption } from 'dpl/components/RadioGroup'
import { Typography, Box, Stack } from 'dpl/core'
import ControlledNumberField from 'forms/components/ControlledNumberField'
import ControlledRadioGroup from 'forms/components/ControlledRadioGroup'
import get from 'lodash/get'
import { RULE_TYPE_LABELS } from '../../../../../constants'
import { useAdjustmentRuleFormContext } from '../../../hooks'
import { TRateStrategySchema } from '../../../schema/RateStrategySchema'
import {
  COST_ADDITIVE_SHIFT_NAME,
  COST_BASE_PERCENTILE_NAME,
  COST_RULE_TYPE_NAME,
} from '../constants'

const COST_STRATEGIES_OPTIONS: IRadioGroupOption[] = [
  {
    value: IRuleTypeEnum.base,
    label: RULE_TYPE_LABELS[IRuleTypeEnum.base],
    description: 'A multi-factor rule to use in place of the default cost percentile.',
    composed: true,
  },
  {
    value: IRuleTypeEnum.additive,
    label: RULE_TYPE_LABELS[IRuleTypeEnum.additive],
    description:
      'A one-factor rule used to shift the distribution of either a default cost percentile or a baseline rule.',
    composed: true,
  },
]

interface ICostStrategyInputsProps {
  costBasePercentile: TRateStrategySchema['costBasePercentile']
  costRuleType: TRateStrategySchema['costRuleType']
}

export function CostStrategyInputs({ costBasePercentile, costRuleType }: ICostStrategyInputsProps) {
  const { control, errors } = useAdjustmentRuleFormContext()
  const costShiftAmountErrorMessage = get(errors, `${COST_ADDITIVE_SHIFT_NAME}.message`)

  return (
    <Stack gap={3}>
      <Box>
        <ControlledRadioGroup
          name={COST_RULE_TYPE_NAME}
          control={control}
          RadioGroupProps={{
            big: true,
            stacked: true,
            options: COST_STRATEGIES_OPTIONS,
            dataTest: 'CostRuleType-RadioGroup',
          }}
        />
      </Box>
      {costRuleType === IRuleTypeEnum.base && (
        <Stack gap={1}>
          <Typography variant='subtitle1'>Set your own percentile:</Typography>
          <ControlledCostPercentileSlider
            name={COST_BASE_PERCENTILE_NAME}
            control={control}
            SliderProps={{
              label: `Percentile (${costBasePercentile}th)`,
            }}
          />
        </Stack>
      )}
      {costRuleType === IRuleTypeEnum.additive && (
        <Stack gap={2}>
          <Typography variant='subtitle1'>
            How much would you like to shift the distribution?
          </Typography>
          <Box maxWidth={150}>
            <ControlledNumberField
              name={COST_ADDITIVE_SHIFT_NAME}
              control={control}
              NumberFieldProps={{
                label: 'Shift % Amount',
                placeholder: '0%',
                error: !!costShiftAmountErrorMessage,
                helperText: costShiftAmountErrorMessage,
                dataTest: 'CostAdditiveShift-PercentageInput',
                NumericFormatProps: {
                  suffix: '%',
                  decimalScale: 0,
                },
              }}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  )
}
