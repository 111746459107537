import moment from 'moment'

interface IContractDurationFormatter {
  contractEndDate?: string
  contractStartDate?: string
  contractLengthMonths?: number
}
export function contractDurationFormatter({
  contractEndDate = '',
  contractLengthMonths = 0,
  contractStartDate = '',
}: IContractDurationFormatter): string {
  if (!contractStartDate || !contractEndDate || !contractLengthMonths) {
    return '--'
  }
  const formattedStartDate = moment(contractStartDate).format('MMM ‘YY')
  const formattedEndDate = moment(contractEndDate).format('MMM ‘YY')

  if (contractStartDate === contractEndDate) {
    return `${contractLengthMonths} Month (${formattedStartDate})`
  }
  return `${contractLengthMonths} Months (${formattedStartDate} - ${formattedEndDate})`
}
