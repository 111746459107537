import React, { useMemo } from 'react'
import { Tooltip } from 'dpl'
import { Box, Typography } from 'dpl/core'
import { InfoIcon } from 'dpl/icons'
import { IRgLaneCarrierRatesFragment } from '../../../../graphql/RGLaneCarrierRatesFragment'

export interface IDataGridCarrierCellProps {
  routingGuideCarrierRates: IRgLaneCarrierRatesFragment[]
}

export function DataGridCarrierCell({ routingGuideCarrierRates }: IDataGridCarrierCellProps) {
  const carriers = routingGuideCarrierRates.map(
    ({ organizationCarrierRelationship }) => organizationCarrierRelationship.carrier
  )
  const totalCarriersText = useMemo(() => {
    if (carriers.length === 1) {
      return '1 Carrier'
    }
    return `${carriers.length} Carriers`
  }, [carriers.length])

  const tooltipText = useMemo(() => {
    if (carriers.length === 0) {
      return ''
    }
    return carriers.map(({ legalName = '' }) => legalName).join(', ')
  }, [carriers])

  return (
    <Tooltip title={tooltipText} placement='top'>
      <Box display='flex' alignItems='center'>
        <Typography paddingRight={0.5}>{totalCarriersText}</Typography>
        <InfoIcon size='medium' />
      </Box>
    </Tooltip>
  )
}
