import React, { useCallback, useMemo } from 'react'
import { useAnalytics } from '#hooks'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl/components/InlineMessage'
import SidePanel, { ISidePanelProps } from 'dpl/components/SidePanel'
import { Box } from 'dpl/core'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import {
  AdjustmentRuleRateStrategySection,
  AdjustmentRuleReasonSection,
  AdjustmentRuleTimeframeSection,
  CloseFormConfirmationModal,
} from '..'
import { SECTIONS_STEP_NUMBER } from '../../constants'
import { useAdjustmentRuleFormContext, useAdjustmentRuleFormStepsContext } from '../../hooks'
import { AdjustmentRuleFactorsSection } from '../AdjustmentRuleFactorsSection'
import { useAdjustmentRuleFormMutations } from './hooks/useAdjustmentRuleFormMutations'
import { mapAdjustmentRuleSchemaToInput } from './utils/mapAdjustmentRuleSchemaToInput'

export interface IAdjustmentRuleFormSidePanelBodyProps {
  onClose: () => void
  organizationAdjustmentRuleId: string | undefined
}

export function AdjustmentRuleFormSidePanelBody({
  onClose: closeSidePanel,
  organizationAdjustmentRuleId,
}: IAdjustmentRuleFormSidePanelBodyProps) {
  const isEdit = !!organizationAdjustmentRuleId
  const { trackEvent } = useAnalytics()

  const {
    formState: { isDirty: isFormDirty },
    getValues,
    handleSubmit,
  } = useAdjustmentRuleFormContext()
  const { activeStepIndex } = useAdjustmentRuleFormStepsContext()

  const { createAdjustmentRule, errorMessages, loading, updateAdjustmentRule } =
    useAdjustmentRuleFormMutations({ onSuccess: closeSidePanel })

  const onFormSubmit = useCallback(
    formData => {
      const input = mapAdjustmentRuleSchemaToInput(formData)
      if (!isEdit) {
        createAdjustmentRule({ input }, formData)
      } else {
        updateAdjustmentRule(
          {
            input: {
              ...input,
              organizationAdjustmentRuleId,
            },
          },
          formData
        )
      }
    },
    [createAdjustmentRule, isEdit, organizationAdjustmentRuleId, updateAdjustmentRule]
  )

  const onFormError = useCallback(
    formErrors => {
      const formData = getValues()
      trackEvent('Spot Rates Control Panel', 'ADJUSTMENT_RULE_FORM_VALIDATION_ERROR', {
        formErrors,
        formData,
        organizationAdjustmentRuleId,
      })
    },
    [getValues, trackEvent, organizationAdjustmentRuleId]
  )

  const footerActions: ISidePanelProps['actions'] = useMemo(() => {
    return activeStepIndex === SECTIONS_STEP_NUMBER.review
      ? [
          {
            label: isEdit ? 'Update Rule' : 'Publish Rule',
            'data-test': 'AdjustmentRuleForm-submit',
            disabled: loading || !isFormDirty,
            onClick: handleSubmit(onFormSubmit, onFormError),
          },
        ]
      : undefined
  }, [activeStepIndex, isEdit, loading, handleSubmit, onFormSubmit, onFormError, isFormDirty])

  const {
    createToggleHandler,
    modalState: { closeFormConfirmationModal: isCloseFormConfirmationModalOpen },
    toggleModal,
  } = useModalState({
    closeFormConfirmationModal: false,
  })

  const toggleCloseConfirmationModal = createToggleHandler('closeFormConfirmationModal')

  const closeSidePanelHandler = useCallback(() => {
    if (isFormDirty) {
      toggleModal('closeFormConfirmationModal')
    } else {
      closeSidePanel()
    }
  }, [isFormDirty, closeSidePanel, toggleModal])

  const closeAllModals = useCallback(() => {
    toggleModal('closeFormConfirmationModal', false)
    closeSidePanel()
  }, [closeSidePanel, toggleModal])

  return (
    <SidePanel
      dataTest='AdjustmentRuleFormSidePanel'
      onClose={closeSidePanelHandler}
      anchor='right'
      header={<AdjustmentRuleReasonSection />}
      actions={footerActions}>
      {!!errorMessages && (
        <Box marginBottom={4}>
          <InlineMessage
            type={INLINE_MESSAGE_VARIANTS.ALERT}
            message={errorMessages}
            dataTest='AdjustmentRuleFormSidePanel-errorInlineMessage'
          />
        </Box>
      )}
      <AdjustmentRuleRateStrategySection />
      <AdjustmentRuleTimeframeSection isEditMode={!!organizationAdjustmentRuleId} />
      <AdjustmentRuleFactorsSection />
      {isCloseFormConfirmationModalOpen && (
        <CloseFormConfirmationModal
          onCancel={toggleCloseConfirmationModal}
          onConfirm={closeAllModals}
        />
      )}
    </SidePanel>
  )
}
