import React, { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { Avatar } from 'dpl/components'
import { Button, Grid, makeStyles, Typography } from 'dpl/core'
import LockedIcon from 'dpl/icons/build/LockedIcon'
import ControlledPhoneInput from 'forms/components/ControlledPhoneInput'
import ControlledTextField from 'forms/components/ControlledTextField'
import { TEditProfileFormSchema } from './EditProfileFormSchema'
import { useEditProfileFormContext } from './useEditProfileForm'

export interface IEditProfileFormProps {
  isAdmin: boolean
  onClose: () => void
  onSubmit: () => void
}

const useEditProfileFormStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  formActions: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    '& > button': {
      flex: 1,
      [theme.breakpoints.up('md')]: {
        flex: 0,
      },
    },
  },
}))

export function EditProfileForm({ isAdmin, onClose, onSubmit }: IEditProfileFormProps) {
  const { control, errors } = useEditProfileFormContext()
  const classes = useEditProfileFormStyles()

  const { firstName, imageFile, lastName } = useWatch<TEditProfileFormSchema>({
    name: ['firstName', 'lastName', 'imageFile'],
    control,
  })

  const fullName = useMemo(() => {
    if (firstName && lastName) return `${firstName} ${lastName}`
    return firstName || lastName || ''
  }, [firstName, lastName])

  return (
    <div className={classes.root}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Avatar
            name={fullName}
            src={imageFile?.file}
            variant='circular'
            size='xlarge'
            hideTooltip
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name='firstName'
            control={control}
            TextFieldProps={{
              label: 'First Name',
              error: !!errors.firstName,
              helperText: errors?.firstName?.message,
              dataTest: 'input-firstName',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name='lastName'
            control={control}
            TextFieldProps={{
              label: 'Last Name',
              error: !!errors.lastName,
              helperText: errors?.lastName?.message,
              dataTest: 'input-lastName',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name='email'
            control={control}
            TextFieldProps={{
              label: 'Email',
              error: !!errors.email,
              helperText: errors?.email?.message,
              dataTest: 'input-email',
              required: true,
              disabled: true,
              clearable: false,
              InputProps: {
                startAdornment: !isAdmin ? <LockedIcon size='large' /> : undefined,
              },
            }}
          />
          {!isAdmin && (
            <Typography variant='caption' mt={0.5} component='div'>
              Email is set by the Admin.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <ControlledPhoneInput
            name='phoneNumber'
            control={control}
            PhoneInputProps={{
              label: 'Phone Number',
              error: !!errors.phoneNumber,
              helperText: errors?.phoneNumber?.message,
              dataTest: 'input-phoneNumber',
              required: true,
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.formActions}>
        <Button variant='text' onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Save</Button>
      </div>
    </div>
  )
}
