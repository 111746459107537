import { MODULES_BY_PERMISSION } from '#constants/modules'
import { IUserPermissions } from 'auth/common/context/PermissionsContext/types'
import { INavProps } from 'dpl/templates/AppLayout/Nav'
import { NAV_LISTS_BY_PERMISSION, DEFAULT_NAV_LISTS } from '../constants/navLists'
import { IProductModules } from '../types/graphqlTypes'

interface IGetUserEnabledNavListsProps {
  /**
   * Active modules for the org.
   */
  activeProductModules?: IProductModules[]
  /**
   * The users permissions.
   */
  userPermissions: Record<keyof IUserPermissions, boolean>
}

/**
 * Get the nav lists for the enabled user permissions and active product modules.
 *
 * @param activeProductModules - The active product modules.
 * @param userPermissions - The user permissions.
 * @returns The nav lists for the enabled permissions.
 *
 * @example getUserEnabledNavLists({ userPermissions: { 'routing_guide.read_app': true } })
 *
 */
export const getUserEnabledNavLists = ({
  activeProductModules,
  userPermissions,
}: IGetUserEnabledNavListsProps): NonNullable<INavProps['navLists']> => {
  const navListsPermissions: (keyof IUserPermissions)[] = Object.keys(
    NAV_LISTS_BY_PERMISSION
  ) as (keyof IUserPermissions)[]

  const userEnabledNavList: INavProps['navLists'] = []

  navListsPermissions.forEach(permission => {
    const module = MODULES_BY_PERMISSION[permission]
    const isModuleEnabled =
      module && activeProductModules ? activeProductModules.includes(module) : false
    if (!isModuleEnabled) {
      return
    }

    const isPermissionEnabled = userPermissions[permission]
    if (!isPermissionEnabled) {
      return
    }

    const permissionNavList = NAV_LISTS_BY_PERMISSION[permission]

    if (permissionNavList) {
      permissionNavList.forEach(navListItem => {
        userEnabledNavList.push(navListItem)
      })
    }
  })

  /**
   * Set used to remove potential duplicate values by reference. This
   * enable us to add the same navListItem by two different permissions.
   */
  return DEFAULT_NAV_LISTS.concat([...new Set(userEnabledNavList)])
}
