import * as GeoJSON from 'geojson'
import { IMapStopProps } from '../types'

export function getGeoJSONFeatureStop<S extends IMapStopProps>(
  stop: S
): GeoJSON.Feature<GeoJSON.Point, S> {
  const { coords, type } = stop || {}
  const { lat = 0, lon = 0 } = coords || {}
  return {
    type: 'Feature',
    properties: {
      ...stop,
      /**
       * Adds a short way to detect stop type in Expression syntax
       */
      typeShort: type === 'pickup' ? 'P' : 'D',
    },
    geometry: {
      type: 'Point',
      coordinates: [lon, lat],
    },
  }
}
