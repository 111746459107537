import { DeepPartial } from 'react-hook-form'
import get from 'lodash/get'
import { TAdjustmentRuleFormSchema } from '../../../schema'
import {
  CUSTOMER_FACTOR_NAME,
  DELIVERY_LOCATION_FACTOR_NAME,
  PICKUP_LOCATION_FACTOR_NAME,
  EQUIPMENT_FACTOR_NAME,
} from '../constants'

/**
 * Additive adjustment rules can only have one factor.
 * When customer or equipment factors are included, the other factors are disabled.
 * When either the pickup/delivery factors is included, the other factors are disabled except the remaining pickup/delivery.
 * @returns object where key is the name of the factor and the value is an object that
 * represents if it should be included or disabled
 */
export const getFactorsState = (
  values: DeepPartial<Pick<TAdjustmentRuleFormSchema, 'factors'>>,
  isAdditiveRule: boolean
) => {
  const pickupLocation = get(values, PICKUP_LOCATION_FACTOR_NAME) || {}
  const deliveryLocation = get(values, DELIVERY_LOCATION_FACTOR_NAME) || {}
  const customer = get(values, CUSTOMER_FACTOR_NAME) || {}
  const equipment = get(values, EQUIPMENT_FACTOR_NAME) || {}

  const { includeFactor: includePickupLocation = false } = pickupLocation
  const { includeFactor: includeDeliveryLocation = false } = deliveryLocation
  const { includeFactor: includeCustomer = false } = customer
  const { includeFactor: includeEquipment = false } = equipment

  const isAFactorSelected =
    includePickupLocation || includeDeliveryLocation || includeCustomer || includeEquipment

  if (!isAdditiveRule || !isAFactorSelected) {
    return {
      pickupLocationState: {
        included: includePickupLocation,
        disabled: false,
      },
      deliveryLocationState: {
        included: includeDeliveryLocation,
        disabled: false,
      },
      customerState: {
        included: includeCustomer,
        disabled: false,
      },
      equipmentState: {
        included: includeEquipment,
        disabled: false,
      },
    }
  }

  if (includePickupLocation || includeDeliveryLocation) {
    return {
      pickupLocationState: {
        included: includePickupLocation,
        disabled: false,
      },
      deliveryLocationState: {
        included: includeDeliveryLocation,
        disabled: false,
      },
      customerState: {
        included: false,
        disabled: true,
      },
      equipmentState: {
        included: false,
        disabled: true,
      },
    }
  }

  return {
    pickupLocationState: {
      included: false,
      disabled: true,
    },
    deliveryLocationState: {
      included: false,
      disabled: true,
    },
    customerState: {
      included: includeCustomer,
      disabled: includeEquipment,
    },
    equipmentState: {
      included: includeEquipment,
      disabled: includeCustomer,
    },
  }
}
