import { IDropTrailerLocations } from '#routes/contract-rates-tool/ContractRatesToolPage/types.js'
import { IContractAdvancedInputsSchema } from '../schema'

export const getDropTrailerLocations = ({
  dropTrailer,
  dropTrailerSwitch,
}: Pick<IContractAdvancedInputsSchema, 'dropTrailer' | 'dropTrailerSwitch'>) => {
  if (!dropTrailerSwitch || !dropTrailer) {
    return {
      dropTrailerPickup: false,
      dropTrailerDelivery: false,
    }
  }
  const dropTrailerPickup = dropTrailer.includes(IDropTrailerLocations.firstPickup)
  const dropTrailerDelivery = dropTrailer.includes(IDropTrailerLocations.lastDelivery)

  return {
    dropTrailerPickup,
    dropTrailerDelivery,
  }
}
