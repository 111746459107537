import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl/components'
import SidePanel from 'dpl/components/SidePanel'
import { IUserProfileInfo } from 'dpl/templates'
import { EditProfileForm } from './EditProfileForm'
import { useUpdateUserMutation } from './graphql/UpdateUser'
import { useEditProfileForm } from './useEditProfileForm'

export interface IEditProfileDrawerProps {
  open: boolean
  onClose: () => void
  userInfo: IUserProfileInfo & { organizationUserId: string }
  isAdmin: boolean
}

export function EditProfileDrawer({ isAdmin, onClose, open, userInfo }: IEditProfileDrawerProps) {
  const [apiError, setApiError] = useState('')
  const methods = useEditProfileForm({
    defaultValues: {
      ...userInfo,
      imageFile: { file: userInfo.imageSrc },
    },
  })
  const [updateUser] = useUpdateUserMutation()

  const { handleSubmit } = methods || {}

  /**
   * Refresh data when it is updated
   */
  useEffect(() => {
    methods.reset({
      ...userInfo,
      imageFile: { file: userInfo.imageSrc },
    })
    /**
     * This effect should only run when the userInfo changes
     * meaning that the methods.reset should only run when the userInfo changes
     * avoiding infinite loops
     */
  }, [methods.reset, userInfo])

  const saveHandler = handleSubmit(async values => {
    setApiError('')
    try {
      const { data } = await updateUser({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
          },
        },
      })

      const { updateCurrentUser } = data || {}
      const { errors = [] } = updateCurrentUser || {}

      if (errors.length > 0) {
        setApiError(errors[0].message)
      } else if (onClose) {
        onClose()
      } else {
        throw new Error('onClose method is not defined')
      }
    } catch (e) {
      setApiError(e.message)
    }
  })

  return (
    <SidePanel open={open} onClose={onClose} anchor='right' title='Edit Profile'>
      <FormProvider {...methods}>
        {apiError && <InlineMessage message={apiError} type={INLINE_MESSAGE_VARIANTS.ALERT} />}
        <EditProfileForm isAdmin={isAdmin} onClose={onClose} onSubmit={saveHandler} />
      </FormProvider>
    </SidePanel>
  )
}
