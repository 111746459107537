import React from 'react'
import { Navigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { usePermissionsContext } from 'auth/common/context'

export function RatesRedirect() {
  /**
   * its safe to check just user permission since this route can only be added dynamically.
   */
  const { userPermissions } = usePermissionsContext()
  /**
   * spot will always be the main route unless the user has no access to it.
   */
  const ratesHome = userPermissions['rates_tool.read_app']
    ? PROTECTED_PATHS.spotRatesTool
    : PROTECTED_PATHS.contractRatesTool

  return <Navigate to={ratesHome} />
}
