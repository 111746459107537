import { IRuleTypeEnum } from '#types/rates'
import MarginAdditiveNegativeIcon from 'dpl/icons/build/MarginAdditiveNegativeIcon'
import MarginAdditivePositiveIcon from 'dpl/icons/build/MarginAdditivePositiveIcon'
import MarginBaseIcon from 'dpl/icons/build/MarginBaseIcon'
import SettingsIcon from 'dpl/icons/build/SettingsIcon'
import { TIconComponent } from 'dpl/icons/types'

interface IGetAdjustmentRuleIconProps {
  adjustmentValue: Nullable<number>
  ruleAction: Nullable<string>
}

/**
 * Returns the icon for an adjustment rule based on the adjustment value and rule action.
 *
 * @returns {TIconComponent} The icon for the adjustment rule
 *
 * @example getAdjustmentRuleIcon({ adjustmentValue: 60, ruleAction: 'baseline_adjustment' }) // MarginBaseIcon
 * @example getAdjustmentRuleIcon({ adjustmentValue: 0.02, ruleAction: 'additive_adjustment' }) // MarginAdditivePositiveIcon
 * @example getAdjustmentRuleIcon({ adjustmentValue: -0.03, ruleAction: 'additive_adjustment' }) // MarginAdditiveNegativeIcon
 */
export const getAdjustmentRuleIcon = ({
  adjustmentValue,
  ruleAction,
}: IGetAdjustmentRuleIconProps): TIconComponent => {
  if (ruleAction === IRuleTypeEnum.base || ruleAction === 'default') {
    return MarginBaseIcon
  }

  if (ruleAction === IRuleTypeEnum.additive) {
    if (adjustmentValue && adjustmentValue < 0) {
      return MarginAdditiveNegativeIcon
    }
    return MarginAdditivePositiveIcon
  }

  return SettingsIcon
}
