import { UseFormOptions, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SaveQuoteFormSchema, TSaveQuoteFormSchema } from '../schema'

export interface ISaveQuoteModalFormContext {
  markAsWon: boolean
  isAdjustmentRulesOverridden: boolean
  isDoNotBidRulesOverridden: boolean
}

export interface ISaveQuoteModalFormProps
  extends Omit<UseFormOptions<TSaveQuoteFormSchema, ISaveQuoteModalFormContext>, 'resolver'> {}

export const useSaveQuoteModalForm = ({ ...rest }: ISaveQuoteModalFormProps) => {
  return useForm<TSaveQuoteFormSchema, ISaveQuoteModalFormContext>({
    resolver: yupResolver(SaveQuoteFormSchema),
    mode: 'onChange',
    ...rest,
  })
}
