import React, { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UNPROTECTED_PATHS } from '#constants/paths'
import { IAuthenticateTokenResponseBody } from 'auth/b2b/clients/discovery/types'
import {
  DiscoveredOrganizationList,
  DiscoveryCard,
  DiscoveredOrganizationRedirect,
  IDiscoveredOrganizationListProps,
  IDiscoveryCardProps,
} from 'auth/b2b/components'
import { makeStyles } from 'dpl/core'
import { useAnalytics } from '../../hooks/useAnalytics'
import { TERMS_OF_SERVICE_URL } from './constants'

const useLoginStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(6),
    },
  },
}))

const REDIRECT_URLS: IDiscoveryCardProps['redirectUrls'] = {
  discovery_redirect_url: `${import.meta.env.VITE_ROOT}${UNPROTECTED_PATHS.authenticate}`,
}

interface ILoginRouteProps {
  /**
   * If invalid, we show different copy and ask user to request a new link.
   * @default false
   */
  isMagicLinkInvalid?: boolean
}

export function Login({ isMagicLinkInvalid = false }: ILoginRouteProps) {
  const classes = useLoginStyles()

  const navigate = useNavigate()
  const location = useLocation()

  const { identifyUser, trackEvent } = useAnalytics()

  const state = location.state as IAuthenticateTokenResponseBody

  const { discovered_organizations: discoveredOrganizations, email_address: email } = state ?? {}

  const organizationSelectHandler = useCallback<
    IDiscoveredOrganizationListProps['onOrganizationSelect']
  >(
    ({ organization: { organization_slug: organizationSlug } }) => {
      const searchParams = new URLSearchParams({ email })
      navigate(`${UNPROTECTED_PATHS.login}/${organizationSlug}?${searchParams.toString()}`)
    },
    [email, navigate]
  )

  const magicLinkRequestHandler = useCallback<
    NonNullable<IDiscoveryCardProps['onMagicLinkRequest']>
  >(
    email => {
      identifyUser({ email })
      trackEvent(
        'Discovery Login',
        isMagicLinkInvalid ? 'REQUEST_NEW_LOGIN_MAGIC_LINK' : 'REQUEST_LOGIN_MAGIC_LINK',
        { email }
      )
    },
    [identifyUser, isMagicLinkInvalid, trackEvent]
  )

  if (discoveredOrganizations) {
    return (
      <div data-test='route-login' className={classes.root}>
        {discoveredOrganizations.length === 1 &&
        discoveredOrganizations[0].membership.type === 'active_member' ? (
          <DiscoveredOrganizationRedirect
            onOrganizationSelect={organizationSelectHandler}
            discoveredOrganization={discoveredOrganizations[0]}
          />
        ) : (
          <DiscoveredOrganizationList
            onOrganizationSelect={organizationSelectHandler}
            discoveredOrganizations={discoveredOrganizations}
            termsOfServiceUrl={TERMS_OF_SERVICE_URL}
          />
        )}
      </div>
    )
  }

  return (
    <div data-test='route-login' className={classes.root}>
      <DiscoveryCard
        isRequestingNewLink={isMagicLinkInvalid}
        redirectUrls={REDIRECT_URLS}
        termsOfServiceUrl={TERMS_OF_SERVICE_URL}
        loginHref={UNPROTECTED_PATHS.login}
        onMagicLinkRequest={magicLinkRequestHandler}
      />
    </div>
  )
}
