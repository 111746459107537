import React, { memo, useCallback, useMemo } from 'react'
import { ILocationResultFragment } from '#components/AutocompleteLocation/graphql/LocationResultFragment'
import { IDataGridFilterProps } from 'dpl/components/DataGrid/components/DataGridFilters/components/types'
import { IconButton } from 'dpl/components/IconButton'
import { Box, makeStyles } from 'dpl/core'
import { ArrowDownIcon, ArrowUpIcon, SwapIcon } from 'dpl/icons'
import { AutocompleteLocation, IAutocompleteLocationProps } from '../AutocompleteLocation'
import { IDataGridFilterOriginDestinationLocation } from './types'

const useDataGridFilterOriginDestinationLocationStyles = makeStyles(() => ({
  headerFilter: {
    minWidth: '250px',
  },
  menuFilter: {
    flex: 1,
  },
}))
export interface IDataGridFilterOriginDestinationLocationProps
  extends IDataGridFilterProps<IDataGridFilterOriginDestinationLocation> {
  /**
   * @default 'DataGridFilterOriginDestinationLocation'
   */
  dataTest?: string
}

export const DataGridFilterOriginDestinationLocation =
  memo<IDataGridFilterOriginDestinationLocationProps>(
    ({
      dataTest = 'DataGridFilterOriginDestinationLocation',
      name,
      onChange,
      value: valueProp,
      variant,
    }) => {
      const classes = useDataGridFilterOriginDestinationLocationStyles()

      const originValue = useMemo(() => {
        const { originCity, originStateCode } = valueProp || {}
        const cityState = {
          city: originCity,
          stateCode: originStateCode,
        } as ILocationResultFragment
        return originCity && originStateCode ? cityState : null
      }, [valueProp])

      const destinationValue = useMemo(() => {
        const { destinationCity, destinationStateCode } = valueProp || {}
        const cityState = {
          city: destinationCity,
          stateCode: destinationStateCode,
        } as ILocationResultFragment
        return destinationCity && destinationStateCode ? cityState : null
      }, [valueProp])

      const originLocationHandler = useCallback<
        NonNullable<IAutocompleteLocationProps['onChange']>
      >(
        (_, value) => {
          const { city: originCity, stateCode: originStateCode } = value || {}
          onChange({ name, value: { ...valueProp, originCity, originStateCode } })
        },
        [name, onChange, valueProp]
      )

      const destinationLocationHandler = useCallback<
        NonNullable<IAutocompleteLocationProps['onChange']>
      >(
        (_, value) => {
          const { city: destinationCity, stateCode: destinationStateCode } = value || {}
          onChange({ name, value: { ...valueProp, destinationCity, destinationStateCode } })
        },
        [name, onChange, valueProp]
      )

      const swapHandler = useCallback(() => {
        const { destinationCity, destinationStateCode, originCity, originStateCode } =
          valueProp || {}
        onChange({
          name,
          value: {
            originCity: destinationCity,
            originStateCode: destinationStateCode,
            destinationCity: originCity,
            destinationStateCode: originStateCode,
          },
        })
      }, [name, onChange, valueProp])

      return (
        <Box display='flex' gap={1} alignItems='center'>
          <div className={variant === 'header' ? classes.headerFilter : classes.menuFilter}>
            <AutocompleteLocation
              renderStartAdornment={() => <ArrowUpIcon size='xlarge' />}
              size={variant === 'menu' ? 'medium' : 'small'}
              dataTest={`${dataTest}-origin`}
              onChange={originLocationHandler}
              value={originValue}
              isOptionEqualToValue={(option, value) =>
                `${option.city}, ${option.stateCode}` === `${value.city}, ${value.stateCode}`
              }
              placeholder='Origin'
            />
          </div>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <IconButton
              dataTest={`${dataTest}-swap-button`}
              Icon={SwapIcon}
              color='secondary'
              onClick={swapHandler}
            />
          </Box>
          <div className={variant === 'header' ? classes.headerFilter : classes.menuFilter}>
            <AutocompleteLocation
              renderStartAdornment={() => <ArrowDownIcon size='xlarge' />}
              size={variant === 'menu' ? 'medium' : 'small'}
              dataTest={`${dataTest}-destination`}
              onChange={destinationLocationHandler}
              value={destinationValue}
              isOptionEqualToValue={(option, value) =>
                `${option.city}, ${option.stateCode}` === `${value.city}, ${value.stateCode}`
              }
              placeholder='Destination'
            />
          </div>
        </Box>
      )
    }
  )
