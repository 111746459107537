import { ICreateRoutingGuideCarrierRateInput } from '../../../../../../types/graphqlTypes'
import { TRoutingGuideCarrierSchema } from '../schema'
import { getRoutingGuideCarrierRateInput } from './getRoutingGuideCarrierRateInput'

export interface IGetCreateRoutingGuideCarrierRateInputProps {
  routingGuideLaneId: string
  formValues: TRoutingGuideCarrierSchema
}

export function getCreateRoutingGuideCarrierRateInput({
  formValues,
  routingGuideLaneId,
}: IGetCreateRoutingGuideCarrierRateInputProps): ICreateRoutingGuideCarrierRateInput {
  const { organizationCarrier } = formValues || {}
  // org carrier
  const { carrier } = organizationCarrier || {}
  const { id: carrierId } = carrier || {}

  return {
    ...getRoutingGuideCarrierRateInput({ formValues }),
    carrierId: `${carrierId}`,
    routingGuideLaneId,
  }
}
