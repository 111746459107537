import React from 'react'
import { useAnalytics } from '#hooks'
import { ConfirmationModal, IModalAction } from 'dpl/components/Modals'
import { useToastContext } from 'dpl/components/Toast'
import { Box, Typography, Button, makeStyles } from 'dpl/core'
import DownloadIcon from 'dpl/icons/build/DownloadIcon'
import { brandColors } from 'dpl/theme/colors'
import { useRestartRfpRoundMutation } from './graphql/RestartRfpRoundMutation'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      /**
       * Magic number coming from spec 🪄✨
       */
      maxWidth: '500px',
    },
  },
}))

interface RestartRoundModalProps {
  open: boolean
  onClose: () => void
  roundNumber: number
  currentRoundId: string
  contractId: string
  displayId: number
}

export function RestartRoundModal({
  contractId,
  currentRoundId,
  displayId,
  onClose,
  open,
  roundNumber,
}: RestartRoundModalProps) {
  const { trackEvent } = useAnalytics()
  const [restartRound, { loading: isRestartRoundLoading }] = useRestartRfpRoundMutation()
  const { openToast } = useToastContext() || {}
  const classes = useStyles()
  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'dismiss',
      action: onClose,
      ButtonProps: { color: 'secondary' },
      disabled: isRestartRoundLoading,
    },
    {
      label: 'Delete and Import New Lanes',
      type: 'confirm',
      action: async () => {
        const input = {
          id: currentRoundId,
        }
        try {
          const { data } = await restartRound({
            variables: {
              input,
            },
          })
          const { restartContractRfpVersion } = data ?? {}
          const { errors: mutationErrors } = restartContractRfpVersion ?? {}

          if (mutationErrors?.length) {
            const error = mutationErrors.map(({ message }) => message).join(', ')
            openToast({
              toastMessage: error,
              toastType: 'alert',
              multiLine: true,
            })

            trackEvent('Contract RFPs', 'RESTART_ROUND_ERROR', {
              errors: mutationErrors,
              item: { contractId, currentRoundId, displayId, roundNumber },
              variables: input,
            })

            return
          }

          trackEvent('Contract RFPs', 'RESTART_ROUND_SUCCESS', {
            item: { contractId, currentRoundId, displayId, roundNumber },
            variables: input,
          })
        } catch (error) {
          openToast({
            toastMessage: 'Something went wrong. Please try again.',
            toastType: 'alert',
          })

          trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
            errors: [error],
            item: { contractId, currentRoundId, displayId, roundNumber },
            variables: input,
          })
        } finally {
          onClose()
        }
      },
      ButtonProps: { color: 'error' },
      disabled: isRestartRoundLoading,
    },
  ]

  return (
    <ConfirmationModal
      className={classes.modal}
      title='Restart Round'
      includeCloseIconButton
      maxWidth={false}
      actions={actions}
      open={open}
      onClose={onClose}>
      <Typography variant='body1' pb={3}>
        All lanes in <strong>Round {roundNumber}</strong> will be deleted. You&apos;ll be able to
        import new lanes next.
      </Typography>
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='caption' color={brandColors.coolGray5}>
          Download a copy of this round to save the current state.
        </Typography>
        <Box display='inline-flex' marginBottom={0.5}>
          <Button
            size='small'
            color='secondary'
            startIcon={<DownloadIcon />}
            href={`${import.meta.env.VITE_BASE_API}/contract_rfp/rfps/${contractId}/versions/${currentRoundId}/csv`}
            download={`RFP-${displayId}-version-${currentRoundId}.csv`}>
            Download
          </Button>
        </Box>
      </Box>
    </ConfirmationModal>
  )
}
