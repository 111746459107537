import React, { memo, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { PROTECTED_PATHS } from '#constants/paths'
import { useToastContext } from 'dpl/components/Toast'
import { makeStyles } from 'dpl/core'
import { useNavigateBack } from 'dpl/hooks/useNavigateBack'
import { PageTemplate } from 'dpl/templates'
import {
  ActivitySection,
  CarriersSection,
  LaneDetailsSection,
  RoutingGuideUnavailableCard,
  RoutingGuideLaneDetailsTabs,
} from './components'
import { CarrierRatesReorderProvider } from './context'
import { useRoutingGuideLaneQuery } from './graphql/RoutingGuideLane'

const useRoutingGuideLaneDetailsPageStyles = makeStyles(theme => ({
  tabs: {
    '& .MuiTabs-root': {
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 3),
      },
    },
  },
  mainContent: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 3, 6),
    },
  },
}))

export const RoutingGuideLaneDetailsPage = memo(() => {
  const classes = useRoutingGuideLaneDetailsPageStyles()
  const { openToast } = useToastContext()
  const navigate = useNavigate()
  const navigateBack = useNavigateBack()
  const { id = '', tab = '' } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  const isLaneNew = searchParams.get('create')

  const { data, loading, refetch } = useRoutingGuideLaneQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  })
  const { routingGuideLane } = data || {}
  const {
    id: routingGuideLaneId,
    deliveryLocation,
    displayId,
    equipment,
    laneManager,
    orgShipper,
    pickupLocation,
    routingGuideCarrierRates = [],
  } = routingGuideLane || {}
  const { city: pickupCity = '--', stateCode: pickupStateCode = '--' } = pickupLocation || {}
  const { city: deliveryCity = '--', stateCode: deliveryStateCode = '--' } = deliveryLocation || {}
  const { name: equipmentName = '--' } = equipment || {}
  const { name: orgShipperName = '--' } = orgShipper || {}

  const pageTitle = useMemo(() => {
    if (loading || !displayId) {
      return ' '
    }
    return `RG-${displayId}`
  }, [displayId, loading])

  const showUnavailableCard = useMemo(() => {
    if (!loading && !data) {
      return true
    }
    return false
  }, [loading, data])

  const backButtonClickHandler = () => {
    navigateBack({ path: PROTECTED_PATHS.routingGuideLanes })
  }

  useEffectOnce(() => {
    if (isLaneNew) {
      openToast({ toastMessage: 'Lane successfully created.' })
      setSearchParams({}, { replace: true })
    }
  })

  useEffectOnce(() => {
    if (tab === '') {
      navigate(`${PROTECTED_PATHS.routingGuideLanes}/${id}/carriers`, { replace: true })
    }
  })

  return (
    <CarrierRatesReorderProvider routingGuideLaneId={id}>
      <PageTemplate
        dataTest='route-routing-guide-lane-details'
        pageTitle={pageTitle}
        documentTitle='Routing Guide'
        noPadding
        PageBodyTitleProps={{
          pageType: 'subPage',
          onBackButtonClick: backButtonClickHandler,
        }}>
        {showUnavailableCard ? (
          <RoutingGuideUnavailableCard />
        ) : (
          <div>
            <LaneDetailsSection
              routingGuideLaneId={routingGuideLaneId}
              equipmentName={equipmentName}
              origin={`${pickupCity}, ${pickupStateCode}`}
              destination={`${deliveryCity}, ${deliveryStateCode}`}
              laneManager={laneManager}
              orgShipperName={orgShipperName}
              isLoading={loading}
            />
            <RoutingGuideLaneDetailsTabs
              numberOfCarrierRates={routingGuideCarrierRates?.length}
              isLoading={loading}>
              <div className={classes.mainContent}>
                {tab === 'carriers' && (
                  <CarriersSection
                    routingGuideLaneId={id}
                    carrierRates={routingGuideCarrierRates}
                    isLoading={loading}
                    onRefetch={refetch}
                  />
                )}
                {tab === 'activity' && <ActivitySection routingGuideLaneId={id} />}
              </div>
            </RoutingGuideLaneDetailsTabs>
          </div>
        )}
      </PageTemplate>
    </CarrierRatesReorderProvider>
  )
})
