import React from 'react'
import { ArrayField, Controller, useFormContext } from 'react-hook-form'
import { ILocationResultFragment } from '#components/AutocompleteLocation/graphql/LocationResultFragment'
import { ControlledAutocompleteLocation } from '#components/ControlledAutocompleteLocation'
import { IRfpLaneInfoSchema, IStopSchema } from '#routes/contract-rfp/ContractRFPDetailPage/schema'
import { IGeocodingResultTypeEnum } from '#types/graphqlTypes'
import { ButtonGroup, IButtonGroupProps } from 'dpl/components/ButtonGroup'
import { Box, Button, makeStyles, Typography } from 'dpl/core'
import ControlledHiddenInput from 'forms/components/ControlledHiddenInput'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { StopButton } from './StopButton'

export const LOCATION_RESULT_TYPES = [
  IGeocodingResultTypeEnum.City,
  IGeocodingResultTypeEnum.PostalCode,
]

export const getOptionLabel = ({
  city,
  postalCode,
  resultType,
  stateCode,
}: ILocationResultFragment) => {
  /**
   * For consistency, we avoid showing postal code for
   * City result types, even if the response has one.
   */
  const postalCodeLabel = resultType === 'City' ? '' : `(${postalCode})`

  return `${city}, ${stateCode} ${postalCodeLabel}`
}

const isContinentalUsState = ({ stateCode }: ILocationResultFragment) => {
  // Exclude Alaska (AK) and Hawaii (HI) state codes
  return stateCode !== 'AK' && stateCode !== 'HI'
}

export const filterOptions = (options: ILocationResultFragment[]) => {
  return uniqBy(options, ({ city, postalCode, stateCode }) =>
    [city ?? '', stateCode ?? '', postalCode ?? ''].join('')
  )
    .filter(isContinentalUsState) // Reject non-continental US states
    .sort(({ resultType: firstResultType }, { resultType: secondResultType }) => {
      const isFirstTypePostalCode = firstResultType !== 'City'
      const isSecondTypePostalCode = secondResultType !== 'City'
      /**
       * Postal code result should come before city result.
       */
      if (isFirstTypePostalCode) return -1
      if (!isFirstTypePostalCode && isSecondTypePostalCode) return 1

      return 0
    })
}

export const minDate = moment().subtract(7, 'days')
export const maxDate = moment().add(45, 'days')

export const locationGroupBy = ({ resultType }: ILocationResultFragment) => resultType

const useStopApptInputRowStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 48px',
    gap: theme.spacing(1),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'start',
    },
    padding: theme.spacing(1, 1),
  },
  stopAppt: {
    display: 'flex',
    gap: theme.spacing(1),
    flex: 1,
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  stopTypeButtonGroupContainer: {
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      width: '154px', // per design
    },
  },
  timeField: {
    // magic number taken from spec 🪄✨
    maxWidth: '200px',
  },
  dateTime: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
}))

interface IStopApptInputRowProps {
  /**
   * Index of current stop
   */
  index: number
  /**
   * Field array object
   */
  stopField: Partial<ArrayField<IStopSchema, 'laneId'>>
  /**
   * Number of stops in field array
   */
  stopFieldsLength: number
  /**
   * Watched values of the current stop, useful for rendering any changes dependent on value
   */
  currentStopValues: IStopSchema | undefined
  /**
   * Adds a new stop right before the final destination
   */
  onAddStop: () => void
  /**
   * Removes the stop with the given index
   */
  onRemoveStop: (index: number) => void
  /**
   * Swaps the origin and destination, only applicable when there are 2 total stops
   */
  onSwapStops: () => void
  /**
   * Input label for the stop autocomplete
   * @example Pickup 2
   */
  stoplabel: string
}

/**
 * Row of stop AutocompleteLocation input, and associated appointment time DateTimePicker if is the first or the last stop
 */
export function StopApptInputRow({
  index,
  onAddStop,
  onRemoveStop,
  onSwapStops,
  stopField,
  stopFieldsLength,
  stoplabel,
}: IStopApptInputRowProps) {
  const classes = useStopApptInputRowStyles()
  const { control, errors, trigger } = useFormContext<IRfpLaneInfoSchema>()
  const fieldName = `stops[${index}]`
  const isFirstStop = index === 0
  const isLastStop = index === stopFieldsLength - 1
  const stopWithAppt = isFirstStop || isLastStop

  return (
    <div className={classes.root}>
      <div className={classes.stopAppt}>
        {!stopWithAppt ? (
          <Controller
            name={`${fieldName}.type`}
            control={control}
            defaultValue={stopField.type}
            render={({ onChange, value }) => {
              const onChangeHandler: IButtonGroupProps['onChange'] = value => {
                onChange(value)
                trigger(`stops[${index - 1}].type`)
              }
              return (
                <div className={classes.stopTypeButtonGroupContainer}>
                  <ButtonGroup value={value} onChange={onChangeHandler} size='xsmall'>
                    <Button value='pickup' data-test='pickupType-button'>
                      Pickup
                    </Button>
                    <Button value='delivery' data-test='deliveryType-button'>
                      Delivery
                    </Button>
                  </ButtonGroup>
                  {errors?.stops?.[index]?.type?.message && (
                    <Typography variant='caption' color='error'>
                      {errors?.stops?.[index]?.type?.message}
                    </Typography>
                  )}
                </div>
              )
            }}
          />
        ) : (
          <ControlledHiddenInput
            control={control}
            name={`${fieldName}.type`}
            defaultValue={stopField.type}
          />
        )}
        <Box flex={1}>
          <ControlledAutocompleteLocation
            name={`${fieldName}.location`}
            control={control}
            defaultValue={stopField?.location}
            AutocompleteLocationProps={{
              label: stoplabel,
              placeholder: stoplabel,
              resultTypes: LOCATION_RESULT_TYPES,
              getOptionLabel,
              filterOptions,
              size: 'small',
              helperText: errors?.stops?.[index]?.location?.message,
              error: Boolean(errors?.stops?.[index]?.location),
              dataTest: `${fieldName}-autocompleteLocation`,
              groupBy: locationGroupBy,
            }}
          />
        </Box>
      </div>

      <StopButton
        stopFieldsLength={stopFieldsLength}
        index={index}
        onSwap={onSwapStops}
        onAdd={onAddStop}
        onRemove={onRemoveStop}
      />
    </div>
  )
}
