import {
  AutocompleteOrganizationUserSchema,
  TAutocompleteOrganizationUserSchema,
} from '#components'
import * as yup from 'yup'

function valueNotNull(value: unknown) {
  return value !== null
}

export const UpdateUserAssignmentsFormSchema = yup.object({
  assignee: AutocompleteOrganizationUserSchema.nullable().test(
    'required',
    'You must select an assignee',
    valueNotNull
  ),
  additionalTeamMember1: AutocompleteOrganizationUserSchema.nullable().when(
    'additionalTeamMember2',
    {
      is: (value: TAutocompleteOrganizationUserSchema) => Boolean(value),
      then: schema =>
        schema.test(
          'required',
          'You must select the first team member before adding a second one',
          valueNotNull
        ),
    }
  ),
  additionalTeamMember2: AutocompleteOrganizationUserSchema.nullable(),
})

export type TUpdateUserAssignmentsFormSchema = yup.InferType<typeof UpdateUserAssignmentsFormSchema>
