import React, { useCallback } from 'react'
import {
  AutocompleteOrganizationUser,
  IAutocompleteOrganizationUserProps,
} from '#components/AutocompleteOrganizationUser'
import { IOrganizationUserResultFragment } from '#components/AutocompleteOrganizationUser/graphql/OrganizationUserResultFragment'
import classnames from 'classnames'
import { IDataGridFilterProps } from 'dpl/components/DataGrid'
import { makeStyles } from 'dpl/core'

const useStyles = makeStyles(() => ({
  headerFilter: {
    /**
     * Magic number coming from spec 🪄✨
     */
    minWidth: '250px',
  },
}))

export interface UserFilterProps
  extends IDataGridFilterProps<IOrganizationUserResultFragment | null> {
  /**
   * @default 'DataGridFilterLaneManager'
   */
  dataTest?: string
}

export function UserFilter({
  dataTest = 'DataGridUserFilter',
  name,
  onChange,
  value: valueProp = null,
  variant,
}: UserFilterProps) {
  const changeHandler = useCallback<NonNullable<IAutocompleteOrganizationUserProps['onChange']>>(
    (_, newValue) => {
      onChange({
        name,
        value: newValue ?? null,
      })
    },
    [name, onChange]
  )
  const classes = useStyles()
  const className = classnames({
    [classes.headerFilter]: variant === 'header',
  })

  return (
    <AutocompleteOrganizationUser
      className={className}
      dataTest={dataTest}
      value={valueProp}
      onChange={changeHandler}
      placeholder='User'
      size={variant === 'menu' ? 'medium' : 'small'}
      organizationUserPermissions={['quotes.read_app']}
    />
  )
}
