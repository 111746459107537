import React, { useMemo, useState } from 'react'
import { useAnalytics } from '#hooks'
import { FormModal, SelectField } from 'dpl'
import { Box, Typography } from 'dpl/core'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { IContractRfpNodeFragment } from '../../graphql/ContractRFPNodeFragment'

export interface IDownloadRfpModalProps {
  rounds: IContractRfpNodeFragment['versions']
  onClose: () => void
  contractId: string
  displayId: number
}

export function DownloadRfpModal({
  contractId,
  displayId,
  onClose,
  rounds,
}: IDownloadRfpModalProps) {
  const { trackEvent } = useAnalytics()
  const options = useMemo(
    () =>
      orderBy(rounds, ['number'], ['desc'])
        .filter(({ lanes }) => lanes.totalCount > 0)
        .map(({ createdAt, id, number }) => ({
          label: `Round ${number} (Created ${moment(createdAt).format('MMM D, YYYY')})`,
          value: id,
        })),
    [rounds]
  )
  const [selectedRoundId, setSelectedRoundId] = useState(options[0].value)

  return (
    <FormModal
      open
      maxWidth='md'
      onClose={onClose}
      title='Download RFP'
      actions={[
        { label: 'Cancel', action: onClose, ButtonProps: { variant: 'text' } },
        {
          label: 'Download',
          ButtonProps: {
            href: `${import.meta.env.VITE_BASE_API}/contract_rfp/rfps/${contractId}/versions/${selectedRoundId}/csv`,
            download: `RFP-${displayId}-round-${selectedRoundId}.csv`,
            onClick: () => {
              trackEvent('Contract RFPs', 'DOWNLOAD_RFP_SUCCESS', {
                variables: {
                  contractId,
                  displayId,
                  roundId: selectedRoundId,
                },
              })
            },
          },
        },
      ]}>
      <Box display='flex' flexDirection='column' gap={3}>
        <Typography variant='body2'>This will download the RFP as a CSV file.</Typography>
        {options.length > 1 ? (
          <SelectField
            value={selectedRoundId}
            onChange={e => setSelectedRoundId(e.target.value)}
            label='Round'
            options={options}
          />
        ) : null}
      </Box>
    </FormModal>
  )
}
