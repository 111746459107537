import React, { useMemo } from 'react'
import { Control, FieldErrors, UseFormMethods } from 'react-hook-form'
import { Stack } from 'dpl/core'
import ArrowDownIcon from 'dpl/icons/build/ArrowDownIcon'
import ArrowUpIcon from 'dpl/icons/build/ArrowUpIcon'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { ControlledAutocompleteLocation } from '../../../../../../../components/ControlledAutocompleteLocation'
import { ControlledAutocompleteOrganizationShipper } from '../../../../../../../components/ControlledAutocompleteOrganizationShipper'
import { ControlledEquipmentSelectField } from '../../../../../../../components/ControlledEquipmentSelectField'
import { TRoutingGuideLaneSchema } from '../../schema'
import { ControlledLaneManagerField } from '../ControlledLaneManagerField'

export interface IRoutingGuideLaneFormBodyProps {
  control: Control<TRoutingGuideLaneSchema>
  errors: FieldErrors<TRoutingGuideLaneSchema>
  setValue: UseFormMethods<TRoutingGuideLaneSchema>['setValue']
  /**
   * @default 'RoutingGuideLaneFormBody'
   */
  dataTest?: string
}

export function RoutingGuideLaneFormBody({
  control,
  dataTest = 'RoutingGuideLaneFormBody',
  errors,
  setValue,
}: IRoutingGuideLaneFormBodyProps) {
  const { isFlagEnabled } = useFlagsContext()

  const isCustomerCreateEnabled = useMemo(() => isFlagEnabled('create_customer'), [isFlagEnabled])

  const {
    customer: customerError,
    destination: destinationError,
    equipment: equipmentError,
    laneManager: laneManagerError,
    origin: originError,
  } = errors || {}
  const { message: originErrorMessage } = (originError as FieldErrors) || {}
  const { message: destinationErrorMessage } = (destinationError as FieldErrors) || {}
  const { message: equipmentErrorMessage } = equipmentError || {}
  const { message: customerErrorMessage } = (customerError as FieldErrors) || {}
  const { message: laneManagerErrorMessage } = (laneManagerError as FieldErrors) || {}
  return (
    <Stack data-test={dataTest} gap={3} component='div'>
      <ControlledAutocompleteLocation
        control={control}
        name='origin'
        AutocompleteLocationProps={{
          label: 'Origin City',
          placeholder: 'Origin City',
          error: !!originErrorMessage,
          helperText: originErrorMessage,
          dataTest: `${dataTest}-origin`,
          renderStartAdornment: () => <ArrowUpIcon size='xlarge' />,
        }}
      />
      <ControlledAutocompleteLocation
        control={control}
        name='destination'
        AutocompleteLocationProps={{
          label: 'Destination City',
          placeholder: 'Destination City',
          error: !!destinationErrorMessage,
          helperText: destinationErrorMessage,
          dataTest: `${dataTest}-destination`,
          renderStartAdornment: () => <ArrowDownIcon size='xlarge' />,
        }}
      />
      <ControlledEquipmentSelectField
        control={control}
        name='equipment'
        SelectProps={{
          error: !!equipmentErrorMessage,
          helperText: equipmentErrorMessage,
          dataTest: `${dataTest}-equipment`,
        }}
      />
      <ControlledAutocompleteOrganizationShipper
        enableCreate={isCustomerCreateEnabled}
        control={control}
        name='customer'
        AutocompleteOrganizationShipperProps={{
          label: 'Customer',
          placeholder: 'Customer',
          error: !!customerErrorMessage,
          helperText: customerErrorMessage,
          dataTest: `${dataTest}-customer`,
        }}
      />
      <ControlledLaneManagerField
        control={control}
        setValue={setValue}
        errorMessage={laneManagerErrorMessage}
        dataTest={`${dataTest}-laneManager`}
      />
    </Stack>
  )
}
