import * as yup from 'yup'

export const LinkFormSchema = yup.object({
  link: yup
    .string()
    .required('Please enter a link URL.')
    .test('is-valid', 'This link is not supported', value => {
      if (!value) return true

      return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i.test(
        value
      )
    }),
  name: yup.string(),
})

export type TLinkFormSchema = yup.InferType<typeof LinkFormSchema>
