import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IModalAction, IModalBaseProps, ModalBase } from 'dpl/components/Modals'
import { useToastContext } from 'dpl/components/Toast'
import { Typography } from 'dpl/core'
import ControlledTextField from 'forms/components/ControlledTextField'
import { REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import * as yup from 'yup'
import { useAnalytics } from '../../../hooks'
import { useUpdateQuoteMutation } from '../graphql/UpdateQuoteMutation'

const formSchema = yup.object({
  shipmentNumber: yup.string().required(REQUIRED_MESSAGE),
})

interface MarkAsWonModalFormSchema extends yup.InferType<typeof formSchema> {}

interface IMarkAsWonModalProps extends Pick<IModalBaseProps, 'open' | 'onClose'> {
  quoteId: string
}

export function MarkAsWonModal({ onClose, open, quoteId }: IMarkAsWonModalProps) {
  const { control, errors, handleSubmit } = useForm<MarkAsWonModalFormSchema>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      shipmentNumber: '',
    },
  })
  const { openToast } = useToastContext() || {}
  const { trackEvent } = useAnalytics()

  const [markAsWon, { loading }] = useUpdateQuoteMutation()

  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'dismiss',
      action: onClose,
    },
    {
      label: 'Submit',
      type: 'confirm',
      disabled: loading,
      action: handleSubmit(formData => {
        markAsWon({
          variables: {
            input: {
              id: quoteId,
              status: 'won',
              shipmentNumber: formData?.shipmentNumber,
            },
          },
          onCompleted(data) {
            const { updateQuote } = data || {}
            const { errors, organizationQuote } = updateQuote || {}
            if (errors?.length) {
              openToast({
                toastMessage: 'Something went wrong. Please try again.',
                toastType: 'alert',
              })
              trackEvent('Quotes Tool', 'MARK_AS_WON_ERROR', {
                errors,
                formData,
                quoteId,
              })
            } else {
              onClose()
              openToast({
                toastMessage: 'Quote status updated.',
              })
              trackEvent('Quotes Tool', 'MARK_AS_WON_SUCCESS', {
                organizationQuote,
                formData,
                quoteId,
              })
            }
          },
          onError: apolloErrors => {
            openToast({
              toastMessage: 'Something went wrong. Please try again.',
              toastType: 'alert',
            })
            trackEvent('Quotes Tool', 'RUNTIME_ERROR', {
              apolloErrors,
              quoteId,
            })
          },
        })
      }),
    },
  ]

  return (
    <ModalBase
      open={open}
      title='Provide Shipment #'
      onClose={onClose}
      actions={actions}
      maxWidth='xs'
      dataTest='MarkAsWonModal'>
      <Typography variant='body1' marginBottom={3}>
        To mark as won, provide the shipment number.
      </Typography>
      <ControlledTextField
        control={control}
        name='shipmentNumber'
        TextFieldProps={{
          error: !!errors?.shipmentNumber?.message,
          helperText: errors?.shipmentNumber?.message || '',
          name: 'shipmentNumber',
          label: 'Shipment #',
          dataTest: 'shipmentNumberTextField',
        }}
      />
    </ModalBase>
  )
}
