import React, { useCallback, useEffect, useState } from 'react'
import {
  IContractRfpLaneVolumeFrequencies,
  IContractRfpVersionLaneRateRateTypes,
  IContractRfpVersionLaneSortBy,
  ICountrySubdivisionCodes,
  IDropCombinations,
  ISortOrder,
} from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import { AccessRestrictedModal } from 'dpl'
import DataGrid from 'dpl/components/DataGrid'
import { makeStyles, StringParam } from 'dpl/core'
import { useQueryParams } from 'dpl/hooks'
import { getGraphqlAfterCursor } from 'dpl/utils/graphql/getGraphqlAfterCursor'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags/src/FlagsContext'
import compact from 'lodash/compact'
import { DeleteLaneModal } from '../DeleteLaneModal'
import { EditLaneSideNav } from '../EditLaneSideNav'
import { useCurrentVersionLanesQuery } from './graphql/CurrentVersionLanes'
import { FILTERS_CONFIG, getColumns, SORT_OPTIONS, SORT_OPTIONS_DEPRECATED } from './grid'
import { IFilters, IPagination, ISorting } from './types'
import { convertData, PAGE_RANGES } from './utils'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))

export interface ILaneListProps {
  contractRfpVersionId: string
  readOnly: boolean
  rfpId: string
}
const POLL_INTERVAL = 4000

export function LaneList({ contractRfpVersionId, readOnly, rfpId }: ILaneListProps) {
  const classes = useStyles()
  const { isFlagEnabled } = useFlagsContext()
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled
  const isDynamicVolumeEnabled = isFlagEnabled('rfp_dynamic_volume')
  const sortOptions = isDynamicVolumeEnabled ? SORT_OPTIONS : SORT_OPTIONS_DEPRECATED

  const [filters, setFilters] = useState<IFilters>({
    laneId: null,
    location: {
      originCity: null,
      originStateCode: null,
      destinationCity: null,
      destinationStateCode: null,
    },
    equipmentType: [],
    dropType: undefined,
    volume: {
      blank: false,
      max: null,
      min: null,
      value: null,
    },
    teamRequired: null,
    rate: {
      blank: false,
      max: null,
      min: null,
      value: null,
    },
  })
  const [sorting, setSorting] = useState<ISorting>({
    sortBy: IContractRfpVersionLaneSortBy.import_order,
    sortOrder: ISortOrder.asc,
  })
  const [pagination, setPagination] = useState<IPagination>({
    page: 0,
    pageSize: PAGE_RANGES[0],
  })
  const { equipmentType, location, rate, teamRequired, volume } = filters
  const { page, pageSize } = pagination
  const { sortBy, sortOrder } = sorting
  const { data, loading, startPolling, stopPolling } = useCurrentVersionLanesQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractRfpVersionId,
      first: pageSize,
      after: getGraphqlAfterCursor({ pageNumber: page, pageSize }),
      pickupCity: location?.originCity,
      pickupStateCode: location?.originStateCode as ICountrySubdivisionCodes,
      deliveryCity: location?.destinationCity,
      deliveryStateCode: location?.destinationStateCode as ICountrySubdivisionCodes,
      equipments: equipmentType,
      isTeam: teamRequired || undefined,
      volumeFrequency: (volume?.value as IContractRfpLaneVolumeFrequencies) || undefined,
      volumeRangeStart: volume?.min ?? undefined,
      volumeRangeEnd: volume?.max ?? undefined,
      hasVolume: volume?.blank || undefined,
      rateRangeStart: rate?.min ?? undefined,
      rateRangeEnd: rate?.max ?? undefined,
      hasRate: rate?.blank || undefined,
      searchExternalIds: filters.laneId || undefined,
      ratetypes: filters.rate?.value
        ? [filters.rate.value as IContractRfpVersionLaneRateRateTypes]
        : undefined,
      dropCombinations: (filters.dropType as IDropCombinations[]) || undefined,
      sortBy,
      sortOrder,
      displayId: rfpId,
    },
  })
  const { createToggleHandler, modalState } = useModalState({
    restrictedModal: false,
  })
  const { contractRfpVersionLanes, contractRfps } = data || {}
  const { nodes: laneNodes = [], totalCount } = contractRfpVersionLanes || {}
  const lanes = compact(laneNodes)

  const { nodes: rfpNodes = [] } = contractRfps || {}
  const [contractRfp] = rfpNodes || []
  const { currentVersion } = contractRfp || {}
  const { rategenMetadataSummary } = currentVersion || {}
  const { numPendingLanes } = rategenMetadataSummary || {}

  /*
  Start polling CurrentVersionLanes query until there are no lanes with pending rate generation.
  */
  useEffect(() => {
    if (numPendingLanes && isRfpRateGenEnabled) {
      startPolling(POLL_INTERVAL)
    } else {
      stopPolling()
    }
  }, [numPendingLanes, stopPolling, startPolling, isRfpRateGenEnabled])

  const handleFilterChange = useCallback((newFilters: Partial<IFilters>) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }))
    setPagination(prevPagination => ({
      ...prevPagination,
      page: 0,
    }))
  }, [])

  const handleSortChange = useCallback((newSorting: Partial<ISorting>) => {
    setSorting(prevSorting => ({
      ...prevSorting,
      ...newSorting,
    }))
    setPagination(prevPagination => ({
      ...prevPagination,
      page: 0,
    }))
  }, [])

  const handlePaginationChange = useCallback((newPagination: IPagination) => {
    setPagination(newPagination)
  }, [])

  const handleFilterOrSortChange = useCallback(
    params => {
      const { dropType, equipmentType, laneId, location, rate, sort, teamRequired, volume } = params

      const [sortBy, sortOrder] = sort.split(':') as [IContractRfpVersionLaneSortBy, ISortOrder]

      handleFilterChange({ laneId, location, equipmentType, dropType, volume, teamRequired, rate })
      handleSortChange({ sortBy, sortOrder })
    },
    [handleFilterChange, handleSortChange]
  )

  const [{ deleteLaneId, editLaneId }, setQueryParams] = useQueryParams(
    {
      editLaneId: StringParam,
      deleteLaneId: StringParam,
    },
    {
      updateType: 'replaceIn',
    }
  )
  const columns = getColumns({
    isDynamicVolumeEnabled,
    readOnly,
    onEditLane: lane => {
      setQueryParams({
        editLaneId: lane.externalId,
      })
    },
    onDeleteLane: lane => {
      setQueryParams({
        deleteLaneId: lane.externalId,
      })
    },
    onRestrictAccess: createToggleHandler('restrictedModal', true),
    userPermissions,
  })

  return (
    <>
      <div className={classes.root}>
        <DataGrid
          dataTest='lanes-list'
          loading={loading ?? true}
          filtersConfig={FILTERS_CONFIG}
          sortOptions={sortOptions}
          visibleFiltersLimit={2}
          defaultFilterValues={filters}
          defaultSortValue={sorting.sortBy}
          rows={convertData(lanes)}
          rowCount={totalCount}
          columns={columns}
          pageSizeOptions={PAGE_RANGES}
          onPaginationModelChange={handlePaginationChange}
          onFilterOrSortChange={handleFilterOrSortChange}
          paginationMode='server'
          paginationModel={pagination}
        />
      </div>
      {editLaneId && (
        <EditLaneSideNav
          contractRfpId={contractRfp?.id || ''}
          contractRfpVersionId={contractRfpVersionId}
          externalId={editLaneId}
          onClose={() => setQueryParams({ editLaneId: null })}
          readOnly={readOnly}
        />
      )}
      {deleteLaneId && (
        <DeleteLaneModal
          contractRfpVersionId={contractRfpVersionId}
          externalId={deleteLaneId}
          onClose={() => setQueryParams({ deleteLaneId: null })}
        />
      )}
      {modalState.restrictedModal && (
        <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
      )}
    </>
  )
}
