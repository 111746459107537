import { IRuleTypeEnum } from '#types/rates'
import { DISPLAY } from 'dpl/constants'

export interface IGetAdjustmentRuleLabelProps {
  adjustmentValue: Nullable<number>
  reason: Nullable<string>
  ruleAction: Nullable<string>
}

/**
 * Returns the label for an adjustment rule based on the adjustment value, reason, and rule action.
 *
 * @returns {string} The label for the adjustment rule
 *
 * @example getAdjustmentRuleLabel({ adjustmentValue: 60, reason: 'Hurricane Maria', ruleAction: 'baseline_adjustment' }) // '60th Percentile for Hurricane Maria'
 * @example getAdjustmentRuleLabel({ adjustmentValue: 0.02, reason: 'Hurricane Maria', ruleAction: 'additive_adjustment' }) // '+2% Shift for Hurricane Maria'
 * @example getAdjustmentRuleLabel({ adjustmentValue: -0.03, reason: 'Hurricane Maria', ruleAction: 'additive_adjustment' }) // '-3% Shift for Hurricane Maria'
 */
export const getAdjustmentRuleLabel = ({
  adjustmentValue,
  reason,
  ruleAction,
}: IGetAdjustmentRuleLabelProps): string => {
  if (!adjustmentValue) {
    return DISPLAY.empty
  }

  if (ruleAction === IRuleTypeEnum.base) {
    return `${adjustmentValue}th Percentile for ${reason}`
  }

  const percentage = adjustmentValue * 100
  return `${percentage < 0 ? '' : '+'}${percentage}% Shift for ${reason}`
}
