import React, { useMemo, useCallback } from 'react'
import { RateDifference } from '#components/RateDifference'
import { StopListModal } from '#components/StopListModal'
import { currencyFormatter } from '#utils/currencyFormatter'
import { getStopsWithRelativeSequence } from '#utils/getStopsWithRelativeSequence'
import { leadTimeFormatter } from '#utils/leadTimeFormatter'
import { minMaxTempFormatter } from '#utils/minMaxTempFormatter'
import { weightFormatter } from '#utils/weightFormatter'
import { Avatar } from 'dpl/components/Avatar'
import { IconButton } from 'dpl/components/IconButton'
import SidePanel, { ISidePanelProps } from 'dpl/components/SidePanel'
import { IFunctionalTagProps } from 'dpl/components/Tag'
import { Tooltip } from 'dpl/components/Tooltip'
import { FORMATS } from 'dpl/constants/datetime'
import { DISPLAY } from 'dpl/constants/display'
import { Box, Divider, Link, makeStyles, Typography } from 'dpl/core'
import { EditIcon } from 'dpl/icons'
import BotIcon from 'dpl/icons/build/BotIcon'
import CustomerIcon from 'dpl/icons/build/CustomerIcon'
import DryVanIcon from 'dpl/icons/build/DryVanIcon'
import LaneArrowIcon from 'dpl/icons/build/LaneArrowIcon'
import NumberIcon from 'dpl/icons/build/NumberIcon'
import ReeferIcon from 'dpl/icons/build/ReeferIcon'
import ShipmentsIcon from 'dpl/icons/build/ShipmentsIcon'
import { brandColors } from 'dpl/theme/colors'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags'
import startCase from 'lodash/startCase'
import moment from 'moment-timezone'
import { EditQuoteDetailsModal } from '../EditQuoteDetailsModal'
import { MarkAsWonModal } from '../MarkAsWonModal'
import { StatusSelectTag } from '../StatusSelectTag'
import { COMMUNICATION_METHOD_ICONS } from '../constants'
import { useOrganizationQuoteQuery } from '../graphql/OrganizationQuoteQuery'
import { SpotQuoteDetails } from './components/SpotQuoteDetails'

interface QuoteDetailProps extends Pick<ISidePanelProps, 'onClose'> {
  id: string
}

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(2, -3),
  },
  editButton: {
    '& svg': {
      color: brandColors.skyBlue6,
    },
  },
}))

export function QuoteDetail({ id, onClose }: QuoteDetailProps) {
  const { isFlagEnabled } = useFlagsContext()
  const isQuotesRulesVisEnabled = isFlagEnabled('quotes_rules_visibility')

  const { data } = useOrganizationQuoteQuery({
    variables: {
      id: id!,
    },
    skip: !id,
  })

  const { organizationQuote: quote } = data || {}
  const {
    communicationMethod,
    createdAt,
    createdBy,
    customerReference,
    deliveryStartAt,
    equipment,
    isDeliveryDropTrailer,
    isPickupDropTrailer,
    organizationShipper,
    pickupStartAt,
    shipmentNumber,
    source,
    status,
    stops,
  } = quote || {}
  const { reeferTempMax, reeferTempMin } = quote?.pricingRequestParameters ?? {}
  const { estimatedCarrierRate = 0, margin = 0 } = quote?.pricingResponse ?? {}
  const numStops = stops?.length ?? 0
  const {
    city: originCity,
    postalCode: originPostalCode,
    startTimeZone: originTimezone,
    stateCode: originStateCode,
  } = stops?.[0] ?? {}
  const {
    city: destinationCity,
    postalCode: destinationPostalCode,
    startTimeZone: destinationTimezone,
    stateCode: destinationStateCode,
  } = stops?.[Number(stops?.length) - 1] ?? {}
  const isReefer = equipment?.key === '53_reefer'
  const hasMultiStops = numStops > 2
  const numIntermediateStops = hasMultiStops ? numStops - 2 : 0
  const stopList = getStopsWithRelativeSequence(stops ?? [])

  const pickupAppointment = moment
    .tz(pickupStartAt, originTimezone || '')
    .format(FORMATS.monthDayYearTimeFormat12hUppercase)
  const deliveryAppointment = moment
    .tz(deliveryStartAt, destinationTimezone || '')
    .format(FORMATS.monthDayYearTimeFormat12hUppercase)

  const advancedData = useMemo(() => {
    if (!quote?.pricingRequestParameters) return ''

    const { assignmentLeadTimeMinutes, creationLeadTimeMinutes, isTeam, palletCount, weight } =
      quote?.pricingRequestParameters ?? {}
    const info = []

    if (weight) {
      info.push(`Weight: ${weightFormatter(weight)}`)
    }

    if (creationLeadTimeMinutes) {
      info.push(`Creation Lead Time: ${leadTimeFormatter(creationLeadTimeMinutes)}`)
    }

    if (assignmentLeadTimeMinutes) {
      info.push(`Assignment Lead Time: ${leadTimeFormatter(assignmentLeadTimeMinutes)}`)
    }

    if (palletCount) {
      info.push(`# of Pallets: ${palletCount}`)
    }

    if (isTeam) {
      info.push('Team Required')
    }

    if (isDeliveryDropTrailer && isPickupDropTrailer) {
      info.push('Drop Trailer: Pickup & Delivery')
    }

    if (isPickupDropTrailer && !isDeliveryDropTrailer) {
      info.push('Drop Trailer: Pickup')
    }

    if (isDeliveryDropTrailer && !isPickupDropTrailer) {
      info.push('Drop Trailer: Delivery')
    }

    return info.join(' · ')
  }, [quote?.pricingRequestParameters, isDeliveryDropTrailer, isPickupDropTrailer])

  const CommunicationIcon = COMMUNICATION_METHOD_ICONS[communicationMethod ?? '']
  const communicationMethodLabel = startCase(communicationMethod ?? '')
  const classes = useStyles()

  const estimatedCarrierRateValue = estimatedCarrierRate ?? 0
  const marginValue = margin ?? 0

  const {
    modalState: {
      editQuoteDetailsModal: isEditQuoteDetailsModalOpen,
      stopListModal: isStopListModalOpen,
      wonStatusModal: isWonStatusModalOpen,
    },
    toggleModal,
  } = useModalState({
    wonStatusModal: false,
    editQuoteDetailsModal: false,
    stopListModal: false,
  })
  const wonStatusSelectHandler: IFunctionalTagProps['onSelect'] = useCallback(
    ({ value }) => {
      if (value === 'won') {
        toggleModal('wonStatusModal')
      }
    },
    [toggleModal]
  )
  const closeWonStatusModal = useCallback(() => toggleModal('wonStatusModal', false), [toggleModal])
  const toggleEditQuoteDetailsModal = useCallback(() => {
    toggleModal('editQuoteDetailsModal')
  }, [toggleModal])
  const toggleStopListModal = useCallback(() => {
    toggleModal('stopListModal')
  }, [toggleModal])

  const isApiGeneratedQuote = source === 'spot_quotes_api'
  const sourceLabel = isApiGeneratedQuote ? 'Spot Quote API' : 'Spot Rates Tool'

  return (
    <SidePanel
      open={Boolean(quote)}
      menuItems={<StatusSelectTag status={status} onSelect={wonStatusSelectHandler} />}
      onClose={onClose}
      anchor='right'
      title={`Quote ID: ${id}`}>
      <MarkAsWonModal open={isWonStatusModalOpen} onClose={closeWonStatusModal} quoteId={id} />
      <EditQuoteDetailsModal
        open={isEditQuoteDetailsModalOpen}
        onClose={toggleEditQuoteDetailsModal}
        quote={quote}
      />
      <StopListModal open={isStopListModalOpen} onClose={toggleStopListModal} stops={stopList} />

      <Box
        borderTop={`1px solid ${brandColors.coolGray2}`}
        borderBottom={`1px solid ${brandColors.coolGray2}`}
        bgcolor={brandColors.coolGray0}
        display='flex'
        mx={-3}
        mt={-3}
        px={3}
        py={1.5}
        gap={2.5}
        justifyContent='space-between'
        alignItems='center'>
        <Box display='flex' gap={2.5} alignItems='center'>
          <Box display='flex' gap={0.75} flexDirection='column' data-test='customer-box'>
            <Typography variant='caption' color='textSecondary' component='div'>
              Customer
            </Typography>
            <Box display='flex' gap={0.5} alignItems='center'>
              <CustomerIcon size='xlarge' color='coolGray5' />
              <Typography variant='body1'>{organizationShipper?.name ?? ''}</Typography>
            </Box>
          </Box>
          <Divider orientation='vertical' flexItem />
          <Box display='flex' gap={0.75} flexDirection='column' data-test='customer-ref-box'>
            <Typography variant='caption' color='textSecondary' component='div'>
              External Ref #
            </Typography>
            <Box display='flex' gap={0.5} alignItems='center'>
              <NumberIcon size='xlarge' color='coolGray5' />
              <Typography variant='body1'>{customerReference || DISPLAY.empty}</Typography>
            </Box>
          </Box>
          <Divider orientation='vertical' flexItem />
          <Box display='flex' gap={0.75} flexDirection='column' data-test='shipment-num-box'>
            <Typography
              variant='caption'
              color={shipmentNumber ? 'textSecondary' : brandColors.gray2}
              component='div'>
              Shipment #
            </Typography>
            <Box display='flex' gap={0.5} alignItems='center'>
              <ShipmentsIcon size='xlarge' color={shipmentNumber ? 'coolGray5' : 'gray2'} />
              <Typography variant='body1'>{shipmentNumber || DISPLAY.empty}</Typography>
            </Box>
          </Box>
        </Box>

        <IconButton
          Icon={EditIcon}
          className={classes.editButton}
          color='secondary'
          onClick={toggleEditQuoteDetailsModal}
          dataTest='edit-quote-button'
        />
      </Box>
      <Box display='flex' gap={1} alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center' gap={1.5} py={2}>
          <Avatar
            name={createdBy?.user.fullName ?? ''}
            Icon={isApiGeneratedQuote ? <BotIcon color='coolGray8' size='large' /> : null}
            variant='circular'
            size='medium'
            hideTooltip
          />
          <Typography variant='body2'>
            {isApiGeneratedQuote ? 'Imabot' : createdBy?.user.fullName}
          </Typography>
          <Typography variant='caption' color='textSecondary'>
            {moment(createdAt).format(FORMATS.readableFormat)}
          </Typography>
          {CommunicationIcon && (
            <Tooltip title={communicationMethodLabel} placement='top'>
              <span>
                <CommunicationIcon size='large' color='coolGray5' />
              </span>
            </Tooltip>
          )}
        </Box>
        <Box textAlign='right'>
          <Typography variant='caption' color={brandColors.coolGray5}>
            Source
          </Typography>
          <Typography variant='body2'>{sourceLabel}</Typography>
        </Box>
      </Box>

      <Box display='flex' alignItems='center' gap={1.5} pb={1} flexWrap='wrap'>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' alignItems='flex-end' gap={0.5}>
            <Typography variant='h4' component='span'>
              {originCity}, {originStateCode}
            </Typography>
            <Typography variant='subtitle2' color='textSecondary' component='span'>
              ({originPostalCode})
            </Typography>
          </Box>

          <Typography variant='subtitle2' color='textSecondary' marginTop={0.5}>
            {pickupAppointment}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <LaneArrowIcon size='xlarge' />
          {hasMultiStops && (
            <Link
              variant='caption'
              color='textSecondary'
              underline='always'
              component='button'
              onClick={toggleStopListModal}>
              {numIntermediateStops} Stops
            </Link>
          )}
        </Box>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' alignItems='flex-end' gap={0.5}>
            <Typography variant='h4' component='span'>
              {destinationCity}, {destinationStateCode}
            </Typography>
            <Typography variant='subtitle2' color='textSecondary' component='span'>
              ({destinationPostalCode})
            </Typography>
          </Box>

          <Typography variant='subtitle2' color='textSecondary' marginTop={0.5}>
            {deliveryAppointment}
          </Typography>
        </Box>

        {isReefer ? (
          <Box display='flex' alignItems='center' gap={0.5}>
            <Tooltip title='Reefer' placement='top'>
              <span>
                <ReeferIcon size='xlarge' data-test='reefer-icon' />
              </span>
            </Tooltip>
            <Typography variant='subtitle2' color='textSecondary'>
              (
              {minMaxTempFormatter({
                minTemp: reeferTempMin ?? 0,
                maxTemp: reeferTempMax ?? 0,
              })}
              )
            </Typography>
          </Box>
        ) : (
          <Tooltip title='Dry Van' placement='top'>
            <span>
              <DryVanIcon size='xlarge' data-test='dry-van-icon' />
            </span>
          </Tooltip>
        )}
      </Box>
      <Typography variant='subtitle2' color='textSecondary'>
        {advancedData}
      </Typography>
      <Divider className={classes.divider} />

      {!isQuotesRulesVisEnabled && (
        <>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h3' fontWeight={500}>
              Spot Quote
            </Typography>
            <Typography variant='h3' fontWeight={500}>
              {currencyFormatter(estimatedCarrierRateValue + marginValue)}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='space-between' py={1.5}>
            <Typography variant='subtitle2' color='textSecondary'>
              Est. Carrier Rate (including fuel)
            </Typography>
            <Typography variant='subtitle2' color='textSecondary'>
              {currencyFormatter(estimatedCarrierRateValue)}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='subtitle2' color='textSecondary'>
              Est. Margin
            </Typography>
            <Typography variant='subtitle2' color='textSecondary'>
              <RateDifference value={marginValue} dataTest='margin-value' />
            </Typography>
          </Box>
        </>
      )}
      {isQuotesRulesVisEnabled && (
        <SpotQuoteDetails
          carrierRateValue={estimatedCarrierRateValue}
          marginValue={marginValue}
          rateAdjustments={null}
          spotCostModel={null}
          overrideReason={null}
        />
      )}
    </SidePanel>
  )
}
