import CaFlagSvg from './assets/ca.svg?react'
import MxFlagSvg from './assets/mx.svg?react'
import UsFlagSvg from './assets/us.svg?react'
import { IGeocodingAllowedCountryEnum } from './types'

export const COUNTRY_FLAGS: Record<IGeocodingAllowedCountryEnum, typeof UsFlagSvg> = {
  US: UsFlagSvg,
  CA: CaFlagSvg,
  MX: MxFlagSvg,
}

export const COUNTRY_LABELS: Record<IGeocodingAllowedCountryEnum, string> = {
  US: 'United States',
  CA: 'Canada',
  MX: 'Mexico',
}
