import { IDynamicVolumeSchema } from '../schema'
import { getMonthYearRange } from './getMonthYearRange'

interface IGetDateVolumeRangeProps {
  startDate: string
  endDate: string
  volume: number
}

export const getDateVolumeRange = ({
  endDate,
  startDate,
  volume,
}: IGetDateVolumeRangeProps): IDynamicVolumeSchema[] => {
  const months = getMonthYearRange({ startDate, endDate })
  const dateVolumeRange = months.map(month => ({
    date: month,
    volume,
  }))

  return dateVolumeRange
}

interface ITransformMonthYearRangeToQueryFormattedProps {
  monthYearRange: IDynamicVolumeSchema[]
}

export const transformMonthYearRangeToQueryFormatted = ({
  monthYearRange = [],
}: ITransformMonthYearRangeToQueryFormattedProps) => {
  return monthYearRange.map(({ date, volume }) => ({
    month: date,
    volume,
  }))
}
