import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useAnalytics } from '#hooks'
import { IContractRfpVersionLaneRateRateTypes } from '#types/graphqlTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import { brandColors, IFormModalProps } from 'dpl'
import SidePanel from 'dpl/components/SidePanel'
import { Button, Divider, makeStyles } from 'dpl/core'
import { IRfpRatesSchema, RfpRatesSchema } from '../../schema'
import { getLaneRatesInput } from '../../utils'
import { EditLaneInformationSideNav } from '../EditLaneInformationSideNav'
import { Estimate, LaneInformation } from './components'
import { isCustomRate } from './components/Estimate/utils'
import { useEditLaneQuery } from './graphql/EditLane'
import { useUpdateRatesMutation } from './graphql/UpdateRates'

export interface IEditLaneSideNavProps extends Pick<IFormModalProps, 'onClose'> {
  externalId: string
  contractRfpVersionId: string
  readOnly: boolean
  contractRfpId: string
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 72,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(3),
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: brandColors.white,
    zIndex: 1,
  },
}))

export function EditLaneSideNav({
  contractRfpId,
  contractRfpVersionId,
  externalId,
  onClose,
  readOnly,
}: IEditLaneSideNavProps) {
  const classes = useStyles()
  const { trackEvent } = useAnalytics()
  const [isEditingInformation, setIsEditingInformation] = useState<boolean>(false)
  const [apiError, setApiError] = useState<string>('')
  const [updateRates] = useUpdateRatesMutation()
  const { data } = useEditLaneQuery({
    variables: {
      externalId,
      contractRfpVersionId,
    },
    fetchPolicy: 'network-only',
  })
  const { contractRfpVersionLanes } = data || {}
  const { nodes = [] } = contractRfpVersionLanes || {}
  const [lane] = nodes || []

  const methods = useForm<IRfpRatesSchema>({
    resolver: yupResolver(RfpRatesSchema),
    defaultValues: {},
  })
  const defaultIsCustomRate = isCustomRate({
    shipperRate: lane?.rate?.shipperRate,
    fuelRate: lane?.rate?.fuelRatePerMile,
    marginAmount: lane?.rate?.marginAmount,
    rateType: lane?.rate?.rateType as IContractRfpVersionLaneRateRateTypes,
  })

  useEffect(() => {
    if (lane) {
      methods.reset({
        rateType: lane?.rate?.rateType ?? 'all_in',
        carrierRate: lane?.rate?.carrierRate,
        customRate: lane?.rate?.shipperRate,
        marginAmount: lane?.rate?.marginAmount || null,
        fuelRate: lane?.rate?.fuelRatePerMile,
        marginPercentage: lane?.rate?.marginPercentage ? lane.rate.marginPercentage * 100 : null,
        shipperRate: defaultIsCustomRate ? lane.rate?.shipperRate : null,
      })
    }
  }, [lane])

  const { handleSubmit } = methods

  const handleRateSubmit = handleSubmit(async data => {
    const input = {
      contractRfpVersionId,
      id: lane?.id,
      ...getLaneRatesInput(data),
    }

    try {
      const { data: apiData } = await updateRates({
        variables: {
          input,
        },
        refetchQueries: ['EditLane'],
      })
      const { upsertContractRfpVersionLane } = apiData || {}
      const { errors } = upsertContractRfpVersionLane || {}

      if (errors?.length) {
        setApiError(errors[0].message)

        trackEvent('Contract RFPs', 'UPDATE_LANE_RATE_ERROR', {
          variables: input,
          errors,
        })
        return
      }

      trackEvent('Contract RFPs', 'UPDATE_LANE_RATE_SUCCESS', {
        variables: input,
      })

      onClose()
    } catch (error) {
      setApiError(error.message)

      trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
        variables: input,
        apolloErrors: [error],
      })
    }
  })

  if (!lane) return null

  return (
    <>
      {!isEditingInformation && (
        <SidePanel onClose={onClose} title={`Edit Lane #${externalId}`} anchor='right'>
          <div className={classes.root}>
            <LaneInformation
              onEdit={() => setIsEditingInformation(true)}
              lane={lane}
              readOnly={readOnly}
            />
            <Divider />
            <FormProvider {...methods}>
              <Estimate lane={lane} apiError={apiError} readOnly={readOnly} />
            </FormProvider>
          </div>
          {!readOnly && (
            <div className={classes.actions}>
              <Button variant='text' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit' onClick={handleRateSubmit}>
                Save
              </Button>
            </div>
          )}
        </SidePanel>
      )}
      {isEditingInformation && (
        <EditLaneInformationSideNav
          contractRfpVersionId={contractRfpVersionId}
          externalId={externalId}
          contractRfpId={contractRfpId}
          onClose={() => setIsEditingInformation(false)}
        />
      )}
    </>
  )
}
