import { ILatLonPosition } from '../types'
import { getArcGeoJSON } from './getArcGeoJson'

/**
 * @note This only uses two points on a map, and doesn't leverage any routing API
 */
export const getCurvyLine = (
  start: ILatLonPosition,
  stop: ILatLonPosition
): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  const curveFeatures = getArcGeoJSON(start, stop)
  return {
    type: 'FeatureCollection',
    features: [curveFeatures],
  }
}
