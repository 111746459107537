import React, { PropsWithChildren, useEffect } from 'react'
import { Box, Divider, Grid, makeStyles, Typography } from 'dpl/core'
import SvgIcon from 'dpl/icons/components/SvgIcon'
import ControlledSwitch from 'forms/components/ControlledSwitch'
import { useAdjustmentRuleFormContext } from '../../../../hooks'

export interface IFactorSelectionProps {
  Icon: typeof SvgIcon
  /**
   * Text shown next to Icon
   * @example 'Pickup Location'
   */
  label: string
  /**
   * Name of factor field in form
   * @example 'factors.pickupLocation'
   */
  factorName: string
  /**
   * Function that is called when switch value is changed
   */
  onChange?: (checked: boolean, factorName: string) => void
  /**
   * If true, this means that factor is enabled and factor inputs are rendered.
   */
  showFactor: boolean
  /**
   * Disables switch when true
   * @default false
   */
  disabled?: boolean
}

const useFactorSwitchStyles = makeStyles(theme => ({
  switch: {
    // Align Switch to right edge of side panel
    marginRight: theme.spacing(-1.5),
    '& label': {
      marginRight: 0,
    },
  },
}))

export function FactorSwitch({
  Icon,
  children,
  disabled = false,
  factorName,
  label,
  showFactor,
}: PropsWithChildren<IFactorSelectionProps>) {
  const classes = useFactorSwitchStyles()
  const { control, setValue } = useAdjustmentRuleFormContext()
  const switchName = `${factorName}.includeFactor`

  /**
   * Ensures that if a factor switch is disabled, its form value is set to false.
   * This handles the edge case if a user has already selected factors for a base rule and
   * then goes back to edit the rate strategy to be an additive rule. Setting the value here prevents
   * a factor from getting disabled in the checked position.
   */
  useEffect(() => {
    if (disabled) {
      setValue(switchName, false)
    }
  }, [setValue, switchName, disabled])

  return (
    <Grid container alignItems='center' columnSpacing={1.5}>
      <Grid item>
        <Box display='flex'>
          <Icon color='skyBlue6' size='xlarge' />
        </Box>
      </Grid>
      <Grid item flex={1}>
        <Typography variant='body2' id={`${switchName}-label`}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <ControlledSwitch
          name={switchName}
          control={control}
          SwitchProps={{
            'aria-labelledby': `${switchName}-label`,
            dataTest: `${factorName}-switch`,
            className: classes.switch,
            disabled,
          }}
        />
      </Grid>
      <Grid item xs={12} paddingY={1}>
        <Divider />
      </Grid>
      {showFactor && (
        <Grid item xs={12}>
          <Box padding={3} paddingTop={2}>
            {children}
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
