import React, { useCallback } from 'react'
import { Button, makeStyles } from 'dpl/core'
import { AddIcon, RemoveIcon, SwapIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme/colors'

const useStopButtonStyles = makeStyles(theme => ({
  firstStopButton: {
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1.5),
      padding: theme.spacing(1),
      borderRadius: 2,
    },
  },
  swapButton: {
    backgroundColor: brandColors.coolGray1,
    color: brandColors.coolGray5,
    ' & svg': {
      transform: 'rotate(90deg)',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1.5),
      padding: theme.spacing(1),
      borderRadius: 2,
    },
  },
  removeButton: {
    color: brandColors.coolGray5,
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1.5),
      padding: theme.spacing(1),
      borderRadius: 2,
    },
  },
}))

interface IStopButtonProps {
  /**
   * Index of current stop in field array
   */
  index: number
  /**
   * Number of stops in field array
   */
  stopFieldsLength: number
  /**
   * Add stop field
   */
  onAdd: () => void
  /**
   * Remove current stop field
   */
  onRemove: (index: number) => void
  /**
   * Swaps origin and destination locations
   */
  onSwap: () => void
}

/**
 * This is the button shown next to the stop location. This component determines whether the button
 * should add, remove, or swap stops.
 */
export function StopButton({ index, onAdd, onRemove, onSwap, stopFieldsLength }: IStopButtonProps) {
  const classes = useStopButtonStyles()
  const isFirstStop = index === 0
  const isLastStop = index === stopFieldsLength - 1

  const removeLocationHandler = useCallback(() => {
    onRemove(index)
  }, [index, onRemove])

  if (isFirstStop) {
    return stopFieldsLength < 7 ? (
      <Button
        color='secondary'
        data-test='add-location-button'
        onClick={onAdd}
        className={classes.firstStopButton}>
        <AddIcon size='large' />
      </Button>
    ) : null
  }

  if (stopFieldsLength === 2 && isLastStop) {
    return (
      <Button
        className={classes.swapButton}
        color='info'
        data-test='swap-location-button'
        onClick={onSwap}>
        <SwapIcon size='large' />
      </Button>
    )
  }

  return (
    <Button
      className={classes.removeButton}
      variant='text'
      data-test='remove-location-button'
      onClick={removeLocationHandler}>
      <RemoveIcon size='large' />
    </Button>
  )
}
