import React, { memo, useMemo } from 'react'
import { getCarrierRatePercentileLabel } from '#components/RatesEstimatedCarrierRateAccordion/utils'
import { ICostModel } from '#types/costModel'
import Box from 'dpl/core/Box'

export interface ICarrierRatePercentileDescriptionProps {
  baseCostPercentile: number
  costModel: Nullable<ICostModel>
  defaultCostPercentile: number
  hasAppliedRules: boolean
  isAboveMaxCostLimit: boolean
  isBelowMinCostLimit: boolean
  isSuggestedOverridden: boolean
  rulesCumulativeAdditivePercentage: number
  sliderValue: number
  suggestedCostPercentile: number
  suggestedCostShiftPercentage: number
}

export const CarrierRatePercentileDescription = memo<ICarrierRatePercentileDescriptionProps>(
  ({
    baseCostPercentile,
    costModel,
    defaultCostPercentile,
    hasAppliedRules,
    isAboveMaxCostLimit,
    isBelowMinCostLimit,
    isSuggestedOverridden,
    rulesCumulativeAdditivePercentage,
    sliderValue,
    suggestedCostPercentile,
    suggestedCostShiftPercentage,
  }) => {
    const isOutsideOfCostLimits = useMemo(
      () => isAboveMaxCostLimit || isBelowMinCostLimit,
      [isAboveMaxCostLimit, isBelowMinCostLimit]
    )

    const percentileLabel = useMemo<string>(
      () =>
        getCarrierRatePercentileLabel({
          baseCostPercentile,
          costModel,
          defaultCostPercentile,
          hasAppliedRules,
          isOutsideOfCostLimits,
          isSuggestedOverridden,
          rulesCumulativeAdditivePercentage,
          sliderValue,
          suggestedCostPercentile,
          suggestedCostShiftPercentage,
        }),
      [
        baseCostPercentile,
        costModel,
        defaultCostPercentile,
        hasAppliedRules,
        isOutsideOfCostLimits,
        isSuggestedOverridden,
        rulesCumulativeAdditivePercentage,
        sliderValue,
        suggestedCostPercentile,
        suggestedCostShiftPercentage,
      ]
    )

    const showBelowMinTreatment = useMemo(() => {
      return !isSuggestedOverridden && isBelowMinCostLimit
    }, [isBelowMinCostLimit, isSuggestedOverridden])

    const showMaxReachedTreatment = useMemo(() => {
      return !isSuggestedOverridden && isAboveMaxCostLimit
    }, [isSuggestedOverridden, isAboveMaxCostLimit])

    const displayPercentileLabelCrossedOut = useMemo(() => {
      return showBelowMinTreatment || showMaxReachedTreatment
    }, [showBelowMinTreatment, showMaxReachedTreatment])

    return (
      <div>
        {showBelowMinTreatment && <span data-test='min-label'>10th </span>}
        {showMaxReachedTreatment && <span data-test='max-label'>90th </span>}
        <Box
          data-test='percentile-label'
          sx={{
            textDecoration: displayPercentileLabelCrossedOut ? 'line-through' : 'none',
          }}
          component='span'>
          {percentileLabel}
        </Box>
      </div>
    )
  }
)
