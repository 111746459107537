import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TrimbleMaps from '@trimblemaps/trimblemaps-js'
import * as GeoJSON from 'geojson'
import get from 'lodash/get'
import times from 'lodash/times'
import ReactDOMServer from 'react-dom/server'
import { v4 as uuid } from 'uuid'
import { PREVIOUS_LINE_PAINT } from '../../constants'
import { IMapPosition, IMapStopProps, IMapRouteGroup, WithId } from '../../types'
import {
  getTrimbleFitBounds,
  getGeoJSONLineSource,
  getDefaultPositionLayerStyle,
  IGetDefaultPositionLayerProps,
  getDefaultPositionShadowLayerStyle,
  getGeoJSONFeatureLineCollection,
  getGeoJSONFeaturePositionCollection,
  getGeoJSONPositionSource,
  getGeoJSONStopSource,
  getPositionSources,
  getStopMarkerIcon,
  getStopsLayer,
  getTrimbleMapRoute,
} from '../../utils'
import { getDecodedData, getPositionsWithShadow } from './utils'

const STOP_LAYER_ID = 'stops-layer'

interface IGetPopupOptions {
  /**
   * Optionally pass additional async map data to popup
   * @note This will be deprecated by TrimbleMaps query data
   */
  // TODO enable 'geocoding/search' request
  // mapFeatures?: IGeocodingSearchResult[]
  isLoading?: boolean
  onClose?: () => void
}

TrimbleMaps.APIKey = import.meta.env.VITE_TRIMBLE_TOKEN

const DEFAULT_CENTER = new TrimbleMaps.LngLat(-98.35, 39.5)

const DEFAULT_PROPS: Omit<TrimbleMaps.MapOptions, 'container'> = {
  center: DEFAULT_CENTER,
}

export interface IUseMapProps<
  TPosition extends WithId<IMapPosition> = WithId<IMapPosition>,
  TStop extends WithId<IMapStopProps> = WithId<IMapStopProps>,
  TLine extends WithId<IMapRouteGroup> = WithId<IMapRouteGroup>,
> extends Partial<Omit<TrimbleMaps.MapOptions, 'container' | 'style'>> {
  /**
   * disables routes outside of US
   */
  bordersOpen?: boolean
  /**
   * Adds a shadow layer effect for latest ping
   * @default true
   */
  shadowType?: 'latest' | 'all'
  /**
   * Use "New Wave" style for icons
   * @default false
   */
  isNewWave?: boolean
  /**
   * Fade positions from new to old
   * @default true
   */
  fadePositions?: boolean
  /**
   * @default TrimbleMaps.Common.Style.DATALIGHT
   */
  mapStyle?: TrimbleMaps.MapOptions['style']
  /**
   * Passed to <div /> or other element
   * @note This overrides TrimbleMaps.MapOptions['container'] for a stricter 'string' type.
   * @example 'my-map'
   * @default uuid()
   */
  container?: string
  /**
   * @default []
   * @example [{ coords: { lon: 123, lat: 123 }}]
   */
  stops?: TStop[]
  /**
   * @default []
   * @example [{ coords: { lon: 123, lat: 123 }}]
   */
  positions?: TPosition[]
  /**
   * @default []
   * @example [{ route: [{coords: { lon: 123, lat 123 }] }]
   */
  lines?: TLine[]
  /**
   * Optionally defines a specific source name, for debugging.
   * @default 'map-positions'
   */
  positionsSourceName?: string
  /**
   * Optionally defines a specific source name, for debugging.
   * @default 'map-lines'
   */
  linesSourceName?: string
  /**
   * Optionally defines a specific source name, for debugging
   * @default 'map-stops'
   */
  stopsSourceName?: string
  /**
   * Override Trimble Route options for rendering Stops
   * @default {}
   */
  routeOptions?: Partial<Omit<TrimbleMaps.RouteOptions, 'stops'>>
  /**
   * Displays a zoom/control comoponent in the top/right corner
   * @default false
   */
  enableNavigation?: boolean
  /**
   * @default {}
   */
  NavigationControlOptions?: {
    showCompass?: boolean
    showZoom?: boolean
    visualizePitch?: boolean
  }
  /**
   * Sets position of NavigationControl
   * @default 'bottom-right'
   */
  navigationPosition?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
  /**
   * Returns a JSX Element with the rendered popup
   * @example ({ coords }) => <div>{coords.latitude} {coords.longitude}</div>
   */
  getPositionPopup?: (
    selectedPosition: GeoJSON.GeoJsonProperties | null,
    options?: IGetPopupOptions
  ) => JSX.Element
  /**
   * Returns a JSX Element with the rendered popup
   * @example ({ coords }) => <div>{coords.latitude} {coords.longitude}</div>
   */
  getStopPopup?: (
    selectedStop: GeoJSON.GeoJsonProperties | null,
    options?: IGetPopupOptions
  ) => JSX.Element
  onMouseEnterPosition?: (
    event: TrimbleMaps.MapMouseEvent & {
      features?: TrimbleMaps.GeoJSONFeature[] | undefined
    } & TrimbleMaps.EventData,
    position: TPosition | null
  ) => void
  onSelectPosition?: (
    event: TrimbleMaps.MapMouseEvent & {
      features?: TrimbleMaps.GeoJSONFeature[] | undefined
    } & TrimbleMaps.EventData,
    position: TPosition | null
  ) => void
  onMouseLeavePosition?: (
    event: TrimbleMaps.MapMouseEvent & {
      features?: TrimbleMaps.GeoJSONFeature[] | undefined
    } & TrimbleMaps.EventData
  ) => void
  onMouseEnterStop?: (
    event: TrimbleMaps.MapMouseEvent & {
      features?: TrimbleMaps.GeoJSONFeature[] | undefined
    } & TrimbleMaps.EventData,
    stop: TStop | null
  ) => void
  onMouseLeaveStop?: (
    event: TrimbleMaps.MapMouseEvent & {
      features?: TrimbleMaps.GeoJSONFeature[] | undefined
    } & TrimbleMaps.EventData
  ) => void
  onSelectStop?: (
    event: TrimbleMaps.MapMouseEvent & {
      features?: TrimbleMaps.GeoJSONFeature[] | undefined
    } & TrimbleMaps.EventData,
    stop: TStop | null
  ) => void
  /**
   * Dynamically styles the position layer based on logic
   * @default getDefaultPositionLayerStyle
   */
  getPositionLayerStyle?: (props: IGetDefaultPositionLayerProps) => TrimbleMaps.CirclePaint
  /**
   * Dynamically shadows the shadow position layer based on logic
   * @default getDefaultPositionShadowLayerStyle
   */
  getPositionShadowLayerStyle?: () => TrimbleMaps.CirclePaint
  /**
   * hides attraction names for high risk loads
   * @default false
   */
  hideNearbyAttractions?: boolean
}

/**
 * Configures a TrimbleMaps map to be attached to a DOM element via `id` prop
 * @example
 * ```tsx
 * const { containerId } = useMap({
 *   container: 'myMap',
 *   stops: [
 *     { coords: { lon: -74.566234, lat: 40.49944 } },
 *     { coords: { lon: -74.006, lat: 40.7128 } },
 *   ],
 * })
 *
 * // and then
 * <div id={containerId} />
 * ```
 */
export function useMap<
  TPosition extends WithId<IMapPosition>,
  TStop extends WithId<IMapStopProps>,
  TLine extends WithId<IMapRouteGroup>,
>(props: IUseMapProps<TPosition, TStop, TLine> = {}) {
  const [hasZoomed, setHasZoomed] = useState<boolean>(false)
  const [hasLoadedImages, setHasLoadedImages] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const {
    bordersOpen,
    container: containerProp = uuid(),
    stops: stopsProp = [],
    enableNavigation: enableNavigationProp = false,
    positions: positionsProp = [],
    lines: linesProp = [],
    linesSourceName = 'map-lines',
    stopsSourceName = 'map-stops',
    getPositionPopup,
    getStopPopup,
    hideNearbyAttractions = false,
    mapStyle: mapStyleProp = TrimbleMaps.Common.Style.DATALIGHT,
    onSelectPosition,
    onMouseEnterPosition,
    onMouseLeavePosition,
    onSelectStop,
    onMouseEnterStop,
    onMouseLeaveStop,
    NavigationControlOptions: NavigationControlOptionsProp,
    navigationPosition: navigationPositionProp = 'bottom-right',
    routeOptions = {},
    fadePositions = true,
    isNewWave = false,
    shadowType = 'latest',
    getPositionLayerStyle: getPositionLayerStyleProp = getDefaultPositionLayerStyle,
    getPositionShadowLayerStyle:
      getPositionShadowLayerStyleProp = getDefaultPositionShadowLayerStyle,
    fitBoundsOptions: fitBoundsOptionsProp,
    ...rest
  } = props || {}
  const mapRef = useRef<TrimbleMaps.Map>()
  const { current: Map } = mapRef || {}

  const popup = useMemo(() => {
    return new TrimbleMaps.Popup({
      offset: 10,
    })
  }, [])

  /**
   * @note For internal state management
   */
  const _addStopSource = useCallback(
    (stops: TStop[]) => {
      if (!Map || !isLoaded) {
        return
      }

      try {
        if (!Map.getSource(stopsSourceName)) {
          const stopGeoJSON = getGeoJSONStopSource(stops)
          Map.addSource(stopsSourceName, stopGeoJSON)
        }
      } catch (e) {
        // no op
      }
    },
    [Map, isLoaded, stopsSourceName]
  )

  /**
   * @note For internal state management
   */
  const _addStopMarkerImages = useCallback(() => {
    if (!Map || !isLoaded || hasLoadedImages) {
      return
    }

    try {
      const STOP_TYPES = ['P', 'D']
      STOP_TYPES.forEach(stopTypeShort => {
        // For MapIcon-D and MapIcon-P we use D1 and P1 by default
        const imageUrl = getStopMarkerIcon({ stopTypeShort, stopSequence: 1, isNewWave })
        Map.loadImage(imageUrl, (_: string, image: HTMLImageElement) => {
          Map.addImage(`MapIcon-${stopTypeShort}`, image) // 'MapIcon-D'
        })

        // there are 4 total icon images
        times(4).forEach((_, count) => {
          const imageName = [stopTypeShort, count + 1].join('')
          const imageUrl = getStopMarkerIcon({
            stopTypeShort,
            stopSequence: count + 1,
            isNewWave,
          })
          Map.loadImage(imageUrl, (_: string, image: HTMLImageElement) => {
            Map.addImage(`MapIcon-${imageName}`, image) // 'MapIcon-D1'
          })
        })
      })

      setHasLoadedImages(true)
    } catch (e) {
      // no op
    }
  }, [Map, hasLoadedImages, isLoaded, isNewWave])

  /**
   * @note For internal management only.
   */
  const _addStopLayer = useCallback(
    (stops: TStop[]) => {
      if (!Map || !isLoaded) {
        return
      }

      try {
        const stopsLayer = getStopsLayer({ stops, stopsSourceName, stopLayerId: STOP_LAYER_ID })

        // Only add if not already added
        if (Map.getSource(stopsSourceName) && !Map.getLayer(STOP_LAYER_ID)) {
          Map.addLayer(stopsLayer)
        }

        // Move z-index above key positions
        if (Map.getLayer(STOP_LAYER_ID) && Map.getLayer('keyPositions-layer')) {
          Map.moveLayer('keyPositions-layer', STOP_LAYER_ID)
        }

        Map.on('click', STOP_LAYER_ID, async function onClick(evt) {
          const { features = [] } = evt || {}
          const [firstFeature] = features || []

          // center map on selected
          const { geometry, properties } = firstFeature || []
          const { coordinates } = (geometry || {}) as GeoJSON.Point
          const popupLocation = coordinates.slice() as TrimbleMaps.LngLatLike

          /**
           * If `getStopPopup` is defined, render static markup as HTML popup when clicked
           * @note This renders static content, which must be manually re-rendered when data changes
           */
          if (getStopPopup && !popup.isOpen()) {
            const PopupComponent = getStopPopup(properties)
            const popupContent = ReactDOMServer.renderToStaticMarkup(PopupComponent)
            popup.setLngLat(popupLocation).setHTML(popupContent).addTo(Map)
          }

          if (onSelectStop) {
            let selected: TStop | undefined

            if (Map) {
              const [firstFeature] = Map.queryRenderedFeatures(evt.point)
              const { properties } = firstFeature || {}

              /**
               * Data is encoded like '{ \"id\": 123 }'
               */
              const { data: dataRaw } = properties || {}
              const decodedData = getDecodedData(dataRaw)
              const id = get(decodedData, 'id')

              /**
               * If found, override the value
               */
              selected = stopsProp.find(({ data }) => {
                const { id: stopId } = data || {}
                return stopId === id
              })
            }

            onSelectStop(evt, selected ?? null)
          }
        })

        // Add hover event
        Map.on('mouseenter', STOP_LAYER_ID, function mouseEnter(evt) {
          const [firstFeature] = Map.queryRenderedFeatures(evt.point)
          const { properties } = firstFeature || {}
          const { data: dataRaw } = properties || {}
          const dataDecoded = getDecodedData(dataRaw)
          const positionId = get(dataDecoded, 'id')

          const hoveredStop = stopsProp.find(({ data }) => {
            const { id: posId } = data || {}
            return posId === positionId
          })

          if (onMouseEnterStop) {
            onMouseEnterStop(evt, hoveredStop ?? null)
          }

          Map.getCanvas().style.cursor = 'pointer'
        })

        // Add un-hover event
        Map.on('mouseleave', STOP_LAYER_ID, function mouseLeave(evt) {
          if (onMouseLeaveStop) {
            onMouseLeaveStop(evt)
          }
          Map.getCanvas().style.cursor = ''
        })
      } catch (e) {
        // no op
      }
    },
    [
      Map,
      getStopPopup,
      isLoaded,
      onMouseEnterStop,
      onMouseLeaveStop,
      onSelectStop,
      popup,
      stopsProp,
      stopsSourceName,
    ]
  )

  /**
   * Adds svg markers to each stop location
   */
  const _addStopMarkers = useCallback(
    (stops: TStop[]) => {
      if (!Map || !isLoaded) {
        return
      }

      /**
       * Add Pickup/Delivery custom icon images
       */
      _addStopMarkerImages()

      /**
       * Add click and hover actions for custom stop markers
       */
      _addStopLayer(stops)

      /**
       * Add Stops source for custom markers
       */
      _addStopSource(stops)
    },
    [Map, _addStopLayer, _addStopMarkerImages, _addStopSource, isLoaded]
  )

  /**
   * Sets stops which will create a route
   * @example
   * ```
   * setStops([
   *   { coords: { lat: 123, lon: 123 }},
   *   { coords: { lat: 345, lon: 555 }}
   * ])
   * ```
   */
  const _setStops = useCallback(
    (stops: TStop[], routeId?: string) => {
      if (!Map || !isLoaded) {
        return
      }

      try {
        const myRoute = getTrimbleMapRoute(
          stops,
          { routeId, frameRoute: true, ...routeOptions },
          { bordersOpen }
        )
        myRoute.addTo(Map)
      } catch (e) {
        // no op
      }
    },
    [Map, isLoaded, routeOptions]
  )

  /**
   * @note For internal management only. Use `setPosition` externally to update state.
   */
  const _updatePositions = useCallback(
    (positions: IMapPosition[], sourceName: string) => {
      if (!Map || !isLoaded) {
        return
      }

      try {
        const source = Map.getSource(sourceName) as TrimbleMaps.GeoJSONSource
        const data = getGeoJSONFeaturePositionCollection(positions)
        source.setData(data)
      } catch (e) {
        // no op
      }
    },
    [Map, isLoaded]
  )

  /**
   * @note For internal management only. Use `setPosition` externally to update state.
   */
  const _updateShadows = useCallback(
    (positions: IMapPosition[], sourceName: string) => {
      if (!Map || !isLoaded) {
        return
      }

      try {
        const source = Map.getSource(sourceName) as TrimbleMaps.GeoJSONSource
        const positionsWithShadow = getPositionsWithShadow(positions, { shadowType })
        const data = getGeoJSONFeaturePositionCollection(positionsWithShadow)
        source.setData(data)
      } catch (e) {
        // no op
      }
    },
    [Map, isLoaded, shadowType]
  )

  /**
   * @note for internal use only
   */
  const _addShadows = useCallback(
    (positions: IMapPosition[], sourceName: string) => {
      if (!Map || !isLoaded) {
        return
      }

      /**
       * Add source for a shadow layer, only for last ping (1 element)
       */
      try {
        if (!Map.getSource(sourceName)) {
          const positionsWithShadow = getPositionsWithShadow(positions, { shadowType })
          const shadowSource = getGeoJSONPositionSource(positionsWithShadow)
          Map.addSource(sourceName, shadowSource)
        }
      } catch (e) {
        // no op
      }

      const shadowLayerId = [sourceName, 'layer'].join('-')

      // add the layer
      if (
        Map.getSource(sourceName) &&
        !Map.getLayer(shadowLayerId) &&
        typeof shadowType !== 'undefined'
      ) {
        Map.addLayer({
          id: shadowLayerId,
          type: 'circle',
          source: sourceName,
          paint: getPositionShadowLayerStyleProp(),
        })
      }

      // if the keyPositions layer exists, raise to topmost z-index
      if (Map.getLayer('keyPositions-layer')) {
        Map.moveLayer('keyPositions-layer')
      }

      if (Map.getLayer('other-layer')) {
        Map.moveLayer('other-layer')
      }

      if (Map.getLayer(STOP_LAYER_ID)) {
        Map.moveLayer(STOP_LAYER_ID)
      }
    },
    [Map, getPositionShadowLayerStyleProp, isLoaded, shadowType]
  )

  /**
   * Sets positions using stop coordinates
   * @example
   * ```
   * _setShadows([
   *   { coords: { lat: 123, lon: 123 }},
   *   { coords: { lat: 345, lon: 555 }}
   * ], 'other')
   * ```
   */
  const _setShadows = useCallback(
    (positions: IMapPosition[], sourceName: string) => {
      if (!Map || !isLoaded) {
        return
      }

      const shadowPositionSourceName = [sourceName, 'shadow'].join('-')

      if (Map.getSource(shadowPositionSourceName)) {
        _updateShadows(positions, shadowPositionSourceName)
      } else {
        _addShadows(positions, shadowPositionSourceName)
      }
    },
    [Map, isLoaded, _updateShadows, _addShadows]
  )

  /**
   * @note For internal management only. Use `setPosition` externally to update state.
   */
  const _addPositions = useCallback(
    (positions: IMapPosition[], sourceName: string) => {
      if (!Map || !isLoaded) {
        return
      }

      const positionLayerId = [sourceName, 'layer'].join('-')

      /**
       * Add Positions source for custom markers
       */
      try {
        if (!Map.getSource(sourceName)) {
          const positionsSource = getGeoJSONPositionSource(positions)
          Map.addSource(sourceName, positionsSource)
        }
      } catch (e) {
        // no op
      }

      try {
        Map.on('click', positionLayerId, async function onClick(evt) {
          const { features = [] } = evt || {}
          const [firstFeature] = features || []
          const { geometry, properties } = firstFeature || []
          const { coordinates } = (geometry || {}) as GeoJSON.Point
          // const [lng, lat] = coordinates || []
          const popupLocation = coordinates.slice() as TrimbleMaps.LngLatLike

          /**
           * If `getPositionPopup` is defined, render static markup as HTML popup
           * @note This renders static content, which must be manually re-rendered when data changes
           */
          if (getPositionPopup && !popup.isOpen()) {
            // set loading state
            const LoadingComponent = getPositionPopup(properties, { isLoading: true })
            const loadingHTML = ReactDOMServer.renderToStaticMarkup(LoadingComponent)
            popup.setLngLat(popupLocation).setHTML(loadingHTML).addTo(Map)

            // fetch data
            // TODO enable 'geocoding/search' request
            // const response = await getMapGeocode([lat, lng])
            // const { features: mapFeatures = [] } = response || {}

            // Render popup component
            const PopupComponent = getPositionPopup(properties, {
              isLoading: false,
              // mapFeatures,
            })
            const popupHTML = ReactDOMServer.renderToStaticMarkup(PopupComponent)
            popup.setLngLat(popupLocation).setHTML(popupHTML).addTo(Map)
          }

          if (onSelectPosition) {
            let selectedPos: TPosition | undefined

            if (Map) {
              const [firstFeature] = Map.queryRenderedFeatures(evt.point)
              const { properties } = firstFeature || {}

              /**
               * Data is encoded like '{ \"id\": 123 }'
               */
              const { data: dataRaw } = properties || {}
              const decodedData = getDecodedData(dataRaw)
              const id = get(decodedData, 'id')

              /**
               * If found, override the value
               */
              selectedPos = positionsProp.find(({ data }) => {
                const { id: posId } = data || {}
                return posId === id
              })
            }

            onSelectPosition(evt, selectedPos ?? null)
          }
        })

        // Add hover event
        Map.on('mouseenter', positionLayerId, function mouseEnter(evt) {
          const [firstFeature] = Map.queryRenderedFeatures(evt.point)
          const { properties } = firstFeature || {}
          const { data: dataRaw } = properties || {}
          const dataDecoded = getDecodedData(dataRaw)
          const positionId = get(dataDecoded, 'id')

          const hoveredPosition = positionsProp.find(({ data }) => {
            const { id: posId } = data || {}
            return posId === positionId
          })

          if (onMouseEnterPosition) {
            onMouseEnterPosition(evt, hoveredPosition ?? null)
          }

          Map.getCanvas().style.cursor = 'pointer'
        })

        // Add un-hover event
        Map.on('mouseleave', positionLayerId, function mouseLeave(evt) {
          if (onMouseLeavePosition) {
            onMouseLeavePosition(evt)
          }
          Map.getCanvas().style.cursor = ''
        })

        if (Map.getSource(sourceName) && !Map.getLayer(positionLayerId)) {
          Map.addLayer({
            id: positionLayerId,
            type: 'circle',
            source: sourceName,
            paint: getPositionLayerStyleProp({ fadePositions }),
          })
        }

        // Always keep key positions on top
        if (Map.getLayer('keyPositions-layer')) {
          Map.moveLayer('keyPositions-layer')
        }
      } catch (e) {
        // no op
      }
    },
    [
      Map,
      isLoaded,
      getPositionPopup,
      popup,
      onSelectPosition,
      positionsProp,
      onMouseEnterPosition,
      onMouseLeavePosition,
      getPositionLayerStyleProp,
      fadePositions,
    ]
  )

  /**
   * @note For internal management only. Use `setLines` externally to update state.
   */
  const _updateLines = useCallback(
    (lines: IMapRouteGroup[]) => {
      if (!Map || !isLoaded) {
        return
      }

      try {
        const source = Map.getSource(linesSourceName) as TrimbleMaps.GeoJSONSource
        const data = getGeoJSONFeatureLineCollection(lines)
        source.setData(data)
      } catch (e) {
        // no op
      }
    },
    [Map, isLoaded, linesSourceName]
  )

  /**
   * @note For internal management only. Use `setLines` externally to update state.
   */
  const _addLines = useCallback(
    (lines: IMapRouteGroup[]) => {
      if (!Map || !isLoaded) {
        return
      }

      try {
        const linesSource = getGeoJSONLineSource(lines)
        Map.addSource(linesSourceName, linesSource)
        Map.addLayer({
          id: [linesSourceName, 'lines'].join('-'),
          source: linesSourceName,
          type: 'line',
          paint: PREVIOUS_LINE_PAINT,
        })
      } catch (e) {
        // no op
      }
    },
    [Map, isLoaded, linesSourceName]
  )

  /**
   * @note For internal management only
   */
  const _addNavigation = useCallback(() => {
    if (!Map || !isLoaded || !enableNavigationProp) {
      return
    }

    try {
      const navigation = new TrimbleMaps.NavigationControl({
        showCompass: false,
        ...NavigationControlOptionsProp,
      })
      Map.addControl(navigation, navigationPositionProp)
    } catch (e) {
      // no opf
    }
  }, [Map, NavigationControlOptionsProp, enableNavigationProp, isLoaded, navigationPositionProp])

  /**
   * Sets lines using lines prop
   * @example
   * ```
   * setLines([
   *   { route: [{ coords: { lat: 123, lon: 123 } }] },
   *   { route: [{ coords: { lat: 345, lon: 555 } }] }
   * ])
   * ```
   */
  const _setLines = useCallback(
    (lines: IMapRouteGroup[]) => {
      if (!Map || !isLoaded) {
        return
      }

      if (Map.getSource(linesSourceName)) {
        _updateLines(lines)
      } else {
        _addLines(lines)
      }
    },
    [Map, isLoaded, linesSourceName, _updateLines, _addLines]
  )

  /**
   * Sets positions using stop coordinates
   * @example
   * ```
   * setPositions([
   *   { coords: { lat: 123, lon: 123 }},
   *   { coords: { lat: 345, lon: 555 }}
   * ], 'other')
   * ```
   */
  const _setPositions = useCallback(
    (positions: IMapPosition[], sourceName: string) => {
      if (!Map || !isLoaded) {
        return
      }

      if (Map.getSource(sourceName)) {
        _updatePositions(positions, sourceName)
      } else {
        _addPositions(positions, sourceName)
      }
    },
    [Map, _addPositions, isLoaded, _updatePositions]
  )

  /**
   * Init the map with configuration, and toggle isLoaded state
   */
  useEffect(() => {
    const initialBounds = getTrimbleFitBounds([], positionsProp, stopsProp, linesProp)
    const initialCenter = initialBounds.getCenter()

    mapRef.current = new TrimbleMaps.Map({
      ...DEFAULT_PROPS,
      container: containerProp,
      bounds: initialBounds,
      center: initialCenter,
      style: mapStyleProp,
      logoPosition: 'bottom-right',
      ...rest,
    }).on('load', function onLoad() {
      setIsLoaded(true)
      if (hideNearbyAttractions) {
        mapRef.current?.setPlacesVisibility(false)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * When map has loaded, or stops update, set the stops
   */
  useEffect(() => {
    if (isLoaded && stopsProp.length > 0) {
      _setStops(stopsProp)
      _addStopMarkers(stopsProp)
    }
  }, [_setStops, isLoaded, stopsProp, _addStopMarkers])

  // split positions into two groups
  const { otherPositions = [], keyPositions = [] } = useMemo(() => {
    return getPositionSources(positionsProp)
  }, [positionsProp])

  /**
   * When map has loaded, or positions update, set the positions
   */
  useEffect(() => {
    if (isLoaded && otherPositions.length > 0) {
      _setPositions(otherPositions, 'other')

      // add shadows for other positions
      _setShadows(otherPositions, 'other')
    }
  }, [Map, _setPositions, _setShadows, isLoaded, keyPositions, otherPositions])

  /**
   * Set any key positions (usually only 1 or 2 items)
   */
  useEffect(() => {
    if (isLoaded && keyPositions.length > 0) {
      _setPositions(keyPositions, 'keyPositions')

      // add shadows for key positions
      _setShadows(keyPositions, 'keyPositions')
    }
  }, [Map, _setPositions, _setShadows, isLoaded, keyPositions])

  /**
   * Zoom to positions when positions and stops have loaded
   */
  useEffect(() => {
    const hasPositions = positionsProp.length > 0

    if (Map && isLoaded && !hasZoomed && hasPositions) {
      const initialBounds = getTrimbleFitBounds([], positionsProp, stopsProp, linesProp)
      Map.fitBounds(initialBounds, { animate: false, padding: 48, ...fitBoundsOptionsProp })
      setHasZoomed(true)
    }
  }, [Map, fitBoundsOptionsProp, hasZoomed, isLoaded, linesProp, positionsProp, stopsProp])

  /**
   * When map has loaded, or lines update, set the lines
   */
  useEffect(() => {
    if (isLoaded && linesProp.length > 0) {
      _setLines(linesProp)
    }
  }, [_setLines, isLoaded, linesProp])

  /**
   * When map has loaded, add navigation elements
   */
  useEffect(() => {
    if (isLoaded) {
      _addNavigation()
    }
  }, [_addNavigation, isLoaded])

  return {
    Map,
    isLoaded,
    containerId: containerProp,
  }
}
