export function decimalToPercentFormatter(
  /**
   * Decimal value of percentage
   */
  value: number,
  /**
   * Returns percentage as string with % suffix
   * @default true
   */
  asString: boolean = true,
  /**
   * Number of digits after decimal point
   * @default 0
   */
  toFixed: number = 0
): number | string {
  const percentage = (value * 100).toFixed(toFixed)
  if (asString) return `${percentage}%`
  return Number(percentage)
}
