import React, { memo, useMemo } from 'react'
import { Button, makeStyles, Stack, Typography } from 'dpl/core'
import { CheckFilledIcon, SettingsOverrideIcon } from 'dpl/icons'
import SettingsOffIcon from 'dpl/icons/build/SettingsOffIcon'
import SettingsOnIcon from 'dpl/icons/build/SettingsOnIcon'
import { TIconColor } from 'dpl/icons/components/SvgIcon/types'
import { TIconComponent } from 'dpl/icons/types'
import { AdjustmentRuleLineItem } from '../AdjustmentRuleLineItem'
import {
  IAdjustmentRuleLineItem,
  IAdjustmentRuleLineItemKeyEnum,
  IAdjustmentRulesSectionStateEnum,
  TAdjustmentRulesSectionVariant,
} from './types'
import { getAdjustmentRuleSectionTitle } from './utils/getAdjustmentRulesSectionTitle'

export interface IAdjustmentRulesSectionProps {
  /**
   * @default 'AdjustmentRulesSection'
   */
  dataTest?: string
  /**
   * The applied adjustment rules to display in the accordion
   * @default []
   */
  rules: IAdjustmentRuleLineItem[]
  /**
   * The variant of the adjustment rules section
   * @default 'applied'
   */
  variant?: TAdjustmentRulesSectionVariant
  overridden?: boolean
  unlocked?: boolean
  onOverride?: () => void
  onUseRules?: () => void
}

const useAdjustmentRulesSectionStyles = makeStyles(() => ({
  cta: {
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
}))

export const AdjustmentRulesSection = memo<IAdjustmentRulesSectionProps>(
  ({
    dataTest = 'AdjustmentRulesSection',
    rules = [],
    variant = IAdjustmentRulesSectionStateEnum.applied,
    overridden,
    onOverride,
    onUseRules,
    unlocked,
  }) => {
    const classes = useAdjustmentRulesSectionStyles()
    const rulesState = useMemo<IAdjustmentRulesSectionStateEnum>(() => {
      if (unlocked && !overridden) return IAdjustmentRulesSectionStateEnum.interstitial
      if (overridden) return IAdjustmentRulesSectionStateEnum.overridden
      return variant
    }, [overridden, unlocked, variant])

    const Icon = useMemo<TIconComponent>(() => {
      if (rulesState === IAdjustmentRulesSectionStateEnum.interstitial) return CheckFilledIcon
      if (rulesState === IAdjustmentRulesSectionStateEnum.overridden) return SettingsOverrideIcon
      return rulesState === IAdjustmentRulesSectionStateEnum.applied
        ? SettingsOnIcon
        : SettingsOffIcon
    }, [rulesState])

    const iconColor = useMemo<TIconColor>(() => {
      if (rulesState === IAdjustmentRulesSectionStateEnum.interstitial) return 'green2'
      return rulesState === IAdjustmentRulesSectionStateEnum.applied ? 'coolGray5' : 'gray2'
    }, [rulesState])

    const limitRule = useMemo(
      () =>
        rules.find(
          rule =>
            rule.key === IAdjustmentRuleLineItemKeyEnum.maxMarginLimit ||
            rule.key === IAdjustmentRuleLineItemKeyEnum.maxPercentileLimit ||
            rule.key === IAdjustmentRuleLineItemKeyEnum.minPercentileLimit
        ),
      [rules]
    )
    const hasLimitRule = useMemo(() => Boolean(limitRule), [limitRule])

    const title = useMemo<string>(
      () =>
        getAdjustmentRuleSectionTitle({
          numRules: rules?.length || 0,
          rulesState,
          limitRule,
        }),
      [limitRule, rules?.length, rulesState]
    )

    const disableLineItem = useMemo<boolean>(() => {
      return (
        rulesState === IAdjustmentRulesSectionStateEnum.notApplied ||
        rulesState === IAdjustmentRulesSectionStateEnum.overridden
      )
    }, [rulesState])

    const disableCta = rulesState === IAdjustmentRulesSectionStateEnum.interstitial
    const showCta = !!onOverride || !!onUseRules
    const ctaLabel = useMemo<string>(() => {
      return rulesState === IAdjustmentRulesSectionStateEnum.overridden ? 'Use Rules' : 'Override'
    }, [rulesState])

    if (rules.length === 0) {
      return null
    }

    return (
      <Stack gap={1} data-test={dataTest}>
        <Stack direction='row' gap={1} alignItems='center'>
          <Icon size='large' color={iconColor} data-test={Icon?.name} />
          <Typography variant='subtitle2' data-test={`${dataTest}-title`}>
            {title}
          </Typography>
          {showCta && (
            <Button
              className={classes.cta}
              variant='text'
              size='xsmall'
              disabled={disableCta}
              onClick={rulesState === 'applied' ? onOverride : onUseRules}
              data-test={`${dataTest}-cta`}>
              {ctaLabel}
            </Button>
          )}
        </Stack>
        {rules.map(({ Icon, amount, key, label }) => {
          const isThresholdLimitLineItem =
            key === IAdjustmentRuleLineItemKeyEnum.maxMarginLimit ||
            key === IAdjustmentRuleLineItemKeyEnum.maxPercentileLimit ||
            key === IAdjustmentRuleLineItemKeyEnum.minPercentileLimit
          const disableAmount = hasLimitRule && !isThresholdLimitLineItem
          const textColor = isThresholdLimitLineItem ? 'textPrimary' : undefined
          return (
            <AdjustmentRuleLineItem
              key={key}
              disabled={disableLineItem}
              disableAmount={disableAmount}
              amount={amount}
              Icon={Icon}
              label={label}
              textColor={textColor}
            />
          )
        })}
      </Stack>
    )
  }
)
