import React from 'react'
import { CONTRACT_RFP_FAQ_URL } from '#routes/contract-rfp/utils'
import { brandColors } from 'dpl'
import { Link, makeStyles, Typography } from 'dpl/core'

const useStyles = makeStyles(theme => ({
  settingsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    margin: theme.spacing(3, 0, 3, 0),
  },
  settingBox: {
    flex: 1,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: theme.spacing(1),
    alignItems: 'space-between',
  },
}))

export function DefaultRateSettingInfo() {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.settingsContainer}>
        <div className={classes.settingBox}>
          <Typography variant='caption'>Cost Strategy</Typography>
          <Typography variant='body2' color={brandColors.coolGray5}>
            50th Percentile (Balanced)
          </Typography>
        </div>
        <div className={classes.settingBox}>
          <Typography variant='caption'>Margin Strategy</Typography>
          <Typography variant='body2' color={brandColors.coolGray5}>
            10% Premium
          </Typography>
        </div>
      </div>
      <Link
        href={CONTRACT_RFP_FAQ_URL}
        variant='subtitle2'
        target='_blank'
        rel='noopener noreferrer'>
        Learn more about rate generation
      </Link>
    </div>
  )
}
