import React from 'react'
import { Control } from 'react-hook-form'
import { REGION_OPTIONS } from '#routes/spot-rates-control-panel/constants'
import { makeStyles, Typography } from 'dpl/core'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import { TAdjustmentRuleFormSchema } from '../../../../schema'

interface ISelectFieldLabelProps {
  label?: string
  description?: string
}

function RegionLabelDescription({ description, label }: ISelectFieldLabelProps) {
  return (
    <div>
      <Typography variant='body2' className='selectOptionLabel'>
        {label}
      </Typography>
      {description && (
        <Typography variant='caption' color='textSecondary' className='selectOptionDescription'>
          {description}
        </Typography>
      )}
    </div>
  )
}

const regionSelectOptions = REGION_OPTIONS.map(option => {
  const { description, label } = option || {}
  return { ...option, label: <RegionLabelDescription label={label} description={description} /> }
})

const useControlledRegionSelectStyles = makeStyles(theme => ({
  selectDisplay: {
    '& .selectOptionLabel': {
      ...theme.typography.body1,
    },
    '& .selectOptionDescription': {
      display: 'none',
    },
  },
}))

export interface IControlledRegionSelectProps {
  control: Control<TAdjustmentRuleFormSchema>
  name: string
  errorMessage?: string
}

export function ControlledRegionSelect({
  control,
  errorMessage,
  name,
}: IControlledRegionSelectProps) {
  const classes = useControlledRegionSelectStyles()

  return (
    <ControlledSelectField
      name={name}
      control={control}
      SelectProps={{
        options: regionSelectOptions,
        label: 'Region',
        error: !!errorMessage,
        helperText: errorMessage,
        SelectProps: {
          SelectDisplayProps: { className: classes.selectDisplay },
        },
        dataTest: `${name}-select`,
      }}
    />
  )
}
