import React, { useCallback, useMemo, useState } from 'react'
import { renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers'
import moment from 'moment'
import { FORMATS } from '../../constants/datetime'
import { makeStyles } from '../../core'
import {
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
  MaterialUiPickersDate,
} from '../../core/pickers'
import ClockIcon from '../../icons/build/ClockIcon'
import { useBreakpointContext } from '../../providers'
import { DatePickerActionBar } from '../DatePicker/components/DatePickerActionBar'
import { DatePickerTextField } from '../DatePicker/components/DatePickerTextField'
import { getParsedValue } from '../DatePicker/utils'
import { ITextFieldProps } from '../TextField'

type TMuiTimePickerProps = MuiTimePickerProps<MaterialUiPickersDate>

export interface TimePickerProps
  extends Pick<
      ITextFieldProps,
      | 'clearable'
      | 'onClear'
      | 'onBlur'
      | 'dataTest'
      | 'disabled'
      | 'label'
      | 'required'
      | 'error'
      | 'fullWidth'
      | 'size'
      | 'placeholder'
      | 'helperText'
      | 'name'
      | 'InputLabelProps'
      | 'className'
    >,
    Pick<
      TMuiTimePickerProps,
      'ampm' | 'format' | 'readOnly' | 'value' | 'views' | 'onClose' | 'timezone'
    > {
  onChange: (newVal: MaterialUiPickersDate) => void
}

const useTimePickerStyles = makeStyles(() => ({
  mobilePaper: {
    '& .MuiPickersToolbar-root': {
      gridColumn: '1 / span 3',
    },
    '& .MuiPickersLayout-contentWrapper': {
      gridColumn: '1 / span 3',
    },
    '& .MuiMultiSectionDigitalClockSection-root': {
      flex: 1,
      '& .MuiMultiSectionDigitalClockSection-item': {
        width: 'auto',
      },
    },
  },
}))

/**
 * @name MUI TimePicker
 * @see https://v6.mui.com/x/react-date-pickers/time-picker/
 */
export function TimePicker({
  ampm = true,
  className,
  clearable,
  disabled,
  error,
  fullWidth,
  helperText,
  label,
  onClear,
  required,
  format = FORMATS.militaryTimeFormatShort12HUpperCase,
  name,
  onChange: onChangeProp,
  readOnly: readOnlyProp = false,
  value: valueProp,
  views: viewsProp = ['hours', 'minutes'],
  size,
  dataTest,
  placeholder = format,
  onClose: onCloseProp,
  timezone,
}: TimePickerProps) {
  const classes = useTimePickerStyles()
  const { isMobile } = useBreakpointContext()

  const changeHandler = useCallback(
    (newVal: MaterialUiPickersDate) => {
      if (typeof newVal === 'undefined' || typeof newVal === 'number') {
        // nothing
      } else {
        onChangeProp(moment(newVal))
      }
    },
    [onChangeProp]
  )

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const value = useMemo(() => getParsedValue(valueProp), [valueProp])

  return (
    <MuiTimePicker
      ampm={ampm}
      disabled={disabled}
      open={isOpen}
      format={format}
      value={value || null}
      readOnly={readOnlyProp}
      views={viewsProp}
      onChange={changeHandler}
      timezone={timezone}
      onClose={() => {
        if (onCloseProp) onCloseProp()
        setIsOpen(false)
      }}
      slots={{
        textField: DatePickerTextField,
        actionBar: DatePickerActionBar,
      }}
      slotProps={{
        textField: {
          className,
          clearable,
          dataTest,
          disabled,
          label,
          required,
          error,
          name,
          onClick: () => setIsOpen(true),
          onClear,
          fullWidth,
          size,
          placeholder,
          helperText,
          Icon: ClockIcon,
        } as ITextFieldProps,
        mobilePaper: {
          className: classes.mobilePaper,
        },
      }}
      viewRenderers={
        isMobile
          ? {
              hours: renderMultiSectionDigitalClockTimeView,
              minutes: renderMultiSectionDigitalClockTimeView,
              seconds: renderMultiSectionDigitalClockTimeView,
            }
          : undefined
      }
    />
  )
}

export default TimePicker
