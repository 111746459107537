import { IAdjustmentRuleLineItem } from '#components/RatesEstimatedStrategyAccordion/types'
import { ICostModel } from '#types/costModel'
import { IRuleTypeEnum } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { DISPLAY } from 'dpl/constants'
import { IRule } from '../../types'
import { getAdjustmentRuleLineItem } from '../getAdjustmentRuleLineItem'
import { getAdjustmentRuleIcon } from '../getAdjustmentRuleLineItem/getAdjustmentRuleIcon'
import { getMaxCostLimitLineItem } from './getMaxCostLimitLineItem'
import { getMinCostLimitLineItem } from './getMinCostLimitLineItem'

interface IGetAppliedAdjustmentRulesLineItemsProps {
  baseCost: number
  baseCostPercentile: number
  costModel: Nullable<ICostModel>
  isAboveMaxCostLimit: boolean
  isBelowMinCostLimit: boolean
  isBaseTheDefaultCostPercentile: boolean
  rules: IRule[]
}

/**
 * Returns the applied adjustment rules for display as line items in the adjustment rules section.
 * If there are no rules to display, an empty array is returned.
 * If the base cost percentile is the default and there are additive rules, the default rule is added to the list.
 * Otherwise, only the rules are returned.
 *
 * @returns {IAdjustmentRuleLineItem[]} The formatted adjustment rules
 *
 */
export const getAppliedAdjustmentRulesLineItems = ({
  baseCost,
  baseCostPercentile,
  costModel,
  isAboveMaxCostLimit,
  isBaseTheDefaultCostPercentile,
  isBelowMinCostLimit,
  rules,
}: IGetAppliedAdjustmentRulesLineItemsProps): IAdjustmentRuleLineItem[] => {
  if (!baseCost) {
    return []
  }

  // If there are no rules to display, return an empty array
  if (rules.length === 0) {
    return []
  }

  // Determine if there are any additive rules
  const hasAdditiveRules = rules.some(({ ruleAction }) => ruleAction === IRuleTypeEnum.additive)

  // Format the rules
  const formattedRules = rules.map(rule => getAdjustmentRuleLineItem({ rule, baseCost }))

  // Get the min cost limit line item, if applicable
  const minCostLineItem = getMinCostLimitLineItem({ costModel, isBelowMinCostLimit })

  // Get the max cost limit line item, if applicable
  const maxCostLineItem = getMaxCostLimitLineItem({ costModel, isAboveMaxCostLimit })

  // If the base cost percentile is the default, and there are additive rules...
  // then, add the default rule to the list
  if (isBaseTheDefaultCostPercentile && hasAdditiveRules) {
    return [
      {
        key: 'default',
        Icon: getAdjustmentRuleIcon({
          adjustmentValue: baseCostPercentile,
          ruleAction: IRuleTypeEnum.base,
        }),
        label: `${baseCostPercentile}th Percentile (Default)`,
        amount: baseCost ? currencyFormatter(baseCost) : DISPLAY.empty,
      },
      ...formattedRules,
      ...(minCostLineItem ? [minCostLineItem] : []),
      ...(maxCostLineItem ? [maxCostLineItem] : []),
    ]
  }

  return [
    ...formattedRules,
    ...(minCostLineItem ? [minCostLineItem] : []),
    ...(maxCostLineItem ? [maxCostLineItem] : []),
  ]
}
