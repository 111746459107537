import React from 'react'
import { ICostModel } from '#types/costModel'
import { currencyFormatter } from '#utils/currencyFormatter'
import { SliderProps } from 'dpl/core'
import { useBreakpoint } from 'dpl/hooks'
import { RateSlider } from '..'
import { RateSliderLabel } from '../RateSliderLabel'
import { LABEL_BY_PERCENTILE_INDEX } from '../constants'

export interface IRateEstimateSliderProps {
  costModel: Nullable<ICostModel>
  onChange: SliderProps['onChange']
  disabled?: boolean
  value?: number
}

const getMarks = (costModel: Nullable<ICostModel>): SliderProps['marks'] => {
  const {
    cost10,
    cost15,
    cost20,
    cost25,
    cost30,
    cost35,
    cost40,
    cost45,
    cost50,
    cost55,
    cost60,
    cost65,
    cost70,
    cost75,
    cost80,
    cost85,
    cost90,
  } = costModel || {}

  const percentiles = [
    cost10,
    cost15,
    cost20,
    cost25,
    cost30,
    cost35,
    cost40,
    cost45,
    cost50,
    cost55,
    cost60,
    cost65,
    cost70,
    cost75,
    cost80,
    cost85,
    cost90,
  ]

  const marks = percentiles.map((percentileValue, index) => {
    const value = percentileValue ?? 0
    const labelText = LABEL_BY_PERCENTILE_INDEX[index]

    return {
      value,
      label: (
        <RateSliderLabel value={value} labelHeader={labelText} getValueText={currencyFormatter} />
      ),
    }
  })

  return marks
}

export function RateEstimateSlider({
  costModel,
  disabled,
  onChange,
  value: valueProp,
}: IRateEstimateSliderProps) {
  const { isMobile } = useBreakpoint()

  const { cost10, cost50, cost90 } = costModel || {}
  const value = valueProp ?? cost50 ?? 0
  const rangeMinimumValue = cost10 ?? 0
  const rangeMaximumValue = cost90 ?? 0
  // Offset the slider min and max values so that the range min and max values are not right at the edge of the slider, per designs
  // Mobile has a larger offset to provide space for labels
  const offset = (rangeMaximumValue - rangeMinimumValue) * (isMobile ? 0.2 : 0.1)
  const sliderMinimumValue = rangeMinimumValue - offset
  const sliderMaximumValue = rangeMaximumValue + offset
  const marks = getMarks(costModel)

  return (
    <RateSlider
      aria-label='Est. Carrier Rate (including fuel)'
      marks={marks}
      value={value}
      getAriaValueText={currencyFormatter}
      valueLabelFormat={currencyFormatter}
      valueLabelDisplay='auto'
      min={sliderMinimumValue}
      max={sliderMaximumValue}
      onChange={onChange}
      disabled={disabled}
      step={null}
      showMinMaxValueLabels={false}
    />
  )
}
