import React from 'react'
import classNames from 'classnames'
import { makeStyles, Typography } from 'dpl/core'
import { useBreakpointContext } from 'dpl/providers'

export interface RateSliderLabelProps {
  value: number
  /**
   * Header label shown above value
   * If not provided, then label is not shown.
   * @example Balanced
   */
  labelHeader: Nullable<string>
  /**
   * Function used to get formatted value text to show in sublabel
   * @example $1094.34
   */
  getValueText: (value: number) => string
  /**
   * If true, value sublabel is shown on mobile screens
   * @default false
   */
  showSubLabelOnMobile?: boolean
}

const useRateSliderLabelStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
  },
}))

export function RateSliderLabel({
  getValueText,
  labelHeader,
  showSubLabelOnMobile = false,
  value,
}: RateSliderLabelProps) {
  const { isMobile } = useBreakpointContext()
  const classes = useRateSliderLabelStyles()
  const rootClasses = classNames(classes.root, labelHeader)
  const renderSubLabel = isMobile ? showSubLabelOnMobile : true

  if (!labelHeader) return null

  return (
    <div data-test={`slider-mark-label-${labelHeader}`} className={rootClasses}>
      <Typography variant='body2'>{labelHeader}</Typography>
      {renderSubLabel && (
        <Typography variant='caption' color='textSecondary'>
          {getValueText(value)}
        </Typography>
      )}
    </div>
  )
}
