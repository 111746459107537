import { IRule } from '../../RatesEstimatedStrategyAccordion/types'
import { getAdjustmentRuleLineItem } from './getAdjustmentRuleLineItem'

interface IGetNotAppliedAdjustmentRuleLineItemsArgs {
  rules: Nullable<IRule[]>
  carrierRate: number
}

export const getNotAppliedAdjustmentRuleLineItems = ({
  carrierRate,
  rules,
}: IGetNotAppliedAdjustmentRuleLineItemsArgs) => {
  if (!rules?.length) return []

  const lineItems = rules.map(rule => {
    return getAdjustmentRuleLineItem({ carrierRate, rule })
  })

  return lineItems
}
