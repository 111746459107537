import React from 'react'
import { Spinner } from 'dpl/components/Spinner'
import compact from 'lodash/compact'
import { OrganizationProfile } from './OrganizationProfile'
import { useOrganizationProfileQuery } from './graphql/OrganizationProfile'

function OrganizationProfileContainer() {
  const { data, loading } = useOrganizationProfileQuery()
  const { currentSession, productModules: productModulesList, searchOrganizationUsers } = data || {}
  const { nodes = [] } = searchOrganizationUsers || {}
  const { organizationUser } = currentSession || {}
  const { organization, organizationTeams } = organizationUser || {}
  const { createdAt, fullLogo, name = '', productModules } = organization || {}
  const isAdmin = compact(organizationTeams).some(({ key }) => key === 'organization_admins')
  const members = compact(nodes)
  const orgAdmins = members.filter(({ organizationTeams }) =>
    organizationTeams.some(({ key }) => key === 'organization_admins')
  )
  const orgAdminNames = orgAdmins.map(({ user }) => `${user.firstName} ${user.lastName}`)

  const apps = [
    {
      appName: 'Product Access',
      appUrl: 'console.transfix.io',
      totalUsers: members.length,
      modules: compact(productModules).map(
        ({ accessGrantedAt, organizationAdmins, organizationUsers, productModule }) => ({
          moduleName: productModulesList?.find(({ key }) => key === productModule)?.name || '',
          activatedAt: accessGrantedAt,
          totalMembers: organizationUsers.length,
          adminNames: organizationAdmins.map(
            ({ user: { emailAddress, firstName, lastName } }) =>
              `${firstName || ''} ${lastName || ''}`.trim() || emailAddress
          ),
        })
      ),
    },
  ]

  if (loading) {
    return <Spinner />
  }

  return (
    <OrganizationProfile
      orgActiveDate={createdAt || ''}
      orgName={name}
      orgImgSrc={fullLogo?.url}
      orgAdminNames={orgAdminNames}
      isAdmin={isAdmin}
      apps={apps}
      status='active'
    />
  )
}

export default OrganizationProfileContainer
