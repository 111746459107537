import React from 'react'
import { ConfirmationModal, IModalAction } from 'dpl/components/Modals'
import Typography from 'dpl/core/Typography'

interface IRateForecastInfoModal {
  onClose: () => void
  open: boolean
}

export function RateForecastInfoModal({ onClose, open }: IRateForecastInfoModal) {
  const actions: IModalAction[] = [
    {
      label: 'Close',
      action: onClose,
    },
  ]
  return (
    <ConfirmationModal
      open={open}
      title='What is Forecasted Carrier Rate?'
      actions={actions}
      onClose={onClose}
      includeCloseIconButton={false}>
      <Typography variant='body2'>
        This chart shows how carrier rates are predicted to change over time and how your selected
        contract rate, including margin, is projected to generate margin each month.
      </Typography>
    </ConfirmationModal>
  )
}
