import React from 'react'
import { usePermissionsContext } from 'auth/common/context'
import classNames from 'classnames'
import { FORMATS } from 'dpl/constants'
import { Button, Link } from 'dpl/core'
import Typography from 'dpl/core/Typography'
import makeStyles from 'dpl/core/styles/makeStyles'
import { AIIcon } from 'dpl/icons/build'
import { brandColors } from 'dpl/theme'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags/src/FlagsContext'
import moment from 'moment'
import { DefaultSettingsModal } from '../../../DefaultSettingsModal'
import { IRfpLaneFragment } from '../../../LaneList/graphql/RfpLane'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    border: `1px solid ${brandColors.coolGray3}`,
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  upsell: {
    backgroundColor: brandColors.skyBlue1,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  icon: {
    borderRadius: '50%',
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: brandColors.skyBlue2,
    marginRight: theme.spacing(1),
  },
  info: {
    display: 'flex',
  },
  button: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

interface IRateGenCalloutProps {
  lane: IRfpLaneFragment
  onClick: () => void
  loading: boolean
}

export function RateGenCallout({ lane, loading, onClick }: IRateGenCalloutProps) {
  const { isFlagEnabled } = useFlagsContext()
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled

  const classes = useStyles()

  const { rate } = lane || {}
  const { createdAt, source } = rate || {}

  const isRateGenerated = source === 'auto_rate_gen'
  const lastRateGenerated = moment(createdAt).format(FORMATS.monthDayYearTimeFormat12hUppercase)

  const className = classNames(classes.container, {
    [classes.upsell]: !isRateGenerated,
  })

  const { modalState, toggleModal } = useModalState({
    defaultSettingsModal: false,
  })

  const openModalHandler = () => {
    toggleModal('defaultSettingsModal', true)
  }

  const closeModalHandler = () => {
    toggleModal('defaultSettingsModal', false)
  }

  if (!isRfpRateGenEnabled) {
    return null
  }

  return (
    <div>
      <div className={className}>
        <div className={classes.iconContainer}>
          <div className={classes.icon}>
            <AIIcon size='large' color='info' />
          </div>
        </div>
        <div>
          <div className={classes.info}>
            <Typography variant='body2'>
              Generate a new rate using{' '}
              <Link variant='subtitle2' onClick={openModalHandler} component='span'>
                default settings
              </Link>
              , overwriting any current rates. This action is permanent and will be saved to the
              lane.
            </Typography>
          </div>
          <div className={classes.button}>
            {isRateGenerated && (
              <Typography marginRight={2} color={brandColors.coolGray5} variant='caption'>
                Last Generated: {lastRateGenerated}
              </Typography>
            )}
            <Button disabled={loading} size='xsmall' onClick={onClick}>
              Generate and Save rate
            </Button>
          </div>
        </div>
      </div>
      <DefaultSettingsModal open={modalState.defaultSettingsModal} onClose={closeModalHandler} />
    </div>
  )
}
