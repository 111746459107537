import { useForm, UseFormOptions, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdjustmentRuleFormSchema, TAdjustmentRuleFormSchema } from '../schema'

export interface IUseAdjustmentRuleFormProps
  extends Omit<UseFormOptions<TAdjustmentRuleFormSchema>, 'resolver'> {}

export const useAdjustmentRuleForm = ({
  defaultValues = {},
  ...props
}: IUseAdjustmentRuleFormProps) => {
  return useForm<TAdjustmentRuleFormSchema>({
    resolver: yupResolver(AdjustmentRuleFormSchema),
    defaultValues: AdjustmentRuleFormSchema.cast(defaultValues),
    ...props,
  })
}

export const useAdjustmentRuleFormContext = () => useFormContext<TAdjustmentRuleFormSchema>()
