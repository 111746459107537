import React, { memo } from 'react'
import { AutocompleteHighlightText } from 'dpl/components/Autocomplete'
import { Stack, Typography } from 'dpl/core'
import { IOrganizationCarrierResultFragment } from '../../graphql/OrganizationCarrierResultFragment'

export interface IAutocompleteOrganizationCarrierOptionProps {
  inputValue: string
  option: IOrganizationCarrierResultFragment
  /**
   * @default 'AutocompleteOrganizationCarrierOption'
   */
  dataTest?: string
}

export const AutocompleteOrganizationCarrierOption =
  memo<IAutocompleteOrganizationCarrierOptionProps>(
    ({ dataTest = 'AutocompleteOrganizationCarrierOption', inputValue, option }) => {
      const { carrier } = option || {}
      const { dotNumber, legalName, mcNumber } = carrier || {}
      return (
        <Stack data-test={dataTest} gap={0.25}>
          <Typography component='div' variant='body1'>
            <AutocompleteHighlightText label={`${legalName}`} inputValue={inputValue} />
          </Typography>
          <Typography component='div' variant='caption' color='textSecondary'>
            <AutocompleteHighlightText
              label={`DOT ${dotNumber} · MC ${mcNumber}`}
              inputValue={inputValue}
            />
          </Typography>
        </Stack>
      )
    }
  )
