import { useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { OnboardFormSchema, TOnboardFormSchema } from '../schema'

interface IUseOnboardFormProps extends Omit<UseFormOptions<TOnboardFormSchema>, 'resolver'> {}

export const useOnboardForm = ({ defaultValues = {}, ...props }: IUseOnboardFormProps) => {
  return useForm<TOnboardFormSchema>({
    resolver: yupResolver(OnboardFormSchema),
    defaultValues: OnboardFormSchema.cast(defaultValues),
    mode: 'onChange',
    criteriaMode: 'all',
    ...props,
  })
}
