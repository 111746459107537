import React, { memo, useRef } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { Checkbox, ICheckboxProps } from 'dpl/components/Checkbox'
import { v4 as uuid } from 'uuid'

type IBaseControllerProps = ControllerProps<React.ComponentType>

type TIDProp = number | string

export interface ICheckboxOption {
  label: string
  id: TIDProp
  disabled?: boolean
}

export interface IControlledCheckboxGroupInputProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  CheckboxProps?: ICheckboxProps
  label?: string
  id?: string
  disabled?: boolean
  required?: boolean
  options: ICheckboxOption[]
}

interface IRenderProps {
  value: TIDProp[]
  onChange: (newValue: TIDProp[]) => void
  onBlur: () => void
}

const ControlledCheckboxGroupInput = memo<IControlledCheckboxGroupInputProps>(
  ({
    name,
    id: idProp,
    control,
    disabled,
    defaultValue = [],
    options,
    label,
    required,
    CheckboxProps: { dataTest, ...CheckboxProps } = {},
  }) => {
    const id = useRef(idProp || uuid())

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onBlur, onChange, value: valueProp }: IRenderProps) => {
          const changeHandler = (searchId: string | number) => () => {
            let newValue = [...valueProp]

            const isChecked = valueProp.find(id => searchId === id)

            if (!isChecked) {
              newValue.push(searchId)
            } else {
              newValue = valueProp.filter(id => searchId !== id)
            }

            onChange(newValue)
          }

          return (
            <div>
              {label && (
                <label
                  htmlFor={id.current}
                  className={`label ${disabled ? 'disabled' : ''} ${required ? 'required' : ''}`}>
                  {label}
                </label>
              )}
              {options.map(({ disabled, id: currentId, label }) => {
                return (
                  <div key={currentId}>
                    <Checkbox
                      {...CheckboxProps}
                      dataTest={dataTest && `${dataTest}-${currentId}`}
                      label={label}
                      onBlur={onBlur}
                      onChange={changeHandler(currentId)}
                      checked={valueProp.some(id => `${id}` === `${currentId}`)}
                      disabled={disabled}
                    />
                  </div>
                )
              })}
            </div>
          )
        }}
      />
    )
  }
)

export default ControlledCheckboxGroupInput
