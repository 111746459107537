import * as yup from 'yup'
import { IDropTrailerLocations } from '../../../types'

/**
 * The additional input for the lane.
 */

export const DEFAULT_ADVANCED_INPUTS = {
  teamRequired: false,
  dropTrailerSwitch: false,
  dropTrailer: [],
}

export const ContractAdvancedInputsSchema = yup.object({
  teamRequired: yup.boolean().default(DEFAULT_ADVANCED_INPUTS.teamRequired).required(),
  dropTrailerSwitch: yup.boolean().default(DEFAULT_ADVANCED_INPUTS.dropTrailerSwitch).required(),
  dropTrailer: yup
    .array()
    .of(yup.mixed<IDropTrailerLocations>())
    .when('dropTrailerSwitch', {
      is: true,
      then: schema => schema.min(1, 'You must indicate where the drop trailer will be.'),
    }),
})

export interface IContractAdvancedInputsSchema
  extends yup.InferType<typeof ContractAdvancedInputsSchema> {}
