import React, { useState, useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { Badge } from 'dpl/components/Badge'
import { ToggleButton } from 'dpl/components/ToggleButton'
import { Box } from 'dpl/core'
import { DownArrowIcon } from 'dpl/icons'
import { useFlagsContext } from 'flags'
import { LaneInformationFormSchema } from '../laneInformationFormSchema'
import { AdvancedInputsModal } from './AdvancedInputsModal'

type AdvancedInputValue =
  | number
  | boolean
  | null
  | LaneInformationFormSchema['advancedInputs']['creationLeadTime']

interface AdvancedInputConfig {
  name: string
  hasValue?: (props: {
    inputValue: AdvancedInputValue
    isUiImproveEnabled?: boolean
    advancedInputs: LaneInformationFormSchema['advancedInputs']
  }) => boolean
}

const hasLeadTimeValue: AdvancedInputConfig['hasValue'] = ({
  advancedInputs,
  inputValue: value,
  isUiImproveEnabled,
}) => {
  if (isUiImproveEnabled) {
    const { leadTimeSwitch } = advancedInputs || {}
    if (!leadTimeSwitch) {
      return false
    }
  }

  const { amount } =
    (value as LaneInformationFormSchema['advancedInputs']['creationLeadTime']) ?? {}

  return Boolean(amount)
}

const hasDropTrailerValue: AdvancedInputConfig['hasValue'] = ({
  inputValue: value,
  isUiImproveEnabled,
}) => !!value && !isUiImproveEnabled

const inputConfigByName: Record<string, AdvancedInputConfig> = {
  palletCount: {
    name: 'palletCount',
  },
  palletSwitch: {
    name: 'palletSwitch',
    hasValue: () => false,
  },
  teamRequired: {
    name: 'teamRequired',
  },
  dropTrailerSwitch: {
    name: 'dropTrailerSwitch',
  },
  dropTrailerPickup: {
    name: 'dropTrailerPickup',
    hasValue: hasDropTrailerValue,
  },
  dropTrailerDelivery: {
    name: 'dropTrailerDelivery',
    hasValue: hasDropTrailerValue,
  },
  leadTimeSwitch: {
    name: 'leadTimeSwitch',
    hasValue: () => false,
  },
  assignmentLeadTime: {
    name: 'assignmentLeadTime',
    hasValue: hasLeadTimeValue,
  },
  creationLeadTime: {
    name: 'creationLeadTime',
    hasValue: hasLeadTimeValue,
  },
}

interface AdvancedInputsProps {
  control: Control<LaneInformationFormSchema>
  updateAdvancedInputs: (advancedInputs: LaneInformationFormSchema['advancedInputs']) => void
}

export function AdvancedInputs({ control, updateAdvancedInputs }: AdvancedInputsProps) {
  const { isFlagEnabled } = useFlagsContext()
  const isUiImproveEnabled = isFlagEnabled('rates_ui_improvements')

  const [isOpen, setIsOpen] = useState(false)
  const advancedInputs = useWatch<LaneInformationFormSchema['advancedInputs']>({
    control,
    name: 'advancedInputs',
    defaultValue: {
      creationLeadTime: {},
    },
  })

  const filledAdvancedInputsConfig = useMemo(() => {
    const inputsWithValues: string[] = []

    Object.keys(advancedInputs).forEach(inputName => {
      const inputValue = advancedInputs[inputName as keyof typeof advancedInputs] ?? null
      const { hasValue, name } = inputConfigByName[inputName]
      const isValuePresent = hasValue
        ? hasValue({ inputValue, isUiImproveEnabled, advancedInputs })
        : Boolean(inputValue)

      if (isValuePresent) {
        inputsWithValues.push(name)
      }
    })

    return inputsWithValues
  }, [advancedInputs, isUiImproveEnabled])

  const numSelectedInputs = filledAdvancedInputsConfig.length

  return (
    <Box flexGrow={1} display='flex' flexWrap='wrap' alignItems='center' gap={1}>
      <ToggleButton
        dataTest='open-advanced-inputs-modal'
        label={
          <>
            Advanced Inputs
            {numSelectedInputs > 0 && (
              <Badge color='blue' label={`${numSelectedInputs}`} dataTest='advanced-inputs-badge' />
            )}
          </>
        }
        data-test='open-advanced-inputs-modal'
        onClick={() => setIsOpen(true)}
        endIcon={DownArrowIcon}
        selected={false}
        value
      />

      {isOpen && (
        <AdvancedInputsModal
          onSubmit={updateAdvancedInputs}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          advancedInputs={advancedInputs}
        />
      )}
    </Box>
  )
}
