import * as yup from 'yup'

export const EmbeddedCreateCustomerFormSchema = yup.object({
  name: yup.string().required('This field is required'),
  externalId: yup
    .string()
    .transform(value => value.trim()) // Trim the value before validation
    .required('This field is required')
    .matches(/^\S*$/, 'This field cannot contain spaces'),
})

export type TEmbeddedCreateCustomerFormSchema = yup.InferType<
  typeof EmbeddedCreateCustomerFormSchema
>
