import { emptyStringToNull } from '#routes/spot-rates-control-panel/components/AdjustmentRuleFormSidePanel/schema/utils'
import * as yup from 'yup'

export const RfpRatesSchema = yup.object({
  rateType: yup.string().required('Rate type is required'),
  carrierRate: yup.number().transform(emptyStringToNull).nullable(),
  marginPercentage: yup
    .number()
    .transform(emptyStringToNull)
    .min(0, 'Margin percentage must be at least 0')
    .max(100, 'Margin percentage cannot exceed 100')
    .nullable(),
  marginAmount: yup.number().transform(emptyStringToNull).nullable(),
  customRate: yup.number().transform(emptyStringToNull).nullable(),
  fuelRate: yup.number().transform(emptyStringToNull).nullable(),
  shipperRate: yup.number().transform(emptyStringToNull).nullable(),
  autoGenerateRates: yup.string(),
})

export interface IRfpRatesSchema extends yup.InferType<typeof RfpRatesSchema> {}
