import React, { ChangeEventHandler, useCallback, useMemo, useState } from 'react'
import { UnpackNestedValue } from 'react-hook-form'
import { Button, Stack } from 'dpl/core'
import {
  ControlledPasswordField,
  IControlledPasswordFieldProps,
} from 'forms/components/ControlledPasswordField'
import { IApiError, IApiErrorResponse } from 'network/src/rest/hooks/useRequest'
import {
  PasswordStrengthValidation,
  getPasswordValidationState,
} from '../../../PasswordStrengthValidation'
import { ResetPasswordErrorMessage } from './ResetPasswordErrorMessage'
import { useResetPasswordForm } from './hooks'
import { TResetPasswordFormSchema } from './schema'

const PASSWORD_ERROR_MESSAGE = 'Make sure your password has met all the requirements.'

export interface IResetPasswordFormProps {
  /**
   * Callback fired to submit password reset.
   * Returns true if successful. Else, returns api errors.
   */
  onSubmit: (
    data: UnpackNestedValue<TResetPasswordFormSchema>
  ) => Promise<IApiErrorResponse | undefined>
  /**
   * Callback fired on password change.
   * This will be used to check the password strength as user inputs password.
   */
  onChange: ChangeEventHandler<HTMLInputElement>
  /**
   * Strength of password as determined by Stytch, from 0 to 4
   * @see https://stytch.com/docs/guides/passwords/strength-policy
   * @example 2
   */
  passwordStrengthScore?: number
  /**
   * If loading, submit button is disabled
   */
  isLoading?: boolean
  /**
   * Href to login page to easily direct user back to login page on error
   * @example /login
   */
  loginHref: string
}

export function ResetPasswordForm({
  isLoading,
  loginHref,
  onChange,
  onSubmit: onSubmitProp,
  passwordStrengthScore,
}: IResetPasswordFormProps) {
  const methods = useResetPasswordForm({
    defaultValues: {
      password: '',
    },
  })
  const { control, errors, handleSubmit, watch } = methods
  const { password: passwordValue } = watch()
  const { password: passwordErrors } = errors || {}

  const [showPasswordStrength, setShowPasswordStrength] = useState<boolean>(false)
  const [formError, setFormError] = useState<IApiError>()

  /**
   * <PasswordStrengthValidation /> is not displayed until the password field is focused
   */
  const passwordFieldFocusHandler = useCallback(() => {
    setShowPasswordStrength(true)
  }, [])

  const passwordValidationState = useMemo(() => {
    return getPasswordValidationState({
      errors: passwordErrors,
      score: passwordStrengthScore,
      value: passwordValue,
    })
  }, [passwordErrors, passwordValue, passwordStrengthScore])

  const onSubmit = async (formData: TResetPasswordFormSchema) => {
    const result = await onSubmitProp(formData)
    if (result?.errors) {
      // Set error based on api error
      setFormError(result.errors?.[0])
    }
  }

  const onError = () => {
    /**
     * The errors from the form schema are used to determine which requirements can be checked off
     * as the user enters a new password. If the user attempts to submit before all requirements are fulfilled,
     * we display a custom error message.
     */
    setFormError({
      message: PASSWORD_ERROR_MESSAGE,
      key: 'custom',
    })
  }

  const submitHandler = handleSubmit(onSubmit, onError)

  const passwordTextFieldProps: IControlledPasswordFieldProps['PasswordFieldProps'] = useMemo(
    () => ({
      dataTest: 'password-field',
      placeholder: 'Enter password',
      error: !!formError,
      helperText: formError ? (
        <ResetPasswordErrorMessage loginHref={loginHref} apiError={formError} />
      ) : (
        ''
      ),
      size: 'medium',
      id: 'new-password',
      autocomplete: 'new-password',
      onChange,
      onFocus: passwordFieldFocusHandler,
    }),
    [formError, onChange, passwordFieldFocusHandler, loginHref]
  )

  return (
    <form data-test='reset-password-form' onSubmit={submitHandler}>
      <Stack spacing={2}>
        <ControlledPasswordField
          control={control}
          name='password'
          PasswordFieldProps={passwordTextFieldProps}
        />

        {showPasswordStrength && (
          <PasswordStrengthValidation
            score={passwordStrengthScore}
            validationState={passwordValidationState}
          />
        )}

        <Button fullWidth type='submit' data-test='submit-button' disabled={isLoading}>
          Reset Password
        </Button>
      </Stack>
    </form>
  )
}
