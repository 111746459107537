import { IUpdateRoutingGuideCarrierRateInput } from '../../../../../../types/graphqlTypes'
import { TRoutingGuideCarrierSchema } from '../schema'
import { getRoutingGuideCarrierRateInput } from './getRoutingGuideCarrierRateInput'

export interface IGetUpdateRoutingGuideCarrierRateInput {
  formValues: TRoutingGuideCarrierSchema
  routingGuideCarrierRateId: string
}

export function getUpdateRoutingGuideCarrierRateInput({
  formValues,
  routingGuideCarrierRateId,
}: IGetUpdateRoutingGuideCarrierRateInput): IUpdateRoutingGuideCarrierRateInput {
  return {
    ...getRoutingGuideCarrierRateInput({ formValues }),
    routingGuideCarrierRateId,
  }
}
