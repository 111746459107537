import React, { useCallback } from 'react'
import { IPillSelectProps, PillSelect } from 'dpl'
import Box from 'dpl/core/Box'
import { IMarginBy } from '../../ProjectedMarginCard'

export interface IProjectedMarginToggleProps {
  marginBy: string
  /**
   * Callback function that is called when the option changes
   */
  onChange: (margin: IMarginBy) => void
}

const options = [
  { label: 'Monthly Total', value: 'monthly' },
  { label: 'Per Load', value: 'per_load' },
]

export function ProjectedMarginToggle({ marginBy, onChange }: IProjectedMarginToggleProps) {
  const changeHandler: IPillSelectProps<IMarginBy>['onChange'] = useCallback(
    newVal => {
      onChange(newVal)
    },
    [onChange]
  )

  return (
    <Box>
      <PillSelect clearable={false} value={marginBy} options={options} onChange={changeHandler} />
    </Box>
  )
}
