import { TAutocompleteOrganizationUserSchema } from '#components'
import {
  IContractRfpUserAssignmentInput,
  IContractRfpUserAssignmentRoles,
} from '#types/graphqlTypes'

interface IBuildUserAssignmentsInputProps {
  assignee: Nullable<TAutocompleteOrganizationUserSchema>
  additionalTeamMembers: Nullable<TAutocompleteOrganizationUserSchema>[]
}

export const buildUserAssignmentsInput = ({
  additionalTeamMembers,
  assignee,
}: IBuildUserAssignmentsInputProps): IContractRfpUserAssignmentInput[] => {
  return [
    { id: assignee?.id, role: IContractRfpUserAssignmentRoles.assignee },
    ...additionalTeamMembers.map(additionalTeamMember => ({
      id: additionalTeamMember?.id,
      role: IContractRfpUserAssignmentRoles.additional_team_member,
    })),
  ]
    .filter(({ id }) => Boolean(id))
    .map(({ id, role }) => ({
      organizationUserId: id || '',
      role,
    }))
}
