import React, { useEffect, useState } from 'react'
import { Spinner } from 'dpl/components/Spinner'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ListItemIcon,
  Button,
  Divider,
  Typography,
} from 'dpl/core'
import GraphIcon from 'dpl/icons/build/GraphIcon'
import { brandColors } from 'dpl/theme/colors'
import LeadTimeCaptionSvg from './lead-time-caption.svg?react'
import LeadTimeGraphSvg from './lead-time-graph.svg?react'
import ShortTermForecastCaptionSvg from './short-term-forecast-caption.svg?react'
import ShortTermForecastGraphSvg from './short-term-forecast-graph.svg?react'
import WeightGraphSvg from './weight-graph.svg?react'

const useStyles = makeStyles(theme => ({
  listItem: {
    gap: theme.spacing(2.75),
    padding: theme.spacing(0, 0, 3),
  },
  itemBullet: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: '50%',
    flexShrink: 0,
    background: brandColors.coolGray4,
    '&.warning': {
      background: brandColors.warning1,
    },
    '&.info': {
      background: brandColors.skyBlue4,
    },
  },
  graphCard: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    flexGrow: 1,
    borderColor: brandColors.skyBlue1,
  },
  graphCardContent: {
    position: 'relative',
  },
  forecastGraph: {
    padding: theme.spacing(3, 0),
  },
  svgGraph: {
    width: '100%',
    height: 'auto',
  },
  svgCaption: {
    paddingLeft: theme.spacing(2),
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: brandColors.white,
    position: 'absolute',
    zIndex: 1,
  },
}))

export function DetailedData() {
  const [showCards, setShowCards] = useState(false)
  const [hideLoader, setHideLoader] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    if (showCards) {
      timeoutId = setTimeout(() => {
        setHideLoader(true)
      }, 750)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [showCards])

  return (
    <Box pt={2}>
      <List>
        <ListItem className={classes.listItem} alignItems='flex-start' disableGutters>
          <ListItemIcon>
            <span className={`${classes.itemBullet} warning`} />
          </ListItemIcon>
          <ListItemText
            primary='Cost is expected to increase in the coming days'
            primaryTypographyProps={{ variant: 'body1' }}
          />
        </ListItem>
        {showCards && (
          <Card className={classes.graphCard}>
            <CardHeader
              subheader={
                <Box display='flex' alignItems='center' gap={0.5}>
                  <GraphIcon />
                  <Typography variant='overline'>Short-Term Forecast</Typography>
                </Box>
              }
            />
            <CardContent className={`${classes.graphCardContent} ${classes.forecastGraph}`}>
              <ShortTermForecastGraphSvg className={classes.svgGraph} />
              <ShortTermForecastCaptionSvg className={classes.svgCaption} />
              {!hideLoader && (
                <div className={classes.loaderContainer}>
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
        )}
        <ListItem className={classes.listItem} alignItems='flex-start' disableGutters>
          <ListItemIcon>
            <span className={classes.itemBullet} />
          </ListItemIcon>
          <ListItemText
            primary='Costs for high shipment weights are estimated to be 5-7% higher than low weight shipments on this lane'
            primaryTypographyProps={{ variant: 'body1' }}
          />
        </ListItem>
        {showCards && (
          <Card className={classes.graphCard}>
            <CardHeader
              subheader={
                <Box display='flex' alignItems='center' gap={0.5}>
                  <GraphIcon />
                  <Typography variant='overline'>Impact of weight on cost</Typography>
                </Box>
              }
            />
            <CardContent className={classes.graphCardContent}>
              <WeightGraphSvg className={classes.svgGraph} />
              {!hideLoader && (
                <div className={classes.loaderContainer}>
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
        )}
        <ListItem className={classes.listItem} alignItems='flex-start' disableGutters>
          <ListItemIcon>
            <span className={`${classes.itemBullet} info`} />
          </ListItemIcon>
          <ListItemText
            primary='Compared to pre-booking the load +48 hours out, costs are X% higher if you wait until 5 hours before scheduled pickup'
            primaryTypographyProps={{ variant: 'body1' }}
          />
        </ListItem>
        {showCards && (
          <Card className={classes.graphCard}>
            <CardHeader
              subheader={
                <Box display='flex' alignItems='center' gap={0.5}>
                  <GraphIcon />
                  <Typography variant='overline'>Impact of Lead Time on Cost</Typography>
                </Box>
              }
            />
            <CardContent className={classes.graphCardContent}>
              <LeadTimeGraphSvg className={classes.svgGraph} />
              <LeadTimeCaptionSvg />
              {!hideLoader && (
                <div className={classes.loaderContainer}>
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </List>
      {!showCards && (
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Divider className={classes.divider} />
          <Button
            onClick={() => setShowCards(true)}
            color='secondary'
            startIcon={<GraphIcon size='large' />}>
            Load More Detailed Data
          </Button>
          <Divider className={classes.divider} />
        </Box>
      )}
    </Box>
  )
}
