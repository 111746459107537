import React from 'react'
import { Grid, Typography } from 'dpl/core'
import { DEFAULT_EMPTY_ACTIVITY_STRING } from '../components'

export interface IAddedCarrierEventJson {
  capacity?: string[]
  carrier_user?: string[]
  rate?: string[]
  sequence_number?: string[]
}

// carrier manager currently is not being returned from the BE with the event. Update to include once BE sends the data
export const getAddedCarrierActivityDetail = (rowEventJson: IAddedCarrierEventJson) => {
  const {
    capacity = DEFAULT_EMPTY_ACTIVITY_STRING,
    carrier_user: carrierUser = DEFAULT_EMPTY_ACTIVITY_STRING,
    rate = DEFAULT_EMPTY_ACTIVITY_STRING,
    sequence_number: sequenceNumber = DEFAULT_EMPTY_ACTIVITY_STRING,
  } = rowEventJson
  return (
    <Grid container padding={2}>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Carrier Rank</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          {sequenceNumber}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Carrier Contact</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          {carrierUser}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Rate</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          ${rate}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Capacity</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          {capacity}
        </Typography>
      </Grid>
    </Grid>
  )
}
