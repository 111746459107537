import React from 'react'
import SvgIcon from '../components/SvgIcon'
import { CostAdditivePositiveSvg } from '../icons'

function CostAdditivePositiveIcon(props) {
  return (
    <SvgIcon
      className='RogersSvgIcon-CostAdditivePositive'
      component={CostAdditivePositiveSvg}
      {...props}
    />
  )
}

export default CostAdditivePositiveIcon
