import { PROTECTED_PATHS } from '#constants/paths'
import { usePermissionsContext } from 'auth/common/context'
import DefaultCompany from 'dpl/assets/DefaultCompany.svg'
import PeopleIcon from 'dpl/icons/build/PeopleIcon'
import { IAppLayoutProps } from 'dpl/templates/AppLayout'
import { IOrgNavMenuLink } from 'dpl/templates/AppLayout/Nav/OrgNavButton/OrgNavMenuContents'
import { useFlagsContext } from 'flags'
import { useOrganizationNavQuery } from '../graphql/OrganizationNav'

const { VITE_ADMIN_CONSOLE_URL } = import.meta.env

const CUSTOMERS_LINK: IOrgNavMenuLink = {
  title: 'Customers',
  href: PROTECTED_PATHS.customers,
  Icon: PeopleIcon,
}

interface IUseRootLayoutOrgInfoProps {
  /**
   * @default false
   */
  skip?: boolean
}

interface IUseRootLayoutOrgInfoState
  extends Pick<IAppLayoutProps, 'isOrgNavButtonEnabled' | 'orgNavItem' | 'orgNavButtonProps'> {}

export const useRootLayoutOrgInfo = ({
  skip = false,
}: IUseRootLayoutOrgInfoProps): IUseRootLayoutOrgInfoState => {
  const { userPermissions } = usePermissionsContext()
  const isOrgAdmin = userPermissions['org_admin_app.read_app']

  const { isFlagEnabled } = useFlagsContext()
  const isOrgNavUpdatesEnabled = isFlagEnabled('enable_org_nav_updates')
  const isShipperManagementEnabled = isFlagEnabled('shipper_management')

  const { data } = useOrganizationNavQuery({
    skip,
  })
  const { currentSession } = data || {}
  const { organizationUser } = currentSession || {}
  const { organization } = organizationUser || {}
  const { name: organizationName = '' } = organization || {}
  const { thumbLogo } = organization || {}
  const { url: orgLogoUrl } = thumbLogo || {}

  return {
    isOrgNavButtonEnabled: isOrgNavUpdatesEnabled,
    orgNavItem: {
      name: organizationName,
      href: PROTECTED_PATHS.organizationProfile,
      imgSrc: orgLogoUrl || DefaultCompany,
      isAdmin: isOrgAdmin,
    },
    orgNavButtonProps: {
      isAdmin: isOrgAdmin,
      orgName: organizationName,
      orgImgSrc: orgLogoUrl || DefaultCompany,
      orgAdminHref: VITE_ADMIN_CONSOLE_URL,
      orgProfileHref: PROTECTED_PATHS.organizationProfile,
      orgNavMenuLinks: [...(isShipperManagementEnabled ? [CUSTOMERS_LINK] : [])],
    },
  }
}
