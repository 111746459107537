import React from 'react'
import { IFileObject } from 'dpl/components/Dropzone/types'
import { FORMATS } from 'dpl/constants'
import { IconButton, LinearProgress, makeStyles, Stack, Typography } from 'dpl/core'
import { AttachmentIcon, CloseIcon } from 'dpl/icons/build'
import { brandColors } from 'dpl/theme/colors'
import moment from 'moment'

const useAttachedFileStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'inline-flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: brandColors.coolGray3,
    borderRadius: '4px',
    padding: '12px',
    gap: theme.spacing(1),
  },
  iconContainer: {
    backgroundColor: brandColors.coolGray2,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    padding: '17px',
  },
  titleContainer: {
    flex: 1,
    marginLeft: '12px',
    marginRight: '12px',
    justifyContent: 'center',
    gap: '4px',
  },
}))

export interface IAttachedFileProps {
  clearFile: () => void
  file: IFileObject
  /**
   * @description Is the file being processed or imported?
   * @default false
   */
  loading?: boolean
}

export function AttachedFile({ clearFile, file: { file }, loading }: IAttachedFileProps) {
  const classes = useAttachedFileStyles()

  const formattedDate = moment(file.lastModified).format(FORMATS.monthDayYearTimeFormat12hUppercase)

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <AttachmentIcon size='xlarge' color='coolGray5' />
      </div>

      <Stack className={classes.titleContainer}>
        <Typography color={brandColors.coolGray8} variant='body2'>
          {file.name}
        </Typography>

        {loading ? (
          <>
            <LinearProgress variant='indeterminate' />
            <Typography color={brandColors.coolGray5} variant='caption'>
              This may take a few minutes...
            </Typography>
          </>
        ) : (
          <Typography color={brandColors.coolGray5} variant='caption'>
            {formattedDate}
          </Typography>
        )}
      </Stack>

      <IconButton onClick={clearFile}>
        <CloseIcon color='coolGray5' size='large' />
      </IconButton>
    </div>
  )
}
