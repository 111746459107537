import React, { memo, useEffect, useMemo } from 'react'
import { Control } from 'react-hook-form'
import { FormModal, IFormModalProps, IModalAction } from 'dpl/components/Modals'
import { Box } from 'dpl/core'
import isEmpty from 'lodash/isEmpty'
import { IVolumeTypes } from '../../types'
import { DEFAULT_FLAT_VOLUME_PER_MONTH } from '../ContractLaneInformationForm/constants'
import { useContractLaneInformationFormContext } from '../ContractLaneInformationForm/hooks'
import { IContractLaneInformationSchema } from '../ContractLaneInformationForm/schema'
import { getDateVolumeRange } from '../ContractLaneInformationForm/utils'
import { VolumePreferenceFormBody } from './components/VolumePreferenceFormBody/VolumePreferenceFormBody'

export interface IVolumePreferenceModalProps extends Pick<IFormModalProps, 'open' | 'onClose'> {
  onSuccess: () => void
  /**
   * @default 'VolumePreferenceModal'
   */
  dataTest?: string
  control: Control<IContractLaneInformationSchema>
}

export const VolumePreferenceModal = memo(
  ({
    control,
    dataTest = 'VolumePreferenceFormModal',
    onClose,
    onSuccess,
    open,
  }: IVolumePreferenceModalProps) => {
    const { clearErrors, errors, setValue, trigger, watch } =
      useContractLaneInformationFormContext()

    const { contractDuration, volume } = watch(['contractDuration', 'volume'])

    const { volumeType } = volume || {}
    const { dynamicVolumePerMonth = [] } = volume || {}
    const { contractEndDate, contractStartDate } = contractDuration

    const defaultDynamicVolumePerMonthValue = useMemo(
      () =>
        getDateVolumeRange({
          startDate: contractStartDate,
          endDate: contractEndDate,
          volume: DEFAULT_FLAT_VOLUME_PER_MONTH,
        }),
      [contractStartDate, contractEndDate]
    )

    /** Set default value for dynamicVolumePerMonth */
    useEffect(() => {
      if (volumeType === IVolumeTypes.dynamicVolume && !dynamicVolumePerMonth.length) {
        setValue('volume.dynamicVolumePerMonth', defaultDynamicVolumePerMonthValue)
      }
    })

    const selectVolumeTypeHandler = (volumeType: IVolumeTypes) => {
      if (volumeType === IVolumeTypes.dynamicVolume) {
        clearErrors('volume.flatVolumePerMonth')
      }
      if (volumeType === IVolumeTypes.flatVolume) {
        clearErrors('volume.dynamicVolumePerMonth')
      }
    }

    const { volume: volumeErrors } = errors || {}
    const {
      dynamicVolumePerMonth: dynamicVolumePerMonthErrors,
      flatVolumePerMonth: flatVolumePerMonthError,
    } = volumeErrors || {}

    const actions: IModalAction[] = [
      {
        label: 'Cancel',
        type: 'dismiss',
        dataTest: `${dataTest}-cancel`,
        action: onClose,
      },
      {
        label: 'Save Changes',
        type: 'confirm',
        dataTest: `${dataTest}-submit`,
        action: onSuccess,
        disabled:
          volumeType === IVolumeTypes.flatVolume
            ? !isEmpty(flatVolumePerMonthError)
            : !isEmpty(dynamicVolumePerMonthErrors),
      },
    ]

    return (
      <FormModal
        actions={actions}
        dataTest={dataTest}
        open={open}
        onClose={onClose}
        includeCloseIconButton>
        <Box mt={1}>
          <VolumePreferenceFormBody
            selectVolumeTypeHandler={selectVolumeTypeHandler}
            contractDuration={contractDuration}
            volume={volume}
            control={control}
            trigger={trigger}
            dynamicVolumePerMonthErrors={dynamicVolumePerMonthErrors}
            flatVolumePerMonthError={flatVolumePerMonthError}
          />
        </Box>
      </FormModal>
    )
  }
)
