import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { PercentageInput, IPercentageInputProps } from 'dpl/components/PercentageInput'

type IBaseControllerProps = ControllerProps<React.ComponentType<IPercentageInputProps>>
export type TTransformFunction = (value: string) => number | null

export interface IControlledPercentageInputProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue' | 'disabled'> {
  PercentageInputProps?: Partial<IPercentageInputProps>
  transformFunction?: TTransformFunction
}

const DEFAULT_TRANSFORM_FUNCTION: TTransformFunction = (a: string) => Number(a)

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledPercentageInput
 *   name='amount'
 *   control={control}
 *   PercentageInputProps={{
 *     label: 'Amount',
 *     placeholder: '%',
 *   }}
 * />
 * ```
 */
const ControlledPercentageInput = memo<IControlledPercentageInputProps>(
  ({
    name,
    control,
    disabled = false,
    defaultValue = '',
    PercentageInputProps = {},
    transformFunction = DEFAULT_TRANSFORM_FUNCTION,
  }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onBlur, onChange, value }) => {
          const changeHandler = ({ value }: { value: string }) => {
            if (value) {
              onChange(transformFunction(value))
            } else {
              onChange()
            }
          }

          const clearHandler = () => {
            onChange(transformFunction(''))
          }

          return (
            <PercentageInput
              {...PercentageInputProps}
              onValueChange={changeHandler}
              onBlur={onBlur}
              onClear={clearHandler}
              value={value}
              disabled={disabled}
            />
          )
        }}
      />
    )
  }
)

export default ControlledPercentageInput
