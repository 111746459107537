import React, { useCallback } from 'react'
import { UNPROTECTED_PATHS } from '#constants/paths'
import { IUseAuthenticationTokenProps, useAuthenticationToken } from 'auth/b2b/hooks'
import { RadialSpinner } from 'dpl/components/RadialSpinner'
import { useAnalytics } from '../../hooks/useAnalytics'

export function Authenticate() {
  const { hasLoadedAirhorn, trackEvent } = useAnalytics()

  const discoveryTokenErrorHandler = useCallback<
    NonNullable<IUseAuthenticationTokenProps['onDiscoveryTokenError']>
  >(
    error => {
      trackEvent('Authenticate', 'DISCOVERY_TOKEN_ERROR', { error })
    },
    [trackEvent]
  )

  const discoveryTokenSuccessHandler = useCallback(() => {
    trackEvent('Authenticate', 'DISCOVERY_TOKEN_SUCCESS')
  }, [trackEvent])

  const multiTenantTokenErrorHandler = useCallback<
    NonNullable<IUseAuthenticationTokenProps['onMultiTenantTokenError']>
  >(
    error => {
      trackEvent('Authenticate', 'MULTI_TENANT_TOKEN_ERROR', { error })
    },
    [trackEvent]
  )

  const multiTenantTokenSuccessHandler = useCallback<
    NonNullable<IUseAuthenticationTokenProps['onMultiTenantTokenSuccess']>
  >(() => {
    trackEvent('Authenticate', 'MULTI_TENANT_TOKEN_SUCCESS')
  }, [trackEvent])

  const ssoTokenErrorHandler = useCallback<
    NonNullable<IUseAuthenticationTokenProps['onSsoTokenError']>
  >(
    error => {
      trackEvent('Authenticate', 'SSO_TOKEN_ERROR', { error })
    },
    [trackEvent]
  )

  const ssoTokenSuccessHandler = useCallback<
    NonNullable<IUseAuthenticationTokenProps['onSsoTokenSuccess']>
  >(() => {
    trackEvent('Authenticate', 'SSO_TOKEN_SUCCESS')
  }, [trackEvent])

  const { loading } = useAuthenticationToken({
    readyToAuthenticate: hasLoadedAirhorn,
    magicLinkInvalidRedirectTo: UNPROTECTED_PATHS.magicLink,
    onDiscoveryTokenError: discoveryTokenErrorHandler,
    onDiscoveryTokenSuccess: discoveryTokenSuccessHandler,
    onMultiTenantTokenError: multiTenantTokenErrorHandler,
    onMultiTenantTokenSuccess: multiTenantTokenSuccessHandler,
    onSsoTokenError: ssoTokenErrorHandler,
    onSsoTokenSuccess: ssoTokenSuccessHandler,
  })

  return loading ? <RadialSpinner position='absolute' size='large' /> : null
}
