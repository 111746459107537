import { organizationShipperSchema } from '#components/ControlledAutocompleteOrganizationShipper'
import * as yup from 'yup'

function valueNotNull(value: unknown) {
  return value !== null
}

export const editQuoteDetailsFormSchema = yup.object({
  customer: organizationShipperSchema
    .nullable()
    .test('not-null', 'You must select a customer.', valueNotNull),
  customerReference: yup.string(),
  shipmentNumber: yup
    .string()
    .nullable()
    .when('$status', {
      is: 'won',
      then: (schema: yup.Schema) => schema.required('You must provide the shipment number.'),
    }),
})

export interface EditQuoteDetailsFormSchema
  extends yup.InferType<typeof editQuoteDetailsFormSchema> {}
