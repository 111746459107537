import * as yup from 'yup'
import { FactorsSchema } from './FactorsSchema'
import { RateStrategySchema } from './RateStrategySchema'
import { ReasonSchema } from './ReasonSchema'
import { TimeframeSchema } from './TimeframeSchema'

export const AdjustmentRuleFormSchema = yup.object({
  reason: ReasonSchema,
  rateStrategy: RateStrategySchema,
  timeframe: TimeframeSchema,
  factors: FactorsSchema,
})

export type TAdjustmentRuleFormSchema = yup.InferType<typeof AdjustmentRuleFormSchema>
