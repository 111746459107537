import React, { useMemo, useState } from 'react'
import { IconButton, Menu, MenuProps } from '../../core'
import { SelectCountryOption } from './components'
import { COUNTRY_FLAGS } from './constants'
import { IGeocodingAllowedCountryEnum, TSelectCountryChangeHandler } from './types'
import { getCountryOptions } from './utils'

export interface ISelectCountryProps {
  countries: IGeocodingAllowedCountryEnum[]
  onChange: TSelectCountryChangeHandler
  value: IGeocodingAllowedCountryEnum
  MenuProps?: Omit<MenuProps, 'open' | 'anchorEl' | 'onClose' | 'onClick' | 'hidden'>
  /**
   * @default 'SelectCountry'
   */
  dataTest?: string
}

export function SelectCountry({
  MenuProps = {},
  countries,
  onChange,
  value,
  dataTest = 'SelectCountry',
}: ISelectCountryProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const openHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeHandler = () => {
    setAnchorEl(null)
  }

  const options = useMemo(() => {
    return getCountryOptions(countries)
  }, [countries])

  const Flag = COUNTRY_FLAGS[value]

  return (
    <div data-test={dataTest}>
      <IconButton
        aria-label='select country'
        data-test={`${dataTest}-button`}
        onClick={openHandler}>
        <Flag data-test={`${dataTest}-selected`} title={value} height={12} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        hidden={options.length <= 1}
        open={open}
        onClose={closeHandler}
        onClick={closeHandler}
        {...MenuProps}>
        {options.map(({ code, label }) => (
          <SelectCountryOption
            key={code}
            code={code}
            label={label}
            onChange={onChange}
            selectedCode={value}
          />
        ))}
      </Menu>
    </div>
  )
}

export default SelectCountry
