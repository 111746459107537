import { DefaultValues, useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RoutingGuideManagerSchema, TRoutingGuideManagerSchema } from '../schema'

export const getDefaultValues = (values: DefaultValues<TRoutingGuideManagerSchema>) => {
  return RoutingGuideManagerSchema.cast(
    {
      laneManager: null,
      ...values,
    },
    {
      assert: false,
    }
  )
}

export interface IUseRoutingGuideManagerFormProps
  extends Omit<UseFormOptions<TRoutingGuideManagerSchema>, 'resolver'> {}

export const useRoutingGuideManagerForm = ({
  defaultValues = {},
  ...rest
}: IUseRoutingGuideManagerFormProps) => {
  return useForm<TRoutingGuideManagerSchema>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: yupResolver(RoutingGuideManagerSchema),
    ...rest,
  })
}
