import React from 'react'
import { Link } from 'dpl/core'
import { AuthenticationCard, IAuthenticationCardProps } from '../AuthenticationCard'

export interface IForgotPasswordCardProps
  extends Pick<IAuthenticationCardProps, 'termsOfServiceUrl'> {
  /**
   * Email address that the password magic link was sent to
   */
  magicLinkSentTo: string
  /**
   * Href to go back to login page
   */
  loginHref: string
}

export function ForgotPasswordCard({
  loginHref,
  magicLinkSentTo,
  termsOfServiceUrl,
}: IForgotPasswordCardProps) {
  return (
    <AuthenticationCard
      dataTest='forgot-password-card'
      title='Forgot Password'
      subheader={`An email was sent to ${magicLinkSentTo}. Open the email and click the link to continue.`}
      termsOfServiceUrl={termsOfServiceUrl}>
      <Link href={loginHref} variant='subtitle1'>
        Try a different email
      </Link>
    </AuthenticationCard>
  )
}
