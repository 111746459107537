import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { IPillSelectProps, PillSelect } from 'dpl/components'

type IBaseControllerProps = ControllerProps<React.ComponentType<IPillSelectProps>>

export interface IControlledPillSelectProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  PillSelectProps: Omit<IPillSelectProps, 'onChange' | 'value'>
}

export const ControlledPillSelect = memo<IControlledPillSelectProps>(
  ({ PillSelectProps, control, defaultValue, name }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          return <PillSelect {...PillSelectProps} onChange={onChange} value={value} />
        }}
      />
    )
  }
)
