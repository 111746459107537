import { IContractRfpVersionLaneRateRateTypes } from '#types/graphqlTypes'
import isNil from 'lodash/isNil'

export interface ICalculateLinehaulRateParams {
  carrierRate: Nullable<number>
  marginAmount: Nullable<number>
  totalFuel: Nullable<number>
}

export interface ICalculateAllInRateParams {
  carrierRate: Nullable<number>
  marginAmount: Nullable<number>
}

export interface ICalculateLineahaulTotalFuelParams {
  miles: Nullable<number>
  fuelRate: Nullable<number>
}

export interface ICalculateRateParams
  extends ICalculateAllInRateParams,
    ICalculateLineahaulTotalFuelParams {
  rateType: IContractRfpVersionLaneRateRateTypes
  strategy: 'calculated' | 'custom'
  marginPercentage: Nullable<number>
  shipperRate: Nullable<number>
}

export interface IIsCustomRateParams {
  fuelRate: Nullable<number>
  marginAmount: Nullable<number>
  rateType: IContractRfpVersionLaneRateRateTypes
  shipperRate: Nullable<number>
}

export interface ICalculateMarginAmountParams {
  carrierRate: Nullable<number>
  marginPercentage: Nullable<number>
}

export interface ICalculateMarginPercentageParams {
  carrierRate: Nullable<number>
  marginAmount: Nullable<number>
}

export interface IGetMarginColorParams {
  marginAmount: Nullable<number>
}

export function calculateLinehaulTotalFuel({
  fuelRate,
  miles,
}: ICalculateLineahaulTotalFuelParams) {
  if (!fuelRate || !miles) return 0

  return miles * fuelRate
}

export function calculateAllInRate({ carrierRate, marginAmount }: ICalculateAllInRateParams) {
  if (isNil(carrierRate) || isNil(marginAmount)) return null

  return carrierRate + marginAmount
}

export function calculateLinehaulRate({
  carrierRate,
  marginAmount,
  totalFuel,
}: ICalculateLinehaulRateParams) {
  if (isNil(carrierRate) || isNil(marginAmount) || isNil(totalFuel)) return null

  return carrierRate + marginAmount - totalFuel
}

export function isCustomRate({
  fuelRate,
  marginAmount,
  rateType,
  shipperRate,
}: IIsCustomRateParams) {
  return (
    shipperRate &&
    !marginAmount &&
    !fuelRate &&
    rateType === IContractRfpVersionLaneRateRateTypes.all_in
  )
}

export function calculateRate({
  carrierRate,
  fuelRate,
  marginAmount,
  marginPercentage,
  miles,
  rateType,
  shipperRate,
  strategy,
}: ICalculateRateParams) {
  if (strategy === 'custom' || (shipperRate && !marginAmount && !marginPercentage))
    return {
      contractRate: shipperRate,
      totalFuel: null,
      calculatedMarginAmount: null,
      calculatedMarginPercentage: null,
      calculatedContractRate: null,
      calculatedFuelRate: null,
    }

  if (rateType === IContractRfpVersionLaneRateRateTypes.linehaul) {
    const totalFuel = calculateLinehaulTotalFuel({ fuelRate, miles })

    return {
      totalFuel,
      contractRate: calculateLinehaulRate({ carrierRate, marginAmount, totalFuel }),
      calculatedMarginAmount: marginAmount,
      calculatedMarginPercentage: marginPercentage,
      calculatedContractRate: carrierRate,
      calculatedFuelRate: fuelRate,
    }
  }

  return {
    contractRate: calculateAllInRate({ carrierRate, marginAmount }),
    totalFuel: null,
    calculatedMarginAmount: marginAmount,
    calculatedMarginPercentage: marginPercentage,
    calculatedContractRate: carrierRate,
    calculatedFuelRate: null,
  }
}

export function calculateMarginAmount({
  carrierRate,
  marginPercentage,
}: ICalculateMarginAmountParams) {
  if (carrierRate === 0) return 0
  if (!carrierRate) return null
  if (marginPercentage === null || marginPercentage === undefined) return null

  // Remove the decimal point and round to 3 decimal places and keep the type as number
  return parseFloat((carrierRate * (marginPercentage / 100)).toFixed(3))
}

export function calculateMarginPercentage({
  carrierRate,
  marginAmount,
}: ICalculateMarginPercentageParams) {
  if (carrierRate === 0) return 0
  if (!carrierRate) return null
  if (marginAmount === null || marginAmount === undefined) return null

  // Remove the decimal point and round to 3 decimal places and keep the type as number
  return parseFloat(((marginAmount * 100) / carrierRate).toFixed(3))
}

export function getMarginColor({ marginAmount }: IGetMarginColorParams) {
  if (!marginAmount) return 'textSecondary'

  return marginAmount > 0 ? 'green' : 'error'
}
