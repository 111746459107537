import React, { memo } from 'react'
import { makeStyles, Typography, Button } from 'dpl/core'
import { AddIcon, SearchIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme/colors'

export interface IRoutingGuideLanesEmptyCardProps {
  /**
   * toggles open the routing guide side panel form
   */
  onAddRoutingGuideLaneClick: () => void
}

const useRoutingGuideLanesEmptyCardStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: '10%',
    backgroundColor: brandColors.coolGray1,
  },
  icon: {
    width: `${theme.spacing(4)} !important`,
    height: `${theme.spacing(4)} !important`,
    color: brandColors.coolGray5,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

export const RoutingGuideLanesEmptyCard = memo(
  ({ onAddRoutingGuideLaneClick }: IRoutingGuideLanesEmptyCardProps) => {
    const classes = useRoutingGuideLanesEmptyCardStyles()
    return (
      <div className={classes.root}>
        <div className={classes.iconContainer}>
          <SearchIcon className={classes.icon} />
        </div>
        <div className={classes.textContainer}>
          <Typography variant='subtitle1' mb={0.5}>
            No Routing Guide Lanes
          </Typography>
          <Typography variant='body2'>Add lanes to leverage the auto-offer benefits.</Typography>
        </div>
        <Button
          size='medium'
          onClick={onAddRoutingGuideLaneClick}
          startIcon={<AddIcon size='large' />}>
          Add New Lane
        </Button>
      </div>
    )
  }
)
