import React, { useCallback } from 'react'
import { DateRangePicker, IDateRangePickerProps } from 'dpl'
import { IDataGridFilterProps } from 'dpl/components/DataGrid'
import { IDateRangePickerOption } from 'dpl/components/DateRangePicker/types'
import moment from 'moment'
import { IDataGridDueDate } from './types'

export interface IDataGridDueDateProps extends IDataGridFilterProps<IDataGridDueDate | null> {
  /**
   * @default 'DataGridDueDate'
   */
  dataTest?: string
}

const OPTIONS: IDateRangePickerProps['options'] = [
  {
    label: 'Select date range',
    key: 'custom-range',
    type: 'calendar',
  },
  {
    key: 'past-due',
    label: 'Past Due',
    dateRange: {
      startAt: moment(0).toISOString(),
      endAt: moment().toISOString(),
    },
  },
  {
    key: 'due-this-week',
    label: 'Due This week',
    dateRange: {
      startAt: moment().startOf('week').toISOString(),
      endAt: moment().endOf('week').toISOString(),
    },
  },
  {
    key: 'due-next-week',
    label: 'Due Next week',
    dateRange: {
      startAt: moment().add(1, 'week').startOf('week').toISOString(),
      endAt: moment().add(1, 'week').endOf('week').toISOString(),
    },
  },
]

export function DataGridDueDate({
  dataTest = 'DataGridDueDate',
  name,
  onChange,
  value: valueProp,
}: IDataGridDueDateProps) {
  const selectHandler = useCallback<NonNullable<IDateRangePickerProps['onSelect']>>(
    newValue => {
      const { dateRange, key, label } = newValue || {}
      const { endAt, startAt } = dateRange || {}

      onChange({
        name,
        value: {
          dateRange: {
            startAt,
            endAt,
          },
          label,
          key,
        },
      })
    },
    [onChange, name]
  )

  return (
    <div>
      <DateRangePicker
        dataTest={dataTest}
        value={(valueProp as IDateRangePickerOption) || null}
        onSelect={selectHandler}
        label='Due Date'
        options={OPTIONS}
      />
    </div>
  )
}
