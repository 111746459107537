import React, { memo, useMemo } from 'react'
import {
  formatCostStrategy,
  formatMarginStrategy,
  IFormatCostStrategyArgs,
} from '#routes/spot-rates-control-panel/utils'
import { IRuleTypeEnum } from '#types/rates'
import { Typography } from 'dpl/core'
import { TAdjustmentRulesRow } from '../../types'

export interface IAdjustmentRuleStrategyProps
  extends Pick<
    TAdjustmentRulesRow,
    'adjustmentType' | 'adjustmentValue' | 'ruleAction' | 'isRowDisabled'
  > {
  /**
   * @default 'AdjustmentRuleStrategy'
   */
  dataTest?: string
}

export const AdjustmentRuleStrategy = memo<IAdjustmentRuleStrategyProps>(
  ({
    adjustmentType,
    adjustmentValue,
    dataTest = 'AdjustmentRuleStrategy',
    isRowDisabled = false,
    ruleAction,
  }) => {
    const label = useMemo(() => {
      const adjustmentPercentage = Math.round((adjustmentValue || 0) * 100)
      if (adjustmentType === 'cost') {
        return formatCostStrategy({
          costAdditiveShiftPercentage: adjustmentPercentage,
          costBasePercentile: adjustmentValue,
          costRuleType: ruleAction as IFormatCostStrategyArgs['costRuleType'],
        })
      }
      return formatMarginStrategy({
        marginAdditivePercentage: adjustmentPercentage,
        marginBasePercentage: adjustmentPercentage,
        marginRuleType: ruleAction as IRuleTypeEnum,
      })
    }, [adjustmentType, ruleAction, adjustmentValue])

    return (
      <div data-test={dataTest}>
        <Typography
          variant='body2'
          data-test={`${dataTest}-label`}
          color={isRowDisabled ? 'textSecondary' : 'textPrimary'}>
          {label}
        </Typography>
      </div>
    )
  }
)
