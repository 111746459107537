import { organizationShipperSchema } from '#components/ControlledAutocompleteOrganizationShipper/organizationShipperSchema'
import * as yup from 'yup'
import { EQUIPMENT_TYPES } from '../../types'
import { LocationFactorSchema } from '../LocationFactorSchema'

/**
 * Each factor schema must have field `includeFactor`.
 */
export const FactorsSchema = yup
  .object({
    pickupLocation: LocationFactorSchema,
    deliveryLocation: LocationFactorSchema,
    customer: yup.object({
      includeFactor: yup.boolean(),
      value: organizationShipperSchema.nullable().when('includeFactor', {
        is: true,
        then: schema => schema.required('You must enter a customer name.'),
      }),
    }),
    equipment: yup.object({
      includeFactor: yup.boolean(),
      value: yup
        .string<EQUIPMENT_TYPES>()
        .nullable()
        .when('includeFactor', {
          is: true,
          then: schema => schema.required('You must select an equipment type.'),
        }),
    }),
  })
  .test('min-one-factor', 'You must enable at least one factor.', factors => {
    return Object.values(factors).some(factor => !!factor?.includeFactor)
  })

export type TFactorsSchema = yup.InferType<typeof FactorsSchema>
