import React from 'react'
import { Box, Grid, Typography } from 'dpl/core'
import { LaneArrowIcon } from 'dpl/icons'

export interface IUpdatedCarrierEventJson {
  capacity?: string[]
  carrier_manager?: string[]
  carrier_user?: string[]
  rate?: string[]
  sequence_number?: string[]
}
export const getUpdatedCarrierActivityDetail = (rowEventJson: IUpdatedCarrierEventJson) => {
  const formattedKeyValues = {
    capacity: 'Capacity',
    carrier_manager: 'Carrier Manager',
    carrier_user: 'Carrier Contact',
    rate: 'Rate',
    sequence_number: 'Carrier Rank',
  }

  return (
    <Box padding={2}>
      {Object.entries(rowEventJson).map(([key, value]) => {
        if (!(key in formattedKeyValues)) {
          return null
        }
        const label = formattedKeyValues[key as keyof typeof formattedKeyValues]
        return (
          <Grid container key={`carrier-update-${key}`}>
            <Grid item xs={2} sx={{ textAlign: 'left' }}>
              <Typography variant='caption'>{label}</Typography>
            </Grid>
            <Grid item xs={9} sx={{ textAlign: 'left' }}>
              <Box display='flex'>
                <Typography variant='caption' color='textSecondary'>
                  {key === 'rate' ? `$${value[0]}` : value[0]}
                </Typography>
                <Typography variant='caption' color='textSecondary' ml={1} mr={1}>
                  <LaneArrowIcon />
                </Typography>
                <Typography variant='caption' color='textSecondary'>
                  {key === 'rate' ? `$${value[1]}` : value[1]}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )
      })}
    </Box>
  )
}
