import React from 'react'
import { currencyFormatter } from '#utils/currencyFormatter'
import classnames from 'classnames'
import { Box, makeStyles, Theme } from 'dpl/core'
import { brandColors, avatarColors } from 'dpl/theme/colors'

const useStyles = makeStyles<Theme, { isDark: boolean }>(() => ({
  increment: {
    color: ({ isDark }) => (isDark ? brandColors.green2 : brandColors.green3),
  },
  decrement: {
    color: ({ isDark }) => (isDark ? avatarColors.roseRed : brandColors.error1),
  },
}))

interface RateDifferenceProps {
  value: number
  dataTest?: string
  variant?: 'light' | 'dark'
  isPercentage?: boolean
}

/**
 * Component used to highlight rate difference by applying colors.
 */
export function RateDifference({
  dataTest,
  isPercentage = false,
  value,
  variant = 'light',
}: RateDifferenceProps) {
  const percentValue = `${value.toFixed(2)}%`
  const currencyValue = currencyFormatter(value)
  const classes = useStyles({ isDark: variant === 'dark' })
  const isIncrement = value > 0
  const className = classnames({
    [classes.increment]: isIncrement,
    [classes.decrement]: value < 0,
  })

  const formattedValue = isPercentage ? percentValue : currencyValue

  return (
    <Box className={className} component='span' data-test={dataTest}>
      {isIncrement ? '+' : ''}
      {formattedValue}
    </Box>
  )
}
