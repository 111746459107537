import { organizationShipperSchema } from '#components/ControlledAutocompleteOrganizationShipper'
import * as yup from 'yup'

function valueNotNull(value: unknown) {
  return value !== null
}

export const SaveQuoteFormSchema = yup.object({
  overrideReason: yup
    .string()
    .when('$isAdjustmentRulesOverridden', {
      is: true,
      then: (schema: yup.Schema) => schema.required('You must provide a reason.'),
    })
    .when('$isDoNotBidRulesOverridden', {
      is: true,
      then: (schema: yup.Schema) => schema.required('You must provide a reason.'),
    }),
  customer: organizationShipperSchema
    .nullable()
    .test('not-null', 'You must select a customer.', valueNotNull),
  date: yup.date().nullable(),
  communicationMethod: yup
    .string()
    .required("You must indicate how you've communicated with the shipper."),
  referenceNumber: yup.string(),
  shipmentNumber: yup.string().when('$markAsWon', {
    is: true,
    then: (schema: yup.Schema) => schema.required('You must provide the shipment number.'),
  }),
})

export interface TSaveQuoteFormSchema extends yup.InferType<typeof SaveQuoteFormSchema> {
  // using interface instead of type generally gives nicer editor feedback
}
