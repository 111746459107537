import React, { useEffect } from 'react'
import { DiscoveredOrganization } from '@stytch/vanilla-js'
import { Spinner } from 'dpl/components/Spinner'
import { IDiscoveredOrganizationListItemProps } from './DiscoveredOrganizationListItem'
import { useOrganizationRedirect } from './useOrganizationRedirect'

export interface IDiscoveredOrganizationRedirectProps {
  discoveredOrganization: DiscoveredOrganization
  onOrganizationSelect: IDiscoveredOrganizationListItemProps['onOrganizationSelect']
}

export function DiscoveredOrganizationRedirect({
  discoveredOrganization,
  onOrganizationSelect,
}: IDiscoveredOrganizationRedirectProps) {
  const { redirect } = useOrganizationRedirect(discoveredOrganization, onOrganizationSelect)

  useEffect(() => {
    redirect()
  }, [redirect])

  return <Spinner />
}
