import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PROTECTED_PATHS } from '#constants/paths'
import { useAnalytics } from '#hooks'
import { IContractRfpStates } from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import {
  AccessRestrictedModal,
  IColumn,
  IconButton,
  IKanbanBoardProps,
  KanbanBoard,
  OverflowMenu,
  useToastContext,
} from 'dpl'
import { Box, Button, makeStyles, Typography } from 'dpl/core'
import { AddIcon, ArchiveIcon, MoreIcon, OpenInNewTabIcon } from 'dpl/icons'
import { DatePickersProvider, QueryParamProvider } from 'dpl/providers'
import { PageTemplate } from 'dpl/templates'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import compact from 'lodash/compact'
import { StateChangeModal } from '../components/StateChangeModal'
import { useStateChangeModal } from '../components/StateChangeModal/useStateChangeModal'
import {
  BOTTOM_FRAME_HEIGHT,
  FILTERS_OPTIONS_CONFIG,
  HEADER_HEIGHT,
  RFP_STATES,
  TOP_BAR_HEIGHT,
} from '../utils/constans'
import { AddNewRFPSidePanel, KanbanItem } from './components'
import { IContractRfpsQuery, useContractRfpsQuery } from './graphql/ContractRfp'
import { IRfpItemFragment } from './graphql/RFPItem'
import { IReorderRfpMutationVariables, useReorderRfpMutation } from './graphql/ReorderRfp'
import { IFilters } from './types'
import { canMove } from './utils'

export interface IContractRFPsPageProps {}

const PAGE_SIZE = 25

const useStyles = makeStyles(theme => ({
  kanban: {
    padding: theme.spacing(3, 3, 0, 3),
    minHeight: `calc(100vh - ${HEADER_HEIGHT} - ${TOP_BAR_HEIGHT} - ${BOTTOM_FRAME_HEIGHT})`,
  },
  extraContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}))

function getItems(connection?: IContractRfpsQuery['planning']) {
  const { nodes = [], totalCount } = connection || {}
  return {
    items: compact(nodes),
    totalCount: totalCount || 0,
  }
}

export function ContractRFPsPage() {
  const classes = useStyles()
  const { createToggleHandler, modalState, toggleModal } = useModalState({
    restrictedModal: false,
  })
  const [filters, setFilters] = useState<IFilters>({
    rfpName: null,
    customer: null,
    assignee: null,
    dueDate: null,
  })
  const [columns, setColumns] = useState<IColumn<IRfpItemFragment>[]>([])
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const [reorder] = useReorderRfpMutation()
  const { data, loading, refetch } = useContractRfpsQuery({
    variables: {
      assigneeIds: filters.assignee?.id ? filters.assignee?.id : undefined,
      ids: filters.rfpName?.id ? filters.rfpName?.id : undefined,
      shipperIds: filters.customer?.id ? filters.customer?.id : undefined,
      dueEndAt: filters.dueDate?.dateRange.endAt,
      dueStartAt: filters.dueDate?.dateRange.startAt,
      first: PAGE_SIZE,
      includeClosed: !(filters.dueDate?.dateRange.startAt || filters.dueDate?.dateRange.endAt),
      includeSubmitted: !(filters.dueDate?.dateRange.startAt || filters.dueDate?.dateRange.endAt),
    },
    fetchPolicy: 'network-only',
  })

  /**
   * We are storing columns into a state to handle reordering of items with positive feedback for the user,
   * so they will see the change once it's made and is going to be reverted in case of an error
   */
  useEffect(() => {
    const columns = RFP_STATES.map(({ label, value }) => ({
      id: value,
      title: label,
      ...getItems(data?.[value]),
    }))
    setColumns(columns)
  }, [data])

  const showAddNewItem = useMemo(() => {
    return columns.every(({ items }) => items.length === 0)
  }, [columns])

  const hasMore = useMemo(() => {
    return columns.some(({ items, totalCount }) => items.length !== totalCount)
  }, [columns])

  const maxItems = useMemo(() => {
    return Math.max(...columns.map(({ items }) => items.length))
  }, [columns])

  const { changeRfpStatus, closeHandler, confirmHandler, moveRfp } = useStateChangeModal()
  const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()
  const { userPermissions } = usePermissionsContext()

  const overflowMenuOptions = useMemo(
    () => [
      {
        action: () => {
          window.open(`${PROTECTED_PATHS.contractRFPs}/archived`, '_blank', 'noopener,noreferrer')
        },
        itemComponent: (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            data-test='overflow-menu-archived-rfps-option'>
            <ArchiveIcon size='large' />
            <Typography ml={1} mr={2}>
              Archived RFPs
            </Typography>
            <OpenInNewTabIcon size='large' color='coolGray4' />
          </Box>
        ),
        label: 'Archived RFPs',
      },
    ],
    []
  )

  const { trackEvent } = useAnalytics()
  const { openToast } = useToastContext()
  const getReorderInput = useCallback(
    (
      item: IRfpItemFragment,
      columnId: string,
      closestEdge: string,
      itemId: string
    ): IReorderRfpMutationVariables['input'] => {
      if (closestEdge === 'bottom') {
        return {
          id: item.id ?? '',
          insertAfterKanbanId: itemId ?? '',
        }
      }
      const column = columns.find(({ id }) => id === columnId)
      const { items = [] } = column || {}
      const index = items.findIndex(({ id }) => id === itemId)
      const prevItem = index - 1 >= 0 ? items[index - 1] : null
      const prevItemId = prevItem?.id ?? ''

      return {
        id: item.id ?? '',
        insertAfterKanbanId: prevItemId ?? '',
      }
    },
    [columns]
  )

  const moveItemHandler: IKanbanBoardProps<IRfpItemFragment>['onMoveItem'] = useCallback(
    async (item, columnId, { closestEdge, itemId }) => {
      if (item.state === columnId && closestEdge) {
        const input = getReorderInput(item, columnId, closestEdge, itemId)
        const originalColumns = [...columns]

        if (input.id === input.insertAfterKanbanId) return

        /**
         * swap the item with the previous item in the same column
         */
        setColumns(prevColumns => {
          const newColumns = prevColumns.map(column => {
            if (column.id === columnId) {
              const newItems = column.items.filter(({ id }) => id !== item.id)
              const index = newItems.findIndex(({ id }) => id === input.insertAfterKanbanId)
              newItems.splice(index + 1, 0, item)
              return {
                ...column,
                items: newItems,
              }
            }
            if (column.id === item.state) {
              return {
                ...column,
                items: column.items.filter(({ id }) => id !== item.id),
              }
            }
            return column
          })

          return newColumns
        })

        const { data } = await reorder({
          variables: {
            input,
          },
          refetchQueries: ['ContractRfps'],
          onError: async apolloError => {
            trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
              variables: input,
              apolloError,
            })

            // restore original columns
            setColumns(originalColumns)

            openToast({
              toastMessage: apolloError.message,
              toastType: 'alert',
            })
          },
        })

        const { updateContractRfpKanbanPosition } = data || {}
        const { errors } = updateContractRfpKanbanPosition || {}

        if (errors?.length) {
          trackEvent('Contract RFPs', 'REORDER_RFP_ERROR', {
            variables: input,
            errors,
          })

          openToast({
            toastMessage: errors[0].message,
            toastType: 'alert',
          })
          return
        }

        trackEvent('Contract RFPs', 'REORDER_RFP_SUCCESS', {
          variables: input,
        })

        return
      }
      changeRfpStatus(item, columnId)
    },
    [changeRfpStatus, columns, reorder]
  )

  const handleFilterChange = useCallback(
    (newFilters: Partial<IFilters>) => {
      setFilters(prevFilters => ({
        ...prevFilters,
        ...newFilters,
      }))
    },
    [setFilters]
  )

  const handleAddNewRfp = useCallback(() => {
    if (userPermissions['contract_rfp.create_rfp_versions']) {
      setShowSidePanel(true)
    } else {
      toggleModal('restrictedModal', true)
    }
  }, [setShowSidePanel])

  const handleFilterOrSortChange = useCallback(
    params => {
      const { assignee, customer, dueDate, rfpName } = params

      handleFilterChange({ rfpName, customer, assignee, dueDate })
    },
    [handleFilterChange]
  )

  return (
    <QueryParamProvider>
      <DatePickersProvider>
        <PageTemplate
          ref={newRef => setRef(newRef)}
          dataTest='contract-rfps-list'
          pageTitle='Contract RFPs'
          documentTitle='Contract RFPs'
          PageBodyTitleProps={{
            extra: (
              <Box className={classes.extraContainer}>
                <Button
                  color='primary'
                  variant='contained'
                  startIcon={<AddIcon size='large' />}
                  onClick={handleAddNewRfp}>
                  Add New RFP
                </Button>

                <OverflowMenu
                  dataTest='contract-rfp-overflow-menu'
                  dropdownId='contract-rfp-overflow-menu'
                  anchorEl={anchorEl}
                  expanded={expanded}
                  onClose={handleClose}
                  onSelectMenuItem={handleClose}
                  options={overflowMenuOptions}
                  trigger={
                    <IconButton
                      label='overflow-trigger'
                      Icon={MoreIcon}
                      onClick={handleClick}
                      size='xlarge'
                      color='secondary'
                    />
                  }
                />
              </Box>
            ),
          }}
          noPadding>
          <KanbanBoard
            className={classes.kanban}
            columns={columns}
            ItemComponent={KanbanItem}
            canDrop={({ state }, newState) => canMove(state, newState as IContractRfpStates)}
            estimateSize={200}
            scrollRef={ref?.parentElement || null}
            addNewItemLabel='Add New RFP'
            showAddNewItem={() => showAddNewItem}
            onAddNewItem={() => setShowSidePanel(true)}
            onMoveItem={moveItemHandler}
            hasMore={hasMore}
            loading={loading}
            onLoadMore={() => refetch({ first: maxItems + PAGE_SIZE })}
            filtersConfig={FILTERS_OPTIONS_CONFIG}
            defaultFilterValues={filters}
            onFilterOrSortChange={handleFilterOrSortChange}
          />
          {moveRfp && (
            <StateChangeModal
              rfp={moveRfp.item}
              newState={moveRfp.newColumnId}
              onClose={closeHandler}
              onConfirm={confirmHandler}
            />
          )}
          {modalState.restrictedModal && (
            <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
          )}
          {showSidePanel && <AddNewRFPSidePanel onClose={() => setShowSidePanel(false)} />}
        </PageTemplate>
      </DatePickersProvider>
    </QueryParamProvider>
  )
}
