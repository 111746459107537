import { STATES } from 'dpl/constants/countriesStates'
import { TLocationFactorSchema } from '../../schema/LocationFactorSchema'
import { GEOGRAPHIC_TYPES } from '../../types'

const getStateValue = (stateCode: string) => {
  const state = STATES.US.find(({ abbr }) => abbr === stateCode)
  const { abbr, name } = state || {}
  return {
    id: name,
    stateCode: abbr,
  }
}

interface ILocationFactor {
  geoType: Nullable<string>
  values: Nullable<string[]>
}

export const mapLocationFactorToFormSchema = (
  locationFactor: Nullable<ILocationFactor>
): TLocationFactorSchema => {
  const includeFactor = !!locationFactor
  const { geoType, values } = locationFactor || {}
  const geographicType = (geoType || '') as TLocationFactorSchema['geographicType']

  switch (geographicType) {
    case GEOGRAPHIC_TYPES.regional: {
      const [region] = values || []
      return {
        includeFactor,
        geographicType,
        region: region as TLocationFactorSchema['region'],
        state: null,
        fiveDigitZipCodes: [],
      }
    }
    case GEOGRAPHIC_TYPES.state: {
      const [stateCode] = values || []
      const state = getStateValue(stateCode)
      return { includeFactor, geographicType, region: null, state, fiveDigitZipCodes: [] }
    }
    case GEOGRAPHIC_TYPES.fiveDigitZipCodes: {
      const fiveDigitZipCodes = values?.map(postalCode => ({
        postalCode,
      }))
      return {
        includeFactor,
        geographicType,
        region: null,
        state: null,
        fiveDigitZipCodes,
      }
    }
    default: {
      return {
        includeFactor: false,
        geographicType: null,
        region: null,
        state: null,
        fiveDigitZipCodes: [],
      }
    }
  }
}
