import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { usePermissionsContext } from 'auth/common/context'
import { AccessRestrictedModal, Tooltip, useToastContext } from 'dpl'
import { Box, Button, Divider } from 'dpl/core'
import { AddIcon, DownloadIcon, SettingsIcon } from 'dpl/icons'
import RolledIcon from 'dpl/icons/build/RolledIcon'
import { brandColors } from 'dpl/theme/colors'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { IContractRfpNodeFragment } from '../../graphql/ContractRFPNodeFragment'
import { useRefreshRfpAutoRatesMutation } from '../../graphql/RefreshRFPAutoRates'
import { AddLaneSideNav } from '../AddLaneSideNav'
import { DownloadRfpModal } from '../DownloadRfpModal'
import { RateSettingModal } from './RateSettingsModal'
import { RoundsDropdown } from './RoundsDropdown'
import { StartNewRoundModal } from './StartNewRoundModal'

interface RoundsBarProps {
  currentRoundId: string
  selectedRoundId: string
  setSelectedRoundId: Dispatch<SetStateAction<string | undefined>>
  rounds: IContractRfpNodeFragment['versions']
  showStartNewRound: boolean
  disableStartNewRound?: boolean
  disableOptions?: boolean
  contractId: string
  displayId: number
  contractStartAt: string
  contractEndAt: string
}

export function RoundsBar({
  contractEndAt,
  contractId,
  contractStartAt,
  currentRoundId,
  disableOptions,
  disableStartNewRound,
  displayId,
  rounds,
  selectedRoundId,
  setSelectedRoundId,
  showStartNewRound,
}: RoundsBarProps) {
  const { isFlagEnabled } = useFlagsContext()
  const isAddLaneEnabled = isFlagEnabled('rfp_add_new_lane')
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled
  const { openToast } = useToastContext()

  const [refreshRfpAutoRatesMutation] = useRefreshRfpAutoRatesMutation({
    variables: {
      rfpVersionId: currentRoundId,
    },
    refetchQueries: ['CurrentVersionLanes'],
  })

  const refreshRfpAutoRates = useCallback(async () => {
    try {
      const { errors = [] } = await refreshRfpAutoRatesMutation()

      if (errors.length > 0) {
        openToast({
          toastMessage: 'There was an issue generating rates. Please try again.',
          toastType: 'alert',
        })
      }
    } catch {
      openToast({
        toastMessage: 'There was an issue generating rates. Please try again.',
        toastType: 'alert',
      })
    }
  }, [refreshRfpAutoRatesMutation, openToast])

  const { createToggleHandler, modalState, toggleModal } = useModalState({
    startNewRoundModal: false,
    restrictedModal: false,
    downloadRfpModal: false,
    addLaneModal: false,
    rateSettingsModal: false,
  })

  const startNewRoundHandler = () => {
    if (!userPermissions['contract_rfp.create_rfp_versions']) {
      toggleModal('restrictedModal', true)
      return
    }

    toggleModal('startNewRoundModal', true)
  }

  const addNewLaneHandler = () => {
    if (!userPermissions['contract_rfp.create_rfp_version_lanes']) {
      toggleModal('restrictedModal', true)
      return
    }

    toggleModal('addLaneModal', true)
  }

  const rateSettingsHandler = () => {
    toggleModal('rateSettingsModal', true)
  }

  const { number: currentRoundNumber } = rounds.find(round => round.id === selectedRoundId) || {}

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      py={1}
      px={2}
      bgcolor={brandColors.coolGray0}>
      <Box display='flex' alignItems='center' gap={0.5} data-test='rounds-tab'>
        <RoundsDropdown
          rounds={rounds}
          selectedRoundId={selectedRoundId}
          setSelectedRoundId={setSelectedRoundId}
          currentRoundId={currentRoundId}
          contractId={contractId}
          displayId={displayId}
        />
        {showStartNewRound && (
          <>
            <Divider orientation='vertical' flexItem />

            {disableStartNewRound ? (
              <Tooltip title='Action unavailable in the current RFP status'>
                <span>
                  <Button
                    disabled
                    onClick={startNewRoundHandler}
                    variant='text'
                    size='xsmall'
                    startIcon={<RolledIcon size='large' />}>
                    Start New Round
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                onClick={startNewRoundHandler}
                variant='text'
                size='xsmall'
                data-test='add-round-button'
                startIcon={<RolledIcon size='large' />}>
                Start New Round
              </Button>
            )}

            {isAddLaneEnabled && (
              <>
                {disableOptions ? (
                  <Tooltip title='Action unavailable in the current RFP status'>
                    <span>
                      <Button
                        disabled
                        onClick={addNewLaneHandler}
                        variant='text'
                        size='xsmall'
                        startIcon={<AddIcon size='large' />}>
                        Add Lane
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={addNewLaneHandler}
                    variant='text'
                    size='xsmall'
                    startIcon={<AddIcon size='large' />}>
                    Add Lane
                  </Button>
                )}
              </>
            )}

            {isRfpRateGenEnabled && (
              <Tooltip
                disabled={!disableOptions}
                title='Action unavailable in the current RFP status'>
                <span>
                  <Button
                    disabled={disableOptions}
                    onClick={rateSettingsHandler}
                    variant='text'
                    size='xsmall'
                    startIcon={<SettingsIcon size='large' />}>
                    Rate Settings
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        )}
      </Box>
      {rounds[0].lanes.totalCount !== 0 && (
        <Button
          variant='text'
          startIcon={<DownloadIcon size='large' />}
          onClick={createToggleHandler('downloadRfpModal', true)}>
          Download
        </Button>
      )}
      {modalState.downloadRfpModal && (
        <DownloadRfpModal
          rounds={rounds}
          onClose={createToggleHandler('downloadRfpModal', false)}
          contractId={contractId}
          displayId={displayId}
        />
      )}
      <StartNewRoundModal
        open={modalState.startNewRoundModal}
        onClose={createToggleHandler('startNewRoundModal', false)}
        setSelectedRoundId={setSelectedRoundId}
      />
      {modalState.restrictedModal && (
        <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
      )}
      {modalState.addLaneModal && (
        <AddLaneSideNav
          currentRoundNumber={currentRoundNumber ?? 0}
          contractRfpVersionId={currentRoundId || ''}
          onClose={createToggleHandler('addLaneModal', false)}
          contractStartAt={contractStartAt}
          contractEndAt={contractEndAt}
        />
      )}
      {isRfpRateGenEnabled && (
        <RateSettingModal
          currentRoundNumber={currentRoundNumber ?? 0}
          open={modalState.rateSettingsModal}
          onConfirm={() => {
            refreshRfpAutoRates()
          }}
          onClose={createToggleHandler('rateSettingsModal', false)}
        />
      )}
    </Box>
  )
}
