import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useAnalytics } from '#hooks'
import { useStateTransitionMutation } from '#routes/contract-rfp/ContractRFPsPage/graphql/StateTransition'
import { RFP_STATES, TRANSITIONS } from '#routes/contract-rfp/utils'
import { IContractRfpStates, ITransitionContractRfpStateInput } from '#types/graphqlTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormModal } from 'dpl/components/Modals/FormModal'
import { RadioGroup } from 'dpl/components/RadioGroup'
import { useToastContext } from 'dpl/components/Toast'
import { FORMATS } from 'dpl/constants/datetime'
import { Box, DialogProps, Divider, Typography, makeStyles, Collapse } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import ControlledDatePicker from 'forms/components/ControlledDatePicker'
import ControlledFunctionalTag from 'forms/components/ControlledFunctionalTag'
import moment from 'moment'
import { useContractRfpQuery } from '../../../graphql/ContractRfp'
import { StartNewRoundFormSchema, startNewRoundFormSchema } from './startNewRoundFormSchema'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      /**
       * Magic number coming from spec 🪄✨
       */
      maxWidth: '500px',
    },
  },
}))

const SUBMITTED_RADIO_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]

const FILTERED_RFP_STATES = RFP_STATES.filter(
  ({ value }) => value === IContractRfpStates.planning || value === IContractRfpStates.in_progress
)

interface StartNewRoundModalProps extends Pick<DialogProps, 'open'> {
  onClose: () => void
  setSelectedRoundId: Dispatch<SetStateAction<string | undefined>>
}

export function StartNewRoundModal({ onClose, open, setSelectedRoundId }: StartNewRoundModalProps) {
  const classes = useStyles()
  const { trackEvent } = useAnalytics()
  const { openToast } = useToastContext() || {}
  const [startNewRound, { loading: isStartNewRoundLoading }] = useStateTransitionMutation()
  const [wasSubmitted, setWasSubmitted] = useState('yes')
  const { rfpId = '' } = useParams()
  const { data } = useContractRfpQuery({
    nextFetchPolicy: 'cache-only',
    variables: { displayId: rfpId },
    skip: !rfpId,
  })
  const { contractRfps } = data || {}
  const { nodes = [] } = contractRfps || {}
  const [contractRfp] = nodes || []
  const { currentVersion, state } = contractRfp || {}
  const isCurrentRoundSubmitted = Boolean(currentVersion?.submittedAt)

  const methods = useForm<StartNewRoundFormSchema>({
    resolver: yupResolver(startNewRoundFormSchema),
    defaultValues: {},
    mode: 'onChange',
  })
  const { control, errors, handleSubmit, reset } = methods || {}

  const continueHandler = handleSubmit(async formData => {
    const input: ITransitionContractRfpStateInput = {
      id: contractRfp?.id!,
      transition: TRANSITIONS[formData.state.value as IContractRfpStates],
    }

    if (wasSubmitted === 'yes') {
      input.submittedAt = formData.submittedDate
    }

    const variables = {
      input,
      upsertVersionInput: {
        contractRfpId: contractRfp?.id,
        dueAt: formData.dueDate,
      },
      upsertNewVersion: true,
      isDifferentState: isCurrentRoundSubmitted,
    }

    const { data } = await startNewRound({
      variables,
      refetchQueries: ['ContractRfp'],
      onError: apolloError => {
        openToast({
          toastMessage: apolloError,
          toastType: 'alert',
          multiLine: true,
        })
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          apolloError,
          item: contractRfp,
          variables,
        })
      },
    })

    const { upsertContractRfpVersion } = data || {}
    const { errors: mutationErrors } = upsertContractRfpVersion || {}

    if (mutationErrors?.length) {
      const error = mutationErrors.map(({ message }) => message).join(', ')
      openToast({
        toastMessage: error,
        toastType: 'alert',
        multiLine: true,
      })

      trackEvent('Contract RFPs', 'CREATE_NEW_ROUND_ERROR', {
        errors: mutationErrors,
        item: contractRfp,
        variables,
      })
      return
    }

    trackEvent('Contract RFPs', 'CREATE_NEW_ROUND_SUCCESS', {
      item: contractRfp,
      variables,
    })

    setSelectedRoundId(upsertContractRfpVersion?.contractRfpVersion?.id)
    onClose()
  })

  /**
   * useEffect to reset form state on modal open.
   * This helps us keep the modal's open/close animations 🍭
   */
  useEffect(() => {
    if (open) {
      reset({
        state: {
          value: IContractRfpStates.in_progress,
        },
        dueDate: currentVersion?.dueAt,
        submittedDate: moment().startOf('day').toISOString(),
      })
      setWasSubmitted('yes')
    }
  }, [currentVersion?.dueAt, open, reset, state])

  return (
    <FormModal
      open={open}
      className={classes.modal}
      onClose={onClose}
      maxWidth={false}
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          ButtonProps: {
            variant: 'text',
          },
          disabled: isStartNewRoundLoading,
        },
        {
          label: 'Continue',
          action: continueHandler,
          disabled: isStartNewRoundLoading,
          dataTest: 'save-round-button',
        },
      ]}
      title='Start New Round'
      header={
        <Typography variant='body1' color={brandColors.coolGray5}>
          This action starts Round {(currentVersion?.number ?? 0) + 1} of the RFP. Previous round(s)
          will be uneditable.
        </Typography>
      }>
      <Box display={isCurrentRoundSubmitted ? 'none' : 'block'}>
        <Typography variant='subtitle1' pb={2}>
          Previous Round&apos;s Status
        </Typography>
        <Typography variant='caption' pb={0.5} color={brandColors.coolGray5}>
          Did you submit Round {currentVersion?.number} of this RFP?
        </Typography>
        <RadioGroup
          big
          options={SUBMITTED_RADIO_OPTIONS}
          onOptionChange={e => setWasSubmitted(e.target.value)}
          checkedValue={wasSubmitted}
        />
        <Collapse in={wasSubmitted === 'yes'}>
          <Box pt={3}>
            <ControlledDatePicker
              control={control}
              name='submittedDate'
              transform={date => date?.toISOString() ?? ''}
              DatePickerProps={{
                label: `Submitted Date (Round ${currentVersion?.number})`,
                clearable: false,
                format: FORMATS.readableFormat,
                dataTest: 'submitted-date-input',
              }}
            />
          </Box>
        </Collapse>
        <Box py={3}>
          <Divider />
        </Box>
      </Box>
      <Typography variant='subtitle1' pb={2}>
        Update your RFP
      </Typography>
      <Box
        display={isCurrentRoundSubmitted ? 'flex' : 'none'}
        pb={3}
        flexDirection='column'
        gap={0.5}>
        <Typography variant='caption' color={brandColors.coolGray5}>
          Status
        </Typography>
        <ControlledFunctionalTag
          control={control}
          name='state'
          FunctionalTagProps={{
            options: FILTERED_RFP_STATES,
          }}
        />
      </Box>
      <ControlledDatePicker
        control={control}
        name='dueDate'
        transform={date => date?.toISOString() ?? ''}
        DatePickerProps={{
          label: 'Due Date',
          required: true,
          error: Boolean(errors.dueDate),
          helperText: errors.dueDate?.message,
          format: FORMATS.readableFormat,
          minDate: moment(),
          clearable: false,
          dataTest: 'due-date-input',
        }}
      />
    </FormModal>
  )
}
