import { IRgCarrierCapacityFragment } from '../../../graphql/RGCarrierCapacityFragment'

export function getCarrierCapacityText(capacityDays: IRgCarrierCapacityFragment): string {
  const {
    fridayCapacity,
    mondayCapacity,
    saturdayCapacity,
    sundayCapacity,
    thursdayCapacity,
    tuesdayCapacity,
    wednesdayCapacity,
  } = capacityDays || {}

  const availableDays = [
    { hasCapacity: mondayCapacity, name: 'Mon' },
    { hasCapacity: tuesdayCapacity, name: 'Tue' },
    { hasCapacity: wednesdayCapacity, name: 'Wed' },
    { hasCapacity: thursdayCapacity, name: 'Thu' },
    { hasCapacity: fridayCapacity, name: 'Fri' },
    { hasCapacity: saturdayCapacity, name: 'Sat' },
    { hasCapacity: sundayCapacity, name: 'Sun' },
  ].filter(({ hasCapacity }) => hasCapacity)

  if (availableDays.length === 7) {
    return 'Any Day'
  }

  if (availableDays.length === 0) {
    return '--'
  }

  return availableDays.map(({ name }) => name).join(', ')
}
