import { ISpotRatesAdjustmentRuleVersionFragment } from '../../../AdjustmentRulesSection/graphql/SpotRatesAdjustmentRuleVersionFragment'
import { TFactorsSchema } from '../../schema/FactorsSchema'
import { EQUIPMENT_TYPES } from '../../types'
import { mapLocationFactorToFormSchema } from './mapLocationFactorToFormSchema'

export const mapFactorsToFormSchema = (
  rule: Nullable<
    Pick<ISpotRatesAdjustmentRuleVersionFragment, 'factors' | 'organizationShipper' | 'equipment'>
  >
): TFactorsSchema => {
  const { factors: factorsApiValue, organizationShipper } = rule || {}
  const { customerFactor, deliveryGeoFactor, equipmentFactor, laneFactor, pickupGeoFactor } =
    factorsApiValue || {}
  const { deliveryGeoType, deliveryValues, pickupGeoType, pickupValues } = laneFactor || {}
  const { equipmentKey } = equipmentFactor || {}

  const pickupLocation = mapLocationFactorToFormSchema(
    laneFactor
      ? {
          geoType: pickupGeoType,
          values: pickupValues,
        }
      : pickupGeoFactor
  )

  const deliveryLocation = mapLocationFactorToFormSchema(
    laneFactor
      ? {
          geoType: deliveryGeoType,
          values: deliveryValues,
        }
      : deliveryGeoFactor
  )

  const factors: TFactorsSchema = {
    pickupLocation,
    deliveryLocation,
    customer:
      !!customerFactor && organizationShipper
        ? { includeFactor: true, value: organizationShipper }
        : { includeFactor: false, value: null },
    equipment: equipmentKey
      ? { includeFactor: true, value: equipmentKey as EQUIPMENT_TYPES }
      : { includeFactor: false, value: null },
  }

  return factors
}
