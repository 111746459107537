import React from 'react'
import { IStop } from '#types/graphqlTypes'
import NumericFormat from 'dpl/components/NumericFormat'
import { Box, Card, CardContent, CardHeader, makeStyles, Typography } from 'dpl/core'
import { LocationPinIcon } from 'dpl/icons'
import { TrimbleMap, TTrimbleMapProps } from 'maps/components/TrimbleMap'
import { IMapStopProps } from 'maps/types'

function LaneMapCardSubheader() {
  return (
    <Box display='flex' alignItems='center' gap={0.5}>
      <LocationPinIcon />
      <Typography variant='overline'>Lane</Typography>
    </Box>
  )
}

const useLaneMapCardStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3, 0, 3),
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 3, 3, 3),
      '&:last-child': {
        paddingBottom: theme.spacing(3),
      },
    },
  },
  laneDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  laneDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  mapContainer: {
    position: 'relative',
    height: 260,
  },
}))

const getStopLabel = (stop: Stop) => {
  const { city, postalCode, stateCode } = stop || {}
  return `${city}, ${stateCode} ${postalCode}`
}

const getMapStops = (stops: LaneMapCardProps['stops']): NonNullable<TTrimbleMapProps['stops']> => {
  const noCoords = stops?.some(stop => !(stop?.stopLatitude && stop?.stopLongitude))

  if (noCoords) {
    return []
  }

  let pickupCount = 1
  let deliveryCount = 1

  return (
    stops?.map(({ stopLatitude, stopLongitude, stopSequence, stopType }) => {
      const isPickup = stopType === 'pickup'
      const sequence = isPickup ? pickupCount : deliveryCount

      if (isPickup) {
        pickupCount += 1
      } else {
        deliveryCount += 1
      }

      return {
        type: stopType as IMapStopProps['type'],
        sequence,
        coords: {
          lat: stopLatitude ?? 0,
          lon: stopLongitude ?? 0,
        },
        data: {
          id: stopSequence,
        },
      }
    }) || []
  )
}

/**
 * Bare minimum props needed and shared across stop types (IStop, IContractRatesStop, etc)
 */
interface Stop
  extends Pick<
    IStop,
    | 'city'
    | 'postalCode'
    | 'stateCode'
    | 'stopLatitude'
    | 'stopLongitude'
    | 'stopSequence'
    | 'stopType'
  > {}

interface LaneMapCardProps {
  miles: number
  stops: Stop[]
}

export function LaneMapCard({ miles, stops }: LaneMapCardProps) {
  const classes = useLaneMapCardStyles()
  const [originStop] = stops || []
  const destinationStop = stops[stops.length - 1]
  const origin = getStopLabel(originStop)
  const destination = getStopLabel(destinationStop)
  const mapStops = getMapStops(stops)

  return (
    <Card data-test='lane-map-card'>
      <CardHeader subheader={<LaneMapCardSubheader />} className={classes.cardHeader} />
      <CardContent className={classes.cardContent}>
        <div className={classes.mapContainer}>
          <TrimbleMap stops={mapStops} bordersOpen={false} />
        </div>
        <div className={classes.laneDetailsContainer}>
          <div className={classes.laneDetail}>
            <Typography variant='body1'>Origin</Typography>
            <Typography variant='subtitle1'>{origin}</Typography>
          </div>
          <div className={classes.laneDetail}>
            <Typography variant='body1'>Destination</Typography>
            <Typography variant='subtitle1'>{destination}</Typography>
          </div>
          <div className={classes.laneDetail}>
            <Typography variant='body1'>Distance</Typography>
            <Typography variant='subtitle1'>
              <NumericFormat
                value={miles}
                thousandSeparator
                displayType='text'
                decimalScale={0}
                suffix=' Miles'
              />
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
