import React, { useMemo } from 'react'
import { REGION_OPTIONS } from '#routes/spot-rates-control-panel/constants'
import { Box, Typography } from 'dpl/core'
import { DryVanIcon, ReeferIcon } from 'dpl/icons'
import CustomerIcon from 'dpl/icons/build/CustomerIcon'
import DeliveryIcon from 'dpl/icons/build/DeliveryIcon'
import PickupIcon from 'dpl/icons/build/PickupIcon'
import SvgIcon from 'dpl/icons/components/SvgIcon'
import get from 'lodash/get'
import { GEOGRAPHIC_TYPES, EQUIPMENT_TYPES } from '../../../../types'
import {
  EQUIPMENT_FACTOR_NAME,
  EQUIPMENT_TYPE_OPTIONS,
  CUSTOMER_FACTOR_NAME,
  PICKUP_LOCATION_FACTOR_NAME,
  DELIVERY_LOCATION_FACTOR_NAME,
} from '../../constants'
import { TWatchedFactors, TWatchedLocationFactor } from '../../types'

const getFormattedLocationValue = (locationFactor: TWatchedLocationFactor) => {
  const { fiveDigitZipCodes, geographicType, includeFactor, region, state } = locationFactor || {}
  if (!includeFactor) return ''
  switch (geographicType) {
    case GEOGRAPHIC_TYPES.regional:
      return REGION_OPTIONS.find(option => option.value === region)?.label || ''
    case GEOGRAPHIC_TYPES.state:
      return state?.id || ''
    case GEOGRAPHIC_TYPES.fiveDigitZipCodes:
      return fiveDigitZipCodes?.map(location => location.postalCode).join(', ') || ''
    default:
      return ''
  }
}
interface IFactorIconValueProps {
  Icon: typeof SvgIcon
  value: string
  dataTest: string
}

function FactorIconValue({ Icon, dataTest, value }: IFactorIconValueProps) {
  return (
    <Box display='flex' gap={1.5} alignItems='center'>
      <Icon size='xlarge' color='coolGray5' data-test={`${dataTest}Icon`} />
      <Typography variant='body2' data-test={`${dataTest}Value`}>
        {value}
      </Typography>
    </Box>
  )
}

interface ILocationFactorsReviewProps {
  /**
   * The current factors values in the form
   */
  values: TWatchedFactors
}

export function FactorsReview({ values }: ILocationFactorsReviewProps) {
  const pickupLocation = get(values, PICKUP_LOCATION_FACTOR_NAME) || {}
  const deliveryLocation = get(values, DELIVERY_LOCATION_FACTOR_NAME) || {}
  const customer = get(values, CUSTOMER_FACTOR_NAME) || {}
  const equipment = get(values, EQUIPMENT_FACTOR_NAME) || {}

  const { includeFactor: includePickupLocation } = pickupLocation
  const { includeFactor: includeDeliveryLocation } = deliveryLocation
  const { includeFactor: includeCustomer, value: customerValue } = customer
  const { name: customerName = '' } = customerValue || {}
  const { includeFactor: includeEquipment, value: equipmentValue } = equipment

  const equipmentTypeFormatted = useMemo(() => {
    const equipment = EQUIPMENT_TYPE_OPTIONS.find(option => option.value === equipmentValue)
    const label = equipment?.label || ''
    const Icon = equipmentValue === EQUIPMENT_TYPES.reefer ? ReeferIcon : DryVanIcon
    const iconDataTest = equipmentValue === EQUIPMENT_TYPES.reefer ? 'Reefer' : 'DryVan'
    return { label, Icon, iconDataTest }
  }, [equipmentValue])
  const {
    Icon: EquipmentIcon,
    iconDataTest: equipmentIconDataTest,
    label: equipmentLabel,
  } = equipmentTypeFormatted

  return (
    <Box display='flex' flexDirection='column' gap={3} marginTop={3}>
      {includeCustomer && (
        <FactorIconValue Icon={CustomerIcon} value={customerName} dataTest='Customer' />
      )}
      {includePickupLocation && (
        <FactorIconValue
          Icon={PickupIcon}
          value={getFormattedLocationValue(pickupLocation)}
          dataTest='Pickup'
        />
      )}
      {includeDeliveryLocation && (
        <FactorIconValue
          Icon={DeliveryIcon}
          value={getFormattedLocationValue(deliveryLocation)}
          dataTest='Delivery'
        />
      )}
      {includeEquipment && (
        <FactorIconValue
          Icon={EquipmentIcon}
          value={equipmentLabel}
          dataTest={equipmentIconDataTest}
        />
      )}
    </Box>
  )
}
