import * as yup from 'yup'
import { IContractLengthMonths } from '../../../types'
import { MAX_CONTRACT_MONTHS, MIN_CONTRACT_MONTHS } from '../constants'
import { getContractDurationValues } from '../utils'
import { contractDurationEndDateValidation } from '../utils/contractDurationEndDateValidation'

/**
 * Selected months for the contract.
 * Default contractLengthMonths is 12 from start date now + 1 month.
 * Contract length can not exceed MAX_CONTRACT_MONTHS (15) months from today
 * When we add custom date to contract length picker we need to validate end date
 */

export const DEFAULT_CONTRACT_DURATION = getContractDurationValues(
  IContractLengthMonths.twelveMonths
)

const DEFAULT_START_MONTH = DEFAULT_CONTRACT_DURATION.contractStartDate
const DEFAULT_END_MONTH = DEFAULT_CONTRACT_DURATION.contractEndDate

export const ContractDurationSchema = yup.object({
  contractStartDate: yup.string().default(DEFAULT_START_MONTH).required(),
  contractEndDate: yup
    .string()
    .default(DEFAULT_END_MONTH)
    .test(
      'end-date',
      'Contracts can not be greater than 15 months in the future.',
      contractDurationEndDateValidation
    )
    .required(),
  contractLengthMonths: yup
    .number()
    .default(IContractLengthMonths.twelveMonths)
    .max(MAX_CONTRACT_MONTHS)
    .min(MIN_CONTRACT_MONTHS)
    .required('You must select a contract length'),
})

export interface IContractDurationSchema extends yup.InferType<typeof ContractDurationSchema> {}
