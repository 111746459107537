import { useMemo } from 'react'
import { B2BAllowedAuthMethods } from '@stytch/vanilla-js'

export interface IUseOrganizationAuthMethodsProps {
  /**
   * @description An array of allowed authentication methods. This list is enforced when auth_methods is set to
   *    RESTRICTED. The list's accepted values are: sso, magic_link, password, google_oauth, and microsoft_oauth.
   */
  allowedAuthMethods?: B2BAllowedAuthMethods[]
  /**
   * @description The setting that controls which authentication methods can be used by Members of an Organization.
   *    The accepted values are:
   *        ALL_ALLOWED – The default setting which allows all authentication methods to be used.
   *        RESTRICTED – Only methods that comply with allowed_auth_methods can be used for authentication. This setting
   *            does not apply to Members with is_breakglass set to true.
   */
  authMethods: 'ALL_ALLOWED' | 'RESTRICTED'
}

export interface IUseOrganizationAuthMethodsResult {
  isGoogleOAuthAllowed: boolean
  isMagicLinkAllowed: boolean
  isPasswordAllowed: boolean
  isSsoAllowed: boolean
}

export const useOrganizationAuthMethods = ({
  allowedAuthMethods = [],
  authMethods,
}: IUseOrganizationAuthMethodsProps): IUseOrganizationAuthMethodsResult =>
  useMemo(
    () => ({
      isGoogleOAuthAllowed:
        authMethods === 'ALL_ALLOWED' || allowedAuthMethods.includes('google_oauth'),
      isMagicLinkAllowed:
        authMethods === 'ALL_ALLOWED' || allowedAuthMethods.includes('magic_link'),
      isPasswordAllowed: authMethods === 'ALL_ALLOWED' || allowedAuthMethods.includes('password'),
      isSsoAllowed: authMethods === 'ALL_ALLOWED' || allowedAuthMethods.includes('sso'),
    }),
    [allowedAuthMethods, authMethods]
  )
