import React, { memo } from 'react'
import { Box, ITypographyProps, Stack, Typography } from 'dpl/core'
import { TIconComponent } from 'dpl/icons/types'
import { brandColors } from 'dpl/theme/colors'

export interface IAdjustmentRuleLineItemProps {
  /**
   * Amount for the line item
   * @example '$100.00'
   */
  amount: string
  /**
   * If `true`, line-through text will be displayed.
   * @example false
   */
  disabled?: boolean
  /**
   * If `true`, amount will be displayed as line-through text.
   * @example false
   */
  disableAmount?: boolean
  /**
   * @default 'AdjustmentRuleLineItem'
   */
  dataTest?: string
  /**
   * Icon component
   */
  Icon: TIconComponent
  /**
   * Label for the line item
   * @example '+2% Shift for Reason'
   */
  label: string
  /**
   * Color of line item typography
   * @default 'textSecondary'
   */
  textColor?: ITypographyProps['color']
}

export const AdjustmentRuleLineItem = memo<IAdjustmentRuleLineItemProps>(
  ({
    Icon,
    amount,
    dataTest = 'AdjustmentRuleLineItem',
    disableAmount = false,
    disabled = false,
    label,
    textColor = 'textSecondary',
  }) => {
    return (
      <Stack
        data-test={dataTest}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        gap={1}>
        <Stack direction='row' alignItems='center' gap={1}>
          <Box display='flex' sx={{ bgcolor: brandColors.coolGray2, borderRadius: 1 }}>
            <Icon size='xlarge' color='activeBlue' data-test={Icon?.name} />
          </Box>
          <Typography
            data-test={`${dataTest}-label`}
            variant='subtitle2'
            color={textColor}
            sx={{ textDecoration: disabled ? 'line-through' : 'initial' }}>
            {label}
          </Typography>
        </Stack>
        <Typography
          data-test={`${dataTest}-amount`}
          variant='subtitle2'
          color={textColor}
          sx={{ textDecoration: disableAmount || disabled ? 'line-through' : 'initial' }}>
          {amount}
        </Typography>
      </Stack>
    )
  }
)
