import get from 'lodash/get'
import D from '../icons/D.png'
import DNewWave from '../icons/D-newWave.png'
import D1 from '../icons/D1.png'
import D1NewWave from '../icons/D1-newWave.png'
import D2 from '../icons/D2.png'
import D2NewWave from '../icons/D2-newWave.png'
import D3 from '../icons/D3.png'
import D3NewWave from '../icons/D3-newWave.png'
import D4 from '../icons/D4.png'
import D4NewWave from '../icons/D4-newWave.png'
import D5 from '../icons/D5.png'
import D5NewWave from '../icons/D5-newWave.png'
import P from '../icons/P.png'
import PNewWave from '../icons/P-newWave.png'
import P1 from '../icons/P1.png'
import P1NewWave from '../icons/P1-newWave.png'
import P2 from '../icons/P2.png'
import P2NewWave from '../icons/P2-newWave.png'
import P3 from '../icons/P3.png'
import P3NewWave from '../icons/P3-newWave.png'
import P4 from '../icons/P4.png'
import P4NewWave from '../icons/P4-newWave.png'
import P5 from '../icons/P5.png'
import P5NewWave from '../icons/P5-newWave.png'

const ICONS_BY_NAME = {
  D,
  D1,
  D2,
  D3,
  D4,
  D5,
  P,
  P1,
  P2,
  P3,
  P4,
  P5,
  DNewWave,
  D1NewWave,
  D2NewWave,
  D3NewWave,
  D4NewWave,
  D5NewWave,
  PNewWave,
  P1NewWave,
  P2NewWave,
  P3NewWave,
  P4NewWave,
  P5NewWave,
} as const

interface IGetStopMarkerIconProps {
  /**
   * @example 'P'
   */
  stopTypeShort: string
  /**
   * @example 1
   */
  stopSequence: number | null
  /**
   * Uses "new wave" style
   * @default false
   */
  isNewWave?: boolean
}

export const getStopMarkerName = ({
  isNewWave = false,
  stopSequence,
  stopTypeShort,
}: IGetStopMarkerIconProps): string => {
  const stopProps: (string | number)[] = [stopTypeShort]

  // only add to array, if defined
  if (typeof stopSequence === 'number') {
    stopProps.push(stopSequence)
  }

  // if newWave, use style
  if (isNewWave) {
    stopProps.push('NewWave')
  }

  // i.e. 'P2'
  return stopProps.join('')
}

/**
 * Gets image asset url for associated stop marker icon
 * @example getStopMarkerIcon({ stopSequence: 1, stopType: 'P' }) // 'P1.png'
 */
export const getStopMarkerIcon = (props: IGetStopMarkerIconProps) => {
  const stopName = getStopMarkerName(props)
  return get(ICONS_BY_NAME, stopName, 'P')
}
