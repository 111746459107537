import { UploadInputSchema } from 'forms/components/ControlledUploadInputButton/schema'
import * as yup from 'yup'

const REQUIRED_MESSAGE = 'This field is required'

export const EditProfileFormSchema = yup.object({
  imageFile: UploadInputSchema,
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  email: yup.string().email().required(REQUIRED_MESSAGE),
  phoneNumber: yup.string().nullable(),
})

export type TEditProfileFormSchema = yup.InferType<typeof EditProfileFormSchema>
