import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormModal, IModalAction, IModalBaseProps } from 'dpl/components/Modals'
import { useToastContext } from 'dpl/components/Toast'
import { Box } from 'dpl/core'
import { SearchIcon } from 'dpl/icons'
import { useFlagsContext } from 'flags/src/FlagsContext'
import ControlledTextField from 'forms/components/ControlledTextField'
import { ControlledAutocompleteOrganizationShipper } from '../../../components/ControlledAutocompleteOrganizationShipper'
import { useAnalytics } from '../../../hooks'
import { IOrganizationQuoteItemFragment } from '../graphql/OrganizationQuoteItemFragment'
import { useUpdateQuoteMutation } from '../graphql/UpdateQuoteMutation'
import {
  EditQuoteDetailsFormSchema,
  editQuoteDetailsFormSchema,
} from './editQuoteDetailsFormSchema'

interface IEditQuoteDetailsModalProps extends Pick<IModalBaseProps, 'open' | 'onClose'> {
  quote: Nullable<
    Pick<
      IOrganizationQuoteItemFragment,
      'id' | 'organizationShipper' | 'customerReference' | 'shipmentNumber' | 'status'
    >
  >
}

export function EditQuoteDetailsModal({ onClose, open, quote }: IEditQuoteDetailsModalProps) {
  const { isFlagEnabled } = useFlagsContext()

  const isCustomerCreateEnabled = useMemo(() => isFlagEnabled('create_customer'), [isFlagEnabled])
  const {
    customerReference,
    id: quoteId,
    organizationShipper,
    shipmentNumber,
    status,
  } = quote || {}
  const { control, errors, handleSubmit, reset } = useForm<EditQuoteDetailsFormSchema>({
    resolver: yupResolver(editQuoteDetailsFormSchema),
    defaultValues: {
      customer: organizationShipper ?? null,
      customerReference: customerReference ?? '',
      shipmentNumber: shipmentNumber ?? null,
    },
    context: { status },
  })
  const { openToast } = useToastContext() || {}
  const { trackEvent } = useAnalytics()

  const [updateQuote, { loading }] = useUpdateQuoteMutation()

  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'dismiss',
      action: onClose,
    },
    {
      label: 'Submit',
      type: 'confirm',
      disabled: loading,
      action: handleSubmit(formData => {
        updateQuote({
          variables: {
            input: {
              id: quoteId,
              customerReference: formData?.customerReference || null,
              organizationShipperId: formData?.customer?.id,
              shipmentNumber: formData?.shipmentNumber || null,
            },
          },
          onCompleted(data) {
            const { updateQuote } = data || {}
            const { errors, organizationQuote } = updateQuote || {}
            if (errors?.length) {
              openToast({
                toastMessage: 'Something went wrong. Please try again.',
                toastType: 'alert',
              })
              trackEvent('Quotes Tool', 'UPDATE_QUOTE_ERROR', {
                errors,
                formData,
                quoteId,
              })
            } else {
              onClose()
              openToast({
                toastMessage: 'Quote details updated.',
              })
              trackEvent('Quotes Tool', 'UPDATE_QUOTE_SUCCESS', {
                organizationQuote,
                formData,
                quoteId,
              })
            }
          },
          onError: apolloErrors => {
            openToast({
              toastMessage: 'Something went wrong. Please try again.',
              toastType: 'alert',
            })
            trackEvent('Quotes Tool', 'RUNTIME_ERROR', {
              apolloErrors,
              quoteId,
            })
          },
        })
      }),
    },
  ]

  /**
   * Updates form with latest values after updating quote from this modal or externally (from the Mark As Won Modal)
   */
  useEffect(() => {
    reset({
      customer: organizationShipper ?? null,
      customerReference: customerReference ?? '',
      shipmentNumber: shipmentNumber ?? null,
    })
  }, [customerReference, organizationShipper, reset, shipmentNumber])

  return (
    <FormModal open={open} title='Edit Details' onClose={onClose} actions={actions} maxWidth='xs'>
      <Box display='flex' flexDirection='column' gap={3}>
        <ControlledAutocompleteOrganizationShipper
          enableCreate={isCustomerCreateEnabled}
          control={control}
          name='customer'
          AutocompleteOrganizationShipperProps={{
            label: 'Customer',
            StartIcon: SearchIcon,
            error: Boolean(errors.customer),
            helperText: errors.customer?.message,
            placeholder: 'Customer',
          }}
        />
        <ControlledTextField
          control={control}
          name='customerReference'
          TextFieldProps={{
            error: Boolean(errors.customerReference),
            helperText: errors?.customerReference?.message || '',
            name: 'customerReference',
            label: 'External Ref #',
            dataTest: 'customerReferenceTextField',
          }}
        />
        {status === 'won' && (
          <ControlledTextField
            control={control}
            name='shipmentNumber'
            TextFieldProps={{
              error: Boolean(errors.shipmentNumber),
              helperText: errors?.shipmentNumber?.message || '',
              name: 'shipmentNumber',
              label: 'Shipment #',
              dataTest: 'shipmentNumberTextField',
            }}
          />
        )}
      </Box>
    </FormModal>
  )
}
