import React, { useCallback } from 'react'
import { IOrganizationShipperStatus } from '#types/graphqlTypes'
import Autocomplete, { IAutocompleteProps } from 'dpl/components/Autocomplete'
import CustomerIcon from 'dpl/icons/build/CustomerIcon'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import { OrganizationShipperOption } from './OrganizationShipperOption'
import { IOrganizationShipperItemFragment } from './graphql/OrganizationShipperFragment'
import { useSearchOrganizationShippersLazyQuery } from './graphql/SearchOrganizationShippers'

interface IAutocompleteBaseProps extends IAutocompleteProps<IOrganizationShipperItemFragment> {}

const DEBOUNCE_MS = 250

type OmittedProps =
  | 'freeSolo'
  | 'options'
  | 'loading'
  | 'inputValue'
  | 'onInputChange'
  | 'isOptionEqualToValue'
  | 'getOptionLabel'
  | 'renderOption'
  | 'renderStartAdornment'

export interface AutocompleteOrganizationShipperProps
  extends Omit<IAutocompleteProps<IOrganizationShipperItemFragment>, OmittedProps> {
  StartIcon?: typeof CustomerIcon
}

export function AutocompleteOrganizationShipper({
  dataTest = 'autocomplete-organization-shipper',
  StartIcon = CustomerIcon,
  ...props
}: AutocompleteOrganizationShipperProps) {
  const [search, { data, loading }] = useSearchOrganizationShippersLazyQuery({
    variables: { searchTerm: '', filters: { status: IOrganizationShipperStatus.active } },
  })

  const { searchOrganizationShippers } = data || {}
  const { nodes = [] } = searchOrganizationShippers || {}
  const organizationShippers = compact<IOrganizationShipperItemFragment>(nodes)

  const searchByTerm = useCallback(
    (searchTerm: string) => {
      search({ variables: { searchTerm } })
    },
    [search]
  )

  const inputValueChangeHandler = debounce<NonNullable<IAutocompleteBaseProps['onInputChange']>>(
    (_, value) => {
      searchByTerm(value)
    },
    DEBOUNCE_MS
  )

  return (
    <Autocomplete<IOrganizationShipperItemFragment>
      freeSolo={false}
      dataTest={dataTest}
      options={organizationShippers}
      loading={loading}
      onInputChange={inputValueChangeHandler}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={option => option?.name}
      getOptionDisabled={option => !!option?.deprecatedAt}
      renderOption={(optionProps, option, { inputValue: autocompleteInputValue }) => {
        return (
          <li {...optionProps}>
            <OrganizationShipperOption
              option={option}
              inputValue={autocompleteInputValue}
              dataTest={`${dataTest}-${option?.id}`}
            />
          </li>
        )
      }}
      renderStartAdornment={() => {
        return <StartIcon size='xlarge' />
      }}
      {...props}
    />
  )
}
