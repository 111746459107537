import { eachMonthOfInterval, format } from 'date-fns'
import { DEFAULT_YYYY_MM_FORMAT } from '../constants'

interface IGetMonthYearRangeProps {
  startDate: string
  endDate: string
}

export const getMonthYearRange = ({ endDate, startDate }: IGetMonthYearRangeProps) => {
  const start = new Date(`${startDate}-02`)
  const end = new Date(`${endDate}-02`)
  const months = eachMonthOfInterval({ start, end })
  const formattedMonths = months.map(month => format(month, DEFAULT_YYYY_MM_FORMAT))
  return formattedMonths
}
