import React from 'react'
import { AuthenticationCard } from 'auth/common/components/AuthenticationCard'
import { Box } from 'dpl/core'
import { EmailTag } from '../EmailTag'
import { IResetPasswordFormProps, ResetPasswordForm } from './components/ResetPasswordForm'

export interface IResetPasswordCardProps extends IResetPasswordFormProps {
  email?: string
}

export function ResetPasswordCard({
  email,
  isLoading,
  loginHref,
  onChange,
  onSubmit,
  passwordStrengthScore,
}: IResetPasswordCardProps) {
  return (
    <AuthenticationCard
      title={
        <Box display='flex' flexDirection='column' gap={3}>
          {email && <EmailTag email={email} />}
          Reset your password
        </Box>
      }
      dataTest='reset-password-card'>
      <ResetPasswordForm
        onSubmit={onSubmit}
        onChange={onChange}
        passwordStrengthScore={passwordStrengthScore}
        isLoading={isLoading}
        loginHref={loginHref}
      />
    </AuthenticationCard>
  )
}
