import { ISortOrder } from '#types/graphqlTypes'
import { IAdjustmentRulesDataGridFilters } from './types'

export const SORT_OPTIONS_CONFIG: IAdjustmentRulesDataGridFilters['sortOptions'] = [
  {
    label: 'Sort: A - Z',
    value: ISortOrder.asc,
  },
  {
    label: 'Sort: Z - A',
    value: ISortOrder.desc,
  },
]

export const DEFAULT_FILTER_VALUES: IAdjustmentRulesDataGridFilters['defaultFilterValues'] = {
  pickup: { geographicType: 'region', value: '' },
  delivery: { geographicType: 'region', value: '' },
}
