import { MIN_COST_PERCENTILE } from '#components/RatesEstimatedCarrierRateAccordion/constants'
import { IAdjustmentRuleLineItemKeyEnum } from '#components/RatesEstimatedStrategyAccordion/components/AdjustmentRulesSection/types'
import { IAdjustmentRuleLineItem } from '#components/RatesEstimatedStrategyAccordion/types'
import { ICostModel } from '#types/costModel'
import { currencyFormatter } from '#utils/currencyFormatter'
import { DISPLAY } from 'dpl/constants'
import SettingsIcon from 'dpl/icons/build/SettingsIcon'
import { getPercentileAmount } from '../getPercentileAmount'

const MIN_COST_LINE_ITEM: IAdjustmentRuleLineItem = {
  key: IAdjustmentRuleLineItemKeyEnum.minPercentileLimit,
  Icon: SettingsIcon,
  label: 'Min Cost Limit of 10th reached',
  amount: DISPLAY.empty,
}

interface IGetMinCostLimitLineItemProps {
  costModel: Nullable<ICostModel>
  isBelowMinCostLimit: boolean
}

/**
 * Returns the min cost limit line item if the total cost is below the min cost limit.
 * If the min cost limit is not exceeded, it returns null.
 *
 * @returns {IAdjustmentRuleLineItem | null} The min cost limit line item
 *
 * @example getMinCostLimitLineItem({ costModel: { cost10: 100 }, isBelowMinCostLimit: true }) // returns { key: 'minPercentileLimit', Icon: SettingsIcon, label: 'Min Cost Limit of 10th reached', amount: '$100.00' }
 * @example getMinCostLimitLineItem({ costModel: { cost10: 100 }, isBelowMinCostLimit: false }) // returns null
 */
export const getMinCostLimitLineItem = ({
  costModel,
  isBelowMinCostLimit,
}: IGetMinCostLimitLineItemProps): Nullable<IAdjustmentRuleLineItem> => {
  const minCost = getPercentileAmount({ costModel, percentile: MIN_COST_PERCENTILE })

  return isBelowMinCostLimit
    ? {
        ...MIN_COST_LINE_ITEM,
        amount: currencyFormatter(minCost),
      }
    : null
}
