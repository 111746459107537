import React, { useCallback, useMemo, useState } from 'react'
import { RateDifference } from '#components/RateDifference'
import { useAnalytics } from '#hooks'
import { IEstimate } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import {
  BarSeries,
  Group,
  scaleBand,
  scaleLinear,
  useParentSize,
  min,
  max,
  AxisRight,
  AxisBottom,
  TickLabelProps,
  NumberLike,
  localPoint,
  bisectCenter,
  XYChart,
} from 'charts/core'
import { brandColors } from 'dpl'
import { Box, makeStyles, Typography } from 'dpl/core'
import moment from 'moment'
import { contractDurationFormatter } from '../ContractRatesToolPage/forms/ContractLaneInformationForm/utils'
import { getMarginDifferenceValues, TContractDuration } from '../ContractRatesToolPage/utils'
import { IPercentilesFragment } from '../graphql/CostModelRangeFragment'
import { IMarketForecastFragment } from '../graphql/MarketSimFragment'

const AXIS_LABEL_FONT_SIZE = 10
const AXIS_LABEL_LINE_HEIGHT = AXIS_LABEL_FONT_SIZE * 1.4
const AXIS_LABEL_FONT_WEIGHT = 400

const AXIS_LABEL_MAX_FONT_SIZE = 12
const AXIS_LABEL_MAX_LINE_HEIGHT = AXIS_LABEL_MAX_FONT_SIZE * 1.4
const AXIS_TICK_LENGTH = 8

const MAX_CHART_HEIGHT = 400
const AXIS_COLOR = brandColors.coolGray5
const Y_AXIS_WIDTH = 80

const bottomAxisTickLabelProps = {
  fill: AXIS_COLOR,
  fontSize: AXIS_LABEL_FONT_SIZE,
  lineHeight: AXIS_LABEL_MAX_LINE_HEIGHT,
  textAnchor: 'middle',
  verticalAnchor: 'end',
  tickLength: 0,
  style: {
    fontWeight: AXIS_LABEL_FONT_WEIGHT,
  },
} as const

export const leftAxisTickLabelProps: TickLabelProps<NumberLike> = {
  fill: AXIS_COLOR,
  fontSize: AXIS_LABEL_FONT_SIZE,
  lineHeight: AXIS_LABEL_LINE_HEIGHT,
  textAnchor: 'start',
  style: {
    fontWeight: AXIS_LABEL_FONT_WEIGHT,
  },
}

const dateFormatter = (value: string) => moment(value).format("MMM 'YY")

interface HoveredContext {
  x: number
  xIndex: number
  marginDifference: number
  isPositive: boolean
  marginDifferencePercentage: string
}

const useStyles = makeStyles(theme => ({
  axis: {
    pointerEvents: 'none',
  },
  annotationTitle: {
    flex: 1,
    '& span': {
      color: brandColors.coolGray5,
    },
  },
  positive: {
    color: brandColors.green3,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  negative: {
    color: brandColors.error1,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  noPointerEvents: {
    pointerEvents: 'none',
  },
  rateItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
  },
  percentileLabel: {
    color: brandColors.coolGray4,
  },
  boldestText: {
    fontWeight: 700,
  },
  fadeIn: {
    transition: 'opacity 0.25s',
  },
}))

const xAccessor = (d: { month: string }) => d.month
const yAccessor = (d: { monthMarginValue: number }) => d.monthMarginValue

interface IBarChartProps
  extends Pick<IEstimate, 'carrierRate' | 'marginPercentage' | 'marginValue'> {
  marketSimAvg: IMarketForecastFragment
  contractDuration: TContractDuration
  isPerLoad: boolean
}
interface IPercentiles extends Omit<IPercentilesFragment, '__typename'> {}

export function BarChart({
  carrierRate: volumeWtdAvgCarrierRate,
  contractDuration,
  isPerLoad,
  marginPercentage: selectedMarginPercentage,
  marginValue: volumeWtdAvgMarginValue,
  marketSimAvg,
}: IBarChartProps) {
  const { trackEvent } = useAnalytics()
  const { monthlyCost: monthlyCostProp, volumeWtdAvgCost: volumeWtdAvgCostProp } = marketSimAvg
  const monthlyCost = monthlyCostProp!
  const volumeWtdAvgCost = volumeWtdAvgCostProp!

  const percentileByValue = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { __typename, ...percentiles } = volumeWtdAvgCost
    const swapped = Object.entries(percentiles).map(([key, value]) => [
      currencyFormatter(value),
      key,
    ])

    return Object.fromEntries(swapped)
  }, [volumeWtdAvgCost])

  const selectedPercentile = (percentileByValue[currencyFormatter(volumeWtdAvgCarrierRate)] ??
    'cost50') as keyof IPercentiles

  const volumeWtdAvgContractRate = volumeWtdAvgCarrierRate * (selectedMarginPercentage + 1)

  const { parentRef, width } = useParentSize()
  const chartWidth = width - Y_AXIS_WIDTH
  const chartHeight = MAX_CHART_HEIGHT - AXIS_LABEL_MAX_LINE_HEIGHT - AXIS_TICK_LENGTH
  const xAxisValues = useMemo(() => {
    return monthlyCost!.map(({ month }) => month)
  }, [monthlyCost])

  const marginData = useMemo(() => {
    const transformedData = monthlyCost!.map(({ costModelRange, month, volume }, index) => {
      const monthCarrierRate = costModelRange[selectedPercentile]!
      const { marginDifference } = getMarginDifferenceValues({
        monthCarrierRate,
        volumeWtdAvgCarrierRate,
        volumeWtdAvgContractRate,
        volume,
        isPerLoad,
      })
      return {
        index,
        month,
        volume,
        monthMarginValue: marginDifference,
      }
    })

    return transformedData
  }, [
    monthlyCost,
    selectedPercentile,
    isPerLoad,
    volumeWtdAvgCarrierRate,
    volumeWtdAvgContractRate,
  ])

  const xScale = scaleBand<string>({
    range: [0, chartWidth],
    domain: xAxisValues,
  })

  const getXPosition = useCallback(
    value => {
      const scalePosition = xScale(value) ?? 0
      const bandwidthOffset = xScale.bandwidth() / 2

      return scalePosition + bandwidthOffset
    },
    [xScale]
  )

  const xPositions = useMemo(() => {
    return xAxisValues.map(getXPosition) ?? []
  }, [getXPosition, xAxisValues])

  const [minYValue, maxYValue] = useMemo(() => {
    const marginValueRange = monthlyCost.map(({ costModelRange, volume }) => {
      const monthCarrierRate = costModelRange[selectedPercentile]
      const { marginDifference } = getMarginDifferenceValues({
        monthCarrierRate,
        volumeWtdAvgCarrierRate,
        volumeWtdAvgContractRate,
        volume,
        isPerLoad,
      })
      return marginDifference
    })
    const minY = min(marginValueRange) ?? 0
    const maxY = max(marginValueRange) ?? 0
    return [minY, maxY]
  }, [
    volumeWtdAvgContractRate,
    volumeWtdAvgCarrierRate,
    monthlyCost,
    selectedPercentile,
    isPerLoad,
  ])

  const yAxisThirdValue = (maxYValue - minYValue) / 3
  const yAxisTickValues = [
    minYValue,
    minYValue + yAxisThirdValue,
    minYValue + yAxisThirdValue * 2,
    maxYValue,
  ]

  const yScale = scaleLinear<number>({
    range: [chartHeight, 18],
    domain: [minYValue, maxYValue],
  })

  const [hoveredContext, setHoveredContext] = useState<HoveredContext | null>(null)

  const mouseMoveHandler = useCallback(
    (event: React.MouseEvent) => {
      const { x = 0 } = localPoint(event) ?? {}
      const closestXIndex = bisectCenter(xPositions, x)
      const xPosition = xPositions[closestXIndex]
      if (hoveredContext?.x === xPosition || closestXIndex === -1) {
        return
      }

      const currentMonthlyCost = monthlyCost[closestXIndex ?? 0]
      const monthCarrierRate = currentMonthlyCost.costModelRange[selectedPercentile] ?? 0
      const { isPositive, marginDifference, marginDifferencePercentage } =
        getMarginDifferenceValues({
          monthCarrierRate,
          volumeWtdAvgCarrierRate,
          volumeWtdAvgContractRate,
          volume: currentMonthlyCost.volume,
          isPerLoad,
        })

      setHoveredContext({
        xIndex: closestXIndex,
        x: xPosition,
        marginDifference,
        isPositive,
        marginDifferencePercentage: String(marginDifferencePercentage),
      })

      const { month } = currentMonthlyCost
      trackEvent('Contract Rates Tool', 'BAR_CHART_MONTH_CONTEXT', {
        field: `Bar Chart Monthly Cost Context`,
        month,
        marginDifference,
        isPositive,
        marginDifferencePercentage,
        volumeWtdAvgContractRate,
      })
    },
    [
      volumeWtdAvgCarrierRate,
      chartHeight,
      volumeWtdAvgContractRate,
      hoveredContext?.x,
      monthlyCost,
      selectedPercentile,
      width,
      xPositions,
      yScale,
      isPerLoad,
    ]
  )

  const mouseLeaveHandler = useCallback(() => {
    setHoveredContext(null)
  }, [])

  const classes = useStyles()

  // refactor with better way to get header values
  const totalLoads = monthlyCost?.reduce((totalLoads, { volume }) => {
    return totalLoads + volume
  }, 0)

  const getMarginDifferenceWithTotalLoads = useCallback(() => {
    if (isPerLoad) {
      return hoveredContext ? hoveredContext.marginDifference : volumeWtdAvgMarginValue
    }

    if (!hoveredContext) {
      // volumeWtdAvgMarginValueFixed ensures that the per load value matches multiplied by the volume
      const volumeWtdAvgMarginValueFixed = Number(volumeWtdAvgMarginValue.toFixed(2))
      return volumeWtdAvgMarginValueFixed * totalLoads
    }

    return hoveredContext.marginDifference
  }, [isPerLoad, hoveredContext, volumeWtdAvgMarginValue, totalLoads])

  const marginDifferenceWithTotalLoads = getMarginDifferenceWithTotalLoads()
  const marginDifferencePercentage = hoveredContext
    ? hoveredContext.marginDifferencePercentage
    : selectedMarginPercentage * 100
  const isPositiveMargin = hoveredContext
    ? hoveredContext.isPositive
    : marginDifferenceWithTotalLoads >= 0
  const contractMonth = hoveredContext
    ? moment(monthlyCost[hoveredContext.xIndex].month).format('MMMM ‘YY')
    : contractDurationFormatter(contractDuration)

  return (
    <Box ref={parentRef} display='flex' flexDirection='column' alignItems='flex-start'>
      <Box display='flex' flexDirection='row' alignItems='baseline'>
        <Typography
          mr={0.5}
          className={isPositiveMargin ? classes.positive : classes.negative}
          variant='h3'>
          <RateDifference value={marginDifferenceWithTotalLoads} dataTest='margin-value' />
        </Typography>
        <Typography
          variant='body2'
          className={isPositiveMargin ? classes.positive : classes.negative}>
          (
          <RateDifference
            value={Number(marginDifferencePercentage)}
            dataTest='margin-percentage'
            isPercentage
          />
          )
        </Typography>
      </Box>
      <Typography variant='body2'>{contractMonth}</Typography>
      <Box padding={1}>
        <svg
          width={width}
          height={MAX_CHART_HEIGHT + AXIS_TICK_LENGTH}
          onMouseMove={mouseMoveHandler}
          onMouseLeave={mouseLeaveHandler}>
          <Group className={classes.noPointerEvents}>
            <XYChart
              height={MAX_CHART_HEIGHT + AXIS_TICK_LENGTH}
              margin={{ top: 20, right: Y_AXIS_WIDTH, bottom: 40, left: 0 }}
              width={width}
              xScale={{ type: 'band', paddingInner: 0.3 }}
              yScale={{ type: 'linear' }}>
              <AxisRight
                axisClassName={classes.axis}
                hideTicks
                hideAxisLine
                scale={yScale}
                left={chartWidth}
                numTicks={AXIS_TICK_LENGTH}
                tickValues={yAxisTickValues}
                tickFormat={value => currencyFormatter(value as number)}
              />
              <AxisBottom
                axisClassName={classes.axis}
                hideTicks
                hideAxisLine
                orientation='bottom'
                top={chartHeight}
                scale={xScale}
                tickValues={xAxisValues}
                tickLength={AXIS_TICK_LENGTH}
                tickFormat={dateFormatter}
                tickLabelProps={(_, index) => {
                  if (!hoveredContext || index !== hoveredContext?.xIndex) {
                    return bottomAxisTickLabelProps
                  }

                  return {
                    ...bottomAxisTickLabelProps,
                    fontSize: AXIS_LABEL_MAX_FONT_SIZE,
                    textAnchor: 'middle',
                    style: {
                      transition: 'font-weight 0.25s',
                      fontWeight: 700,
                    },
                  }
                }}
              />
              <BarSeries
                dataKey='Projected Margin'
                data={marginData}
                xAccessor={xAccessor}
                yAccessor={yAccessor}
                colorAccessor={d => {
                  const isPositive = d.monthMarginValue >= 0
                  if (hoveredContext && hoveredContext?.x === xPositions[d.index]) {
                    if (!isPositive) {
                      return brandColors.error1
                    }
                    return brandColors.green3
                  }

                  return !isPositive ? brandColors.error0 : brandColors.green1
                }}
              />
            </XYChart>
          </Group>
        </svg>
      </Box>
    </Box>
  )
}
