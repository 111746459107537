import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  IPillSelectSearchableOrganizationShipperProps,
  PillSelectSearchableOrganizationShipper,
} from '../PillSelectSearchableOrganizationShipper'
import { TPillSelectSearchableOrganizationShipperSchema } from './schema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IPillSelectSearchableOrganizationShipperProps>,
  TPillSelectSearchableOrganizationShipperSchema
>

export interface IControlledPillSelectSearchableOrganizationShipperProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  PillSelectSearchableOrganizationShipperProps?: Omit<
    IPillSelectSearchableOrganizationShipperProps,
    'value'
  >
}

export const ControlledPillSelectSearchableOrganizationShipper =
  memo<IControlledPillSelectSearchableOrganizationShipperProps>(
    ({ PillSelectSearchableOrganizationShipperProps, control, name }) => {
      return (
        <Controller
          name={name}
          control={control}
          render={({ onChange, value }) => {
            const { onChange: onChangeProp } = PillSelectSearchableOrganizationShipperProps || {}

            const changeHandler: IPillSelectSearchableOrganizationShipperProps['onChange'] =
              newValue => {
                onChange(newValue)
                if (onChangeProp) {
                  onChangeProp(newValue)
                }
              }

            return (
              <PillSelectSearchableOrganizationShipper
                {...PillSelectSearchableOrganizationShipperProps}
                onChange={changeHandler}
                value={value}
              />
            )
          }}
        />
      )
    }
  )
