export enum IAdjustmentRuleLocationFilterGeographicType {
  region = 'region',
  state = 'state',
  zipCode = 'zipCode',
}

export const GEOGRAPHIC_TYPE_SELECT_OPTIONS = [
  { label: 'Region', value: IAdjustmentRuleLocationFilterGeographicType.region },
  { label: 'State', value: IAdjustmentRuleLocationFilterGeographicType.state },
  { label: 'Zip code (5-digit)', value: IAdjustmentRuleLocationFilterGeographicType.zipCode },
]
