export enum IRegionsEnum {
  'NORTHEAST' = 'NORTHEAST',
  'MID_ATLANTIC' = 'MID_ATLANTIC',
  'EAST' = 'EAST',
  'SOUTHEAST' = 'SOUTHEAST',
  'MIDWEST' = 'MIDWEST',
  'NORTHERN_MIDWEST' = 'NORTHERN_MIDWEST',
  'CENTRAL' = 'CENTRAL',
  'SOUTH' = 'SOUTH',
  'SOUTHWEST' = 'SOUTHWEST',
  'WEST' = 'WEST',
}

export interface IRegionOption {
  label?: string
  value: IRegionsEnum
  description?: string
}
