import { IVolumeTypes } from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import {
  IContractRfpLaneVolumeFrequencies,
  IContractRfpVersionLaneRateRateTypes,
  IEquipmentKeys,
  IStopTypes,
} from '#types/graphqlTypes'
import moment from 'moment'
import { IRfpLaneInfoSchema } from '../schema/RfpLaneInfoSchema'
import { IRfpRatesSchema } from '../schema/RfpRatesSchema'

export function getLaneInfoInput(data: IRfpLaneInfoSchema) {
  return {
    customMiles: data.customMiles ?? null,
    equipmentKey: data.equipment as IEquipmentKeys,
    isTeam: data.isTeam,
    stops: data.stops.map(({ location, type }, index) => ({
      city: location?.city,
      isoCountryCode: location?.countryCode,
      postalCode: location?.postalCode,
      stateCode: location?.stateCode,
      stopType: type as IStopTypes,
      stopSequence: index + 1,
      ...(index === 0 && { drop: data.dropType?.startsWith('drop') }),
      ...(index === data.stops.length - 1 && { drop: data.dropType?.endsWith('drop') }),
    })),
    ...(data.volume.volumeType === IVolumeTypes.dynamicVolume
      ? {
          volumeAmounts: data.volume.dynamicVolumePerMonth?.map(amount => ({
            volume: amount.volume ?? 0,
            period: amount.date ? moment(amount.date).format('YYYY-MM') : null,
          })),
        }
      : { volume: data.volume.flatVolumePerMonth }),
    volumeFrequency: data.volume.volumeFrequency as IContractRfpLaneVolumeFrequencies,
    autoGenerateRates: data.autoGenerateRates === 'yes',
  }
}

export function getLaneRatesInput(data: IRfpRatesSchema) {
  return {
    rate: {
      rateType: data.rateType as IContractRfpVersionLaneRateRateTypes,
      carrierRate: data.carrierRate,
      fuelRatePerMile: data.fuelRate,
      marginPercentage: data.marginPercentage ? data.marginPercentage / 100 : undefined,
      shipperRate: data.shipperRate,
    },
    autoGenerateRates: data.autoGenerateRates === 'yes',
  }
}
