import { IAdjustmentRuleVersion } from '#types/graphqlTypes'
import { separateRulesByAction } from '../../../routes/spot-rates-control-panel/components/AdjustmentRulesSection/utils/separateRulesByAction'
import { IAdjustmentRulesSectionProps } from '../../RatesEstimatedStrategyAccordion/components/AdjustmentRulesSection'
import { IAdjustmentRuleLineItemKeyEnum } from '../../RatesEstimatedStrategyAccordion/components/AdjustmentRulesSection/types'
import { IRule } from '../../RatesEstimatedStrategyAccordion/types'
import { getAdjustmentRuleLineItem } from './getAdjustmentRuleLineItem'

interface IGetAppliedAdjustmentRuleLineItemsArgs {
  defaultMarginPremium: number
  rules: Nullable<IRule[]>
  carrierRate: number
  maxMarginPremium?: number
  marginPremiumLimited?: boolean
}

export const getAppliedAdjustmentRuleLineItems = ({
  carrierRate,
  defaultMarginPremium,
  marginPremiumLimited = false,
  maxMarginPremium,
  rules,
}: IGetAppliedAdjustmentRuleLineItemsArgs) => {
  if (!rules?.length) return []

  const lineItems: IAdjustmentRulesSectionProps['rules'] = []
  const { additiveRules, baseRules } = separateRulesByAction(rules as IAdjustmentRuleVersion[])

  // If there no base rules, this means that the default setting is applied
  if (!baseRules?.length) {
    lineItems.push(
      getAdjustmentRuleLineItem({
        carrierRate,
        rule: {
          id: 'default',
          ruleAction: 'default',
          adjustmentValue: defaultMarginPremium,
          reason: '',
        },
      })
    )
  } else {
    const baseRulesLineItems = baseRules.map(rule => {
      return getAdjustmentRuleLineItem({ carrierRate, rule })
    })
    lineItems.push(...baseRulesLineItems)
  }

  if (additiveRules?.length) {
    const additiveRulesLineItems = additiveRules.map(rule => {
      return getAdjustmentRuleLineItem({ carrierRate, rule })
    })
    lineItems.push(...additiveRulesLineItems)
  }

  if (marginPremiumLimited) {
    lineItems.push(
      getAdjustmentRuleLineItem({
        carrierRate,
        rule: {
          id: IAdjustmentRuleLineItemKeyEnum.maxMarginLimit,
          ruleAction: IAdjustmentRuleLineItemKeyEnum.maxMarginLimit,
          adjustmentValue: maxMarginPremium,
        },
      })
    )
  }

  return lineItems
}
