import React, { memo, useCallback, useMemo, useState } from 'react'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import { brandColors } from 'dpl'
import { Box, Button, Collapse, Typography } from 'dpl/core'
import AddIcon from 'dpl/icons/build/AddIcon'
import {
  EmbeddedCreateCustomerForm,
  IEmbeddedCreateCustomerFormProps,
} from './EmbeddedCreateCustomerForm'

export interface IEmbeddedCreateCustomerFormButtonProps
  extends Omit<IEmbeddedCreateCustomerFormProps, 'dataTest' | 'onCancel'> {
  /**
   * @default 'EmbeddedCreateCustomerFormButton'
   */
  dataTest?: string
  clearOrganizationShipper: () => void
}

export const EmbeddedCreateCustomerFormButton = memo<IEmbeddedCreateCustomerFormButtonProps>(
  ({ clearOrganizationShipper, dataTest = 'EmbeddedCreateCustomerFormButton', onSuccess }) => {
    const { userPermissions } = usePermissionsContext()

    const isPermittedToCreateCustomer = useMemo(
      () => userPermissions['shipper_management.create_organization_shipper'],
      [userPermissions]
    )
    const [open, setOpen] = useState(false)
    const [showPermissionError, setShowPermissionError] = useState(false)

    const clickHandler = useCallback(() => {
      if (isPermittedToCreateCustomer) {
        clearOrganizationShipper()
        setOpen(prev => !prev)
      } else {
        setShowPermissionError(prev => !prev)
      }
    }, [isPermittedToCreateCustomer, clearOrganizationShipper])

    const successHandler = useCallback<IEmbeddedCreateCustomerFormButtonProps['onSuccess']>(
      newCustomer => {
        setOpen(false)
        if (onSuccess) onSuccess(newCustomer)
      },
      [onSuccess]
    )

    return (
      <Box mt={0.5} data-test={dataTest}>
        <Collapse in={!open}>
          <Button
            size='xsmall'
            variant='text'
            data-test={`${dataTest}-button`}
            startIcon={<AddIcon size='large' />}
            onClick={clickHandler}>
            Add New Customer
          </Button>
        </Collapse>
        <Collapse in={open} unmountOnExit>
          <EmbeddedCreateCustomerForm
            onCancel={clickHandler}
            onSuccess={successHandler}
            dataTest={`${dataTest}-form`}
          />
        </Collapse>
        {showPermissionError && (
          <Typography mt={0.5} color={brandColors.error1}>
            You need permission from an organization admin to access this feature. Please contact
            your admin to request access.
          </Typography>
        )}
      </Box>
    )
  }
)
