import React, { useState } from 'react'
import { canMove } from '#routes/contract-rfp/ContractRFPsPage/utils'
import { RFP_STATES } from '#routes/contract-rfp/utils'
import { IContractRfp, IContractRfpStates } from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import { brandColors, FunctionalTag, IconButton, TAG_STYLE } from 'dpl'
import { makeStyles, Typography } from 'dpl/core'
import { FolderIcon } from 'dpl/icons'
import { FilesSideNav } from '../FilesSideNav'

export interface TitleActionsProps extends Pick<IContractRfp, 'state'> {
  onStatusChange: (state: IContractRfpStates) => void
  contractId: string
}

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
  },
}))

export function TitleActions({ contractId, onStatusChange, state }: TitleActionsProps) {
  const classes = useStyles()
  const { userPermissions } = usePermissionsContext()
  const [showSideNav, setShowSideNav] = useState(false)
  return (
    <div className={classes.root}>
      <div>
        <Typography variant='caption' color={brandColors.coolGray5}>
          Status:{' '}
        </Typography>
        {state === IContractRfpStates.archived ? (
          <FunctionalTag
            options={[
              {
                value: 'archived',
                label: 'Archived',
                outlineOnly: true,
                style: TAG_STYLE.disabled,
              },
            ]}
            disabled
            value='archived'
          />
        ) : (
          <FunctionalTag
            options={RFP_STATES.filter(({ value }) => canMove(state, value) || value === state)}
            onSelect={newState => {
              onStatusChange(newState.value as IContractRfpStates)
            }}
            disabled={
              state === IContractRfpStates.closed ||
              !userPermissions['contract_rfp.update_rfp_state']
            }
            value={state}
          />
        )}
      </div>
      <IconButton
        Icon={FolderIcon}
        onClick={() => setShowSideNav(true)}
        size='xlarge'
        color='secondary'
        dataTest='files-button'
      />
      {showSideNav && (
        <FilesSideNav contractId={contractId} onClose={() => setShowSideNav(false)} />
      )}
    </div>
  )
}
