import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ControlledAutocompleteOrganizationUser } from '#components'
import { ControlledAutocompleteOrganizationShipper } from '#components/ControlledAutocompleteOrganizationShipper'
import { RFP_STATES } from '#routes/contract-rfp/utils/constans'
import { useCurrentOrgUserQuery } from '#routes/routing-guide/RoutingGuideLanesPage/forms/RoutingGuideLaneFormModal/components/ControlledLaneManagerField/graphql/CurrentOrgUser'
import { IContractRfpStates, IOrganizationUserStatus } from '#types/graphqlTypes'
import { brandColors, InlineMessage } from 'dpl'
import { FORMATS } from 'dpl/constants'
import { Divider, makeStyles, Typography } from 'dpl/core'
import { MinusIcon } from 'dpl/icons'
import { useFlagsContext } from 'flags/src/FlagsContext'
import ControlledDatePicker from 'forms/components/ControlledDatePicker'
import ControlledFunctionalTag from 'forms/components/ControlledFunctionalTag'
import ControlledTextField from 'forms/components/ControlledTextField'
import moment from 'moment'
import { TAddNewRFPFormSchema } from '../schema'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  addCustomerBtn: {
    marginTop: theme.spacing(1),
  },
  datesContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  addCustomer: {
    marginTop: theme.spacing(1),
    backgroundColor: brandColors.coolGray0,
    padding: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1.5),
    flexDirection: 'column',
    borderRadius: theme.spacing(0.5),
  },
  addCustomerForm: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
  cancelContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export function AddNewRFPForm() {
  const { isFlagEnabled } = useFlagsContext()
  const isMultipleTeamMembersEnabled = isFlagEnabled('rfp_multiple_team_members')
  const classes = useStyles()
  const { control, errors, setValue } = useFormContext<TAddNewRFPFormSchema>()
  const { value: status } =
    useWatch<TAddNewRFPFormSchema['status']>({ control, name: 'status' }) || {}
  const contractStartDate = useWatch<TAddNewRFPFormSchema['contractStartDate']>({
    control,
    name: 'contractStartDate',
  })
  const { loading: loadingCurrentOrgUser } = useCurrentOrgUserQuery({
    onCompleted: ({ currentSession }) => {
      const { organizationUser } = currentSession || {}
      setValue('assignee', organizationUser)
    },
  })

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>RFP Details</Typography>
      <ControlledTextField
        name='name'
        control={control}
        TextFieldProps={{
          placeholder: 'RFP Name *',
          label: 'RFP Name',
          required: true,
          error: Boolean(errors.name),
          helperText: errors.name?.message,
          dataTest: 'rfp-name-input',
        }}
      />
      <div>
        <ControlledAutocompleteOrganizationShipper
          name='customer'
          control={control}
          enableCreate
          AutocompleteOrganizationShipperProps={{
            placeholder: 'Customer *',
            error: Boolean(errors.customer),
            helperText: errors.customer?.message,
            dataTest: 'rfp-customer-input',
          }}
        />
      </div>
      <div className={classes.datesContainer}>
        <ControlledDatePicker
          control={control}
          name='contractStartDate'
          DatePickerProps={{
            placeholder: 'MM/DD/YYYY',
            label: 'Contract Start Date',
            required: true,
            error: Boolean(errors.contractStartDate),
            helperText: errors.contractStartDate?.message,
            format: FORMATS.dateFormat,
            dataTest: 'rfp-start-date-input',
          }}
        />
        <MinusIcon />
        <ControlledDatePicker
          control={control}
          name='contractEndDate'
          DatePickerProps={{
            placeholder: 'MM/DD/YYYY',
            label: 'Contract End Date',
            required: true,
            error: Boolean(errors.contractEndDate),
            helperText: errors.contractEndDate?.message,
            format: FORMATS.dateFormat,
            minDate: contractStartDate ? moment(contractStartDate) : undefined,
            dataTest: 'rfp-end-date-input',
          }}
        />
      </div>
      <Divider />
      <ControlledFunctionalTag
        name='status'
        control={control}
        defaultValue={{}}
        FunctionalTagProps={{
          label: 'Status',
          required: true,
          disabled: true,
          options: RFP_STATES,
          dataTest: 'rfp-status-input',
        }}
      />
      {[IContractRfpStates.in_progress, IContractRfpStates.planning].includes(
        status as IContractRfpStates
      ) && (
        <ControlledDatePicker
          control={control}
          name='dueDate'
          DatePickerProps={{
            placeholder: 'MM/DD/YYYY',
            label: 'Due Date',
            required: true,
            error: Boolean(errors.dueDate),
            helperText: errors.dueDate?.message,
            format: FORMATS.dateFormat,
            minDate: moment(),
            dataTest: 'rfp-due-date-input',
          }}
        />
      )}
      {IContractRfpStates.submitted === (status as IContractRfpStates) && (
        <ControlledDatePicker
          control={control}
          name='submittedDate'
          DatePickerProps={{
            placeholder: 'MM/DD/YYYY HH:MM',
            label: 'Submitted Date',
            required: true,
            error: Boolean(errors.submittedDate),
            helperText: errors.submittedDate?.message,
            dataTest: 'rfp-submitted-date-input',
          }}
        />
      )}
      {IContractRfpStates.closed === (status as IContractRfpStates) && (
        <InlineMessage message="Once an RFP is closed, you'll have access to the RFP to view but it cannot be re-opened and lanes cannot be edited." />
      )}
      {isMultipleTeamMembersEnabled ? (
        <>
          <Divider />
          <Typography variant='subtitle1'>Team</Typography>
          <ControlledAutocompleteOrganizationUser
            control={control}
            name='assignee'
            AutocompleteOrganizationUserProps={{
              label: 'Assignee',
              placeholder: 'Select',
              required: true,
              organizationUserStatuses: [IOrganizationUserStatus.active],
              error: Boolean(errors.assignee),
              helperText: errors.assignee?.message,
              disabled: loadingCurrentOrgUser,
              dataTest: 'rfp-assignee-input',
            }}
          />
          <ControlledAutocompleteOrganizationUser
            control={control}
            name='additionalTeamMember1'
            AutocompleteOrganizationUserProps={{
              label: 'Additional Team Member (Optional)',
              placeholder: 'Select',
              organizationUserStatuses: [IOrganizationUserStatus.active],
              error: Boolean(errors.additionalTeamMember1),
              helperText: errors.additionalTeamMember1?.message,
              dataTest: 'rfp-additional-team-member-1-input',
            }}
          />
          <ControlledAutocompleteOrganizationUser
            control={control}
            name='additionalTeamMember2'
            AutocompleteOrganizationUserProps={{
              label: 'Additional Team Member (Optional)',
              placeholder: 'Select',
              organizationUserStatuses: [IOrganizationUserStatus.active],
              error: Boolean(errors.additionalTeamMember2),
              helperText: errors.additionalTeamMember2?.message,
              dataTest: 'rfp-additional-team-member-2-input',
            }}
          />
        </>
      ) : (
        <ControlledAutocompleteOrganizationUser
          name='assignee'
          control={control}
          AutocompleteOrganizationUserProps={{
            label: 'Assignee',
            placeholder: 'Assignee',
            required: true,
            dataTest: 'rfp-assignee-input',
          }}
        />
      )}
    </div>
  )
}
