type TStepState = 'active' | 'inactive' | 'review'

export type TAdjustmentRuleFormSteps = TStepState[]

export enum ADJUSTMENT_TYPES {
  cost = 'cost',
  margin = 'margin',
}

export enum GEOGRAPHIC_TYPES {
  'regional' = 'region',
  'state' = 'state',
  'fiveDigitZipCodes' = 'postal_code',
}

export enum EQUIPMENT_TYPES {
  dryVan = '53_van',
  reefer = '53_reefer',
}
