import React from 'react'
import { Link, Typography } from 'dpl/core'
import { AuthenticationCard, IAuthenticationCardProps } from '../AuthenticationCard'

export interface IMagicLinkSentCardProps
  extends Pick<IAuthenticationCardProps, 'termsOfServiceUrl'> {
  /**
   * Email that magic link was sent to
   */
  magicLinkSentTo: string
  /**
   * Href to go back to login page
   */
  loginHref: string
}

export function MagicLinkSentCard({
  loginHref,
  magicLinkSentTo,
  termsOfServiceUrl,
}: IMagicLinkSentCardProps) {
  return (
    <AuthenticationCard
      dataTest='magic-link-sent'
      title='Check Your Email'
      subheader={`An email was sent to ${magicLinkSentTo}. Open the email and click the link to continue.`}
      termsOfServiceUrl={termsOfServiceUrl}>
      <Typography variant='subtitle1' color='textSecondary' marginBottom={3}>
        If you don&apos;t see the email within a few minutes be sure to check spam folders.
      </Typography>
      <Link href={loginHref} variant='subtitle1'>
        Use a different email
      </Link>
    </AuthenticationCard>
  )
}
