import { IRuleTypeEnum } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { DISPLAY } from 'dpl/constants'

interface IGetAdjustmentRuleAmountLabelProps {
  adjustmentValue: Nullable<number>
  carrierRate: Nullable<number>
  ruleAction: Nullable<string>
}

/**
 * Returns the label for an adjustment rule amount based on the adjustment amount and rule action.
 *
 * @returns {string} The label for the adjustment rule amount
 *
 * @example getAdjustmentRuleAmountLabel({ carrierRate: 100, adjustmentValue: 0.1, ruleAction: 'baseline_adjustment' }) // $10.00
 * @example getAdjustmentRuleAmountLabel({ carrierRate: 100, adjustmentValue: 0.02, ruleAction: 'additive_adjustment' }) // +$2.00
 * @example getAdjustmentRuleAmountLabel({ carrierRate: 100, adjustmentValue: -0.03, ruleAction: 'additive_adjustment' }) // -$3.00
 */
export const getAdjustmentRuleAmountLabel = ({
  adjustmentValue,
  carrierRate,
  ruleAction,
}: IGetAdjustmentRuleAmountLabelProps) => {
  if (!carrierRate) {
    return DISPLAY.empty
  }

  if (!adjustmentValue) {
    return DISPLAY.empty
  }
  const value = adjustmentValue || 0

  if (ruleAction === IRuleTypeEnum.additive) {
    const additiveAmount = carrierRate * value
    if (additiveAmount > 0) {
      return `+${currencyFormatter(additiveAmount)}`
    }

    return currencyFormatter(additiveAmount)
  }

  // Default or Base Rule Amount
  return currencyFormatter(carrierRate * value)
}
