import React, { memo, useCallback } from 'react'
import { useAnalytics } from '#hooks/useAnalytics'
import { getApolloErrorMessages } from 'dpl/components/ApolloErrorMessages'
import { IModalAction } from 'dpl/components/Modals'
import { ConfirmationModal, IConfirmationModalProps } from 'dpl/components/Modals/ConfirmationModal'
import { useToastContext } from 'dpl/components/Toast'
import { Typography } from 'dpl/core'
import { useDeactivateAdjustmentRuleMutation } from './graphql/DeactivateAdjustmentRuleMutation'

const TITLE = 'Are you sure you want to deactivate this rule?'
const BODY = 'Deactivating is immediate and cannot be undone.'

export interface IDeactivateAdjustmentRuleConfirmationModalProps
  extends Pick<IConfirmationModalProps, 'onClose'> {
  /**
   * @default 'DeactivateAdjustmentRuleConfirmationModal'
   */
  dataTest?: string
  /**
   * Id of the organization adjustment rule being deactivated
   */
  organizationAdjustmentRuleId: string
}

export const DeactivateAdjustmentRuleConfirmationModal =
  memo<IDeactivateAdjustmentRuleConfirmationModalProps>(
    ({
      dataTest = 'DeactivateAdjustmentRuleConfirmationModal',
      onClose,
      organizationAdjustmentRuleId,
    }) => {
      const { openToast } = useToastContext()
      const { trackEvent } = useAnalytics()
      const [deactivateCarrierRate, { error: apolloErrors }] = useDeactivateAdjustmentRuleMutation()

      const confirmHandler = useCallback(async () => {
        try {
          const { data } = await deactivateCarrierRate({
            variables: { input: { organizationAdjustmentRuleId } },
          })

          const { deactivateOrganizationAdjustmentRule } = data || {}
          const { organizationAdjustmentRule, errors: mutationErrors = [] } =
            deactivateOrganizationAdjustmentRule || {}
          const { id: adjustmentRuleId } = organizationAdjustmentRule || {}
          const errorMessages = getApolloErrorMessages({ apolloErrors, mutationErrors })

          if (mutationErrors.length) {
            openToast({ toastMessage: errorMessages, toastType: 'alert' })
            trackEvent('Spot Rates Control Panel', 'DEACTIVATE_ADJUSTMENT_RULE_ERROR', {
              organizationAdjustmentRuleId,
              mutationErrors,
            })
            return
          }
          if (adjustmentRuleId) {
            openToast({ toastMessage: 'Adjustment rule deactivated.' })
            trackEvent('Spot Rates Control Panel', 'DEACTIVATE_ADJUSTMENT_RULE_SUCCESS', {
              organizationAdjustmentRuleId,
            })
            onClose()
          }
        } catch (e) {
          const errorMessages = getApolloErrorMessages({ apolloErrors: e })
          openToast({ toastMessage: errorMessages, toastType: 'alert' })
          trackEvent('Spot Rates Control Panel', 'RUNTIME_ERROR', {
            organizationAdjustmentRuleId,
            apolloErrors: e,
          })
        }
      }, [
        apolloErrors,
        deactivateCarrierRate,
        onClose,
        openToast,
        organizationAdjustmentRuleId,
        trackEvent,
      ])

      const actions: IModalAction[] = [
        {
          label: 'No, Cancel',
          action: onClose,
          type: 'dismiss',
          dataTest: `${dataTest}-dismissButton`,
        },
        {
          label: 'Yes, Deactivate',
          action: confirmHandler,
          type: 'delete',
          dataTest: `${dataTest}-deactivateButton`,
        },
      ]

      return (
        <ConfirmationModal
          actions={actions}
          dataTest={dataTest}
          title={TITLE}
          titleVariant='h3'
          onClose={onClose}
          open>
          <Typography variant='body1'>{BODY}</Typography>
        </ConfirmationModal>
      )
    }
  )
