import { AdvancedInputsSchema } from '../../laneInformationFormSchema'

export const getAdvancedInputsDefaultValues = ({
  advancedInputs,
  isUiImproveEnabled,
}: {
  advancedInputs?: AdvancedInputsSchema
  isUiImproveEnabled: boolean
}): AdvancedInputsSchema => {
  const { assignmentLeadTime, creationLeadTime, palletCount } = advancedInputs || {}
  const { amount: assignmentAmount, scale: assignmentScale } = assignmentLeadTime || {}
  const { amount: creationAmount, scale: creationScale } = creationLeadTime || {}

  return {
    ...advancedInputs,
    creationLeadTime: {
      amount: creationAmount ?? (isUiImproveEnabled ? 3 : null),
      scale: creationScale || (isUiImproveEnabled ? 'days' : 'mins'),
    },
    assignmentLeadTime: {
      amount: assignmentAmount ?? (isUiImproveEnabled ? 1 : null),
      scale: assignmentScale || (isUiImproveEnabled ? 'days' : 'mins'),
    },
    palletCount: palletCount || (isUiImproveEnabled ? 24 : null),
  }
}
