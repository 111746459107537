import partition from 'lodash/partition'
import { IMapPosition, POSITION_TYPE } from '../types'

/**
 * Splits array into two separate properties based on pings that should always be clearly seen
 * @example getPositionSources([ ...etc ]) // { keyPositions: [], otherPositions: [] }
 */
export function getPositionSources<TPosition extends IMapPosition>(
  positions: TPosition[]
): Record<'keyPositions' | 'otherPositions', TPosition[]> {
  const [keyPositions = [], otherPositions = []] = partition(
    positions,
    ({ type = POSITION_TYPE.PING }) => {
      return [
        POSITION_TYPE.LAST_PING,
        POSITION_TYPE.LAST_PING_SELECTED,
        POSITION_TYPE.LAST_PING_DESELECTED,
        POSITION_TYPE.LAST_PING_LATE_DESELECTED,
        POSITION_TYPE.LAST_PING_LATE,
        POSITION_TYPE.LAST_PING_OUTDATED,
        POSITION_TYPE.LAST_PING_OUTDATED_DESELECTED,
        POSITION_TYPE.PREVIOUS_PING_SELECTED,
      ].includes(type)
    }
  )
  return {
    keyPositions,
    otherPositions,
  }
}
