import React, { memo, useState } from 'react'
import { IContractLengthMonths } from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import { FormModal, IFormModalProps, IModalAction } from 'dpl/components/Modals'
import { Box } from 'dpl/core'
import { IContractDurationSchema } from '../../../../schema'
import { getContractDurationValues, getMonthYearRange } from '../../../../utils'
import { MonthRangePicker } from './components/MonthRangePicker/MonthRangePicker'

export interface ICustomMonthRangeModalProps extends Pick<IFormModalProps, 'open' | 'onClose'> {
  onSuccess: (contractDurationValues: IContractDurationSchema) => void
  onClose: () => void
  open: boolean
  /**
   * @default 'CustomMonthRangeModal'
   */
  dataTest?: string
  currentContractDuration: IContractDurationSchema
}

export const CustomMonthRangeModal = memo(
  ({
    currentContractDuration,
    dataTest = 'CustomMonthRangeModal',
    onClose,
    onSuccess,
    open,
  }: ICustomMonthRangeModalProps) => {
    const { contractEndDate: currentEndDate, contractStartDate: currentStartDate } =
      currentContractDuration

    const [startDate, setStartDate] = useState<string>(currentStartDate)
    const [endDate, setEndDate] = useState<string>(currentEndDate)

    const fifteenMonthsContractDuration = getContractDurationValues(
      IContractLengthMonths.fifteenMonths
    )
    const { contractEndDate, contractStartDate } = fifteenMonthsContractDuration

    const months = getMonthYearRange({ startDate: contractStartDate, endDate: contractEndDate })

    const handleSave = () => {
      const contractMonths = getMonthYearRange({ startDate, endDate })
      onSuccess({
        contractStartDate: startDate,
        contractEndDate: endDate,
        contractLengthMonths: contractMonths.length,
      })

      onClose()
    }

    const handleCancel = () => {
      setStartDate(currentStartDate)
      setEndDate(currentEndDate)
      onClose()
    }

    const actions: IModalAction[] = [
      {
        label: 'Cancel',
        type: 'dismiss',
        dataTest: `${dataTest}-cancel`,
        action: handleCancel,
      },
      {
        label: 'Save Changes',
        type: 'confirm',
        dataTest: `${dataTest}-submit`,
        action: handleSave,
        disabled: !startDate || !endDate,
      },
    ]

    const clickMonthHandler = (date: string) => {
      if (!startDate || (startDate && endDate)) {
        setStartDate(date)
        setEndDate('')
        return
      }
      const [newStart, newEnd] =
        new Date(date) < new Date(startDate) ? [date, startDate] : [startDate, date]
      setStartDate(newStart)
      setEndDate(newEnd)
    }

    return (
      <FormModal
        actions={actions}
        dataTest={dataTest}
        open={open}
        onClose={onClose}
        includeCloseIconButton>
        <Box mt={1}>
          <MonthRangePicker
            months={months}
            startDate={startDate}
            endDate={endDate}
            onMonthClick={clickMonthHandler}
          />
        </Box>
      </FormModal>
    )
  }
)
