import React from 'react'
import { DataGridAdvancedTextCell } from 'dpl'
import NumericFormat from 'dpl/components/NumericFormat'
import { DISPLAY } from 'dpl/constants'
import { Typography } from 'dpl/core'
import { ICarrierRateRateTypes } from '../../../../../../types/graphqlTypes'
import { IRoutingGuideCarrierDataGrid } from '../types'
import { getCarrierCapacityText } from '../utils/getCarrierCapacityText'

export const ROUTING_GUIDE_CARRIER_SEQUENCE_COLUMN: IRoutingGuideCarrierDataGrid['columns'] = [
  {
    field: 'sequenceNumber',
    headerName: '#',
    width: 50,
  },
]

export const ROUTING_GUIDE_CARRIER_COLUMNS: IRoutingGuideCarrierDataGrid['columns'] = [
  {
    field: 'carrier',
    headerName: 'Carrier',
    minWidth: 200,
    renderCell: ({ row }) => {
      const { organizationCarrierRelationship } = row || {}
      const { carrier } = organizationCarrierRelationship || {}
      const { dotNumber, legalName, mcNumber } = carrier || {}
      return (
        <DataGridAdvancedTextCell bottomCaption={`DOT ${dotNumber} · MC ${mcNumber}`}>
          <Typography variant='body2'>{legalName}</Typography>
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'carrierContact',
    headerName: 'Carrier Contact',
    minWidth: 200,
    renderCell: ({ row }) => {
      const { carrierUser } = row || {}
      const { consumerUser } = carrierUser || {}
      const { user } = consumerUser || {}
      const { emailAddress } = user || {}
      return (
        <DataGridAdvancedTextCell>
          <Typography variant='body2'>{emailAddress}</Typography>
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'rate',
    headerName: 'Rate',
    minWidth: 150,
    renderCell: ({ row }) => {
      const { rate, rateType } = row || {}
      return (
        <DataGridAdvancedTextCell
          bottomCaption={rateType === ICarrierRateRateTypes.all_in ? 'All-in' : 'Linehaul'}>
          <Typography variant='body2'>
            <NumericFormat prefix='$' value={rate} thousandSeparator displayType='text' />
          </Typography>
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'capacity',
    headerName: 'Capacity',
    minWidth: 200,
    renderCell: ({ row }) => {
      const capacityText = getCarrierCapacityText(row.routingGuideCarrierCapacity)
      return <Typography variant='body2'>{capacityText}</Typography>
    },
  },
  {
    field: 'carrierManager',
    headerName: 'Carrier Manager',
    renderCell: ({ row }) => {
      const { organizationCarrierRelationship } = row || {}
      const { organizationCarrierManagers } = organizationCarrierRelationship || {}
      const [carrierManager] = organizationCarrierManagers || []
      const { organizationUser } = carrierManager || {}
      const { user } = organizationUser || {}
      const { emailAddress, firstName = '', lastName = '' } = user || {}
      const name = [firstName, lastName].filter(Boolean).join(' ')
      return (
        <DataGridAdvancedTextCell bottomCaption={emailAddress}>
          <Typography variant='body2'>{name || DISPLAY.empty}</Typography>
        </DataGridAdvancedTextCell>
      )
    },
  },
]
