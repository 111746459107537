import * as yup from 'yup'
import { AutocompleteOrganizationUserSchema } from '../../../../../../components/ControlledAutocompleteOrganizationUser/schema'

export const RoutingGuideManagerSchema = yup.object({
  laneManager: AutocompleteOrganizationUserSchema.nullable()
    .default(null)
    .required('You must assign a lane manager'),
})

export type TRoutingGuideManagerSchema = yup.InferType<typeof RoutingGuideManagerSchema>
