import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { DEFAULT_FORM_STEPS_STATE, STEPS_BY_NAME } from '../constants'
import { useAdjustmentRuleFormContext } from '../hooks'
import { TAdjustmentRuleFormSteps } from '../types'
import { AdjustmentRuleFormStepsContext } from './AdjustmentRuleFormStepsContext'

export interface IAdjustmentRuleFormStepsProviderProps {
  formSteps?: TAdjustmentRuleFormSteps
}

export function AdjustmentRuleFormStepsProvider({
  children,
  formSteps: formStepsProp,
}: PropsWithChildren<IAdjustmentRuleFormStepsProviderProps>) {
  const [formSteps, setFormSteps] = useState(formStepsProp || DEFAULT_FORM_STEPS_STATE)
  const { trigger } = useAdjustmentRuleFormContext()

  const activeStepIndex = useMemo(() => {
    return formSteps.findIndex(key => key === 'active') || 0
  }, [formSteps])

  const continueClickHandler = useCallback(
    async (newStep: number) => {
      const isStepValid = await trigger(['reason', STEPS_BY_NAME[newStep - 1]])
      if (isStepValid) {
        setFormSteps(prevSteps => {
          const newSteps = [...prevSteps]
          newSteps[newStep - 1] = 'review'
          newSteps[newStep] = 'active'
          return newSteps
        })
      }
    },
    [trigger]
  )

  const editClickHandler = useCallback(async (newStep: number) => {
    setFormSteps(prevSteps => {
      return prevSteps.map((state, i) => {
        if (i < newStep) return 'review'
        if (i === newStep) return 'active'
        return 'inactive'
      })
    })
  }, [])

  const value = useMemo(() => {
    return {
      formSteps,
      activeStepIndex,
      continueClickHandler,
      editClickHandler,
    }
  }, [activeStepIndex, continueClickHandler, formSteps, editClickHandler])

  return (
    <AdjustmentRuleFormStepsContext.Provider value={value}>
      {children}
    </AdjustmentRuleFormStepsContext.Provider>
  )
}
