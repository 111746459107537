import React from 'react'
import { UserAssignmentsAvatarGroup } from '#routes/contract-rfp/components'
import { DataGridAvatarCell } from 'dpl'
import { FORMATS } from 'dpl/constants'
import { Typography } from 'dpl/core'
import moment from 'moment'
import { IArchivedContractRFPsDataGrid } from '../types'

export const DEPRECATED_COLUMNS: IArchivedContractRFPsDataGrid['columns'] = [
  {
    field: 'displayId',
    headerName: 'ID',
    width: 120,
    renderCell: ({ row: { displayId } }) => (
      <Typography variant='body2'>{`RFP-${displayId}`}</Typography>
    ),
  },
  {
    field: 'name',
    headerName: 'RFP Name',
    flex: 1,
    minWidth: 300,
    renderCell: ({ row: { name } }) => <Typography variant='body2'>{name}</Typography>,
  },
  {
    field: 'organizationShipper',
    headerName: 'Customer',
    minWidth: 250,
    renderCell: ({ row: { organizationShipper } }) => (
      <Typography variant='body2'>{organizationShipper.name}</Typography>
    ),
  },
  {
    field: 'contractDates',
    headerName: 'Contract Dates',
    minWidth: 300,
    renderCell: ({ row: { endAt, startAt } }) => {
      const formattedStartAt = moment(startAt).format(FORMATS.monthDayYear)
      const formattedEndAt = moment(endAt).format(FORMATS.monthDayYear)

      return (
        <Typography variant='body2'>
          {formattedStartAt} - {formattedEndAt}
        </Typography>
      )
    },
  },
  {
    field: 'assignedToOrganizationUser',
    headerName: 'Assignee',
    minWidth: 300,
    renderCell: ({ row: { assignedToOrganizationUser } }) => {
      const { user } = assignedToOrganizationUser ?? {}
      const { fullName } = user ?? {}

      return (
        <DataGridAvatarCell
          AvatarProps={{
            name: fullName ?? undefined,
          }}>
          {fullName}
        </DataGridAvatarCell>
      )
    },
  },
]

export const COLUMNS: IArchivedContractRFPsDataGrid['columns'] = [
  {
    field: 'displayId',
    headerName: 'ID',
    width: 120,
    renderCell: ({ row: { displayId } }) => (
      <Typography variant='body2'>{`RFP-${displayId}`}</Typography>
    ),
  },
  {
    field: 'name',
    headerName: 'RFP Name',
    flex: 1,
    minWidth: 300,
    renderCell: ({ row: { name } }) => <Typography variant='body2'>{name}</Typography>,
  },
  {
    field: 'organizationShipper',
    headerName: 'Customer',
    minWidth: 250,
    renderCell: ({ row: { organizationShipper } }) => (
      <Typography variant='body2'>{organizationShipper.name}</Typography>
    ),
  },
  {
    field: 'contractDates',
    headerName: 'Contract Dates',
    minWidth: 300,
    renderCell: ({ row: { endAt, startAt } }) => {
      const formattedStartAt = moment(startAt).format(FORMATS.monthDayYear)
      const formattedEndAt = moment(endAt).format(FORMATS.monthDayYear)

      return (
        <Typography variant='body2'>
          {formattedStartAt} - {formattedEndAt}
        </Typography>
      )
    },
  },
  {
    field: 'userAssignments',
    headerName: 'Team',
    flex: 1,
    renderCell: function Render({ row }) {
      return <UserAssignmentsAvatarGroup userAssignments={row.userAssignments} />
    },
  },
]
