import { PasswordSchema } from 'auth/common/schema'
import { REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import * as yup from 'yup'

export const OnboardFormSchema = yup.object({
  firstName: yup.string().required(REQUIRED_MESSAGE),
  lastName: yup.string().required(REQUIRED_MESSAGE),
  password: PasswordSchema,
})

export type TOnboardFormSchema = yup.InferType<typeof OnboardFormSchema>
