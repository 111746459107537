import moment from 'moment'
import { TODAY } from '../../../constants'
import { TAdjustmentRulesRow } from '../../../types'

interface IHasAdjustmentRuleStartedProps extends Pick<TAdjustmentRulesRow, 'startAt'> {}

export const hasAdjustmentRuleStarted = ({ startAt }: IHasAdjustmentRuleStartedProps): boolean => {
  const startDate = moment(startAt).utc()
  const started = startDate.isSameOrBefore(TODAY.utc(), 'day')
  return started
}
