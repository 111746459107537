import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  AutocompleteOrganizationUser,
  IAutocompleteOrganizationUserProps,
} from '../AutocompleteOrganizationUser'
import { TAutocompleteOrganizationUserSchema } from './schema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IAutocompleteOrganizationUserProps>,
  TAutocompleteOrganizationUserSchema
>

export interface IControlledAutocompleteOrganizationUserProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteOrganizationUserProps?: Omit<IAutocompleteOrganizationUserProps, 'onChange' | 'value'>
}

export const ControlledAutocompleteOrganizationUser =
  memo<IControlledAutocompleteOrganizationUserProps>(
    ({ AutocompleteOrganizationUserProps, control, defaultValue, name }) => {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ onChange, value }) => {
            const changeHandler: IAutocompleteOrganizationUserProps['onChange'] = (_, newValue) => {
              onChange(newValue)
            }
            return (
              <AutocompleteOrganizationUser
                {...AutocompleteOrganizationUserProps}
                onChange={changeHandler}
                value={value}
              />
            )
          }}
        />
      )
    }
  )
