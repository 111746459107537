import { ISettingVersion } from '#types/graphqlTypes'
import { ICostStrategyEnum } from '#types/rates'
import { ICostSettingsFormSchema } from '../schema'

interface IGetCostSettingsFormSchemaProps {
  defaultCostPercentile?: ISettingVersion['defaultCostPercentile']
  costStrategyLabel?: ICostStrategyEnum
}

export const getCostSettingsFormSchema = ({
  costStrategyLabel,
  defaultCostPercentile,
}: IGetCostSettingsFormSchemaProps): ICostSettingsFormSchema => {
  return {
    defaultStrategy: costStrategyLabel || ICostStrategyEnum.Customized,
    percentile: defaultCostPercentile || 50,
  }
}
