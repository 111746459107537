import { IContractRfpStates } from '#types/graphqlTypes'

const ALLOWED_TRANSITIONS_BY_STATE: Record<IContractRfpStates, Set<IContractRfpStates>> = {
  [IContractRfpStates.planning]: new Set([
    IContractRfpStates.in_progress,
    IContractRfpStates.submitted,
  ]),
  [IContractRfpStates.in_progress]: new Set([
    IContractRfpStates.planning,
    IContractRfpStates.submitted,
    IContractRfpStates.closed,
  ]),
  [IContractRfpStates.submitted]: new Set([
    IContractRfpStates.planning,
    IContractRfpStates.in_progress,
    IContractRfpStates.closed,
  ]),
  [IContractRfpStates.closed]: new Set([]),
  [IContractRfpStates.archived]: new Set([]),
}

export function canMove(state: IContractRfpStates, newState: IContractRfpStates) {
  const allowedTransitions = ALLOWED_TRANSITIONS_BY_STATE[state]

  return allowedTransitions.has(newState)
}
