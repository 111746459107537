import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import NumberField, { INumberFieldProps } from 'dpl/components/NumberField'

type IBaseControllerProps = ControllerProps<React.ComponentType<INumberFieldProps>>
export type TTransformFunction = (value: string) => number

export interface IControlledNumberFieldProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue' | 'disabled'> {
  NumberFieldProps?: Partial<INumberFieldProps>
  transformFunction?: TTransformFunction
}

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledNumberField
 *   name='amount'
 *   control={control}
 *   NumberFieldProps={{
 *     label: 'Amount',
 *     placeholder: '%',
 *   }}
 * />
 * ```
 */
const ControlledNumberField = memo<IControlledNumberFieldProps>(
  ({ name, control, defaultValue = '', NumberFieldProps = {} }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onBlur, onChange, value }) => {
          const changeHandler: INumberFieldProps['onChange'] = value => {
            onChange(value)
          }
          return (
            <NumberField
              {...NumberFieldProps}
              name={name}
              id={name}
              onChange={changeHandler}
              onBlur={onBlur}
              value={value}
            />
          )
        }}
      />
    )
  }
)

export default ControlledNumberField
