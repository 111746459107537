import React from 'react'
import {
  CONTRACT_LENGTHS_DISPLAY_LABELS,
  IContractLengthMonths,
} from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import { OverflowMenu, ToggleButton } from 'dpl'
import { Box } from 'dpl/core'
import { DownArrowIcon } from 'dpl/icons'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { IContractLaneInformationSchema } from '../../schema'
import { contractDurationFormatter, getContractDurationValues } from '../../utils'
import { CustomMonthRangeModal } from './components/CustomMonthRangeModal'

interface IContractDurationToggleProps {
  contractDuration: IContractLaneInformationSchema['contractDuration']
  onChange: (contractDuration: IContractLaneInformationSchema['contractDuration']) => void
}

export function ContractDurationToggle({
  contractDuration: contractDurationProp,
  onChange,
}: IContractDurationToggleProps) {
  const { createToggleHandler, modalState } = useModalState({
    monthRangeModal: false,
  })

  const closeMonthRangeModal = createToggleHandler('monthRangeModal', false)
  const openMonthRangeModal = createToggleHandler('monthRangeModal', true)

  const { monthRangeModal } = modalState

  const currentContractDurationValue = contractDurationProp
  const formattedCurrentContractDuration = contractDurationFormatter({
    ...currentContractDurationValue,
  })

  const threeMonthsOption = {
    action: () => {
      const threeMonthsContractDuration = getContractDurationValues(
        IContractLengthMonths.threeMonths
      )
      onChange(threeMonthsContractDuration)
    },
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        data-test='overflow-menu-3-months-option'>
        <Box>{CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.threeMonths]}</Box>
      </Box>
    ),
    label: CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.threeMonths],
  }

  const sixMonthsOption = {
    action: () => {
      const sixMonthsContractDuration = getContractDurationValues(IContractLengthMonths.sixMonths)
      onChange(sixMonthsContractDuration)
    },
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        data-test='overflow-menu-3-months-option'>
        <Box>{CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.sixMonths]}</Box>
      </Box>
    ),
    label: CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.sixMonths],
  }

  const twelveMonthsOption = {
    action: () => {
      const twelveMonthsContractDuration = getContractDurationValues(
        IContractLengthMonths.twelveMonths
      )

      onChange(twelveMonthsContractDuration)
    },
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        data-test='overflow-menu-3-months-option'>
        <Box>{CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.twelveMonths]}</Box>
      </Box>
    ),
    label: CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.twelveMonths],
  }

  const fifteenMonthsOption = {
    action: () => {
      const fifteenMonthsContractDuration = getContractDurationValues(
        IContractLengthMonths.fifteenMonths
      )
      onChange(fifteenMonthsContractDuration)
    },
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        data-test='overflow-menu-3-months-option'>
        <Box width='250px'>
          {CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.fifteenMonths]}
        </Box>
      </Box>
    ),
    label: CONTRACT_LENGTHS_DISPLAY_LABELS[IContractLengthMonths.fifteenMonths],
  }

  const customMonthsOption = {
    action: () => {
      openMonthRangeModal()
    },
    itemComponent: (
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        data-test='overflow-menu-custom-months-option'>
        <Box width='250px'>Select Custom Months</Box>
      </Box>
    ),
    label: 'Select Custom Months',
  }

  const options = [
    threeMonthsOption,
    sixMonthsOption,
    twelveMonthsOption,
    fifteenMonthsOption,
    customMonthsOption,
  ]

  const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()
  return (
    <>
      <OverflowMenu
        dataTest='organizations-overflow-menu'
        dropdownId='organizations-overflow-menu'
        anchorEl={anchorEl}
        expanded={expanded}
        onClose={handleClose}
        onSelectMenuItem={handleClose}
        options={options}
        menuListLabel='Duration'
        trigger={
          <ToggleButton
            onClick={handleClick}
            value={expanded}
            selected={false}
            endIcon={DownArrowIcon}
            label={formattedCurrentContractDuration}
          />
        }
      />
      <CustomMonthRangeModal
        key={currentContractDurationValue.contractLengthMonths}
        onSuccess={onChange}
        open={monthRangeModal}
        onClose={closeMonthRangeModal}
        currentContractDuration={currentContractDurationValue}
      />
    </>
  )
}
