import { COST_PERCENTILES_BY_LABEL } from '#routes/spot-rates-control-panel/constants'
import { ICreateSpotRatesSettingVersionInput } from '#types/graphqlTypes'
import { ICostSettingsFormSchema } from '../schema'

export const getUpdateCostSettingsInput = (
  formValues: ICostSettingsFormSchema
): ICreateSpotRatesSettingVersionInput => {
  const { defaultStrategy, percentile } = formValues || {}

  // predefined strategy
  if (defaultStrategy && !percentile) {
    return {
      defaultCostPercentile: COST_PERCENTILES_BY_LABEL[defaultStrategy],
    }
  }

  // custom strategy
  return {
    defaultCostPercentile: percentile,
  }
}
