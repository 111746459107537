import React from 'react'
import { Control, FieldError, DeepMap, Controller } from 'react-hook-form'
import { brandColors, FormHelperText } from 'dpl'
import NumericFormat from 'dpl/components/NumericFormat'
import FORMATS from 'dpl/constants/datetime'
import { Box, Collapse, Grid, Stack, Typography } from 'dpl/core'
import ControlledHiddenInput from 'forms/components/ControlledHiddenInput'
import ControlledRadioGroup from 'forms/components/ControlledRadioGroup'
import moment from 'moment'
import { IVolumeTypes } from '../../../../types'
import {
  DEFAULT_CONTRACT_DURATION,
  IContractLaneInformationSchema,
} from '../../../ContractLaneInformationForm/schema'
import { IVolumeSchema } from '../../../ContractLaneInformationForm/schema/VolumeSchema'
import {
  contractDurationFormatter,
  getTotalLoads,
} from '../../../ContractLaneInformationForm/utils'

export interface IVolumePreferenceFormBodyProps {
  control: Control<IContractLaneInformationSchema>
  dynamicVolumePerMonthErrors: DeepMap<IVolumeSchema['dynamicVolumePerMonth'], FieldError>
  flatVolumePerMonthError?: FieldError
  /**
   * @default 'VolumePreferenceFormBody'
   */
  dataTest?: string
  volume?: IContractLaneInformationSchema['volume']
  contractDuration?: IContractLaneInformationSchema['contractDuration']
  selectVolumeTypeHandler: (volumeType: IVolumeTypes) => void
  trigger: (name?: string | string[] | undefined) => Promise<boolean>
}

export function VolumePreferenceFormBody({
  contractDuration,
  control,
  dataTest,
  dynamicVolumePerMonthErrors,
  flatVolumePerMonthError,
  selectVolumeTypeHandler,
  trigger,
  volume,
}: IVolumePreferenceFormBodyProps) {
  const { dynamicVolumePerMonth = [], flatVolumePerMonth, volumeType } = volume || {}
  const { contractLengthMonths = 0 } = contractDuration || {}

  const contractDurationValue = contractDuration ?? DEFAULT_CONTRACT_DURATION
  const formattedCurrentContractDuration = contractDurationFormatter(contractDurationValue)

  const formatMonthYear = (date: string) => moment(date).format(FORMATS.monthYearShort)

  const { totalDynamicLoads, totalFlatLoads } = getTotalLoads({
    dynamicVolumePerMonth,
    flatVolumePerMonth,
    contractDurationLength: contractLengthMonths,
  })
  const { message: flatVolumePerMonthErrorMessage } = flatVolumePerMonthError || {}

  const getErrorMessage = (index: number) => dynamicVolumePerMonthErrors?.[index]?.volume?.message

  const dynamicVolumePerMonthError = dynamicVolumePerMonthErrors as { message?: string } | undefined

  return (
    <Stack data-test={dataTest} gap={3} component='div'>
      <Typography variant='h4'>Set Volume Preference</Typography>
      <ControlledRadioGroup
        onChange={value => {
          selectVolumeTypeHandler(value as IVolumeTypes)
        }}
        RadioGroupProps={{
          big: true,
          options: [
            {
              label: 'Flat',
              name: IVolumeTypes.flatVolume,
              description: 'Volume will be equal for each selected month',
              value: IVolumeTypes.flatVolume,
              dataTest: 'flat-option',
            },
            {
              label: 'Dynamic',
              name: IVolumeTypes.dynamicVolume,
              description: 'Volume can be custom for each selected month',
              value: IVolumeTypes.dynamicVolume,
              dataTest: 'dynamic-option',
            },
          ],
        }}
        name='volume.volumeType'
        control={control}
      />
      <Box display='flex' flexDirection='row'>
        <Typography variant='subtitle1'>Loads per Month</Typography>
        &nbsp;
        <Typography variant='subtitle1' color={brandColors.coolGray5}>
          {formattedCurrentContractDuration}
        </Typography>
      </Box>
      {volumeType === IVolumeTypes.flatVolume && (
        <Box maxWidth={300}>
          <Controller
            control={control}
            name='volume.flatVolumePerMonth'
            render={({ onChange, value }) => (
              <NumericFormat
                label='Loads per Month'
                error={!!flatVolumePerMonthErrorMessage}
                helperText={flatVolumePerMonthErrorMessage}
                dataTest='flat-volume-per-month-input'
                value={value}
                decimalScale={0}
                allowNegative={false}
                onValueChange={({ floatValue }) => {
                  onChange(floatValue ?? null)
                }}
              />
            )}
          />
          <Typography mt={1} variant='subtitle1' color={brandColors.coolGray5}>
            {`Total: ${totalFlatLoads} loads`}
          </Typography>
        </Box>
      )}
      {volumeType === IVolumeTypes.dynamicVolume && (
        <>
          <Grid direction='row' container columnSpacing={2} rowSpacing={3}>
            {dynamicVolumePerMonth.map(({ date }, index) => (
              <Grid xs={12} md={2} key={date} item>
                <Controller
                  control={control}
                  name={`volume.dynamicVolumePerMonth[${index}].volume`}
                  render={({ onChange, value }) => (
                    <NumericFormat
                      label={formatMonthYear(date)}
                      error={!!getErrorMessage(index)}
                      helperText={getErrorMessage(index)}
                      dataTest={`volume-per-month-input-${index}`}
                      value={value}
                      decimalScale={0}
                      allowNegative={false}
                      onValueChange={({ floatValue }) => {
                        onChange(floatValue ?? null)
                        trigger('volume.dynamicVolumePerMonth')
                      }}
                    />
                  )}
                />
                <ControlledHiddenInput
                  name={`volume.dynamicVolumePerMonth[${index}].date`}
                  control={control}
                />
              </Grid>
            ))}
          </Grid>
          <Collapse in={Boolean(dynamicVolumePerMonthError?.message)}>
            <FormHelperText data-test='dynamic-volume-errors' hasError>
              {dynamicVolumePerMonthError?.message}
            </FormHelperText>
          </Collapse>
          <Typography variant='subtitle1' color={brandColors.coolGray5}>
            {`Total: ${totalDynamicLoads} loads`}
          </Typography>
        </>
      )}
    </Stack>
  )
}
