import React, { useState, ChangeEvent, useCallback } from 'react'
import { minMaxTempFormatter } from '#utils/minMaxTempFormatter'
import { Radio } from 'dpl/components/Radio'
import { ISliderProps, Slider } from 'dpl/components/Slider'
import { Box, Typography } from 'dpl/core'
import { ToggleButtonPopper } from '../../../../components'
import { EQUIPMENT_TYPE_KEY_LABEL, IEquipment53Types } from '../../../../types'
import { DEFAULT_TEMP, MAX_TEMP, MAX_TEMP_LABEL, MIN_TEMP, MIN_TEMP_LABEL } from '../../constants'
import { IContractLaneInformationSchema } from '../../schema'

interface IEquipmentToggleProps {
  equipmentKey: IContractLaneInformationSchema['equipmentKey']
  temperature: IContractLaneInformationSchema['temperature']
  onChange: (args: { equipmentKey: string; minTemp: number | null; maxTemp: number | null }) => void
}

export function EquipmentToggle({
  equipmentKey,
  onChange,
  temperature: currentTemperatureValue,
}: IEquipmentToggleProps) {
  const [isReefer, setIsReefer] = useState(equipmentKey === IEquipment53Types.reefer)

  const currentMaxTemp = currentTemperatureValue?.max ?? DEFAULT_TEMP
  const currentMinTemp = currentTemperatureValue?.min ?? DEFAULT_TEMP
  const formattedCurrentTemperature = minMaxTempFormatter({
    minTemp: currentMinTemp,
    maxTemp: currentMaxTemp,
  })
  const toggleButtonLabel = `${EQUIPMENT_TYPE_KEY_LABEL[equipmentKey]}${equipmentKey === IEquipment53Types.reefer ? ` (${formattedCurrentTemperature})` : ''}`

  const [sliderTemperature, setSliderTemperature] = useState<number[]>([
    currentMinTemp,
    currentMaxTemp,
  ])
  const [sliderMinTemp, sliderMaxTemp] = sliderTemperature
  const formattedSliderTemperature = minMaxTempFormatter({
    minTemp: sliderMinTemp,
    maxTemp: sliderMaxTemp,
  })
  const sliderLabel = `Temperature (${formattedSliderTemperature})`

  const equipmentSelectHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setIsReefer(value === IEquipment53Types.reefer)
  }

  const temperatureChangeHandler: ISliderProps['onChange'] = (_, newValue) => {
    setSliderTemperature(newValue as number[])
  }

  const commitEquipmentValues = useCallback(() => {
    let minTemp = null
    let maxTemp = null
    if (isReefer) {
      minTemp = sliderMinTemp
      maxTemp = sliderMaxTemp
    }
    const equipmentKey = isReefer ? IEquipment53Types.reefer : IEquipment53Types.dryVan
    onChange({ equipmentKey, minTemp, maxTemp })
  }, [isReefer, onChange, sliderMaxTemp, sliderMinTemp])

  return (
    <ToggleButtonPopper label={toggleButtonLabel} onClose={commitEquipmentValues}>
      <Box display='flex' gap={2} flexDirection='column'>
        <Typography variant='caption' color='textSecondary' component='div'>
          Equipment
        </Typography>
        <Radio
          label={EQUIPMENT_TYPE_KEY_LABEL[IEquipment53Types.reefer]}
          value={IEquipment53Types.reefer}
          checked={isReefer}
          onChange={equipmentSelectHandler}
        />
        {isReefer && (
          <Slider
            min={MIN_TEMP}
            minLabel={MIN_TEMP_LABEL}
            max={MAX_TEMP}
            maxLabel={MAX_TEMP_LABEL}
            value={sliderTemperature}
            label={sliderLabel}
            onChange={temperatureChangeHandler}
            valueLabelFormat={value => `${value}°`}
          />
        )}
        <Radio
          label={EQUIPMENT_TYPE_KEY_LABEL[IEquipment53Types.dryVan]}
          value={IEquipment53Types.dryVan}
          checked={!isReefer}
          onChange={equipmentSelectHandler}
        />
      </Box>
    </ToggleButtonPopper>
  )
}
