type TMarginDifferenceValues = {
  monthCarrierRate: number
  volumeWtdAvgCarrierRate: number
  volumeWtdAvgContractRate: number
  volume: number
  isPerLoad: boolean
}

export const getMarginDifferenceValues = ({
  isPerLoad,
  monthCarrierRate,
  volume,
  volumeWtdAvgCarrierRate,
  volumeWtdAvgContractRate,
}: TMarginDifferenceValues) => {
  const marginDifference = volumeWtdAvgContractRate - monthCarrierRate
  const isPositive = marginDifference >= 0
  const marginDifferencePercentage = ((marginDifference * 100) / volumeWtdAvgCarrierRate).toFixed(2)
  if (isPerLoad) {
    return {
      marginDifference,
      isPositive,
      marginDifferencePercentage,
    }
  }

  if (volume === 0) {
    return {
      marginDifference: 0,
      isPositive: true,
      marginDifferencePercentage: '0',
    }
  }

  //   marginDifferenceFixed ensures that the per load value matches multiplied by the volume
  const marginDifferenceFixed = Number(marginDifference.toFixed(2))
  const volumeWtdAvgCarrierRateFixed = Number(volumeWtdAvgCarrierRate.toFixed(2))

  const marginDifferenceWithVolume = marginDifferenceFixed * volume
  const isPositiveWithVolume = marginDifferenceWithVolume >= 0
  const marginDifferencePercentageWithVolume = (
    (marginDifferenceWithVolume * 100) /
    (volumeWtdAvgCarrierRateFixed * volume)
  ).toFixed(2)
  return {
    marginDifference: marginDifferenceWithVolume,
    isPositive: isPositiveWithVolume,
    marginDifferencePercentage: marginDifferencePercentageWithVolume,
  }
}
