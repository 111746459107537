import { IUserPermissions } from 'auth/common/context/PermissionsContext/types'
import { InboxIcon } from 'dpl/icons'
import NotesIcon from 'dpl/icons/build/NotesIcon'
import QuoteIcon from 'dpl/icons/build/QuoteIcon'
import ReportsIcon from 'dpl/icons/build/ReportsIcon'
import RoutingGuideIcon from 'dpl/icons/build/RoutingGuideIcon'
import { INavProps } from 'dpl/templates/AppLayout/Nav'
import { PROTECTED_PATHS } from './paths'

export const DEFAULT_NAV_LISTS: NonNullable<INavProps['navLists']> = []

/**
 * Position is set based on shipment lifecycle.
 */

const CONTRACT_RFP_NAV_LISTS: NonNullable<INavProps['navLists']> = [
  {
    navListItems: [
      {
        title: 'Contract RFPs',
        href: PROTECTED_PATHS.contractRFPs,
        Icon: InboxIcon,
      },
    ],
    position: 3,
  },
]

const ROUTING_GUIDE_NAV_LISTS: NonNullable<INavProps['navLists']> = [
  {
    navListItems: [
      {
        title: 'Routing Guide',
        href: PROTECTED_PATHS.routingGuideLanes,
        Icon: RoutingGuideIcon,
      },
    ],
    position: 4,
  },
]

const RATES_NAV_LISTS: NonNullable<INavProps['navLists']> = [
  {
    navListItems: [
      {
        title: 'Rates',
        href: PROTECTED_PATHS.rates,
        Icon: QuoteIcon,
      },
    ],
    position: 1,
  },
]

const QUOTES_NAV_LISTS: NonNullable<INavProps['navLists']> = [
  {
    navListItems: [
      {
        title: 'Quotes',
        href: PROTECTED_PATHS.quotes,
        Icon: NotesIcon,
      },
    ],
    position: 2,
  },
]

const REPORTS_NAV_LISTS: NonNullable<INavProps['navLists']> = [
  {
    navListItems: [
      {
        title: 'Reports',
        href: PROTECTED_PATHS.reports,
        Icon: ReportsIcon,
      },
    ],
    position: 5,
  },
]

/**
 * list of nav lists that will be displayed based on the user's permissions.
 * keep alphabetically sorted!!
 */
export const NAV_LISTS_BY_PERMISSION: {
  [K in keyof IUserPermissions]?: NonNullable<INavProps['navLists']>
} = {
  'contract_rates_tool.read_app': RATES_NAV_LISTS,
  'contract_rfp.read_app': CONTRACT_RFP_NAV_LISTS,
  'quotes.read_app': QUOTES_NAV_LISTS,
  'rates_tool.read_app': RATES_NAV_LISTS,
  'reporting.read_app': REPORTS_NAV_LISTS,
  'routing_guide.read_app': ROUTING_GUIDE_NAV_LISTS,
}
