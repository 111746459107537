import moment from 'moment'

interface IGetSelectedOptionDate {
  startAt: string
  endAt?: string
}

export const getSelectedOptionDate = (
  dateRange: IGetSelectedOptionDate | undefined
): [Date, Date] | undefined => {
  const { endAt, startAt } = dateRange || {}
  const hasDates = startAt && endAt

  if (!hasDates) {
    return undefined
  }

  const startAtRange = moment(startAt).toISOString()
  const endAtRange = moment(endAt).toISOString()

  return [new Date(startAtRange), new Date(endAtRange)]
}
