import React, { memo, useCallback } from 'react'
import { IDataGridFilterProps } from 'dpl/components/DataGrid/components/DataGridFilters/components/types'
import { MenuAccordion } from '../MenuAccordion'
import { IRangeWithOptionsInputProps, RangeWithOptionsInput } from '../RangeWithOptionsInput'
import { IDataGridFilterRangeWithOptions } from './types'

export interface IDataGridFilterRangeWithOptionsProps
  extends IDataGridFilterProps<IDataGridFilterRangeWithOptions>,
    Pick<
      IRangeWithOptionsInputProps,
      'blankLabel' | 'maxValueLabel' | 'minValueLabel' | 'options'
    > {
  /**
   * @default 'DataGridFilterRangeWithOptions'
   */
  dataTest?: string
  label: string
}

export const DataGridFilterRangeWithOptions = memo<IDataGridFilterRangeWithOptionsProps>(
  ({
    blankLabel,
    dataTest = 'DataGridFilterRangeWithOptions',
    label,
    maxValueLabel,
    minValueLabel,
    name,
    onChange,
    options,
    value: valueProp,
  }) => {
    const rangeChangeHandler: IRangeWithOptionsInputProps['onChange'] = useCallback(
      newValue => {
        onChange({
          value: newValue,
          name,
        })
      },
      [name, onChange]
    )

    return (
      <MenuAccordion
        dataTest={dataTest}
        filterConfig={{
          label,
          options: options || [],
        }}
        filterValue={valueProp}>
        <RangeWithOptionsInput
          onChange={rangeChangeHandler}
          options={options}
          value={valueProp}
          maxValueLabel={maxValueLabel}
          minValueLabel={minValueLabel}
          blankLabel={blankLabel}
        />
      </MenuAccordion>
    )
  }
)
