import React from 'react'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import classNames from 'classnames'
import { makeStyles } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import { useFlagsContext } from 'flags'

const useWinPercentageEstimateStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: '0 0 4px 4px',
  },
  highChance: {
    backgroundColor: brandColors.green0,
    color: brandColors.green3,
  },
  mediumChance: {
    backgroundColor: brandColors.gold0,
    color: brandColors.warning1,
  },
  lowChance: {
    backgroundColor: brandColors.warning0,
    color: brandColors.warning1,
  },
}))

const HIGH_CHANCE_THRESHOLD = 0.1
const MEDIUM_CHANCE_THRESHOLD = 0.15

interface IWinPercentageEstimateProps {
  marginPct: number
}

export function WinPercentageEstimate({ marginPct }: IWinPercentageEstimateProps) {
  const classes = useWinPercentageEstimateStyles()
  const { userPermissions } = usePermissionsContext()
  const hasDemoEnhancementsPermission = userPermissions['demo.read_rates_tool_enhancements']
  const { isFlagEnabled } = useFlagsContext()
  const demoEnhancementsEnabled = isFlagEnabled('rates_demo_enhancements')

  if (!(hasDemoEnhancementsPermission && demoEnhancementsEnabled)) {
    return null
  }

  // Returns an approximate percentage chance of winning
  // the freight based on the margin percentage. These
  // values are determined by the product team.
  const getWinPercentage = (marginPct: number): number => {
    if (marginPct <= HIGH_CHANCE_THRESHOLD) {
      return 50
    }
    if (marginPct <= MEDIUM_CHANCE_THRESHOLD) {
      return 25
    }

    return 5
  }
  const winPct = getWinPercentage(marginPct)

  const getColorClass = (marginPct: number): string => {
    if (marginPct <= HIGH_CHANCE_THRESHOLD) {
      return classes.highChance
    }
    if (marginPct <= MEDIUM_CHANCE_THRESHOLD) {
      return classes.mediumChance
    }

    return classes.lowChance
  }
  const colorClass = getColorClass(marginPct)

  return (
    <div data-test='win-percentage-estimate' className={classNames(classes.container, colorClass)}>
      You have a <strong>{winPct}%</strong> chance of winning this freight.
    </div>
  )
}
