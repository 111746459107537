import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useAnalytics } from '#hooks/useAnalytics'
import { IError } from '#types/graphqlTypes'
import { ApolloErrorMessages, useToastContext } from 'dpl'
import SidePanel from 'dpl/components/SidePanel'
import { useCustomerQuery } from '../../components/CustomersPageContent/graphql/CustomerQuery'
import { CustomerForm } from './CustomerForm'
import { useAddCustomerMutation } from './graphql/AddCustomer'
import { useEditCustomerMutation } from './graphql/EditCustomer'
import { useCustomerForm } from './hooks'

export interface ICustomerDrawerProps {
  open: boolean
  onClose: () => void
  /**
   * @default 'CustomerDrawer'
   */
  dataTest?: string
  isEditing: boolean
}

export function CustomerDrawer({
  dataTest = 'CustomerDrawer',
  isEditing,
  onClose,
  open,
}: ICustomerDrawerProps) {
  const { trackEvent } = useAnalytics()

  const { id: organizationShipperId = '' } = useParams()

  const methods = useCustomerForm({
    defaultValues: {
      name: '',
      externalId: '',
    },
  })
  const { handleSubmit, setValue } = methods || {}

  const { loading: loadingCustomer } = useCustomerQuery({
    variables: { id: organizationShipperId },
    skip: !isEditing,
    onCompleted: data => {
      /* When in editing mode, pre-populate form values to existing customer's data */
      if (isEditing) {
        const { organizationShipper } = data || {}
        const { externalId, name } = organizationShipper || {}
        if (externalId && name) {
          setValue('externalId', externalId)
          setValue('name', name)
        }
      }
    },
  })

  const { openToast } = useToastContext()

  const [apiErrors, setApiErrors] = useState<IError[]>([])
  const [addCustomer, { error: apolloAddCustomerErrors, loading: loadingAddCustomer }] =
    useAddCustomerMutation()
  const [editCustomer, { error: apolloEditCusomerErrors, loading: loadingEditCustomer }] =
    useEditCustomerMutation()

  const addHandler = handleSubmit(async ({ externalId, name }) => {
    setApiErrors([])
    try {
      const { data } = await addCustomer({
        variables: {
          input: {
            name,
            externalId,
          },
        },
        refetchQueries: ({ data }) => {
          const { createOrganizationShipper } = data || {}
          const { organizationShipper } = createOrganizationShipper || {}
          const { id } = organizationShipper || {}
          if (id) {
            return ['SearchCustomers']
          }
          return []
        },
      })

      const { createOrganizationShipper } = data || {}
      const { errors = [], organizationShipper } = createOrganizationShipper || {}
      const { id } = organizationShipper || {}
      if (id) {
        openToast({
          toastMessage: 'Customer successfully added',
        })
        onClose()

        trackEvent('Customer', 'CREATE_CUSTOMER_SUCCESS', {
          orgShipperId: id,
        })
        return
      }

      if (errors.length) {
        setApiErrors(errors)

        trackEvent('Customer', 'CREATE_CUSTOMER_ERROR', {
          mutationErrors: errors,
        })
      }
    } catch (e) {
      setApiErrors([e.message])
    }
  })

  const editHandler = handleSubmit(async ({ externalId, name }) => {
    setApiErrors([])
    try {
      const { data } = await editCustomer({
        variables: {
          input: {
            name,
            externalId,
            id: organizationShipperId,
          },
        },
        refetchQueries: ({ data }) => {
          const { updateOrganizationShipper } = data || {}
          const { organizationShipper } = updateOrganizationShipper || {}
          const { id } = organizationShipper || {}
          if (id) {
            return ['SearchCustomers']
          }
          return []
        },
      })

      const { updateOrganizationShipper } = data || {}
      const { errors = [], organizationShipper } = updateOrganizationShipper || {}
      const { id } = organizationShipper || {}
      if (id) {
        openToast({
          toastMessage: 'Customer successfully edited',
        })

        onClose()

        trackEvent('Customer', 'UPDATE_CUSTOMER_SUCCESS', {
          orgShipperId: id,
        })
        return
      }

      if (errors.length) {
        setApiErrors(errors)

        trackEvent('Customer', 'UPDATE_CUSTOMER_ERROR', {
          mutationErrors: errors,
          orgShipperId: organizationShipperId,
        })
      }
    } catch (e) {
      setApiErrors([e.message])
    }
  })

  const disableForm = loadingCustomer || loadingEditCustomer || loadingAddCustomer

  const closeHandler = () => {
    if (apiErrors.length) {
      setApiErrors([])
    }
    onClose()
  }

  return (
    <SidePanel
      actions={[
        {
          label: 'Cancel',
          variant: 'text',
          onClick: closeHandler,
          disabled: disableForm,
        },
        {
          label: isEditing ? 'Edit Customer' : 'Add Customer',
          onClick: isEditing ? editHandler : addHandler,
          disabled: disableForm,
        },
      ]}
      open={open}
      onClose={closeHandler}
      anchor='right'
      title={isEditing ? 'Edit Customer' : 'Add Customer'}
      dataTest={dataTest}>
      <FormProvider {...methods}>
        <ApolloErrorMessages
          apolloErrors={apolloAddCustomerErrors || apolloEditCusomerErrors}
          mutationErrors={apiErrors}
        />
        <CustomerForm disableForm={disableForm} />
      </FormProvider>
    </SidePanel>
  )
}
