import { IFetchRatesInput, Maybe } from '#types/graphqlTypes'
import { FORMATS } from 'dpl/constants'
import moment from 'moment'

export interface IManualAppointment {
  dropoffZip?: Maybe<string>
  manualDropoffTime?: Maybe<string>
  manualPickupTime?: Maybe<string>
  pickupZip?: Maybe<string>
}

export const formatDropTrailer = (isPuDropTrailer: boolean, isDelDropTrailer: boolean) => {
  if (isPuDropTrailer && isDelDropTrailer) return 'Pickup & Delivery'
  if (isPuDropTrailer) return 'Pickup'
  if (isDelDropTrailer) return 'Delivery'
  return ''
}

export const getManuallyEnteredAppointment = (
  formData: IFetchRatesInput | null
): IManualAppointment => {
  if (!formData) {
    return {}
  }

  const { stops = [] } = formData || {}

  const [pickup] = stops
  const dropoff = stops[stops.length - 1]
  const {
    date: pickupDate,
    postalCode: pickupZip,
    startTime: pickupTime,
    startTimeZone: pickupTimeZone,
  } = pickup || {}
  const {
    date: dropoffDate,
    postalCode: dropoffZip,
    startTime: dropoffTime,
    startTimeZone: dropoffTimeZone,
  } = dropoff || {}

  const isPickupValid = pickupDate && pickupTime && pickupTimeZone
  const isDropoffValid = dropoffDate && dropoffTime && dropoffTimeZone

  const manualPickupTime = isPickupValid
    ? moment
        .tz(`${pickupDate} ${pickupTime}`, pickupTimeZone)
        .format(FORMATS.monthDayYearTimeFormat12hUppercase)
    : null

  const manualDropoffTime = isDropoffValid
    ? moment
        .tz(`${dropoffDate} ${dropoffTime}`, dropoffTimeZone)
        .format(FORMATS.monthDayYearTimeFormat12hUppercase)
    : null

  return {
    dropoffZip,
    manualDropoffTime,
    manualPickupTime,
    pickupZip,
  }
}
