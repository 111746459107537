import React, { useState } from 'react'
import { DatePicker, FormModal, INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import { Box } from 'dpl/core'
import moment from 'moment'

export type TField = 'submittedAt' | 'dueAt'

export interface IUpdateDateModalProps {
  field: TField
  title: string
  currentDate: string
  inputLabel: string
  onClose: () => void
  onConfirm: (field: TField, newValue: string) => Promise<Error | null>
}

export function UpdateDateModal({
  currentDate,
  field,
  inputLabel,
  onClose,
  onConfirm,
  title,
}: IUpdateDateModalProps) {
  const [error, setError] = useState<string>('')
  const [newDate, setNewDate] = useState(moment(currentDate))

  return (
    <FormModal
      open
      onClose={onClose}
      maxWidth='md'
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          ButtonProps: {
            variant: 'text',
          },
        },
        {
          label: 'Save',
          async action() {
            setError('')
            const error = await onConfirm(field, newDate.toISOString())
            if (error) setError(error.message)
          },
        },
      ]}
      title={title}>
      {error && (
        <Box mb={3}>
          <InlineMessage message={error} type={INLINE_MESSAGE_VARIANTS.ALERT} />
        </Box>
      )}
      <DatePicker
        value={newDate}
        label={`${inputLabel} *`}
        onChange={newVal => newVal && setNewDate(newVal)}
        minDate={moment()}
      />
    </FormModal>
  )
}
