import React, { memo } from 'react'
import { Control, UseFormMethods } from 'react-hook-form'
import { ControlledAutocompleteOrganizationUser } from '../../../../../../../components/ControlledAutocompleteOrganizationUser'
import { IOrganizationUserStatus } from '../../../../../../../types/graphqlTypes'
import { TRoutingGuideLaneSchema } from '../../schema'
import { useCurrentOrgUserQuery } from './graphql/CurrentOrgUser'

export interface IControlledLaneManagerFieldProps {
  control: Control<TRoutingGuideLaneSchema>
  setValue: UseFormMethods<TRoutingGuideLaneSchema>['setValue']
  errorMessage?: string
  /**
   * @default 'ControlledLaneManagerField'
   */
  dataTest?: string
}

export const ControlledLaneManagerField = memo<IControlledLaneManagerFieldProps>(
  ({ control, dataTest = 'ControlledLaneManagerField', errorMessage, setValue }) => {
    const { loading } = useCurrentOrgUserQuery({
      onCompleted: ({ currentSession }) => {
        const { organizationUser } = currentSession || {}
        setValue('laneManager', organizationUser)
      },
    })

    return (
      <ControlledAutocompleteOrganizationUser
        control={control}
        name='laneManager'
        AutocompleteOrganizationUserProps={{
          label: 'Lane Manager',
          placeholder: 'Lane Manager',
          organizationUserStatuses: [IOrganizationUserStatus.active],
          organizationUserPermissions: ['routing_guide.create_lane'],
          error: !!errorMessage,
          helperText: errorMessage,
          disabled: loading,
          dataTest,
        }}
      />
    )
  }
)
