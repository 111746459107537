import React from 'react'
import { brandColors, OverflowMenu, IconButton } from 'dpl'
import { Box, makeStyles, Typography } from 'dpl/core'
import { ArchiveIcon, AttachmentIcon, DownloadIcon, EditIcon, LinkIcon, MoreIcon } from 'dpl/icons'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { File, IFileProps } from './File'
import { ILinkProps, WebLink } from './WebLink'

export type TTypes = 'file' | 'link'

export type TItemProps = (
  | {
      type: 'file' | 'rfpFile' | 'loadingFile'
      data: IFileProps
    }
  | {
      type: 'link'
      data: ILinkProps
    }
) & {
  onEdit?: () => void
  onDelete?: () => void
  onDownload?: () => void
}

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
    border: `1px solid ${brandColors.coolGray3}`,
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  icon: {
    width: 55,
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: brandColors.coolGray2,
  },
}))

export function Item({ data, onDelete, onDownload, onEdit, type }: TItemProps) {
  const classes = useStyles()
  const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()

  // Get file name for data-test attribute
  const getItemTestId = () => {
    if (type === 'link' && 'name' in data) {
      return `file-item-${data.name.replace(/\s+/g, '-').toLowerCase()}`
    }
    if ((type === 'file' || type === 'rfpFile' || type === 'loadingFile') && 'name' in data) {
      return `file-item-${data.name.replace(/\s+/g, '-').toLowerCase()}`
    }
    return `file-item-${type}`
  }

  const options = [
    ...(type === 'file'
      ? [
          {
            itemComponent: (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                data-test='overflow-menu-archive-option'>
                <DownloadIcon size='large' />
                <Typography ml={1}>Download</Typography>
              </Box>
            ),
            label: 'Download',
            action: () => onDownload && onDownload(),
          },
          {
            label: 'Delete',
            action: () => onDelete && onDelete(),
            itemComponent: (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                data-test='overflow-menu-archive-option'>
                <ArchiveIcon size='large' color='error1' />
                <Typography ml={1} color={brandColors.error1}>
                  Delete
                </Typography>
              </Box>
            ),
          },
        ]
      : []),
    ...(type === 'rfpFile'
      ? [
          {
            itemComponent: (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                data-test='overflow-menu-archive-option'>
                <DownloadIcon size='large' />
                <Typography ml={1}>Download</Typography>
              </Box>
            ),
            label: 'Download',
            action: () => onDownload && onDownload(),
          },
        ]
      : []),
    ...(type === 'link'
      ? [
          {
            itemComponent: (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                data-test='overflow-menu-archive-option'>
                <EditIcon size='large' />
                <Typography ml={1}>Edit Link</Typography>
              </Box>
            ),
            label: 'Edit',
            action: () => onEdit && onEdit(),
          },
          {
            label: 'Delete',
            action: () => onDelete && onDelete(),
            itemComponent: (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                data-test='overflow-menu-archive-option'>
                <ArchiveIcon size='large' color='error1' />
                <Typography ml={1} color={brandColors.error1}>
                  Delete
                </Typography>
              </Box>
            ),
          },
        ]
      : []),
  ]

  return (
    <div className={classes.root} data-test={getItemTestId()}>
      <div className={classes.icon}>
        {type === 'file' && (
          <AttachmentIcon data-test='attachment-icon' color='coolGray5' size='xlarge' />
        )}
        {type === 'rfpFile' && (
          <AttachmentIcon data-test='attachment-icon' color='coolGray5' size='xlarge' />
        )}
        {type === 'link' && <LinkIcon data-test='link-icon' color='coolGray5' size='xlarge' />}
        {type === 'loadingFile' && (
          <AttachmentIcon data-test='attachment-icon' color='coolGray5' size='xlarge' />
        )}
      </div>
      <Box flex={1} data-test='file-content'>
        {type === 'file' && <File {...data} />}
        {type === 'rfpFile' && <File {...data} />}
        {type === 'link' && <WebLink {...data} />}
        {type === 'loadingFile' && <File {...data} />}
      </Box>
      {type !== 'loadingFile' && (
        <OverflowMenu
          dropdownId='contract-rfp-overflow-menu'
          anchorEl={anchorEl}
          expanded={expanded}
          onClose={handleClose}
          onSelectMenuItem={handleClose}
          options={options}
          trigger={
            <IconButton
              label='overflow-trigger'
              Icon={MoreIcon}
              onClick={handleClick}
              size='xlarge'
              color='secondary'
            />
          }
        />
      )}
    </div>
  )
}
