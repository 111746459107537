import React, { Key } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { ISelectFieldProps, SelectField } from 'dpl/components/SelectField'

type IBaseControllerProps<TOptionValue extends Key> = ControllerProps<
  React.ComponentType<ISelectFieldProps<TOptionValue>>
>

export interface IControlledSelectFieldProps<TOptionValue extends Key = string>
  extends Pick<IBaseControllerProps<TOptionValue>, 'control' | 'name' | 'defaultValue'> {
  SelectProps: Omit<ISelectFieldProps<TOptionValue>, 'onChange' | 'onBlur' | 'value'>
}

export function ControlledSelectField<TOptionValue extends Key = string>({
  SelectProps,
  control,
  defaultValue,
  name,
}: IControlledSelectFieldProps<TOptionValue>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, value: val }) => {
        const changeHandler: ISelectFieldProps<TOptionValue>['onChange'] = event => {
          onChange(event.target.value)
        }

        return <SelectField {...SelectProps} value={val} onChange={changeHandler} onBlur={onBlur} />
      }}
    />
  )
}
