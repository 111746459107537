import React, { memo, useCallback, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { ApolloErrorMessages } from 'dpl/components/ApolloErrorMessages'
import { Button, FormHelperText, makeStyles, Stack, Typography } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import { boxShadowLevels } from 'dpl/theme/shadows'
import ControlledTextField from 'forms/components/ControlledTextField'
import { useAnalytics } from '../../hooks/useAnalytics'
import { IError } from '../../types/graphqlTypes'
import { useCreateCustomerMutation } from './graphql/CreateCustomerMutation'
import { INewCustomerFragment } from './graphql/NewCustomerFragment'
import { useEmbeddedCreateCustomerForm } from './hooks'
import { TEmbeddedCreateCustomerFormSchema } from './schema'

const EXTERNAL_ID_HELPER_TEXT =
  'This is how your customer is identified within your organization. The identifier must be unique and may include only letters, numbers, underscores, and dashes.'

const useEmbeddedCreateCustomerFormStyles = makeStyles(theme => ({
  root: {
    background: brandColors.coolGray0,
    border: `1px solid ${brandColors.coolGray3}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: boxShadowLevels.low,
    padding: theme.spacing(2),
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: brandColors.white,
    },
  },
}))

export interface IEmbeddedCreateCustomerFormProps {
  /**
   * @default 'EmbeddedCreateCustomerForm'
   */
  dataTest?: string
  /**
   * Callback to be called when the customer is successfully created
   */
  onSuccess: (newCustomer: INewCustomerFragment) => void
  /**
   * Callback to be called when the user clicks cancel on the form
   */
  onCancel: () => void
}

export const EmbeddedCreateCustomerForm = memo<IEmbeddedCreateCustomerFormProps>(
  ({ dataTest = 'EmbeddedCreateCustomerForm', onCancel, onSuccess }) => {
    const classes = useEmbeddedCreateCustomerFormStyles()
    const { trackEvent } = useAnalytics()

    const [mutationErrors, setMutationErrors] = useState<IError[]>([])
    const [createCustomerMutation, { error: apolloErrors, loading }] = useCreateCustomerMutation()

    const { control, errors, handleSubmit } = useEmbeddedCreateCustomerForm({
      defaultValues: {
        name: '',
        externalId: '',
      },
    })
    const { externalId: externalIdError, name: nameError } = errors || {}
    const { message: nameErrorMessage } = nameError || {}
    const { message: externalIdErrorMessage } = externalIdError || {}

    const createCustomer = useCallback<SubmitHandler<TEmbeddedCreateCustomerFormSchema>>(
      async formValues => {
        setMutationErrors([])

        const { externalId, name } = formValues || {}
        const { data } = await createCustomerMutation({
          variables: {
            input: {
              name,
              externalId,
            },
          },
        })
        const { createOrganizationShipper } = data || {}
        const { organizationShipper, errors = [] } = createOrganizationShipper || {}
        const { id: organizationShipperId } = organizationShipper || {}

        if (errors.length) {
          trackEvent('Embedded Forms', 'CREATE_CUSTOMER_ERROR', {
            formValues,
            mutationErrors: errors,
          })
          setMutationErrors(errors)
          return
        }

        if (organizationShipperId) {
          trackEvent('Embedded Forms', 'CREATE_CUSTOMER_SUCCESS', {
            formValues,
            newOrganizationShipper: organizationShipper,
          })

          if (organizationShipper && onSuccess) {
            onSuccess(organizationShipper)
          }
        }
      },
      [createCustomerMutation, onSuccess, trackEvent]
    )

    const submitHandler = useCallback(() => {
      handleSubmit(createCustomer)()
    }, [createCustomer, handleSubmit])

    return (
      <div data-test={dataTest} className={classes.root}>
        <Typography variant='subtitle2'>Add New Customer</Typography>
        <ApolloErrorMessages apolloErrors={apolloErrors} mutationErrors={mutationErrors} />
        <Stack gap={1.5} my={1.5}>
          <ControlledTextField
            control={control}
            name='name'
            TextFieldProps={{
              size: 'small',
              label: 'Name',
              required: true,
              dataTest: `${dataTest}-name`,
              disabled: loading,
              error: !!nameErrorMessage,
              helperText: nameErrorMessage,
              className: classes.input,
            }}
          />
          <div>
            <ControlledTextField
              control={control}
              name='externalId'
              TextFieldProps={{
                size: 'small',
                label: 'External ID',
                required: true,
                dataTest: `${dataTest}-externalId`,
                disabled: loading,
                error: !!externalIdErrorMessage,
                helperText: externalIdErrorMessage,
                className: classes.input,
              }}
            />
            <FormHelperText>{EXTERNAL_ID_HELPER_TEXT}</FormHelperText>
          </div>
        </Stack>
        <Stack gap={1} direction='row' justifyContent='flex-end'>
          <Button
            size='small'
            variant='text'
            data-test={`${dataTest}-cancel`}
            onClick={onCancel}
            disabled={loading}>
            Cancel
          </Button>
          <Button
            size='small'
            data-test={`${dataTest}-submit`}
            onClick={submitHandler}
            disabled={loading}>
            Add Customer
          </Button>
        </Stack>
      </div>
    )
  }
)
