import * as GeoJSON from 'geojson'
import { IMapPosition, POSITION_TYPE } from '../types'
import { getGeoJSONFeaturePosition } from './getGeoJSONFeaturePosition'

export function getGeoJSONFeaturePositionCollection<P extends IMapPosition>(
  positions: P[]
): GeoJSON.FeatureCollection {
  return {
    type: 'FeatureCollection',
    /**
     * @note Positions are returned from API in reverse chronological order so we reverse that here
     */
    features: positions.map((position, index) => {
      const { type = POSITION_TYPE.PING, ...rest } = position || {}
      return getGeoJSONFeaturePosition({
        index,
        type,
        ...rest,
      })
    }),
  }
}
