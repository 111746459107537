import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IApiErrorResponse, useRequest } from 'network'
import { useAuthContext } from './useAuthContext'

const SEARCH_PARAM_KEYS = {
  TOKEN: 'token',
  TOKEN_TYPE: 'stytch_token_type',
}

/**
 * @see https://stytch.com/docs/guides/dashboard/redirect-urls#structure
 */
export type TAuthenticationTokenType =
  | 'multi_tenant_magic_links'
  | 'multi_tenant_passwords'
  | 'sso'
  | 'discovery'
  | 'discovery_oauth'
  | 'oauth'

export interface IUseAuthenticationTokenResult {
  loading: boolean
}
export interface IUseAuthenticationTokenProps {
  /**
   * Flag to indicate if the app is ready to authenticate.
   * When set to true, the hook will attempt to authenticate the token.
   * @default false
   */
  readyToAuthenticate: boolean
  /**
   * The URL to redirect to when the magic link token is invalid.
   */
  magicLinkInvalidRedirectTo: string
  /**
   * Callback to execute when the discovery token is successfully authenticated.
   */
  onDiscoveryTokenSuccess?: () => void
  /**
   * Callback to execute when the discovery token authentication fails.
   */
  onDiscoveryTokenError?: (error?: IApiErrorResponse) => void
  /**
   * Callback to execute when the multi-tenant token is successfully authenticated.
   */
  onMultiTenantTokenSuccess?: () => void
  /**
   * Callback to execute when the multi-tenant token authentication fails.
   */
  onMultiTenantTokenError?: (error?: IApiErrorResponse) => void
  /**
   * Callback to execute when SSO token authentication succeeds.
   */
  onSsoTokenSuccess?: () => void
  /**
   * Callback to execute when SSO token authentication fails.
   */
  onSsoTokenError?: (error?: IApiErrorResponse) => void
}

export const useAuthenticationToken = ({
  magicLinkInvalidRedirectTo,
  onDiscoveryTokenError,
  onDiscoveryTokenSuccess,
  onMultiTenantTokenError,
  onMultiTenantTokenSuccess,
  onSsoTokenError,
  onSsoTokenSuccess,
  readyToAuthenticate = false,
}: IUseAuthenticationTokenProps): IUseAuthenticationTokenResult => {
  const { authClient, discoveryRedirectTo, signInRedirectTo } = useAuthContext()

  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get(SEARCH_PARAM_KEYS.TOKEN)
  const tokenType = searchParams.get(
    SEARCH_PARAM_KEYS.TOKEN_TYPE
  ) as TAuthenticationTokenType | null

  const [authenticateDiscoveryToken] = useRequest({
    requestFn: authClient.discovery.authenticateToken,
  })
  const [authenticateMagicLinkToken] = useRequest({
    requestFn: authClient.sessions.createWithMagicLinkToken,
  })
  const [authenticateSsoToken] = useRequest({
    requestFn: authClient.sessions.createWithSsoToken,
  })

  const authenticateToken = useCallback(
    async (tokenType: TAuthenticationTokenType, token: string) => {
      setLoading(true)
      switch (tokenType) {
        case 'discovery': {
          const { data, error } = await authenticateDiscoveryToken({ token })
          if (data) {
            onDiscoveryTokenSuccess?.()
            navigate(discoveryRedirectTo, {
              state: data,
            })
          } else {
            onDiscoveryTokenError?.(error)
            navigate(magicLinkInvalidRedirectTo)
          }

          break
        }

        case 'discovery_oauth':
          // @todo
          break
        case 'multi_tenant_magic_links': {
          const { data, error } = await authenticateMagicLinkToken({
            token,
          })

          if (data) {
            onMultiTenantTokenSuccess?.()
            await authClient.sessions.authenticate()
            navigate(signInRedirectTo)
          } else {
            onMultiTenantTokenError?.(error)
            navigate(magicLinkInvalidRedirectTo)
          }

          break
        }
        case 'multi_tenant_passwords':
          // @todo
          break
        case 'oauth':
          // @todo
          break
        case 'sso': {
          const { data, error } = await authenticateSsoToken({
            token,
          })

          if (data) {
            onSsoTokenSuccess?.()
            await authClient.sessions.authenticate()
            navigate(signInRedirectTo)
          } else {
            onSsoTokenError?.(error)
            navigate(magicLinkInvalidRedirectTo)
          }

          break
        }
        default:
      }
      setLoading(false)
    },
    [
      authClient.sessions,
      authenticateDiscoveryToken,
      authenticateMagicLinkToken,
      authenticateSsoToken,
      discoveryRedirectTo,
      magicLinkInvalidRedirectTo,
      navigate,
      onDiscoveryTokenError,
      onDiscoveryTokenSuccess,
      onMultiTenantTokenError,
      onMultiTenantTokenSuccess,
      onSsoTokenError,
      onSsoTokenSuccess,
      signInRedirectTo,
    ]
  )

  useEffect(() => {
    if (readyToAuthenticate && tokenType && token) {
      authenticateToken(tokenType, token)
    }
  }, [authenticateToken, token, tokenType, readyToAuthenticate])

  return {
    loading,
  }
}
