import React from 'react'
import { getIsImaBotUser } from '#utils/getIsImaBotUser'
import { brandColors, DataGridAdvancedTextCell, DataGridAvatarCell, LogoIcon } from 'dpl'
import FORMATS from 'dpl/constants/datetime'
import { Typography } from 'dpl/core'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { ICustomersDataGrid } from '../types'

const DEACTIVATED_CUSTOMER = 'Deactivated'
const ACTIVE_CUSTOMER = 'Active'

export const CUSTOMERS_COLUMNS: ICustomersDataGrid['columns'] = [
  {
    field: 'displayId',
    headerName: 'ID',
    minWidth: 150,
    renderCell: ({ row }) => {
      const { deprecatedAt } = row
      const fontColor = deprecatedAt ? brandColors.coolGray5 : brandColors.black

      return <Typography color={fontColor}>{row.displayId}</Typography>
    },
  },
  {
    field: 'nameExternalId',
    headerName: 'Name/External ID',
    minWidth: 200,
    renderCell: ({ row }) => {
      const { name } = row
      const orgName = capitalize(name)
      const { deprecatedAt } = row
      const fontColor = deprecatedAt ? brandColors.coolGray5 : brandColors.black

      return (
        <DataGridAdvancedTextCell bottomCaption={row.externalId}>
          <Typography color={fontColor}>{orgName}</Typography>
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 300,
    renderCell: ({ row }) => {
      const { deprecatedAt = '' } = row
      const fontColor = deprecatedAt ? brandColors.coolGray5 : brandColors.black
      const status = deprecatedAt ? DEACTIVATED_CUSTOMER : ACTIVE_CUSTOMER
      const date = deprecatedAt ? `On ${moment(deprecatedAt).format(FORMATS.readableFormat)}` : ''
      return (
        <DataGridAdvancedTextCell bottomCaption={date}>
          <Typography color={fontColor}>{status}</Typography>
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    minWidth: 150,
    renderCell: ({ row }) => {
      const { createdByOrganizationUser } = row || {}
      const { organization, user } = createdByOrganizationUser || {}
      const { firstName = '', lastName = '' } = user || {}
      const { name } = organization || {}
      const isImaBotUser = getIsImaBotUser({ name, firstName, lastName })

      if (isImaBotUser) {
        return (
          <DataGridAvatarCell
            AvatarProps={{
              hideTooltip: true,
              Icon: <LogoIcon size='xsmall' />,
            }}
          />
        )
      }
      return (
        <DataGridAvatarCell
          AvatarProps={{
            name: `${firstName} ${lastName}`,
          }}
        />
      )
    },
  },
]
