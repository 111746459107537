import React from 'react'
import { ConfirmationModal, IModalAction, Spinner } from 'dpl'
import { Typography } from 'dpl/core'

export interface IDeletePermissionCategoryModalProps {
  /**
   * If company is assigned to a routing guide
   */
  isAssigned?: boolean
  isLoading?: boolean
  onClose: () => void
  onConfirm: () => void
  open: boolean
}

export function DeactivateCustomerModal({
  isAssigned,
  isLoading,
  onClose,
  onConfirm,
  open,
}: IDeletePermissionCategoryModalProps) {
  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      action: onClose,
      ButtonProps: {
        variant: 'text',
      },
    },
    {
      label: 'Deactivate',
      action: onConfirm,
      dataTest: 'confirm-button',
      ButtonProps: {
        variant: 'contained',
      },
    },
  ]

  const bodyText = isAssigned
    ? 'This customer is currently assigned to a routing guide. If deactivated, the customer will no longer be available for use in the platform.'
    : 'This customer will no longer be available for use in the platform.'

  if (isLoading) {
    return <Spinner />
  }

  return (
    <ConfirmationModal
      actions={actions}
      open={open}
      onClose={onClose}
      title='Deactivate this customer?'
      includeCloseIconButton={false}>
      <Typography>{bodyText}</Typography>
    </ConfirmationModal>
  )
}
