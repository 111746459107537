import React, { memo, useCallback } from 'react'
import { IDataGridFilterProps } from 'dpl/components/DataGrid'
import {
  AutocompleteOrganizationUser,
  IAutocompleteOrganizationUserProps,
} from '../AutocompleteOrganizationUser'
import { IOrganizationUserResultFragment } from '../AutocompleteOrganizationUser/graphql/OrganizationUserResultFragment'
import { IDataGridFilterOrganizationUser } from './types'

export interface IDataGridFilterOrganizationUserProps
  extends IDataGridFilterProps<IDataGridFilterOrganizationUser | null> {
  /**
   * @default 'DataGridFilterOrganizationUser'
   */
  dataTest?: string
  /**
   * @default {}
   */
  AutocompleteOrganizationUserProps?: Omit<
    IAutocompleteOrganizationUserProps,
    'dataTest' | 'value' | 'onChange' | 'size'
  >
}

export const DataGridFilterOrganizationUser = memo<IDataGridFilterOrganizationUserProps>(
  ({
    dataTest = 'DataGridFilterOrganizationUser',
    name,
    onChange,
    value: valueProp,
    variant,
    AutocompleteOrganizationUserProps = {},
  }) => {
    const changeHandler = useCallback<NonNullable<IAutocompleteOrganizationUserProps['onChange']>>(
      (_, newValue) => {
        const { id, status, user } = newValue || {}
        const { firstName, lastName } = user || {}
        const value = newValue
          ? {
              user: {
                firstName,
                lastName,
              },
              status,
              id,
            }
          : null

        onChange({
          name,
          value,
        })
      },
      [name, onChange]
    )

    return (
      <div style={{ minWidth: '250px' }}>
        <AutocompleteOrganizationUser
          dataTest={dataTest}
          value={(valueProp as IOrganizationUserResultFragment) || null}
          onChange={changeHandler}
          size={variant === 'menu' ? 'medium' : 'small'}
          {...AutocompleteOrganizationUserProps}
        />
      </div>
    )
  }
)
