import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  IRatesEstimatedStrategyAccordionProps,
  RatesEstimatedStrategyAccordion,
} from '#components/RatesEstimatedStrategyAccordion'
import { currencyFormatter } from '#utils/currencyFormatter'
import { Typography } from 'dpl/core'
import { IEstimate, IRateStrategyAccordionStateEnum } from '../../types/rates'
import { IRule } from '../RatesEstimatedStrategyAccordion/types'
import { getInitialState } from '../RatesEstimatedStrategyAccordion/utils/getInitialState'
import { getIsCurrentValueDefaultSetting } from '../RatesEstimatedStrategyAccordion/utils/getIsCurrentValueDefaultSetting'
import { MarginPercentageDescription } from './components/MarginPercentageDescription'
import { IMarginPremiumInputsProps, MarginPremiumInputs } from './components/MarginPremiumInputs'
import { getAppliedAdjustmentRuleLineItems } from './utils/getAppliedAdjustmentRuleLineItems'
import { getIsMarginPremiumLimited } from './utils/getIsMarginPremiumLimited'
import { getNotAppliedAdjustmentRuleLineItems } from './utils/getNotAppliedAdjustmentRuleLineItems'

export interface IRatesEstimatedMarginAccordionProps
  extends Omit<IMarginPremiumInputsProps, 'disabled' | 'percentage' | 'amount'>,
    Pick<IRatesEstimatedStrategyAccordionProps, 'isDoNotBid' | 'isReadOnly'> {
  /**
   * @default 'RatesEstimatedMarginAccordion'
   */
  dataTest?: string
  /**
   * List of base and additive margin rules that match the shipment
   */
  appliedRules: Nullable<IRule[]>
  /**
   * List of base margin rules that match the shipment but are overridden by higher-priority rules
   */
  matchedRules: Nullable<IRule[]>
  /**
   * The suggested margin premium % to use based on matching rules or defaults
   * @example 0.10
   */
  suggestedMarginPremium: Nullable<number>
  /**
   * The default margin premium % setting
   * @example 0.10
   */
  defaultMarginPremium: number
  /**
   * The estimated rate
   * @example { carrierRate: 2000,
   * marginPercentage: 0.1,
   * marginValue: 200}
   */
  rateEstimate: IEstimate
  /**
   * Represents the margin premium value prior to any restrictions.
   * If this value is greater than the suggestedMarginPremium,
   * this means that the suggestedMarginPremium was capped at the max limit.
   */
  rawMarginPremium: Nullable<number>
  /**
   * The callback to handle when the user overrides the applied rules
   * If there are no applied rules, callback is called with false.
   * @param isRulesOverridden - true if the user has overridden the applied rules, false if they are using the applied rules
   */
  onRulesOverride?: (isRulesOverridden: boolean) => void
}

export const RatesEstimatedMarginAccordion = memo<IRatesEstimatedMarginAccordionProps>(
  ({
    appliedRules,
    dataTest = 'RatesEstimatedMarginAccordion',
    defaultMarginPremium,
    isDoNotBid = false,
    isReadOnly,
    matchedRules,
    onAmountChange,
    onPercentageChange,
    onRulesOverride,
    rateEstimate,
    rawMarginPremium,
    suggestedMarginPremium,
  }: IRatesEstimatedMarginAccordionProps) => {
    const controlElementRef = useRef<HTMLDivElement>(null)

    const { carrierRate, marginPercentage: percentage, marginValue: amount } = rateEstimate || {}
    const hasAppliedRules = !!appliedRules?.length
    const [accordionState, setAccordionState] = useState<IRateStrategyAccordionStateEnum>(
      getInitialState({ hasAppliedRules, isReadOnly })
    )

    const marginPremiumLimited = useMemo<boolean>(
      () => getIsMarginPremiumLimited({ rawMarginPremium, suggestedMarginPremium }),
      [rawMarginPremium, suggestedMarginPremium]
    )

    const marginAmountLabel = useMemo<string>(
      () => (amount ? currencyFormatter(amount) : '$0'),
      [amount]
    )

    const isAccordionDisabled = useMemo<boolean>(() => {
      return (isReadOnly || isDoNotBid) && !hasAppliedRules
    }, [hasAppliedRules, isReadOnly, isDoNotBid])

    const isCurrentValueDefaultSetting = useMemo<boolean>(
      () =>
        getIsCurrentValueDefaultSetting({
          appliedRules,
          defaultValue: defaultMarginPremium,
          currentValue: percentage,
        }),
      [defaultMarginPremium, appliedRules, percentage]
    )

    const isSuggestedValueOverridden = useMemo<boolean>(() => {
      return !!suggestedMarginPremium && suggestedMarginPremium !== percentage
    }, [percentage, suggestedMarginPremium])

    const showControls = useMemo(() => {
      if (isReadOnly) return false
      return (
        accordionState === IRateStrategyAccordionStateEnum.default ||
        accordionState === IRateStrategyAccordionStateEnum.overrideRules
      )
    }, [accordionState, isReadOnly])

    const overrideClickHandler = useCallback(() => {
      setAccordionState(IRateStrategyAccordionStateEnum.overrideRules)
    }, [])

    const useRulesClickHandler = useCallback(() => {
      setAccordionState(IRateStrategyAccordionStateEnum.useRules)
      onPercentageChange?.((suggestedMarginPremium || 0) * 100)
    }, [onPercentageChange, suggestedMarginPremium])

    const appliedRuleLineItems = useMemo(() => {
      return getAppliedAdjustmentRuleLineItems({
        rules: appliedRules,
        defaultMarginPremium,
        carrierRate,
        marginPremiumLimited,
        maxMarginPremium: suggestedMarginPremium || 0,
      })
    }, [
      carrierRate,
      defaultMarginPremium,
      marginPremiumLimited,
      appliedRules,
      suggestedMarginPremium,
    ])

    const notAppliedRuleLineItems = useMemo(() => {
      return getNotAppliedAdjustmentRuleLineItems({
        rules: matchedRules,
        carrierRate,
      })
    }, [carrierRate, matchedRules])

    // Scrolls controls into view
    useEffect(() => {
      if (showControls && controlElementRef?.current) {
        controlElementRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      }
    }, [controlElementRef, showControls])

    /**
     * Calls the onOverride callback when the suggested value is overridden
     */
    useEffect(() => {
      onRulesOverride?.(hasAppliedRules && isSuggestedValueOverridden)
    }, [hasAppliedRules, isSuggestedValueOverridden, onRulesOverride])

    return (
      <RatesEstimatedStrategyAccordion
        dataTest={dataTest}
        title='Est. Margin'
        amount={marginAmountLabel}
        description={
          <MarginPercentageDescription
            isCurrentValueDefaultSetting={isCurrentValueDefaultSetting}
            isSuggestedValueOverridden={isSuggestedValueOverridden}
            percentage={percentage}
            marginPremiumLimited={marginPremiumLimited}
            rawMarginPremium={rawMarginPremium}
          />
        }
        appliedRules={appliedRuleLineItems}
        notAppliedRules={notAppliedRuleLineItems}
        areAppliedRulesUnlocked={accordionState === IRateStrategyAccordionStateEnum.overrideRules}
        areAppliedRulesOverridden={isSuggestedValueOverridden}
        disabled={isAccordionDisabled}
        onOverride={overrideClickHandler}
        onUseRules={useRulesClickHandler}
        isReadOnly={isReadOnly}
        isDoNotBid={isDoNotBid}>
        {showControls && (
          <div ref={controlElementRef}>
            {accordionState === IRateStrategyAccordionStateEnum.overrideRules && (
              <Typography variant='subtitle1' marginBottom={2}>
                Adjust Premium Strategy
              </Typography>
            )}
            <MarginPremiumInputs
              amount={amount}
              percentage={percentage}
              onAmountChange={onAmountChange}
              onPercentageChange={onPercentageChange}
            />
          </div>
        )}
      </RatesEstimatedStrategyAccordion>
    )
  }
)
