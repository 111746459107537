import React, { memo, useCallback } from 'react'
import { IOrganizationShipperStatus } from '#types/graphqlTypes'
import {
  IPillSelectSearchableProps,
  PillSelectSearchable,
} from 'dpl/components/PillSelectSearchable'
import compact from 'lodash/compact'
import { IOrganizationShipperSearchResultFragment } from './graphql/OrganizationShipperSearchResultFragment'
import { usePillSelectSearchableOrganizationShipperLazyQuery } from './graphql/PillSelectSearchableOrganizationShipper'

const DEFAULT_ORGANIZATION_SHIPPER_STATUS: IOrganizationShipperStatus =
  IOrganizationShipperStatus.active

interface IPillSelectSearchableBaseProps
  extends IPillSelectSearchableProps<IOrganizationShipperSearchResultFragment> {}

export interface IPillSelectSearchableOrganizationShipperProps
  extends Omit<
    IPillSelectSearchableBaseProps,
    'getOptionKey' | 'isOptionEqualToValue' | 'loading' | 'onSearch' | 'options'
  > {
  /**
   * The status of the organization shipper to search by
   * @default IOrganizationShipperStatus.active
   */
  organizationShipperStatus?: IOrganizationShipperStatus
  /**
   * @default 'PillSelectSearchableOrganizationShipper'
   */
  dataTest?: string
}

export const PillSelectSearchableOrganizationShipper =
  memo<IPillSelectSearchableOrganizationShipperProps>(
    ({
      dataTest = 'PillSelectSearchableOrganizationShipper',
      organizationShipperStatus = DEFAULT_ORGANIZATION_SHIPPER_STATUS,
      label = 'Customer',
      ...props
    }) => {
      const [search, { data, loading }] = usePillSelectSearchableOrganizationShipperLazyQuery({
        variables: {
          searchTerm: '',
          filters: {
            status: organizationShipperStatus,
          },
        },
      })

      const { searchOrganizationShippers } = data || {}
      const { nodes = [] } = searchOrganizationShippers || {}
      const organizationShippers = compact<IOrganizationShipperSearchResultFragment>(nodes)

      const searchHandler = useCallback<NonNullable<IPillSelectSearchableBaseProps['onSearch']>>(
        searchTerm => {
          search({
            variables: {
              searchTerm,
              filters: {
                status: organizationShipperStatus,
              },
            },
          })
        },
        [organizationShipperStatus, search]
      )

      return (
        <PillSelectSearchable
          dataTest={dataTest}
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          label={label}
          loading={loading}
          onSearch={searchHandler}
          options={organizationShippers}
          {...props}
        />
      )
    }
  )
