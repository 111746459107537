import React from 'react'
import {
  getDueDays,
  getDueHours,
  getReadableDays,
  getSubmittedDays,
  isNumber,
} from '#routes/contract-rfp/utils/utils'
import { IContractRfp, IContractRfpStates, IContractRfpVersion } from '#types/graphqlTypes'
import { Tooltip } from 'dpl'
import { makeStyles, Typography } from 'dpl/core'
import { ClockAlertOutlineIcon, ClockIcon } from 'dpl/icons'
import moment from 'moment'

interface ICurrentVersion extends Pick<IContractRfpVersion, 'dueAt' | 'submittedAt'> {}

interface IContractRfpItem extends Pick<IContractRfp, 'state'> {
  currentVersion: ICurrentVersion
}

export interface IKanbanItemProps {
  item: IContractRfpItem
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}))

const STATES_WITH_DUE_DATE = [IContractRfpStates.planning, IContractRfpStates.in_progress]

export function DueDate({ item }: IKanbanItemProps) {
  const classes = useStyles()
  const dueAt = moment(item.currentVersion.dueAt).format('MMM D, YYYY')
  const dueHours = item.currentVersion.dueAt ? getDueHours(item.currentVersion.dueAt) : 0
  const dueDays = item.currentVersion.dueAt && getDueDays(item.currentVersion.dueAt)
  const dueReadableDays = isNumber(dueDays) ? getReadableDays(dueDays) : undefined
  const submittedDays =
    item.currentVersion.submittedAt && getSubmittedDays(item.currentVersion.submittedAt)
  const submittedReadableDays =
    isNumber(submittedDays) && item.currentVersion.submittedAt && getReadableDays(submittedDays)
  const submittedAt =
    item.currentVersion.submittedAt && moment(item.currentVersion.submittedAt).format('MMM D, YYYY')
  const isPastDue = dueHours < 48 && STATES_WITH_DUE_DATE.includes(item.state)
  const tooltipMessages: Record<
    IContractRfpStates | 'default',
    { tooltipMessage?: string; show: boolean }
  > = {
    [IContractRfpStates.submitted]: {
      tooltipMessage:
        submittedDays && submittedDays > 1
          ? `Submitted in ${submittedReadableDays} on ${submittedAt}`
          : `Submitted ${submittedReadableDays} on ${submittedAt}`,
      show: true,
    },
    [IContractRfpStates.planning]: {
      tooltipMessage:
        dueDays && dueDays > 1
          ? `Due in ${dueReadableDays} on ${dueAt}`
          : `Due ${dueReadableDays} on ${dueAt}`,
      show: true,
    },
    [IContractRfpStates.in_progress]: {
      tooltipMessage:
        dueDays && dueDays > 1
          ? `Due in ${dueReadableDays} on ${dueAt}`
          : `Due ${dueReadableDays} on ${dueAt}`,
      show: true,
    },
    [IContractRfpStates.closed]: {
      show: false,
    },
    [IContractRfpStates.archived]: {
      show: false,
    },
    default: {
      show: false,
    },
  }

  const { show: showClock, tooltipMessage = '' } =
    tooltipMessages[item.state] || tooltipMessages.default

  if (!showClock) return null

  if (isPastDue) {
    return (
      <Tooltip title={tooltipMessage}>
        <div className={classes.root}>
          <ClockAlertOutlineIcon size='medium' color='error' />
          <Typography variant='caption' color='error'>
            {dueReadableDays}
          </Typography>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={classes.root}>
      <Tooltip title={tooltipMessage}>
        <span>
          <ClockIcon size='medium' color='coolGray8' />
        </span>
      </Tooltip>
    </div>
  )
}
