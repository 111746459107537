import React, { memo, useCallback } from 'react'
import {
  AutocompleteOrganizationShipper,
  AutocompleteOrganizationShipperProps,
} from '#components/AutocompleteOrganizationShipper'
import { IOrganizationShipperItemFragment } from '#components/AutocompleteOrganizationShipper/graphql/OrganizationShipperFragment'
import classnames from 'classnames'
import { IDataGridFilterProps } from 'dpl/components/DataGrid/components'
import { makeStyles } from 'dpl/core'
import { IDataGridFilterCustomer } from './types'

const useStyles = makeStyles(() => ({
  headerFilter: {
    /**
     * Magic number coming from spec 🪄✨
     */
    minWidth: '250px',
  },
}))

export interface IDataGridFilterCustomerProps
  extends IDataGridFilterProps<IDataGridFilterCustomer | null> {
  /**
   * @default 'DataGridFilterCustomer'
   */
  dataTest?: string
}

export const DataGridFilterCustomer = memo<IDataGridFilterCustomerProps>(
  ({ dataTest = 'DataGridFilterCustomer', name, onChange, value: valueProp = null, variant }) => {
    const changeHandler = useCallback<
      NonNullable<AutocompleteOrganizationShipperProps['onChange']>
    >(
      (_, newValue) => {
        onChange({
          name,
          value: newValue ?? null,
        })
      },
      [name, onChange]
    )
    const classes = useStyles()
    const className = classnames({
      [classes.headerFilter]: variant === 'header',
    })

    return (
      <AutocompleteOrganizationShipper
        className={className}
        dataTest={dataTest}
        value={valueProp as IOrganizationShipperItemFragment}
        onChange={changeHandler}
        placeholder='Customer'
        size={variant === 'menu' ? 'medium' : 'small'}
      />
    )
  }
)
