import TrimbleMaps from '@trimblemaps/trimblemaps-js'
import get from 'lodash/get'
import { PREVIOUS_LINE_PAINT, PREDICTED_LINE_PAINT, LINE_HIGHLIGHTED_PAINT } from '../constants'
import { IMapStopProps } from '../types'

/**
 * Get a route style depending on type
 * @example getRoutePaint({ selected, type }) // '#0D0E10'
 */
const getRoutePaint = ({ selected, type }: { type: string; selected: boolean }): string => {
  const regularLinePaint = type === 'predicted' ? PREDICTED_LINE_PAINT : PREVIOUS_LINE_PAINT
  const style = selected ? LINE_HIGHLIGHTED_PAINT : regularLinePaint
  return get(style, 'line-color') as string
}

/**
 * Returns ID string based on concatenated lat/lon values
 * @example getRouteId([...]) // '70.123-23.220-71.222-34.202'
 */
function getRouteId<S extends Pick<IMapStopProps, 'coords'>>(stops: S[]): string {
  return stops
    .map(stop => {
      const { coords } = stop || {}
      const { lat, lon } = coords || {}
      return [lon, lat].join('-')
    })
    .join('-')
}

/**
 * Returns array of TrimbleMaps.LngLat
 * @example getStops([ ... ]) // [{ lng: 123, lat: 123 }]
 */
function getStops<S extends IMapStopProps>(stops: S[]): TrimbleMaps.LngLat[] {
  return stops.map(({ coords }) => {
    const { lat, lon } = coords || {}
    return new TrimbleMaps.LngLat(lon, lat)
  })
}

interface IRouteOptions {
  /**
   * @example 'previous' | 'predicted'
   * @default 'previous'
   */
  type?: string
  /**
   * @default false
   */
  selected?: boolean
  /**
   * disables routes outside of US
   * @default false
   */
  bordersOpen?: boolean
}

/**
 * Returns TrimbleMaps.Route for use in map configuration
 * @example
 * ```
 * const route = getTrimbleMapRoute([...], { showStops: true })
 * ```
 */
export function getTrimbleMapRoute<S extends IMapStopProps>(
  stops: S[],
  options: Partial<Omit<TrimbleMaps.RouteOptions, 'stops'>> = {},
  routeOptions: IRouteOptions = {}
): TrimbleMaps.Route {
  const { routeId = getRouteId(stops), frameOptions, ...rest } = options || {}
  const { bordersOpen = false, selected = false, type = 'previous' } = routeOptions || {}
  const routeColor = getRoutePaint({ type, selected })
  return new TrimbleMaps.Route({
    bordersOpen,
    routeId,
    routeColor,
    frameOptions: {
      animate: false,
      padding: 48,
      ...frameOptions,
    },
    showStops: false,
    stops: getStops(stops),
    routePathOpacity: 1,
    routeWidth: 2,
    ...rest,
  })
}
