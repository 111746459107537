import React, { useCallback, useMemo } from 'react'
import { Control, useWatch, FieldErrors, UseFormMethods } from 'react-hook-form'
import { Box, Grid, Typography } from 'dpl/core'
import EmailIcon from 'dpl/icons/build/EmailIcon'
import SearchIcon from 'dpl/icons/build/SearchIcon'
import { FormIf } from 'forms/components/FormIf'
import { ControlledAutocompleteOrganizationCarrier } from '../../../../../../../components/ControlledAutocompleteOrganizationCarrier'
import { TAutocompleteOrganizationCarrierSchema } from '../../../../../../../components/ControlledAutocompleteOrganizationCarrier/schema'
import { ControlledAutocompleteOrganizationCarrierUserEmail } from '../../../../../../../components/ControlledAutocompleteOrganizationCarrierUserEmail'
import { ControlledDaysOfWeekToggleButtonGroup } from '../../../../../../../components/ControlledDaysOfWeekToggleButtonGroup'
import { ControlledRateTypeSelectField } from '../../../../../../../components/ControlledRateTypeSelectField'
import { TRoutingGuideCarrierSchema } from '../../schema'
import { IAvailabilityType } from '../../types'
import { ControlledAvailabilitySelect } from '../ControlledAvailabilitySelect'
import { ControlledRateInput } from '../ControlledRateInput'

export interface IRoutingGuideCarrierFormBodyProps {
  control: Control<TRoutingGuideCarrierSchema>
  errors: FieldErrors<TRoutingGuideCarrierSchema>
  setValue: UseFormMethods<TRoutingGuideCarrierSchema>['setValue']
  /**
   * If the form is in update mode
   * @default false
   */
  isUpdateMode?: boolean
  /**
   * @default 'RoutingGuideCarrierFormBody'
   */
  dataTest?: string
}

export function RoutingGuideCarrierFormBody({
  control,
  dataTest = 'RoutingGuideCarrierFormBody',
  errors,
  isUpdateMode = false,
  setValue,
}: IRoutingGuideCarrierFormBodyProps) {
  const rateTypeValue = useWatch<TRoutingGuideCarrierSchema['rateType']>({
    control,
    name: 'rateType',
  })
  const orgCarrier = useWatch<TAutocompleteOrganizationCarrierSchema>({
    control,
    name: 'organizationCarrier',
  })
  const { carrier } = orgCarrier || {}
  const { id: carrierId } = carrier || {}

  const { availabilityDays, carrierUserEmail, organizationCarrier, rate, rateType } = errors || {}
  const { message: availabilityDaysMessage } = (availabilityDays as FieldErrors) || {}
  const { message: organizationCarrierMessage } = (organizationCarrier as FieldErrors) || {}
  const { message: rateErrorMessage } = rate || {}
  const { message: rateTypeErrorMessage } = rateType || {}

  /**
   * Error message for Carrier contact field
   */
  const { message: carrierUserEmailMessage } = (carrierUserEmail as FieldErrors) || {}
  const { inputValue: carrierUserEmailInput } = carrierUserEmail || {}
  const { message: carrierUserEmailInputMessage } = carrierUserEmailInput || {}
  const carrierContactErrorMessage = useMemo(() => {
    if (!carrierId) {
      return ''
    }
    return carrierUserEmailMessage || carrierUserEmailInputMessage
  }, [carrierId, carrierUserEmailInputMessage, carrierUserEmailMessage])

  /**
   * Resets the carrier user email field when the org carrier changes
   */
  const organizationCarrierChangeHandler = useCallback(() => {
    setValue('carrierUserEmail', { inputValue: '', value: null })
  }, [setValue])

  return (
    <Grid data-test={dataTest} container rowSpacing={3} columnSpacing={1.5}>
      <Grid item xs={12} md={6}>
        <ControlledAutocompleteOrganizationCarrier
          control={control}
          name='organizationCarrier'
          AutocompleteOrganizationCarrierProps={{
            label: 'Carrier',
            error: !!organizationCarrierMessage,
            helperText: organizationCarrierMessage,
            dataTest: `${dataTest}-organizationCarrier`,
            disabled: isUpdateMode,
            renderStartAdornment: () => <SearchIcon size='xlarge' color='coolGray5' />,
            onChange: organizationCarrierChangeHandler,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ControlledAutocompleteOrganizationCarrierUserEmail
          control={control}
          name='carrierUserEmail'
          AutocompleteOrganizationCarrierUserEmailProps={{
            label: 'Carrier Contact',
            error: !!carrierContactErrorMessage,
            helperText: carrierContactErrorMessage,
            dataTest: `${dataTest}-carrierUserEmail`,
            organizationCarrierId: carrierId || '',
            disabled: !carrierId,
            renderStartAdornment: () => (
              <EmailIcon size='xlarge' color={!carrierId ? 'gray2' : 'coolGray5'} />
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledRateTypeSelectField
          control={control}
          name='rateType'
          SelectProps={{
            error: !!rateTypeErrorMessage,
            helperText: rateTypeErrorMessage,
            dataTest: `${dataTest}-rateType`,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        {rateTypeValue && (
          <ControlledRateInput
            control={control}
            name='rate'
            rateType={rateTypeValue}
            CurrencyInputProps={{
              error: !!rateErrorMessage,
              helperText: rateErrorMessage,
              dataTest: `${dataTest}-rate`,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' component={Box} mb={0.5} color='textSecondary'>
          Availability
        </Typography>
        <ControlledAvailabilitySelect
          control={control}
          name='availability'
          dataTest={`${dataTest}-availability`}
        />
        <FormIf control={control} name='availability' when={IAvailabilityType.specificDays}>
          <Box mt={1.5}>
            <ControlledDaysOfWeekToggleButtonGroup
              control={control}
              name='availabilityDays'
              ToggleButtonGroupProps={{
                error: !!availabilityDaysMessage,
                helperText: availabilityDaysMessage,
                dataTest: `${dataTest}-availabilityDays`,
              }}
            />
          </Box>
        </FormIf>
      </Grid>
    </Grid>
  )
}
