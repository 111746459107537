import React, { ReactNode } from 'react'
import {
  getDueDays,
  getDueHours,
  getReadableDays,
  isNumber,
} from '#routes/contract-rfp/utils/utils'
import { makeStyles, Typography } from 'dpl/core'
import { ClockAlertOutlineIcon, ClockIcon } from 'dpl/icons'
import moment from 'moment'

export interface IKanbanItemProps {
  date: string
  showPastDue?: boolean
  children?: ReactNode
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}))

export function DateInfo({ children, date, showPastDue = false }: IKanbanItemProps) {
  const classes = useStyles()
  const dateHours = date ? getDueHours(date) : 0
  const dateDays = date && getDueDays(date)
  const formattedDate = moment(date).format('MMM D, YYYY')
  const dateReadableDays = isNumber(dateDays) ? getReadableDays(dateDays) : undefined
  const isPastDue = showPastDue && dateHours < 48

  return (
    <div className={classes.root}>
      {isPastDue ? (
        <ClockAlertOutlineIcon size='medium' color='error' />
      ) : (
        <ClockIcon size='medium' color='coolGray8' />
      )}
      <Typography variant='body1' color={isPastDue ? 'error' : undefined}>
        {formattedDate} ({dateReadableDays})
      </Typography>
      {children}
    </div>
  )
}
