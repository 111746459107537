import { IContractRatesStopInput, IQueryFetchContractRatesArgs } from '#types/graphqlTypes'
import { IEquipment53Types } from '../../../types'
import { IContractLaneInformationSchema } from '../schema'
import { getDropTrailerLocations } from './getDropTrailerLocations'
import { mapVolumeSchemaToQuery } from './mapVolumeSchemaToQuery'

interface MapSchemaToQueryProps {
  formData: IContractLaneInformationSchema
}

export function mapSchemaToQuery({
  formData,
}: MapSchemaToQueryProps): IQueryFetchContractRatesArgs {
  const {
    contractAdvancedInputs,
    contractDuration,
    equipmentKey,
    stops = [],
    temperature,
    volume,
  } = formData

  const { dropTrailer, dropTrailerSwitch, teamRequired } = contractAdvancedInputs
  const { max, min } = temperature ?? {}
  const isReefer = equipmentKey === IEquipment53Types.reefer

  const { dropTrailerDelivery, dropTrailerPickup } = getDropTrailerLocations({
    dropTrailer,
    dropTrailerSwitch,
  })
  const originDropTrailer = dropTrailerPickup ?? false
  const destinationDropTrailer = dropTrailerDelivery ?? false
  const { monthlyVolume } = mapVolumeSchemaToQuery({
    volumeSchemaInputs: volume,
    contractDurationSchemaInputs: contractDuration,
  })

  return {
    equipmentKey,
    isTeam: teamRequired ?? false,
    monthlyVolume,
    reeferTempMax: isReefer ? max ?? 45 : null,
    reeferTempMin: isReefer ? min ?? 45 : null,
    stops: stops.map((stop, index) => {
      const { location, type } = stop || {}
      const isOrigin = index === 0
      const isDestination = index === (stops?.length ?? 0) - 1
      const stopInfo: IContractRatesStopInput = {
        stopSequence: index + 1,
        stopType: type,
        /**
         * Avoid sending postal code if result is city. This
         * will ensure consistent behavior based on result type.
         */
        postalCode: location?.resultType === 'City' ? null : location?.postalCode,
        city: location?.city,
        stateCode: location?.stateCode,
        /**
         * Following values can be set only for origin and destination.
         */
        isDropTrailer: false,
      }

      if (isOrigin) {
        stopInfo.isDropTrailer = originDropTrailer
      } else if (isDestination) {
        stopInfo.isDropTrailer = destinationDropTrailer
      }

      return stopInfo
    }),
  }
}
