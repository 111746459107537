import * as GeoJSON from 'geojson'
import { IMapPosition } from '../types'

export function getGeoJSONFeaturePosition<P extends IMapPosition>(
  position: P
): GeoJSON.Feature<GeoJSON.Point, P> {
  const { coords } = position || {}
  const { lat = 0, lon = 0 } = coords || {}
  return {
    type: 'Feature',
    properties: position,
    geometry: {
      type: 'Point',
      coordinates: [lon, lat],
    },
  }
}
