import React from 'react'
import SvgIcon from '../components/SvgIcon'
import { SpecializedDeprecatedSvg } from '../icons'

function SpecializedDeprecatedIcon(props) {
  return (
    <SvgIcon
      className='RogersSvgIcon-SpecializedDeprecated'
      component={SpecializedDeprecatedSvg}
      {...props}
    />
  )
}

export default SpecializedDeprecatedIcon
