import { CurrencyInputSchema } from 'forms/components/ControlledCurrencyInput/schema'
import * as yup from 'yup'
import { AutocompleteOrganizationCarrierSchema } from '../../../../../../components/ControlledAutocompleteOrganizationCarrier/schema'
import { AutocompleteOrganizationCarrierUserEmailSchema } from '../../../../../../components/ControlledAutocompleteOrganizationCarrierUserEmail/schema'
import { IDaysOfWeek } from '../../../../../../types/daysOfWeek'
import { IRateType } from '../../../../../../types/rateType'
import { IAvailabilityType } from '../types'

export const RoutingGuideCarrierSchema = yup.object({
  /**
   * Carrier
   */
  organizationCarrier: AutocompleteOrganizationCarrierSchema.required('You must select a carrier.'),
  /**
   * Carrier Contact Email
   */
  carrierUserEmail: AutocompleteOrganizationCarrierUserEmailSchema.shape({
    inputValue: yup
      .string()
      .nullable()
      .matches(
        /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i,
        'You must provide a valid email address.'
      ),
  })
    .test(
      'not-null-or-empty',
      'You must provide a carrier contact email.',
      ({ inputValue, value }) => inputValue !== '' || value != null
    )
    .nullable()
    .required('You must provide a carrier contact email.'),
  /**
   * Rate Type
   *
   * @example 'allIn'
   */
  rateType: yup
    .mixed<IRateType>()
    .default(IRateType.allIn)
    .required('You must select a rate type.'),
  /**
   * Lane Rate
   *
   * @example 100
   */
  rate: CurrencyInputSchema.moreThan(0, 'You must enter a rate.').required(
    'You must enter a rate.'
  ),
  /**
   * Availability
   *
   * @example 'anyDay'
   * @default 'anyDay'
   */
  availability: yup
    .mixed<IAvailabilityType>()
    .default(IAvailabilityType.anyDay)
    .required('You must select an availability.'),
  /**
   * Available Days of the week
   *
   * @example ['mon']
   */
  availabilityDays: yup
    .array(yup.mixed<IDaysOfWeek>())
    .default([])
    .when('availability', ([availability], schema) => {
      return availability === IAvailabilityType.specificDays
        ? schema
            .nonNullable('You must select days available.')
            .min(1, 'You must select days available.')
        : schema.nullable().notRequired()
    }),
})

export type TRoutingGuideCarrierSchema = yup.InferType<typeof RoutingGuideCarrierSchema>
