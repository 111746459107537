import React, { useState, useCallback, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { ControlledAutocompleteOrganizationShipper } from '#components/ControlledAutocompleteOrganizationShipper'
import { ICreateQuoteInput } from '#types/graphqlTypes'
import { IEstimate } from '#types/rates'
import { FormModal, IModalAction, IFormModalProps } from 'dpl/components/Modals'
import { TextField } from 'dpl/components/TextField'
import { useToastContext } from 'dpl/components/Toast'
import { FORMATS } from 'dpl/constants/datetime'
import { Grid, Checkbox, FormControlLabel, Typography, Box, Button } from 'dpl/core'
import CopyIcon from 'dpl/icons/build/CopyIcon'
import SearchIcon from 'dpl/icons/build/SearchIcon'
import { DatePickersProvider } from 'dpl/providers'
import { useFlagsContext } from 'flags/src/FlagsContext'
import ControlledDatePicker from 'forms/components/ControlledDatePicker'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import { ControlledTextField } from 'forms/components/ControlledTextField'
import moment from 'moment-timezone'
import { TRatesResponse } from '../../types'
import { useSaveQuoteModalForm } from './hooks'
import { getSuggestedMessage, mapSchemaToInput } from './utils'

const COMMUNICATION_METHODS = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Load Board',
    value: 'load_board',
  },
  {
    label: 'Customer Portal',
    value: 'customer_portal',
  },
]

const DATA_TEST = 'save-quote-modal'

export interface ISaveQuoteModalProps extends Pick<IFormModalProps, 'open' | 'onClose'> {
  ratesResponse: TRatesResponse
  rateEstimate: IEstimate
  isLoading: boolean
  /**
   * If true, the user is required to provide a reason for overriding the adjustment rules
   * @default false
   */
  isAdjustmentRulesOverridden?: boolean
  /**
   * If true, the user is required to provide a reason for overriding the do not bid rules
   * @default false
   */
  isDoNotBidRulesOverridden?: boolean
  onSubmit: (formData: ICreateQuoteInput) => void
}

export function SaveQuoteModal({
  isAdjustmentRulesOverridden = false,
  isDoNotBidRulesOverridden = false,
  isLoading,
  onClose,
  onSubmit,
  open,
  rateEstimate,
  ratesResponse,
}: ISaveQuoteModalProps) {
  const [markAsWon, setMarkAsWon] = useState(false)
  const { openToast } = useToastContext() || {}
  const { isFlagEnabled } = useFlagsContext()

  const isCustomerCreateEnabled = useMemo(() => isFlagEnabled('create_customer'), [isFlagEnabled])

  const { organizationShipper } = ratesResponse || {}

  const { control, errors, handleSubmit } = useSaveQuoteModalForm({
    defaultValues: {
      customer: organizationShipper || null,
    },
    context: {
      markAsWon,
      isAdjustmentRulesOverridden,
      isDoNotBidRulesOverridden,
    },
  })

  const communicationMethod = useWatch({
    control,
    name: 'communicationMethod',
    defaultValue: null,
  })

  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'dismiss',
      dataTest: `${DATA_TEST}-cancel`,
      action: onClose,
      disabled: isLoading,
    },
    {
      label: 'Save',
      type: 'confirm',
      dataTest: `${DATA_TEST}-submit`,
      action: handleSubmit(async formData => {
        onSubmit(mapSchemaToInput({ formData, rateEstimate, ratesResponse }))
      }),
      disabled: isLoading,
    },
  ]

  const toggleMarkAsWon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarkAsWon(event.target.checked)
  }

  const writeClipboardText = useCallback(async () => {
    try {
      const suggestedMessage = getSuggestedMessage(ratesResponse, rateEstimate)

      await navigator.clipboard.writeText(suggestedMessage)

      openToast({
        toastMessage: 'Suggested message copied to clipboard.',
        toastType: 'default',
      })
    } catch {
      openToast({
        toastMessage: 'Something went wrong. Please try again.',
        toastType: 'alert',
      })
    }
  }, [openToast, rateEstimate, ratesResponse])

  return (
    <DatePickersProvider>
      <FormModal
        title='Save Quote'
        actions={actions}
        dataTest={DATA_TEST}
        open={open}
        onClose={onClose}
        maxWidth='xs'
        includeCloseIconButton>
        {(isAdjustmentRulesOverridden || isDoNotBidRulesOverridden) && (
          <Grid container rowSpacing={2} mb={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>Provide a Reason for Overriding Rules</Typography>
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name='overrideReason'
                TextFieldProps={{
                  label: 'Reason',
                  helperText: errors?.overrideReason?.message,
                  error: Boolean(errors.overrideReason),
                  dataTest: 'override-reason-input',
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Submission Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocompleteOrganizationShipper
              enableCreate={isCustomerCreateEnabled && !organizationShipper}
              control={control}
              name='customer'
              AutocompleteOrganizationShipperProps={{
                label: 'Customer',
                StartIcon: SearchIcon,
                error: Boolean(errors.customer),
                helperText: errors.customer?.message,
                placeholder: 'Customer',
                dataTest: 'customer-autocomplete',
                disabled: !!organizationShipper,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledDatePicker
              control={control}
              name='date'
              DatePickerProps={{
                label: 'Date Submitted',
                placeholder: moment().format(FORMATS.monthDayYear),
                format: FORMATS.monthDayYear,
                dataTest: 'date-submitted-input',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledSelectField
              control={control}
              name='communicationMethod'
              SelectProps={{
                options: COMMUNICATION_METHODS,
                label: 'Communication Method',
                placeholder: 'Communication Method',
                helperText: errors?.communicationMethod?.message,
                error: Boolean(errors.communicationMethod),
                dataTest: 'communication-method',
              }}
            />
          </Grid>
          {communicationMethod === 'email' && (
            <Grid item xs={12} direction='column' rowGap={1} data-test='suggested-message'>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2'>Suggested Message</Typography>
                <Button
                  variant='text'
                  onClick={writeClipboardText}
                  startIcon={<CopyIcon size='large' />}>
                  Copy to Clipboard
                </Button>
              </Box>
              <TextField
                value={getSuggestedMessage(ratesResponse, rateEstimate)}
                multiline
                maxRows={8}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name='referenceNumber'
              TextFieldProps={{
                label: 'Customer Reference #',
                placeholder: 'Customer Reference #',
                helperText: 'This number will help you track all the quote activity.',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={markAsWon}
                  onChange={toggleMarkAsWon}
                  data-test='mark-as-won-checkbox'
                />
              }
              label='Mark as Won'
            />
          </Grid>
          {markAsWon && (
            <Grid item xs={12}>
              <ControlledTextField
                control={control}
                name='shipmentNumber'
                TextFieldProps={{
                  label: 'Shipment #',
                  placeholder: 'Shipment #',
                  helperText: errors?.shipmentNumber?.message,
                  error: Boolean(errors.shipmentNumber),
                  dataTest: 'shipment-number-input',
                }}
              />
            </Grid>
          )}
        </Grid>
      </FormModal>
    </DatePickersProvider>
  )
}
