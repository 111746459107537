import moment from 'moment'

export function getDueDays(date: string) {
  const today = moment().startOf('day')
  const dueDate = moment(date).startOf('day')

  return dueDate.diff(today, 'days')
}

export function getDueHours(date: string) {
  const today = moment()
  const dueDate = moment(date)

  return dueDate.diff(today, 'hours')
}

export function getSubmittedDays(date: string) {
  const today = moment().startOf('day')
  const submittedDate = moment(date).startOf('day')

  return submittedDate.diff(today, 'days')
}

export function getReadableDays(days: number) {
  if (days === 0) return 'Today'
  if (days === 1) return 'Tomorrow'
  if (days === -1) return 'Yesterday'
  if (days > 1) return `${days} days`

  return `${Math.abs(days)} days ago`
}

export function isNumber(value: number | string | null | undefined): value is number {
  return typeof value === 'number' && Number.isFinite(value)
}

export function getDuration(startDate: string, endDate: string) {
  const start = moment(startDate)
  const end = moment(endDate)
  const monthDiffs = end.diff(start, 'months')

  if (monthDiffs < 1) {
    return `${end.diff(start, 'days')} days`
  }

  return `${monthDiffs} mo`
}

export function getLiveAndDrop(isDrop: boolean) {
  return isDrop ? 'Drop' : 'Live'
}

export function getLaneLiveAndDrop(isPickupDrop: boolean, isDeliveryDrop: boolean) {
  return `${getLiveAndDrop(isPickupDrop)}-${getLiveAndDrop(isDeliveryDrop)}`
}
