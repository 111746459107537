import React, { useState, useCallback } from 'react'
import { numericFormatter } from 'react-number-format'
import { weightFormatter } from '#utils/weightFormatter'
import { ISliderProps, Slider } from 'dpl/components/Slider'
import { ToggleButtonPopper } from './ToggleButtonPopper'
import { LaneInformationFormSchema } from './laneInformationFormSchema'

interface IWeightInputMenuProps {
  weight: LaneInformationFormSchema['weight']
  onChange: (weight: number) => void
}

const DEFAULT_WEIGHT = 35000
const MIN_WEIGHT = 1500
const MIN_WEIGHT_LABEL = '1,500'
const MAX_WEIGHT = 45000
const MAX_WEIGHT_LABEL = '45,000'
const STEP = 5000
const WEIGHT_FORMATTING_PROPS = {
  thousandSeparator: ',',
  decimalScale: 0,
  allowNegative: false,
}
const valueLabelFormatter = (value: number) => numericFormatter(`${value}`, WEIGHT_FORMATTING_PROPS)

export function WeightInputMenu({ onChange, weight: weightProp }: IWeightInputMenuProps) {
  const currentWeightValue = weightProp ?? DEFAULT_WEIGHT
  const formattedCurrentWeight = weightFormatter(currentWeightValue)
  const [weight, setWeight] = useState<number>(currentWeightValue)

  const sliderLabel = `Weight (${weightFormatter(weight)})`

  const sliderChangeHandler: ISliderProps['onChange'] = (_, newValue) => {
    setWeight(newValue as number)
  }

  const commitValue = useCallback(() => {
    onChange(weight)
  }, [onChange, weight])

  return (
    <ToggleButtonPopper label={formattedCurrentWeight} onClose={commitValue}>
      <Slider
        min={MIN_WEIGHT}
        minLabel={MIN_WEIGHT_LABEL}
        max={MAX_WEIGHT}
        maxLabel={MAX_WEIGHT_LABEL}
        value={weight}
        label={sliderLabel}
        onChange={sliderChangeHandler}
        valueLabelFormat={valueLabelFormatter}
        step={STEP}
      />
    </ToggleButtonPopper>
  )
}
