export interface IEstimate {
  carrierRate: number
  marginPercentage: number
  marginValue: number
}

export enum ICostStrategyEnum {
  Aggressive = 'Aggressive',
  Balanced = 'Balanced',
  Conservative = 'Conservative',
  Customized = 'Customized',
}

export enum IRuleTypeEnum {
  base = 'baseline_adjustment',
  additive = 'additive_adjustment',
  doNotBid = 'do_not_bid',
}

export enum IAdjustmentType {
  cost = 'cost',
  margin = 'margin',
}

export enum IRateStrategyAccordionStateEnum {
  /**
   * Displays the suggested rate strategy and shows controls when expanded.
   */
  default = 'default',
  /**
   * Displays the suggested rate strategy and controls are disabled or not shown.
   */
  defaultReadOnly = 'defaultReadOnly',
  /**
   * Displays list of matching rules and Override CTA. Controls are disabled or not shown.
   */
  useRules = 'useRules',
  /**
   * Displays list of matching rules and Use Rules CTA. Applied rules are overridden. Controls are shown and active.
   */
  overrideRules = 'overrideRules',
  /**
   * Does not show rate.
   */
  doNotBid = 'doNotBid',
}
