import React from 'react'
import { DataGridAvatarCell } from 'dpl'
import { FormattedDateTime } from 'dpl/components/FormattedDateTime'
import { DataGridEventTypeCell } from '../components/DataGridEventTypeCell'
import { IRoutingGuideActivityDataGrid } from '../types'

// TODO convert to user TZ
export const ROUTING_GUIDE_ACTIVITY_COLUMNS: IRoutingGuideActivityDataGrid['columns'] = [
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 300,
    renderCell: ({ row }) => {
      const { occurredAt } = row
      return <FormattedDateTime datetime={occurredAt} format='timeDateAndYear' />
    },
  },
  {
    field: 'user',
    headerName: 'User',
    minWidth: 300,
    renderCell: ({ row }) => {
      const { performedByOrganizationUser } = row
      const { user } = performedByOrganizationUser
      const { emailAddress, fullName } = user || {}
      return <DataGridAvatarCell bottomCaption={emailAddress}>{fullName}</DataGridAvatarCell>
    },
  },
  {
    field: 'event',
    headerName: 'Event',
    minWidth: 300,
    renderCell: ({ row }) => {
      const { carrier, eventType } = row
      return <DataGridEventTypeCell carrier={carrier} eventType={eventType} />
    },
  },
]
