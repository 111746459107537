import { IMarketForecastFragment } from '../graphql/MarketSimFragment'

export const MOCKED_TWELVE_MONTHS_DATA: IMarketForecastFragment = {
  monthlyCost: [
    {
      month: '2025-03-01',
      volume: 1,
      costModelRange: {
        cost10: 2176.69,
        cost15: 2202.78,
        cost20: 2228.87,
        cost25: 2254.97,
        cost30: 2286.49,
        cost35: 2318.02,
        cost40: 2349.54,
        cost45: 2381.07,
        cost50: 2412.59,
        cost55: 2460.38,
        cost60: 2508.16,
        cost65: 2555.95,
        cost70: 2603.73,
        cost75: 2651.52,
        cost80: 2752.69,
        cost85: 2853.86,
        cost90: 2955.04,
      },
    },
    {
      month: '2025-04-01',
      volume: 1,
      costModelRange: {
        cost10: 2043.28,
        cost15: 2075.07,
        cost20: 2106.85,
        cost25: 2138.63,
        cost30: 2167.48,
        cost35: 2196.34,
        cost40: 2225.2,
        cost45: 2254.05,
        cost50: 2282.91,
        cost55: 2319.65,
        cost60: 2356.39,
        cost65: 2393.13,
        cost70: 2429.87,
        cost75: 2466.62,
        cost80: 2529.02,
        cost85: 2591.43,
        cost90: 2653.83,
      },
    },
    {
      month: '2025-05-01',
      volume: 1,
      costModelRange: {
        cost10: 1984.64,
        cost15: 2013.05,
        cost20: 2041.47,
        cost25: 2069.89,
        cost30: 2096.63,
        cost35: 2123.37,
        cost40: 2150.11,
        cost45: 2176.85,
        cost50: 2203.59,
        cost55: 2255.97,
        cost60: 2308.36,
        cost65: 2360.74,
        cost70: 2413.13,
        cost75: 2465.52,
        cost80: 2513.17,
        cost85: 2560.82,
        cost90: 2608.47,
      },
    },
    {
      month: '2025-06-01',
      volume: 1,
      costModelRange: {
        cost10: 1985.8,
        cost15: 2028.98,
        cost20: 2072.16,
        cost25: 2115.33,
        cost30: 2147.6,
        cost35: 2179.86,
        cost40: 2212.12,
        cost45: 2244.38,
        cost50: 2276.64,
        cost55: 2317.35,
        cost60: 2358.05,
        cost65: 2398.76,
        cost70: 2439.47,
        cost75: 2480.17,
        cost80: 2532.4,
        cost85: 2584.64,
        cost90: 2636.87,
      },
    },
    {
      month: '2025-07-01',
      volume: 1,
      costModelRange: {
        cost10: 2119.29,
        cost15: 2160.97,
        cost20: 2202.66,
        cost25: 2244.34,
        cost30: 2283.62,
        cost35: 2322.91,
        cost40: 2362.19,
        cost45: 2401.48,
        cost50: 2440.76,
        cost55: 2493.42,
        cost60: 2546.07,
        cost65: 2598.73,
        cost70: 2651.39,
        cost75: 2704.04,
        cost80: 2770.22,
        cost85: 2836.39,
        cost90: 2902.56,
      },
    },
    {
      month: '2025-08-01',
      volume: 1,
      costModelRange: {
        cost10: 2348.14,
        cost15: 2382.01,
        cost20: 2415.88,
        cost25: 2449.74,
        cost30: 2484.53,
        cost35: 2519.31,
        cost40: 2554.09,
        cost45: 2588.87,
        cost50: 2623.66,
        cost55: 2678.65,
        cost60: 2733.65,
        cost65: 2788.64,
        cost70: 2843.64,
        cost75: 2898.63,
        cost80: 2979.05,
        cost85: 3059.46,
        cost90: 3139.87,
      },
    },
    {
      month: '2025-09-01',
      volume: 1,
      costModelRange: {
        cost10: 2463.01,
        cost15: 2500.2,
        cost20: 2537.38,
        cost25: 2574.56,
        cost30: 2613.87,
        cost35: 2653.18,
        cost40: 2692.49,
        cost45: 2731.81,
        cost50: 2771.12,
        cost55: 2812.12,
        cost60: 2853.13,
        cost65: 2894.13,
        cost70: 2935.13,
        cost75: 2976.14,
        cost80: 3078.26,
        cost85: 3180.38,
        cost90: 3282.51,
      },
    },
    {
      month: '2025-10-01',
      volume: 1,
      costModelRange: {
        cost10: 2494.55,
        cost15: 2541.89,
        cost20: 2589.22,
        cost25: 2636.56,
        cost30: 2671.38,
        cost35: 2706.21,
        cost40: 2741.03,
        cost45: 2775.85,
        cost50: 2810.67,
        cost55: 2868,
        cost60: 2925.33,
        cost65: 2982.66,
        cost70: 3039.99,
        cost75: 3097.32,
        cost80: 3162.4,
        cost85: 3227.49,
        cost90: 3292.57,
      },
    },
    {
      month: '2025-11-01',
      volume: 1,
      costModelRange: {
        cost10: 2381.13,
        cost15: 2421.01,
        cost20: 2460.89,
        cost25: 2500.77,
        cost30: 2530.52,
        cost35: 2560.27,
        cost40: 2590.02,
        cost45: 2619.77,
        cost50: 2649.52,
        cost55: 2695.66,
        cost60: 2741.79,
        cost65: 2787.93,
        cost70: 2834.07,
        cost75: 2880.21,
        cost80: 2971.97,
        cost85: 3063.73,
        cost90: 3155.49,
      },
    },
    {
      month: '2025-12-01',
      volume: 1,
      costModelRange: {
        cost10: 2431.66,
        cost15: 2465.72,
        cost20: 2499.78,
        cost25: 2533.84,
        cost30: 2576.3,
        cost35: 2618.75,
        cost40: 2661.21,
        cost45: 2703.66,
        cost50: 2746.11,
        cost55: 2791.73,
        cost60: 2837.34,
        cost65: 2882.96,
        cost70: 2928.57,
        cost75: 2974.19,
        cost80: 3052.67,
        cost85: 3131.16,
        cost90: 3209.64,
      },
    },
    {
      month: '2026-01-01',
      volume: 1,
      costModelRange: {
        cost10: 2571.23,
        cost15: 2612.38,
        cost20: 2653.53,
        cost25: 2694.68,
        cost30: 2754.29,
        cost35: 2813.9,
        cost40: 2873.51,
        cost45: 2933.12,
        cost50: 2992.73,
        cost55: 3063.47,
        cost60: 3134.21,
        cost65: 3204.95,
        cost70: 3275.68,
        cost75: 3346.42,
        cost80: 3493.99,
        cost85: 3641.55,
        cost90: 3789.12,
      },
    },
    {
      month: '2026-02-01',
      volume: 1,
      costModelRange: {
        cost10: 2462.52,
        cost15: 2497.83,
        cost20: 2533.14,
        cost25: 2568.45,
        cost30: 2606.91,
        cost35: 2645.36,
        cost40: 2683.82,
        cost45: 2722.27,
        cost50: 2760.73,
        cost55: 2826.31,
        cost60: 2891.88,
        cost65: 2957.46,
        cost70: 3023.04,
        cost75: 3088.61,
        cost80: 3152.93,
        cost85: 3217.24,
        cost90: 3281.55,
      },
    },
  ],
  volumeWtdAvgCost: {
    cost10: 2288.5,
    cost15: 2325.16,
    cost20: 2361.82,
    cost25: 2398.48,
    cost30: 2434.97,
    cost35: 2471.46,
    cost40: 2507.94,
    cost45: 2544.43,
    cost50: 2580.92,
    cost55: 2631.89,
    cost60: 2682.86,
    cost65: 2733.84,
    cost70: 2784.81,
    cost75: 2835.78,
    cost80: 2915.73,
    cost85: 2995.68,
    cost90: 3075.63,
  },
}

export const MOCKED_SIX_MONTHS_DATA: IMarketForecastFragment = {
  monthlyCost: [
    {
      month: '2025-03-01',
      volume: 100,
      costModelRange: {
        cost10: 2638.82,
        cost15: 2671.74,
        cost20: 2704.66,
        cost25: 2737.58,
        cost30: 2771.27,
        cost35: 2804.96,
        cost40: 2838.65,
        cost45: 2872.34,
        cost50: 2906.04,
        cost55: 2964.19,
        cost60: 3022.35,
        cost65: 3080.51,
        cost70: 3138.67,
        cost75: 3196.83,
        cost80: 3272.56,
        cost85: 3348.3,
        cost90: 3424.03,
      },
    },
    {
      month: '2025-04-01',
      volume: 200,
      costModelRange: {
        cost10: 2639.83,
        cost15: 2663.92,
        cost20: 2688.02,
        cost25: 2712.11,
        cost30: 2745.98,
        cost35: 2779.85,
        cost40: 2813.71,
        cost45: 2847.58,
        cost50: 2881.45,
        cost55: 2919.16,
        cost60: 2956.87,
        cost65: 2994.59,
        cost70: 3032.3,
        cost75: 3070.01,
        cost80: 3113.59,
        cost85: 3157.17,
        cost90: 3200.76,
      },
    },
    {
      month: '2025-05-01',
      volume: 200,
      costModelRange: {
        cost10: 2582.59,
        cost15: 2605.86,
        cost20: 2629.12,
        cost25: 2652.38,
        cost30: 2681.42,
        cost35: 2710.47,
        cost40: 2739.51,
        cost45: 2768.55,
        cost50: 2797.6,
        cost55: 2845.41,
        cost60: 2893.22,
        cost65: 2941.03,
        cost70: 2988.84,
        cost75: 3036.65,
        cost80: 3081.07,
        cost85: 3125.5,
        cost90: 3169.92,
      },
    },
    {
      month: '2025-06-01',
      volume: 100,
      costModelRange: {
        cost10: 2730.56,
        cost15: 2775.96,
        cost20: 2821.36,
        cost25: 2866.76,
        cost30: 2887,
        cost35: 2907.23,
        cost40: 2927.47,
        cost45: 2947.71,
        cost50: 2967.95,
        cost55: 3016.88,
        cost60: 3065.82,
        cost65: 3114.75,
        cost70: 3163.68,
        cost75: 3212.62,
        cost80: 3298.31,
        cost85: 3384.01,
        cost90: 3469.71,
      },
    },
    {
      month: '2025-07-01',
      volume: 50,
      costModelRange: {
        cost10: 2914.42,
        cost15: 2943.52,
        cost20: 2972.63,
        cost25: 3001.74,
        cost30: 3036.68,
        cost35: 3071.62,
        cost40: 3106.56,
        cost45: 3141.5,
        cost50: 3176.43,
        cost55: 3235.67,
        cost60: 3294.9,
        cost65: 3354.13,
        cost70: 3413.36,
        cost75: 3472.59,
        cost80: 3563.44,
        cost85: 3654.3,
        cost90: 3745.16,
      },
    },
    {
      month: '2025-08-01',
      volume: 0,
      costModelRange: {
        cost10: 2803.87,
        cost15: 2848.4,
        cost20: 2892.93,
        cost25: 2937.46,
        cost30: 2962.86,
        cost35: 2988.27,
        cost40: 3013.67,
        cost45: 3039.07,
        cost50: 3064.47,
        cost55: 3116.62,
        cost60: 3168.77,
        cost65: 3220.92,
        cost70: 3273.06,
        cost75: 3325.21,
        cost80: 3397.22,
        cost85: 3469.23,
        cost90: 3541.25,
      },
    },
  ],
  volumeWtdAvgCost: {
    cost10: 2657.14,
    cost15: 2686,
    cost20: 2714.86,
    cost25: 2743.72,
    cost30: 2774.06,
    cost35: 2804.41,
    cost40: 2834.74,
    cost45: 2865.09,
    cost50: 2895.43,
    cost55: 2942.78,
    cost60: 2990.12,
    cost65: 3037.47,
    cost70: 3084.82,
    cost75: 3132.16,
    cost80: 3191.06,
    cost85: 3249.97,
    cost90: 3308.87,
  },
}

export const NAMED_STRATEGY_BY_PERCENTILE: Record<string, string> = {
  cost20: 'Aggressive',
  cost50: 'Balanced',
  cost80: 'Conservative',
}
