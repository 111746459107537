import { ICostStrategyEnum } from '#types/rates'

export const LABEL_BY_PERCENTILE_INDEX: Record<string, string> = {
  // cost20
  2: ICostStrategyEnum.Aggressive,
  // cost50
  8: ICostStrategyEnum.Balanced,
  // cost80
  14: ICostStrategyEnum.Conservative,
}
