import React, { useCallback, useMemo, useState } from 'react'
import { PageTemplate } from 'dpl/templates'
import { getGraphqlAfterCursor } from 'dpl/utils/graphql/getGraphqlAfterCursor'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import { ArchivedContractRFPsDataGrid, IArchivedContractRFPsDataGridProps } from './components'
import {
  IArchivedContractRfpsQueryVariables,
  useArchivedContractRfpsQuery,
} from './graphql/ArchivedContractRfps'

const DEBOUNCE_MS = 250
const PAGE_SIZE = 50

export interface IArchivedContractRFPsPageProps {}

export function ArchivedContractRFPsPage() {
  const [page, setPage] = useState<number>(0)

  const [filters, setFilters] = useState<IArchivedContractRfpsQueryVariables>({
    searchTerm: null,
    organizationShipperIds: null,
    assignedToIds: null,
  })

  const paginationModel = useMemo(
    () => ({
      pageSize: PAGE_SIZE,
      page,
    }),
    [page]
  )

  const { data, loading } = useArchivedContractRfpsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ...filters,
      first: paginationModel.pageSize,
      after: getGraphqlAfterCursor({
        pageNumber: paginationModel.page,
        pageSize: paginationModel.pageSize,
      }),
    },
  })
  const { contractRfps } = data ?? {}
  const { nodes, totalCount } = contractRfps ?? {}
  const rows = compact(nodes ?? [])

  const filterOrSortChangeHandler = debounce<
    NonNullable<IArchivedContractRFPsDataGridProps['onFilterOrSortChange']>
  >(({ assignee, customer, searchTerm }) => {
    const organizationShipperIds = customer ? [customer.id] : null
    const assignedToIds = assignee ? [assignee.id] : null

    setFilters({
      searchTerm,
      organizationShipperIds,
      assignedToIds,
    })
  }, DEBOUNCE_MS)

  const paginationModelChangeHandler = useCallback<
    NonNullable<IArchivedContractRFPsDataGridProps['onPaginationModelChange']>
  >(({ page }) => {
    setPage(page)
  }, [])

  return (
    <PageTemplate
      dataTest='archived-contract-rfps-page'
      pageTitle='Archived RFPs'
      documentTitle='Archived RFPs'
      noPadding>
      <ArchivedContractRFPsDataGrid
        loading={loading}
        onFilterOrSortChange={filterOrSortChangeHandler}
        onPaginationModelChange={paginationModelChangeHandler}
        paginationModel={paginationModel}
        rows={rows}
        rowCount={totalCount}
      />
    </PageTemplate>
  )
}
