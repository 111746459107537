import React, { memo, useCallback } from 'react'
import {
  AutocompleteOrganizationUser,
  IAutocompleteOrganizationUserProps,
} from '#components/AutocompleteOrganizationUser'
import { IOrganizationUserResultFragment } from '#components/AutocompleteOrganizationUser/graphql/OrganizationUserResultFragment'
import { IDataGridFilterProps } from 'dpl/components/DataGrid'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { IDataGridFilterTeamMember } from './types'

export interface IDataGridFilterTeamMemberProps
  extends IDataGridFilterProps<IDataGridFilterTeamMember | null> {
  /**
   * @default 'DataGridFilterTeamMember'
   */
  dataTest?: string
}

export const DataGridFilterTeamMember = memo<IDataGridFilterTeamMemberProps>(
  ({ dataTest = 'DataGridFilterTeamMember', name, onChange, value: valueProp, variant }) => {
    const { isFlagEnabled } = useFlagsContext()
    const isMultipleTeamMembersEnabled = isFlagEnabled('rfp_multiple_team_members')
    const changeHandler = useCallback<NonNullable<IAutocompleteOrganizationUserProps['onChange']>>(
      (_, newValue) => {
        const { id, status, user } = newValue || {}
        const { fullName } = user || {}
        const value = newValue
          ? {
              user: {
                fullName,
              },
              status,
              id,
            }
          : null

        onChange({
          name,
          value,
        })
      },
      [name, onChange]
    )

    return (
      <div style={{ minWidth: '250px' }}>
        <AutocompleteOrganizationUser
          dataTest={dataTest}
          value={(valueProp as IOrganizationUserResultFragment) || null}
          onChange={changeHandler}
          placeholder={isMultipleTeamMembersEnabled ? 'Team Member' : 'Assignee'}
          size={variant === 'menu' ? 'medium' : 'small'}
        />
      </div>
    )
  }
)
