import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import { ToggleButton } from 'dpl'
import { Box } from 'dpl/core'
import { DownArrowIcon } from 'dpl/icons'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import startCase from 'lodash/startCase'
import { VolumePreferenceModal } from '../../../VolumePreferenceFormModal/VolumePreferenceFormModal'
import { IContractLaneInformationSchema } from '../../schema/ContractLaneInformationSchema'

interface IContractVolumeToggleProps {
  control: Control<IContractLaneInformationSchema>
}

export function ContractVolumeToggle({ control }: IContractVolumeToggleProps) {
  const { createToggleHandler, modalState } = useModalState({
    volumePreferenceModal: false,
  })
  const toggleVolumePreferenceModal = createToggleHandler('volumePreferenceModal')

  const volume = useWatch<IContractLaneInformationSchema['volume']>({
    name: 'volume',
    control,
  })

  const { volumeType } = volume || {}

  const typeLabel = volumeType && `${startCase(volumeType)} Volume`

  return (
    <Box flexGrow={1} display='flex' flexWrap='wrap' alignItems='center' gap={1}>
      <ToggleButton
        dataTest='open-volume-preference-modal'
        label={typeLabel}
        onClick={toggleVolumePreferenceModal}
        endIcon={DownArrowIcon}
        selected={false}
        value
      />
      <VolumePreferenceModal
        onSuccess={toggleVolumePreferenceModal}
        onClose={toggleVolumePreferenceModal}
        open={modalState.volumePreferenceModal}
        control={control}
      />
    </Box>
  )
}
