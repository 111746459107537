import { DEFAULT_COST_PERCENTILE, DEFAULT_MARGIN_PERCENTAGE } from '#constants/rates'
import { IEstimate } from '#types/rates'
import get from 'lodash/get'
import { TRatesResponse } from '../types'

interface IGetInitialRateEstimatesProps {
  costModel: TRatesResponse['spotCostModel']
  rateAdjustments: TRatesResponse['rateAdjustments']
}

export const getInitialRateEstimates = ({
  costModel,
  rateAdjustments,
}: IGetInitialRateEstimatesProps): IEstimate => {
  if (!costModel) {
    return {
      carrierRate: 0,
      marginPercentage: DEFAULT_MARGIN_PERCENTAGE,
      marginValue: 0,
    }
  }

  const { suggestedCostPercentile, suggestedCostShiftPercentage, suggestedMarginPremium } =
    rateAdjustments || {}
  const baseCostPercentile = suggestedCostPercentile || DEFAULT_COST_PERCENTILE
  const baseCostModelPercentile = `cost${baseCostPercentile}`
  const baseCost = get(costModel, baseCostModelPercentile, 0)

  const additiveAdjustment = baseCost * (suggestedCostShiftPercentage || 0)

  const defaultCarrierRate = baseCost + additiveAdjustment
  const defaultMarginPremium = suggestedMarginPremium || DEFAULT_MARGIN_PERCENTAGE

  return {
    carrierRate: defaultCarrierRate,
    marginPercentage: defaultMarginPremium,
    marginValue: defaultMarginPremium * defaultCarrierRate,
  }
}
