import { useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EmbeddedCreateCustomerFormSchema, TEmbeddedCreateCustomerFormSchema } from '../schema'

interface IUseEmbeddedCreateCustomerFormProps
  extends Omit<UseFormOptions<TEmbeddedCreateCustomerFormSchema>, 'resolver'> {}

export const useEmbeddedCreateCustomerForm = ({
  defaultValues = {},
  ...props
}: IUseEmbeddedCreateCustomerFormProps) => {
  return useForm<TEmbeddedCreateCustomerFormSchema>({
    resolver: yupResolver(EmbeddedCreateCustomerFormSchema),
    defaultValues: EmbeddedCreateCustomerFormSchema.cast(defaultValues),
    ...props,
  })
}
