import React, { memo } from 'react'
import { brandColors } from 'dpl'
import { AutocompleteHighlightText, Avatar } from 'dpl/components'
import { Box, makeStyles, Typography } from 'dpl/core'
import EmailIcon from 'dpl/icons/build/EmailIcon'
import { IOrganizationUserStatus } from '../../../../types/graphqlTypes'
import { IOrganizationUserResultFragment } from '../../graphql/OrganizationUserResultFragment'

export interface IAutocompleteOrganizationUserOptionProps {
  inputValue: string
  option: IOrganizationUserResultFragment
  /**
   * @default 'AutocompleteOrganizationUserOption'
   */
  dataTest?: string
}

const useAutocompleteOrganizationUserOptionStyles = makeStyles(theme => ({
  invitedIcon: {
    backgroundColor: brandColors.coolGray3,
    borderRadius: '50%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const AutocompleteOrganizationUserOption = memo<IAutocompleteOrganizationUserOptionProps>(
  ({ dataTest = 'AutocompleteOrganizationUserOption', inputValue, option }) => {
    const classes = useAutocompleteOrganizationUserOptionStyles()

    const { status, user } = option || {}
    const { firstName, lastName } = user || {}
    const fullName = [firstName, lastName].join(' ')
    return (
      <Box display='flex' gap={1.5} alignItems='center' data-test={dataTest}>
        <div>
          {status === IOrganizationUserStatus.active ? (
            <Avatar name={fullName} size='xsmall' hideTooltip dataTest={`${dataTest}-avatar`} />
          ) : (
            <div className={classes.invitedIcon}>
              <EmailIcon size='medium' color='coolGray5' data-test={`${dataTest}-pending-icon`} />
            </div>
          )}
        </div>
        <div>
          <Typography variant='body1' component='div' data-test={`${dataTest}-name`}>
            <AutocompleteHighlightText label={fullName} inputValue={inputValue} />
          </Typography>
          {status === IOrganizationUserStatus.invited && (
            <Typography
              variant='caption'
              component='div'
              color='textSecondary'
              data-test={`${dataTest}-status`}>
              Pending
            </Typography>
          )}
        </div>
      </Box>
    )
  }
)
