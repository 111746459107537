import React from 'react'
import NumericFormat from 'dpl/components/NumericFormat'
import { TextFieldGroup } from 'dpl/components/TextFieldGroup'
import { brandColors } from 'dpl/theme/colors'

export interface IMarginPremiumInputsProps {
  percentage: number
  amount: number
  onPercentageChange?: (value?: number) => void
  onAmountChange?: (value?: number) => void
  disabled?: boolean
}

export function MarginPremiumInputs({
  amount,
  disabled = false,
  onAmountChange,
  onPercentageChange,
  percentage,
}: IMarginPremiumInputsProps) {
  return (
    <TextFieldGroup
      Component={NumericFormat}
      label='Premium'
      fields={[
        {
          value: percentage * 100,
          name: 'percentage',
          allowNegative: true,
          decimalScale: 2,
          suffix: '%',
          placeholder: '%',
          allowLeadingZeros: false,
          type: 'tel',
          onValueChange: ({ floatValue }) => {
            onPercentageChange?.(floatValue)
          },
          dataTest: 'margin-percentage-input',
          disabled,
          style: { backgroundColor: brandColors.white },
        },
        {
          value: amount,
          name: 'amount',
          allowNegative: true,
          decimalScale: 2,
          prefix: '$',
          placeholder: '$',
          allowLeadingZeros: false,
          type: 'tel',
          onValueChange: ({ floatValue }) => {
            onAmountChange?.(floatValue)
          },
          dataTest: 'margin-value-input',
          fixedDecimalScale: true,
          disabled,
          style: { backgroundColor: brandColors.white },
        },
      ]}
    />
  )
}
