import { TRatesResponse } from '#routes/spot-rates-tool/types'
import { IEstimate } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { leadTimeFormatter } from '#utils/leadTimeFormatter'
import { minMaxTempFormatter } from '#utils/minMaxTempFormatter'
import { weightFormatter } from '#utils/weightFormatter'
import FORMATS from 'dpl/constants/datetime'
import moment from 'moment-timezone'

export const getSuggestedMessage = (ratesResponse: TRatesResponse, rateEstimate: IEstimate) => {
  const {
    assignmentLeadTimeMinutes,
    creationLeadTimeMinutes,
    equipmentKey,
    isTeam,
    palletCount,
    reeferTempMax,
    reeferTempMin,
    stops,
    weight,
  } = ratesResponse
  const isReefer = equipmentKey === '53_reefer'
  const TEMPLATE = [
    'Hello,',
    'Please take a look at the bid below and respond at your earliest convenience.',
    '',
    `Total Estimate: ${currencyFormatter(rateEstimate.carrierRate + rateEstimate.marginValue)}`,
    '',
    'QUOTE DETAILS',
  ]

  stops.forEach((stop, index) => {
    const {
      appointmentStartAtDatetime,
      city,
      isDropTrailer,
      postalCode,
      startTimeZone,
      stateCode,
    } = stop
    TEMPLATE.push(`Stop ${index + 1}:`)
    TEMPLATE.push(`${city}, ${stateCode} (${postalCode})`)

    if (appointmentStartAtDatetime) {
      TEMPLATE.push(
        `${moment(appointmentStartAtDatetime).tz(startTimeZone!).format(FORMATS.monthDayYearTimeFormat12hUppercase)}`
      )
    }

    if (isDropTrailer) {
      TEMPLATE.push('Drop Trailer: yes')
    }

    TEMPLATE.push('')
  })

  TEMPLATE.push(`Equipment: ${isReefer ? 'Reefer' : 'Dry Van'}`)
  TEMPLATE.push(`Weight: ${weightFormatter(weight!)}`)
  TEMPLATE.push('')

  if (creationLeadTimeMinutes) {
    TEMPLATE.push(`Creation Lead Time: ${leadTimeFormatter(creationLeadTimeMinutes)}`)
  }

  if (assignmentLeadTimeMinutes) {
    TEMPLATE.push(`Assignment Lead Time: ${leadTimeFormatter(assignmentLeadTimeMinutes)}`)
  }

  if (palletCount) {
    TEMPLATE.push(`Palletized: ${palletCount}`)
  }

  if (isReefer) {
    TEMPLATE.push(
      `Temperature: ${minMaxTempFormatter({ minTemp: reeferTempMin!, maxTemp: reeferTempMax! })}`
    )
  }

  if (isTeam) {
    TEMPLATE.push('Team Required: yes')
  }

  return TEMPLATE.join('\n')
}
