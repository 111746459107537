import { IMapPosition } from '../types'
import { getGeoJSONFeaturePositionCollection } from './getGeoJSONFeaturePositionCollection'

/**
 * Returns a GeoJSON object with coordinates
 * @example
 * ```
 * getGeoJSONSourceRaw([{ coords: { lat: 123, lon: 123 }}]) // { ... }
 * ```
 */
export function getGeoJSONPositionSource<P extends IMapPosition>(
  positions: P[]
): TrimbleMaps.GeoJSONSourceRaw {
  return {
    type: 'geojson',
    data: getGeoJSONFeaturePositionCollection(positions),
  }
}
