import React from 'react'
import { FormModal, IModalAction, IModalBaseProps } from 'dpl/components/Modals'
import Typography from 'dpl/core/Typography'
import { DefaultRateSettingInfo } from '../../DefaultRateSettingInfo'

interface IRateSettingModal extends Pick<IModalBaseProps, 'onClose' | 'open'> {
  /**
   * Callback used when user confirms generating rates for lanes.
   */
  onConfirm: () => void
  currentRoundNumber: number
}

export function RateSettingModal({
  currentRoundNumber,
  onClose,
  onConfirm,
  open,
}: IRateSettingModal) {
  const actions: IModalAction[] = [
    {
      label: 'Close',
      action: onClose,
      ButtonProps: {
        variant: 'text',
      },
    },
    {
      label: 'Generate rate for all lanes',
      action() {
        onConfirm()
        onClose()
      },
      ButtonProps: {
        variant: 'contained',
      },
    },
  ]

  return (
    <FormModal maxWidth='xs' onClose={onClose} title='Rate Settings' open={open} actions={actions}>
      <Typography variant='body2'>
        Generate new rates for all lanes within Round {currentRoundNumber}, overwriting all existing
        rates. Rates will be generated using default settings:
      </Typography>
      <DefaultRateSettingInfo />
    </FormModal>
  )
}
