import * as yup from 'yup'

export const AutocompleteOrganizationCarrierUserEmailSchema = yup.object({
  inputValue: yup.string().email().optional(),
  value: yup
    .object({
      id: yup.string(),
      consumerUser: yup.object({
        id: yup.string(),
        user: yup.object({
          id: yup.string(),
          emailAddress: yup.string(),
        }),
      }),
    })
    .optional()
    .nullable(),
})

export type TAutocompleteOrganizationCarrierUserEmailSchema = yup.InferType<
  typeof AutocompleteOrganizationCarrierUserEmailSchema
>
