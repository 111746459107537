import React, { memo } from 'react'
import { AccessRestrictedModal, FeatureUnavailableModal } from 'dpl/components/Modals'
import { DeactivateCustomerModal } from '../../DeactivateCustomerModal'
import { useCustomersActions } from '../hooks/useCustomerStatusAction'
import { useDeactivateCustomerModal } from '../hooks/useDeactivateCustomerModal'

export const DeactivateCustomer = memo(() => {
  const { closeModal, deactivateModalStates } = useDeactivateCustomerModal()

  const { deactivateCustomerHandler, isCustomerAssignedRG, isRGCountLoading } =
    useCustomersActions()

  const confirmDeactivationHandler = async () => {
    await deactivateCustomerHandler()
    closeModal()
  }

  return (
    <div>
      <DeactivateCustomerModal
        open={deactivateModalStates.isModalOpen}
        onClose={closeModal}
        isAssigned={isCustomerAssignedRG}
        onConfirm={confirmDeactivationHandler}
        isLoading={isRGCountLoading}
      />
      <AccessRestrictedModal
        open={deactivateModalStates.isAccessRestrictedModalOpen}
        onClose={closeModal}
      />
      <FeatureUnavailableModal
        open={deactivateModalStates.isFeatureUnavailableModalOpen}
        onClose={closeModal}
      />
    </div>
  )
})
