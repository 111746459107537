import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { IAutocompleteLocationBaseResult } from 'dpl/components/AutocompleteLocationBase/types'
import { AutocompleteState, IAutocompleteStateProps } from '../AutocompleteState/AutocompleteState'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IAutocompleteStateProps>,
  IAutocompleteLocationBaseResult
>

export interface IControlledAutocompleteStateProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteLocationProps?: Omit<IAutocompleteStateProps, 'onChange' | 'value'>
  onChange?: (e: React.SyntheticEvent, value: IAutocompleteStateProps['value']) => void
}

export const ControlledAutocompleteState = memo<IControlledAutocompleteStateProps>(
  ({ AutocompleteLocationProps, control, defaultValue, name, onChange: onChangeProp }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          const changeHandler: IAutocompleteStateProps['onChange'] = (e, newValue) => {
            onChange(newValue)
            if (onChangeProp) onChangeProp(e, value)
          }

          return (
            <AutocompleteState
              {...AutocompleteLocationProps}
              onChange={changeHandler}
              value={value}
            />
          )
        }}
      />
    )
  }
)
