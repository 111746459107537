interface IGetIsMarginPremiumLimitedArgs {
  rawMarginPremium: Nullable<number>
  suggestedMarginPremium: Nullable<number>
}
export const getIsMarginPremiumLimited = ({
  rawMarginPremium,
  suggestedMarginPremium,
}: IGetIsMarginPremiumLimitedArgs) => {
  if (!rawMarginPremium || !suggestedMarginPremium) return false
  return rawMarginPremium > suggestedMarginPremium
}
