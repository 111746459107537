import React from 'react'
import { IContractRfpUserAssignment, IOrganizationUser, IUser } from '#types/graphqlTypes'
import { Avatar } from 'dpl'
import { AvatarGroup } from 'dpl/core'
import startCase from 'lodash/startCase'

export interface IUserAssignmentsAvatarGroupProps {
  userAssignments: (Pick<IContractRfpUserAssignment, 'id' | 'role'> & {
    organizationUser: Pick<IOrganizationUser, 'id'> & { user: Pick<IUser, 'id' | 'fullName'> }
  })[]
}

export function UserAssignmentsAvatarGroup({ userAssignments }: IUserAssignmentsAvatarGroupProps) {
  return (
    <AvatarGroup>
      {userAssignments.map(
        ({
          id,
          organizationUser: {
            user: { fullName },
          },
          role,
        }) => {
          return (
            <Avatar
              key={id}
              details={startCase(role)}
              name={fullName ?? ''}
              size='xsmall'
              data-test='shown-app-admin'
            />
          )
        }
      )}
    </AvatarGroup>
  )
}
