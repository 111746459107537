import { IRuleTypeEnum } from '../../../types/rates'
import { IRule } from '../types'

interface IGetIsCurrentValueDefaultSettingArgs {
  appliedRules: Nullable<IRule[]>
  defaultValue: Nullable<number>
  currentValue: Nullable<number>
}

/**
 * The current value is the default setting if they are equal and there are no applied base rules.
 */
export const getIsCurrentValueDefaultSetting = ({
  appliedRules,
  currentValue,
  defaultValue,
}: IGetIsCurrentValueDefaultSettingArgs) => {
  if (!defaultValue || !currentValue) return false
  const baseRules =
    appliedRules && appliedRules?.some(rule => rule?.ruleAction === IRuleTypeEnum.base)
  return defaultValue === currentValue && !baseRules
}
