import React, { memo } from 'react'
import { Stack, Typography } from 'dpl/core'
import { TIconColor } from 'dpl/icons/components/SvgIcon/types'
import { TIconComponent } from 'dpl/icons/types'

export interface IAdjustmentRuleOverflowMenuItemProps {
  /**
   * The color of the icon and label
   */
  color?: TIconColor
  /**
   * @default 'AdjustmentRuleOverflowMenuItem'
   */
  dataTest?: string
  /**
   * The icon component to render
   */
  icon: TIconComponent
  /**
   * The label to display
   */
  label?: string
}

export const AdjustmentRuleOverflowMenuItem = memo<IAdjustmentRuleOverflowMenuItemProps>(
  ({ color, dataTest = 'AdjustmentRuleOverflowMenuItem', icon: IconComponent, label }) => {
    return (
      <Stack alignItems='center' direction='row' gap={1.5} data-test={dataTest}>
        <IconComponent size='large' color={color} />
        <Typography variant='body2' color={color}>
          {label}
        </Typography>
      </Stack>
    )
  }
)
