import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import { useFlagsContext } from 'flags/src/FlagsContext'

export interface IUseRGLaneCreateModalProps {}

export interface IUseRGLaneCreateModalState {
  isCreateModalOpen: boolean
  isAccessRestrictedModalOpen: boolean
  isFeatureUnavailableModalOpen: boolean
  openCreateModal: () => void
  closeCreateModal: () => void
}

export const useRGLaneCreateModal = (): IUseRGLaneCreateModalState => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isPathnameRoutingGuideLaneCreate = useMemo(
    () => pathname === PROTECTED_PATHS.routingGuideLaneCreate,
    [pathname]
  )

  const { isFlagEnabled } = useFlagsContext()
  const isRoutingGuideLaneCreateEnabled = useMemo(
    () => isFlagEnabled('routing_guide_lane_create'),
    [isFlagEnabled]
  )

  const { userPermissions } = usePermissionsContext()
  const isPermittedToCreateRGLane = useMemo(
    () => userPermissions['routing_guide.create_lane'],
    [userPermissions]
  )

  const modalStates: Pick<
    IUseRGLaneCreateModalState,
    'isCreateModalOpen' | 'isAccessRestrictedModalOpen' | 'isFeatureUnavailableModalOpen'
  > = useMemo(() => {
    if (!isPathnameRoutingGuideLaneCreate) {
      return {
        isCreateModalOpen: false,
        isAccessRestrictedModalOpen: false,
        isFeatureUnavailableModalOpen: false,
      }
    }

    if (!isRoutingGuideLaneCreateEnabled) {
      return {
        isCreateModalOpen: false,
        isAccessRestrictedModalOpen: false,
        isFeatureUnavailableModalOpen: true,
      }
    }

    if (!isPermittedToCreateRGLane) {
      return {
        isCreateModalOpen: false,
        isAccessRestrictedModalOpen: true,
        isFeatureUnavailableModalOpen: false,
      }
    }

    return {
      isCreateModalOpen: true,
      isAccessRestrictedModalOpen: false,
      isFeatureUnavailableModalOpen: false,
    }
  }, [isPathnameRoutingGuideLaneCreate, isPermittedToCreateRGLane, isRoutingGuideLaneCreateEnabled])

  const openCreateModal = useCallback(() => {
    navigate(PROTECTED_PATHS.routingGuideLaneCreate)
  }, [navigate])

  const closeCreateModal = useCallback(() => {
    navigate(PROTECTED_PATHS.routingGuideLanes)
  }, [navigate])

  return {
    ...modalStates,
    openCreateModal,
    closeCreateModal,
  }
}
