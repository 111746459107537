import React from 'react'
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom'
import { useRoutes } from '#hooks/useRoutes'
import { useAuthMemberSession } from 'auth/b2b/hooks'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import { Spinner } from 'dpl/components/Spinner'
import { useUserDetailsContext } from '../context/UserDetailsContext'

export function Router() {
  const { session } = useAuthMemberSession()
  const { userPermissions } = usePermissionsContext()
  const { activeProductModules, hasLoaded } = useUserDetailsContext()
  const { routes } = useRoutes({
    activeProductModules,
    isSessionActive: Boolean(session),
    userPermissions,
  })
  const createRouter = import.meta.env.MODE === 'branch' ? createHashRouter : createBrowserRouter

  return Boolean(session) && !hasLoaded ? (
    <Spinner />
  ) : (
    <RouterProvider router={createRouter(routes)} />
  )
}
