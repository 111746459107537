import React from 'react'
import { AutocompleteHighlightText } from 'dpl/components/Autocomplete'
import { Stack, Typography } from 'dpl/core'
import { IAutocompleteRfpFragment } from './graphql/AutocompleteRfp'

export interface RfpOptionProps {
  inputValue: string
  option: IAutocompleteRfpFragment
  /**
   * @default 'AutocompleteOrganizationCarrierOption'
   */
  dataTest?: string
}

export function RfpOption({ dataTest = 'rfp-option', inputValue, option }: RfpOptionProps) {
  const { displayId, name, organizationShipper } = option || {}

  return (
    <Stack data-test={dataTest} gap={0.25}>
      <Typography component='div' variant='body1'>
        <AutocompleteHighlightText label={`${name}`} inputValue={inputValue} />
      </Typography>
      <Typography component='div' variant='caption' color='textSecondary'>
        <AutocompleteHighlightText
          label={`RFP-${displayId} • ${organizationShipper?.name}`}
          inputValue={inputValue}
        />
      </Typography>
    </Stack>
  )
}
