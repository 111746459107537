import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, Controller, useWatch, useFieldArray, FormProvider } from 'react-hook-form'
import { ControlledPillSelectSearchableOrganizationShipper } from '#components/ControlledPillSelectSearchableOrganizationShipper'
import { RatesSegmentedToggle } from '#components/RatesSegmentedToggle'
import { IFetchRatesInput } from '#types/graphqlTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import DraggableList, { DraggableListItem } from 'dpl/components/DraggableList'
import NumericFormat from 'dpl/components/NumericFormat'
import { TextField } from 'dpl/components/TextField'
import { Box, Grid, Button, MenuItem, makeStyles, Typography } from 'dpl/core'
import { DatePickersProvider } from 'dpl/providers'
import { brandColors } from 'dpl/theme/colors'
import { useFlagsContext } from 'flags'
import { AdvancedInputs } from './AdvancedInputs'
import { EquipmentInputMenu } from './EquipmentInputMenu'
import { StopApptInputRow } from './StopApptInputRow'
import { StopDragPreview } from './StopDragPreview'
import { WeightInputMenu } from './WeightInputMenu'
import { laneInformationFormSchema, LaneInformationFormSchema } from './laneInformationFormSchema'
import { IStopSchema } from './laneInformationFormSchema/laneInformationFormSchema'
import { mapSchemaToInput } from './mapSchemaToInput'

const EQUIPMENT_TYPES = [
  {
    label: 'Dry Van',
    value: '53_van',
  },
  {
    label: 'Reefer',
    value: '53_reefer',
  },
]

const FORM_DEFAULT_VALUES: LaneInformationFormSchema = {
  equipmentKey: '53_van',
  weight: null,
  customer: null,
  advancedInputs: {
    palletCount: null,
    palletSwitch: false,
    teamRequired: null,
    dropTrailerPickup: null,
    dropTrailerDelivery: null,
    leadTimeSwitch: false,
    creationLeadTime: null,
    assignmentLeadTime: null,
  },
  temperature: {
    min: null,
    max: null,
  },
  stops: [
    {
      location: null,
      type: 'pickup',
    },
    {
      location: null,
      type: 'delivery',
    },
  ],
}

/**
 * Magic number to match spec 🪄✨
 */
const INPUT_MAX_WIDTH = '112px'

interface LaneInformationFormProps {
  onSubmit: (formData: IFetchRatesInput) => void
}

const useLaneInformationFormStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('md')]: {
      border: 'none',
      boxShadow: 'none',
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(4),
      borderBottom: `1px solid ${brandColors.coolGray3}`,
      '& .MuiCardHeader-root, & .MuiCardContent-root': {
        padding: 0,
      },
    },
    marginBottom: theme.spacing(6),
  },
  stopAppt: {
    display: 'flex',
    gap: theme.spacing(2),
    flex: 1,
    flexDirection: 'column',
    '& > div': {
      flex: 1,
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  swapButton: {
    backgroundColor: brandColors.coolGray1,
    color: brandColors.coolGray5,
    ' & svg': {
      transform: 'rotate(90deg)',
    },
  },
  draggableStop: {
    // Targets drag icon
    '& > div:first-of-type': {
      [theme.breakpoints.down('md')]: {
        background: brandColors.coolGray1,
      },
    },
  },
}))

export function LaneInformationForm({ onSubmit }: LaneInformationFormProps) {
  const { isFlagEnabled } = useFlagsContext()
  const isUiImproveEnabled = isFlagEnabled('rates_ui_improvements')
  const isRatesControlPanelRulesEnabled = isFlagEnabled('spot_rates_control_panel_rules')

  const classes = useLaneInformationFormStyles()

  const methods = useForm<LaneInformationFormSchema>({
    resolver: yupResolver(laneInformationFormSchema),
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldUnregister: false, // Need to maintain input state even when getting unmounted as stops are dragged and dropped
  })

  const { control, errors, getValues, handleSubmit, register, reset, setValue, trigger, watch } =
    methods || {}

  const {
    fields: stopFields,
    insert: insertStop,
    move: moveStops,
    remove: removeStop,
  } = useFieldArray<IStopSchema, 'rhfId'>({
    control,
    name: 'stops',
    keyName: 'rhfId',
  })
  const stopFieldsLength = stopFields?.length ?? 0

  const watchedStops = watch('stops', FORM_DEFAULT_VALUES.stops)
  const firstStopType = watchedStops?.[0]?.type
  const lastStopType = watchedStops?.[stopFieldsLength - 1]?.type

  const addStopHandler = useCallback(() => {
    const index = stopFieldsLength - 1
    insertStop(index, {
      location: null,
      type: 'pickup',
    })
  }, [insertStop, stopFieldsLength])

  const removeStopHandler = useCallback(
    index => {
      if (index === stopFieldsLength - 1) {
        // If removing the last stop, make sure the one before it is a delivery stop
        setValue(`stops[${index - 1}].type`, 'delivery')
      }
      removeStop(index)
    },
    [removeStop, setValue, stopFieldsLength]
  )

  const updateAdvancedInputs = (advancedInputs: LaneInformationFormSchema['advancedInputs']) => {
    setValue('advancedInputs', advancedInputs)
  }

  const generateHandler = useCallback(() => {
    handleSubmit(data => {
      onSubmit(mapSchemaToInput({ formData: data }))
    })()
  }, [handleSubmit, onSubmit])

  const { equipmentKey, temperature } = useWatch<
    Pick<LaneInformationFormSchema, 'equipmentKey' | 'temperature'>
  >({
    control,
    name: ['equipmentKey', 'origin', 'destination', 'temperature'],
    defaultValue: FORM_DEFAULT_VALUES,
  })

  const swapStopsHandler = useCallback(() => {
    const { stops } = getValues()
    const [{ location: origin }, { location: destination }] = stops || []

    setValue('stops[0].location', destination, { shouldValidate: true })
    setValue('stops[1].location', origin, { shouldValidate: true })
  }, [getValues, setValue])

  /**
   * This handles drag and drop updates
   */
  const moveStopRowHandler = useCallback(
    ({ sourceIndex, targetIndex }) => {
      moveStops(sourceIndex, targetIndex)
      trigger('stops')
    },
    [moveStops, trigger]
  )

  /**
   * Ensures first and last stop are always the correct type
   */
  useEffect(() => {
    const lastFieldIndex = stopFieldsLength - 1
    setValue('stops[0].type', 'pickup')
    setValue(`stops[${lastFieldIndex}].type`, 'delivery')
  }, [firstStopType, lastStopType, stopFieldsLength, setValue])

  const stopFieldsLabels = useMemo(() => {
    let pickupCount = 0
    let deliveryCount = 0
    const labels =
      watchedStops?.map(stop => {
        const { type } = stop || {}
        if (type === 'delivery') {
          deliveryCount += 1
          return `Delivery ${deliveryCount}`
        }
        pickupCount += 1
        return `Pickup ${pickupCount}`
      }) || []
    if (pickupCount === 1) labels[0] = 'Origin'
    if (deliveryCount === 1) labels[labels.length - 1] = 'Destination'
    return labels
  }, [watchedStops])

  const draggableListItems = useMemo(() => {
    return stopFields.map(field => ({ ...field, id: field.rhfId || '' }))
  }, [stopFields])

  return (
    <FormProvider {...methods}>
      <DatePickersProvider>
        <Box
          display='flex'
          alignItems='center'
          gap={2}
          justifyContent='space-between'
          marginBottom={1.5}>
          <Box display='flex' alignItems='center' gap={2.5}>
            <Typography variant='h4'>Lane Information</Typography>
            <RatesSegmentedToggle currentTool='spotRatesTool' />
          </Box>
          <Button
            variant='text'
            onClick={() => reset(FORM_DEFAULT_VALUES)}
            data-test='clear-all-button'>
            Clear All
          </Button>
        </Box>
        {/* we have to register() in order to set them via setValue */}
        <input type='hidden' name='advancedInputs.teamRequired' ref={register()} />
        <input type='hidden' name='advancedInputs.dropTrailerPickup' ref={register()} />
        <input type='hidden' name='advancedInputs.dropTrailerDelivery' ref={register()} />
        <Box display='flex' flexDirection='column' gap={2} marginBottom={6}>
          <DraggableList onDrop={moveStopRowHandler} items={draggableListItems}>
            {draggableListItems.map((stop, index) => {
              const { id } = stop || {}
              const label = stopFieldsLabels[index]
              const currentStopValues = watchedStops?.[index]
              return (
                <DraggableListItem
                  key={id}
                  item={stop}
                  index={index}
                  className={classes.draggableStop}
                  DraggableListItemPreview={
                    <StopDragPreview location={currentStopValues?.location} label={label} />
                  }>
                  <StopApptInputRow
                    stopField={stop}
                    index={index}
                    stopFieldsLength={stopFieldsLength}
                    onSwapStops={swapStopsHandler}
                    onAddStop={addStopHandler}
                    onRemoveStop={removeStopHandler}
                    stoplabel={label}
                    currentStopValues={currentStopValues}
                  />
                </DraggableListItem>
              )
            })}
          </DraggableList>
          {!isUiImproveEnabled && (
            <Grid container spacing={2}>
              <Grid item md={2} xs={5}>
                <Controller
                  control={control}
                  name='equipmentKey'
                  render={({ onChange, value }) => {
                    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value === '53_van') {
                        setValue('temperature', {
                          max: null,
                          min: null,
                        })
                      }
                      onChange(event)
                    }
                    return (
                      <TextField
                        select
                        label='Equipment'
                        value={value}
                        onChange={onChangeHandler}
                        error={Boolean(errors?.equipmentKey)}
                        helperText={errors?.equipmentKey?.message}
                        dataTest='equipment-select'>
                        {EQUIPMENT_TYPES.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                md='auto'
                xs={12}
                columnGap={1.5}
                display={equipmentKey === '53_reefer' ? 'flex' : 'none'}>
                <Box maxWidth={INPUT_MAX_WIDTH} flexShrink={0} flexGrow={0}>
                  <Controller
                    control={control}
                    name='temperature.min'
                    defaultValue=''
                    render={({ onChange, value }) => (
                      <NumericFormat
                        value={value ?? ''}
                        placeholder='45°'
                        label='Min °F Degrees'
                        suffix='°'
                        type='tel'
                        error={Boolean(errors.temperature?.min)}
                        decimalScale={0}
                        allowNegative
                        onValueChange={({ floatValue }) => {
                          onChange(floatValue)
                          trigger('temperature.max')
                        }}
                        helperText={errors.temperature?.min?.message}
                        InputLabelProps={{ shrink: true }}
                        data-test='min-temp-input'
                      />
                    )}
                  />
                </Box>
                <Box maxWidth={INPUT_MAX_WIDTH} flexShrink={0} flexGrow={0}>
                  <Controller
                    control={control}
                    name='temperature.max'
                    defaultValue=''
                    render={({ onChange, value }) => (
                      <NumericFormat
                        value={value ?? ''}
                        placeholder='45°'
                        label='Max °F Degrees'
                        suffix='°'
                        type='tel'
                        error={Boolean(errors.temperature?.max)}
                        decimalScale={0}
                        allowNegative
                        onValueChange={({ floatValue }) => {
                          onChange(floatValue)
                          trigger('temperature.min')
                        }}
                        helperText={errors.temperature?.max?.message}
                        InputLabelProps={{ shrink: true }}
                        data-test='max-temp-input'
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={2} xs={5}>
                <Controller
                  control={control}
                  name='weight'
                  defaultValue=''
                  render={({ onChange, value }) => (
                    <NumericFormat
                      value={value ?? ''}
                      placeholder='35,000 lbs'
                      label='Weight'
                      thousandSeparator
                      suffix=' lbs'
                      type='tel'
                      decimalScale={0}
                      error={Boolean(errors?.weight)}
                      helperText={errors?.weight?.message}
                      allowNegative={false}
                      onValueChange={({ floatValue }) => {
                        onChange(floatValue)
                      }}
                      InputLabelProps={{ shrink: true }}
                      data-test='weight-input'
                    />
                  )}
                />
              </Grid>

              <Grid item xs>
                <Box height='56px' display='flex' alignItems='center'>
                  <AdvancedInputs control={control} updateAdvancedInputs={updateAdvancedInputs} />
                </Box>
              </Grid>
              <Grid item md='auto' xs={12} minWidth={150}>
                <Button fullWidth onClick={generateHandler} data-test='generate-button'>
                  Generate
                </Button>
              </Grid>
            </Grid>
          )}
          {isUiImproveEnabled && (
            <Grid container spacing={2} wrap='wrap' alignItems='center'>
              <Grid
                container
                item
                columnSpacing={2}
                rowSpacing={1}
                alignItems='center'
                xs={12}
                sm={12}
                md>
                <Grid item xs='auto'>
                  <Controller
                    control={control}
                    name='equipmentKey'
                    render={({ onChange, value }) => (
                      <EquipmentInputMenu
                        equipmentKey={value}
                        temperature={temperature}
                        onChange={({ equipmentKey, maxTemp, minTemp }) => {
                          setValue('temperature.min', minTemp)
                          setValue('temperature.max', maxTemp)
                          onChange(equipmentKey)
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs='auto'>
                  <Controller
                    control={control}
                    name='weight'
                    render={({ onChange, value }) => (
                      <WeightInputMenu
                        weight={value}
                        onChange={weight => {
                          onChange(weight)
                        }}
                      />
                    )}
                  />
                </Grid>
                {isRatesControlPanelRulesEnabled && (
                  <Grid item xs='auto'>
                    <ControlledPillSelectSearchableOrganizationShipper
                      control={control}
                      name='customer'
                    />
                  </Grid>
                )}
                <Grid item xs='auto'>
                  <AdvancedInputs control={control} updateAdvancedInputs={updateAdvancedInputs} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={2} minWidth={168}>
                <Button fullWidth onClick={generateHandler} data-test='generate-button'>
                  Generate
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </DatePickersProvider>
    </FormProvider>
  )
}
