import { TAutocompleteLocationSchema } from '#components/ControlledAutocompleteLocation/schema'
import * as yup from 'yup'

export function stopLocationNotNullValidation(
  value: TAutocompleteLocationSchema | null,
  context: yup.TestContext
) {
  const {
    createError,
    from,
    // @ts-ignore index is missing in yup.TestContext type
    options: { index },
  } = context || {}

  const stops = from?.find(ancestor => !!ancestor?.value?.stops)?.value?.stops // gets stops from ancestor tree
  const stopsLength = stops?.length ?? 2
  const isNull = !!(value === null)
  // if the location is not null then field is validated
  if (!isNull) return true

  // if the location value is null and index is 0 specify they select an origin
  if (index === 0) {
    return createError({
      message: 'You must select an origin.',
    })
  }
  // if the location value is null and index is the last item in stops array specify they select a destination
  if (index === stopsLength - 1) {
    return createError({
      message: 'You must select a destination.',
    })
  }
  // if the location value is null and it is not the origin or destination stop, specify they enter a location or remove multistop
  return createError({
    message: 'You must select a location.',
  })
}
