import { IAdjustmentRulesTabProps } from '../../components/AdjustmentRulesTab'
import { ISpotRatesAdjustmentRulesQueryVariables } from '../../graphql/SpotRatesAdjustmentRules'

type IGetSpotRatesAdjustmentRulesQueryVariablesProps = Parameters<
  NonNullable<IAdjustmentRulesTabProps['onFilterOrSortChange']>
>

export const getSpotRatesAdjustmentRulesQueryVariables = (
  params: IGetSpotRatesAdjustmentRulesQueryVariablesProps[0]
): ISpotRatesAdjustmentRulesQueryVariables => {
  const { createdBy, customer, delivery, pickup, ruleType, sort } = params || {}
  const { id: createdByUserId } = createdBy || {}
  const { id: customerId } = customer || {}
  const { value: pickupValue } = pickup || {}
  const { value: deliveryValue } = delivery || {}

  return {
    pickupLocation: pickupValue || null,
    deliveryLocation: deliveryValue || null,
    ruleAction: ruleType || null,
    ordering: {
      sortBy: 'reason',
      sortOrder: sort,
    },
    createdByOrganizationUserId: createdByUserId,
    organizationShipperId: customerId,
  }
}
