import React from 'react'
import { brandColors, RadialSpinner } from 'dpl'
import { Box, makeStyles, Typography } from 'dpl/core'

const useImportRFPSpinnerStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    marginTop: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
}))

export interface IImportRFPSpinnerProps {
  isImporting: boolean
}

export function ImportRFPSpinner({ isImporting }: IImportRFPSpinnerProps) {
  const classes = useImportRFPSpinnerStyles()

  return (
    <Box className={classes.container}>
      <RadialSpinner position='relative' size='large' />
      {isImporting && (
        <>
          <Typography className={classes.headline} variant='h3'>
            Importing RFP
          </Typography>
          <Typography
            className={classes.subtitle}
            variant='subtitle1'
            color={brandColors.coolGray5}>
            We&apos;re setting up the lanes from your RFP...
          </Typography>
        </>
      )}
    </Box>
  )
}
