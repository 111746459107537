import { REQUIREMENT_KEYS, EPasswordStrengthLevel, TPasswordValidationState } from '../types'

/**
 * Returns the strength level to be indicated in the strength meter.
 * Level can only reach 'Good' or 'Great' if Stytch score is above 3 and all
 * other requirements are met.
 * @example Weak
 */
export const getPasswordStrengthLevel = (
  validationState: TPasswordValidationState,
  score?: number
): EPasswordStrengthLevel | undefined => {
  const numRequirementsMet = REQUIREMENT_KEYS.filter(key => !!validationState[key])?.length || 0
  if (score === undefined) return undefined
  if (numRequirementsMet <= 1) {
    return EPasswordStrengthLevel.weak
  }
  if (numRequirementsMet <= 4) {
    return EPasswordStrengthLevel.fair
  }
  if (score === 3) {
    return EPasswordStrengthLevel.good
  }
  // score === 4
  return EPasswordStrengthLevel.great
}
