import React, { memo, useCallback, useMemo } from 'react'
import { ISettingVersion } from '#types/graphqlTypes'
import { FormattedDateTime, IconButton } from 'dpl/components'
import { Box, Card, CardContent, makeStyles, Skeleton, Typography } from 'dpl/core'
import { EditIcon } from 'dpl/icons'
import { useBreakpointContext } from 'dpl/providers'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { MarginSettingsModal } from '../MarginSettingsModal'

export interface IMarginSettingsCardProps {
  /**
   * The default margin premium to be displayed in the card
   */
  defaultMarginPremium: ISettingVersion['defaultMarginPremium']
  /**
   * The max margin premium to be displayed in the card
   */
  maxMarginPremium: ISettingVersion['maxMarginPremium']
  /**
   * The last updated date to be displayed in the card
   */
  updatedAt: string
  /**
   * If true, loading skeleton is shown
   * @default false
   */
  isLoading?: boolean
  /**
   * @default 'MarginSettingsCard'
   */
  dataTest?: string
  /**
   * If true, edit button is shown
   */
  canEdit: boolean
}

const useMarginSettingsCardStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
  },
  cardContent: {
    padding: `${theme.spacing(2)} !important`,
  },
  lastUpdatedText: {
    display: 'flex',
    gap: theme.spacing(0.25),
  },
  editButton: {
    color: theme.palette.primary.main,
    marginTop: `-${theme.spacing(0.5)}`,
    marginBottom: `-${theme.spacing(0.5)}`,
    marginRight: `-${theme.spacing(1)}`,
  },
}))

export const MarginSettingsCard = memo<IMarginSettingsCardProps>(
  ({
    canEdit,
    dataTest = 'MarginSettingsCard',
    defaultMarginPremium,
    isLoading = false,
    maxMarginPremium,
    updatedAt,
  }) => {
    const classes = useMarginSettingsCardStyles()
    const { isTablet } = useBreakpointContext()

    const { modalState, toggleModal } = useModalState({
      marginSettingsModal: false,
    })
    const { marginSettingsModal: isMarginSettingsModalOpen } = modalState || {}
    const toggleModalHandler = useCallback(() => {
      toggleModal('marginSettingsModal')
    }, [toggleModal])

    const premiumLabel = useMemo(() => {
      if (defaultMarginPremium) {
        return `${(defaultMarginPremium * 100).toFixed(0)}% Premium`
      }
      return ''
    }, [defaultMarginPremium])

    const maxMarginLimitLabel = useMemo(() => {
      if (maxMarginPremium) {
        return `(${(maxMarginPremium * 100).toFixed(0)}% Max Margin Limit)`
      }
      return ''
    }, [maxMarginPremium])

    return (
      <>
        <Card data-test={dataTest} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box display='flex' flexDirection='column' gap={1}>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='subtitle1' component='div'>
                  Default Margin Base
                </Typography>
                <Box display='flex' alignItems='center' gap={0.5}>
                  {!isTablet && (
                    <Typography
                      variant='caption'
                      component='div'
                      color='textSecondary'
                      className={classes.lastUpdatedText}>
                      <span>Last updated: </span>
                      {isLoading ? (
                        <Skeleton variant='text' width='50px' component='span' />
                      ) : (
                        <FormattedDateTime
                          datetime={updatedAt || ''}
                          format='dateAndYearFullDateAlways'
                        />
                      )}
                    </Typography>
                  )}
                  {canEdit && (
                    <IconButton
                      dataTest={`${dataTest}-edit-button`}
                      Icon={EditIcon}
                      color='secondary'
                      className={classes.editButton}
                      onClick={toggleModalHandler}
                    />
                  )}
                </Box>
              </Box>
              {isLoading ? (
                <Skeleton variant='rectangular' height='24px' width='130px' />
              ) : (
                <Box display='flex' alignItems='baseline' gap={0.5} flexWrap='wrap'>
                  <Typography variant='h4' component='div'>
                    {premiumLabel}
                  </Typography>
                  <Typography variant='subtitle2'>{maxMarginLimitLabel}</Typography>
                </Box>
              )}
              {isTablet && (
                <Typography
                  variant='caption'
                  component='div'
                  color='textSecondary'
                  className={classes.lastUpdatedText}>
                  <span>Last updated: </span>
                  {isLoading ? (
                    <Skeleton variant='text' width='50px' component='span' />
                  ) : (
                    <FormattedDateTime
                      datetime={updatedAt || ''}
                      format='dateAndYearFullDateAlways'
                    />
                  )}
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
        {isMarginSettingsModalOpen && defaultMarginPremium && (
          <MarginSettingsModal
            dataTest={`${dataTest}-modal`}
            defaultMarginPremium={defaultMarginPremium}
            maxMarginPremium={maxMarginPremium}
            onClose={toggleModalHandler}
          />
        )}
      </>
    )
  }
)
