import { COUNTRY_LABELS } from '../constants'
import { ICountryOption, IGeocodingAllowedCountryEnum } from '../types'

export const getCountryOptions = (
  countryCodes: IGeocodingAllowedCountryEnum[]
): ICountryOption[] => {
  return countryCodes.map(code => ({
    label: COUNTRY_LABELS[code],
    code,
  }))
}
