import * as yup from 'yup'
import { ContractAdvancedInputsSchema } from './ContractAdvancedInputsSchema'
import { ContractDurationSchema } from './ContractDurationSchema'
import { StopSchema } from './StopSchema'
import { VolumeSchema } from './VolumeSchema'

function checkMinPresent(this: yup.TestContext) {
  const { parent } = this
  const { max = null, min = null } = parent

  if (max === null) return true

  return !(Number.isInteger(max) && min === null)
}

function checkMaxPresent(this: yup.TestContext) {
  const { parent } = this
  const { max = null, min = null } = parent

  if (min === null) return true

  return !(Number.isInteger(min) && max === null)
}

function checkMinLessThanMax(this: yup.TestContext) {
  const { parent } = this
  const { max = null, min = null } = parent

  // avoid validation if there's no sufficient info
  if (min === null || max === null) return true

  return min <= max
}

const emptyStringToNull = (value: string, originalValue: string | boolean) => {
  if (originalValue === '' || originalValue === false) {
    return null
  }
  return value
}

export const ContractLaneInformationSchema = yup.object({
  /**
   * Stops is an array of stop locations and type for the lane.
   */
  stops: yup.array(StopSchema).required('You must select a location.'),
  /**
   * Equipment type for the lane.
   */
  equipmentKey: yup.string().default('53_van').required('You must select an equipment type.'),
  /**
   * Temperature of Reefer Truck - only needed if Equipment is reefer.
   */
  temperature: yup
    .object({
      min: yup
        .number()
        .integer()
        .moreThan(-51, 'Min values cannot be below -50°.')
        .test('is-min-set', 'You must enter a Min °F Degree.', checkMinPresent)
        .test(
          'is-min-less-than-max',
          'Min °F Degree amount cannot exceed Max °F Degree.',
          checkMinLessThanMax
        )
        .transform(emptyStringToNull)
        .nullable(),
      max: yup
        .number()
        .integer()
        .lessThan(76, 'Max values cannot exceed 75°.')
        .test('is-max-set', 'You must enter a Max °F Degree.', checkMaxPresent)
        .test('is-min-less-than-max', '', checkMinLessThanMax)
        .transform(emptyStringToNull)
        .nullable(),
    })
    .optional(),
  /**
   * Selected dates and duration for the contract.
   */
  contractDuration: ContractDurationSchema.required('You must select a contract duration.'),
  /**
   * Selected volume type and volume amounts for the contract duration.
   */
  volume: VolumeSchema.required('You must select a volume type.'),
  /**
   * The additional input for the lane.
   */
  contractAdvancedInputs: ContractAdvancedInputsSchema.required(
    'You must provide a value for advanced inputs.'
  ),
})

export interface IContractLaneInformationSchema
  extends yup.InferType<typeof ContractLaneInformationSchema> {}
