import {
  AutocompleteOrganizationUserSchema,
  TAutocompleteOrganizationUserSchema,
} from '#components'
import { organizationShipperSchema } from '#components/ControlledAutocompleteOrganizationShipper'
import { IContractRfpStates } from '#types/graphqlTypes'
import { REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import moment from 'moment'
import * as yup from 'yup'

function valueNotNull(value: unknown) {
  return value !== null
}

export const AddNewRFPFormSchema = yup.object({
  name: yup.string().required('You must enter an RFP name '),
  customer: organizationShipperSchema
    .nullable()
    .test('not-null', 'You must select a customer.', valueNotNull),
  contractStartDate: yup.string().required('You must select a start date'),
  contractEndDate: yup
    .string()
    .required('You must select an end date')
    .test(
      'end-date-after-start',
      'The contact end date must be after the contract start date',
      function (endDate) {
        const { contractStartDate } = this.parent
        if (!endDate || !contractStartDate) return true

        return moment(endDate).isAfter(moment(contractStartDate))
      }
    ),
  status: yup.object({
    value: yup.string().required(REQUIRED_MESSAGE),
  }),
  dueDate: yup
    .string()
    .when(
      'status',
      ([statusObj]: { value: IContractRfpStates }[], schema: yup.Schema): yup.Schema => {
        const { value: status } = statusObj || {}
        if ([IContractRfpStates.planning, IContractRfpStates.in_progress].includes(status))
          return schema.required('You must select a due date')

        return schema
      }
    ),
  submittedDate: yup
    .string()
    .when(
      'status',
      ([statusObj]: { value: IContractRfpStates }[], schema: yup.Schema): yup.Schema => {
        const { value: status } = statusObj || {}
        if ([IContractRfpStates.submitted].includes(status))
          return schema.required('You must select a submitted date')

        return schema
      }
    ),
  assignee: AutocompleteOrganizationUserSchema.nullable().test(
    'required',
    'You must select an assignee',
    valueNotNull
  ),
  additionalTeamMember1: AutocompleteOrganizationUserSchema.nullable().when(
    'additionalTeamMember2',
    {
      is: (value: TAutocompleteOrganizationUserSchema) => Boolean(value),
      then: schema =>
        schema.test(
          'required',
          'You must select the first team member before adding a second one',
          valueNotNull
        ),
    }
  ),
  additionalTeamMember2: AutocompleteOrganizationUserSchema.nullable(),
})

export type TAddNewRFPFormSchema = yup.InferType<typeof AddNewRFPFormSchema>
