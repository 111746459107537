import { AutocompleteLocationSchema } from '#components/ControlledAutocompleteLocation/schema'
import * as yup from 'yup'
import { stopLocationNotNullValidation } from '../utils'

export const StopSchema = yup.object({
  /**
   * Location and type(pickup or delivery) of stop in contract lane stops.
   */
  location: AutocompleteLocationSchema.nullable()
    .default(null)
    .test(
      'stopLocationNotNullValidation',
      'You must select a location.',
      stopLocationNotNullValidation
    ),
  type: yup.string().required('You must select a stop type.'),
})

export interface IStopSchema extends yup.InferType<typeof StopSchema> {}
