import { EQUIPMENT_TYPES, GEOGRAPHIC_TYPES } from '../../types'

export const GEOGRAPHIC_TYPE_OPTIONS = [
  {
    label: 'Regional',
    value: GEOGRAPHIC_TYPES.regional,
  },
  {
    label: 'State',
    value: GEOGRAPHIC_TYPES.state,
  },
  {
    label: 'ZIP codes (5-digit)',
    value: GEOGRAPHIC_TYPES.fiveDigitZipCodes,
  },
]

export const EQUIPMENT_TYPE_LABELS = {
  [EQUIPMENT_TYPES.dryVan]: 'Dry Van',
  [EQUIPMENT_TYPES.reefer]: 'Reefer',
}

export const EQUIPMENT_TYPE_OPTIONS = [
  {
    label: EQUIPMENT_TYPE_LABELS[EQUIPMENT_TYPES.dryVan],
    value: EQUIPMENT_TYPES.dryVan,
  },
  {
    label: EQUIPMENT_TYPE_LABELS[EQUIPMENT_TYPES.reefer],
    value: EQUIPMENT_TYPES.reefer,
  },
]

/**
 * Factor form names
 */
export const PICKUP_LOCATION_FACTOR_NAME = 'factors.pickupLocation'
export const DELIVERY_LOCATION_FACTOR_NAME = 'factors.deliveryLocation'
export const CUSTOMER_FACTOR_NAME = 'factors.customer'
export const EQUIPMENT_FACTOR_NAME = 'factors.equipment'
