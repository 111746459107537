import React, { useMemo } from 'react'
import NumericFormat from 'dpl/components/NumericFormat'
import { makeStyles, Theme, Typography } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import { decimalToPercentFormatter } from '../../../../utils/decimalToPercentFormatter'

export interface IMarginPercentageDescriptionProps {
  isCurrentValueDefaultSetting: boolean
  isSuggestedValueOverridden: boolean
  percentage: number
  rawMarginPremium: Nullable<number>
  marginPremiumLimited: boolean
  /**
   * @default 'MarginPercentageDecription'
   */
  dataTest?: string
}

const useMarginPercentageDescriptionStyles = makeStyles<Theme, { isPositiveMargin: boolean }>(
  () => ({
    root: {
      color: ({ isPositiveMargin }) => {
        if (isPositiveMargin) return brandColors.green3
        return brandColors.error1
      },
    },
  })
)

export function MarginPercentageDescription({
  dataTest = 'MarginPercentageDecription',
  isCurrentValueDefaultSetting,
  isSuggestedValueOverridden,
  marginPremiumLimited,
  percentage,
  rawMarginPremium,
}: IMarginPercentageDescriptionProps) {
  const classes = useMarginPercentageDescriptionStyles({ isPositiveMargin: percentage > 0 })
  const showPercentageLabel = isSuggestedValueOverridden || isCurrentValueDefaultSetting

  const marginPercentage = useMemo<number>(
    () => (percentage ? (decimalToPercentFormatter(percentage, false, 2) as number) : 0),
    [percentage]
  )

  const rawMarginPercentage = useMemo<number>(
    () =>
      marginPremiumLimited
        ? (decimalToPercentFormatter(rawMarginPremium || 0, false, 2) as number)
        : 0,
    [marginPremiumLimited, rawMarginPremium]
  )

  const showRawMarginPercentage = marginPremiumLimited && !isSuggestedValueOverridden

  return (
    <div data-test={dataTest} className={classes.root}>
      {showRawMarginPercentage && (
        <>
          <NumericFormat
            displayType='text'
            value={rawMarginPercentage}
            decimalScale={2}
            fixedDecimalScale={false}
            suffix='%'
            data-test={`${dataTest}-rawValue`}
            style={marginPremiumLimited ? { textDecorationLine: 'line-through' } : undefined}
          />{' '}
        </>
      )}
      <NumericFormat
        displayType='text'
        value={marginPercentage}
        decimalScale={2}
        fixedDecimalScale={false}
        suffix='%'
        data-test={`${dataTest}-value`}
      />
      {showPercentageLabel && (
        <Typography variant='subtitle2' component='span' data-test={`${dataTest}-label`}>
          {' '}
          {isCurrentValueDefaultSetting && !isSuggestedValueOverridden && '(Default)'}
          {isSuggestedValueOverridden && '(Custom)'}
        </Typography>
      )}
    </div>
  )
}
