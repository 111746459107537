export const getStopsWithRelativeSequence = <T>(stops: (T & { stopType: string })[]) => {
  let pickupNum = 0
  let deliveryNum = 0
  return stops.map(stop => {
    const { stopType } = stop
    if (stopType === 'pickup') pickupNum += 1
    else deliveryNum += 1
    return {
      ...stop,
      relativeStopSequence: stopType === 'pickup' ? pickupNum : deliveryNum,
    }
  })
}
