import { numericFormatter } from 'react-number-format'

const WEIGHT_PROPS = {
  thousandSeparator: ',',
  decimalScale: 0,
  suffix: ' lbs',
  allowNegative: false,
}

export function weightFormatter(weightValue: number): string {
  return numericFormatter(`${weightValue}`, WEIGHT_PROPS)
}
