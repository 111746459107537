import React, { useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import {
  IRfpAddLaneSchema,
  IRfpLaneInfoSchema,
  IRfpRatesSchema,
} from '#routes/contract-rfp/ContractRFPDetailPage/schema'
import { IContractRfpVersionLaneRateRateTypes, IEquipmentKeys } from '#types/graphqlTypes'
import { IRateType } from '#types/rateType'
import { currencyFormatter } from '#utils/currencyFormatter'
import { usePermissionsContext } from 'auth/common/context'
import {
  brandColors,
  INLINE_MESSAGE_VARIANTS,
  InlineMessage,
  ISegmentedToggleButtonProps,
  SegmentedToggleButton,
  TextFieldGroup,
  Tooltip,
} from 'dpl'
import { Box, Chip, Divider, makeStyles, Skeleton, Typography } from 'dpl/core'
import { AIIcon, AlertIcon, InfoIcon, LocationPinIcon, WarningIcon } from 'dpl/icons'
import { useFlagsContext } from 'flags/src/FlagsContext'
import ControlledCurrencyInput from 'forms/components/ControlledCurrencyInput'
import ControlledHiddenInput from 'forms/components/ControlledHiddenInput'
import ControlledPercentageInput from 'forms/components/ControlledPercentageInput'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { IRfpLaneFragment } from '../../../LaneList/graphql/RfpLane'
import { RATE_TYPE_OPTIONS } from '../../../LaneList/grid'
import { RateGenRadioGroup } from '../../../RateGenRadioGroup'
import { useGenerateAutoRateForLaneMutation } from '../../graphql/GenerateAutoRateForLane'
import { RateGenCallout } from '../RateGenCallout'
import {
  calculateMarginAmount,
  calculateMarginPercentage,
  calculateRate,
  getMarginColor,
  isCustomRate,
} from './utils'

export interface IEstimateProps {
  lane?: IRfpLaneFragment
  apiError?: string
  readOnly?: boolean
}

const useStyles = makeStyles(theme => ({
  centerElements: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    flexWrap: 'wrap',
  },
  laneInfo: {
    marginTop: theme.spacing(2),
  },
  root: {
    margin: theme.spacing(3, 0),
  },
  contractRate: {
    margin: theme.spacing(2, 0, 1.5, 0),
  },
  numericValue: {
    margin: theme.spacing(1.5, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    padding: theme.spacing(3, 2),
    border: `1px solid ${brandColors.coolGray3}`,
    backgroundColor: brandColors.coolGray0,
    borderBottomRight: theme.spacing(0.5),
    borderBottomLeft: theme.spacing(0.5),
  },
  calculated: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  withLabel: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
  inputInline: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  error: {
    margin: theme.spacing(2, 0),
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: brandColors.white,
    },
  },
}))

export function Estimate({ apiError, lane, readOnly = false }: IEstimateProps) {
  const classes = useStyles()
  const { isFlagEnabled } = useFlagsContext()
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled

  const { id = '', laneRategenMetadata, rate } = lane || {}
  const { source } = rate || {}
  const { status } = laneRategenMetadata || {}
  const [lastValueModified, setLastValueModified] = useState<'marginAmount' | 'marginPercentage'>()
  const methods = useFormContext<IRfpRatesSchema>()
  const {
    control,
    errors,
    formState: { dirtyFields },
    setValue,
  } = methods || {}

  const rateType = useWatch<IRfpRatesSchema['rateType']>({
    control,
    name: 'rateType',
  })
  const carrierRate = useWatch<IRfpRatesSchema['carrierRate']>({
    control,
    name: 'carrierRate',
  })
  const shipperRate = useWatch<IRfpRatesSchema['shipperRate']>({
    control,
    name: 'shipperRate',
  })
  const marginAmount = useWatch<IRfpRatesSchema['marginAmount']>({
    control,
    name: 'marginAmount',
  })
  const marginPercentage = useWatch<IRfpRatesSchema['marginPercentage']>({
    control,
    name: 'marginPercentage',
  })
  const fuelRate = useWatch<IRfpRatesSchema['fuelRate']>({
    control,
    name: 'fuelRate',
  })

  const watchedAutoGenerateRates = useWatch<IRfpLaneInfoSchema['autoGenerateRates']>({
    control,
    name: 'autoGenerateRates',
  })

  const defaultIsCustom = isCustomRate({
    shipperRate,
    fuelRate,
    marginAmount,
    rateType: rateType as IContractRfpVersionLaneRateRateTypes,
  })

  const watchedEquipment = useWatch<IRfpLaneInfoSchema['equipment']>({
    control,
    name: 'equipment',
  })

  const hasEquipmentTypeForRateGen =
    watchedEquipment === IEquipmentKeys.dry_van || watchedEquipment === IEquipmentKeys.reefer

  const [strategy, setStrategy] = useState<'custom' | 'calculated'>(
    defaultIsCustom ? 'custom' : 'calculated'
  )

  const handleStrategyChange: ISegmentedToggleButtonProps['onChange'] = (_, value) => {
    setStrategy(value)
    const defaultIsCustomRate = isCustomRate({
      shipperRate: lane?.rate?.shipperRate,
      fuelRate: lane?.rate?.fuelRatePerMile,
      marginAmount: lane?.rate?.marginAmount,
      rateType: lane?.rate?.rateType as IContractRfpVersionLaneRateRateTypes,
    })
    setValue('rateType', lane?.rate?.rateType ?? IRateType.allIn)
    setValue('carrierRate', lane?.rate?.carrierRate)
    setValue('marginAmount', lane?.rate?.marginAmount || null)
    setValue('fuelRate', lane?.rate?.fuelRatePerMile)
    setValue(
      'marginPercentage',
      lane?.rate?.marginPercentage ? lane.rate.marginPercentage * 100 : null
    )
    setValue('shipperRate', defaultIsCustomRate ? lane?.rate?.shipperRate : null)
  }

  /**
   * Set rate type to 'all_in' when custom rate is entered
   */
  useEffect(() => {
    if (strategy === 'custom' && shipperRate) {
      setValue('rateType', 'all_in')
    }
  }, [strategy, shipperRate])

  /**
   * Calculate margin amount when margin percentage is changed
   */
  useEffect(() => {
    if (lastValueModified && lastValueModified !== 'marginPercentage') return

    const calculatedMarginAmount = calculateMarginAmount({
      carrierRate,
      marginPercentage,
    })

    if (isNil(calculatedMarginAmount) || calculatedMarginAmount === marginAmount) return

    setValue('marginAmount', calculatedMarginAmount)
  }, [marginPercentage, carrierRate, marginAmount])

  /**
   * Calculate margin percentage when margin amount is changed
   */
  useEffect(() => {
    if (lastValueModified !== 'marginAmount') return

    const calculatedMarginPercentage = calculateMarginPercentage({
      carrierRate,
      marginAmount,
    })

    if (isNil(calculatedMarginPercentage) || calculatedMarginPercentage === marginPercentage) return

    setValue('marginPercentage', calculatedMarginPercentage)
  }, [marginAmount, carrierRate, marginPercentage])

  const { label: laneLabel } = RATE_TYPE_OPTIONS.find(option => option.value === rateType) || {}

  const marginColor = getMarginColor({ marginAmount })
  const customMiles = useWatch<IRfpAddLaneSchema['customMiles']>({
    control,
    name: 'customMiles',
    defaultValue: lane?.customMiles,
  })
  const calculatedMiles = useWatch<IRfpAddLaneSchema['calculatedMiles']>({
    control,
    name: 'calculatedMiles',
    defaultValue: lane?.calculatedMiles,
  })
  const miles = customMiles || calculatedMiles || 0
  const {
    calculatedContractRate,
    calculatedFuelRate,
    calculatedMarginAmount,
    calculatedMarginPercentage,
    contractRate,
    totalFuel,
  } = calculateRate({
    rateType: rateType as IContractRfpVersionLaneRateRateTypes,
    carrierRate: carrierRate || null,
    marginAmount: marginAmount || null,
    fuelRate: fuelRate || null,
    miles,
    strategy,
    marginPercentage: marginPercentage || null,
    shipperRate: shipperRate || null,
  })

  const isRateGenerated = source === 'auto_rate_gen' && isRfpRateGenEnabled
  const isRateGenerationPending = status === 'pending' && isRfpRateGenEnabled

  const isAddMode = !lane
  const [generateRateForLane, { error: rateGenApolloErrors, loading: rateGenloading }] =
    useGenerateAutoRateForLaneMutation({
      variables: {
        input: {
          rfpVersionLaneId: id,
        },
      },
      refetchQueries: ['CurrentVersionLanes'],
    })

  const handleRateGeneration = async () => {
    await generateRateForLane()
  }

  if (isAddMode && watchedAutoGenerateRates === 'yes') {
    return (
      <div className={classes.root}>
        <Typography variant='overline' color='textSecondary' className={classes.centerElements}>
          <LocationPinIcon /> Estimate (per load)
        </Typography>
        {hasEquipmentTypeForRateGen && (
          <Controller
            defaultValue='yes'
            control={control}
            name='autoGenerateRates'
            render={({ onChange }) => <RateGenRadioGroup onChange={onChange} />}
          />
        )}
        <div>
          <div className={classes.calculated}>
            <div className={classes.inputInline}>
              <ControlledSelectField
                control={control}
                name='rateType'
                SelectProps={{
                  options: RATE_TYPE_OPTIONS,
                  label: 'Contract Rate Type',
                  className: classes.input,
                  disabled: !userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly,
                  helperText: errors.rateType?.message,
                  error: !!errors.rateType,
                }}
              />
              {rateType === 'linehaul' && (
                <ControlledCurrencyInput
                  control={control}
                  name='fuelRate'
                  transformFunction={value => parseFloat(value)}
                  CurrencyInputProps={{
                    label: 'Fuel Rate Per Mile',
                    fixedDecimalScale: true,
                    className: classes.input,
                    disabled: !userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly,
                    clearable:
                      userPermissions['contract_rfp.update_rfp_version_lanes'] && !readOnly,
                    helperText: errors.fuelRate?.message,
                    error: !!errors.fuelRate,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Typography variant='overline' color='textSecondary' className={classes.centerElements}>
        <LocationPinIcon /> Estimate (per load)
      </Typography>
      {isAddMode && hasEquipmentTypeForRateGen && (
        <Controller
          defaultValue='yes'
          control={control}
          name='autoGenerateRates'
          render={({ onChange }) => <RateGenRadioGroup onChange={onChange} />}
        />
      )}
      {apiError && (
        <InlineMessage
          className={classes.error}
          type={INLINE_MESSAGE_VARIANTS.ALERT}
          message={apiError}
        />
      )}
      {rateGenApolloErrors && (
        <InlineMessage
          IconComponent={AlertIcon}
          message='Rate generation failed. Please try again.'
          type={INLINE_MESSAGE_VARIANTS.ALERT}
        />
      )}
      {status === 'failed' && (
        <InlineMessage
          IconComponent={WarningIcon}
          message='Rate generation failed. This could be due to the lane criteria or an unexpected error. Please try again, or manually enter a rate.'
          type={INLINE_MESSAGE_VARIANTS.WARNING}
        />
      )}
      <div className={`${classes.contractRate} ${classes.numericValue}`}>
        <div className={classes.centerElements}>
          <Typography variant='h3'>Contract Rate</Typography>{' '}
          {laneLabel && <Chip label={laneLabel} variant='outlined' size='small' />}
        </div>
        {isRateGenerationPending ? (
          <Skeleton width={100} height={30} />
        ) : (
          <Typography variant='h3'>
            {isRateGenerated && isEmpty(dirtyFields) && <AIIcon size='large' />}
            {contractRate ? currencyFormatter(contractRate) : '-'}
          </Typography>
        )}
      </div>
      {rateType === 'linehaul' && (
        <div className={`${classes.contractRate} ${classes.numericValue}`}>
          <div className={classes.centerElements}>
            <Typography variant='subtitle2'>Est. Fuel Total</Typography>
            <Tooltip title='To calculate total fuel, we multiply mileage by fuel rate per mile, using calculated distances unless you specify a custom mileage.'>
              <Box display='flex' alignItems='center'>
                <InfoIcon />
              </Box>
            </Tooltip>
          </div>
          <Typography variant='subtitle2'>
            {totalFuel ? `+${currencyFormatter(totalFuel)}` : '-'}
            {calculatedFuelRate ? ` (${currencyFormatter(calculatedFuelRate)}/mi)` : ''}
          </Typography>
        </div>
      )}
      <Divider />
      <div className={classes.numericValue}>
        <Typography variant='subtitle2' color='textSecondary'>
          Est. Carrier Rate (including fuel)
        </Typography>
        <Typography variant='subtitle2' color='textSecondary'>
          {calculatedContractRate ? currencyFormatter(calculatedContractRate) : '-'}
        </Typography>
      </div>
      <div className={classes.numericValue}>
        <Typography variant='subtitle2' color='textSecondary'>
          Est. Margin
        </Typography>
        <Typography variant='subtitle2' color={marginColor}>
          {!calculatedMarginAmount && !calculatedMarginPercentage ? (
            '-'
          ) : (
            <>
              {calculatedMarginAmount ? currencyFormatter(calculatedMarginAmount) : '$0'}{' '}
              {calculatedMarginPercentage ? `(${calculatedMarginPercentage}%)` : '-'}
            </>
          )}
        </Typography>
      </div>
      {lane && strategy === 'calculated' && !readOnly && (
        <RateGenCallout loading={rateGenloading} onClick={handleRateGeneration} lane={lane} />
      )}
      <div className={classes.form}>
        <SegmentedToggleButton
          disabled={!userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly}
          options={[
            {
              label: 'Calculate Rate',
              value: 'calculated',
            },
            {
              label: 'Enter Custom Rate',
              value: 'custom',
            },
          ]}
          value={strategy}
          onChange={handleStrategyChange}
        />
        {strategy === 'calculated' && (
          <div className={classes.calculated}>
            <div className={classes.inputInline}>
              <ControlledSelectField
                control={control}
                name='rateType'
                SelectProps={{
                  options: RATE_TYPE_OPTIONS,
                  label: 'Contract Rate Type',
                  className: classes.input,
                  disabled: !userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly,
                  helperText: errors.rateType?.message,
                  error: !!errors.rateType,
                }}
              />
              {rateType === 'linehaul' && (
                <ControlledCurrencyInput
                  control={control}
                  name='fuelRate'
                  transformFunction={value => parseFloat(value)}
                  CurrencyInputProps={{
                    label: 'Fuel Rate Per Mile',
                    fixedDecimalScale: true,
                    className: classes.input,
                    disabled: !userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly,
                    clearable:
                      userPermissions['contract_rfp.update_rfp_version_lanes'] && !readOnly,
                    helperText: errors.fuelRate?.message,
                    error: !!errors.fuelRate,
                  }}
                />
              )}
            </div>
            <div className={classes.withLabel}>
              <Typography variant='subtitle2'>Adjust Carrier Rate Strategy</Typography>
              <ControlledCurrencyInput
                control={control}
                name='carrierRate'
                transformFunction={value => parseInt(value, 10)}
                disabled={!userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly}
                CurrencyInputProps={{
                  label: 'Carrier Rate',
                  className: classes.input,
                  clearable: userPermissions['contract_rfp.update_rfp_version_lanes'] && !readOnly,
                  helperText: errors.carrierRate?.message,
                  error: !!errors.carrierRate,
                  InputProps: {
                    startAdornment: !carrierRate ? '$' : undefined,
                  },
                }}
              />
            </div>
            <div className={classes.withLabel}>
              <Typography variant='subtitle2'>Adjust Premium Strategy</Typography>
              <TextFieldGroup>
                <ControlledPercentageInput
                  control={control}
                  name='marginPercentage'
                  transformFunction={value => {
                    setLastValueModified('marginPercentage')
                    return value ? parseFloat(value) : 0
                  }}
                  disabled={!userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly}
                  PercentageInputProps={{
                    label: 'Premium',
                    hiddenLabel: true,
                    className: classes.input,
                    clearable:
                      userPermissions['contract_rfp.update_rfp_version_lanes'] && !readOnly,
                    // margin percentage error is to align both elements when there is an error
                    helperText:
                      errors.marginPercentage?.message || (errors.marginAmount?.message && ' '),
                    error: !!errors.marginPercentage,
                    InputProps: {
                      startAdornment: isNil(marginPercentage) ? '%' : undefined,
                    },
                  }}
                />
                <ControlledCurrencyInput
                  control={control}
                  name='marginAmount'
                  transformFunction={value => {
                    setLastValueModified('marginAmount')
                    return parseInt(value, 10)
                  }}
                  disabled={!userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly}
                  CurrencyInputProps={{
                    className: classes.input,
                    clearable:
                      userPermissions['contract_rfp.update_rfp_version_lanes'] && !readOnly,
                    // margin percentage error is to align both elements when there is an error
                    helperText:
                      errors.marginAmount?.message || (errors.marginPercentage?.message && ' '),
                    error: !!errors.marginAmount,
                    InputProps: {
                      startAdornment: isNil(marginAmount) ? '$' : undefined,
                    },
                  }}
                />
              </TextFieldGroup>
            </div>
          </div>
        )}
        {strategy === 'custom' && (
          <div className={classes.calculated}>
            <ControlledCurrencyInput
              control={control}
              name='shipperRate'
              disabled={!userPermissions['contract_rfp.update_rfp_version_lanes'] || readOnly}
              CurrencyInputProps={{
                label: 'All-In Contract Rate (Custom)',
                className: classes.input,
                placeholder: '$',
                clearable: userPermissions['contract_rfp.update_rfp_version_lanes'] && !readOnly,
                helperText:
                  errors.shipperRate?.message ||
                  'Note: Revenue cannot be calculated when a custom rate is entered.',
                error: !!errors.shipperRate,
              }}
            />
            <ControlledHiddenInput control={control} name='rateType' />
          </div>
        )}
      </div>
    </div>
  )
}
