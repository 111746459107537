import React, { useCallback, useMemo, MouseEvent } from 'react'
import { makeStyles } from 'dpl/core'
import { useBreakpoint } from 'dpl/hooks'
import {
  UserDetailsHeader,
  AppCard,
  OrganizationCard,
  ResetPasswordConfirmationModal,
  IOrganizationInfo,
  IAppInfo,
  ResetPasswordCard,
} from 'dpl/templates'
import { PageTemplate } from 'dpl/templates/PageTemplate'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { PROTECTED_PATHS } from '../../constants/paths'
import { useAnalytics } from '../../hooks/useAnalytics'
import { EditProfileDrawer, IEditProfileDrawerProps } from './components/EditProfileDrawer'

export interface IUserProfileProps {
  userInfo: IEditProfileDrawerProps['userInfo']
  organizationInfo: IOrganizationInfo
  /**
   * Org admins will have a badge icon next to their name and can edit user email
   */
  isOrgAdmin: boolean
  /**
   * Transfix admins can edit user email
   */
  isTransfixAdmin: boolean
  /**
   * Admin and product modules that the user has access to
   */
  moduleAccess: IAppInfo
  /**
   * Callback fired when reset password is clicked
   * If resending password, also triggers Toast.
   */
  onResetPassword: (e: MouseEvent<HTMLButtonElement>, resend?: boolean) => void
}

const useUserProfileStyles = makeStyles(theme => ({
  profileBody: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '8fr 4fr',
      padding: theme.spacing(3),
      flexDirection: 'row',
    },
  },
  accountCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  appCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}))

function UserProfile({
  isOrgAdmin,
  isTransfixAdmin,
  moduleAccess,
  onResetPassword,
  organizationInfo,
  userInfo,
}: IUserProfileProps) {
  const { trackEvent } = useAnalytics()

  const { isMobile } = useBreakpoint()
  const classes = useUserProfileStyles()

  const isAdmin = isTransfixAdmin || isOrgAdmin
  const { email } = userInfo || {}

  const { createToggleHandler, modalState } = useModalState({
    resetPassword: false,
    editProfile: false,
  })
  const toggleEditProfilePanel = createToggleHandler('editProfile')
  const toggleResetPasswordConfirmationModal = createToggleHandler('resetPassword')

  const resetPasswordHandler = useCallback(
    async (e, resend?: boolean) => {
      trackEvent('Reset Password', 'REQUEST_RESET_PASSWORD', { resend })
      if (!resend) toggleResetPasswordConfirmationModal()
      onResetPassword(e, resend)
    },
    [onResetPassword, toggleResetPasswordConfirmationModal, trackEvent]
  )

  const AccountCards = useMemo(() => {
    return <ResetPasswordCard onClick={resetPasswordHandler} />
  }, [resetPasswordHandler])

  return (
    <PageTemplate
      dataTest='route-user-profile'
      pageTitle='Profile'
      documentTitle='My Profile'
      PageBodyTitleProps={{
        actions: [{ label: 'Edit Profile', onClick: toggleEditProfilePanel }],
      }}
      noPadding>
      <UserDetailsHeader userInfo={userInfo} isOrgAdmin={isOrgAdmin} />
      <div className={classes.profileBody}>
        <div className={classes.accountCards}>
          <OrganizationCard
            organizationInfo={organizationInfo}
            profileLink={PROTECTED_PATHS.organizationProfile}
          />
          {!isMobile && AccountCards}
        </div>
        <div className={classes.appCards}>
          <AppCard {...moduleAccess} />
        </div>
        {isMobile && AccountCards}
      </div>
      <EditProfileDrawer
        open={modalState.editProfile}
        userInfo={userInfo}
        onClose={toggleEditProfilePanel}
        isAdmin={isAdmin}
      />
      <ResetPasswordConfirmationModal
        open={modalState.resetPassword}
        email={email}
        onClose={toggleResetPasswordConfirmationModal}
        onResendEmail={resetPasswordHandler}
      />
    </PageTemplate>
  )
}

export default UserProfile
