import React, { Fragment } from 'react'
import { DiscoveredOrganization } from '@stytch/vanilla-js'
import { Divider, List, Typography } from 'dpl/core'
import { AuthenticationCard } from '../../../common'
import {
  DiscoveredOrganizationListItem,
  IDiscoveredOrganizationListItemProps,
} from './DiscoveredOrganizationListItem'

export interface IDiscoveredOrganizationListProps {
  discoveredOrganizations: DiscoveredOrganization[]
  onOrganizationSelect: IDiscoveredOrganizationListItemProps['onOrganizationSelect']
  termsOfServiceUrl: string
}

export function DiscoveredOrganizationList({
  discoveredOrganizations,
  onOrganizationSelect,
  termsOfServiceUrl,
}: IDiscoveredOrganizationListProps) {
  return (
    <AuthenticationCard termsOfServiceUrl={termsOfServiceUrl} title='Select your organization'>
      {discoveredOrganizations.length > 0 ? (
        <List>
          <Divider component='li' />

          {discoveredOrganizations.map(discoveredOrganization => (
            <Fragment key={discoveredOrganization.organization.organization_id}>
              <DiscoveredOrganizationListItem
                discoveredOrganization={discoveredOrganization}
                onOrganizationSelect={onOrganizationSelect}
              />

              <Divider component='li' />
            </Fragment>
          ))}
        </List>
      ) : (
        <Typography variant='body1'>You do not belong to any organizations.</Typography>
      )}
    </AuthenticationCard>
  )
}
