import TrimbleMaps from '@trimblemaps/trimblemaps-js'
import { IMapStopProps } from '../types'

export interface IGetStopsLayerProps {
  stops: Partial<IMapStopProps>[]
  /**
   * Helps for debugging maps
   * @example 'map-stops'
   */
  stopsSourceName: string
  /**
   * @default 'stops-layer'
   */
  stopLayerId?: string
}

/**
 * Generates a map layer with a dynamic expression-based query for the map icon
 * @see https://developer.trimblemaps.com/maps-sdk/style-spec/expressions/
 */
export const getStopsLayer = ({
  stops = [],
  stopsSourceName,
  stopLayerId = 'stops-layer',
}: IGetStopsLayerProps): TrimbleMaps.SymbolLayer => {
  const iconImageExpression: TrimbleMaps.Expression = ['concat', 'MapIcon-', ['get', 'typeShort']]

  /**
   * If there are more than two stops, include stop sequence in icon
   * @example 'P' or 'P2'
   */
  if (!stops || stops.length > 2) {
    iconImageExpression.push(['get', 'sequence'])
  }

  return {
    id: stopLayerId,
    type: 'symbol',
    source: stopsSourceName,
    layout: {
      'icon-size': 0.5,
      'icon-image': iconImageExpression,
      /**
       * Offset so any position underneath is visible
       */
      'icon-offset': [0, 20],
    },
  }
}
