import React, { useCallback } from 'react'
import {
  AutocompleteLocationBase,
  IAutocompleteLocationBaseProps,
} from 'dpl/components/AutocompleteLocationBase'
import { IGeocodingResultTypeEnum } from '../../types/graphqlTypes'
import { useAutocompleteLocationLazyQuery } from './graphql/AutocompleteLocation'
import { ILocationResultFragment } from './graphql/LocationResultFragment'

export interface IAutocompleteLocationProps<TMultiple extends boolean | undefined = false>
  extends Omit<
    IAutocompleteLocationBaseProps<ILocationResultFragment, TMultiple>,
    'loading' | 'onInputChange' | 'options'
  > {
  /**
   * @default 'AutocompleteLocation'
   */
  dataTest?: string
  /**
   * The result types to search for.
   * @default ['City']
   */
  resultTypes?: IGeocodingResultTypeEnum[]
}

const EMPTY_LOCATION_RESULTS: ILocationResultFragment[] = []

export function AutocompleteLocation<TMultiple extends boolean | undefined = false>({
  countryOptions,
  dataTest = 'AutocompleteLocation',
  defaultCountry,
  enableCountrySelect,
  error,
  getOptionLabel,
  helperText,
  label,
  multiple,
  onChange,
  placeholder,
  renderOption,
  renderStartAdornment,
  resultTypes = [IGeocodingResultTypeEnum.City, IGeocodingResultTypeEnum.PostalCode],
  value,
  filterOptions,
  required,
  noOptionsText,
  inputValueMapper,
  type,
  isOptionEqualToValue,
  groupBy,
  size,
}: IAutocompleteLocationProps<TMultiple>) {
  const [search, { data, loading }] = useAutocompleteLocationLazyQuery()

  const { geocodingSearch: locationResults = EMPTY_LOCATION_RESULTS } = data || {}

  const inputValueChangeHandler = useCallback<IAutocompleteLocationBaseProps['onInputChange']>(
    (inputValue, country) => {
      if (inputValue !== '') {
        search({
          variables: {
            countries: [country],
            searchString: inputValue,
            resultTypes,
          },
        })
      }
    },
    [resultTypes, search]
  )

  return (
    <AutocompleteLocationBase
      countryOptions={countryOptions}
      dataTest={dataTest}
      defaultCountry={defaultCountry}
      enableCountrySelect={enableCountrySelect}
      error={error}
      getOptionLabel={getOptionLabel}
      helperText={helperText}
      isOptionEqualToValue={isOptionEqualToValue}
      label={label}
      loading={loading}
      multiple={multiple}
      onChange={onChange}
      onInputChange={inputValueChangeHandler}
      options={locationResults}
      placeholder={placeholder}
      renderOption={renderOption}
      renderStartAdornment={renderStartAdornment}
      value={value}
      filterOptions={filterOptions}
      required={required}
      noOptionsText={noOptionsText}
      inputValueMapper={inputValueMapper}
      type={type}
      groupBy={groupBy}
      size={size}
    />
  )
}
