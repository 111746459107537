import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAnalytics } from '#hooks'
import { buildUserAssignmentsInput } from '#routes/contract-rfp/utils'
import { IContractRfpStates } from '#types/graphqlTypes'
import { ConfirmationModal, INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import SidePanel from 'dpl/components/SidePanel'
import { FORMATS } from 'dpl/constants'
import { Button, makeStyles } from 'dpl/core'
import moment from 'moment'
import { AddNewRFPForm } from './AddNewRFPForm'
import { useUpsertContractRfpMutation } from './graphql/UpsertContractRfp'
import { useAddNewRFPForm } from './hooks'

export interface IAddNewRFPSidePanelProps {
  onClose: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 85px - 48px - 96px)',
    overflowY: 'scroll',
    padding: 3,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(3),
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

export function AddNewRFPSidePanel({ onClose }: IAddNewRFPSidePanelProps) {
  const classes = useStyles()
  const { trackEvent } = useAnalytics()
  const navigate = useNavigate()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [apiErrors, setApiErrors] = useState<string[]>()
  const [createRfp] = useUpsertContractRfpMutation()

  const handleCancel = () => {
    setShowConfirmationModal(true)
  }

  const methods = useAddNewRFPForm({
    defaultValues: {
      additionalTeamMember1: null,
      additionalTeamMember2: null,
      assignee: null,
      customer: null,
      name: '',
      contractEndDate: '',
      contractStartDate: '',
      dueDate: '',
      submittedDate: '',
      status: {
        value: IContractRfpStates.planning,
      },
    },
  })

  const submitHandler = methods.handleSubmit(async values => {
    const input = {
      dueAt: moment(values.dueDate).format(FORMATS.iso8601FormatTimezone),
      name: values.name,
      endAt: moment(values.contractEndDate).format(FORMATS.iso8601FormatTimezone),
      startAt: moment(values.contractStartDate).format(FORMATS.iso8601FormatTimezone),
      organizationShipperId: values.customer?.id,
      userAssignments: buildUserAssignmentsInput({
        additionalTeamMembers: [values.additionalTeamMember1, values.additionalTeamMember2],
        assignee: values.assignee,
      }),
    }
    try {
      const { data } = await createRfp({
        variables: {
          input,
        },
        onError: apolloError => {
          setApiErrors([apolloError.message])
          trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
            variables: input,
            apolloError,
          })
        },
      })
      const { upsertContractRfp } = data || {}
      const { contractRfp, errors } = upsertContractRfp || {}
      const { displayId } = contractRfp || {}

      if (errors?.length) {
        setApiErrors(errors.map(({ key, message }) => message.replace(key, '').trim()))

        trackEvent('Contract RFPs', 'CREATE_CONTRACT_RFP_ERROR', {
          variables: input,
          errors,
        })
        return
      }

      trackEvent('Contract RFPs', 'CREATE_CONTRACT_RFP_SUCCESS', {
        variables: input,
        data,
      })

      navigate(`/contract-rfps/RFP-${displayId}`)
    } catch (error) {
      setApiErrors([error.message])

      trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
        variables: input,
        errors: [error],
      })
    }
  })

  return (
    <SidePanel title='Add RFP' anchor='right' onClose={handleCancel}>
      <FormProvider {...methods}>
        <form className={classes.root} onSubmit={submitHandler} noValidate>
          {apiErrors?.map(error => (
            <InlineMessage key={error} message={error} type={INLINE_MESSAGE_VARIANTS.ALERT} />
          ))}
          <AddNewRFPForm />
          <div className={classes.actions}>
            <Button variant='text' onClick={handleCancel}>
              Cancel
            </Button>
            <Button type='submit'>Add RFP</Button>
          </div>
        </form>
      </FormProvider>
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => setShowConfirmationModal(false)}
          open
          includeCloseIconButton={false}
          title='Discard Unsaved RFP?'
          actions={[
            {
              label: 'Cancel',
              ButtonProps: {
                variant: 'text',
              },
              action: () => setShowConfirmationModal(false),
            },
            {
              label: 'Discard',
              ButtonProps: {
                color: 'error',
              },
              action: () => {
                setShowConfirmationModal(false)
                onClose()
              },
            },
          ]}
        />
      )}
    </SidePanel>
  )
}
