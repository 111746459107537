import React, { useMemo } from 'react'
import classname from 'classnames'
import { Card, CardHeader, makeStyles } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import { SpotQuoteCardAction } from './SpotQuoteCardAction'
import { ISpotQuoteCardContentProps, SpotQuoteCardContent } from './SpotQuoteCardContent'
import { SpotQuoteCardSubheader } from './SpotQuoteCardSubheader'

const useSpotQuoteCardStyles = makeStyles(theme => ({
  blueBorder: {
    borderColor: brandColors.skyBlue3,
  },
  header: {
    '& .MuiCardHeader-subheader': {
      margin: 0,
    },
  },
  estimatesCardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3, 0, 3),
    },
  },
  submitSpotQuoteCardHeader: {
    borderBottom: `1px solid ${brandColors.coolGray3}`,
    padding: theme.spacing(1.5, 3),
    marginBottom: theme.spacing(1),
  },
  highlightCardHeader: {
    borderBottom: `1px solid ${brandColors.skyBlue3}`,
    backgroundColor: brandColors.skyBlue0,
  },
}))

export interface ISpotQuoteCardProps extends ISpotQuoteCardContentProps {
  /**
   * Indicates if the quotes feature is enabled.
   * @default false
   */
  hasQuotesEnabled: boolean
  /**
   * Indicates if the do not bid rules are overridden.
   * @default false
   */
  isDoNotBidRulesOverridden: boolean
  /**
   * Callback to handle when the user clicks the "Save Quote" button.
   */
  onSaveQuoteClick: () => void
  /**
   * The ID of the quote.
   * @default null
   */
  quoteId: Nullable<string>
  /**
   * The status of the quote.
   * @default null
   */
  quoteStatus: Nullable<string>
}

export function SpotQuoteCard({
  hasQuotesEnabled = false,
  isDoNotBidRulesOverridden,
  onCarrierRateRulesOverride,
  onDoNotBidRulesOverride,
  onMarginPremiumRulesOverride,
  onSaveQuoteClick,
  quoteId,
  quoteStatus,
  rateAdjustments,
  rateEstimate,
  setRateEstimate,
  spotCostModel,
}: ISpotQuoteCardProps) {
  const classes = useSpotQuoteCardStyles()

  const dataTest = hasQuotesEnabled ? 'submit-quote-card' : 'estimates-card'

  const { doNotBid: hasDoNotBidRule } = rateAdjustments || {}

  const showHeader = useMemo(() => {
    if (isDoNotBidRulesOverridden) {
      return true
    }
    return !hasDoNotBidRule
  }, [isDoNotBidRulesOverridden, hasDoNotBidRule])

  const hasBlueBorder = useMemo(() => {
    if (isDoNotBidRulesOverridden) {
      return hasQuotesEnabled && !quoteId
    }
    return !hasDoNotBidRule && hasQuotesEnabled && !quoteId
  }, [hasQuotesEnabled, quoteId, hasDoNotBidRule, isDoNotBidRulesOverridden])

  const rootClasses = classname({
    [classes.blueBorder]: hasBlueBorder,
  })

  const headerClasses = classname(classes.header, {
    [classes.submitSpotQuoteCardHeader]: hasQuotesEnabled,
    [classes.estimatesCardHeader]: !hasQuotesEnabled,
    [classes.highlightCardHeader]: hasQuotesEnabled && !quoteId,
  })

  return (
    <Card className={rootClasses} data-test={dataTest}>
      {showHeader && (
        <CardHeader
          data-test='spot-quote-card-header'
          subheader={
            <SpotQuoteCardSubheader hasQuotesEnabled={hasQuotesEnabled} quoteId={quoteId} />
          }
          className={headerClasses}
          action={
            <SpotQuoteCardAction
              hasQuotesEnabled={hasQuotesEnabled}
              onSaveQuoteClick={onSaveQuoteClick}
              quoteStatus={quoteStatus}
              quoteId={quoteId}
            />
          }
        />
      )}
      <SpotQuoteCardContent
        onCarrierRateRulesOverride={onCarrierRateRulesOverride}
        onDoNotBidRulesOverride={onDoNotBidRulesOverride}
        onMarginPremiumRulesOverride={onMarginPremiumRulesOverride}
        quoteStatus={quoteStatus}
        rateAdjustments={rateAdjustments}
        rateEstimate={rateEstimate}
        setRateEstimate={setRateEstimate}
        spotCostModel={spotCostModel}
      />
    </Card>
  )
}
