import { IMapRouteGroup } from '../types'
import { getGeoJSONFeatureLineCollection } from './getGeoJSONFeatureLineCollection'

/**
 * Returns a GeoJSON object with coordinates
 * @example
 * ```
 * getGeoJSONSourceLine([{ coords: { lat: 123, lon: 123 }}]) // { ... }
 * ```
 */
export function getGeoJSONLineSource<P extends IMapRouteGroup>(
  lines: P[]
): TrimbleMaps.GeoJSONSourceRaw {
  return {
    type: 'geojson',
    data: getGeoJSONFeatureLineCollection(lines),
  }
}
