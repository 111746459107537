import { IRuleTypeEnum } from '#types/rates'
import { DISPLAY } from 'dpl/constants'
import { decimalToPercentFormatter } from '../../../utils/decimalToPercentFormatter'
import { IAdjustmentRuleLineItemKeyEnum } from '../../RatesEstimatedStrategyAccordion/components/AdjustmentRulesSection/types'

export interface IGetAdjustmentRuleLabelProps {
  adjustmentValue: Nullable<number>
  reason: Nullable<string>
  ruleAction: Nullable<string>
}

/**
 * Returns the label for an adjustment rule based on the adjustment value, reason, and rule action.
 *
 * @returns {string} The label for the adjustment rule
 *
 * @example getAdjustmentRuleLabel({ adjustmentValue: 0.1, reason: 'Hurricane Maria', ruleAction: 'baseline_adjustment' }) // '10% Premium for Hurricane Maria'
 * @example getAdjustmentRuleLabel({ adjustmentValue: 0.02, reason: 'Hurricane Maria', ruleAction: 'additive_adjustment' }) // '+2% increase for Hurricane Maria'
 * @example getAdjustmentRuleLabel({ adjustmentValue: -0.03, reason: 'Hurricane Maria', ruleAction: 'additive_adjustment' }) // '-3% decrease for Hurricane Maria'
 */
export const getAdjustmentRuleLabel = ({
  adjustmentValue,
  reason,
  ruleAction,
}: IGetAdjustmentRuleLabelProps): string => {
  if (!adjustmentValue) {
    return DISPLAY.empty
  }

  const value = adjustmentValue || 0
  const percentage = decimalToPercentFormatter(value, true)

  if (ruleAction === IAdjustmentRuleLineItemKeyEnum.default) {
    return `${percentage} Premium (Default)`
  }

  if (ruleAction === IAdjustmentRuleLineItemKeyEnum.maxMarginLimit) {
    return `Max Margin Limit of ${percentage} reached`
  }

  if (ruleAction === IRuleTypeEnum.base) {
    return `${percentage} Premium for ${reason}`
  }

  // Additive rules
  return `${adjustmentValue > 0 ? '+' : ''}${percentage} ${value > 0 ? 'increase' : 'decrease'} for ${reason}`
}
