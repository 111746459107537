import React, { memo, useCallback } from 'react'
import { IUserPermissions } from 'auth/common/context/PermissionsContext/types'
import { Avatar } from 'dpl/components'
import Autocomplete, { IAutocompleteProps } from 'dpl/components/Autocomplete'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import { AutocompleteOrganizationUserOption } from './components'
import { DEFAULT_ORGANIZATION_USER_STATUSES } from './constants'
import {
  IAutocompleteOrganizationUserQueryVariables,
  useAutocompleteOrganizationUserLazyQuery,
} from './graphql/AutocompleteOrganizationUser'
import { IOrganizationUserResultFragment } from './graphql/OrganizationUserResultFragment'

const DEBOUNCE_MS = 250

interface IAutocompleteBaseProps extends IAutocompleteProps<IOrganizationUserResultFragment> {}

export interface IAutocompleteOrganizationUserProps
  extends Omit<
    IAutocompleteBaseProps,
    | 'freeSolo'
    | 'options'
    | 'loading'
    | 'inputValue'
    | 'isOptionEqualToValue'
    | 'onInputChange'
    | 'getOptionLabel'
    | 'renderOption'
    | 'renderStartAdornment'
  > {
  /**
   * The user statuses to search for.
   *
   * @default ['active']
   */
  organizationUserStatuses?: IAutocompleteOrganizationUserQueryVariables['statuses']
  /**
   * The user permissions to filter by.
   *
   * @default []
   * @example ['routing_guide.create_lane']
   */
  organizationUserPermissions?: (keyof IUserPermissions)[]
  /**
   * @default 'AutocompleteOrganizationUser'
   */
  dataTest?: string
}

export const AutocompleteOrganizationUser = memo<IAutocompleteOrganizationUserProps>(
  ({
    dataTest = 'AutocompleteOrganizationUser',
    organizationUserStatuses = DEFAULT_ORGANIZATION_USER_STATUSES,
    organizationUserPermissions = [],
    ...props
  }) => {
    const [search, { data, loading }] = useAutocompleteOrganizationUserLazyQuery()

    const { searchOrganizationUsers } = data || {}
    const { nodes = [] } = searchOrganizationUsers || {}
    const organizationUsers = compact<IOrganizationUserResultFragment>(nodes)

    const searchByTerm = useCallback(
      (searchTerm: string) => {
        search({
          variables: {
            searchTerm,
            statuses: organizationUserStatuses,
            permissions: organizationUserPermissions,
          },
        })
      },
      [organizationUserPermissions, organizationUserStatuses, search]
    )

    const inputValueChangeHandler = debounce<NonNullable<IAutocompleteBaseProps['onInputChange']>>(
      (_, value) => {
        searchByTerm(value)
      },
      DEBOUNCE_MS
    )

    return (
      <Autocomplete<IOrganizationUserResultFragment>
        freeSolo={false}
        dataTest={dataTest}
        options={organizationUsers}
        loading={loading}
        onInputChange={inputValueChangeHandler}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={option => {
          const { user } = option || {}
          const { firstName, fullName, lastName } = user || {}
          return fullName || `${firstName} ${lastName}`
        }}
        renderOption={(optionProps, option, { inputValue: autocompleteInputValue }) => {
          return (
            <li {...optionProps}>
              <AutocompleteOrganizationUserOption
                option={option}
                inputValue={autocompleteInputValue}
                dataTest={`${dataTest}-${option.id}`}
              />
            </li>
          )
        }}
        renderStartAdornment={option => {
          const { user } = option || {}
          const { firstName, fullName: fullNameProp, lastName } = user || {}
          const fullName = user ? fullNameProp || `${firstName} ${lastName}` : ''
          return <Avatar name={fullName} size='xsmall' hideTooltip />
        }}
        {...props}
      />
    )
  }
)
