import { DefaultValues, useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddNewRFPFormSchema, TAddNewRFPFormSchema } from '../schema'

export const getDefaultValues = <TFieldValues>(values: DefaultValues<TFieldValues>) => {
  return AddNewRFPFormSchema.cast({
    ...values,
  })
}

export interface IUseEditAddNewuseAddNewRFPFormProps
  extends Omit<UseFormOptions<TAddNewRFPFormSchema>, 'resolver'> {}

export const useAddNewRFPForm = ({
  defaultValues = {},
  ...rest
}: IUseEditAddNewuseAddNewRFPFormProps) => {
  return useForm<TAddNewRFPFormSchema>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: yupResolver(AddNewRFPFormSchema),
    ...rest,
  })
}
