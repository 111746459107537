import React, { PropsWithChildren, useCallback, useState } from 'react'
import { ConfirmationModal, IModalAction } from 'dpl/components/Modals'
import { ToggleButton } from 'dpl/components/ToggleButton'
import { ClickAwayListener, makeStyles, Paper, Popper } from 'dpl/core'
import { DownArrowIcon } from 'dpl/icons'
import { useBreakpointContext } from 'dpl/providers'
import { boxShadowLevels, brandColors } from 'dpl/theme'

interface IToggleButtonPopperProps {
  label: string
  onClose: () => void
}

const useToggleButtonPopperStyles = makeStyles(theme => ({
  paper: {
    width: '282px', // per spec
    padding: theme.spacing(2),
    boxShadow: boxShadowLevels.medium,
    border: `1px solid ${brandColors.coolGray2}`,
  },
  toggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      background: brandColors.white,
      color: theme.palette.text.primary,
    },
  },
  popper: {
    position: 'relative',
    width: '100%',
  },
  mobileModalBody: {
    overflow: 'hidden',
  },
}))

export function ToggleButtonPopper({
  children,
  label,
  onClose,
}: PropsWithChildren<IToggleButtonPopperProps>) {
  const classes = useToggleButtonPopperStyles()
  const { isMobile } = useBreakpointContext()

  const [isOpen, setOpen] = useState(false)
  const anchorEl = React.useRef<HTMLDivElement>(null)

  const toggleMenu = useCallback(() => {
    setOpen(v => !v)
  }, [])

  const closeMenu = useCallback(() => {
    if (isOpen) {
      setOpen(false)
      onClose()
    }
  }, [isOpen, onClose])

  const mobileModalActions: IModalAction[] = [
    {
      label: 'Close',
      ButtonProps: {
        variant: 'text',
      },
      action: closeMenu,
    },
  ]

  return (
    <ClickAwayListener onClickAway={closeMenu} mouseEvent='onMouseDown'>
      <div>
        <div ref={anchorEl}>
          <ToggleButton
            onClick={toggleMenu}
            value={isOpen}
            selected={isOpen}
            endIcon={DownArrowIcon}
            className={classes.toggleButton}
            label={label}
          />
        </div>
        {isMobile ? (
          <ConfirmationModal
            open={isOpen}
            onClose={closeMenu}
            actions={mobileModalActions}
            bodyClassName={classes.mobileModalBody}>
            {children}
          </ConfirmationModal>
        ) : (
          <Popper
            open={isOpen}
            anchorEl={anchorEl.current}
            placement='bottom-start'
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 4],
                  },
                },
              ],
            }}>
            <Paper className={classes.paper}>{children}</Paper>
          </Popper>
        )}
      </div>
    </ClickAwayListener>
  )
}
