import invert from 'lodash/invert'
import { TAdjustmentRuleFormSteps } from './types'

export const SECTIONS_STEP_NUMBER = {
  reason: 0,
  rateStrategy: 1,
  timeframe: 2,
  factors: 3,
  review: 4,
}

export const STEPS_BY_NAME = invert(SECTIONS_STEP_NUMBER)

export const REASON_MAX_CHARACTER_COUNT = 65
export const REASON_MIN_CHARACTER_COUNT = 4

export const DEFAULT_FORM_STEPS_STATE: TAdjustmentRuleFormSteps = [
  'active',
  'inactive',
  'inactive',
  'inactive',
  'inactive',
]

export const EDIT_FORM_STEPS_STATE: TAdjustmentRuleFormSteps = [
  'review',
  'review',
  'review',
  'review',
  'active',
]
