import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants'
import { useAnalytics } from '#hooks'
import { IContractRfpStates } from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import { AccessRestrictedModal, Avatar, brandColors, IconButton, OverflowMenu } from 'dpl'
import { Divider, Box, makeStyles, Typography } from 'dpl/core'
import { useNavigateBack } from 'dpl/hooks'
import { EditIcon } from 'dpl/icons'
import { ArchiveIcon, CalendarIcon, CustomerIcon, MoreIcon } from 'dpl/icons/build'
import { PageTemplate } from 'dpl/templates'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags/src/FlagsContext'
import moment from 'moment'
import { StateChangeModal } from '../components/StateChangeModal'
import { useStateChangeModal } from '../components/StateChangeModal/useStateChangeModal'
import { UserAssignmentsAvatarGroup } from '../components/UserAssignmentsAvatarGroup'
import { getDuration } from '../utils/utils'
import {
  ArchiveContractRFPConfirmationModal,
  DateInfo,
  ImportRFPCard,
  ImportRFPSpinner,
  IUpdateUserAssignmentsModalProps,
  IUpdateDateModalProps,
  TitleActions,
  UpdateUserAssignmentsModal,
  UpdateDateModal,
  LaneList,
  UpdateAssigneeModal,
} from './components'
import { RoundsBar } from './components/RoundsBar'
import { TUpdateAssigneeFormSchema } from './components/UpdateAssigneeModal/UpdateAssigneeFormSchema'
import { useArchiveContractRfpMutation } from './graphql/ArchiveContractRfpMutation'
import { useContractRfpQuery } from './graphql/ContractRfp'
import { useUpdateDueDateMutation } from './graphql/UpdateDueDate'
import { useUpdateRfpMutation } from './graphql/UpdateRfp'
import { useRfpImporter } from './hooks'

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(4, 3),
  },
  detail: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  detailItem: {
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    borderRight: `1px solid ${brandColors.coolGray2}`,
    padding: theme.spacing(0, 2),
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  avatar: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  extra: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  importRfpCardContainer: {
    justifySelf: 'center',
    padding: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  valueWithIcon: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    minHeight: theme.spacing(4),
  },
}))

export function ContractRFPDetailPage() {
  const classes = useStyles()
  const { trackEvent } = useAnalytics()
  const navigate = useNavigateBack()
  const { userPermissions } = usePermissionsContext()
  const { isFlagEnabled } = useFlagsContext()
  const isMultipleTeamMembersEnabled = isFlagEnabled('rfp_multiple_team_members')
  const { createToggleHandler, modalState, toggleModal } = useModalState({
    updateDueDateModal: false,
    updateUserAssignmentsModal: false,
    updateAssigneeModal: false,
    archiveContractRfp: false,
    restrictedModal: false,
  })
  const { rfpId = '' } = useParams()
  const [selectedRoundIdState, setSelectedRoundId] = useState<string | undefined>()
  const { data, loading, refetch } = useContractRfpQuery({
    variables: { displayId: rfpId },
    skip: !rfpId,
  })
  const { contractRfps } = data || {}
  const { nodes = [] } = contractRfps || {}
  const [contractRfp] = nodes || []
  const {
    currentVersion,
    displayId,
    id,
    name,
    organizationShipper,
    state,
    userAssignments = [],
    versions,
    assignedToOrganizationUser,
  } = contractRfp || {}
  const startAt = moment(contractRfp?.startAt).format('MMM D, YYYY')
  const endAt = moment(contractRfp?.endAt).format('MMM D, YYYY')
  const duration = contractRfp && getDuration(contractRfp.startAt, contractRfp.endAt)
  const { changeRfpStatus, closeHandler, confirmHandler, moveRfp } = useStateChangeModal()
  const [updateRfp] = useUpdateRfpMutation({ refetchQueries: ['ContractRfp'] })
  const [updateDueDate] = useUpdateDueDateMutation({ refetchQueries: ['ContractRfp'] })
  const isArchived = state === IContractRfpStates.archived
  const isClosed = state === IContractRfpStates.closed
  const isSubmitted = state === IContractRfpStates.submitted
  const showImportCard = !isArchived && !isClosed && !loading
  const selectedRoundId = selectedRoundIdState || currentVersion?.id
  const selectedVersion = versions?.find(version => version.id === selectedRoundId)

  const {
    checkImportStatusHandler,
    importHandler,
    importStatus,
    importing,
    isFirstLoadDone,
    resetImportStatus,
    validating,
  } = useRfpImporter({
    contractRfpVersionId: currentVersion?.id,
    onImportSuccess: refetch,
    currentRoundLaneCount: currentVersion?.lanes.totalCount ?? 0,
  })

  const onDueDateConfirm: IUpdateDateModalProps['onConfirm'] = useCallback(
    async (_, newDate) => {
      const dueAt = moment(newDate).toISOString()
      const input = { dueAt, id: contractRfp?.currentVersion.id }

      try {
        const { data, errors: apolloErrors } = await updateDueDate({
          variables: {
            input,
          },
          refetchQueries: ['ContractRfp'],
        })
        const { upsertContractRfpVersion } = data || {}
        const { errors } = upsertContractRfpVersion || {}

        if (apolloErrors?.length) {
          trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
            apolloErrors,
            variables: input,
            field: 'dueDate',
          })
          return new Error(apolloErrors[0].message)
        }

        if (errors?.length) {
          trackEvent('Contract RFPs', 'UPDATE_CONTRACT_RFP_ERROR', {
            errors,
            variables: input,
            field: 'dueDate',
          })
          return new Error(errors[0].message)
        }

        toggleModal('updateDueDateModal')
      } catch (e) {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          errors: [e],
          variables: input,
          field: 'dueDate',
        })
        return e
      }

      trackEvent('Contract RFPs', 'UPDATE_CONTRACT_RFP_SUCCESS', {
        variables: input,
        field: 'dueDate',
      })

      return null
    },
    [updateDueDate, contractRfp?.id, toggleModal]
  )

  const onUserAssignmentsConfirm: IUpdateUserAssignmentsModalProps['onConfirm'] = useCallback(
    async userAssignmentsInput => {
      const input = { id: contractRfp?.id, userAssignments: userAssignmentsInput }

      try {
        const { data, errors: apolloErrors } = await updateRfp({
          variables: {
            input,
          },
          refetchQueries: ['ContractRfp'],
        })
        const { upsertContractRfp } = data || {}
        const { errors } = upsertContractRfp || {}

        if (apolloErrors?.length) {
          trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
            apolloErrors,
            variables: input,
            field: 'userAssignments',
          })

          return new Error(apolloErrors[0].message)
        }

        if (errors?.length) {
          trackEvent('Contract RFPs', 'UPDATE_CONTRACT_RFP_ERROR', {
            errors,
            variables: input,
            field: 'userAssignments',
          })

          return new Error(errors[0].message)
        }

        toggleModal('updateUserAssignmentsModal')
      } catch (error) {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          errors: [error],
          variables: input,
          field: 'userAssignments',
        })
        return error
      }

      trackEvent('Contract RFPs', 'UPDATE_CONTRACT_RFP_SUCCESS', {
        variables: input,
        field: 'userAssignments',
      })

      return null
    },
    [contractRfp?.id, trackEvent, updateRfp, toggleModal]
  )

  const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()
  const toggleArchiveContractRfpConfirmationModal = createToggleHandler('archiveContractRfp')

  const [archiveContractRfp, { data: archiveContractRfpData }] = useArchiveContractRfpMutation()
  const { transitionContractRfpState: archiveContractRfpResult } = archiveContractRfpData ?? {}
  const { errors: archiveContractRfpErrors } = archiveContractRfpResult ?? {}
  const archiveContractRfpErrorMessages = archiveContractRfpErrors?.map(({ message }) => message)
  const archiveContractRfpHandler = useCallback(async () => {
    const variables = {
      id: id || '',
      submittedAt: new Date().toISOString(),
    }
    const { data } = await archiveContractRfp({
      variables,
      onError: apolloError => {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          apolloError,
          variables,
        })
      },
    })

    const { transitionContractRfpState } = data ?? {}
    const { errors: archiveContractRfpErrors } = transitionContractRfpState ?? {}

    if (!archiveContractRfpErrors?.length) {
      toggleArchiveContractRfpConfirmationModal()
      trackEvent('Contract RFPs', 'ARCHIVE_CONTRACT_RFP_SUCCESS', {
        variables,
      })
      return
    }

    trackEvent('Contract RFPs', 'ARCHIVE_CONTRACT_RFP_ERROR', {
      errors: archiveContractRfpErrors,
      variables,
    })
  }, [archiveContractRfp, id, toggleArchiveContractRfpConfirmationModal])

  const overflowMenuOptions = useMemo(
    () => [
      {
        action: () => {
          if (!userPermissions['contract_rfp.update_rfp_state']) {
            toggleModal('restrictedModal', true)
            return
          }

          toggleArchiveContractRfpConfirmationModal()
        },
        itemComponent: (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            data-test='overflow-menu-archive-option'>
            <ArchiveIcon size='large' color='error1' />
            <Typography ml={1} color={brandColors.error1}>
              Archive RFP
            </Typography>
          </Box>
        ),
        disabled: isArchived,
        label: 'archive',
      },
    ],
    [isArchived, toggleArchiveContractRfpConfirmationModal]
  )

  const checkPermissions = useCallback(
    (permission: boolean, handler: () => void) => () => {
      if (!permission) {
        toggleModal('restrictedModal', true)
        return
      }

      handler()
    },
    [toggleModal]
  )

  const showStartNewRound =
    currentVersion?.id === selectedRoundId && (selectedVersion?.lanes.totalCount ?? 0) > 0

  // Keep the old assignee logic for when feature flag is off
  const { user } = assignedToOrganizationUser || {}
  const { fullName = '' } = user || {}
  const assignee = fullName || 'Unassigned'

  const onAssigneeConfirm = useCallback(
    async (newAssignee: TUpdateAssigneeFormSchema['assignee']) => {
      const input = { id: contractRfp?.id, assignedToOrganizationUserId: newAssignee.id }

      try {
        const { data, errors: apolloErrors } = await updateRfp({
          variables: {
            input,
          },
        })

        if (apolloErrors?.length) {
          trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
            apolloErrors,
            variables: input,
            field: 'assignee',
          })

          return new Error(apolloErrors[0].message)
        }

        const { errors } = data?.upsertContractRfp || {}

        if (errors?.length) {
          trackEvent('Contract RFPs', 'UPDATE_CONTRACT_RFP_ERROR', {
            errors,
            variables: input,
            field: 'assignee',
          })

          return new Error(errors[0].message)
        }

        toggleModal('updateAssigneeModal')
      } catch (error) {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          errors: [error],
          variables: input,
          field: 'assignee',
        })
        return error as Error
      }

      trackEvent('Contract RFPs', 'UPDATE_CONTRACT_RFP_SUCCESS', {
        variables: input,
        field: 'assignee',
      })

      return null
    },
    [contractRfp?.id, trackEvent, updateRfp, toggleModal]
  )

  if (loading) return null

  return (
    <PageTemplate
      dataTest='contract-rfp-detail-page'
      pageTitle={`RFP-${displayId}`}
      documentTitle={`RFP-${displayId}`}
      PageBodyTitleProps={{
        extra: contractRfp && (
          <div className={classes.extra}>
            <TitleActions
              contractId={id || ''}
              onStatusChange={state => changeRfpStatus(contractRfp, state)}
              {...contractRfp}
            />
            {!isArchived && (
              <OverflowMenu
                dataTest='contract-rfp-overflow-menu'
                dropdownId='contract-rfp-overflow-menu'
                anchorEl={anchorEl}
                expanded={expanded}
                onClose={handleClose}
                onSelectMenuItem={handleClose}
                options={overflowMenuOptions}
                trigger={
                  <IconButton
                    label='overflow-trigger'
                    Icon={MoreIcon}
                    onClick={handleClick}
                    size='xlarge'
                    color='secondary'
                  />
                }
              />
            )}
          </div>
        ),
        pageType: 'subPage',
        onBackButtonClick: () => {
          navigate({
            path: PROTECTED_PATHS.contractRFPs,
          })
        },
      }}
      noPadding>
      <div className={classes.title}>
        <Typography variant='h3'>{name}</Typography>
        <div className={classes.detail}>
          <div className={classes.detailItem}>
            <Typography variant='caption' color={brandColors.coolGray5}>
              Customer
            </Typography>
            <div className={classes.valueWithIcon}>
              <CustomerIcon size='medium' />
              <Typography variant='body1'>{organizationShipper?.name}</Typography>
            </div>
          </div>
          <div className={classes.detailItem}>
            <Typography variant='caption' color={brandColors.coolGray5}>
              Contract
            </Typography>
            <div className={classes.valueWithIcon}>
              <CalendarIcon size='medium' />
              <Typography variant='body1'>
                {startAt} - {endAt} ({duration})
              </Typography>
            </div>
          </div>
          {contractRfp &&
            [IContractRfpStates.planning, IContractRfpStates.in_progress].includes(
              contractRfp.state as IContractRfpStates
            ) && (
              <div className={classes.detailItem}>
                <Typography variant='caption' color={brandColors.coolGray5}>
                  Due Date
                </Typography>
                <DateInfo date={contractRfp.currentVersion.dueAt} showPastDue>
                  <IconButton
                    color='secondary'
                    Icon={EditIcon}
                    onClick={checkPermissions(
                      userPermissions['contract_rfp.update_rfp_versions'],
                      createToggleHandler('updateDueDateModal', true)
                    )}
                  />
                </DateInfo>
              </div>
            )}
          <div className={classes.detailItem}>
            <Typography variant='caption' color={brandColors.coolGray5}>
              {isMultipleTeamMembersEnabled ? 'Team' : 'Assignee'}
            </Typography>
            <Typography variant='body1' className={classes.avatar}>
              {isMultipleTeamMembersEnabled ? (
                <UserAssignmentsAvatarGroup userAssignments={userAssignments} />
              ) : (
                <>
                  <Avatar name={assignee} size='xsmall' /> {assignee}
                </>
              )}
              {contractRfp &&
                [
                  IContractRfpStates.planning,
                  IContractRfpStates.in_progress,
                  IContractRfpStates.submitted,
                ].includes(contractRfp.state as IContractRfpStates) && (
                  <IconButton
                    size='small'
                    Icon={EditIcon}
                    onClick={checkPermissions(
                      userPermissions['contract_rfp.update_rfp'],
                      createToggleHandler(
                        isMultipleTeamMembersEnabled
                          ? 'updateUserAssignmentsModal'
                          : 'updateAssigneeModal',
                        true
                      )
                    )}
                  />
                )}
            </Typography>
          </div>
        </div>
      </div>
      <Divider />
      {selectedRoundId && (
        <>
          <RoundsBar
            currentRoundId={currentVersion?.id!}
            selectedRoundId={selectedRoundId}
            setSelectedRoundId={setSelectedRoundId}
            rounds={versions!}
            showStartNewRound={showStartNewRound}
            contractId={id || ''}
            displayId={displayId ?? 0}
            disableStartNewRound={isArchived || isClosed}
            contractStartAt={contractRfp?.startAt || ''}
            contractEndAt={contractRfp?.endAt || ''}
            disableOptions={isArchived || isClosed || isSubmitted}
          />
          <Divider />
        </>
      )}
      {moveRfp && (
        <StateChangeModal
          rfp={moveRfp.item}
          newState={moveRfp.newColumnId}
          onClose={closeHandler}
          onConfirm={confirmHandler}
        />
      )}
      {modalState.updateDueDateModal && (
        <UpdateDateModal
          currentDate={contractRfp?.currentVersion.dueAt || ''}
          field='dueAt'
          onClose={createToggleHandler('updateDueDateModal', false)}
          onConfirm={onDueDateConfirm}
          title='Edit Due Date'
          inputLabel='Due Date'
        />
      )}
      {modalState.updateUserAssignmentsModal && isMultipleTeamMembersEnabled && (
        <UpdateUserAssignmentsModal
          userAssignments={userAssignments}
          onClose={createToggleHandler('updateUserAssignmentsModal', false)}
          onConfirm={onUserAssignmentsConfirm}
        />
      )}
      {modalState.updateAssigneeModal && !isMultipleTeamMembersEnabled && (
        <UpdateAssigneeModal
          currentAssignee={assignedToOrganizationUser}
          onClose={createToggleHandler('updateAssigneeModal', false)}
          onConfirm={onAssigneeConfirm}
        />
      )}
      {contractRfp && (
        <ArchiveContractRFPConfirmationModal
          contractRfp={contractRfp}
          errorMessages={archiveContractRfpErrorMessages}
          open={modalState.archiveContractRfp}
          onClose={toggleArchiveContractRfpConfirmationModal}
          onConfirm={archiveContractRfpHandler}
        />
      )}
      {contractRfp &&
        selectedVersion &&
        currentVersion?.id === selectedRoundId &&
        !selectedVersion?.lanes.totalCount &&
        showImportCard && (
          <Box className={classes.importRfpCardContainer}>
            {!isFirstLoadDone || importing ? (
              <ImportRFPSpinner isImporting={importing} />
            ) : (
              <ImportRFPCard
                onImport={importHandler}
                importStatus={importStatus}
                validating={validating}
                rounds={versions!}
                onCheckStatus={checkImportStatusHandler}
                rfp={contractRfp}
                resetImportStatus={resetImportStatus}
              />
            )}
          </Box>
        )}
      {(Boolean(selectedVersion?.lanes.totalCount) || !showImportCard) && (
        <LaneList
          rfpId={rfpId}
          contractRfpVersionId={selectedRoundId!}
          readOnly={
            selectedVersion?.id !== currentVersion?.id ||
            [
              IContractRfpStates.archived,
              IContractRfpStates.submitted,
              IContractRfpStates.closed,
            ].includes(state as IContractRfpStates)
          }
        />
      )}
      {modalState.restrictedModal && (
        <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
      )}
    </PageTemplate>
  )
}
