import React, { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { ICountries, ICountrySubdivisionCodes, IRoutingGuideLaneSortBy } from '#types/graphqlTypes'
import { AccessRestrictedModal } from 'dpl/components/Modals/AccessRestrictedModal'
import { FeatureUnavailableModal } from 'dpl/components/Modals/FeatureUnavailableModal'
import { AddIcon } from 'dpl/icons'
import { PageTemplate } from 'dpl/templates'
import { getGraphqlAfterCursor } from 'dpl/utils/graphql/getGraphqlAfterCursor'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import { RoutingGuideLanesDataGrid } from './components/RoutingGuideLanesDataGrid'
import {
  IRoutingGuideLanesDataGrid,
  IRoutingGuideLanesDataGridFilters,
} from './components/RoutingGuideLanesDataGrid/types'
import { RoutingGuideLanesEmptyCard } from './components/RoutingGuideLanesEmptyCard'
import { IRoutingGuideLaneFormModalProps, RoutingGuideLaneFormModal } from './forms'
import { IRgLaneFragment } from './graphql/RGLaneFragment'
import { useRoutingGuideLanesQuery } from './graphql/RoutingGuideLanes'
import { useRGLaneCreateModal } from './hooks'

export interface IRoutingGuideLanesPageProps {}

const PAGE_SIZE = 50

export const RoutingGuideLanesPage = memo<IRoutingGuideLanesPageProps>(() => {
  const navigate = useNavigate()

  const {
    closeCreateModal,
    isAccessRestrictedModalOpen,
    isCreateModalOpen,
    isFeatureUnavailableModalOpen,
    openCreateModal,
  } = useRGLaneCreateModal()

  const createSuccessHandler = useCallback<IRoutingGuideLaneFormModalProps['onSuccess']>(
    newRoutingGuideLaneId => {
      navigate(
        `${PROTECTED_PATHS.routingGuideLanes}/${newRoutingGuideLaneId}/carriers?create=true`,
        {
          replace: true,
        }
      )
    },
    [navigate]
  )

  const [page, setPage] = useState<number>(0)

  const [filters, setFilters] = useState<IRoutingGuideLanesDataGridFilters>({
    displayId: null,
    equipments: [],
    laneManager: null,
    location: {
      originCity: null,
      originStateCode: null,
      destinationCity: null,
      destinationStateCode: null,
    },
    customer: null,
  })

  const hasSearchFilters = useMemo(() => !isEmpty(filters), [filters])

  const paginationChangeHanlder = useCallback<
    NonNullable<IRoutingGuideLanesDataGrid['onPaginationModelChange']>
  >(paginationModel => {
    const { page } = paginationModel || {}
    setPage(page)
  }, [])

  const { data, loading, refetch } = useRoutingGuideLanesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      first: PAGE_SIZE,
      after: getGraphqlAfterCursor({ pageNumber: page, pageSize: PAGE_SIZE }),
    },
  })

  const handleFilterOrSortChange = useCallback<
    NonNullable<IRoutingGuideLanesDataGrid['onFilterOrSortChange']>
  >(
    params => {
      const { customer, displayId: id, equipments, laneManager, location, sort: sortOrder } = params
      const { destinationCity, destinationStateCode, originCity, originStateCode } = location || {}

      const filters = {
        displayId: id,
        equipments,
        laneManager,
        location,
        customer,
      }
      setFilters(filters)

      const strippedId = id?.replace(/rg-/i, '')
      const displayId = strippedId ? parseInt(strippedId, 10) : null
      const { id: laneManagerId } = laneManager || {}
      const { id: customerId } = customer || {}

      const pickupLocation =
        originCity && originStateCode
          ? {
              city: originCity,
              stateCode: originStateCode as ICountrySubdivisionCodes,
              countryCode: ICountries.US,
            }
          : null

      const deliveryLocation =
        destinationCity && destinationStateCode
          ? {
              city: destinationCity,
              stateCode: destinationStateCode as ICountrySubdivisionCodes,
              countryCode: ICountries.US,
            }
          : null

      refetch({
        filters: {
          sortBy: IRoutingGuideLaneSortBy.created_at,
          sortOrder,
          displayId,
          equipmentKeys: equipments,
          laneManagerId,
          pickupLocation,
          deliveryLocation,
          customerId,
        },
      })
    },
    [refetch]
  )

  const { routingGuideLanes } = data || {}
  const { edges, totalCount } = routingGuideLanes || {}
  const lanes = edges?.map(edge => edge?.node)
  const rows = compact<IRgLaneFragment>(lanes)

  const showEmptyCard = useMemo(() => {
    if (loading) {
      return false
    }
    if (hasSearchFilters) {
      return false
    }
    return rows.length === 0
  }, [loading, rows, hasSearchFilters])

  const showDataGrid = useMemo(() => {
    if (loading) {
      return true
    }

    if (hasSearchFilters) {
      return true
    }
    return rows.length > 0
  }, [loading, rows, hasSearchFilters])

  const paginationModel = useMemo(
    () => ({
      pageSize: PAGE_SIZE,
      page,
    }),
    [page]
  )

  return (
    <PageTemplate
      dataTest='route-routing-guide'
      pageTitle='Routing Guide'
      PageBodyTitleProps={{
        showDivider: false,
        actions: [
          {
            label: 'Add New Lane',
            onClick: openCreateModal,
            buttonProps: {
              color: 'primary',
              variant: 'contained',
              size: 'small',
              startIcon: <AddIcon size='large' />,
            },
          },
        ],
      }}>
      <div>
        {showEmptyCard && (
          <RoutingGuideLanesEmptyCard onAddRoutingGuideLaneClick={openCreateModal} />
        )}
        {showDataGrid && (
          <RoutingGuideLanesDataGrid
            rows={rows}
            loading={loading}
            onPaginationModelChange={paginationChangeHanlder}
            paginationModel={paginationModel}
            rowCount={totalCount}
            onFilterOrSortChange={handleFilterOrSortChange}
          />
        )}
      </div>
      <AccessRestrictedModal open={isAccessRestrictedModalOpen} onClose={closeCreateModal} />
      <FeatureUnavailableModal open={isFeatureUnavailableModalOpen} onClose={closeCreateModal} />
      <RoutingGuideLaneFormModal
        open={isCreateModalOpen}
        onClose={closeCreateModal}
        onSuccess={createSuccessHandler}
      />
    </PageTemplate>
  )
})
