import { DEFAULT_FLAT_VOLUME_PER_MONTH } from '../constants'
import { IDynamicVolumeSchema } from '../schema'
import { getMonthYearRange } from './getMonthYearRange'

interface IGetUpdatedDynamicVolumePerMonthProps {
  contractEndDate: string
  contractStartDate: string
  dynamicVolumePerMonth: IDynamicVolumeSchema[]
}

export const getUpdatedDynamicVolumePerMonth = ({
  contractEndDate,
  contractStartDate,
  dynamicVolumePerMonth,
}: IGetUpdatedDynamicVolumePerMonthProps) => {
  const dateVolumeRange = getMonthYearRange({
    startDate: contractStartDate,
    endDate: contractEndDate,
  })

  dynamicVolumePerMonth.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  dateVolumeRange.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

  const updatedDynamicVolumePerMonth = dateVolumeRange.map((date, index) => {
    if (dynamicVolumePerMonth[index] && dynamicVolumePerMonth[index].date === date) {
      return {
        date,
        volume: dynamicVolumePerMonth[index].volume,
      }
    }
    return {
      date,
      volume: DEFAULT_FLAT_VOLUME_PER_MONTH,
    }
  })

  return updatedDynamicVolumePerMonth
}
