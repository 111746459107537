import { IRfpLaneFragment } from './graphql/RfpLane'

export interface ILane {
  id: IRfpLaneFragment['id']
  externalId: IRfpLaneFragment['externalId']
  pickup: IRfpLaneFragment['pickupLocation']
  delivery: IRfpLaneFragment['deliveryLocation']
  laneArrowMultiStop: {
    numStops: number
    stops: IRfpLaneFragment['stops']
  }
  lane: {
    pickupLocation: IRfpLaneFragment['pickupLocation']
    deliveryLocation: IRfpLaneFragment['deliveryLocation']
    stops: IRfpLaneFragment['stops']
  }
  miles: IRfpLaneFragment['customMiles'] | IRfpLaneFragment['calculatedMiles']
  equipment: {
    isDeliveryDropTrailer: IRfpLaneFragment['isDeliveryDropTrailer']
    isPickupDropTrailer: IRfpLaneFragment['isPickupDropTrailer']
    equipment: IRfpLaneFragment['equipment']
  }
  volume: {
    totalVolume: IRfpLaneFragment['volumeTotalAmount']
    volumeAmount: IRfpLaneFragment['volumeFlatAmount']
    isDynamic: IRfpLaneFragment['isVolumeDynamic']
    volumeFrequency: IRfpLaneFragment['volumeFrequency']
  }
  rate: {
    carrierRate: Nullable<NonNullable<IRfpLaneFragment['rate']>['carrierRate']>
    rateType: Nullable<NonNullable<IRfpLaneFragment['rate']>['rateType']>
    fuelRate: Nullable<NonNullable<IRfpLaneFragment['rate']>['fuelRatePerMile']>
    shipperRate: Nullable<NonNullable<IRfpLaneFragment['rate']>['shipperRate']>
    source: Nullable<NonNullable<IRfpLaneFragment['rate']>['source']>
    createdAt: Nullable<NonNullable<IRfpLaneFragment['rate']>['createdAt']>
  }
  margin: {
    marginAmount: Nullable<NonNullable<IRfpLaneFragment['rate']>['marginAmount']>
    marginPercentage: Nullable<NonNullable<IRfpLaneFragment['rate']>['marginPercentage']>
  }
  rategenMetadata: {
    failureReasons: Nullable<NonNullable<IRfpLaneFragment['laneRategenMetadata']>['failureReasons']>
    status: Nullable<NonNullable<IRfpLaneFragment['laneRategenMetadata']>['status']>
  }
}

export function convertData(lanes: IRfpLaneFragment[]) {
  return lanes.map((lane): ILane => {
    return {
      id: lane.id,
      externalId: lane.externalId,
      pickup: lane.pickupLocation,
      delivery: lane.deliveryLocation,
      laneArrowMultiStop: {
        numStops: lane.stops.length,
        stops: lane.stops,
      },
      lane: {
        pickupLocation: lane.pickupLocation,
        deliveryLocation: lane.deliveryLocation,
        stops: lane.stops,
      },
      miles: lane.customMiles ?? lane.calculatedMiles,
      equipment: {
        isDeliveryDropTrailer: lane.isDeliveryDropTrailer,
        isPickupDropTrailer: lane.isPickupDropTrailer,
        equipment: lane.equipment,
      },
      volume: {
        totalVolume: lane.volumeTotalAmount,
        volumeAmount: lane.volumeFlatAmount,
        isDynamic: lane.isVolumeDynamic,
        volumeFrequency: lane.volumeFrequency,
      },
      rate: {
        carrierRate: lane.rate && lane.rate.carrierRate,
        rateType: lane.rate && lane.rate.rateType,
        fuelRate: lane.rate && lane.rate.fuelRatePerMile,
        shipperRate: lane.rate && lane.rate.shipperRate,
        source: lane.rate && lane.rate.source,
        createdAt: lane.rate && lane.rate.createdAt,
      },
      margin: {
        marginAmount: lane.rate && lane.rate.marginAmount,
        marginPercentage: lane.rate && lane.rate.marginPercentage,
      },
      rategenMetadata: {
        failureReasons: lane.laneRategenMetadata?.failureReasons,
        status: lane.laneRategenMetadata?.status,
      },
    }
  })
}

export const PAGE_RANGES = [50, 100, 200]
