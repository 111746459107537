import React, { memo } from 'react'
import { Button } from 'dpl/core'
import { useCarrierRatesReorderContext } from '../../context'

export interface ICarrierRatesReorderButtonsProps {
  /**
   * @default 'CarrierRatesReorderButtons'
   */
  dataTest?: string
}

export const CarrierRatesReorderButtons = memo<ICarrierRatesReorderButtonsProps>(
  ({ dataTest = 'CarrierRatesReorderButtons' }) => {
    const { reorderLoading, saveOrder, toggleReordering } = useCarrierRatesReorderContext()

    return (
      <>
        <Button
          size='small'
          variant='text'
          onClick={toggleReordering}
          disabled={reorderLoading}
          data-test={`${dataTest}-cancel-button`}>
          Cancel
        </Button>
        <Button
          size='small'
          color='secondary'
          onClick={saveOrder}
          disabled={reorderLoading}
          data-test={`${dataTest}-save-order-button`}>
          Save Order
        </Button>
      </>
    )
  }
)
