import React, { useCallback, useMemo, useState } from 'react'
import { useAnalytics } from '#hooks'
import { IAttachedFile, IWebLink } from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import { AccessRestrictedModal, brandColors, UploadInputButton, useToastContext } from 'dpl'
import SidePanel, { ISidePanelProps } from 'dpl/components/SidePanel'
import { Button, makeStyles } from 'dpl/core'
import { AttachmentIcon, LinkIcon } from 'dpl/icons'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import compact from 'lodash/compact'
import orderBy from 'lodash/orderBy'
import { LinkModal, Item } from './components'
import { useFilesQuery } from './graphql/Files'
import { useRemoveFileMutation } from './graphql/RemoveFile'
import { useUploadFileMutation } from './graphql/UploadFile'
import { useFiles } from './useFiles'

export interface IFilesSideNavProps extends Pick<ISidePanelProps, 'onClose'> {
  contractId: string
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 96,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(3),
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: brandColors.white,
    zIndex: 1,
  },
  options: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

export function FilesSideNav({ contractId, onClose }: IFilesSideNavProps) {
  const { openToast } = useToastContext()
  const { trackEvent } = useAnalytics()
  const [loadingFile, setLoadingFile] = useState<
    | (Pick<IAttachedFile, 'filename' | 'createdAt'> & {
        type: 'loadingFile'
      })
    | null
  >(null)
  const classes = useStyles()
  const [editIndex, setEditIndex] = useState<number | undefined>()
  const [remove] = useRemoveFileMutation()
  const [upload] = useUploadFileMutation()
  const { userPermissions } = usePermissionsContext()
  const { createToggleHandler, modalState, toggleModal } = useModalState({
    addNewLink: false,
    restrictedModal: false,
  })
  const { data } = useFilesQuery({
    variables: {
      id: contractId,
    },
  })
  const { contractRfps } = data || {}
  const { nodes } = contractRfps || {}
  const [contractRfp] = nodes || []
  const {
    webLinks: webLinksProp = [],
    uploadedFiles: uploadedFilesProp = [],
    versions,
  } = contractRfp || {}
  const { deleteLink } = useFiles({
    contractId,
    webLinks: webLinksProp ?? [],
  })

  const checkPermissions = useCallback(
    (permission: boolean, handler: () => void) => () => {
      if (!permission) {
        toggleModal('restrictedModal', true)
        return
      }

      handler()
    },
    [toggleModal]
  )

  const allFiles = useMemo(() => {
    const webLinks: (Pick<IWebLink, 'createdAt' | 'name' | 'url'> & {
      type: 'link'
      relativeIndex: number
    })[] = (webLinksProp ?? []).map(({ createdAt, name, url }, index) => ({
      type: 'link',
      createdAt: createdAt || '',
      name: name || '',
      url,
      relativeIndex: index,
    }))

    const uploadedFiles: (Pick<IAttachedFile, 'filename' | 'id' | 'createdAt' | 'url'> & {
      type: 'file'
    })[] = (uploadedFilesProp ?? []).map(({ createdAt, filename, id, url }) => ({
      type: 'file',
      createdAt,
      filename,
      id,
      url,
    }))

    const versionFiles: (Pick<IAttachedFile, 'filename' | 'id' | 'createdAt' | 'url'> & {
      type: 'rfpFile'
    })[] = (versions ?? [])
      .filter(({ rfpFile }) => Boolean(rfpFile))
      .map(({ rfpFile }) => ({
        type: 'rfpFile',
        createdAt: rfpFile?.createdAt || '',
        filename: rfpFile?.filename || '',
        id: rfpFile?.id || '',
        url: rfpFile?.url || '',
      }))

    return orderBy(
      [...(loadingFile ? [loadingFile] : []), ...webLinks, ...uploadedFiles, ...versionFiles],
      [({ createdAt }) => new Date(createdAt)],
      ['desc']
    )
  }, [uploadedFilesProp, webLinksProp, loadingFile])

  return (
    <SidePanel onClose={onClose} title={`Files (${allFiles.length})`} anchor='right'>
      <div className={classes.root}>
        {compact(allFiles).map(data => {
          if (data.type === 'rfpFile' || data.type === 'file') {
            return (
              <Item
                key={`${data.filename}-${data.createdAt}`}
                data={{
                  createdAt: data.createdAt || '',
                  name: data.filename || '',
                  isLoading: false,
                }}
                type={data.type}
                onDelete={checkPermissions(
                  userPermissions['contract_rfp.update_rfp_versions'],
                  async () => {
                    const input = { fileId: data.id, id: contractId }
                    try {
                      const { data: mutationData } = await remove({
                        variables: { input },
                        refetchQueries: ['Files'],
                      })

                      const { removeFileFromContractRfp } = mutationData || {}
                      const { errors } = removeFileFromContractRfp || {}

                      if (errors?.length) {
                        openToast({
                          toastMessage: errors[0].message,
                          toastType: 'alert',
                        })

                        trackEvent('Contract RFPs', 'DELETE_FILE_ERROR', {
                          variables: input,
                          errors,
                        })
                      } else {
                        trackEvent('Contract RFPs', 'DELETE_FILE_SUCCESS', {
                          variables: input,
                        })

                        openToast({
                          toastMessage: 'File deleted successfully',
                        })
                      }
                    } catch (error) {
                      trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
                        variables: input,
                        apolloErrors: [error],
                      })

                      openToast({
                        toastMessage: 'There was an error deleting file',
                        toastType: 'alert',
                      })
                    }
                  }
                )}
                onDownload={() => {
                  trackEvent('Contract RFPs', 'DOWNLOAD_FILE_SUCCESS', {
                    variables: data,
                  })

                  window.open(data.url, '_blank')?.focus()
                }}
              />
            )
          }
          if (data.type === 'link') {
            return (
              <Item
                key={`${data.name}-${data.createdAt}`}
                data={{
                  link: data.url,
                  name: data.name || '',
                }}
                type='link'
                onDelete={checkPermissions(
                  userPermissions['contract_rfp.update_rfp_versions'],
                  async () => {
                    const error = await deleteLink({ index: data.relativeIndex })

                    if (error) {
                      openToast({
                        toastMessage: 'There was an error deleting link',
                        toastType: 'alert',
                      })
                    } else {
                      openToast({
                        toastMessage: 'Link deleted successfully',
                      })
                    }
                  }
                )}
                onEdit={checkPermissions(userPermissions['contract_rfp.update_rfp_versions'], () =>
                  setEditIndex(data.relativeIndex)
                )}
              />
            )
          }
          if (data.type === 'loadingFile') {
            return (
              <Item
                key='loading-file'
                data={{
                  createdAt: data.createdAt,
                  name: data.filename,
                  isLoading: true,
                }}
                type='loadingFile'
              />
            )
          }
          return null
        })}
      </div>
      <div className={classes.actions}>
        <Button variant='text' onClick={onClose}>
          Cancel
        </Button>
        <div className={classes.options}>
          {!userPermissions['contract_rfp.update_rfp_versions'] ? (
            <Button
              startIcon={<LinkIcon size='large' />}
              color='info'
              onClick={createToggleHandler('restrictedModal', true)}
              data-test='attach-file-button'>
              Attach
            </Button>
          ) : (
            <UploadInputButton
              label='Attach'
              disabled={Boolean(loadingFile)}
              onUpload={async uploadedFiles => {
                setLoadingFile({
                  filename: uploadedFiles[0].fileFileName,
                  createdAt: new Date().toISOString(),
                  type: 'loadingFile',
                })

                const fileEncoded = uploadedFiles[0].file as string
                const input = {
                  filename: uploadedFiles[0].fileFileName,
                  id: contractId,
                }
                try {
                  const { data } = await upload({
                    variables: {
                      input: {
                        ...input,
                        file: fileEncoded,
                      },
                    },
                    refetchQueries: ['Files'],
                  })
                  const { uploadFileToContractRfp } = data || {}
                  const { errors } = uploadFileToContractRfp || {}

                  if (errors?.length) {
                    openToast({
                      toastMessage: errors[0].message,
                      toastType: 'alert',
                    })

                    trackEvent('Contract RFPs', 'ATTACH_FILE_ERROR', {
                      variables: input,
                      errors,
                    })
                    return
                  }

                  trackEvent('Contract RFPs', 'ATTACH_FILE_SUCCESS', {
                    variables: input,
                  })

                  openToast({
                    toastMessage: 'File uploaded successfully',
                  })
                } catch (error) {
                  trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
                    variables: input,
                    errors: [error],
                  })

                  openToast({
                    toastMessage: 'There was an error uploading file',
                    toastType: 'alert',
                  })
                } finally {
                  setLoadingFile(null)
                }
              }}
              ButtonProps={{
                color: 'info',
                startIcon: <AttachmentIcon size='large' />,
              }}
              InputProps={{
                accept: '*',
              }}
            />
          )}
          <Button
            data-test='link-button'
            startIcon={<LinkIcon size='large' />}
            color='info'
            onClick={checkPermissions(
              userPermissions['contract_rfp.update_rfp_versions'],
              createToggleHandler('addNewLink', true)
            )}>
            Add Link
          </Button>
        </div>
      </div>
      {modalState.addNewLink && (
        <LinkModal
          contractId={contractId}
          webLinks={webLinksProp ?? []}
          onClose={createToggleHandler('addNewLink', false)}
        />
      )}
      {typeof editIndex === 'number' && (
        <LinkModal
          editIndex={editIndex}
          contractId={contractId}
          webLinks={webLinksProp ?? []}
          onClose={() => setEditIndex(undefined)}
        />
      )}
      {modalState.restrictedModal && (
        <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
      )}
    </SidePanel>
  )
}
