import { useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EmailFormSchema, TEmailFormSchema } from '../schema'

interface IUseEmailFormProps extends Omit<UseFormOptions<TEmailFormSchema>, 'resolver'> {}

export const useEmailForm = ({ defaultValues = {}, ...props }: IUseEmailFormProps) => {
  return useForm<TEmailFormSchema>({
    resolver: yupResolver(EmailFormSchema),
    defaultValues: EmailFormSchema.cast(defaultValues),
    ...props,
  })
}
