import React from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { PROTECTED_PATHS, UNPROTECTED_PATHS } from '#constants/paths'
import { ApolloProvider } from '@apollo/client'
import { AirhornProvider } from 'airhorn'
import { AuthProvider, IAuthClientOptions } from 'auth/b2b'
import { ToastProvider } from 'dpl/components/Toast'
import { BreakpointProvider, ThemeProvider } from 'dpl/providers'
import { FlagsProvider } from 'flags'
import { getAppGraphQLClient } from 'network'
import { SOLUTIONS_CONSOLE_EVENTS } from './constants/events'
import { UserDetailsProvider } from './context'
import { useUserPermissionsLazyQuery } from './graphql/UserPermissions'
import { Router } from './routes'

const { VITE_BASE_API, VITE_INTERCOM_APP_ID, VITE_STYTCH_COOKIE_DOMAIN, VITE_STYTCH_PUBLIC_TOKEN } =
  import.meta.env

const clientOptions: IAuthClientOptions = {
  baseApi: `${VITE_BASE_API}/authentication/v1/b2b`,
  publicToken: VITE_STYTCH_PUBLIC_TOKEN,
  cookieOptions: {
    path: '/',
    domain: VITE_STYTCH_COOKIE_DOMAIN,
    opaqueTokenCookieName: 'b2b_auth_session',
    jwtCookieName: 'b2b_auth_session_jwt',
    istCookieName: 'b2b_auth_intermediate_session_token',
    availableToSubdomains: true,
  },
}

const graphQLClient = getAppGraphQLClient({
  onError: () => {
    // TODO: Handle Errors
  },
  onUnauthorized: () => {
    // TODO: Handle log out
  },
})

const flags = new Set([
  'enable_org_nav_updates',
  'create_customer',
  'edit_customer',
  'routing_guide_activity_tab',
  'routing_guide_carrier_rate_create',
  'routing_guide_carrier_rate_edit',
  'routing_guide_carrier_rate_reorder',
  'routing_guide_lane_create',
  'routing_guide_lane_search_filters',
  'rates_demo_enhancements',
  'rates_ui_improvements',
  'shipper_management',
  'spot_rates_control_panel',
  'spot_rates_control_panel_rules',
  'spot_rates_control_panel_rules_dnb',
  'spot_rates_rules_visibility',
  'quotes_rules_visibility',
  'update_customer_status',
  'contract_rates_tool',
  'contract_rates_tool_projected_margin',
  'rfp_dynamic_volume',
  'rfp_rate_gen',
  'rfp_add_new_lane',
  'api_quotes_filter',
  'rfp_multiple_team_members',
])

export function App() {
  return (
    <IntercomProvider appId={VITE_INTERCOM_APP_ID}>
      <ApolloProvider client={graphQLClient}>
        <FlagsProvider baseApiUrl={VITE_BASE_API} customFlags={flags}>
          <ThemeProvider>
            <AirhornProvider
              appName='Solutions Console'
              customFeatures={SOLUTIONS_CONSOLE_EVENTS}
              config={{ service: 'console' }}>
              <BreakpointProvider>
                <ToastProvider>
                  <AuthProvider
                    clientOptions={clientOptions}
                    discoveryRedirectTo={UNPROTECTED_PATHS.login}
                    signInRedirectTo={PROTECTED_PATHS.home}
                    usePermissionsLazyQuery={useUserPermissionsLazyQuery}>
                    <UserDetailsProvider>
                      <Router />
                    </UserDetailsProvider>
                  </AuthProvider>
                </ToastProvider>
              </BreakpointProvider>
            </AirhornProvider>
          </ThemeProvider>
        </FlagsProvider>
      </ApolloProvider>
    </IntercomProvider>
  )
}
