import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { IProductModules } from '#types/graphqlTypes'
import { FeatureUnavailableModal } from 'dpl'
import { AccessRestrictedModal } from 'dpl/components/Modals/AccessRestrictedModal'
import { AddIcon } from 'dpl/icons'
import { PageTemplate } from 'dpl/templates'
import { useUserDetailsContext } from '../../../context/UserDetailsContext'
import { CustomersPageContent } from './components/CustomersPageContent'
import { CustomerDrawer } from './forms/CustomerDrawer/CustomerDrawer'
import { useCustomerModal } from './hooks/useCustomerModal'

export function CustomersPage() {
  const { activeProductModules = [] } = useUserDetailsContext()
  const navigate = useNavigate()

  const {
    closeModal,
    isAccessRestrictedModalOpen,
    isFeatureUnavailableModalOpen,
    isModalOpen,
    openModal,
  } = useCustomerModal({ isEditing: false })

  const onCloseRestrictedModalHandler = useCallback(() => {
    navigate(PROTECTED_PATHS.home, { replace: true })
  }, [navigate])

  if (!activeProductModules.includes(IProductModules.shipper_management)) {
    return (
      <PageTemplate pageTitle='Customers'>
        <AccessRestrictedModal open onClose={onCloseRestrictedModalHandler} />
      </PageTemplate>
    )
  }

  return (
    <PageTemplate
      pageTitle='Customers'
      dataTest='route-customers'
      PageBodyTitleProps={{
        showDivider: true,
        actions: [
          {
            label: 'Add Customer',
            onClick: openModal,
            buttonProps: {
              color: 'primary',
              variant: 'contained',
              size: 'small',
              startIcon: <AddIcon size='large' />,
            },
          },
        ],
      }}>
      <CustomerDrawer isEditing={false} open={isModalOpen} onClose={closeModal} />
      <AccessRestrictedModal open={isAccessRestrictedModalOpen} onClose={closeModal} />
      <FeatureUnavailableModal open={isFeatureUnavailableModalOpen} onClose={closeModal} />
      <CustomersPageContent />
    </PageTemplate>
  )
}
