import * as GeoJSON from 'geojson'
import { IMapRouteGroup } from '../types'
import { getCurvyLine } from './getCurvyLine'

export function getGeoJSONFeatureLineCollection<P extends IMapRouteGroup>(
  lines: P[]
): GeoJSON.FeatureCollection {
  const [origin, destination] = lines || []
  const { route: routeOrigin = [] } = origin || {}
  const { route: routeDestination = [] } = destination || {}
  const [firstRouteOrigin] = routeOrigin || []
  const [firstRouteDestination] = routeDestination || []
  const { coords: coordsFirstRouteOrigin } = firstRouteOrigin || {}
  const { coords: coordsFirstRouteDestination } = firstRouteDestination || {}
  return getCurvyLine(coordsFirstRouteOrigin, coordsFirstRouteDestination)
}
