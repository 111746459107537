import { IMapStopProps } from '../types'
import { getGeoJSONFeatureStopCollection } from './getGeoJSONFeatureStopCollection'

/**
 * Returns a GeoJSON object with coordinates
 * @example
 * ```
 * getGeoJSONStopSourceRaw([{ coords: { lat: 123, lon: 123 }}]) // { ... }
 * ```
 */
export function getGeoJSONStopSource<S extends IMapStopProps>(
  stops: S[]
): TrimbleMaps.GeoJSONSourceRaw {
  return {
    type: 'geojson',
    data: getGeoJSONFeatureStopCollection(stops),
  }
}
