import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { RateDifference } from '#components/RateDifference'
import { useAnalytics } from '#hooks'
import { IEstimate } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { sumCurrency } from '#utils/sumCurrency'
import {
  HtmlLabel,
  LineSubject,
  Annotation,
  bisectCenter,
  localPoint,
  Area,
  AxisBottom,
  AxisRight,
  NumberLike,
  Group,
  LinePath,
  useParentSize,
  scaleBand,
  scaleLinear,
  max,
  min,
  TickLabelProps,
  GridRows,
  GridColumns,
  animated,
  useSpring,
  useSpringValue,
} from 'charts/core'
import { Box, Divider, Fade, makeStyles, Typography } from 'dpl/core'
import DropdownDownIcon from 'dpl/icons/build/DropdownDownIcon'
import DropdownUpIcon from 'dpl/icons/build/DropdownUpIcon'
import { brandColors, avatarColors } from 'dpl/theme/colors'
import moment from 'moment'
import { IPercentilesFragment } from '../graphql/CostModelRangeFragment'
import { IMarketForecastFragment } from '../graphql/MarketSimFragment'
import { NAMED_STRATEGY_BY_PERCENTILE } from './constants'

const AXIS_LABEL_FONT_SIZE = 10
const AXIS_LABEL_LINE_HEIGHT = AXIS_LABEL_FONT_SIZE * 1.4
const AXIS_LABEL_FONT_WEIGHT = 400

const AXIS_LABEL_MAX_FONT_SIZE = 12
const AXIS_LABEL_MAX_LINE_HEIGHT = AXIS_LABEL_MAX_FONT_SIZE * 1.4
const AXIS_TICK_LENGTH = 8

const HOVER_ANNOTATION_DX = 30
const HOVER_ANNOTATION_DY = -20

const MIN_LABEL_WIDTH = 122
const MIN_LABEL_HEIGHT = 45

const MIN_CONTRACT_HOVER_LABEL_HEIGHT = 84
const CONTRACT_HOVER_DY = 70

const MAX_CHART_HEIGHT = 350
const AXIS_COLOR = brandColors.coolGray5
const Y_AXIS_WIDTH = 80

const useStyles = makeStyles(theme => ({
  axis: {
    pointerEvents: 'none',
  },
  strategyTick: {
    padding: theme.spacing(0.25, 0.75),
    fontSize: AXIS_LABEL_FONT_SIZE,
    color: brandColors.coolGray5,
    backgroundColor: brandColors.coolGray3,
    borderRadius: theme.spacing(0.25),
    display: 'block',
    lineHeight: `${AXIS_LABEL_LINE_HEIGHT}px`,
  },
  selectedPercentile: {
    padding: theme.spacing(0.75),
    fontSize: AXIS_LABEL_MAX_FONT_SIZE,
    lineHeight: `${AXIS_LABEL_MAX_LINE_HEIGHT}px`,
    fontWeight: 700,
    color: brandColors.skyBlue6,
    backgroundColor: brandColors.white,
    borderRadius: theme.spacing(2.25),
    border: `2px solid ${brandColors.skyBlue6}`,
    whiteSpace: 'nowrap',
    pointerEvents: 'auto',
    cursor: 'default',
    transition: 'background 0.25s',
    '&:hover': {
      backgroundColor: brandColors.skyBlue0,
    },
  },
  marginAnnotationLabel: {
    backgroundColor: brandColors.coolGray8,
    color: brandColors.white,
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    flexDirection: 'column',
    minWidth: `${MIN_LABEL_WIDTH}px`,
    borderRadius: theme.spacing(0.25),
  },
  annotationTitle: {
    flex: 1,
    '& span': {
      color: brandColors.coolGray5,
    },
  },
  positive: {
    color: brandColors.green2,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  negative: {
    color: avatarColors.roseRed,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  percentilesTitle: {
    '& span': {
      color: brandColors.coolGray5,
    },
  },
  noPointerEvents: {
    pointerEvents: 'none',
  },
  rateItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
  },
  contractHoverLabel: {
    backgroundColor: brandColors.coolGray8,
    color: brandColors.white,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    minWidth: `${MIN_LABEL_WIDTH}px`,
    borderRadius: theme.spacing(0.25),
    fontSize: AXIS_LABEL_MAX_FONT_SIZE,
    lineHeight: `${AXIS_LABEL_MAX_LINE_HEIGHT}px`,
    position: 'relative',
  },
  hoverLabelDownIcon: {
    position: 'absolute',
    left: 'calc(50% - 24px)',
    bottom: '-27px',
  },
  hoverLabelUpIcon: {
    position: 'absolute',
    left: 'calc(50% - 24px)',
    top: '-27px',
  },
  divider: {
    margin: theme.spacing(1, 0),
    borderColor: brandColors.coolGray7,
  },
  percentileLabel: {
    color: brandColors.coolGray4,
  },
  boldestText: {
    fontWeight: 700,
  },
  fadeIn: {
    transition: 'opacity 0.25s',
  },
}))

const getPercentilesArray = (averageData: IPercentilesFragment) => {
  const {
    cost10,
    cost15,
    cost20,
    cost25,
    cost30,
    cost35,
    cost40,
    cost45,
    cost50,
    cost55,
    cost60,
    cost65,
    cost70,
    cost75,
    cost80,
    cost85,
    cost90,
  } = averageData || {}

  return [
    cost10,
    cost15,
    cost20,
    cost25,
    cost30,
    cost35,
    cost40,
    cost45,
    cost50,
    cost55,
    cost60,
    cost65,
    cost70,
    cost75,
    cost80,
    cost85,
    cost90,
  ]
}

const bottomAxisTickLabelProps = {
  fill: AXIS_COLOR,
  fontSize: AXIS_LABEL_FONT_SIZE,
  lineHeight: AXIS_LABEL_MAX_LINE_HEIGHT,
  textAnchor: 'middle',
  verticalAnchor: 'end',
  tickLength: 0,
  style: {
    fontWeight: AXIS_LABEL_FONT_WEIGHT,
  },
} as const

export const leftAxisTickLabelProps: TickLabelProps<NumberLike> = {
  fill: AXIS_COLOR,
  fontSize: AXIS_LABEL_FONT_SIZE,
  lineHeight: AXIS_LABEL_LINE_HEIGHT,
  textAnchor: 'start',
  style: {
    fontWeight: AXIS_LABEL_FONT_WEIGHT,
  },
}

const dateFormatter = (value: string) => moment(value).format("MMM 'YY")

interface HoveredContext {
  x: number
  xIndex: number
  y: number
  dx: number
  dy: number
  marginDifference: number
  isPositive: boolean
  marginDifferencePercentage: string
}

interface BandChartProps extends Pick<IEstimate, 'carrierRate' | 'marginPercentage'> {
  marketSimAvg: IMarketForecastFragment
  showVolatility: boolean
}

export interface IPercentiles extends Omit<IPercentilesFragment, '__typename'> {}

export function BandChart({
  carrierRate,
  marginPercentage,
  marketSimAvg,
  showVolatility,
}: BandChartProps) {
  const { trackEvent } = useAnalytics()
  const contractAnnotationRef = useRef(null)
  const { monthlyCost: monthlyCostProp, volumeWtdAvgCost: volumeWtdAvgCostProp } = marketSimAvg
  const monthlyCost = monthlyCostProp!
  const volumeWtdAvgCost = volumeWtdAvgCostProp!

  const percentileByValue = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { __typename, ...percentiles } = volumeWtdAvgCost
    const swapped = Object.entries(percentiles).map(([key, value]) => [
      currencyFormatter(value),
      key,
    ])

    return Object.fromEntries(swapped)
  }, [volumeWtdAvgCost])
  /**
   * type gymnastic to enable object indexing using selectedPercentile as key 🤸
   */
  const selectedPercentile = (percentileByValue[currencyFormatter(carrierRate)] ??
    'cost50') as keyof IPercentiles

  const contractRate = carrierRate * (marginPercentage + 1)
  const formattedContractRate = sumCurrency([carrierRate, carrierRate * marginPercentage])

  const { parentRef, width } = useParentSize()
  const chartWidth = width - Y_AXIS_WIDTH
  const chartHeight = MAX_CHART_HEIGHT - AXIS_LABEL_MAX_LINE_HEIGHT - AXIS_TICK_LENGTH
  const xAxisValues = useMemo(() => {
    return monthlyCost!.map(({ month }) => month)
  }, [monthlyCost])

  /**
   * more info on scales in d3 docs 👀
   * https://d3js.org/d3-scale/band
   */
  const xScale = scaleBand<string>({
    range: [0, chartWidth],
    domain: xAxisValues,
  })

  const getXPosition = useCallback(
    value => {
      const scalePosition = xScale(value) ?? 0
      const bandwidthOffset = xScale.bandwidth() / 2

      return scalePosition + bandwidthOffset
    },
    [xScale]
  )

  const xPositions = useMemo(() => {
    return xAxisValues.map(getXPosition) ?? []
  }, [getXPosition, xAxisValues])

  const [minYValue, maxYValue] = useMemo(() => {
    /**
     * min/max based on percentiles and contract rate (it can become the
     * lowest/highest based on the margin set by the user).
     */
    const minPercentile =
      min(monthlyCost, ({ costModelRange }) => min(getPercentilesArray(costModelRange))) ?? 0
    const maxPercentile =
      max(monthlyCost, ({ costModelRange }) => max(getPercentilesArray(costModelRange))) ?? 0
    const minY = min([contractRate, minPercentile]) ?? 0
    const maxY = max([contractRate, maxPercentile]) ?? 0

    return [minY, maxY]
  }, [contractRate, monthlyCost])

  const yScale = scaleLinear<number>({
    /**
     * Magic number that accounts for:
     * - centered tick labels
     * - contract label when is the max value
     */
    range: [chartHeight, 18],
    domain: [minYValue, maxYValue],
  })

  const yAxisThirdValue = (maxYValue - minYValue) / 3
  const yAxisTickValues = [
    minYValue,
    minYValue + yAxisThirdValue,
    minYValue + yAxisThirdValue * 2,
    maxYValue,
  ]

  const namedStrategies = useMemo(() => {
    return [
      {
        label: `${NAMED_STRATEGY_BY_PERCENTILE.cost20}
        (${currencyFormatter(volumeWtdAvgCost.cost20)})`,
        value: volumeWtdAvgCost.cost20,
      },
      {
        label: `${NAMED_STRATEGY_BY_PERCENTILE.cost50}
        (${currencyFormatter(volumeWtdAvgCost.cost50)})`,
        value: volumeWtdAvgCost.cost50,
      },
      {
        label: `${NAMED_STRATEGY_BY_PERCENTILE.cost80}
        (${currencyFormatter(volumeWtdAvgCost.cost80)})`,
        value: volumeWtdAvgCost.cost80,
      },
    ]
  }, [volumeWtdAvgCost])

  const [hoveredContext, setHoveredContext] = useState<HoveredContext | null>(null)
  const [hoveredContractContext, setHoveredContractContext] = useState<Record<
    string,
    boolean
  > | null>(null)

  const mouseMoveHandler = useCallback(
    (event: React.MouseEvent) => {
      const { x = 0 } = localPoint(event) ?? {}
      const closestXIndex = bisectCenter(xPositions, x)
      const xPosition = xPositions[closestXIndex]

      if (event.target === contractAnnotationRef.current) {
        setHoveredContext(null)
        setHoveredContractContext({
          shouldFlipDy:
            chartHeight <
            yScale(contractRate) + CONTRACT_HOVER_DY + MIN_CONTRACT_HOVER_LABEL_HEIGHT,
        })
        if (!hoveredContractContext) {
          trackEvent('Contract Rates Tool', 'BAND_CHART_TOOLTIP', {
            field: `Contract Quote Context`,
            percentile: selectedPercentile,
            margin: marginPercentage,
            contractRate,
          })
        }

        return
      }

      setHoveredContractContext(null)
      /**
       * Avoid unnecessary re-render if
       * - we're hovering the same data point
       * - not a valid datapoint found 🤷
       */
      if (hoveredContext?.x === xPosition || closestXIndex === -1) {
        return
      }

      const yPosition = yScale(contractRate)
      // flip label to keep in view
      const shouldFlipDx = xPosition + HOVER_ANNOTATION_DX + MIN_LABEL_WIDTH > width
      const shouldFlipDy =
        yPosition + HOVER_ANNOTATION_DY - MIN_LABEL_HEIGHT <=
        AXIS_LABEL_LINE_HEIGHT / 2 + HOVER_ANNOTATION_DY + MIN_LABEL_HEIGHT
      const monthPercentile =
        monthlyCost[closestXIndex ?? 0].costModelRange[selectedPercentile] ?? 0
      const marginDifference = contractRate - monthPercentile
      const isPositive = marginDifference >= 0
      const marginDifferencePercentage = ((marginDifference * 100) / carrierRate).toFixed(2)

      setHoveredContext({
        xIndex: closestXIndex,
        x: xPosition,
        y: yPosition,
        dx: (shouldFlipDx ? -1 : 1) * HOVER_ANNOTATION_DX,
        dy: (shouldFlipDy ? -1 : 1) * HOVER_ANNOTATION_DY,
        marginDifference,
        isPositive,
        marginDifferencePercentage,
      })

      const { month } = monthlyCost[closestXIndex]
      trackEvent('Contract Rates Tool', 'BAND_CHART_TOOLTIP', {
        field: `Monthly Cost Context`,
        month,
        marginDifference,
        isPositive,
        marginDifferencePercentage,
        contractRate,
      })
    },
    [
      carrierRate,
      chartHeight,
      contractRate,
      hoveredContext?.x,
      monthlyCost,
      selectedPercentile,
      width,
      xPositions,
      yScale,
    ]
  )

  const mouseLeaveHandler = useCallback(() => {
    setHoveredContext(null)
  }, [])

  const classes = useStyles()

  /**
   * useEffectOnce to avoid half way path animation
   * by delaying it on the initial render.
   */
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false)
  useEffectOnce(() => {
    const timeoutId = setTimeout(() => {
      setIsInitialLoadDone(true)
    }, 250)

    return () => {
      clearTimeout(timeoutId)
    }
  })

  /**
   * useEffect to trigger strategy path animation upon user interaction.
   */
  const pathRef = useRef<SVGPathElement | null>(null)
  const [animatedStyle, animatedStyleApi] = useSpring(
    () => ({
      from: {
        strokeDasharray: 0,
        strokeDashoffset: 0,
      },
      config: {
        duration: 10,
      },
    }),
    []
  )
  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength()

      animatedStyleApi.update({
        from: {
          strokeDasharray: pathLength,
          strokeDashoffset: pathLength,
        },
        to: {
          strokeDasharray: pathLength,
          strokeDashoffset: 0,
        },
        config: {
          duration: 1000,
        },
      })

      animatedStyleApi.start()
    }
  }, [animatedStyleApi, selectedPercentile])

  /**
   * useEffect to animate contract rate y position.
   */
  const animatedContractRate = useSpringValue(yScale(contractRate))
  useEffect(() => {
    animatedContractRate.start(yScale(contractRate))
  }, [animatedContractRate, contractRate, yScale])

  /**
   * useEffect to animate hovered context over the x axis.
   */
  const animatedHoverXPosition = useSpringValue(xPositions[0])
  const animatedHoverX2Position = useSpringValue(xPositions[0])
  const animatedHoverXBarPosition = useSpringValue(xPositions[0] - xScale.bandwidth() / 2)
  const animatedHoverYPosition = useSpringValue(
    yScale(monthlyCost[hoveredContext?.xIndex ?? 0].costModelRange[selectedPercentile])
  )
  useEffect(() => {
    if (hoveredContext) {
      animatedHoverXPosition.start(hoveredContext.x)
      animatedHoverX2Position.start(hoveredContext.x + hoveredContext.dx)
      animatedHoverXBarPosition.start(hoveredContext.x - xScale.bandwidth() / 2)
      animatedHoverYPosition.start(
        yScale(monthlyCost[hoveredContext?.xIndex ?? 0].costModelRange[selectedPercentile])
      )
    }
  }, [
    animatedHoverX2Position,
    animatedHoverXBarPosition,
    animatedHoverXPosition,
    animatedHoverYPosition,
    hoveredContext,
    monthlyCost,
    selectedPercentile,
    xScale,
    yScale,
  ])

  return (
    <Box ref={parentRef} display='flex' flexDirection='column' alignItems='flex-start'>
      <svg
        width={width}
        height={MAX_CHART_HEIGHT}
        onMouseMove={mouseMoveHandler}
        onMouseLeave={mouseLeaveHandler}>
        <Group className={classes.noPointerEvents}>
          <GridRows
            scale={yScale}
            width={chartWidth}
            height={chartHeight}
            tickValues={yAxisTickValues}
            stroke={brandColors.coolGray1}
          />
          <GridColumns
            scale={xScale}
            width={chartWidth}
            height={chartHeight}
            tickValues={xAxisValues}
            stroke={brandColors.coolGray1}
            left={xScale.bandwidth() / 2}
          />
          <AxisRight
            axisClassName={classes.axis}
            hideTicks
            hideAxisLine
            hideZero
            scale={yScale}
            left={chartWidth}
            tickValues={yAxisTickValues}
            tickFormat={value => currencyFormatter(value as number)}
            tickLabelProps={leftAxisTickLabelProps}
          />
          <AxisBottom
            axisClassName={classes.axis}
            hideTicks
            hideAxisLine
            orientation='bottom'
            top={chartHeight}
            scale={xScale}
            tickValues={xAxisValues}
            tickLength={AXIS_TICK_LENGTH}
            tickFormat={dateFormatter}
            tickLabelProps={(_, index) => {
              if (!hoveredContext || index !== hoveredContext?.xIndex) {
                return bottomAxisTickLabelProps
              }

              return {
                ...bottomAxisTickLabelProps,
                fontSize: AXIS_LABEL_MAX_FONT_SIZE,
                style: {
                  transition: 'font-weight 0.25s',
                  fontWeight: 700,
                },
              }
            }}
          />
          <Group className={classes.fadeIn} opacity={showVolatility ? 1 : 0}>
            <Area
              data={monthlyCost}
              x={({ month }) => getXPosition(month)}
              y0={({ costModelRange }) => yScale(costModelRange.cost10)}
              y1={({ costModelRange }) => yScale(costModelRange.cost30)}
              fill={brandColors.windowBlue2}
              opacity={0.1}
            />
            <Area
              data={monthlyCost}
              x={({ month }) => getXPosition(month)}
              y0={({ costModelRange }) => yScale(costModelRange.cost30)}
              y1={({ costModelRange }) => yScale(costModelRange.cost70)}
              fill={brandColors.windowBlue2}
              opacity={0.2}
            />
            <Area
              data={monthlyCost}
              x={({ month }) => getXPosition(month)}
              y0={({ costModelRange }) => yScale(costModelRange.cost70)}
              y1={({ costModelRange }) => yScale(costModelRange.cost90)}
              fill={brandColors.windowBlue2}
              opacity={0.1}
            />
          </Group>
          {isInitialLoadDone && (
            <LinePath
              key={selectedPercentile}
              data={monthlyCost}
              x={({ month }) => getXPosition(month)}
              y={({ costModelRange }) => yScale(costModelRange[selectedPercentile])}>
              {({ path }) => (
                <animated.path
                  ref={pathRef}
                  style={animatedStyle}
                  d={path(monthlyCost) ?? ''}
                  strokeLinecap='round'
                  strokeWidth={2}
                  opacity={0.4}
                  stroke={brandColors.windowBlue2}
                  fill='transparent'
                />
              )}
            </LinePath>
          )}
          {namedStrategies.map(({ label, value }) => (
            <Annotation key={value} x={xScale.range()[1]} y={yScale(value)} dx={AXIS_TICK_LENGTH}>
              <LineSubject
                orientation='horizontal'
                stroke={brandColors.coolGray4}
                strokeDasharray='2 3'
                strokeWidth={1}
                min={0}
                max={xScale.range()[1]}
              />
              <HtmlLabel showAnchorLine={false} verticalAnchor='middle' horizontalAnchor='start'>
                <span className={classes.strategyTick}>{label}</span>
              </HtmlLabel>
            </Annotation>
          ))}
        </Group>
        {hoveredContext && (
          <Group className={classes.noPointerEvents}>
            <animated.rect
              style={{ x: animatedHoverXBarPosition }}
              fill={brandColors.windowBlue2}
              opacity={0.05}
              width={xScale.bandwidth()}
              height={chartHeight - AXIS_TICK_LENGTH * 2}
              y={yScale.range()[1]}
            />
            <animated.line
              stroke={hoveredContext.isPositive ? brandColors.grassGreen2 : brandColors.error1}
              strokeWidth={2}
              y1={yScale(contractRate)}
              y2={animatedHoverYPosition}
              style={{
                x: animatedHoverXPosition,
              }}
            />
            <animated.line
              stroke={hoveredContext.isPositive ? brandColors.green2 : brandColors.error1}
              strokeWidth={1}
              x1={animatedHoverXPosition}
              x2={animatedHoverX2Position}
              y1={hoveredContext.y}
              y2={hoveredContext.y + hoveredContext.dy}
            />
          </Group>
        )}
        <Annotation x={xScale.range()[1]} dx={-xScale.range()[1] / 2}>
          <animated.line
            stroke={brandColors.skyBlue6}
            strokeDasharray='5 3'
            strokeWidth={2}
            x1={0}
            x2={xScale.range()[1]}
            style={{
              y: animatedContractRate,
            }}
          />
          <HtmlLabel showAnchorLine={false} verticalAnchor='middle' horizontalAnchor='middle'>
            <animated.div
              style={{ y: animatedContractRate }}
              ref={contractAnnotationRef}
              className={classes.selectedPercentile}>
              Contract Quote - {formattedContractRate}
            </animated.div>
          </HtmlLabel>
          {hoveredContractContext && (
            <HtmlLabel
              showAnchorLine={false}
              verticalAnchor='middle'
              horizontalAnchor='middle'
              y={
                yScale(contractRate) +
                CONTRACT_HOVER_DY * (hoveredContractContext.shouldFlipDy ? -1 : 1)
              }>
              <Fade in timeout={250}>
                <div className={classes.contractHoverLabel}>
                  {hoveredContractContext.shouldFlipDy ? (
                    <DropdownDownIcon
                      size='xxlarge'
                      className={classes.hoverLabelDownIcon}
                      color='coolGray8'
                    />
                  ) : (
                    <DropdownUpIcon
                      size='xxlarge'
                      className={classes.hoverLabelUpIcon}
                      color='coolGray8'
                    />
                  )}
                  <div className={classes.rateItem}>
                    <span>
                      {NAMED_STRATEGY_BY_PERCENTILE[selectedPercentile] ?? ''}{' '}
                      <span className={classes.percentileLabel}>
                        ({selectedPercentile.replace('cost', '')}th Percentile)
                      </span>
                    </span>
                    <span>{currencyFormatter(carrierRate)}</span>
                  </div>
                  <div className={classes.rateItem}>
                    <span>Margin</span>
                    <RateDifference value={carrierRate * marginPercentage} variant='dark' />
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.rateItem}>
                    <span>Contract Quote</span>
                    <span className={classes.boldestText}>{formattedContractRate}</span>
                  </div>
                </div>
              </Fade>
            </HtmlLabel>
          )}
        </Annotation>
        {hoveredContext && (
          <Group className={classes.noPointerEvents}>
            <Annotation y={hoveredContext.y} dx={hoveredContext.dx} dy={hoveredContext.dy}>
              <HtmlLabel showAnchorLine={false} verticalAnchor='middle'>
                <Fade in timeout={250}>
                  <animated.div
                    style={{ x: animatedHoverXPosition }}
                    className={classes.marginAnnotationLabel}>
                    <Typography className={classes.annotationTitle} variant='caption'>
                      Margin <span>(per load)</span>
                    </Typography>
                    <Typography
                      className={hoveredContext.isPositive ? classes.positive : classes.negative}
                      variant='body2'>
                      {hoveredContext.isPositive ? '+' : ''}
                      {currencyFormatter(hoveredContext.marginDifference)} (
                      {hoveredContext.marginDifferencePercentage}%)
                    </Typography>
                  </animated.div>
                </Fade>
              </HtmlLabel>
            </Annotation>
          </Group>
        )}
      </svg>
    </Box>
  )
}
