import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RadialSpinner } from 'dpl/components/RadialSpinner'
import debounce from 'lodash/debounce'
import { IApiErrorResponse, useRequest } from 'network'
import { ResetPasswordCard } from '../../../common/components'
import { useAuthContext } from '../../hooks'

export interface IPasswordResetProps {
  token: string
  email?: string
  /**
   * Callback to execute when the password change fails.
   */
  onPasswordChangeError?: (error?: IApiErrorResponse) => void
  /**
   * Callback to execute when the password change is successful.
   */
  onPasswordChangeSuccess?: () => void
}

export function PasswordReset({
  email,
  onPasswordChangeError,
  onPasswordChangeSuccess,
  token,
}: IPasswordResetProps) {
  const { authClient, discoveryRedirectTo, signInRedirectTo } = useAuthContext()
  const navigate = useNavigate()

  const [resetPassword, { loading: loadingResetPassword }] = useRequest({
    requestFn: authClient.passwords.resetWithMagicLinkToken,
  })

  const [authenticate, { loading: loadingAuthenticate }] = useRequest({
    requestFn: authClient.sessions.authenticate,
  })

  const [getPasswordStrength, { data: passwordStrengthData }] = useRequest({
    requestFn: authClient.passwords.checkStrength,
  })
  const { score } = passwordStrengthData || {}

  const submitPasswordResetHandler = useCallback(
    async formValues => {
      const { password } = formValues || {}
      const { data, error } = await resetPassword({
        token,
        password,
      })
      if (data?.session_token) {
        await authenticate()
        navigate(signInRedirectTo, { replace: true })
        onPasswordChangeSuccess?.()
        return { success: true }
      }
      onPasswordChangeError?.(error)
      return error
    },
    [
      authenticate,
      navigate,
      resetPassword,
      signInRedirectTo,
      token,
      onPasswordChangeError,
      onPasswordChangeSuccess,
    ]
  )

  const debouncedGetStrength = debounce((value: string) => {
    if (value) getPasswordStrength({ password: value, email_address: email || null })
  }, 250)

  const passwordChangeHandler = useCallback(
    ({ target: { value = '' } }) => {
      debouncedGetStrength(value)
    },
    [debouncedGetStrength]
  )

  const isLoading = loadingResetPassword || loadingAuthenticate

  if (loadingAuthenticate) {
    return <RadialSpinner position='absolute' />
  }

  return (
    <ResetPasswordCard
      passwordStrengthScore={score}
      onSubmit={submitPasswordResetHandler}
      onChange={passwordChangeHandler}
      isLoading={isLoading}
      loginHref={discoveryRedirectTo as string}
      email={email}
    />
  )
}
