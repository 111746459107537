import {
  ICarrierRateRateTypes,
  ICreateRoutingGuideCarrierRateInput,
} from '../../../../../../types/graphqlTypes'
import { IRateType } from '../../../../../../types/rateType'
import { TRoutingGuideCarrierSchema } from '../schema'
import { getCarrierCapacityInput } from './getCarrierCapacityInput'

export interface IGetRoutingGuideCarrierRateInputProps {
  formValues: TRoutingGuideCarrierSchema
}

export function getRoutingGuideCarrierRateInput({
  formValues,
}: IGetRoutingGuideCarrierRateInputProps): Omit<
  ICreateRoutingGuideCarrierRateInput,
  'carrierId' | 'routingGuideLaneId'
> {
  const { availability, availabilityDays = [], carrierUserEmail, rate, rateType } = formValues || {}

  // carrier user email
  const { inputValue: carrierUserEmailInputValue, value: carrierUserEmailValue } =
    carrierUserEmail || {}
  const { consumerUser, id: carrierUserEmailValueId } = carrierUserEmailValue || {}
  const { user } = consumerUser || {}
  const { emailAddress: userEmail } = user || {}

  // if the input value and the email address are the same, we don't need to send the email
  const newCarrierUserEmail =
    userEmail === carrierUserEmailInputValue ? undefined : carrierUserEmailInputValue

  // if the input value and the email address are the same, we send the carrier user id
  const carrierUserId =
    userEmail === carrierUserEmailInputValue ? carrierUserEmailValueId : undefined

  return {
    carrierCapacities: getCarrierCapacityInput({ availability, availabilityDays }),
    carrierUserId,
    newCarrierUserEmail,
    rate,
    rateType:
      rateType === IRateType.allIn ? ICarrierRateRateTypes.all_in : ICarrierRateRateTypes.linehaul,
  }
}
