import React from 'react'
import { Box, ButtonBase, Link, makeStyles, Typography } from 'dpl/core'
import NotPaidFilledIcon from 'dpl/icons/build/NotPaidFilledIcon'
import { brandColors } from 'dpl/theme/colors'

export interface IRatesDoNotBidMessageProps {
  reason: string
  onClick?: () => void
  showCTA?: boolean
  stacked?: boolean
  dataTest?: string
}

const useRatesDoNotBidMessageStyles = makeStyles(theme => ({
  root: {
    backgroundColor: brandColors.error0,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
  },
}))

export function RatesDoNotBidMessage({
  dataTest = 'RatesDoNotBidMessage',
  onClick,
  reason,
  showCTA = false,
  stacked = false,
}: IRatesDoNotBidMessageProps) {
  const classes = useRatesDoNotBidMessageStyles()
  const flexDirection = stacked ? 'column' : 'row'
  return (
    <Box
      display='flex'
      flexDirection={flexDirection}
      gap={2}
      justifyContent='center'
      alignItems='center'
      className={classes.root}
      data-test={dataTest}>
      <Box
        display='flex'
        flexDirection={flexDirection}
        gap={1}
        justifyContent='center'
        alignItems='center'>
        <NotPaidFilledIcon color='error1' size='xlarge' data-test='NotPaidFilledIcon' />
        <Typography variant='subtitle1' whiteSpace='nowrap'>
          Do Not Bid
        </Typography>
      </Box>
      <Typography variant='body2' textAlign='center' data-test={`${dataTest}-reason`}>
        Your organization does not want any bidding on shipments meeting this criteria for the
        following reason: {reason}.
      </Typography>
      {onClick && showCTA && (
        <Link
          data-test={`${dataTest}-CTA`}
          component={ButtonBase}
          onClick={onClick}
          underline='always'
          color='textPrimary'
          variant='button'>
          Override to View Rate
        </Link>
      )}
    </Box>
  )
}
