import React, { useCallback } from 'react'
import classNames from 'classnames'
import { Button } from '../../../core'
import { useDateRangePickerOptionBaseStyles } from '../hooks/useDateRangePickerOptionBaseStyles'
import { IDateRangePickerOption } from '../types'

interface IDateRangeNamedOptionProps {
  option: IDateRangePickerOption
  onSelectOption: (option: IDateRangePickerOption) => void
  selectedOption: IDateRangePickerOption | null
}

export function DateRangeNamedOption({
  onSelectOption,
  option,
  selectedOption,
}: IDateRangeNamedOptionProps) {
  const classes = useDateRangePickerOptionBaseStyles()
  const buttonClasses = classNames(classes.button, {
    [classes.activeButton]: option.key === selectedOption?.key,
  })

  const optionSelectHandler = useCallback(() => {
    onSelectOption(option)
  }, [onSelectOption, option])

  return (
    <Button
      variant='text'
      className={buttonClasses}
      onClick={optionSelectHandler}
      data-test='DateRangeNamedOption'>
      {option.label}
    </Button>
  )
}
