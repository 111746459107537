import React, { memo, useCallback } from 'react'
import { ISettingVersion } from '#types/graphqlTypes'
import { FormattedDateTime, IconButton } from 'dpl/components'
import { Box, Card, CardContent, makeStyles, Skeleton, Typography } from 'dpl/core'
import { EditIcon } from 'dpl/icons'
import { useBreakpointContext } from 'dpl/providers'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { COST_LABELS_BY_PERCENTILE } from '../../constants'
import { CostSettingsModal } from '../CostSettingsModal'

export interface ICostSettingsCardProps {
  /**
   * The default cost percentile to be displayed in the card
   */
  defaultCostPercentile?: ISettingVersion['defaultCostPercentile']
  /**
   * The last updated date to be displayed in the card
   */
  updatedAt: string
  /**
   * If true, loading skeleton is shown
   * @default false
   */
  isLoading?: boolean
  /**
   * @default 'CostSettingsCard'
   */
  dataTest?: string
  /**
   * If true, edit button is shown
   */
  canEdit: boolean
}

const useCostSettingsCardStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
  },
  cardContent: {
    padding: `${theme.spacing(2)} !important`,
  },
  lastUpdatedText: {
    display: 'flex',
    gap: theme.spacing(0.25),
  },
  editButton: {
    color: theme.palette.primary.main,
    marginTop: `-${theme.spacing(0.5)}`,
    marginBottom: `-${theme.spacing(0.5)}`,
    marginRight: `-${theme.spacing(1)}`,
  },
}))

export const CostSettingsCard = memo<ICostSettingsCardProps>(
  ({
    canEdit,
    dataTest = 'CostSettingsCard',
    defaultCostPercentile,
    isLoading = false,
    updatedAt,
  }) => {
    const classes = useCostSettingsCardStyles()
    const { isTablet } = useBreakpointContext()
    const costStrategy = COST_LABELS_BY_PERCENTILE[defaultCostPercentile]

    const { modalState, toggleModal } = useModalState({
      costSettingsModal: false,
    })
    const { costSettingsModal: isCostSettingsModalOpen } = modalState || {}
    const toggleModalHandler = useCallback(() => {
      toggleModal('costSettingsModal')
    }, [toggleModal])

    return (
      <>
        <Card data-test={dataTest} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box display='flex' flexDirection='column' gap={1}>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='subtitle1' component='div'>
                  Default Cost Base
                </Typography>
                <Box display='flex' alignItems='center' gap={0.5}>
                  {!isTablet && (
                    <Typography
                      variant='caption'
                      component='div'
                      color='textSecondary'
                      className={classes.lastUpdatedText}>
                      <span>Last updated: </span>
                      {isLoading ? (
                        <Skeleton variant='text' width='50px' component='span' />
                      ) : (
                        <FormattedDateTime
                          datetime={updatedAt || ''}
                          format='dateAndYearFullDateAlways'
                        />
                      )}
                    </Typography>
                  )}
                  {canEdit && (
                    <IconButton
                      dataTest={`${dataTest}-edit-button`}
                      Icon={EditIcon}
                      color='secondary'
                      className={classes.editButton}
                      onClick={toggleModalHandler}
                    />
                  )}
                </Box>
              </Box>
              {isLoading ? (
                <Skeleton variant='rectangular' height='24px' width='130px' />
              ) : (
                <Typography variant='h4' component='div'>
                  {defaultCostPercentile}th Percentile {costStrategy && `(${costStrategy})`}
                </Typography>
              )}
              {isTablet && (
                <Typography
                  variant='caption'
                  component='div'
                  color='textSecondary'
                  className={classes.lastUpdatedText}>
                  <span>Last updated: </span>
                  {isLoading ? (
                    <Skeleton variant='text' width='50px' component='span' />
                  ) : (
                    <FormattedDateTime
                      datetime={updatedAt || ''}
                      format='dateAndYearFullDateAlways'
                    />
                  )}
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
        {isCostSettingsModalOpen && (
          <CostSettingsModal
            dataTest={`${dataTest}-modal`}
            onClose={toggleModalHandler}
            defaultCostPercentile={defaultCostPercentile}
            costStrategyLabel={costStrategy}
          />
        )}
      </>
    )
  }
)
