import React from 'react'
import { DataGridAvatarCell, DataGridLaneCell } from 'dpl'
import { Box, Typography } from 'dpl/core'
import { DataGridCarrierCell } from '../components/DataGridCarrierCell'
import { IRoutingGuideLanesDataGrid } from '../types'

export const ROUTING_GUIDE_LANE_COLUMNS: IRoutingGuideLanesDataGrid['columns'] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 120,
    renderCell: ({ row }) => <Typography variant='body2'>{`RG-${row.displayId}`}</Typography>,
  },
  {
    field: 'lane',
    headerName: 'Lane',
    flex: 1,
    minWidth: 300,
    renderCell: ({ row }) => (
      <DataGridLaneCell
        topCaption={row.orgShipper.name}
        pickupProps={{ city: row.pickupLocation.city, stateCode: row.pickupLocation.stateCode }}
        deliveryProps={{
          city: row.deliveryLocation.city,
          stateCode: row.deliveryLocation.stateCode,
        }}
      />
    ),
  },

  {
    field: 'equipment',
    headerName: 'Equipment',
    minWidth: 150,
    renderCell: ({ row }) => (
      <Box>
        <Typography variant='body2'>
          {row.equipment.name === 'Open Deck' ? 'Flatbed' : row.equipment.name}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'carriers',
    headerName: 'Carriers',
    minWidth: 150,
    renderCell: ({ row }) => {
      const { routingGuideCarrierRates } = row || {}
      return <DataGridCarrierCell routingGuideCarrierRates={routingGuideCarrierRates || []} />
    },
  },
  {
    field: 'laneManager',
    headerName: 'Lane Manager',
    minWidth: 150,
    renderCell: ({ row }) => {
      const { laneManager } = row || {}
      const { user } = laneManager || {}
      const { firstName, lastName } = user || {}
      return (
        <DataGridAvatarCell
          AvatarProps={{
            name: `${firstName} ${lastName}`,
          }}
        />
      )
    },
  },
]
