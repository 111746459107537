import React, { useCallback, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { UNPROTECTED_PATHS } from '#constants/paths'
import { IPasswordResetProps, PasswordReset } from 'auth/b2b/components/PasswordReset'
import { makeStyles } from 'dpl/core'
import { useAnalytics } from '../../hooks/useAnalytics'

const useResetPasswordStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(6),
    },
  },
  requirementsList: {
    paddingLeft: theme.spacing(3),
    margin: 0,
  },
}))

export function ResetPassword() {
  const classes = useResetPasswordStyles()
  const { trackEvent } = useAnalytics()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') ?? ''
  const email = searchParams.get('email') ?? ''

  useEffect(() => {
    if (!token) {
      navigate(UNPROTECTED_PATHS.login)
    }
  }, [navigate, token])

  const passwordChangeSuccessHandler = useCallback(() => {
    trackEvent('Reset Password', 'CHANGE_PASSWORD_SUCCESS')
  }, [trackEvent])

  const passwordChangeErrorHandler = useCallback<
    NonNullable<IPasswordResetProps['onPasswordChangeError']>
  >(
    error => {
      trackEvent('Reset Password', 'CHANGE_PASSWORD_ERROR', { error })
    },
    [trackEvent]
  )

  return (
    <div data-test='route-reset-password' className={classes.root}>
      <PasswordReset
        token={token}
        email={email}
        onPasswordChangeSuccess={passwordChangeSuccessHandler}
        onPasswordChangeError={passwordChangeErrorHandler}
      />
    </div>
  )
}
