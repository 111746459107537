import React, { useState, useCallback, useRef, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { ISortOrder, Maybe } from '#types/graphqlTypes'
import { currencyFormatter } from '#utils/currencyFormatter'
import { getStopsWithRelativeSequence } from '#utils/getStopsWithRelativeSequence'
import DataGrid, {
  DataGridAdvancedTextCell,
  DataGridAvatarCell,
  IDataGridAvatarCellProps,
  DataGridIconCell,
  IDataGridProps,
} from 'dpl/components/DataGrid'
import { Tooltip, ITooltipProps } from 'dpl/components/Tooltip'
import { FORMATS } from 'dpl/constants/datetime'
import { Box, GridRenderCellParams, Link, Typography } from 'dpl/core'
import {
  LaneArrowIcon,
  CheckFilledIcon,
  DryVanIcon,
  HelpIcon,
  InfoIcon,
  ReeferIcon,
  BotIcon,
} from 'dpl/icons'
import { ISvgIconProps } from 'dpl/icons/components/SvgIcon'
import { QueryParamProvider } from 'dpl/providers/QueryParamProvider'
import { PageTemplate } from 'dpl/templates'
import { brandColors } from 'dpl/theme/colors'
import { getGraphqlAfterCursor } from 'dpl/utils/graphql/getGraphqlAfterCursor'
import { useFlagsContext } from 'flags/src/FlagsContext'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'
import moment from 'moment-timezone'
import { StopListModal } from '../../components/StopListModal'
import { PROTECTED_PATHS } from '../../constants'
import { useUserDetails } from '../../hooks'
import { QuoteDetail } from './QuoteDetail'
import {
  QUOTES_FILTERS_CONFIG,
  QUOTES_FILTERS_CONFIG_WITH_SOURCE,
  SORT_OPTIONS_CONFIG,
  COMMUNICATION_METHOD_ICONS,
} from './constants'
import { IOrganizationQuoteItemFragment } from './graphql/OrganizationQuoteItemFragment'
import { useOrganizationQuotesQuery } from './graphql/OrganizationQuotesQuery'
import { IQuoteRow, IQuotesFilters } from './types'

const defaultTooltipProps = {
  placement: 'top' as ITooltipProps['placement'],
}
const defaultIconProps = {
  size: 'medium' as ISvgIconProps['size'],
}

const COLUMNS: IDataGridProps<IQuoteRow>['columns'] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'customer',
    headerName: 'Customer',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'pickup',
    headerName: 'Lane',
    minWidth: 225,
    flex: 1.5,
    renderCell: ({ value }: GridRenderCellParams<IQuoteRow, IQuoteRow['pickup']>) => {
      const { pickupStartAt, stop } = value || {}
      const { city, postalCode, startTimeZone, stateCode } = stop || {}
      const apptTime = moment(pickupStartAt)
        .tz(startTimeZone ?? FORMATS.defaultTimeZone)
        .format(FORMATS.monthDayYearTimeFormat12hUppercase)
      return (
        <DataGridAdvancedTextCell bottomCaption={apptTime}>
          {city}, {stateCode} ({postalCode})
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'laneArrowMultiStop',
    headerName: '',
    renderCell: function Render({
      value,
    }: GridRenderCellParams<IQuoteRow, IQuoteRow['laneArrowMultiStop']>) {
      const { numStops, stops } = value || {}
      const hasMultiStops = numStops && numStops > 2
      const numIntermediateStops = hasMultiStops ? numStops - 2 : 0
      const [isModalOpen, setIsModalOpen] = useState(false)
      const stopList = getStopsWithRelativeSequence(stops ?? [])
      const closeModal = useCallback(() => {
        setIsModalOpen(prev => !prev)
      }, [])
      const openModal = useCallback(e => {
        e.preventDefault()
        e.stopPropagation()
        setIsModalOpen(prev => !prev)
      }, [])

      return (
        <DataGridAdvancedTextCell alignment='center'>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <LaneArrowIcon size='large' />
            {hasMultiStops && (
              <Link
                variant='caption'
                color='textSecondary'
                underline='always'
                component='button'
                onClick={openModal}
                data-test='multi-stop-modal-button'>
                {numIntermediateStops} Stops
              </Link>
            )}
          </Box>
          <StopListModal stops={stopList} open={isModalOpen} onClose={closeModal} />
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'delivery',
    headerName: '',
    minWidth: 225,
    flex: 1.5,
    renderCell: ({ value }: GridRenderCellParams<IQuoteRow, IQuoteRow['delivery']>) => {
      const { deliveryStartAt, stop } = value || {}
      const { city, postalCode, startTimeZone, stateCode } = stop || {}
      const apptTime = moment(deliveryStartAt)
        .tz(startTimeZone ?? FORMATS.defaultTimeZone)
        .format(FORMATS.monthDayYearTimeFormat12hUppercase)
      return (
        <DataGridAdvancedTextCell bottomCaption={apptTime}>
          {city}, {stateCode} ({postalCode})
        </DataGridAdvancedTextCell>
      )
    },
  },
  {
    field: 'equipment',
    headerName: 'Equipment/Drop Type',
    minWidth: 150,
    renderCell: ({ value }: GridRenderCellParams<IQuoteRow, IQuoteRow['equipment']>) => {
      const { equipment: { key } = {}, isDeliveryDropTrailer, isPickupDropTrailer } = value || {}
      const pickupType = isPickupDropTrailer ? 'Drop' : 'Live'
      const deliveryType = isDeliveryDropTrailer ? 'Drop' : 'Live'
      const equipmentTypeLabel = key === '53_reefer' ? 'Reefer' : 'Dry Van'
      const EquipmentTypeIcon = key === '53_reefer' ? ReeferIcon : DryVanIcon

      return (
        <DataGridIconCell
          icon={EquipmentTypeIcon}
          iconTooltipTitle={equipmentTypeLabel}
          IconProps={defaultIconProps}
          TooltipProps={defaultTooltipProps}>
          <Typography variant='body2' whiteSpace='nowrap' margin={-1}>
            {pickupType}-{deliveryType}
          </Typography>
        </DataGridIconCell>
      )
    },
  },
  {
    field: 'quote',
    headerName: 'Quote',
    headerAlign: 'right',
    renderCell: ({ value }: GridRenderCellParams<IQuoteRow, IQuoteRow['quote']>) => {
      const { bidAmount, rateType, source } = value || {}
      const rateTypeLabel = rateType === 'all_in' ? 'All-in' : 'Linehaul'
      const sourceLabel = source === 'spot_quotes_api' ? 'Spot Quote API' : 'Spot Rates Tool'
      return (
        <Box display='flex' flexDirection='row' alignItems='center' gap={0.5}>
          <Tooltip color='dark' title={sourceLabel}>
            <div>
              <InfoIcon size='medium' color='coolGray5' />
            </div>
          </Tooltip>
          <Box display='flex' flexDirection='column'>
            <Typography variant='body2'>{currencyFormatter(bidAmount ?? 0)}</Typography>
            <Typography variant='caption' color={brandColors.coolGray5}>
              {rateTypeLabel}
            </Typography>
          </Box>
        </Box>
      )
    },
  },
  {
    field: 'submittedBy',
    headerName: 'Submitted by',
    minWidth: 225,
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams<IQuoteRow, IQuoteRow['submittedBy']>) => {
      const { communicationMethod, createdAt, createdBy, source } = value || {}
      const { user: { fullName } = {} } = createdBy || {}
      const formattedCreatedAt = moment(createdAt).format('MMM D YYYY')
      const formattedCommunicationMethod = startCase(communicationMethod ?? '')
      const Icon = communicationMethod ? COMMUNICATION_METHOD_ICONS[communicationMethod] : null
      const isApiGeneratedQuote = source === 'spot_quotes_api'
      const AvatarProps: IDataGridAvatarCellProps['AvatarProps'] = {
        name: isApiGeneratedQuote ? undefined : fullName ?? '',
        hideTooltip: true,
        Icon: isApiGeneratedQuote ? <BotIcon color='coolGray8' size='large' /> : undefined,
      }
      const createdByName = isApiGeneratedQuote ? 'Imabot' : fullName

      return (
        <DataGridAvatarCell
          avatarPosition='left'
          bottomCaption={formattedCreatedAt}
          AvatarProps={AvatarProps}>
          <Box display='flex' alignItems='center' gap={0.5}>
            {createdByName}
            {Icon && (
              <Tooltip title={formattedCommunicationMethod} placement='top'>
                <Box display='flex' alignItems='center'>
                  <Icon color='coolGray5' size='medium' />
                </Box>
              </Tooltip>
            )}
          </Box>
        </DataGridAvatarCell>
      )
    },
  },
  {
    field: 'wonStatus',
    headerName: 'Won',
    width: 55,
    renderCell: ({ value }: GridRenderCellParams<IQuoteRow, IQuoteRow['wonStatus']>) => {
      const { shipmentNumber, status } = value || {}
      const isSubmitted = status === 'submitted'
      const StatusIcon = isSubmitted ? HelpIcon : CheckFilledIcon
      const IconProps = {
        size: 'large',
        color: isSubmitted ? 'coolGray5' : 'green2',
        'data-test': isSubmitted ? 'submitted-icon' : 'won-icon',
      } as ISvgIconProps
      const shipmentIdLabel = `Shipment ID: ${shipmentNumber}`
      const tooltipTitle = !isSubmitted ? shipmentIdLabel : ''
      return (
        <DataGridIconCell
          icon={StatusIcon}
          IconProps={IconProps}
          iconTooltipTitle={tooltipTitle}
          TooltipProps={defaultTooltipProps}
        />
      )
    },
  },
]

const getQuoteRows = (quotes: IOrganizationQuoteItemFragment[]): IQuoteRow[] => {
  return quotes.map(
    ({
      bidAmount,
      communicationMethod,
      createdAt,
      createdBy,
      deliveryStartAt,
      equipment,
      id,
      isDeliveryDropTrailer,
      isPickupDropTrailer,
      numStops,
      organizationShipper,
      pickupStartAt,
      rateType,
      shipmentNumber,
      source,
      status,
      stops,
    }) => {
      return {
        id,
        customer: organizationShipper?.name,
        pickup: {
          stop: stops?.[0],
          pickupStartAt,
        },
        laneArrowMultiStop: {
          stops,
          pickupStartAt,
          deliveryStartAt,
          numStops,
        },
        delivery: {
          stop: stops?.[(numStops ?? 0) - 1],
          deliveryStartAt,
        },
        equipment: {
          equipment,
          isDeliveryDropTrailer,
          isPickupDropTrailer,
        },
        quote: {
          bidAmount,
          rateType,
          source,
        },
        submittedBy: { createdAt, createdBy, communicationMethod, source },
        wonStatus: { status, shipmentNumber },
      }
    }
  )
}

const PAGE_SIZE = 10

function formatStopFilter(dropTypeFilter: string): boolean | null {
  if (!dropTypeFilter) return null

  return dropTypeFilter === 'multiple'
}

function formatDropTypeFilter(dropTypeFilter: string): [Maybe<boolean>, Maybe<boolean>] {
  if (dropTypeFilter === 'Live Live') return [false, false]
  if (dropTypeFilter === 'Drop Live') return [true, false]
  if (dropTypeFilter === 'Live Drop') return [false, true]
  if (dropTypeFilter === 'Drop Drop') return [true, true]

  return [undefined, undefined]
}

function safeJSONParse(stringValue: string | null) {
  let value

  try {
    value = JSON.parse(stringValue ?? '{}')
  } catch {
    // continue regardless of error
  }

  return value
}

export function Quotes() {
  const navigate = useNavigate()
  const { quoteId } = useParams()
  const [selectedQuoteId, setSelectedQuoteId] = useState(quoteId || '')
  const { organizationUser, user } = useUserDetails()
  const defaultUserFilterValues = {
    id: organizationUser?.id!,
    status: organizationUser?.status!,
    user: {
      id: user?.id!,
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  }

  const [searchParams, setSearchParams] = useSearchParams()
  /**
   * Datagrid pagination uses a zero based index.
   */
  const currentPageIndex = Number(searchParams.get('pageIndex') ?? 0)

  const locationFilter = safeJSONParse(searchParams.get('location'))
  const customerFilter = safeJSONParse(searchParams.get('customer'))
  const userFilter = safeJSONParse(searchParams.get('user'))
  const [isPickupDropTrailer, isDeliveryDropTrailer] = formatDropTypeFilter(
    searchParams.get('dropType') ?? ''
  )

  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const { data, loading: isLoading } = useOrganizationQuotesQuery({
    fetchPolicy: 'network-only',
    variables: {
      after: getGraphqlAfterCursor({ pageNumber: currentPageIndex, pageSize: PAGE_SIZE }),
      first: PAGE_SIZE,
      sortOrder: (searchParams.get('sort') as ISortOrder) ?? ISortOrder.desc,
      externalIdQuery: searchParams.get('externalId') ?? null,
      equipmentKey: searchParams.get('equipment'),
      pickupCity: locationFilter?.originCity,
      pickupStateCode: locationFilter?.originStateCode,
      deliveryCity: locationFilter?.destinationCity,
      deliveryStateCode: locationFilter?.destinationStateCode,
      organizationShipperId: customerFilter?.id,
      createdByOrganizationUserId: userFilter?.id,
      isPickupDropTrailer,
      isDeliveryDropTrailer,
      isMultistop: formatStopFilter(searchParams.get('stops') ?? ''),
      status: searchParams.get('won') === '1' ? ['won'] : undefined,
      source: searchParams.get('source'),
    },
    // wait for default user filter to be set in the URL
    skip: isFirstLoad,
  })

  const quotes = data?.organizationQuotes.edges?.map(edge => edge?.node)
  const rows = getQuoteRows(compact<IOrganizationQuoteItemFragment>(quotes))

  const closeQuoteDetailHandler = useCallback(() => {
    setSelectedQuoteId('')
    navigate({ pathname: `${PROTECTED_PATHS.quotes}`, search: searchParams.toString() })
  }, [navigate, searchParams])

  /**
   * Way to avoid DataGrid from resetting pagination while data is loading.
   * more info here https://mui.com/x/react-data-grid/pagination/
   */
  const rowCountRef = useRef(data?.organizationQuotes?.totalCount ?? 0)
  const quotesCount = useMemo(() => {
    if (data?.organizationQuotes?.totalCount !== undefined) {
      rowCountRef.current = data?.organizationQuotes?.totalCount
    }
    return rowCountRef.current
  }, [data?.organizationQuotes?.totalCount])

  const pageChangeHandler = useCallback(
    ({ page }) => {
      setSearchParams(prev => {
        prev.set('pageIndex', `${page}`)
        return prev
      })
    },
    [setSearchParams]
  )

  const rowClickHandler = useCallback(
    params => {
      const { row: { id } = {} } = params || {}
      setSelectedQuoteId(id)
      navigate({ pathname: `${PROTECTED_PATHS.quotes}/${id}`, search: searchParams.toString() })
    },
    [navigate, searchParams]
  )

  /**
   * useEffectOnce to set the current user as the
   * default user filter value on load if there's
   * no filters applied yet.
   */
  useEffectOnce(() => {
    if (!searchParams.toString()) {
      setSearchParams(prev => {
        prev.set('user', JSON.stringify(defaultUserFilterValues))
        return prev
      })
    }
    setIsFirstLoad(false)
  })

  const { isFlagEnabled } = useFlagsContext()
  const isApiQuotesFilterFlagEnabled = isFlagEnabled('api_quotes_filter')

  const quotesFilterConfig = useMemo(() => {
    if (isApiQuotesFilterFlagEnabled) {
      return QUOTES_FILTERS_CONFIG_WITH_SOURCE
    }

    return QUOTES_FILTERS_CONFIG
  }, [isApiQuotesFilterFlagEnabled])

  return (
    <PageTemplate pageTitle='Quotes' dataTest='route-quotes'>
      <QueryParamProvider>
        <DataGrid<IQuoteRow, IQuotesFilters, ISortOrder>
          filtersConfig={quotesFilterConfig}
          sortOptions={SORT_OPTIONS_CONFIG}
          defaultSortValue={ISortOrder.desc}
          visibleFiltersLimit={3}
          columns={COLUMNS}
          rows={rows}
          autosizeOnMount
          loading={isLoading}
          paginationMode='server'
          paginationModel={{
            pageSize: PAGE_SIZE,
            page: currentPageIndex,
          }}
          pageSizeOptions={[PAGE_SIZE]}
          rowCount={quotesCount}
          onPaginationModelChange={pageChangeHandler}
          onRowClick={rowClickHandler}
        />
      </QueryParamProvider>
      <QuoteDetail id={selectedQuoteId} onClose={closeQuoteDetailHandler} />
    </PageTemplate>
  )
}
