import React from 'react'
import classNames from 'classnames'
import Box, { IBoxProps as MuiBoxProps } from 'dpl/core/Box'
import Typography from 'dpl/core/Typography'
import { makeStyles, Theme } from 'dpl/core/styles'
import { brandColors } from 'dpl/theme/colors'
import get from 'lodash/get'
import { useMap, IUseMapProps } from '../../hooks'
import { IMapPosition, IMapRouteGroup, IMapStopProps, WithId } from '../../types'
import { ViewControl } from '../ViewControl'

/**
 * For e2e tests, we disable the maps
 */
const IS_TEST_ENV = !!get(window, 'Cypress')

const useTrimbleMapStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: 100,
    border: `1px solid ${brandColors.coolGray3}`,
    height: '100%',
    width: '100%',
    '& .trimblemaps-popup-close-button': {
      position: 'absolute',
      right: '0',
      top: '0',
      border: '0',
      borderRadius: '0 3px 0 0',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    '& .trimblemaps-popup-content': {
      position: 'relative',
      background: '#fff',
      borderRadius: '3px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      padding: '10px 10px 15px',
      pointerEvents: 'auto',
    },
  },
  map: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',

    /**
     * @note Required to have marker rendered in the correct location
     */
    '& .TrimbleMap-marker': {
      position: 'absolute',
    },
  },
}))

export type TTrimbleMapProps<
  TPosition extends WithId<IMapPosition> = WithId<IMapPosition>,
  TStop extends WithId<IMapStopProps> = WithId<IMapStopProps>,
  TLine extends WithId<IMapRouteGroup> = WithId<IMapRouteGroup>,
> = IUseMapProps<TPosition, TStop, TLine> & {
  className?: string
  dataTest?: string
  /**
   * Show or hide the eye icon
   * @default false
   */
  enableView?: boolean
  BoxProps?: MuiBoxProps
  /**
   * Trigger callback when eye icon is clicked
   */
  onViewChange?: (newView: boolean) => void
  /**
   * disables routes outside of US
   */
  bordersOpen?: boolean
}

function TrimbleMapRaw<
  TPosition extends WithId<IMapPosition>,
  TStop extends WithId<IMapStopProps>,
  TLine extends WithId<IMapRouteGroup>,
>({
  className: classNameProp,
  dataTest = 'TrimbleMap',
  enableView = false,
  onViewChange = () => {},
  BoxProps = {},
  ...rest
}: TTrimbleMapProps<TPosition, TStop, TLine>) {
  const { containerId } = useMap(rest)
  const classes = useTrimbleMapStyles()
  const className = classNames(classes.root, classNameProp)
  return (
    <Box className={className} data-test={dataTest} {...BoxProps}>
      {enableView && <ViewControl onViewChange={onViewChange} />}
      <div id={containerId} data-test='TrimbleMap-map' className={classes.map} />
    </Box>
  )
}

/**
 * Uses componentDidCatch to handle WebGl errors that bubble up
 * @see https://transfix.atlassian.net/browse/FEPF-1050
 */
export class TrimbleMap<
  TPosition extends WithId<IMapPosition>,
  TStop extends WithId<IMapStopProps>,
  TLine extends WithId<IMapRouteGroup>,
> extends React.Component<TTrimbleMapProps<TPosition, TStop, TLine>, { hasError: boolean }> {
  constructor(props: TTrimbleMapProps<TPosition, TStop, TLine>) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  /**
   * Since this error won't bubble to ErrorBoundary, we report it here
   */
  // TODO: set up error logging in wilson
  /* componentDidCatch(error: Error, errorInfo?: {}) {
    this.setState({ hasError: true })
    log('rogers', error, { extra: errorInfo })
  } */

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    if (hasError || IS_TEST_ENV) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          height='100%'
          alignItems='center'
          data-test='TrimbleMapWrapper'>
          <Typography variant='h3' component='div'>
            Map Unavailable
          </Typography>
        </Box>
      )
    }

    return <TrimbleMapRaw {...this.props} />
  }
}
