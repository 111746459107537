import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants'
import { DueDate, UserAssignmentsAvatarGroup } from '#routes/contract-rfp/components'
import { brandColors, Tooltip, Avatar } from 'dpl'
import { Box, makeStyles, Typography } from 'dpl/core'
import { CalendarIcon } from 'dpl/icons'
import { useFlagsContext } from 'flags/src/FlagsContext'
import moment from 'moment'
import { IRfpItemFragment } from '../../graphql/RFPItem'

export interface IKanbanItemProps {
  item: IRfpItemFragment
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  iconText: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  avatarText: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ellipsis: {
    display: 'inline-block',
    maxWidth: '100%',
  },
}))

export function KanbanItem({ item }: IKanbanItemProps) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { isFlagEnabled } = useFlagsContext()
  const isMultipleTeamMembersEnabled = isFlagEnabled('rfp_multiple_team_members')

  const { assignedToOrganizationUser } = item
  const { user } = assignedToOrganizationUser || {}
  const { fullName = '' } = user || {}
  const assignee = fullName || 'Unassigned'

  const startAt = moment(item.startAt).format('MMM D, YYYY')
  const endAt = moment(item.endAt).format('MMM D, YYYY')
  const handleItemClick = () => {
    navigate(`${PROTECTED_PATHS.contractRFPs}/RFP-${item.displayId}`)
  }

  return (
    <Box className={classes.root} onClick={handleItemClick}>
      <div>
        <div>
          <Tooltip title={item.name} enterDelay={600}>
            <span>
              <Typography component='span' variant='subtitle2' noWrap className={classes.ellipsis}>
                {item.name}
              </Typography>
            </span>
          </Tooltip>
        </div>
        <Typography variant='caption' color='GrayText'>
          {item.organizationShipper.name}
        </Typography>
      </div>
      <div>
        <div className={classes.iconText}>
          <CalendarIcon size='medium' color='coolGray5' />
          <Typography variant='caption' color={brandColors.coolGray5}>
            Contract Dates
          </Typography>
        </div>
        <Typography variant='caption'>
          {startAt}-{endAt}
        </Typography>
      </div>
      <div className={classes.info}>
        <div className={classes.avatarText}>
          {isMultipleTeamMembersEnabled ? (
            <UserAssignmentsAvatarGroup userAssignments={item.userAssignments} />
          ) : (
            <Avatar name={assignee} size='xsmall' />
          )}
          <Typography variant='caption'>RFP-{item.displayId}</Typography>
        </div>
        <DueDate item={item} />
      </div>
    </Box>
  )
}
