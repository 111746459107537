export enum IContractLengthMonths {
  threeMonths = 3,
  sixMonths = 6,
  twelveMonths = 12,
  fifteenMonths = 15,
}

export const CONTRACT_LENGTHS_DISPLAY_LABELS: Record<IContractLengthMonths, string> = {
  [IContractLengthMonths.threeMonths]: '3 Months',
  [IContractLengthMonths.sixMonths]: '6 Months',
  [IContractLengthMonths.twelveMonths]: '12 Months',
  [IContractLengthMonths.fifteenMonths]: '15 Months',
}

export enum IVolumeTypes {
  flatVolume = 'flat',
  dynamicVolume = 'dynamic',
}

export const VOLUME_TYPES_DISPLAY_LABELS: Record<IVolumeTypes, string> = {
  [IVolumeTypes.flatVolume]: 'Flat Volume',
  [IVolumeTypes.dynamicVolume]: 'Dynamic Volume',
}

export enum IDropTrailerLocations {
  firstPickup = 'firstPickup',
  lastDelivery = 'lastDelivery',
}

export enum IEquipment53Types {
  reefer = '53_reefer',
  dryVan = '53_van',
}

export const EQUIPMENT_TYPE_KEY_LABEL: Record<string, string> = {
  [IEquipment53Types.reefer]: 'Reefer',
  [IEquipment53Types.dryVan]: 'Dry Van',
}
