import * as yup from 'yup'

export const AutocompleteOrganizationUserSchema = yup.object({
  id: yup.string(),
  user: yup.object({
    id: yup.string(),
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
  }),
})

export type TAutocompleteOrganizationUserSchema = yup.InferType<
  typeof AutocompleteOrganizationUserSchema
>
