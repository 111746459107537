import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  AutocompleteLocationMultiSelect,
  IAutocompleteLocationMultiSelectProps,
} from '../AutocompleteLocationMultiSelect'
import { TAutocompleteLocationSchema } from '../ControlledAutocompleteLocation/schema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IAutocompleteLocationMultiSelectProps>,
  TAutocompleteLocationSchema
>

export interface IControlledAutocompleteLocationMultiSelectProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteLocationProps?: Omit<IAutocompleteLocationMultiSelectProps, 'onChange' | 'value'>
  onChange?: (
    e: React.SyntheticEvent,
    value: IAutocompleteLocationMultiSelectProps['value']
  ) => void
}

export const ControlledAutocompleteLocationMultiSelect =
  memo<IControlledAutocompleteLocationMultiSelectProps>(
    ({ AutocompleteLocationProps, control, defaultValue, name, onChange: onChangeProp }) => {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ onChange, value }) => {
            const changeHandler: IAutocompleteLocationMultiSelectProps['onChange'] = (
              e,
              newValue
            ) => {
              onChange(newValue)
              if (onChangeProp) onChangeProp(e, value)
            }
            return (
              <AutocompleteLocationMultiSelect
                {...AutocompleteLocationProps}
                onChange={changeHandler}
                value={value}
              />
            )
          }}
        />
      )
    }
  )
