import React, { useCallback, useMemo, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { PROTECTED_PATHS } from '#constants/paths'
import { getUserEnabledNavLists } from '#utils/getUserEnabledNavLists'
import { useAuthContext } from 'auth/b2b'
import { useAuthMember } from 'auth/b2b/hooks'
import { usePermissionsContext } from 'auth/common/context'
import { AppLayout, IAppLayoutProps } from 'dpl/templates/AppLayout'
import { EnvironmentBanner, TEnvironment } from 'dpl/templates/AppLayout/EnvironmentBanner'
import { getNavLists } from 'dpl/utils/getNavLists'
import { useUserDetailsContext } from '../context/UserDetailsContext'
import { useAnalytics } from '../hooks'
import { useRootLayoutOrgInfo } from './hooks'

const { MODE, VITE_ADMIN_CONSOLE_URL } = import.meta.env

const SHOW_BANNER = ['branch', 'staging', 'uat'].includes(MODE)

const isStaging = ['branch', 'localStaging', 'staging'].includes(MODE)

export interface IRootLayoutProps {
  /**
   * @default false
   */
  displayNavLists?: boolean
  /**
   * @default false
   */
  displayUserInfo?: boolean
}

export function RootLayout({
  displayNavLists: displayNavListsProp = false,
  displayUserInfo: displayUserInfoProp = false,
}: IRootLayoutProps) {
  const { boot: bootIntercom } = useIntercom()
  const { authClient } = useAuthContext()
  const { clearUser } = useAnalytics()

  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location || {}

  const { member } = useAuthMember()
  const {
    email_address: email = '',
    member_id: userId,
    name = '',
    organization_id: companyId = '',
  } = member || {}
  const isUserLoggedIn = Boolean(member)
  const displayNavLists = displayNavListsProp && isUserLoggedIn
  const displayUserInfo = displayUserInfoProp && isUserLoggedIn

  const logoutHandler = useCallback(async () => {
    await authClient.sessions.deleteSession()
    clearUser()
    navigate(0)
  }, [authClient.sessions, clearUser, navigate])

  const { activeProductModules } = useUserDetailsContext()
  const { userPermissions } = usePermissionsContext()

  const { isOrgNavButtonEnabled, orgNavButtonProps, orgNavItem } = useRootLayoutOrgInfo({
    skip: !displayNavLists,
  })

  const navLists = useMemo(() => {
    if (!displayNavLists) {
      return undefined
    }

    const userEnabledNavLists = getUserEnabledNavLists({ activeProductModules, userPermissions })

    return getNavLists({ navLists: userEnabledNavLists, currentPathname: pathname })
  }, [activeProductModules, displayNavLists, pathname, userPermissions])

  const userInfo = useMemo<IAppLayoutProps['userInfo']>(() => {
    if (!displayUserInfo) {
      return undefined
    }

    return {
      email,
      name: name.trim() || undefined,
      userProfileHref: PROTECTED_PATHS.userProfile,
    }
  }, [displayUserInfo, email, name])

  const adminConsoleLinkHandler = () => {
    window.open(VITE_ADMIN_CONSOLE_URL, '_blank')?.focus()
  }

  /**
   * useEffect to initialize intercom only when user information is available.
   */
  useEffect(() => {
    if (isUserLoggedIn) {
      bootIntercom({
        hideDefaultLauncher: true,
        userId,
        name,
        email,
        company: {
          companyId,
          name: orgNavItem?.name,
        },
        customAttributes: {
          /**
           * Staging credentials are used in different modes,
           * we ensure the env is properly set for intercom.
           */
          env: isStaging ? 'staging' : MODE,
        },
      })
    }
  }, [bootIntercom, companyId, email, isUserLoggedIn, name, orgNavItem?.name, userId])

  return (
    <AppLayout
      BannerComponent={<EnvironmentBanner environment={MODE as TEnvironment} />}
      showBanner={SHOW_BANNER}
      userInfo={userInfo}
      navLists={navLists}
      isOrgNavButtonEnabled={isOrgNavButtonEnabled}
      orgNavButtonProps={orgNavButtonProps}
      orgNavItem={orgNavItem}
      onLogout={logoutHandler}
      onClickAdminConsoleLink={adminConsoleLinkHandler}>
      <Outlet />
    </AppLayout>
  )
}
