import React, { memo } from 'react'
import { ISortOrder } from '#types/graphqlTypes'
import DataGrid from 'dpl/components/DataGrid'
import { makeStyles } from 'dpl/core/styles'
import { QueryParamProvider } from 'dpl/providers/QueryParamProvider'
import { FILTERS_OPTIONS_CONFIG } from './constants/customersDataGridFiltersOptionsConfig'
import { SORT_OPTIONS_CONFIG } from './constants/customersDataGridSortOptions'
import { ICustomersDataGrid } from './types'

type TCustomerDataGridPickedProps = 'loading' | 'rows' | 'onFilterOrSortChange'

export interface ICustomersDataGridProps
  extends Pick<ICustomersDataGrid, TCustomerDataGridPickedProps> {
  columns: ICustomersDataGrid['columns']
}

const useCustomersDataGridStyles = makeStyles(() => ({
  listContainer: {
    width: '100%',
  },
}))

export const CustomersDataGrid = memo<ICustomersDataGridProps>(
  ({ columns, loading, onFilterOrSortChange, rows }) => {
    const classes = useCustomersDataGridStyles()

    return (
      <div className={classes.listContainer}>
        <QueryParamProvider>
          <DataGrid
            dataTest='customers-data-grid'
            filtersConfig={FILTERS_OPTIONS_CONFIG}
            sortOptions={SORT_OPTIONS_CONFIG}
            defaultSortValue={ISortOrder.desc}
            rows={rows}
            columns={columns}
            loading={loading}
            onFilterOrSortChange={onFilterOrSortChange}
          />
        </QueryParamProvider>
      </div>
    )
  }
)
