import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import { PROTECTED_PATHS, UNPROTECTED_PATHS } from '#constants/paths'
import {
  AUTH_ROUTES,
  GRAPHI_QL_ROUTE,
  NOT_FOUND_ROUTE,
  ONBOARD_ROUTE,
  RESET_PASSWORD_ROUTE,
} from '#constants/routes'
import { RootLayout } from '#layouts/RootLayout'
import { IProductModules } from '#types/graphqlTypes'
import { getUserEnabledRoutes } from '#utils/getUserEnabledRoutes'
import { UnprotectedLayout as AuthLayout, ProtectedLayout } from 'auth/b2b/layouts'
import { IUserPermissions } from 'auth/common/context/PermissionsContext/types'

interface IUseRoutesState {
  /**
   * Routes for the application.
   */
  routes: RouteObject[]
}

interface IUseRoutesProps {
  /**
   * The active product modules.
   */
  activeProductModules?: IProductModules[]
  /**
   * Indicates if the user session is active.
   */
  isSessionActive: boolean
  /**
   * User permissions.
   */
  userPermissions: IUserPermissions
}

export const useRoutes = ({
  activeProductModules,
  isSessionActive,
  userPermissions,
}: IUseRoutesProps): IUseRoutesState => {
  /**
   * Routes for unauthenticated users.
   * If there is no session, then the user is not logged in and should be redirected to the login page.
   */
  if (!isSessionActive) {
    return {
      routes: [
        {
          path: '/',
          element: <Navigate to={UNPROTECTED_PATHS.login} />,
        },
        {
          path: '',
          element: <RootLayout />,
          children: [
            {
              path: '',
              element: <AuthLayout />,
              // authentication child routes
              children: AUTH_ROUTES,
            },
            RESET_PASSWORD_ROUTE,
          ],
        },
        {
          path: '*',
          element: <Navigate to={UNPROTECTED_PATHS.login} />,
        },
      ],
    }
  }

  /**
   * Routes for authenticated users.
   */
  return {
    routes: [
      {
        path: '/',
        element: <RootLayout displayNavLists displayUserInfo />,
        children: [
          {
            path: '',
            element: (
              <ProtectedLayout
                redirectTo={UNPROTECTED_PATHS.login}
                redirectToOnboarding={PROTECTED_PATHS.onboard}
              />
            ),
            // protected child routes
            children: getUserEnabledRoutes({ activeProductModules, userPermissions }),
          },
          NOT_FOUND_ROUTE,
        ],
      },
      {
        path: '',
        element: <RootLayout displayUserInfo />,
        children: [
          {
            path: '',
            element: <ProtectedLayout redirectTo={UNPROTECTED_PATHS.login} />,
            children: [ONBOARD_ROUTE],
          },
        ],
      },
      {
        path: '',
        element: <RootLayout />,
        children: [
          {
            path: '',
            element: <AuthLayout />,
            children: AUTH_ROUTES,
          },
          RESET_PASSWORD_ROUTE,
        ],
      },
      ...(import.meta.env.PROD ? [] : [GRAPHI_QL_ROUTE]),
    ],
  }
}
