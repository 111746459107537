import React, { memo } from 'react'
import classNames from 'classnames'
import { createStyles, createTheme, makeStyles } from '../../core'
import { brandColors } from '../../theme/colors'
import { capitalize } from '../../utils/capitalize'
import { TStopType, TMapMarkerStyle, STOP_TYPE } from './types'

export interface IStyleProps extends Record<STOP_TYPE, string> {}

const STYLE_PROPS: Record<TMapMarkerStyle, IStyleProps> = {
  traditional: {
    pickup: brandColors.coolGray8,
    delivery: brandColors.skyBlue6,
  },
  newWave: {
    pickup: brandColors.skyBlue6,
    delivery: brandColors.lightPurple,
  },
  disabled: {
    pickup: brandColors.coolGray5,
    delivery: brandColors.coolGray5,
  },
}

type TClassKey = 'root' | 'pickup' | 'delivery' | 'mini'

const useMapMarkerStyles = makeStyles(() => {
  const theme = createTheme()
  return createStyles<TClassKey, Partial<IMapMarkerProps>>({
    root: {
      alignItems: 'center',
      boxShadow: `0 0 0 2px ${brandColors.white}`,
      color: brandColors.coolGray1,
      display: 'inline-flex',
      fontSize: theme.spacing(1.5),
      fontWeight: 800,
      height: theme.spacing(3),
      justifyContent: 'center',
      paddingTop: theme.spacing(0.125),
      width: theme.spacing(3),

      '&:hover': {
        cursor: 'pointer',
      },
    },
    pickup: {
      backgroundColor: ({ markerStyle = 'traditional' }) => STYLE_PROPS[markerStyle].pickup,
      borderRadius: '50%',
    },
    delivery: {
      backgroundColor: ({ markerStyle = 'traditional' }) => STYLE_PROPS[markerStyle].delivery,
      border: ({ markerStyle = 'traditional' }) => `2px solid ${STYLE_PROPS[markerStyle].delivery}`,
      borderRadius: '2px',
    },
    mini: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      boxShadow: 'none',
    },
  })
})

export interface IMapMarkerProps {
  /**
   * @example 'pickup'
   */
  type?: TStopType
  /**
   * Set to 0 in order to not show a number in the Marker (i.e. P, or D). If
   * set to any number greater than 0, the number gets concatenated to the P or
   * D (i.e. P1, D2).
   */
  sequence?: number
  /**
   * Renders a smaller size
   * @default false
   */
  mini?: boolean
  /**
   * Sets the color and style for markers
   * @default 'traditional'
   */
  markerStyle?: TMapMarkerStyle
  className?: string
  dataTest?: string
}

export const MapMarker = memo<IMapMarkerProps>(
  ({
    dataTest = 'MapMarker',
    type = STOP_TYPE.pickup,
    sequence,
    className: classNameProp,
    mini = false,
    markerStyle = 'traditional',
  }) => {
    const markerType = type === STOP_TYPE.delivery ? STOP_TYPE.delivery : type
    const classes = useMapMarkerStyles({ markerStyle })
    const className = classNames(
      classes.root,
      {
        [classes.mini]: mini,
        [classes.pickup]: markerType === 'pickup',
        [classes.delivery]: markerType === 'delivery',
      },
      classNameProp
    )
    return (
      <span className={className} data-test={dataTest}>
        {!mini ? capitalize({ str: type[0] }) : undefined}
        {sequence && sequence > 0 && !mini ? sequence : undefined}
      </span>
    )
  }
)

export default MapMarker
