import { DefaultValues, useForm, UseFormOptions, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomerFormSchema, TCustomerFormSchema } from '../schema/CustomerFormSchema'

export const getDefaultValues = (values: DefaultValues<TCustomerFormSchema>) => {
  return CustomerFormSchema.cast(
    {
      name: '',
      externalId: '',
      ...values,
    },
    {
      assert: false,
    }
  )
}

export interface IUseCustomerFormProps
  extends Omit<UseFormOptions<TCustomerFormSchema>, 'resolver'> {}

export const useCustomerForm = ({ defaultValues = {}, ...rest }: IUseCustomerFormProps) => {
  return useForm<TCustomerFormSchema>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: yupResolver(CustomerFormSchema),
    ...rest,
  })
}

export const useCustomerFormContext = () => useFormContext<TCustomerFormSchema>()
