import React, { useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useAnalytics } from '#hooks'
import { DEFAULT_FLAT_VOLUME_PER_MONTH } from '#routes/contract-rates-tool/ContractRatesToolPage/forms/ContractLaneInformationForm/constants'
import { getDateVolumeRange } from '#routes/contract-rates-tool/ContractRatesToolPage/forms/ContractLaneInformationForm/utils'
import { IVolumeTypes } from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import {
  IContractRfpLaneVolumeFrequencies,
  IContractRfpVersionLaneRateRateTypes,
  IEquipmentKeys,
} from '#types/graphqlTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import { brandColors, IFormModalProps, INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import SidePanel from 'dpl/components/SidePanel'
import { Box, Button, Divider, makeStyles, Typography } from 'dpl/core'
import ControlledTextField from 'forms/components/ControlledTextField'
import moment from 'moment'
import { IRfpAddLaneSchema, RfpAddLaneSchema } from '../../schema'
import { getLaneInfoInput, getLaneRatesInput } from '../../utils'
import { LaneForm } from '../EditLaneInformationSideNav/components/LaneForm/LaneForm'
import { Estimate } from '../EditLaneSideNav/components'
import { useAddLaneMutation } from './graphql/AddLane'
import { useExternalDuplicatesQuery } from './graphql/ExternalDuplicates'

export interface IAddLaneSideNavProps extends Pick<IFormModalProps, 'onClose'> {
  contractRfpVersionId: string
  currentRoundNumber: number
  contractStartAt: string
  contractEndAt: string
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 72,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(3),
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: brandColors.white,
    zIndex: 1,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

export function AddLaneSideNav({
  contractEndAt,
  contractRfpVersionId,
  contractStartAt,
  currentRoundNumber,
  onClose,
}: IAddLaneSideNavProps) {
  const classes = useStyles()
  const { trackEvent } = useAnalytics()
  const [apiError, setApiError] = useState<string>('')
  const [addNewLane, { loading }] = useAddLaneMutation()
  const contractStartDate = moment(contractStartAt).format('YYYY-MM')
  const contractEndDate = moment(contractEndAt).format('YYYY-MM')

  const methods = useForm<IRfpAddLaneSchema>({
    resolver: yupResolver(RfpAddLaneSchema),
    defaultValues: {
      equipment: IEquipmentKeys.dry_van,
      dropType: 'live_live',
      volume: {
        volumeFrequency: IContractRfpLaneVolumeFrequencies.monthly,
        volumeType: IVolumeTypes.flatVolume,
        flatVolumePerMonth: null,
        dynamicVolumePerMonth: getDateVolumeRange({
          startDate: contractStartDate,
          endDate: contractEndDate,
          volume: DEFAULT_FLAT_VOLUME_PER_MONTH,
        }),
      },
      rateType: IContractRfpVersionLaneRateRateTypes.all_in,
      stops: [
        {
          location: null,
          type: 'pickup',
        },
        {
          location: null,
          type: 'delivery',
        },
      ],
    },
  })

  const { clearErrors, control, handleSubmit, setError } = methods

  const externalId = useWatch<IRfpAddLaneSchema['externalId']>({
    control,
    name: 'externalId',
  })

  /**
   * Clean errors when externalId changes
   */
  useEffect(() => {
    clearErrors('externalId')
  }, [externalId])

  useExternalDuplicatesQuery({
    variables: {
      externalId: externalId?.trim() || '',
      contractRfpVersionId,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const { contractRfpVersionLanes } = data || {}
      const { nodes } = contractRfpVersionLanes || {}
      const [existingLane] = nodes || []

      if (existingLane?.externalId) {
        setError('externalId', {
          type: 'validate',
          message: 'This Lane ID already exists. Please enter a unique ID or leave blank.',
        })
      }
    },
  })

  const handleRateSubmit = handleSubmit(async data => {
    const input = {
      contractRfpVersionId,
      externalId: data.externalId || '',
      ...getLaneRatesInput(data),
      ...getLaneInfoInput(data),
    }

    try {
      const { data: apiData } = await addNewLane({
        variables: {
          input,
        },
        refetchQueries: ['CurrentVersionLanes'],
      })
      const { upsertContractRfpVersionLane } = apiData || {}
      const { errors } = upsertContractRfpVersionLane || {}

      if (errors?.length) {
        setApiError(errors[0].message)

        trackEvent('Contract RFPs', 'CREATE_LANE_ERROR', {
          variables: input,
          errors,
        })
        return
      }

      trackEvent('Contract RFPs', 'CREATE_LANE_SUCCESS', {
        variables: input,
      })

      onClose()
    } catch (error) {
      setApiError(error.message)

      trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
        variables: input,
        apolloErrors: [error],
      })
    }
  })

  return (
    <SidePanel
      onClose={onClose}
      header={
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography variant='h3'>Add Lane</Typography>
          <Typography variant='body2'>
            This will add a lane to <b>Round {currentRoundNumber}</b>.
          </Typography>
        </Box>
      }
      anchor='right'>
      <div className={classes.root}>
        {apiError && (
          <InlineMessage
            className={classes.error}
            type={INLINE_MESSAGE_VARIANTS.ALERT}
            message={apiError}
          />
        )}
        <FormProvider {...methods}>
          <ControlledTextField
            name='externalId'
            control={control}
            TextFieldProps={{
              label: 'Lane ID (Optional)',
              placeholder: 'Enter',
              helperText:
                methods.errors.externalId?.message || 'If left blank an ID will be generated.',
              error: !!methods.errors.externalId,
            }}
          />
          <Divider className={classes.divider} />
          <Typography marginTop={3} variant='subtitle1'>
            Lane Details
          </Typography>
          <LaneForm contractStartAt={contractStartDate} contractEndAt={contractEndDate} />
          <Divider className={classes.divider} />
          <Estimate />
        </FormProvider>
      </div>
      <div className={classes.actions}>
        <Button variant='text' onClick={onClose}>
          Cancel
        </Button>
        <Button type='submit' onClick={handleRateSubmit} disabled={loading}>
          Add Lane
        </Button>
      </div>
    </SidePanel>
  )
}
