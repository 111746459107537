import React from 'react'
import { ControlledAutocompleteLocationMultiSelect } from '#components/ControlledAutocompleteLocationMultiSelect'
import { ControlledAutocompleteState } from '#components/ControlledAutocompleteState'
import { IGeocodingResultTypeEnum } from '#types/graphqlTypes'
import { Stack } from 'dpl/core'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import get from 'lodash/get'
import { useAdjustmentRuleFormContext } from '../../../../hooks'
import { GEOGRAPHIC_TYPES } from '../../../../types'
import { GEOGRAPHIC_TYPE_OPTIONS } from '../../constants'
import { TWatchedLocationFactor } from '../../types'
import { ControlledRegionSelect } from '../ControlledRegionSelect'

export interface ILocationFactorInputsProps {
  /**
   * Name of this factor in form
   * @example 'factors.pickupLocation'
   */
  factorName: string
  /**
   * The current value of this location factor
   */
  values: TWatchedLocationFactor
}

export function LocationFactorInputs({ factorName, values }: ILocationFactorInputsProps) {
  const { control, errors } = useAdjustmentRuleFormContext()
  const geographicTypeName = `${factorName}.geographicType`
  const regionName = `${factorName}.region`
  const stateName = `${factorName}.state`
  const fiveDigitZipCodesName = `${factorName}.fiveDigitZipCodes`

  const { geographicType } = values || {}

  return (
    <Stack gap={3}>
      <ControlledSelectField
        control={control}
        name={geographicTypeName}
        SelectProps={{
          label: 'Geographic Type',
          options: GEOGRAPHIC_TYPE_OPTIONS,
          error: !!get(errors, `${geographicTypeName}.message`),
          helperText: get(errors, `${geographicTypeName}.message`),
          dataTest: `${geographicTypeName}-select`,
        }}
      />
      {geographicType === GEOGRAPHIC_TYPES.regional && (
        <ControlledRegionSelect
          name={regionName}
          control={control}
          errorMessage={get(errors, `${regionName}.message`)}
        />
      )}
      {geographicType === GEOGRAPHIC_TYPES.state && (
        <ControlledAutocompleteState
          name={stateName}
          control={control}
          AutocompleteLocationProps={{
            label: 'State',
            error: !!get(errors, `${stateName}.message`),
            helperText: get(errors, `${stateName}.message`),
            dataTest: `${stateName}-autocomplete`,
          }}
        />
      )}
      {geographicType === GEOGRAPHIC_TYPES.fiveDigitZipCodes && (
        <ControlledAutocompleteLocationMultiSelect
          name={fiveDigitZipCodesName}
          control={control}
          AutocompleteLocationProps={{
            label: '5-digit ZIPs',
            resultTypes: [IGeocodingResultTypeEnum.PostalCode],
            getOptionLabel: option => option.postalCode || '',
            error: !!get(errors, `${fiveDigitZipCodesName}.message`),
            helperText: get(errors, `${fiveDigitZipCodesName}.message`),
            isOptionEqualToValue: (option, value) => option?.postalCode === value?.postalCode,
            dataTest: `${fiveDigitZipCodesName}-autocomplete`,
          }}
        />
      )}
    </Stack>
  )
}
