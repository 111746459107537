import React, { useMemo } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import HelpIcon from 'dpl/icons/build/HelpIcon'
import { QueryParamProvider } from 'dpl/providers/QueryParamProvider'
import { PageTemplate } from 'dpl/templates'
import { IPageTitleProps } from 'dpl/templates/PageTemplate/PageTitle'
import { useFlagsContext } from 'flags'
import { PROTECTED_PATHS } from '../../constants'
import { AdjustmentRulesSection } from './components/AdjustmentRulesSection'
import { SpotRatesSettingsSection } from './components/SpotRatesSettingsSection'
import { SpotRatesControlPanelProvider } from './context'

export function SpotRatesControlPanel() {
  const navigate = useNavigate()
  const { isFlagEnabled } = useFlagsContext()
  const isControlPanelEnabled = isFlagEnabled('spot_rates_control_panel')
  const isAdjustmentRulesEnabled = isFlagEnabled('spot_rates_control_panel_rules')

  const actions = useMemo<IPageTitleProps['actions']>(() => {
    if (isAdjustmentRulesEnabled) {
      return [
        {
          label: 'How do rules work?',
          buttonProps: {
            startIcon: <HelpIcon size='large' />,
            href: 'https://intercom.help/transfix-solutions/en/articles/10206075-spot-rates-faq',
            rel: 'noreferrer',
            target: '_blank',
          },
        },
      ]
    }
    return []
  }, [isAdjustmentRulesEnabled])

  /**
   * Redirect to Rates Tool if Control Panel flag is off
   */
  if (!isControlPanelEnabled) {
    return <Navigate to={PROTECTED_PATHS.rates} />
  }

  return (
    <QueryParamProvider>
      <SpotRatesControlPanelProvider>
        <PageTemplate
          pageTitle='Control Panel'
          dataTest='route-rates-control-panel'
          PageBodyTitleProps={{
            pageType: 'subPage',
            onBackButtonClick: () => navigate(PROTECTED_PATHS.rates),
            actions,
          }}>
          <SpotRatesSettingsSection />
          {isAdjustmentRulesEnabled && <AdjustmentRulesSection />}
        </PageTemplate>
      </SpotRatesControlPanelProvider>
    </QueryParamProvider>
  )
}
