import React from 'react'
import { Tooltip } from 'dpl'
import { DISPLAY } from 'dpl/constants'
import { Box, Typography } from 'dpl/core'
import { InfoIcon } from 'dpl/icons'
import startCase from 'lodash/startCase'
import { IRfpLaneFragment } from '../../../LaneList/graphql/RfpLane'

export interface IVolumeDescriptionProps {
  lane: Pick<
    IRfpLaneFragment,
    | 'volumeFrequency'
    | 'volumeTotalAmount'
    | 'volumeAmounts'
    | 'volumeFlatAmount'
    | 'isVolumeDynamic'
    | 'rate'
  >
}

export function VolumeDescription({ lane }: IVolumeDescriptionProps) {
  const {
    isVolumeDynamic,
    rate,
    volumeAmounts,
    volumeFlatAmount,
    volumeFrequency,
    volumeTotalAmount,
  } = lane || {}
  const { source } = rate || {}

  if (!volumeTotalAmount && source === 'auto_rate_gen') {
    return (
      <Box display='flex' alignItems='center' gap={0.5}>
        <Typography color='textSecondary'>Volume: -</Typography>
        <Tooltip title='Volume: Rate generated using 10 Monthly'>
          <Box display='flex' alignItems='center'>
            <InfoIcon color='coolGray5' />
          </Box>
        </Tooltip>
      </Box>
    )
  }

  if (isVolumeDynamic) {
    return (
      <Box display='flex' alignItems='center' gap={0.5}>
        <Typography variant='subtitle2' color='textSecondary'>
          Volume: {volumeTotalAmount || DISPLAY.empty} Total&nbsp; (
          {volumeFrequency ? `Dynamic ${startCase(volumeFrequency)}` : DISPLAY.empty})
        </Typography>
        <Tooltip
          title={
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
              <div>Volume: Dynamic {startCase(volumeFrequency || 'monthly')}</div>
              <div>{volumeAmounts?.map(({ volume }) => volume).join(', ')}</div>
            </Box>
          }>
          <Box display='flex' alignItems='center'>
            <InfoIcon color='coolGray5' />
          </Box>
        </Tooltip>
      </Box>
    )
  }

  if (volumeFlatAmount && (volumeFrequency || volumeTotalAmount)) {
    return (
      <Typography variant='subtitle2' color='textSecondary'>
        Volume: {lane?.volumeTotalAmount || '-'} Total (
        {volumeFrequency ? `${volumeFlatAmount} ${startCase(volumeFrequency)}` : '-'})
      </Typography>
    )
  }

  return (
    <Typography variant='subtitle2' color='textSecondary'>
      Volume: - {volumeFrequency ? startCase(volumeFrequency) : ''}
    </Typography>
  )
}
