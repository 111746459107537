interface FormatterProps {
  minTemp: number
  maxTemp: number
}

export function minMaxTempFormatter({ maxTemp, minTemp }: FormatterProps): string {
  const tempValue = minTemp === maxTemp ? `${maxTemp}°` : `${minTemp}° to ${maxTemp}°`

  return tempValue
}
