import React, { memo, useCallback, useMemo, useState } from 'react'
import { IRoutingGuideActivityEventTypesEnum, ISortOrder } from '#types/graphqlTypes'
import { makeStyles } from 'dpl/core/styles'
import { getGraphqlAfterCursor } from 'dpl/utils/graphql/getGraphqlAfterCursor'
import compact from 'lodash/compact'
import { RoutingGuideActivityDataGrid } from '../RoutingGuideActivityDataGrid'
import { IRgActivityEventFragment } from '../RoutingGuideActivityDataGrid/graphql/RGActivityFragment'
import { useActivityQuery } from '../RoutingGuideActivityDataGrid/graphql/RoutingGuideLaneActivity'
import { IRoutingGuideActivityDataGrid } from '../RoutingGuideActivityDataGrid/types'

const useActivitySectionStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2, 6),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0),
    },
  },
}))

const PAGE_SIZE = 10
export interface IActivitySectionProps {
  /**
   * The id of the routing guide lane
   */
  routingGuideLaneId: string
  /**
   * @default 'ActivitySection'
   */
  dataTest?: string
}

export const ActivitySection = memo<IActivitySectionProps>(
  ({ dataTest = 'ActivitySection', routingGuideLaneId }) => {
    const classes = useActivitySectionStyles()
    const [page, setPage] = useState<number>(0)

    const paginationChangeHanlder = useCallback<
      NonNullable<IRoutingGuideActivityDataGrid['onPaginationModelChange']>
    >(paginationModel => {
      const { page } = paginationModel || {}
      setPage(page)
    }, [])

    const paginationModel = useMemo(
      () => ({
        pageSize: PAGE_SIZE,
        page,
      }),
      [page]
    )

    const { data, loading } = useActivityQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        first: PAGE_SIZE,
        after: getGraphqlAfterCursor({ pageNumber: page, pageSize: PAGE_SIZE }),
        sortByDateOrder: ISortOrder.asc,
        id: routingGuideLaneId,
        eventTypes: [
          IRoutingGuideActivityEventTypesEnum.added_carrier,
          IRoutingGuideActivityEventTypesEnum.created_lane,
          IRoutingGuideActivityEventTypesEnum.updated_lane,
          IRoutingGuideActivityEventTypesEnum.deactivated_carrier,
          IRoutingGuideActivityEventTypesEnum.reordered_carriers,
          IRoutingGuideActivityEventTypesEnum.updated_carrier,
        ],
      },
    })
    const { routingGuideLaneActivity } = data || {}
    const { edges, totalCount } = routingGuideLaneActivity || {}
    const activities = edges?.map(edge => {
      return edge?.node
    })
    const rows = compact<IRgActivityEventFragment>(activities)

    return (
      <div className={classes.container} data-test={dataTest}>
        <RoutingGuideActivityDataGrid
          loading={loading}
          onPaginationModelChange={paginationChangeHanlder}
          paginationModel={paginationModel}
          rowCount={totalCount}
          rows={rows}
        />
      </div>
    )
  }
)
