import { IAdjustmentTypeEnum, ICreateOrganizationAdjustmentRuleInput } from '#types/graphqlTypes'
import { FORMATS } from 'dpl/constants'
import moment from 'moment'
import { IRuleTypeEnum } from '../../../../../../../types/rates'
import { TAdjustmentRuleFormSchema } from '../../../schema'
import { ADJUSTMENT_TYPES } from '../../../types'
import { mapFactorsSchemaToInput } from './mapFactorsSchemaToInput'

export const mapAdjustmentRuleSchemaToInput = (
  formData: TAdjustmentRuleFormSchema
): ICreateOrganizationAdjustmentRuleInput => {
  const { factors, rateStrategy, reason, timeframe } = formData || {}
  const { endDate, noEndDate, startDate } = timeframe || {}
  const {
    adjustmentType,
    costAdditiveShiftPercentage,
    costBasePercentile,
    costRuleType,
    marginAdditivePercentage,
    marginBasePercentage,
    marginRuleType,
  } = rateStrategy || {}

  const startAt = moment(startDate).format(FORMATS.datetimeFormatApi)
  const endAt = noEndDate ? null : moment(endDate).format(FORMATS.datetimeFormatApi)
  const factorsInput = mapFactorsSchemaToInput(factors)

  // COST
  if (adjustmentType === ADJUSTMENT_TYPES.cost) {
    const adjustmentValue =
      costRuleType === IRuleTypeEnum.base
        ? costBasePercentile
        : (costAdditiveShiftPercentage || 0) / 100

    return {
      reason,
      adjustmentType: IAdjustmentTypeEnum.cost,
      adjustmentValue,
      ruleAction: costRuleType || '',
      startAt,
      endAt,
      factors: factorsInput,
    }
  }

  // MARGIN
  let adjustmentValue = null
  if (marginRuleType === IRuleTypeEnum.base) adjustmentValue = (marginBasePercentage || 0) / 100
  if (marginRuleType === IRuleTypeEnum.additive)
    adjustmentValue = (marginAdditivePercentage || 0) / 100

  return {
    reason,
    adjustmentType: IAdjustmentTypeEnum.margin,
    adjustmentValue,
    ruleAction: marginRuleType || '',
    startAt,
    endAt,
    factors: factorsInput,
  }
}
