import { IAdjustmentTypeEnum } from '#/types/graphqlTypes'
import { IRuleTypeEnum } from '#/types/rates'
import { IMatchedAdjustmentRuleVersionFragment } from '#routes/spot-rates-tool/graphql/MatchedAdjustmentRuleVersionFragment'

export const getAdjustmentRulesByType = (matchedRules: IMatchedAdjustmentRuleVersionFragment[]) => {
  const marginRules: IMatchedAdjustmentRuleVersionFragment[] = []
  const costRules: IMatchedAdjustmentRuleVersionFragment[] = []
  const doNotBidRules: IMatchedAdjustmentRuleVersionFragment[] = []

  matchedRules?.forEach(rule => {
    const { adjustmentType, ruleAction } = rule || {}
    if (adjustmentType === IAdjustmentTypeEnum.cost) {
      costRules.push(rule)
    }
    if (adjustmentType === IAdjustmentTypeEnum.margin) {
      if (ruleAction === IRuleTypeEnum.doNotBid) {
        doNotBidRules.push(rule)
      } else {
        marginRules.push(rule)
      }
    }
  })

  return { marginRules, costRules, doNotBidRules }
}
