import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IWebLinkInput } from '#types/graphqlTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormModal, IFormModalProps, INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import { Box } from 'dpl/core'
import ControlledTextField from 'forms/components/ControlledTextField'
import { useFiles } from '../../useFiles'
import { LinkFormSchema, TLinkFormSchema } from './LinkFormSchema'

export interface ILinkModalProps extends Pick<IFormModalProps, 'onClose'> {
  webLinks: IWebLinkInput[]
  editIndex?: number
  contractId: string
}

export function LinkModal({ contractId, editIndex, onClose, webLinks }: ILinkModalProps) {
  const [apiError, setApiError] = useState('')
  const isEditing = typeof editIndex === 'number'
  const methods = useForm<TLinkFormSchema>({
    defaultValues: {
      link: isEditing ? webLinks?.[editIndex]?.url : '',
      name: isEditing ? webLinks?.[editIndex]?.name : '',
    },
    resolver: yupResolver(LinkFormSchema),
  })
  const { errors } = methods
  const { addLink, editLink } = useFiles({
    contractId,
    webLinks,
  })

  return (
    <FormModal
      open
      onClose={onClose}
      maxWidth='md'
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          ButtonProps: {
            variant: 'text',
          },
        },
        {
          label: 'Save',
          action: methods.handleSubmit(async ({ link, name }) => {
            try {
              const method = isEditing ? editLink : addLink
              const error = await method({ name: name || '', url: link, index: editIndex })

              if (error) {
                setApiError(error)
                return
              }

              onClose()
            } catch (error) {
              setApiError(error.message)
            }
          }),
          dataTest: 'link-save-button',
        },
      ]}
      title={isEditing ? 'Edit Link' : 'Add Link'}>
      <FormProvider {...methods}>
        <Box display='flex' flexDirection='column' gap={3}>
          {apiError && <InlineMessage message={apiError} type={INLINE_MESSAGE_VARIANTS.ALERT} />}
          <ControlledTextField
            name='link'
            TextFieldProps={{
              label: 'Link URL*',
              helperText: errors.link?.message,
              error: Boolean(errors.link),
              dataTest: 'link-url-input',
            }}
          />
          <ControlledTextField
            name='name'
            TextFieldProps={{
              label: 'Name',
              helperText: errors.name?.message,
              error: Boolean(errors.name),
              dataTest: 'link-name-input',
            }}
          />
        </Box>
      </FormProvider>
    </FormModal>
  )
}
