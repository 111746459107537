import React from 'react'
import { IContractRfp, IOrganizationShipper } from '#types/graphqlTypes'
import {
  brandColors,
  ConfirmationModal,
  IConfirmationModalProps,
  IModalAction,
  INLINE_MESSAGE_VARIANTS,
  InlineMessage,
} from 'dpl'
import { FORMATS } from 'dpl/constants'
import { Box, Card, CardContent, makeStyles, Typography } from 'dpl/core'
import { CalendarIcon, CustomerIcon } from 'dpl/icons/build'
import moment from 'moment'
import { getDuration } from '../../../utils/utils'

export interface IArchiveContractRFPConfirmationModalProps
  extends Pick<IConfirmationModalProps, 'open' | 'onClose'> {
  onConfirm: () => Promise<void>
  contractRfp: Pick<IContractRfp, 'displayId' | 'name' | 'startAt' | 'endAt'> & {
    organizationShipper: Pick<IOrganizationShipper, 'name'>
  }
  /**
   * @description Error messages from the archive mutation result.
   */
  errorMessages?: string[] | undefined
}

const useArchiveContractRFPConfirmationModalStyles = makeStyles(theme => ({
  name: {
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

export function ArchiveContractRFPConfirmationModal({
  contractRfp,
  errorMessages,
  onClose,
  onConfirm,
  open,
}: IArchiveContractRFPConfirmationModalProps) {
  const classes = useArchiveContractRFPConfirmationModalStyles()

  const { displayId, endAt, name: contractRfpName, organizationShipper, startAt } = contractRfp
  const { name: organizationShipperName } = organizationShipper

  const formattedStartAt = moment(startAt).format(FORMATS.monthDayYear)
  const formattedEndAt = moment(endAt).format(FORMATS.monthDayYear)
  const duration = getDuration(startAt, endAt)

  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'dismiss',
      action: onClose,
      ButtonProps: { color: 'secondary' },
    },
    {
      label: 'Archive RFP',
      type: 'confirm',
      action: onConfirm,
      ButtonProps: { color: 'error' },
    },
  ]

  return (
    <ConfirmationModal
      dataTest='archive-rfp-confirmation-modal'
      title={`Archive RFP-${displayId}`}
      subtitle='Are you sure you want to archive this RFP? Once it is archived, the RFP cannot be edited.'
      subtitleVariant='body2'
      includeCloseIconButton={false}
      maxWidth='md'
      actions={actions}
      open={open}
      onClose={onClose}>
      <Card>
        <CardContent>
          <Typography variant='subtitle1' className={classes.name}>
            {contractRfpName}
          </Typography>

          <Box display='flex' flexDirection='row' alignItems='center' mb={1}>
            <CustomerIcon className={classes.icon} size='xlarge' color='coolGray5' />
            <Typography variant='body2' color={brandColors.coolGray5}>
              {organizationShipperName}
            </Typography>
          </Box>

          <Box display='flex' flexDirection='row' alignItems='center'>
            <CalendarIcon className={classes.icon} size='xlarge' color='coolGray5' />
            <Typography variant='body2' color={brandColors.coolGray5}>
              {formattedStartAt} - {formattedEndAt} ({duration})
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Box mt={1}>
        {errorMessages?.map(error => (
          <InlineMessage
            dataTest='archive-rfp-error'
            message={error}
            key={error}
            fullWidth
            type={INLINE_MESSAGE_VARIANTS.ALERT}
          />
        ))}
      </Box>
    </ConfirmationModal>
  )
}
