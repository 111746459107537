import React from 'react'
import { FORMATS } from 'dpl/constants'
import { LinearProgress, Typography } from 'dpl/core'
import moment from 'moment'

export interface IFileProps {
  name: string
  createdAt: string
  isLoading: boolean
}

export function File({ createdAt, isLoading, name }: IFileProps) {
  return (
    <div>
      <div>{name}</div>
      {isLoading ? (
        <>
          <LinearProgress variant='indeterminate' />
          <Typography color='coolGray5' variant='caption'>
            This may take a few minutes...
          </Typography>
        </>
      ) : (
        <div>{moment(createdAt).format(FORMATS.monthDayYearTimeFormat12hUppercase)}</div>
      )}
    </div>
  )
}
