import React from 'react'
import { Control, FieldError, DeepMap, Controller } from 'react-hook-form'
import {
  DEFAULT_CONTRACT_DURATION,
  IVolumeSchema,
} from '#routes/contract-rates-tool/ContractRatesToolPage/forms/ContractLaneInformationForm/schema'
import {
  contractDurationFormatter,
  getTotalLoads,
} from '#routes/contract-rates-tool/ContractRatesToolPage/forms/ContractLaneInformationForm/utils'
import { IVolumeTypes } from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import { IRfpLaneInfoSchema } from '#routes/contract-rfp/ContractRFPDetailPage/schema'
import { brandColors, FormHelperText, Tooltip } from 'dpl'
import NumericFormat from 'dpl/components/NumericFormat'
import FORMATS from 'dpl/constants/datetime'
import { Box, Collapse, Grid, Stack, Typography } from 'dpl/core'
import { InfoIcon } from 'dpl/icons'
import ControlledHiddenInput from 'forms/components/ControlledHiddenInput'
import ControlledRadioGroup from 'forms/components/ControlledRadioGroup'
import moment from 'moment'

export interface IVolumePreferenceFormProps {
  control: Control<IRfpLaneInfoSchema>
  dynamicVolumePerMonthErrors: DeepMap<IVolumeSchema['dynamicVolumePerMonth'], FieldError>
  flatVolumePerMonthError?: FieldError
  /**
   * @default 'VolumePreferenceFormBody'
   */
  dataTest?: string
  volume?: IRfpLaneInfoSchema['volume']
  contractDuration?: {
    contractStartDate: string
    contractEndDate: string
    contractLengthMonths: number
  }
  selectVolumeTypeHandler: (volumeType: IVolumeTypes) => void
  trigger: (name?: string | string[] | undefined) => Promise<boolean>
  allowDynamic: boolean
  hasEquipmentTypeForRateGen: boolean
}

export function VolumePreferenceForm({
  allowDynamic,
  contractDuration,
  control,
  dataTest,
  dynamicVolumePerMonthErrors,
  flatVolumePerMonthError,
  hasEquipmentTypeForRateGen,
  selectVolumeTypeHandler,
  trigger,
  volume,
}: IVolumePreferenceFormProps) {
  const { dynamicVolumePerMonth = [], flatVolumePerMonth, volumeType } = volume || {}
  const { contractLengthMonths = 0 } = contractDuration || {}

  const contractDurationValue = contractDuration ?? DEFAULT_CONTRACT_DURATION
  const formattedCurrentContractDuration = contractDurationFormatter(contractDurationValue)

  const formatMonthYear = (date: string) => moment(date).format(FORMATS.monthYearShort)

  const { totalDynamicLoads } = getTotalLoads({
    dynamicVolumePerMonth,
    flatVolumePerMonth,
    contractDurationLength: contractLengthMonths,
  })
  const { message: flatVolumePerMonthErrorMessage } = flatVolumePerMonthError || {}

  const getErrorMessage = (index: number) => dynamicVolumePerMonthErrors?.[index]?.volume?.message

  const dynamicVolumePerMonthError = dynamicVolumePerMonthErrors as { message?: string } | undefined

  return (
    <Stack data-test={dataTest} gap={3} component='div'>
      <ControlledRadioGroup
        onChange={value => {
          selectVolumeTypeHandler(value as IVolumeTypes)
        }}
        RadioGroupProps={{
          big: true,
          options: [
            {
              label: 'Flat',
              name: IVolumeTypes.flatVolume,
              description: 'Volume will be equal for each selected month',
              value: IVolumeTypes.flatVolume,
              dataTest: 'flat-option',
            },
            {
              label: 'Dynamic',
              name: IVolumeTypes.dynamicVolume,
              description: 'Volume can be custom for each selected month',
              value: IVolumeTypes.dynamicVolume,
              dataTest: 'dynamic-option',
              disabled: !allowDynamic,
            },
          ],
        }}
        name='volume.volumeType'
        control={control}
      />
      {volumeType === IVolumeTypes.flatVolume && (
        <>
          <Box>
            <Controller
              control={control}
              name='volume.flatVolumePerMonth'
              render={({ onChange, value }) => (
                <NumericFormat
                  label='Volume Amount per Month'
                  error={!!flatVolumePerMonthErrorMessage}
                  helperText={flatVolumePerMonthErrorMessage}
                  dataTest='flat-volume-per-month-input'
                  value={value}
                  decimalScale={0}
                  allowNegative={false}
                  onValueChange={({ floatValue }) => {
                    onChange(floatValue ?? null)
                  }}
                />
              )}
            />
          </Box>
          <Box display='flex' alignItems='center' gap={0.5}>
            <Typography variant='subtitle1' color={brandColors.coolGray5}>
              {`Total for ${formattedCurrentContractDuration} : ${flatVolumePerMonth ? `${flatVolumePerMonth * contractLengthMonths} loads` : '-'}`}
            </Typography>
            {hasEquipmentTypeForRateGen && !flatVolumePerMonth && (
              <Tooltip title='Rate generated using 10 Monthly'>
                <Box display='flex' alignItems='center'>
                  <InfoIcon size='medium' />
                </Box>
              </Tooltip>
            )}
          </Box>
          <ControlledHiddenInput name='volume.dynamicVolumePerMonth' control={control} />
        </>
      )}
      {volumeType === IVolumeTypes.dynamicVolume && (
        <>
          <Grid direction='row' container columnSpacing={2} rowSpacing={3}>
            {dynamicVolumePerMonth.map(({ date }, index) => (
              <Grid xs={12} md={2} key={date} item>
                <Controller
                  control={control}
                  name={`volume.dynamicVolumePerMonth[${index}].volume`}
                  render={({ onChange, value }) => (
                    <NumericFormat
                      label={formatMonthYear(date)}
                      error={!!getErrorMessage(index)}
                      helperText={getErrorMessage(index)}
                      dataTest={`volume-per-month-input-${index}`}
                      value={value}
                      decimalScale={0}
                      allowNegative={false}
                      onValueChange={({ floatValue }) => {
                        onChange(floatValue ?? null)
                        trigger('volume.dynamicVolumePerMonth')
                      }}
                    />
                  )}
                />
                <ControlledHiddenInput
                  name={`volume.dynamicVolumePerMonth[${index}].date`}
                  control={control}
                />
              </Grid>
            ))}
          </Grid>
          <Collapse in={Boolean(dynamicVolumePerMonthError?.message)}>
            <FormHelperText data-test='dynamic-volume-errors' hasError>
              {dynamicVolumePerMonthError?.message}
            </FormHelperText>
          </Collapse>
          <Typography variant='subtitle1' color={brandColors.coolGray5}>
            {`Total for ${formattedCurrentContractDuration} : ${totalDynamicLoads ? `${totalDynamicLoads} loads` : '-'}`}
          </Typography>
        </>
      )}
    </Stack>
  )
}
