import React, { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useAnalytics } from '#hooks'
import { DEFAULT_FLAT_VOLUME_PER_MONTH } from '#routes/contract-rates-tool/ContractRatesToolPage/forms/ContractLaneInformationForm/constants'
import { getDateVolumeRange } from '#routes/contract-rates-tool/ContractRatesToolPage/forms/ContractLaneInformationForm/utils'
import { IVolumeTypes } from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import {
  IRfpLaneInfoSchema,
  RfpLaneInfoSchema,
} from '#routes/contract-rfp/ContractRFPDetailPage/schema'
import { getLaneInfoInput } from '#routes/contract-rfp/ContractRFPDetailPage/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import { Button, makeStyles, Typography } from 'dpl/core'
import { LocationPinIcon } from 'dpl/icons'
import moment from 'moment'
import { IRfpLaneFragment } from '../../../LaneList/graphql/RfpLane'
import { LaneForm } from '../LaneForm'
import { useUpdateRfpLaneMutation } from '../LaneForm/graphql/UpdateRfpLane'

export interface ILaneInformationProps {
  lane: IRfpLaneFragment
  contractRfpVersionId: string
  contractStartAt: string
  contractEndAt: string
  onClose: () => void
}

const useStyles = makeStyles(theme => ({
  centerElements: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    flexWrap: 'wrap',
  },
  root: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  error: {
    margin: theme.spacing(2, 0),
  },
  buttons: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))

const FORM_DEFAULT_VALUES = {
  stops: [
    {
      location: null,
      type: 'pickup',
    },
    {
      location: null,
      type: 'delivery',
    },
  ],
}

export function LaneInformation({
  contractEndAt,
  contractRfpVersionId,
  contractStartAt,
  lane,
  onClose,
}: ILaneInformationProps) {
  const classes = useStyles()
  const { trackEvent } = useAnalytics()
  const [apiError, setApiError] = useState('')
  const [updateLane] = useUpdateRfpLaneMutation()
  const defaultDropType = [lane.isPickupDropTrailer, lane.isDeliveryDropTrailer]
    .map(isDrop => (isDrop ? 'drop' : 'live'))
    .join('_')
  const contractStartDate = moment(contractStartAt).format('YYYY-MM')
  const contractEndDate = moment(contractEndAt).format('YYYY-MM')
  const dynamicVolumePerMonth = useMemo(() => {
    const defaultVolumePerMonth = lane.volumeAmounts
      ?.filter(({ period }) => Boolean(period))
      ?.map(amount => ({
        date: (amount.period && moment(amount.period).format('YYYY-MM')) || '',
        volume: amount.volume ?? 0,
      }))

    if (!defaultVolumePerMonth || defaultVolumePerMonth?.length === 0) {
      return getDateVolumeRange({
        startDate: contractStartDate,
        endDate: contractEndDate,
        volume: DEFAULT_FLAT_VOLUME_PER_MONTH,
      })
    }

    return defaultVolumePerMonth
  }, [lane.volumeAmounts, contractStartDate, contractEndDate])

  const methods = useForm<IRfpLaneInfoSchema>({
    resolver: yupResolver(RfpLaneInfoSchema),
    defaultValues: {
      stops:
        lane?.stops.map(({ city, postalCode, stateCode, stopType }) => ({
          location: {
            city,
            postalCode,
            stateCode,
          },
          type: stopType,
        })) || FORM_DEFAULT_VALUES.stops,
      customMiles: lane.customMiles,
      volume: {
        volumeType: lane.isVolumeDynamic ? IVolumeTypes.dynamicVolume : IVolumeTypes.flatVolume,
        flatVolumePerMonth: lane.volumeFlatAmount,
        dynamicVolumePerMonth,
        volumeFrequency: lane.volumeFrequency ?? 'daily',
      },
      equipment: lane.equipment.key ?? 'dry_van',
      dropType: defaultDropType,
      calculatedMiles: lane.calculatedMiles,
    },
    mode: 'onChange',
    shouldUnregister: false, // Need to maintain input state even when getting unmounted as stops are dragged and dropped
  })

  const { handleSubmit } = methods

  const handleFormSubmit = handleSubmit(async data => {
    const input = {
      id: lane.id,
      externalId: lane.externalId,
      contractRfpVersionId,
      ...getLaneInfoInput(data),
    }
    const { data: laneData } = await updateLane({
      variables: {
        input,
      },
      onError: apolloError => {
        setApiError(apolloError.message)
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          apolloError,
          item: lane,
          variables: input,
        })
      },
      refetchQueries: ['CurrentVersionLanes', 'EditLane'],
    })
    const { upsertContractRfpVersionLane } = laneData || {}
    const { errors } = upsertContractRfpVersionLane || {}

    if (errors?.length) {
      setApiError(errors[0].message)

      trackEvent('Contract RFPs', 'UPDATE_LANE_INFO_ERROR', {
        errors,
        item: lane,
        variables: input,
      })

      return
    }

    trackEvent('Contract RFPs', 'UPDATE_LANE_INFO_SUCCESS', {
      item: lane,
      variables: input,
    })

    onClose()
  })

  return (
    <FormProvider {...methods}>
      {apiError && (
        <InlineMessage
          className={classes.error}
          type={INLINE_MESSAGE_VARIANTS.ALERT}
          message={apiError}
        />
      )}
      <form onSubmit={handleFormSubmit}>
        <div className={classes.root}>
          <Typography variant='overline' color='textSecondary' className={classes.centerElements}>
            <LocationPinIcon /> Lane Information
          </Typography>
          <LaneForm isEditMode contractStartAt={contractStartAt} contractEndAt={contractEndAt} />
          <div className={classes.buttons}>
            <Button type='submit'>Save Lane Information</Button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
