import { MAX_COST_PERCENTILE } from '#components/RatesEstimatedCarrierRateAccordion/constants'
import { IAdjustmentRuleLineItemKeyEnum } from '#components/RatesEstimatedStrategyAccordion/components/AdjustmentRulesSection/types'
import { IAdjustmentRuleLineItem } from '#components/RatesEstimatedStrategyAccordion/types'
import { ICostModel } from '#types/costModel'
import { currencyFormatter } from '#utils/currencyFormatter'
import { DISPLAY } from 'dpl/constants'
import SettingsIcon from 'dpl/icons/build/SettingsIcon'
import { getPercentileAmount } from '../getPercentileAmount'

const MAX_COST_LINE_ITEM: IAdjustmentRuleLineItem = {
  key: IAdjustmentRuleLineItemKeyEnum.maxPercentileLimit,
  Icon: SettingsIcon,
  label: 'Max Cost Limit of 90th reached',
  amount: DISPLAY.empty,
}

interface IGetMaxCostLimitLineItemProps {
  costModel: Nullable<ICostModel>
  isAboveMaxCostLimit: boolean
}

/**
 * Returns the max cost limit line item if the total cost exceeds the max cost limit.
 * If the max cost limit is not exceeded, it returns null.
 *
 * @returns {IAdjustmentRuleLineItem | null} The max cost limit line item
 *
 * @example getMaxCostLimitLineItem({ costModel: { cost90: 900 }, isAboveMaxCostLimit: true }) // returns { key: 'maxPercentileLimit', Icon: SettingsIcon, label: 'Max Cost Limit of 90th reached', amount: '$900.00' }
 * @example getMaxCostLimitLineItem({ costModel: { cost90: 900 }, isAboveMaxCostLimit: false }) // returns null
 */
export const getMaxCostLimitLineItem = ({
  costModel,
  isAboveMaxCostLimit,
}: IGetMaxCostLimitLineItemProps): Nullable<IAdjustmentRuleLineItem> => {
  const maxCost = getPercentileAmount({ costModel, percentile: MAX_COST_PERCENTILE })

  return isAboveMaxCostLimit
    ? {
        ...MAX_COST_LINE_ITEM,
        amount: currencyFormatter(maxCost),
      }
    : null
}
