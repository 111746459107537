import { IRuleTypeEnum } from '#types/rates'
import CostAdditiveNegativeIcon from 'dpl/icons/build/CostAdditiveNegativeIcon'
import CostAdditivePositiveIcon from 'dpl/icons/build/CostAdditivePositiveIcon'
import CostBaseIcon from 'dpl/icons/build/CostBaseIcon'
import SettingsIcon from 'dpl/icons/build/SettingsIcon'
import { TIconComponent } from 'dpl/icons/types'

interface IGetAdjustmentRuleIconProps {
  adjustmentValue: Nullable<number>
  ruleAction: Nullable<string>
}

/**
 * Returns the icon for an adjustment rule based on the adjustment value and rule action.
 *
 * @returns {TIconComponent} The icon for the adjustment rule
 *
 * @example getAdjustmentRuleIcon({ adjustmentValue: 60, ruleAction: 'baseline_adjustment' }) // CostBaseIcon
 * @example getAdjustmentRuleIcon({ adjustmentValue: 0.02, ruleAction: 'additive_adjustment' }) // CostAdditivePositiveIcon
 * @example getAdjustmentRuleIcon({ adjustmentValue: -0.03, ruleAction: 'additive_adjustment' }) // CostAdditiveNegativeIcon
 */
export const getAdjustmentRuleIcon = ({
  adjustmentValue,
  ruleAction,
}: IGetAdjustmentRuleIconProps): TIconComponent => {
  if (ruleAction === IRuleTypeEnum.base) {
    return CostBaseIcon
  }

  if (ruleAction === IRuleTypeEnum.additive) {
    if (adjustmentValue && adjustmentValue < 0) {
      return CostAdditiveNegativeIcon
    }
    return CostAdditivePositiveIcon
  }

  return SettingsIcon
}
