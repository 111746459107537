import React from 'react'
import { ListItemIcon, MenuItem } from '../../../core'
import { COUNTRY_FLAGS } from '../constants'
import { ICountryOption, TSelectCountryChangeHandler, IGeocodingAllowedCountryEnum } from '../types'

export interface ISelectCountryOptionProps extends ICountryOption {
  onChange: TSelectCountryChangeHandler
  selectedCode: IGeocodingAllowedCountryEnum
}

export function SelectCountryOption({
  code,
  label,
  onChange,
  selectedCode,
}: ISelectCountryOptionProps) {
  const Flag = COUNTRY_FLAGS[code]

  return (
    <MenuItem key={code} selected={code === selectedCode} onClick={() => onChange(code)}>
      <ListItemIcon>
        <Flag height={12} />
      </ListItemIcon>
      {label}
    </MenuItem>
  )
}

export default SelectCountryOption
