import React, { useState } from 'react'
import { ConfirmationModal, IconButton, IFormModalProps } from 'dpl'
import SidePanel from 'dpl/components/SidePanel'
import { makeStyles, Typography } from 'dpl/core'
import { LeftArrowIcon } from 'dpl/icons'
import { LaneInformation } from './components'
import { useEditLaneQuery } from './graphql/EditLane'

export interface IEditLaneInformationSideNavProps extends Pick<IFormModalProps, 'onClose'> {
  externalId: string
  contractRfpVersionId: string
  contractRfpId: string
}

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

export function EditLaneInformationSideNav({
  contractRfpId,
  contractRfpVersionId,
  externalId,
  onClose,
}: IEditLaneInformationSideNavProps) {
  const classes = useStyles()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { data } = useEditLaneQuery({
    variables: {
      externalId,
      contractRfpVersionId,
      contractRfpId,
    },
  })
  const { contractRfpVersionLanes, contractRfpVersions } = data || {}
  const { nodes = [] } = contractRfpVersionLanes || {}
  const [lane] = nodes || []
  const { nodes: contractRfpVersionsNodes = [] } = contractRfpVersions || {}
  const [contractRfpVersion] = contractRfpVersionsNodes || []
  const { contractRfp } = contractRfpVersion || {}
  const { endAt, startAt } = contractRfp || {}

  if (!lane) return null

  return (
    <SidePanel
      onClose={() => setShowConfirmationModal(true)}
      header={
        <div className={classes.header}>
          <IconButton
            Icon={LeftArrowIcon}
            color='secondary'
            onClick={() => setShowConfirmationModal(true)}
          />
          <div>
            <Typography variant='h4'>Edit Lane Information</Typography>
            <Typography variant='subtitle2' color='textSecondary'>
              #{externalId}
            </Typography>
          </div>
        </div>
      }
      anchor='right'>
      <LaneInformation
        contractRfpVersionId={contractRfpVersionId}
        contractStartAt={startAt || ''}
        contractEndAt={endAt || ''}
        lane={lane}
        onClose={onClose}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => setShowConfirmationModal(false)}
          open
          includeCloseIconButton={false}
          title='Discard Unsaved Changes?'
          actions={[
            {
              label: 'Cancel',
              ButtonProps: {
                variant: 'text',
              },
              action: () => setShowConfirmationModal(false),
            },
            {
              label: 'Discard',
              ButtonProps: {
                color: 'error',
              },
              action: () => {
                setShowConfirmationModal(false)
                onClose()
              },
            },
          ]}
        />
      )}
    </SidePanel>
  )
}
