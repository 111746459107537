/* eslint-disable */

/**
 * Do not modify it directly.
 * @see https://devdocs.transfix.io/graphql
 *
 * If you need to update the definitions in this file,
 * Run `npm run generate:graphql:mp` or `npm run generate:graphql:mp:local`
 * in the terminal
 */

export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: { input: any; output: any }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string }
}

/** Autogenerated input type of ActivateOrganizationShipper */
export type IActivateOrganizationShipperInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

/** Autogenerated return type of ActivateOrganizationShipper. */
export type IActivateOrganizationShipperPayload = {
  __typename?: 'ActivateOrganizationShipperPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationShipper?: Maybe<IOrganizationShipper>
}

export type IActivity = {
  __typename?: 'Activity'
  carrier?: Maybe<ICarrier>
  eventJson: Scalars['String']['output']
  eventType: IActivityEventTypesEnum
  id: Scalars['ID']['output']
  occurredAt: Scalars['ISO8601DateTime']['output']
  performedByOrganizationUser: IOrganizationUser
}

/** The connection type for Activity. */
export type IActivityConnection = {
  __typename?: 'ActivityConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IActivityEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IActivity>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IActivityEdge = {
  __typename?: 'ActivityEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IActivity>
}

export enum IActivityEventTypesEnum {
  /** AddedCarrier */
  added_carrier = 'added_carrier',
  /** CreatedLane */
  created_lane = 'created_lane',
  /** DeactivatedCarrier */
  deactivated_carrier = 'deactivated_carrier',
  /** ReorderedCarriers */
  reordered_carriers = 'reordered_carriers',
  /** UpdatedCarrier */
  updated_carrier = 'updated_carrier',
  /** UpdatedLane */
  updated_lane = 'updated_lane',
}

export type IAdjustmentRuleVersion = {
  __typename?: 'AdjustmentRuleVersion'
  adjustmentType?: Maybe<IAdjustmentTypeEnum>
  adjustmentValue?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser?: Maybe<IOrganizationUser>
  deprecatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  equipment?: Maybe<IEquipment>
  factors?: Maybe<IFactors>
  id: Scalars['ID']['output']
  organizationAdjustmentRule: IOrganizationAdjustmentRule
  organizationShipper?: Maybe<IOrganizationShipper>
  reason?: Maybe<Scalars['String']['output']>
  ruleAction: Scalars['String']['output']
  spotRatesOrganizationAdjustmentRuleId: Scalars['ID']['output']
  startAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/** The connection type for AdjustmentRuleVersion. */
export type IAdjustmentRuleVersionConnection = {
  __typename?: 'AdjustmentRuleVersionConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IAdjustmentRuleVersionEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IAdjustmentRuleVersion>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IAdjustmentRuleVersionEdge = {
  __typename?: 'AdjustmentRuleVersionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IAdjustmentRuleVersion>
}

export enum IAdjustmentTypeEnum {
  cost = 'cost',
  margin = 'margin',
}

export type IAttachedFile = {
  __typename?: 'AttachedFile'
  contentType: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  filename: Scalars['String']['output']
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export type ICarrier = {
  __typename?: 'Carrier'
  dbaName?: Maybe<Scalars['String']['output']>
  dotNumber: Scalars['Int']['output']
  id: Scalars['ID']['output']
  legalName?: Maybe<Scalars['String']['output']>
  mcNumber?: Maybe<Scalars['Int']['output']>
}

export type ICarrierCapacityInput = {
  fridayCapacity: Scalars['Boolean']['input']
  mondayCapacity: Scalars['Boolean']['input']
  saturdayCapacity: Scalars['Boolean']['input']
  sundayCapacity: Scalars['Boolean']['input']
  thursdayCapacity: Scalars['Boolean']['input']
  tuesdayCapacity: Scalars['Boolean']['input']
  wednesdayCapacity: Scalars['Boolean']['input']
}

export enum ICarrierRateRateTypes {
  /** All in */
  all_in = 'all_in',
  /** Linehaul */
  linehaul = 'linehaul',
}

export type ICarrierUser = {
  __typename?: 'CarrierUser'
  carrier: ICarrier
  consumerUser: IConsumerUser
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByCarrierUser?: Maybe<ICarrierUser>
  deprecatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  deprecatedByCarrierUser?: Maybe<ICarrierUser>
  id: Scalars['ID']['output']
  role: ICarrierUserRoles
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedByCarrierUser?: Maybe<ICarrierUser>
  userType: ICarrierUserUserTypes
}

/** The connection type for CarrierUser. */
export type ICarrierUserConnection = {
  __typename?: 'CarrierUserConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ICarrierUserEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ICarrierUser>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ICarrierUserEdge = {
  __typename?: 'CarrierUserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<ICarrierUser>
}

export enum ICarrierUserRoles {
  admin = 'admin',
  employee = 'employee',
}

export enum ICarrierUserUserTypes {
  dispatcher = 'dispatcher',
  driver = 'driver',
  owner_operator = 'owner_operator',
}

export type ICityState = {
  __typename?: 'CityState'
  city: Scalars['String']['output']
  countryCode: ICountries
  id: Scalars['ID']['output']
  latitude?: Maybe<Scalars['String']['output']>
  longitude?: Maybe<Scalars['String']['output']>
  stateCode: ICountrySubdivisionCodes
}

export type ICityStateInput = {
  city: Scalars['String']['input']
  countryCode: ICountries
  stateCode: ICountrySubdivisionCodes
}

export type IConsumerUser = {
  __typename?: 'ConsumerUser'
  id: Scalars['ID']['output']
  user: IUser
}

export type IContractRfp = {
  __typename?: 'ContractRFP'
  /** @deprecated Use `userAssignments` instead */
  assignedToOrganizationUser?: Maybe<IOrganizationUser>
  awarded?: Maybe<Scalars['Boolean']['output']>
  closedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser: IOrganizationUser
  currentVersion: IContractRfpVersion
  displayId: Scalars['Int']['output']
  endAt: Scalars['ISO8601Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nextKanbanItemId?: Maybe<Scalars['ID']['output']>
  organization: IOrganization
  organizationShipper: IOrganizationShipper
  startAt: Scalars['ISO8601Date']['output']
  state: IContractRfpStates
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedByOrganizationUser: IOrganizationUser
  uploadedFiles: Array<IAttachedFile>
  userAssignments: Array<IContractRfpUserAssignment>
  versions: Array<IContractRfpVersion>
  webLinks?: Maybe<Array<IWebLink>>
}

export type IContractRfpUserAssignmentsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
}

/** The connection type for ContractRFP. */
export type IContractRfpConnection = {
  __typename?: 'ContractRFPConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IContractRfpEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IContractRfp>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IContractRfpEdge = {
  __typename?: 'ContractRFPEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IContractRfp>
}

export enum IContractRfpLaneVolumeFrequencies {
  daily = 'daily',
  monthly = 'monthly',
  quarterly = 'quarterly',
  weekly = 'weekly',
  yearly = 'yearly',
}

export enum IContractRfpSortBy {
  end_at = 'end_at',
  kanban_order = 'kanban_order',
  name = 'name',
  start_at = 'start_at',
  updated_at = 'updated_at',
}

export enum IContractRfpStateTransitions {
  archive = 'archive',
  close = 'close',
  plan = 'plan',
  put_in_progress = 'put_in_progress',
  submit = 'submit',
}

export enum IContractRfpStates {
  archived = 'archived',
  closed = 'closed',
  in_progress = 'in_progress',
  planning = 'planning',
  submitted = 'submitted',
}

export type IContractRfpUserAssignment = {
  __typename?: 'ContractRFPUserAssignment'
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser: IOrganizationUser
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  deletedByOrganizationUser?: Maybe<IOrganizationUser>
  id: Scalars['ID']['output']
  organizationUser: IOrganizationUser
  role: IContractRfpUserAssignmentRoles
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedByOrganizationUser: IOrganizationUser
}

export type IContractRfpUserAssignmentInput = {
  organizationUserId: Scalars['ID']['input']
  role: IContractRfpUserAssignmentRoles
}

export enum IContractRfpUserAssignmentRoles {
  additional_team_member = 'additional_team_member',
  assignee = 'assignee',
}

export type IContractRfpVersion = {
  __typename?: 'ContractRFPVersion'
  contractRfp: IContractRfp
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser: IOrganizationUser
  dueAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  lanes: IContractRfpVersionLaneConnection
  number: Scalars['Int']['output']
  rategenMetadataSummary: IVersionRategenSummary
  rfpFile?: Maybe<IAttachedFile>
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedByOrganizationUser: IOrganizationUser
}

export type IContractRfpVersionLanesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for ContractRFPVersion. */
export type IContractRfpVersionConnection = {
  __typename?: 'ContractRFPVersionConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IContractRfpVersionEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IContractRfpVersion>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IContractRfpVersionEdge = {
  __typename?: 'ContractRFPVersionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IContractRfpVersion>
}

export type IContractRfpVersionInsights = {
  __typename?: 'ContractRFPVersionInsights'
  totalExpectedRevenue?: Maybe<Scalars['Float']['output']>
  totalLanes: Scalars['Int']['output']
  totalVolume?: Maybe<Scalars['Int']['output']>
  uniqueLocations: Array<IUniqueLocations>
}

export type IContractRfpVersionLane = {
  __typename?: 'ContractRFPVersionLane'
  calculatedMiles?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser: IOrganizationUser
  customMiles?: Maybe<Scalars['Float']['output']>
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  deliveryLocation: IPostalCode
  equipment: IEquipment
  externalId: Scalars['ID']['output']
  historicalRates: Array<IContractRfpVersionLaneRate>
  id: Scalars['ID']['output']
  isDeliveryDropTrailer: Scalars['Boolean']['output']
  isPickupDropTrailer: Scalars['Boolean']['output']
  isTeam?: Maybe<Scalars['Boolean']['output']>
  isVolumeDynamic?: Maybe<Scalars['Boolean']['output']>
  laneRategenMetadata?: Maybe<ILaneRategenMetadata>
  pickupLocation: IPostalCode
  rate?: Maybe<IContractRfpVersionLaneRate>
  reeferTempMax?: Maybe<Scalars['Int']['output']>
  reeferTempMin?: Maybe<Scalars['Int']['output']>
  stops: Array<IContractRfpVersionLaneStop>
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedByOrganizationUser: IOrganizationUser
  /** @deprecated Use volume_amounts or volume_flat_amount instead */
  volume?: Maybe<Scalars['Float']['output']>
  volumeAmounts?: Maybe<Array<IContractRfpVolumeAmount>>
  volumeFlatAmount?: Maybe<Scalars['Float']['output']>
  volumeFrequency?: Maybe<IContractRfpLaneVolumeFrequencies>
  volumeTotalAmount?: Maybe<Scalars['Float']['output']>
}

/** The connection type for ContractRFPVersionLane. */
export type IContractRfpVersionLaneConnection = {
  __typename?: 'ContractRFPVersionLaneConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IContractRfpVersionLaneEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IContractRfpVersionLane>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IContractRfpVersionLaneEdge = {
  __typename?: 'ContractRFPVersionLaneEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IContractRfpVersionLane>
}

export type IContractRfpVersionLaneRate = {
  __typename?: 'ContractRFPVersionLaneRate'
  carrierRate?: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser: IOrganizationUser
  deprecatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  fuelAmount?: Maybe<Scalars['Float']['output']>
  fuelRatePerMile?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  marginAmount?: Maybe<Scalars['Float']['output']>
  marginPercentage?: Maybe<Scalars['Float']['output']>
  rateType: IContractRfpVersionLaneRateRateTypes
  shipperRate?: Maybe<Scalars['Float']['output']>
  source: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedByOrganizationUser: IOrganizationUser
}

export type IContractRfpVersionLaneRateInput = {
  carrierRate?: InputMaybe<Scalars['Float']['input']>
  fuelRatePerMile?: InputMaybe<Scalars['Float']['input']>
  marginPercentage?: InputMaybe<Scalars['Float']['input']>
  rateType: IContractRfpVersionLaneRateRateTypes
  shipperRate?: InputMaybe<Scalars['Float']['input']>
}

export enum IContractRfpVersionLaneRateRateTypes {
  all_in = 'all_in',
  linehaul = 'linehaul',
}

export enum IContractRfpVersionLaneSortBy {
  import_order = 'import_order',
  rate = 'rate',
  volume = 'volume',
  volume_total_amount = 'volume_total_amount',
}

export type IContractRfpVersionLaneStop = {
  __typename?: 'ContractRFPVersionLaneStop'
  city: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  isDropTrailer: Scalars['Boolean']['output']
  postalCode: Scalars['String']['output']
  stateCode: Scalars['String']['output']
  stopSequence: Scalars['Int']['output']
  stopType: IStopTypes
}

export type IContractRfpVersionLaneStopInput = {
  city?: InputMaybe<Scalars['String']['input']>
  drop?: InputMaybe<Scalars['Boolean']['input']>
  isoCountryCode?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  stopSequence: Scalars['Int']['input']
  stopType: IStopTypes
}

export type IContractRfpVolumeAmount = {
  __typename?: 'ContractRFPVolumeAmount'
  period?: Maybe<Scalars['String']['output']>
  volume: Scalars['Float']['output']
}

export type IContractRfpVolumeAmountInput = {
  period?: InputMaybe<Scalars['String']['input']>
  volume: Scalars['Float']['input']
}

export type IContractRatesCost = {
  __typename?: 'ContractRatesCost'
  marketSimAvg: IMarketSim
}

export type IContractRatesResponse = {
  __typename?: 'ContractRatesResponse'
  equipmentKey: Scalars['String']['output']
  isFlatVolume: Scalars['Boolean']['output']
  isTeam: Scalars['Boolean']['output']
  marketCosts: IContractRatesCost
  miles: Scalars['Float']['output']
  milesProvider: Scalars['String']['output']
  reeferTempMax?: Maybe<Scalars['Float']['output']>
  reeferTempMin?: Maybe<Scalars['Float']['output']>
  stops: Array<IContractRatesStop>
}

export type IContractRatesStop = {
  __typename?: 'ContractRatesStop'
  city?: Maybe<Scalars['String']['output']>
  isDropTrailer: Scalars['Boolean']['output']
  postalCode?: Maybe<Scalars['String']['output']>
  relativeTypeSequence: Scalars['Int']['output']
  stateCode?: Maybe<Scalars['String']['output']>
  stopLatitude?: Maybe<Scalars['Float']['output']>
  stopLongitude?: Maybe<Scalars['Float']['output']>
  stopSequence: Scalars['Int']['output']
  stopType: Scalars['String']['output']
}

export type IContractRatesStopInput = {
  address?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  isDropTrailer?: InputMaybe<Scalars['Boolean']['input']>
  isoCountryCode?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  stopSequence: Scalars['Int']['input']
  stopType: Scalars['String']['input']
}

export type ICoordinatesInput = {
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

export type ICostModelRange = {
  __typename?: 'CostModelRange'
  cost10: Scalars['Float']['output']
  cost15: Scalars['Float']['output']
  cost20: Scalars['Float']['output']
  cost25: Scalars['Float']['output']
  cost30: Scalars['Float']['output']
  cost35: Scalars['Float']['output']
  cost40: Scalars['Float']['output']
  cost45: Scalars['Float']['output']
  cost50: Scalars['Float']['output']
  cost55: Scalars['Float']['output']
  cost60: Scalars['Float']['output']
  cost65: Scalars['Float']['output']
  cost70: Scalars['Float']['output']
  cost75: Scalars['Float']['output']
  cost80: Scalars['Float']['output']
  cost85: Scalars['Float']['output']
  cost90: Scalars['Float']['output']
}

export enum ICountries {
  /** Canada */
  CA = 'CA',
  /** United States */
  US = 'US',
}

export enum ICountrySubdivisionCodes {
  AB = 'AB',
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  BC = 'BC',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MB = 'MB',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NB = 'NB',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NL = 'NL',
  NM = 'NM',
  NS = 'NS',
  NT = 'NT',
  NU = 'NU',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  ON = 'ON',
  OR = 'OR',
  PA = 'PA',
  PE = 'PE',
  QC = 'QC',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  SK = 'SK',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  YT = 'YT',
}

/** Autogenerated input type of CreateOrganizationAdjustmentRule */
export type ICreateOrganizationAdjustmentRuleInput = {
  adjustmentType: IAdjustmentTypeEnum
  adjustmentValue?: InputMaybe<Scalars['Float']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  endAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  factors: IFactorsInput
  reason?: InputMaybe<Scalars['String']['input']>
  ruleAction: Scalars['String']['input']
  startAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

/** Autogenerated return type of CreateOrganizationAdjustmentRule. */
export type ICreateOrganizationAdjustmentRulePayload = {
  __typename?: 'CreateOrganizationAdjustmentRulePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationAdjustmentRule?: Maybe<IOrganizationAdjustmentRule>
}

/** Autogenerated input type of CreateOrganizationShipper */
export type ICreateOrganizationShipperInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  externalId: Scalars['String']['input']
  name: Scalars['String']['input']
}

/** Autogenerated return type of CreateOrganizationShipper. */
export type ICreateOrganizationShipperPayload = {
  __typename?: 'CreateOrganizationShipperPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationShipper?: Maybe<IOrganizationShipper>
}

/** Autogenerated input type of CreateQuote */
export type ICreateQuoteInput = {
  assignmentLeadTimeMinutes?: InputMaybe<Scalars['Int']['input']>
  bidAmount: Scalars['Float']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  communicationMethod?: InputMaybe<Scalars['String']['input']>
  creationLeadTimeMinutes?: InputMaybe<Scalars['Int']['input']>
  customerReference?: InputMaybe<Scalars['String']['input']>
  equipmentKey: Scalars['String']['input']
  estimatedCarrierRate: Scalars['Float']['input']
  isTeam?: InputMaybe<Scalars['Boolean']['input']>
  margin: Scalars['Float']['input']
  organizationShipperId?: InputMaybe<Scalars['ID']['input']>
  palletCount?: InputMaybe<Scalars['Float']['input']>
  rateType: Scalars['String']['input']
  reeferTempMax?: InputMaybe<Scalars['Float']['input']>
  reeferTempMin?: InputMaybe<Scalars['Float']['input']>
  shipmentId?: InputMaybe<Scalars['String']['input']>
  shipmentNumber?: InputMaybe<Scalars['String']['input']>
  source: Scalars['String']['input']
  status: Scalars['String']['input']
  stops: Array<IStopInput>
  submittedDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  weight?: InputMaybe<Scalars['Float']['input']>
}

/** Autogenerated return type of CreateQuote. */
export type ICreateQuotePayload = {
  __typename?: 'CreateQuotePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationQuote?: Maybe<IOrganizationQuote>
}

/** Autogenerated input type of CreateRoutingGuideCarrierRate */
export type ICreateRoutingGuideCarrierRateInput = {
  carrierCapacities: ICarrierCapacityInput
  carrierId: Scalars['ID']['input']
  /** pass this field to assign an existing carrier user as carrier contact */
  carrierUserId?: InputMaybe<Scalars['ID']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** pass this field only if a new carrier user is to be created and assigned as carrier contact */
  newCarrierUserEmail?: InputMaybe<Scalars['String']['input']>
  rate: Scalars['Float']['input']
  rateType: ICarrierRateRateTypes
  routingGuideLaneId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateRoutingGuideCarrierRate. */
export type ICreateRoutingGuideCarrierRatePayload = {
  __typename?: 'CreateRoutingGuideCarrierRatePayload'
  carrierRate?: Maybe<IRoutingGuideCarrierRate>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
}

/** Autogenerated input type of CreateRoutingGuideLane */
export type ICreateRoutingGuideLaneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  deliveryLocation: ICityStateInput
  equipmentKey: IEquipmentKeys
  /** An organization user that has the permission to create routing guide lanes */
  laneManagerId: Scalars['ID']['input']
  organizationShipperId: Scalars['ID']['input']
  pickupLocation: ICityStateInput
}

/** Autogenerated return type of CreateRoutingGuideLane. */
export type ICreateRoutingGuideLanePayload = {
  __typename?: 'CreateRoutingGuideLanePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  routingGuideLane?: Maybe<IRoutingGuideLane>
}

/** Autogenerated input type of CreateSpotRatesSettingVersion */
export type ICreateSpotRatesSettingVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  defaultCostPercentile?: InputMaybe<Scalars['BigInt']['input']>
  defaultMarginPremium?: InputMaybe<Scalars['Float']['input']>
  maxMarginPremium?: InputMaybe<Scalars['Float']['input']>
}

/** Autogenerated return type of CreateSpotRatesSettingVersion. */
export type ICreateSpotRatesSettingVersionPayload = {
  __typename?: 'CreateSpotRatesSettingVersionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  settingVersion?: Maybe<ISettingVersion>
}

export type ICustomCostModelResponse = {
  __typename?: 'CustomCostModelResponse'
  cost10?: Maybe<Scalars['Float']['output']>
  cost15?: Maybe<Scalars['Float']['output']>
  cost20?: Maybe<Scalars['Float']['output']>
  cost25?: Maybe<Scalars['Float']['output']>
  cost30?: Maybe<Scalars['Float']['output']>
  cost35?: Maybe<Scalars['Float']['output']>
  cost40?: Maybe<Scalars['Float']['output']>
  cost45?: Maybe<Scalars['Float']['output']>
  cost50?: Maybe<Scalars['Float']['output']>
  cost55?: Maybe<Scalars['Float']['output']>
  cost60?: Maybe<Scalars['Float']['output']>
  cost65?: Maybe<Scalars['Float']['output']>
  cost70?: Maybe<Scalars['Float']['output']>
  cost75?: Maybe<Scalars['Float']['output']>
  cost80?: Maybe<Scalars['Float']['output']>
  cost85?: Maybe<Scalars['Float']['output']>
  cost90?: Maybe<Scalars['Float']['output']>
  costMean?: Maybe<Scalars['Float']['output']>
  miles?: Maybe<Scalars['Float']['output']>
}

export type ICustomerFactor = {
  __typename?: 'CustomerFactor'
  organizationShipperId: Scalars['String']['output']
}

export type ICustomerFactorInput = {
  organizationShipperId: Scalars['String']['input']
}

/** Autogenerated input type of DeactivateOrganizationAdjustmentRule */
export type IDeactivateOrganizationAdjustmentRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  organizationAdjustmentRuleId: Scalars['ID']['input']
}

/** Autogenerated return type of DeactivateOrganizationAdjustmentRule. */
export type IDeactivateOrganizationAdjustmentRulePayload = {
  __typename?: 'DeactivateOrganizationAdjustmentRulePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationAdjustmentRule?: Maybe<IOrganizationAdjustmentRule>
}

/** Autogenerated input type of DeactivateOrganizationShipper */
export type IDeactivateOrganizationShipperInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

/** Autogenerated return type of DeactivateOrganizationShipper. */
export type IDeactivateOrganizationShipperPayload = {
  __typename?: 'DeactivateOrganizationShipperPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationShipper?: Maybe<IOrganizationShipper>
}

/** Autogenerated input type of DeactivateRoutingGuideCarrierRate */
export type IDeactivateRoutingGuideCarrierRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

/** Autogenerated return type of DeactivateRoutingGuideCarrierRate. */
export type IDeactivateRoutingGuideCarrierRatePayload = {
  __typename?: 'DeactivateRoutingGuideCarrierRatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  result?: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated input type of DeleteContractRFPVersionLane */
export type IDeleteContractRfpVersionLaneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteContractRFPVersionLane. */
export type IDeleteContractRfpVersionLanePayload = {
  __typename?: 'DeleteContractRFPVersionLanePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfpVersionLane?: Maybe<IContractRfpVersionLane>
  errors: Array<IError>
}

export type IDeliveryGeoFactor = {
  __typename?: 'DeliveryGeoFactor'
  geoType: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

export type IDeliveryGeoFactorInput = {
  geoType: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

/** DrivingDistance::RouteMeasurement */
export type IDrivingDistanceRouteMeasurement = {
  __typename?: 'DrivingDistanceRouteMeasurement'
  /** Total distance in meters to drive between all points  */
  meters?: Maybe<Scalars['Float']['output']>
  /** Total distance in miles to drive between all points */
  miles?: Maybe<Scalars['Float']['output']>
  /** Estimated time in seconds to drive between all points */
  seconds?: Maybe<Scalars['Int']['output']>
}

export enum IDropCombinations {
  drop_drop = 'drop_drop',
  drop_live = 'drop_live',
  live_drop = 'live_drop',
  live_live = 'live_live',
}

/** Autogenerated input type of DuplicateContractRFPVersionLanes */
export type IDuplicateContractRfpVersionLanesInput = {
  /** Indicates if rates should be auto generated. */
  autoGenerateRates?: InputMaybe<Scalars['Boolean']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  fromContractRfpVersionId: Scalars['ID']['input']
}

/** Autogenerated return type of DuplicateContractRFPVersionLanes. */
export type IDuplicateContractRfpVersionLanesPayload = {
  __typename?: 'DuplicateContractRFPVersionLanesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfpVersion?: Maybe<IContractRfpVersion>
  errors: Array<IError>
}

export type IEquipment = {
  __typename?: 'Equipment'
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  parent?: Maybe<IEquipment>
}

export type IEquipmentFactor = {
  __typename?: 'EquipmentFactor'
  equipmentKey: Scalars['String']['output']
  parentEquipmentId?: Maybe<Scalars['String']['output']>
}

export type IEquipmentFactorInput = {
  equipmentKey: Scalars['String']['input']
  parentEquipmentId?: InputMaybe<Scalars['String']['input']>
}

export enum IEquipmentKeys {
  dry_van = 'dry_van',
  open_deck = 'open_deck',
  reefer = 'reefer',
  specialized = 'specialized',
}

export type IError = {
  __typename?: 'Error'
  key: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type IFactors = {
  __typename?: 'Factors'
  customerFactor?: Maybe<ICustomerFactor>
  deliveryGeoFactor?: Maybe<IDeliveryGeoFactor>
  equipmentFactor?: Maybe<IEquipmentFactor>
  laneFactor?: Maybe<ILaneFactor>
  pickupGeoFactor?: Maybe<IPickupGeoFactor>
}

export type IFactorsInput = {
  customerFactor?: InputMaybe<ICustomerFactorInput>
  deliveryGeoFactor?: InputMaybe<IDeliveryGeoFactorInput>
  equipmentFactor?: InputMaybe<IEquipmentFactorInput>
  laneFactor?: InputMaybe<ILaneFactorInput>
  pickupGeoFactor?: InputMaybe<IPickupGeoFactorInput>
}

/** Autogenerated input type of FetchRates */
export type IFetchRatesInput = {
  assignmentLeadTimeMinutes?: InputMaybe<Scalars['Int']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  creationLeadTimeMinutes?: InputMaybe<Scalars['Int']['input']>
  customerId?: InputMaybe<Scalars['ID']['input']>
  equipmentKey: Scalars['String']['input']
  isFrozen?: InputMaybe<Scalars['Boolean']['input']>
  isTeam?: InputMaybe<Scalars['Boolean']['input']>
  palletCount?: InputMaybe<Scalars['Float']['input']>
  reeferTempMax?: InputMaybe<Scalars['Float']['input']>
  reeferTempMin?: InputMaybe<Scalars['Float']['input']>
  shipmentId?: InputMaybe<Scalars['String']['input']>
  stops: Array<IStopInput>
  weight?: InputMaybe<Scalars['Float']['input']>
}

/** Autogenerated return type of FetchRates. */
export type IFetchRatesPayload = {
  __typename?: 'FetchRatesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  ratesResponse?: Maybe<IRatesResponse>
}

/** Autogenerated input type of GenerateAutoRateForLane */
export type IGenerateAutoRateForLaneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  rfpVersionLaneId: Scalars['ID']['input']
}

/** Autogenerated return type of GenerateAutoRateForLane. */
export type IGenerateAutoRateForLanePayload = {
  __typename?: 'GenerateAutoRateForLanePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  rate?: Maybe<IContractRfpVersionLaneRate>
}

/** GeocoderHelper Response */
export type IGeocoding = {
  __typename?: 'Geocoding'
  address?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  coordinates: Array<Scalars['Float']['output']>
  country?: Maybe<Scalars['String']['output']>
  countryCode?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  postalCode?: Maybe<Scalars['String']['output']>
  resultType: IGeocodingResultTypeEnum
  shortString: Scalars['String']['output']
  state?: Maybe<Scalars['String']['output']>
  stateCode?: Maybe<Scalars['String']['output']>
  timezone?: Maybe<Scalars['String']['output']>
}

export enum IGeocodingAllowedCountryEnum {
  /** CA */
  CA = 'CA',
  /** MX */
  MX = 'MX',
  /** US */
  US = 'US',
}

export enum IGeocodingResultTypeEnum {
  /** Address */
  Address = 'Address',
  /** City */
  City = 'City',
  /** Country */
  Country = 'Country',
  /** Poi */
  POI = 'POI',
  /** Postalcode */
  PostalCode = 'PostalCode',
  /** State */
  State = 'State',
}

export type IHistoricalInsights = {
  __typename?: 'HistoricalInsights'
  averageBidAmount?: Maybe<Scalars['Float']['output']>
  averageEstimatedCarrierRate?: Maybe<Scalars['Float']['output']>
  averageEstimatedMargin?: Maybe<Scalars['Float']['output']>
  countQuotes: Scalars['Int']['output']
  deliveryPostalCode: Scalars['String']['output']
  equipment: IEquipment
  pickupPostalCode: Scalars['String']['output']
  quotedSince?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/** Autogenerated input type of ImportContractRFPVersionLanes */
export type IImportContractRfpVersionLanesInput = {
  /** Indicates if rates should be auto generated. */
  autoGenerateRates?: InputMaybe<Scalars['Boolean']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  contractRfpVersionId: Scalars['ID']['input']
  /** A data URL containing a Base64 encoded CSV. */
  file: Scalars['String']['input']
  filename: Scalars['String']['input']
  /** If false the lanes will just be validated, if true the lanes will also be saved */
  persist?: Scalars['Boolean']['input']
}

/** Autogenerated return type of ImportContractRFPVersionLanes. */
export type IImportContractRfpVersionLanesPayload = {
  __typename?: 'ImportContractRFPVersionLanesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  success: Scalars['Boolean']['output']
}

export type IImportContractRfpVersionLanesStatus = {
  __typename?: 'ImportContractRFPVersionLanesStatus'
  completed: Scalars['Boolean']['output']
  contractRfpVersion: IContractRfpVersion
  contractRfpVersionId: Scalars['ID']['output']
  errors: Array<Scalars['String']['output']>
  persist: Scalars['Boolean']['output']
  validations: Array<IImportContractRfpVersionLanesValidation>
}

export type IImportContractRfpVersionLanesValidation = {
  __typename?: 'ImportContractRFPVersionLanesValidation'
  column?: Maybe<Scalars['String']['output']>
  rowId: Scalars['String']['output']
  severity: IImportContractRfpVersionLanesValidationSeverities
  type: Scalars['String']['output']
}

export enum IImportContractRfpVersionLanesValidationSeverities {
  error = 'error',
  warning = 'warning',
}

export type ILaneFactor = {
  __typename?: 'LaneFactor'
  deliveryGeoType: Scalars['String']['output']
  deliveryValues: Array<Scalars['String']['output']>
  pickupGeoType: Scalars['String']['output']
  pickupValues: Array<Scalars['String']['output']>
}

export type ILaneFactorInput = {
  deliveryGeoType: Scalars['String']['input']
  deliveryValues: Array<Scalars['String']['input']>
  pickupGeoType: Scalars['String']['input']
  pickupValues: Array<Scalars['String']['input']>
}

export type ILaneRategenMetadata = {
  __typename?: 'LaneRategenMetadata'
  failureReasons?: Maybe<Array<Scalars['String']['output']>>
  id: Scalars['ID']['output']
  status: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type IMarketSim = {
  __typename?: 'MarketSim'
  monthlyCost?: Maybe<Array<IMonthlyVolumeCost>>
  volumeWtdAvgCost?: Maybe<ICostModelRange>
}

export type IMonthlyVolumeCost = {
  __typename?: 'MonthlyVolumeCost'
  costModelRange: ICostModelRange
  month: Scalars['String']['output']
  volume: Scalars['Int']['output']
}

export type IMonthlyVolumeInput = {
  month: Scalars['String']['input']
  volume: Scalars['Int']['input']
}

export type IMultisortInput = {
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<ISortOrder>
}

export type IMutation = {
  __typename?: 'Mutation'
  activateOrganizationShipper?: Maybe<IActivateOrganizationShipperPayload>
  createOrganizationAdjustmentRule?: Maybe<ICreateOrganizationAdjustmentRulePayload>
  createOrganizationShipper?: Maybe<ICreateOrganizationShipperPayload>
  createQuote?: Maybe<ICreateQuotePayload>
  createRoutingGuideCarrierRate?: Maybe<ICreateRoutingGuideCarrierRatePayload>
  createRoutingGuideLane?: Maybe<ICreateRoutingGuideLanePayload>
  createSpotRatesSettingVersion?: Maybe<ICreateSpotRatesSettingVersionPayload>
  deactivateOrganizationAdjustmentRule?: Maybe<IDeactivateOrganizationAdjustmentRulePayload>
  deactivateOrganizationShipper?: Maybe<IDeactivateOrganizationShipperPayload>
  deactivateRoutingGuideCarrierRate?: Maybe<IDeactivateRoutingGuideCarrierRatePayload>
  deleteContractRfpVersionLane?: Maybe<IDeleteContractRfpVersionLanePayload>
  duplicateContractRfpVersionLanes?: Maybe<IDuplicateContractRfpVersionLanesPayload>
  fetchRates?: Maybe<IFetchRatesPayload>
  generateAutoRateForLane?: Maybe<IGenerateAutoRateForLanePayload>
  importContractRfpVersionLanes?: Maybe<IImportContractRfpVersionLanesPayload>
  onboardCurrentUser?: Maybe<IOnboardCurrentUserPayload>
  refreshRfpAutoRates?: Maybe<IRefreshRfpAutoRatesPayload>
  removeFileFromContractRfp?: Maybe<IRemoveFileFromContractRfpPayload>
  restartContractRfpVersion?: Maybe<IRestartContractRfpVersionPayload>
  transitionContractRfpState?: Maybe<ITransitionContractRfpStatePayload>
  updateCarrierRateSequences?: Maybe<IUpdateCarrierRateSequencesPayload>
  updateContractRfpKanbanPosition?: Maybe<IUpdateContractRfpKanbanPositionPayload>
  updateCurrentUser?: Maybe<IUpdateCurrentUserPayload>
  updateOrganizationAdjustmentRule?: Maybe<IUpdateOrganizationAdjustmentRulePayload>
  updateOrganizationShipper?: Maybe<IUpdateOrganizationShipperPayload>
  updateQuote?: Maybe<IUpdateQuotePayload>
  updateRoutingGuideCarrierRate?: Maybe<IUpdateRoutingGuideCarrierRatePayload>
  updateRoutingGuideLaneManager?: Maybe<IUpdateRoutingGuideLaneManagerPayload>
  uploadFileToContractRfp?: Maybe<IUploadFileToContractRfpPayload>
  upsertContractRfp?: Maybe<IUpsertContractRfpPayload>
  upsertContractRfpVersion?: Maybe<IUpsertContractRfpVersionPayload>
  upsertContractRfpVersionLane?: Maybe<IUpsertContractRfpVersionLanePayload>
}

export type IMutationActivateOrganizationShipperArgs = {
  input: IActivateOrganizationShipperInput
}

export type IMutationCreateOrganizationAdjustmentRuleArgs = {
  input: ICreateOrganizationAdjustmentRuleInput
}

export type IMutationCreateOrganizationShipperArgs = {
  input: ICreateOrganizationShipperInput
}

export type IMutationCreateQuoteArgs = {
  input: ICreateQuoteInput
}

export type IMutationCreateRoutingGuideCarrierRateArgs = {
  input: ICreateRoutingGuideCarrierRateInput
}

export type IMutationCreateRoutingGuideLaneArgs = {
  input: ICreateRoutingGuideLaneInput
}

export type IMutationCreateSpotRatesSettingVersionArgs = {
  input: ICreateSpotRatesSettingVersionInput
}

export type IMutationDeactivateOrganizationAdjustmentRuleArgs = {
  input: IDeactivateOrganizationAdjustmentRuleInput
}

export type IMutationDeactivateOrganizationShipperArgs = {
  input: IDeactivateOrganizationShipperInput
}

export type IMutationDeactivateRoutingGuideCarrierRateArgs = {
  input: IDeactivateRoutingGuideCarrierRateInput
}

export type IMutationDeleteContractRfpVersionLaneArgs = {
  input: IDeleteContractRfpVersionLaneInput
}

export type IMutationDuplicateContractRfpVersionLanesArgs = {
  input: IDuplicateContractRfpVersionLanesInput
}

export type IMutationFetchRatesArgs = {
  input: IFetchRatesInput
}

export type IMutationGenerateAutoRateForLaneArgs = {
  input: IGenerateAutoRateForLaneInput
}

export type IMutationImportContractRfpVersionLanesArgs = {
  input: IImportContractRfpVersionLanesInput
}

export type IMutationOnboardCurrentUserArgs = {
  input: IOnboardCurrentUserInput
}

export type IMutationRefreshRfpAutoRatesArgs = {
  input: IRefreshRfpAutoRatesInput
}

export type IMutationRemoveFileFromContractRfpArgs = {
  input: IRemoveFileFromContractRfpInput
}

export type IMutationRestartContractRfpVersionArgs = {
  input: IRestartContractRfpVersionInput
}

export type IMutationTransitionContractRfpStateArgs = {
  input: ITransitionContractRfpStateInput
}

export type IMutationUpdateCarrierRateSequencesArgs = {
  input: IUpdateCarrierRateSequencesInput
}

export type IMutationUpdateContractRfpKanbanPositionArgs = {
  input: IUpdateContractRfpKanbanPositionInput
}

export type IMutationUpdateCurrentUserArgs = {
  input: IUpdateCurrentUserInput
}

export type IMutationUpdateOrganizationAdjustmentRuleArgs = {
  input: IUpdateOrganizationAdjustmentRuleInput
}

export type IMutationUpdateOrganizationShipperArgs = {
  input: IUpdateOrganizationShipperInput
}

export type IMutationUpdateQuoteArgs = {
  input: IUpdateQuoteInput
}

export type IMutationUpdateRoutingGuideCarrierRateArgs = {
  input: IUpdateRoutingGuideCarrierRateInput
}

export type IMutationUpdateRoutingGuideLaneManagerArgs = {
  input: IUpdateRoutingGuideLaneManagerInput
}

export type IMutationUploadFileToContractRfpArgs = {
  input: IUploadFileToContractRfpInput
}

export type IMutationUpsertContractRfpArgs = {
  input: IUpsertContractRfpInput
}

export type IMutationUpsertContractRfpVersionArgs = {
  input: IUpsertContractRfpVersionInput
}

export type IMutationUpsertContractRfpVersionLaneArgs = {
  input: IUpsertContractRfpVersionLaneInput
}

/** Autogenerated input type of OnboardCurrentUser */
export type IOnboardCurrentUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
}

/** Autogenerated return type of OnboardCurrentUser. */
export type IOnboardCurrentUserPayload = {
  __typename?: 'OnboardCurrentUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  user?: Maybe<IUser>
}

export type IOrganization = {
  __typename?: 'Organization'
  createdAt: Scalars['ISO8601DateTime']['output']
  fullLogo?: Maybe<IAttachedFile>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  navLogo?: Maybe<IAttachedFile>
  organizationTeams: Array<IOrganizationTeam>
  productModules: Array<IOrganizationProductModule>
  thumbLogo?: Maybe<IAttachedFile>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type IOrganizationProductModulesArgs = {
  statuses?: InputMaybe<Array<IOrganizationProductModuleStatus>>
}

export type IOrganizationAdjustmentRule = {
  __typename?: 'OrganizationAdjustmentRule'
  activeAdjustmentRuleVersion?: Maybe<IAdjustmentRuleVersion>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser?: Maybe<IOrganizationUser>
  deprecatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  displayId?: Maybe<Scalars['BigInt']['output']>
  id: Scalars['ID']['output']
}

export type IOrganizationCarrierManager = {
  __typename?: 'OrganizationCarrierManager'
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: IOrganizationUser
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  organizationCarrierRelationship: IOrganizationCarrierRelationship
  organizationUser: IOrganizationUser
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedBy: IOrganizationUser
}

export type IOrganizationCarrierRelationship = {
  __typename?: 'OrganizationCarrierRelationship'
  carrier: ICarrier
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  organization: IOrganization
  organizationCarrierManagers?: Maybe<Array<IOrganizationCarrierManager>>
  state: IOrganizationCarrierRelationshipState
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for OrganizationCarrierRelationship. */
export type IOrganizationCarrierRelationshipConnection = {
  __typename?: 'OrganizationCarrierRelationshipConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IOrganizationCarrierRelationshipEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IOrganizationCarrierRelationship>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IOrganizationCarrierRelationshipEdge = {
  __typename?: 'OrganizationCarrierRelationshipEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IOrganizationCarrierRelationship>
}

export enum IOrganizationCarrierRelationshipState {
  active = 'active',
  deactivated = 'deactivated',
  denied = 'denied',
  pending = 'pending',
}

export type IOrganizationProductModule = {
  __typename?: 'OrganizationProductModule'
  accessGrantedAt: Scalars['ISO8601DateTime']['output']
  accessGrantedByOrganizationUser: IOrganizationUser
  accessRevokedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  accessRevokedByOrganizationUser?: Maybe<IOrganizationUser>
  organizationAdmins: Array<IOrganizationUser>
  organizationUsers: Array<IOrganizationUser>
  productModule: IProductModules
  status: IOrganizationProductModuleStatus
}

export enum IOrganizationProductModuleStatus {
  active = 'active',
  inactive = 'inactive',
}

export type IOrganizationQuote = {
  __typename?: 'OrganizationQuote'
  bidAmount?: Maybe<Scalars['Float']['output']>
  communicationMethod?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<IOrganizationUser>
  customerReference?: Maybe<Scalars['String']['output']>
  deliveryCity?: Maybe<Scalars['String']['output']>
  deliveryIsoCountryCode?: Maybe<Scalars['String']['output']>
  deliveryPostalCode?: Maybe<Scalars['String']['output']>
  deliveryStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  deliveryStateCode?: Maybe<Scalars['String']['output']>
  equipment: IEquipment
  id: Scalars['BigInt']['output']
  isDeliveryDropTrailer: Scalars['Boolean']['output']
  isPickupDropTrailer: Scalars['Boolean']['output']
  numStops?: Maybe<Scalars['Int']['output']>
  organizationShipper?: Maybe<IOrganizationShipper>
  pickupCity?: Maybe<Scalars['String']['output']>
  pickupIsoCountryCode?: Maybe<Scalars['String']['output']>
  pickupPostalCode?: Maybe<Scalars['String']['output']>
  pickupStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  pickupStateCode?: Maybe<Scalars['String']['output']>
  pricingRequestParameters?: Maybe<IPricingRequestParameters>
  pricingResponse?: Maybe<IPricingResponse>
  rateType?: Maybe<Scalars['String']['output']>
  shipmentNumber?: Maybe<Scalars['String']['output']>
  source?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  stops?: Maybe<Array<IStop>>
  submittedDate?: Maybe<Scalars['ISO8601Date']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedBy?: Maybe<IOrganizationUser>
}

/** The connection type for OrganizationQuote. */
export type IOrganizationQuoteConnection = {
  __typename?: 'OrganizationQuoteConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IOrganizationQuoteEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IOrganizationQuote>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IOrganizationQuoteEdge = {
  __typename?: 'OrganizationQuoteEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IOrganizationQuote>
}

export type IOrganizationSetting = {
  __typename?: 'OrganizationSetting'
  activeSettingVersion?: Maybe<ISettingVersion>
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  settingVersions: Array<ISettingVersion>
}

export type IOrganizationShipper = {
  __typename?: 'OrganizationShipper'
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser: IOrganizationUser
  deprecatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  displayId: Scalars['Int']['output']
  externalId: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  organization: IOrganization
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedByOrganizationUser?: Maybe<IOrganizationUser>
}

/** The connection type for OrganizationShipper. */
export type IOrganizationShipperConnection = {
  __typename?: 'OrganizationShipperConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IOrganizationShipperEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IOrganizationShipper>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IOrganizationShipperEdge = {
  __typename?: 'OrganizationShipperEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IOrganizationShipper>
}

export type IOrganizationShipperFilter = {
  sortBy?: InputMaybe<IOrganizationShipperSortBy>
  sortOrder?: InputMaybe<ISortOrder>
  status?: InputMaybe<IOrganizationShipperStatus>
}

export enum IOrganizationShipperSortBy {
  created_at = 'created_at',
  external_id = 'external_id',
  name = 'name',
  updated_at = 'updated_at',
}

export enum IOrganizationShipperStatus {
  /** The organization shipper is active (deprecated_at is nil). */
  active = 'active',
  /** The organization shipper is deactivated (deprecated_at is present). */
  deactivated = 'deactivated',
}

export type IOrganizationTeam = {
  __typename?: 'OrganizationTeam'
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  system: Scalars['Boolean']['output']
}

export type IOrganizationUser = {
  __typename?: 'OrganizationUser'
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  externalId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  lastLogInAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  organization: IOrganization
  organizationTeams: Array<IOrganizationTeam>
  status: IOrganizationUserStatus
  statusChangedAt: Scalars['ISO8601DateTime']['output']
  user: IUser
}

/** The connection type for OrganizationUser. */
export type IOrganizationUserConnection = {
  __typename?: 'OrganizationUserConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IOrganizationUserEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IOrganizationUser>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IOrganizationUserEdge = {
  __typename?: 'OrganizationUserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IOrganizationUser>
}

export enum IOrganizationUserSortBy {
  firstName = 'firstName',
  lastLogInAt = 'lastLogInAt',
}

export enum IOrganizationUserStatus {
  active = 'active',
  deleted = 'deleted',
  invited = 'invited',
}

/** Information about pagination in a connection. */
export type IPageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type IPermission = {
  __typename?: 'Permission'
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  value: Scalars['Boolean']['output']
}

export type IPickupGeoFactor = {
  __typename?: 'PickupGeoFactor'
  geoType: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

export type IPickupGeoFactorInput = {
  geoType: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

export type IPostalCode = {
  __typename?: 'PostalCode'
  city: Scalars['String']['output']
  countryCode: ICountries
  id: Scalars['ID']['output']
  postalCode: Scalars['String']['output']
  stateCode: ICountrySubdivisionCodes
}

export type IPricingRequestParameters = {
  __typename?: 'PricingRequestParameters'
  assignmentLeadTimeMinutes?: Maybe<Scalars['Int']['output']>
  creationLeadTimeMinutes?: Maybe<Scalars['Int']['output']>
  isTeam?: Maybe<Scalars['Boolean']['output']>
  palletCount?: Maybe<Scalars['Float']['output']>
  reeferTempMax?: Maybe<Scalars['Float']['output']>
  reeferTempMin?: Maybe<Scalars['Float']['output']>
  weight?: Maybe<Scalars['Float']['output']>
}

export type IPricingResponse = {
  __typename?: 'PricingResponse'
  estimatedCarrierRate?: Maybe<Scalars['Float']['output']>
  margin?: Maybe<Scalars['Float']['output']>
}

export type IProductModule = {
  __typename?: 'ProductModule'
  key: IProductModules
  name: Scalars['String']['output']
}

export enum IProductModules {
  /** Autobidder */
  autobidder = 'autobidder',
  /** Contract Rates Tool */
  contract_rates_tool = 'contract_rates_tool',
  /** Contract RFP Workflow */
  contract_rfp = 'contract_rfp',
  /** Quotes Tool */
  quotes = 'quotes',
  /** Spot Rates Tool */
  rates_tool = 'rates_tool',
  /** Reporting */
  reporting = 'reporting',
  /** Routing Guide */
  routing_guide = 'routing_guide',
  /** Shipper Management */
  shipper_management = 'shipper_management',
}

export type IQuery = {
  __typename?: 'Query'
  contractRfpVersionInsights?: Maybe<IContractRfpVersionInsights>
  contractRfpVersionLanes: IContractRfpVersionLaneConnection
  contractRfpVersions: IContractRfpVersionConnection
  contractRfps: IContractRfpConnection
  currentSession: ISession
  drivingDistance: IDrivingDistanceRouteMeasurement
  fetchContractRates: IContractRatesResponse
  geocodingSearch: Array<IGeocoding>
  importContractRfpVersionLanesStatus?: Maybe<IImportContractRfpVersionLanesStatus>
  organizationQuote?: Maybe<IOrganizationQuote>
  organizationQuotes: IOrganizationQuoteConnection
  organizationQuotesHistoricalInsights: IHistoricalInsights
  organizationShipper?: Maybe<IOrganizationShipper>
  organizationUser?: Maybe<IOrganizationUser>
  productModules: Array<IProductModule>
  routingGuideLane?: Maybe<IRoutingGuideLane>
  routingGuideLaneActivity: IActivityConnection
  routingGuideLanes: IRoutingGuideLaneConnection
  searchOrganizationCarrierUsers: ICarrierUserConnection
  searchOrganizationCarriers: IOrganizationCarrierRelationshipConnection
  searchOrganizationShippers: IOrganizationShipperConnection
  searchOrganizationUsers: IOrganizationUserConnection
  spotRatesAdjustmentRuleVersions: IAdjustmentRuleVersionConnection
  spotRatesOrganizationSetting?: Maybe<IOrganizationSetting>
}

export type IQueryContractRfpVersionInsightsArgs = {
  contractRfpVersionId: Scalars['ID']['input']
}

export type IQueryContractRfpVersionLanesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  contractRfpVersionId: Scalars['ID']['input']
  deliveryCity?: InputMaybe<Scalars['String']['input']>
  deliveryPostalCode?: InputMaybe<Scalars['String']['input']>
  deliveryStateCode?: InputMaybe<ICountrySubdivisionCodes>
  dropCombinations?: InputMaybe<Array<IDropCombinations>>
  equipmentKeys?: InputMaybe<Array<Scalars['String']['input']>>
  externalIds?: InputMaybe<Array<Scalars['ID']['input']>>
  first?: InputMaybe<Scalars['Int']['input']>
  hasRate?: InputMaybe<Scalars['Boolean']['input']>
  hasVolume?: InputMaybe<Scalars['Boolean']['input']>
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>
  isDeliveryDropTrailer?: InputMaybe<Scalars['Boolean']['input']>
  isPickupDropTrailer?: InputMaybe<Scalars['Boolean']['input']>
  isTeam?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  pickupCity?: InputMaybe<Scalars['String']['input']>
  pickupPostalCode?: InputMaybe<Scalars['String']['input']>
  pickupStateCode?: InputMaybe<ICountrySubdivisionCodes>
  rateRangeEnd?: InputMaybe<Scalars['Float']['input']>
  rateRangeStart?: InputMaybe<Scalars['Float']['input']>
  rateTypes?: InputMaybe<Array<IContractRfpVersionLaneRateRateTypes>>
  searchExternalIds?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<IContractRfpVersionLaneSortBy>
  sortOrder?: InputMaybe<ISortOrder>
  volumeFrequency?: InputMaybe<IContractRfpLaneVolumeFrequencies>
  volumeRangeEnd?: InputMaybe<Scalars['Float']['input']>
  volumeRangeStart?: InputMaybe<Scalars['Float']['input']>
}

export type IQueryContractRfpVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  contractRfpId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  numbers?: InputMaybe<Array<Scalars['Int']['input']>>
  onlyCurrent?: InputMaybe<Scalars['Boolean']['input']>
}

export type IQueryContractRfpsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  assignedToIds?: InputMaybe<Array<Scalars['ID']['input']>>
  before?: InputMaybe<Scalars['String']['input']>
  dueAtRangeEnd?: InputMaybe<Scalars['ISO8601Date']['input']>
  dueAtRangeStart?: InputMaybe<Scalars['ISO8601Date']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  nameOrDisplayId?: InputMaybe<Scalars['String']['input']>
  organizationShipperIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sortBy?: InputMaybe<IContractRfpSortBy>
  sortOrder?: InputMaybe<ISortOrder>
  states?: InputMaybe<Array<IContractRfpStates>>
}

export type IQueryDrivingDistanceArgs = {
  points: Array<ICoordinatesInput>
}

export type IQueryFetchContractRatesArgs = {
  equipmentKey: Scalars['String']['input']
  isTeam?: InputMaybe<Scalars['Boolean']['input']>
  monthlyVolume: Array<IMonthlyVolumeInput>
  reeferTempMax?: InputMaybe<Scalars['Float']['input']>
  reeferTempMin?: InputMaybe<Scalars['Float']['input']>
  stops: Array<IContractRatesStopInput>
}

export type IQueryGeocodingSearchArgs = {
  countries?: InputMaybe<Array<IGeocodingAllowedCountryEnum>>
  latitude?: InputMaybe<Scalars['Float']['input']>
  longitude?: InputMaybe<Scalars['Float']['input']>
  resultTypes?: InputMaybe<Array<IGeocodingResultTypeEnum>>
  searchString?: InputMaybe<Scalars['String']['input']>
}

export type IQueryImportContractRfpVersionLanesStatusArgs = {
  contractRfpVersionId: Scalars['ID']['input']
}

export type IQueryOrganizationQuoteArgs = {
  id: Scalars['ID']['input']
}

export type IQueryOrganizationQuotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdByOrganizationUserId?: InputMaybe<Scalars['ID']['input']>
  deliveryCity?: InputMaybe<Scalars['String']['input']>
  deliveryStateCode?: InputMaybe<Scalars['String']['input']>
  equipmentKey?: InputMaybe<Scalars['String']['input']>
  externalIdQuery?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Array<Scalars['ID']['input']>>
  isDeliveryDropTrailer?: InputMaybe<Scalars['Boolean']['input']>
  isMultistop?: InputMaybe<Scalars['Boolean']['input']>
  isPickupDropTrailer?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  organizationShipperId?: InputMaybe<Scalars['ID']['input']>
  pickupCity?: InputMaybe<Scalars['String']['input']>
  pickupStateCode?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<ISortOrder>
  source?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<Scalars['String']['input']>>
}

export type IQueryOrganizationQuotesHistoricalInsightsArgs = {
  deliveryPostalCode: Scalars['String']['input']
  equipmentKey: IEquipmentKeys
  pickupPostalCode: Scalars['String']['input']
  quotedSince?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type IQueryOrganizationShipperArgs = {
  id: Scalars['ID']['input']
}

export type IQueryOrganizationUserArgs = {
  id: Scalars['ID']['input']
}

export type IQueryRoutingGuideLaneArgs = {
  id: Scalars['ID']['input']
}

export type IQueryRoutingGuideLaneActivityArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  dateRangeEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  dateRangeStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  eventTypes: Array<IRoutingGuideActivityEventTypesEnum>
  first?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  last?: InputMaybe<Scalars['Int']['input']>
  queryString?: InputMaybe<Scalars['String']['input']>
  sortByDateOrder?: InputMaybe<ISortOrder>
}

export type IQueryRoutingGuideLanesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<IRoutingGuideLaneFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type IQuerySearchOrganizationCarrierUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  carrierId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  roles?: InputMaybe<Array<ICarrierUserRoles>>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  userTypes?: InputMaybe<Array<ICarrierUserUserTypes>>
}

export type IQuerySearchOrganizationCarriersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  states?: InputMaybe<Array<IOrganizationCarrierRelationshipState>>
}

export type IQuerySearchOrganizationShippersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<IOrganizationShipperFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type IQuerySearchOrganizationUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  permissions?: InputMaybe<Array<Scalars['String']['input']>>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<IOrganizationUserSortBy>
  sortOrder?: InputMaybe<ISortOrder>
  statuses?: InputMaybe<Array<IOrganizationUserStatus>>
  teamKeys?: InputMaybe<Array<Scalars['String']['input']>>
}

export type IQuerySpotRatesAdjustmentRuleVersionsArgs = {
  adjustmentType?: InputMaybe<IAdjustmentTypeEnum>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdByOrganizationUserId?: InputMaybe<Scalars['ID']['input']>
  deliveryLocation?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  ordering?: InputMaybe<Array<IMultisortInput>>
  organizationShipperId?: InputMaybe<Scalars['ID']['input']>
  pickupLocation?: InputMaybe<Scalars['String']['input']>
  ruleAction?: InputMaybe<Scalars['String']['input']>
}

export type IRateAdjustments = {
  __typename?: 'RateAdjustments'
  appliedRules?: Maybe<Array<IAdjustmentRuleVersion>>
  doNotBid?: Maybe<Scalars['Boolean']['output']>
  matchedRules?: Maybe<Array<IAdjustmentRuleVersion>>
  maxCostLimitReached?: Maybe<Scalars['Boolean']['output']>
  minCostLimitReached?: Maybe<Scalars['Boolean']['output']>
  organizationSetting?: Maybe<IOrganizationSetting>
  rawCostPercentile?: Maybe<Scalars['Int']['output']>
  rawCostShiftPercentage?: Maybe<Scalars['Float']['output']>
  rawMarginPremium?: Maybe<Scalars['Float']['output']>
  suggestedCostPercentile?: Maybe<Scalars['Int']['output']>
  suggestedCostShiftPercentage?: Maybe<Scalars['Float']['output']>
  suggestedMarginPremium?: Maybe<Scalars['Float']['output']>
  unmatchedRules?: Maybe<Array<IAdjustmentRuleVersion>>
}

export type IRatesResponse = {
  __typename?: 'RatesResponse'
  assignmentLeadTimeMinutes?: Maybe<Scalars['Int']['output']>
  creationLeadTimeMinutes?: Maybe<Scalars['Int']['output']>
  /** deprecated, use spot_cost_model */
  customCostModel?: Maybe<ICustomCostModelResponse>
  equipmentKey?: Maybe<Scalars['String']['output']>
  isTeam?: Maybe<Scalars['Boolean']['output']>
  miles?: Maybe<Scalars['Float']['output']>
  organizationShipper?: Maybe<IOrganizationShipper>
  palletCount?: Maybe<Scalars['Float']['output']>
  rateAdjustments?: Maybe<IRateAdjustments>
  reeferTempMax?: Maybe<Scalars['Float']['output']>
  reeferTempMin?: Maybe<Scalars['Float']['output']>
  spotCostModel?: Maybe<ISpotCostModelResponse>
  stops: Array<IStop>
  weight?: Maybe<Scalars['Float']['output']>
}

/** Autogenerated input type of RefreshRFPAutoRates */
export type IRefreshRfpAutoRatesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  rfpVersionId: Scalars['ID']['input']
}

/** Autogenerated return type of RefreshRFPAutoRates. */
export type IRefreshRfpAutoRatesPayload = {
  __typename?: 'RefreshRFPAutoRatesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  result: Scalars['Boolean']['output']
}

/** Autogenerated input type of RemoveFileFromContractRFP */
export type IRemoveFileFromContractRfpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  fileId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

/** Autogenerated return type of RemoveFileFromContractRFP. */
export type IRemoveFileFromContractRfpPayload = {
  __typename?: 'RemoveFileFromContractRFPPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfp?: Maybe<IContractRfp>
  errors: Array<IError>
}

/** Autogenerated input type of RestartContractRFPVersion */
export type IRestartContractRfpVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

/** Autogenerated return type of RestartContractRFPVersion. */
export type IRestartContractRfpVersionPayload = {
  __typename?: 'RestartContractRFPVersionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfpVersion?: Maybe<IContractRfpVersion>
  errors: Array<IError>
}

export enum IRoutingGuideActivityEventTypesEnum {
  /** AddedCarrier */
  added_carrier = 'added_carrier',
  /** CreatedLane */
  created_lane = 'created_lane',
  /** DeactivatedCarrier */
  deactivated_carrier = 'deactivated_carrier',
  /** ReorderedCarriers */
  reordered_carriers = 'reordered_carriers',
  /** UpdatedCarrier */
  updated_carrier = 'updated_carrier',
  /** UpdatedLane */
  updated_lane = 'updated_lane',
}

export type IRoutingGuideCarrierCapacity = {
  __typename?: 'RoutingGuideCarrierCapacity'
  createdAt: Scalars['ISO8601DateTime']['output']
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  fridayCapacity: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  mondayCapacity: Scalars['Boolean']['output']
  saturdayCapacity: Scalars['Boolean']['output']
  sundayCapacity: Scalars['Boolean']['output']
  thursdayCapacity: Scalars['Boolean']['output']
  tuesdayCapacity: Scalars['Boolean']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  wednesdayCapacity: Scalars['Boolean']['output']
}

export type IRoutingGuideCarrierRate = {
  __typename?: 'RoutingGuideCarrierRate'
  carrierUser: ICarrierUser
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: IOrganizationUser
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  deletedBy?: Maybe<IOrganizationUser>
  id: Scalars['ID']['output']
  organizationCarrierRelationship: IOrganizationCarrierRelationship
  rate: Scalars['Int']['output']
  rateType: ICarrierRateRateTypes
  routingGuideCarrierCapacity: IRoutingGuideCarrierCapacity
  sequenceNumber: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  updatedBy: IOrganizationUser
}

export type IRoutingGuideLane = {
  __typename?: 'RoutingGuideLane'
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: IOrganizationUser
  deliveryLocation: ICityState
  displayId: Scalars['Int']['output']
  equipment: IEquipment
  id: Scalars['ID']['output']
  laneManager: IOrganizationUser
  orgShipper: IOrganizationShipper
  pickupLocation: ICityState
  routingGuideCarrierRates?: Maybe<Array<IRoutingGuideCarrierRate>>
  updatedBy: IOrganizationUser
}

/** The connection type for RoutingGuideLane. */
export type IRoutingGuideLaneConnection = {
  __typename?: 'RoutingGuideLaneConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IRoutingGuideLaneEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IRoutingGuideLane>>>
  /** Information to aid in pagination. */
  pageInfo: IPageInfo
  /** Total number of items in this collection */
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type IRoutingGuideLaneEdge = {
  __typename?: 'RoutingGuideLaneEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<IRoutingGuideLane>
}

export type IRoutingGuideLaneFilter = {
  customerId?: InputMaybe<Scalars['ID']['input']>
  deliveryLocation?: InputMaybe<ICityStateInput>
  displayId?: InputMaybe<Scalars['Int']['input']>
  equipmentKeys?: InputMaybe<Array<IEquipmentKeys>>
  laneManagerId?: InputMaybe<Scalars['ID']['input']>
  pickupLocation?: InputMaybe<ICityStateInput>
  sortBy?: InputMaybe<IRoutingGuideLaneSortBy>
  sortOrder?: InputMaybe<ISortOrder>
}

export enum IRoutingGuideLaneSortBy {
  created_at = 'created_at',
}

export type ISession = {
  __typename?: 'Session'
  organizationUser: IOrganizationUser
  permissions: Array<IPermission>
  user: IUser
}

export type ISettingVersion = {
  __typename?: 'SettingVersion'
  createdAt: Scalars['ISO8601DateTime']['output']
  createdByOrganizationUser?: Maybe<IOrganizationUser>
  defaultCostPercentile?: Maybe<Scalars['BigInt']['output']>
  defaultMarginPremium?: Maybe<Scalars['Float']['output']>
  deprecatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  maxMarginPremium?: Maybe<Scalars['Float']['output']>
  organizationSetting: IOrganizationSetting
}

export enum ISortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type ISpotCostModelResponse = {
  __typename?: 'SpotCostModelResponse'
  cost10?: Maybe<Scalars['Float']['output']>
  cost15?: Maybe<Scalars['Float']['output']>
  cost20?: Maybe<Scalars['Float']['output']>
  cost25?: Maybe<Scalars['Float']['output']>
  cost30?: Maybe<Scalars['Float']['output']>
  cost35?: Maybe<Scalars['Float']['output']>
  cost40?: Maybe<Scalars['Float']['output']>
  cost45?: Maybe<Scalars['Float']['output']>
  cost50?: Maybe<Scalars['Float']['output']>
  cost55?: Maybe<Scalars['Float']['output']>
  cost60?: Maybe<Scalars['Float']['output']>
  cost65?: Maybe<Scalars['Float']['output']>
  cost70?: Maybe<Scalars['Float']['output']>
  cost75?: Maybe<Scalars['Float']['output']>
  cost80?: Maybe<Scalars['Float']['output']>
  cost85?: Maybe<Scalars['Float']['output']>
  cost90?: Maybe<Scalars['Float']['output']>
  costMean?: Maybe<Scalars['Float']['output']>
  miles?: Maybe<Scalars['Float']['output']>
}

export type IStop = {
  __typename?: 'Stop'
  appointmentStartAtDatetime?: Maybe<Scalars['ISO8601DateTime']['output']>
  city?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['String']['output']>
  isDropTrailer: Scalars['Boolean']['output']
  postalCode?: Maybe<Scalars['String']['output']>
  relativeTypeSequence: Scalars['Int']['output']
  startTime?: Maybe<Scalars['String']['output']>
  startTimeZone?: Maybe<Scalars['String']['output']>
  stateCode?: Maybe<Scalars['String']['output']>
  stopLatitude?: Maybe<Scalars['Float']['output']>
  stopLongitude?: Maybe<Scalars['Float']['output']>
  stopSequence: Scalars['Int']['output']
  stopType: Scalars['String']['output']
}

export type IStopInput = {
  appointmentStartAtDatetime?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['String']['input']>
  isDropTrailer?: InputMaybe<Scalars['Boolean']['input']>
  isoCountryCode?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  startTime?: InputMaybe<Scalars['String']['input']>
  startTimeZone?: InputMaybe<Scalars['String']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  stopLatitude?: InputMaybe<Scalars['Float']['input']>
  stopLongitude?: InputMaybe<Scalars['Float']['input']>
  stopSequence: Scalars['Int']['input']
  stopType: Scalars['String']['input']
}

export enum IStopTypes {
  delivery = 'delivery',
  pickup = 'pickup',
}

/** Autogenerated input type of TransitionContractRFPState */
export type ITransitionContractRfpStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  /** Must be provided when performing the `submit` transition */
  submittedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  transition: IContractRfpStateTransitions
}

/** Autogenerated return type of TransitionContractRFPState. */
export type ITransitionContractRfpStatePayload = {
  __typename?: 'TransitionContractRFPStatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfp?: Maybe<IContractRfp>
  errors: Array<IError>
}

export type IUniqueLocations = {
  __typename?: 'UniqueLocations'
  count: Scalars['Int']['output']
  mostCommonLocation: IPostalCode
  stopType: Scalars['String']['output']
}

export type IUpdateCarrierRateSequenceNumbersInput = {
  routingGuideCarrierRateId: Scalars['ID']['input']
  sequenceNumber: Scalars['Int']['input']
}

/** Autogenerated input type of UpdateCarrierRateSequences */
export type IUpdateCarrierRateSequencesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  routingGuideLaneId: Scalars['ID']['input']
  sequenceNumberUpdates: Array<IUpdateCarrierRateSequenceNumbersInput>
}

/** Autogenerated return type of UpdateCarrierRateSequences. */
export type IUpdateCarrierRateSequencesPayload = {
  __typename?: 'UpdateCarrierRateSequencesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  routingGuideLane?: Maybe<IRoutingGuideLane>
}

/** Autogenerated input type of UpdateContractRFPKanbanPosition */
export type IUpdateContractRfpKanbanPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  insertAfterKanbanId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateContractRFPKanbanPosition. */
export type IUpdateContractRfpKanbanPositionPayload = {
  __typename?: 'UpdateContractRFPKanbanPositionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfp?: Maybe<IContractRfp>
  errors: Array<IError>
}

/** Autogenerated input type of UpdateCurrentUser */
export type IUpdateCurrentUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateCurrentUser. */
export type IUpdateCurrentUserPayload = {
  __typename?: 'UpdateCurrentUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  user?: Maybe<IUser>
}

/** Autogenerated input type of UpdateOrganizationAdjustmentRule */
export type IUpdateOrganizationAdjustmentRuleInput = {
  adjustmentType: IAdjustmentTypeEnum
  adjustmentValue?: InputMaybe<Scalars['Float']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  endAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  factors: IFactorsInput
  organizationAdjustmentRuleId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  ruleAction: Scalars['String']['input']
  startAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

/** Autogenerated return type of UpdateOrganizationAdjustmentRule. */
export type IUpdateOrganizationAdjustmentRulePayload = {
  __typename?: 'UpdateOrganizationAdjustmentRulePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationAdjustmentRule?: Maybe<IOrganizationAdjustmentRule>
}

/** Autogenerated input type of UpdateOrganizationShipper */
export type IUpdateOrganizationShipperInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  externalId: Scalars['String']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

/** Autogenerated return type of UpdateOrganizationShipper. */
export type IUpdateOrganizationShipperPayload = {
  __typename?: 'UpdateOrganizationShipperPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationShipper?: Maybe<IOrganizationShipper>
}

/** Autogenerated input type of UpdateQuote */
export type IUpdateQuoteInput = {
  bidAmount?: InputMaybe<Scalars['Float']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  customerReference?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  margin?: InputMaybe<Scalars['Float']['input']>
  organizationShipperId?: InputMaybe<Scalars['ID']['input']>
  rateType?: InputMaybe<Scalars['String']['input']>
  shipmentNumber?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  submittedDate?: InputMaybe<Scalars['ISO8601Date']['input']>
}

/** Autogenerated return type of UpdateQuote. */
export type IUpdateQuotePayload = {
  __typename?: 'UpdateQuotePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  organizationQuote?: Maybe<IOrganizationQuote>
}

/** Autogenerated input type of UpdateRoutingGuideCarrierRate */
export type IUpdateRoutingGuideCarrierRateInput = {
  carrierCapacities?: InputMaybe<ICarrierCapacityInput>
  /** pass this field to assign an existing carrier user as carrier contact, or if the carrier contact is unchanged */
  carrierUserId?: InputMaybe<Scalars['ID']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** pass this field only if a new carrier user is to be created and assigned as carrier contact */
  newCarrierUserEmail?: InputMaybe<Scalars['String']['input']>
  rate: Scalars['Float']['input']
  rateType: ICarrierRateRateTypes
  routingGuideCarrierRateId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateRoutingGuideCarrierRate. */
export type IUpdateRoutingGuideCarrierRatePayload = {
  __typename?: 'UpdateRoutingGuideCarrierRatePayload'
  carrierRate?: Maybe<IRoutingGuideCarrierRate>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
}

/** Autogenerated input type of UpdateRoutingGuideLaneManager */
export type IUpdateRoutingGuideLaneManagerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  laneManagerId: Scalars['ID']['input']
  routingGuideLaneId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateRoutingGuideLaneManager. */
export type IUpdateRoutingGuideLaneManagerPayload = {
  __typename?: 'UpdateRoutingGuideLaneManagerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<IError>
  routingGuideLane?: Maybe<IRoutingGuideLane>
}

/** Autogenerated input type of UploadFileToContractRFP */
export type IUploadFileToContractRfpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  file: Scalars['String']['input']
  filename: Scalars['String']['input']
  id: Scalars['ID']['input']
}

/** Autogenerated return type of UploadFileToContractRFP. */
export type IUploadFileToContractRfpPayload = {
  __typename?: 'UploadFileToContractRFPPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfp?: Maybe<IContractRfp>
  errors: Array<IError>
}

/** Autogenerated input type of UpsertContractRFP */
export type IUpsertContractRfpInput = {
  assignedToOrganizationUserId?: InputMaybe<Scalars['ID']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  endAt?: InputMaybe<Scalars['ISO8601Date']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  organizationShipperId?: InputMaybe<Scalars['ID']['input']>
  startAt?: InputMaybe<Scalars['ISO8601Date']['input']>
  userAssignments?: InputMaybe<Array<IContractRfpUserAssignmentInput>>
  webLinks?: InputMaybe<Array<IWebLinkInput>>
}

/** Autogenerated return type of UpsertContractRFP. */
export type IUpsertContractRfpPayload = {
  __typename?: 'UpsertContractRFPPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfp?: Maybe<IContractRfp>
  errors: Array<IError>
}

/** Autogenerated input type of UpsertContractRFPVersion */
export type IUpsertContractRfpVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  contractRfpId?: InputMaybe<Scalars['ID']['input']>
  dueAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  submittedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

/** Autogenerated input type of UpsertContractRFPVersionLane */
export type IUpsertContractRfpVersionLaneInput = {
  /** Indicates if rates should be auto generated. */
  autoGenerateRates?: InputMaybe<Scalars['Boolean']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  contractRfpVersionId?: InputMaybe<Scalars['ID']['input']>
  customMiles?: InputMaybe<Scalars['Float']['input']>
  equipmentKey?: InputMaybe<IEquipmentKeys>
  externalId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isTeam?: InputMaybe<Scalars['Boolean']['input']>
  rate?: InputMaybe<IContractRfpVersionLaneRateInput>
  reeferTempMax?: InputMaybe<Scalars['Int']['input']>
  reeferTempMin?: InputMaybe<Scalars['Int']['input']>
  stops?: InputMaybe<Array<IContractRfpVersionLaneStopInput>>
  volume?: InputMaybe<Scalars['Float']['input']>
  volumeAmounts?: InputMaybe<Array<IContractRfpVolumeAmountInput>>
  volumeFrequency?: InputMaybe<IContractRfpLaneVolumeFrequencies>
}

/** Autogenerated return type of UpsertContractRFPVersionLane. */
export type IUpsertContractRfpVersionLanePayload = {
  __typename?: 'UpsertContractRFPVersionLanePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfpVersionLane?: Maybe<IContractRfpVersionLane>
  errors: Array<IError>
}

/** Autogenerated return type of UpsertContractRFPVersion. */
export type IUpsertContractRfpVersionPayload = {
  __typename?: 'UpsertContractRFPVersionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contractRfpVersion?: Maybe<IContractRfpVersion>
  errors: Array<IError>
}

export type IUser = {
  __typename?: 'User'
  emailAddress: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type IVersionRategenSummary = {
  __typename?: 'VersionRategenSummary'
  numErrorLanes: Scalars['Int']['output']
  numPendingLanes: Scalars['Int']['output']
}

export type IWebLink = {
  __typename?: 'WebLink'
  createdAt: Scalars['ISO8601DateTime']['output']
  name: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type IWebLinkInput = {
  name: Scalars['String']['input']
  url: Scalars['String']['input']
}
