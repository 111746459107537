import { IRoutingGuideCarrierDataGrid } from '../types'

export const getRoutingGuideCarrierDataGridRows = (
  rows: IRoutingGuideCarrierDataGrid['rows']
): IRoutingGuideCarrierDataGrid['rows'] => {
  return rows
    ?.map(row => {
      const { organizationCarrierRelationship } = row || {}
      const { carrier } = organizationCarrierRelationship || {}
      const { legalName } = carrier || {}
      /**
       * Customizing the reorder value
       * @see https://mui.com/x/react-data-grid/row-ordering/#customizing-the-reorder-value
       */
      return { ...row, __reorder__: `${legalName}` }
    })
    .sort(({ sequenceNumber: a }, { sequenceNumber: b }) => a - b)
}
