import React from 'react'
import { usePermissionsContext } from 'auth/common/context'
import { brandColors } from 'dpl'
import { Box, makeStyles } from 'dpl/core'
import Typography from 'dpl/core/Typography'
import AIIcon from 'dpl/icons/build/AIIcon'
import { useFlagsContext } from 'flags/src/FlagsContext'

const useStyles = makeStyles(theme => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: brandColors.skyBlue1,
    padding: theme.spacing(1, 2),
    borderRadius: 8,
    marginBottom: theme.spacing(3),
  },
  iconContainer: {
    borderRadius: '50%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: brandColors.skyBlue2,
    marginRight: theme.spacing(1),
  },
}))

export function RateGenBanner() {
  const { isFlagEnabled } = useFlagsContext()
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled

  const classes = useStyles()

  if (!isRfpRateGenEnabled) {
    return null
  }
  return (
    <div className={classes.banner}>
      <Box className={classes.iconContainer}>
        <AIIcon color='info' />
      </Box>
      <Typography variant='body2'>Rates will be instantly generated for your RFP.</Typography>
    </div>
  )
}
