import React, { memo } from 'react'
import { AutocompleteLocation, IAutocompleteLocationProps } from '#components/AutocompleteLocation'

export interface IAutocompleteLocationMultiSelectProps
  extends Omit<IAutocompleteLocationProps<true>, 'multiple'> {}

export const AutocompleteLocationMultiSelect = memo<IAutocompleteLocationMultiSelectProps>(
  ({ dataTest = 'AutocompleteLocationMultiSelect', ...props }) => {
    return <AutocompleteLocation dataTest={dataTest} multiple {...props} />
  }
)
