import { IProductModules } from '#types/graphqlTypes'
import { NAV_LISTS_BY_PERMISSION } from './navLists'

/**
 * list of modules that will be enabled based on the user's permissions.
 * keep alphabetically sorted!!
 */
export const MODULES_BY_PERMISSION: {
  [K in keyof typeof NAV_LISTS_BY_PERMISSION]: IProductModules
} = {
  'contract_rates_tool.read_app': IProductModules.contract_rates_tool,
  'contract_rfp.read_app': IProductModules.contract_rfp,
  'quotes.read_app': IProductModules.quotes,
  'rates_tool.read_app': IProductModules.rates_tool,
  'reporting.read_app': IProductModules.reporting,
  'routing_guide.create_lane': IProductModules.routing_guide,
  'routing_guide.read_app': IProductModules.routing_guide,
}
