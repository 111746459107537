import React from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Button, Stack } from 'dpl/core'
import { ControlledTextField } from 'forms/components/ControlledTextField'
import { useEmailForm } from './hooks'
import { TEmailFormSchema } from './schema'

export type TEmailFormSubmitHandler = SubmitHandler<TEmailFormSchema>

export interface IEmailFormProps {
  /**
   * Text used in Action button in form
   * @default 'Continue'
   */
  buttonText?: string
  defaultEmail?: string
  onSubmit: TEmailFormSubmitHandler
}

export function EmailForm({
  buttonText = 'Continue',
  defaultEmail = '',
  onSubmit,
}: IEmailFormProps) {
  const methods = useEmailForm({
    mode: 'onSubmit',
    defaultValues: {
      email: defaultEmail,
    },
  })
  const { control, errors, handleSubmit } = methods

  const submitHandler = handleSubmit(onSubmit)

  return (
    <form data-cy='magic-link-form' onSubmit={submitHandler}>
      <Stack role='form' data-test='magic-link-form' spacing={2}>
        <ControlledTextField
          control={control}
          name='email'
          TextFieldProps={{
            dataTest: 'email-field',
            label: 'Email',
            error: Boolean(errors.email?.message),
            helperText: errors.email?.message,
            size: 'medium',
            autoComplete: 'username',
          }}
        />

        <Button fullWidth type='submit' data-test='submit-email-button'>
          {buttonText}
        </Button>
      </Stack>
    </form>
  )
}
