import { IAdjustmentRuleLineItem } from '#components/RatesEstimatedStrategyAccordion/types'
import { ICostModel } from '#types/costModel'
import { IRuleTypeEnum } from '#types/rates'
import { IRule } from '../types'
import { getAdjustmentRuleLineItem } from './getAdjustmentRuleLineItem'
import { getPercentileAmount } from './getPercentileAmount'

interface IGetNotAppliedAdjustmentRulesLineItemsProps {
  costModel: Nullable<ICostModel>
  rules: IRule[]
}

/**
 * Returns the not applied adjustment rules for display as line items in the adjustment rules section.
 * If there are no rules to display, an empty array is returned.
 * Only base rules can be displayed as not applied.
 *
 * @returns {IAdjustmentRuleLineItem[]} The formatted adjustment rules
 *
 */
export const getNotAppliedAdjustmentRulesLineItems = ({
  costModel,
  rules,
}: IGetNotAppliedAdjustmentRulesLineItemsProps): IAdjustmentRuleLineItem[] => {
  // If there are no rules to display, return an empty array
  if (rules.length === 0) {
    return []
  }

  // Filter by base rules only
  const baseRules = rules.filter(({ ruleAction }) => ruleAction === IRuleTypeEnum.base)

  // Format the rules
  return baseRules.map(rule => {
    const { adjustmentValue } = rule || {}
    const baseCost = getPercentileAmount({
      costModel,
      percentile: adjustmentValue || 0,
    })
    return getAdjustmentRuleLineItem({ rule, baseCost })
  })
}
