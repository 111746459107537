import { useCallback } from 'react'
import { useAnalytics } from '#hooks'
import { useToastContext } from 'dpl'
import { IRfpLaneFragment } from '../LaneList/graphql/RfpLane'
import { useDeleteLaneMutation } from './graphql/DeleteLane'

export function useDeleteLaneModal() {
  const { openToast } = useToastContext()
  const { trackEvent } = useAnalytics()
  const [deleteLane] = useDeleteLaneMutation()
  const deleteRfpLane = useCallback(
    async (item: IRfpLaneFragment) => {
      const input = {
        id: item.id,
      }
      try {
        const { data } = await deleteLane({
          variables: {
            input,
          },
          refetchQueries: ['CurrentVersionLanes'],
        })

        const { errors } = data?.deleteContractRfpVersionLane || {}

        if (errors?.length) {
          trackEvent('Contract RFPs', 'DELETE_LANE_ERROR', {
            errors,
            item,
            variables: input,
          })
          return errors[0].message
        }
      } catch (error) {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          apolloErrors: [error],
          item,
          variables: input,
        })
        return error.message
      }

      openToast({
        toastMessage: `Lane #${item.externalId} Deleted sucessfully`,
      })

      trackEvent('Contract RFPs', 'DELETE_LANE_SUCCESS', {
        item,
        variables: input,
      })

      return null
    },
    [deleteLane]
  )

  return {
    deleteRfpLane,
  }
}
