import { DataGridFilterCustomer } from '#components/DataGridFilterCustomer'
import { IContractRfpStates, IContractRfpStateTransitions } from '#types/graphqlTypes'
import { TAG_STYLE, TDataGridFiltersConfig } from 'dpl'
import { DataGridDueDate } from '../ContractRFPsPage/components/DataGridDueDate'
import { DataGridFilterRfpName } from '../ContractRFPsPage/components/DataGridFilterRfpName'
import { DataGridFilterTeamMember } from '../ContractRFPsPage/components/DataGridFilterTeamMember'
import { IFilters } from '../ContractRFPsPage/types'

export const HEADER_HEIGHT = '96px'
export const TOP_BAR_HEIGHT = '55px'
export const BOTTOM_FRAME_HEIGHT = '12px'

export const RFP_STATES: {
  value:
    | IContractRfpStates.planning
    | IContractRfpStates.in_progress
    | IContractRfpStates.submitted
    | IContractRfpStates.closed
  label: string
  style: TAG_STYLE
  outlineOnly: boolean
}[] = [
  {
    value: IContractRfpStates.planning,
    label: 'Plan',
    style: TAG_STYLE.info,
    outlineOnly: true,
  },
  {
    value: IContractRfpStates.in_progress,
    label: 'In Progress',
    style: TAG_STYLE.warning,
    outlineOnly: false,
  },
  {
    value: IContractRfpStates.submitted,
    label: 'Submitted',
    style: TAG_STYLE.info,
    outlineOnly: false,
  },
  {
    value: IContractRfpStates.closed,
    label: 'Closed',
    style: TAG_STYLE.dark,
    outlineOnly: false,
  },
]

export const TRANSITIONS: Record<IContractRfpStates, IContractRfpStateTransitions> = {
  [IContractRfpStates.planning]: IContractRfpStateTransitions.plan,
  [IContractRfpStates.in_progress]: IContractRfpStateTransitions.put_in_progress,
  [IContractRfpStates.submitted]: IContractRfpStateTransitions.submit,
  [IContractRfpStates.closed]: IContractRfpStateTransitions.close,
  [IContractRfpStates.archived]: IContractRfpStateTransitions.archive,
}

export const RFP_READABLE_STATES: Record<IContractRfpStates, string> = {
  [IContractRfpStates.planning]: 'Plan',
  [IContractRfpStates.in_progress]: 'In Progress',
  [IContractRfpStates.submitted]: 'Submitted',
  [IContractRfpStates.closed]: 'Closed',
  [IContractRfpStates.archived]: 'Archived',
}

export const FILTERS_OPTIONS_CONFIG: TDataGridFiltersConfig<IFilters> = {
  rfpName: {
    type: 'custom',
    FilterComponent: DataGridFilterRfpName,
  },
  customer: {
    type: 'custom',
    FilterComponent: DataGridFilterCustomer,
  },
  assignee: {
    type: 'custom',
    FilterComponent: DataGridFilterTeamMember,
  },
  dueDate: {
    type: 'custom',
    FilterComponent: DataGridDueDate,
  },
}

export const PAGE_RANGES = [20, 40, 60]

export const CONTRACT_RFP_FAQ_URL =
  'https://intercom.help/transfix-solutions/en/articles/10871954-contract-rfp-faq'
