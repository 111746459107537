import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import { useNavigateBack } from 'dpl/hooks'
import { useFlagsContext } from 'flags/src/FlagsContext'

export interface IUseDeactivateCustomerModalState {
  isModalOpen: boolean
  isAccessRestrictedModalOpen: boolean
  isFeatureUnavailableModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const useDeactivateCustomerModal = () => {
  const navigate = useNavigate()
  const navigateBack = useNavigateBack()
  const { isFlagEnabled } = useFlagsContext()
  const { pathname } = useLocation()

  const isPathnameCustomerDeactivate = useMemo(
    () => pathname.includes(PROTECTED_PATHS.customerDeactivate),
    [pathname]
  )

  const isCustomerDeactivateEnabled = useMemo(
    () => isFlagEnabled('update_customer_status'),
    [isFlagEnabled]
  )

  const { userPermissions } = usePermissionsContext()

  const isPermittedToDeactivateCustomer = useMemo(
    () => userPermissions['shipper_management.update_organization_shipper'],
    [userPermissions]
  )

  const deactivateModalStates: Pick<
    IUseDeactivateCustomerModalState,
    'isModalOpen' | 'isAccessRestrictedModalOpen' | 'isFeatureUnavailableModalOpen'
  > = useMemo(() => {
    if (!isPathnameCustomerDeactivate) {
      return {
        isModalOpen: false,
        isAccessRestrictedModalOpen: false,
        isFeatureUnavailableModalOpen: false,
      }
    }

    if (!isCustomerDeactivateEnabled) {
      return {
        isModalOpen: false,
        isAccessRestrictedModalOpen: false,
        isFeatureUnavailableModalOpen: true,
      }
    }

    if (!isPermittedToDeactivateCustomer) {
      return {
        isModalOpen: false,
        isAccessRestrictedModalOpen: true,
        isFeatureUnavailableModalOpen: false,
      }
    }

    return {
      isModalOpen: true,
      isAccessRestrictedModalOpen: false,
      isFeatureUnavailableModalOpen: false,
    }
  }, [isPermittedToDeactivateCustomer, isCustomerDeactivateEnabled, isPathnameCustomerDeactivate])

  const openDeactivateModal = useCallback(() => {
    navigate(PROTECTED_PATHS.customerDeactivate)
  }, [navigate])

  const closeModal = () => {
    navigateBack({ path: PROTECTED_PATHS.customers })
  }

  return {
    deactivateModalStates,
    openDeactivateModal,
    closeModal,
  }
}
