import { IPickupGeoFactorInput } from '../../../../../../../types/graphqlTypes'
import { TLocationFactorSchema } from '../../../schema/LocationFactorSchema'
import { GEOGRAPHIC_TYPES } from '../../../types'

export const getLocationFactor = (
  factor: TLocationFactorSchema
): Nullable<IPickupGeoFactorInput> => {
  const { fiveDigitZipCodes, geographicType, includeFactor, region, state } = factor || {}
  if (!includeFactor) return null
  if (geographicType === GEOGRAPHIC_TYPES.regional) {
    return {
      geoType: geographicType,
      values: [region || ''],
    }
  }
  if (geographicType === GEOGRAPHIC_TYPES.state) {
    const { stateCode } = state || {}
    return {
      geoType: geographicType,
      values: [stateCode || ''],
    }
  }
  if (geographicType === GEOGRAPHIC_TYPES.fiveDigitZipCodes) {
    if (fiveDigitZipCodes?.length) {
      return {
        geoType: geographicType,
        values: fiveDigitZipCodes.map(location => location?.postalCode || ''),
      }
    }
  }
  return {
    geoType: geographicType || '',
    values: [],
  }
}
