import React, { useCallback, useState } from 'react'
import { Typography } from 'dpl/core'
import { AuthenticationCard, EmailForm, MagicLinkSentCard } from '../../../common/components'
import { ForgotPasswordCard } from '../../../common/components/ForgotPasswordCard'
import { useOrganizationAuthMethods } from '../../hooks'
import { MainView, IMainViewProps, SsoButton } from './components'
import { TOrganization, TView } from './types'

export interface IOrganizationSignInProps
  extends Pick<
    IMainViewProps,
    'onMagicLinkRequest' | 'onPasswordError' | 'onPasswordSuccess' | 'onResetPassword'
  > {
  /**
   * Pre-filled email value
   */
  defaultEmail?: string
  /**
   * The organization the user is logging into
   */
  organization: TOrganization
  /**
   * Href to login page
   * @example /login/:organization
   */
  loginHref: string
  /**
   * Urls for redirecting after sign in or resetting pw
   * @example {reset_password_redirect_url: 'http://...', sign_in_redirect_url: 'http://...'}
   */
  redirectUrls: IMainViewProps['redirectUrls']
  /**
   * Url for terms of service
   */
  termsOfServiceUrl: string
}

export function OrganizationSignIn({
  defaultEmail = '',
  loginHref,
  onMagicLinkRequest,
  onPasswordError,
  onPasswordSuccess,
  onResetPassword,
  organization,
  redirectUrls,
  termsOfServiceUrl,
}: IOrganizationSignInProps) {
  const initialView: TView = defaultEmail ? 'login_form' : 'email_form'
  const [view, setView] = useState<TView>(initialView)
  const [emailValue, setEmailValue] = useState<string>(defaultEmail)

  const {
    allowed_auth_methods: allowedAuthMethods,
    auth_methods: authMethods,
    organization_name: organizationName,
    sso_default_connection_id: defaultSsoConnectionId,
  } = organization || {}
  const { sign_in_redirect_url: signInRedirectUrl, sign_up_redirect_url: signUpRedirectUrl } =
    redirectUrls

  const { isSsoAllowed } = useOrganizationAuthMethods({ authMethods, allowedAuthMethods })

  const viewChangeHandler = useCallback((view: TView) => {
    setView(view)
  }, [])

  const continueAfterEmailHandler = useCallback(formData => {
    setEmailValue(formData?.email)
    setView('login_form')
  }, [])

  if (view === 'magic_link_sent') {
    return (
      <MagicLinkSentCard
        loginHref={loginHref}
        magicLinkSentTo={emailValue}
        termsOfServiceUrl={termsOfServiceUrl}
      />
    )
  }

  if (view === 'forgot_password') {
    return (
      <ForgotPasswordCard
        loginHref={loginHref}
        magicLinkSentTo={emailValue}
        termsOfServiceUrl={termsOfServiceUrl}
      />
    )
  }

  if (view === 'login_form') {
    return (
      <MainView
        termsOfServiceUrl={termsOfServiceUrl}
        onViewChange={viewChangeHandler}
        defaultEmail={emailValue}
        setDefaultEmail={setEmailValue}
        organization={organization}
        redirectUrls={redirectUrls}
        onMagicLinkRequest={onMagicLinkRequest}
        onPasswordError={onPasswordError}
        onPasswordSuccess={onPasswordSuccess}
        onResetPassword={onResetPassword}
      />
    )
  }

  return (
    <AuthenticationCard
      termsOfServiceUrl={termsOfServiceUrl}
      title='Log in to Transfix'
      subheader={`Enter your ${organizationName} email to get started.`}>
      <EmailForm onSubmit={continueAfterEmailHandler} />
      {isSsoAllowed && defaultSsoConnectionId && (
        <>
          <Typography display='block' padding={2} justifySelf='center' variant='caption'>
            OR
          </Typography>

          <SsoButton
            connectionId={defaultSsoConnectionId}
            signInRedirectUrl={signInRedirectUrl}
            signUpRedirectUrl={signUpRedirectUrl}
          />
        </>
      )}
    </AuthenticationCard>
  )
}
