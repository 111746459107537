import { IAdjustmentRuleLineItemProps } from '../AdjustmentRuleLineItem'

export enum IAdjustmentRuleLineItemKeyEnum {
  minPercentileLimit = 'minPercentileLimit',
  maxPercentileLimit = 'maxPercentileLimit',
  maxMarginLimit = 'maxMarginLimit',
  default = 'default',
}

export interface IAdjustmentRuleLineItem
  extends Pick<IAdjustmentRuleLineItemProps, 'amount' | 'Icon' | 'label'> {
  key: string
}

export enum IAdjustmentRulesSectionStateEnum {
  applied = 'applied',
  notApplied = 'notApplied',
  interstitial = 'interstitial',
  overridden = 'overridden',
}

export type TAdjustmentRulesSectionVariant = Extract<
  IAdjustmentRulesSectionStateEnum,
  'applied' | 'notApplied'
>
