import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  AutocompleteOrganizationCarrierUserEmail,
  IAutocompleteOrganizationCarrierUserEmailProps,
} from '../AutocompleteOrganizationCarrierUserEmail'
import { IOrganizationCarrierUserEmailFragment } from '../AutocompleteOrganizationCarrierUserEmail/graphql/OrganizationCarrierUserEmailResultFragment'
import { TAutocompleteOrganizationCarrierUserEmailSchema } from './schema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IAutocompleteOrganizationCarrierUserEmailProps>,
  TAutocompleteOrganizationCarrierUserEmailSchema
>

export interface IControlledAutocompleteOrganizationCarrierUserEmailProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteOrganizationCarrierUserEmailProps: Omit<
    IAutocompleteOrganizationCarrierUserEmailProps,
    'inputValue' | 'onInputValue' | 'onChange' | 'value'
  >
}

export const ControlledAutocompleteOrganizationCarrierUserEmail =
  memo<IControlledAutocompleteOrganizationCarrierUserEmailProps>(
    ({ AutocompleteOrganizationCarrierUserEmailProps, control, defaultValue, name }) => {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ onChange, value: controlledValue }) => {
            const { inputValue, value } = controlledValue || {}
            const { consumerUser } = (value as IOrganizationCarrierUserEmailFragment) || {}
            const { user } = consumerUser || {}
            const { emailAddress } = user || {}

            const changeHandler: IAutocompleteOrganizationCarrierUserEmailProps['onChange'] = (
              _,
              newValue,
              reason
            ) => {
              /**
               * Prevent user from setting `value` as a string.
               * This reason is fired when the user presses Enter.
               */
              if (reason === 'createOption') {
                return
              }
              /**
               * If the user clears the input, reset the `value` and `inputValue`
               */
              if (reason === 'clear') {
                onChange({ inputValue: '', value: null })
                return
              }
              /**
               * Otherwise, update the `value`
               */
              onChange({ ...controlledValue, value: newValue })
            }

            const inputValueChangeHandler: IAutocompleteOrganizationCarrierUserEmailProps['onInputChange'] =
              (_, newInputValue) => {
                /**
                 * If the emailAddress from `value` is different from the `inputValue`, reset the `value`
                 */
                if (emailAddress !== newInputValue) {
                  onChange({ inputValue: newInputValue, value: null })
                  return
                }
                /**
                 * Otherwise, update the `inputValue`
                 */
                onChange({ ...controlledValue, inputValue: newInputValue })
              }

            return (
              <AutocompleteOrganizationCarrierUserEmail
                {...AutocompleteOrganizationCarrierUserEmailProps}
                onChange={changeHandler}
                onInputChange={inputValueChangeHandler}
                inputValue={inputValue}
                value={value}
              />
            )
          }}
        />
      )
    }
  )
