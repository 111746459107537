import React, { memo } from 'react'
import { IPillSelectProps } from 'dpl/components'
import CalendarIcon from 'dpl/icons/build/CalendarIcon'
import {
  ControlledPillSelect,
  IControlledPillSelectProps,
} from 'forms/components/ControlledPillSelect'
import { IAvailabilityType } from '../../types'

const OPTIONS: IPillSelectProps['options'] = [
  {
    label: 'Any Day',
    value: IAvailabilityType.anyDay,
  },
  {
    label: 'Days of the Week',
    value: IAvailabilityType.specificDays,
  },
]

export interface IControlledAvailabilitySelectProps
  extends Pick<IControlledPillSelectProps, 'control' | 'name'> {
  /**
   * @default 'ControlledAvailabilitySelect'
   */
  dataTest?: string
}

export const ControlledAvailabilitySelect = memo<IControlledAvailabilitySelectProps>(
  ({ control, dataTest = 'ControlledAvailabilitySelect', name }) => {
    return (
      <ControlledPillSelect
        control={control}
        name={name}
        PillSelectProps={{ dataTest, options: OPTIONS, icon: CalendarIcon, clearable: false }}
      />
    )
  }
)
