import { REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import * as yup from 'yup'
import { IVolumeTypes } from '../../../types'
import { DynamicVolumeSchema } from './DynamicVolumeSchema'

export const VolumeSchema = yup.object({
  /**
   * Selected volume type either flat or dynamic.
   */
  volumeType: yup
    .mixed<IVolumeTypes>()
    .default(IVolumeTypes.flatVolume)
    .required('You must select a volume type'),
  /**
   * Volume amount per month for flat volume type.
   */
  flatVolumePerMonth: yup
    .number()
    .min(1, `This field can't be left blank or set to 0.`)
    .nullable() // this is going to allow the field to pass if the volume type is not flat
    .when('volumeType', {
      is: IVolumeTypes.flatVolume,
      then: (schema: yup.Schema) => schema.required(REQUIRED_MESSAGE),
    }),
  /**
   * Volume amount per month for dynamic volume type.
   */
  dynamicVolumePerMonth: yup
    .array(DynamicVolumeSchema)
    .when('volumeType', {
      is: IVolumeTypes.dynamicVolume,
      then: (schema: yup.Schema) => schema.required(REQUIRED_MESSAGE),
      otherwise: (schema: yup.Schema) => schema.nullable().notRequired(),
    })
    .when('volumeType', {
      is: IVolumeTypes.dynamicVolume,
      then: (schema: yup.Schema) =>
        schema.test(
          'not-all-zero',
          'Please enter a value in all fields. (At least one field must have a value of 1 or more)',
          dynamicVolumePerMonth =>
            dynamicVolumePerMonth?.some(({ volume }: { volume: number }) => volume !== 0)
        ),
    }),
})

export interface IVolumeSchema extends yup.InferType<typeof VolumeSchema> {}
