import { IFactorsInput } from '#types/graphqlTypes'
import { TFactorsSchema } from '../../../schema/FactorsSchema'
import { getLocationFactor } from './getLocationFactor'

export const mapFactorsSchemaToInput = (factors: TFactorsSchema): IFactorsInput => {
  const { customer, deliveryLocation, equipment, pickupLocation } = factors || {}
  const { includeFactor: includeCustomerFactor, value: customerValue } = customer || {}
  const { id: organizationShipperId = '' } = customerValue || {}
  const { includeFactor: includeEquipmentFactor, value: equipmentKey } = equipment || {}

  const pickupGeoFactor: IFactorsInput['pickupGeoFactor'] = getLocationFactor(pickupLocation)
  const deliveryGeoFactor: IFactorsInput['deliveryGeoFactor'] = getLocationFactor(deliveryLocation)
  const customerFactor: IFactorsInput['customerFactor'] = includeCustomerFactor
    ? {
        organizationShipperId,
      }
    : null
  const equipmentFactor: IFactorsInput['equipmentFactor'] = includeEquipmentFactor
    ? {
        equipmentKey: equipmentKey || '',
        parentEquipmentId: '', // TODO: SQD2-601 Will remove once this is removed from BE
      }
    : null

  if (pickupGeoFactor && deliveryGeoFactor) {
    return {
      laneFactor: {
        deliveryGeoType: deliveryGeoFactor.geoType,
        deliveryValues: deliveryGeoFactor.values,
        pickupGeoType: pickupGeoFactor.geoType,
        pickupValues: pickupGeoFactor.values,
      },
      customerFactor,
      equipmentFactor,
      pickupGeoFactor: null,
      deliveryGeoFactor: null,
    }
  }

  return {
    pickupGeoFactor,
    deliveryGeoFactor,
    customerFactor,
    equipmentFactor,
    laneFactor: null,
  }
}
