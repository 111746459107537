import React, { useCallback, useState } from 'react'
import { useRequest } from 'network'
import { AuthenticationCard, EmailForm, TEmailFormSubmitHandler } from '../../../common'
import { MagicLinkSentCard } from '../../../common/components'
import { MagicLinkExpiredCard } from '../../../common/components/MagicLinkExpiredCard'
import { IRequestMagicLinkRequestBody } from '../../clients/discovery/types'
import { useAuthContext } from '../../hooks'

export interface IDiscoveryCardProps {
  /**
   * Url for redirecting after sign in
   * @example {discovery_redirect_url: 'http://...'}
   */
  redirectUrls: Pick<IRequestMagicLinkRequestBody, 'discovery_redirect_url'>
  /**
   * If user is requesting a new magic link in this view
   * @optional
   * @default false
   */
  isRequestingNewLink?: boolean
  /**
   * Href to login page
   * @example /login
   */
  loginHref?: string
  /**
   * Url to terms of service
   */
  termsOfServiceUrl: string
  /**
   * Callback to execute when user requests magic link
   */
  onMagicLinkRequest?: (email: string) => void
}

export function DiscoveryCard({
  isRequestingNewLink = false,
  loginHref = '',
  onMagicLinkRequest,
  redirectUrls,
  termsOfServiceUrl,
}: IDiscoveryCardProps) {
  const { authClient } = useAuthContext()
  const [sendDiscoveryLink] = useRequest({
    requestFn: authClient.discovery.requestMagicLink,
  })

  const [magicLinkSentTo, setMagicLinkSentTo] = useState<string | null>(null)

  const submitHandler = useCallback<TEmailFormSubmitHandler>(
    async ({ email }) => {
      onMagicLinkRequest?.(email)
      await sendDiscoveryLink({
        email,
        ...redirectUrls,
      })
      // We don't want to expose any user info so confirmation is shown regardless of response
      setMagicLinkSentTo(email)
    },
    [onMagicLinkRequest, redirectUrls, sendDiscoveryLink]
  )

  if (magicLinkSentTo) {
    return (
      <MagicLinkSentCard
        magicLinkSentTo={magicLinkSentTo}
        loginHref={loginHref}
        termsOfServiceUrl={termsOfServiceUrl}
      />
    )
  }

  if (isRequestingNewLink) {
    return <MagicLinkExpiredCard onSubmit={submitHandler} termsOfServiceUrl={termsOfServiceUrl} />
  }

  return (
    <AuthenticationCard
      title='Log in to Transfix'
      subheader='Enter your email to get started.'
      termsOfServiceUrl={termsOfServiceUrl}>
      <EmailForm buttonText='Continue' onSubmit={submitHandler} />
    </AuthenticationCard>
  )
}
