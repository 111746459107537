import * as yup from 'yup'

export const organizationShipperSchema = yup.object({
  id: yup.string(),
  createdAt: yup.string(),
  deprecatedAt: yup.string().nullable(),
  externalId: yup.string().nullable(),
  name: yup.string(),
})

export interface OrganizationShipperSchema extends yup.InferType<typeof organizationShipperSchema> {
  // using interface instead of type generally gives nicer editor feedback
}
