import { useEffect } from 'react'
import { OrganizationBySlugMatch } from '@stytch/vanilla-js'
import { useRequest } from 'network'
import { useAuthContext } from './useAuthContext'

export interface IUseOrganizationSlugResult {
  loading: boolean
  organization: Nullable<OrganizationBySlugMatch>
}

/**
 * @description Gets an organization by its slug (e.g., `console.transfix.io/login/{organization_slug}`).
 */
export const useOrganizationSlug = (
  organizationSlug: Nullable<string>
): IUseOrganizationSlugResult => {
  const { authClient } = useAuthContext()
  const [loadOrganization, { data, loading }] = useRequest({
    requestFn: authClient.organizations.getBySlug,
  })

  useEffect(() => {
    if (organizationSlug) {
      loadOrganization({
        organization_slug: organizationSlug,
      })
    }
  }, [loadOrganization, organizationSlug])

  return {
    loading,
    organization: data?.organization,
  }
}
