import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RadialSpinner } from 'dpl/components/RadialSpinner'
import { useRequest, IApiErrorResponse } from 'network'
import {
  PasswordOrMagicLinkLoginForm,
  IPasswordOrMagicLinkLoginFormProps,
  AuthenticationCard,
} from '../../../../common'
import { useAuthContext, useOrganizationAuthMethods } from '../../../hooks'
import { TRedirectUrls } from '../../types'
import { IBaseViewProps, TOrganization } from '../types'

export interface IMainViewProps extends IBaseViewProps {
  /**
   * Pre-filled email value
   */
  defaultEmail?: string
  /**
   * The callback used to set the default email value
   */
  setDefaultEmail: (email: string) => void
  /**
   * The organization the user is logging into
   */
  organization: TOrganization
  /**
   * Urls for redirecting after sign in, sign up, or resetting pw
   */
  redirectUrls: TRedirectUrls
  /**
   * Callback to execute when the magic link is requested
   */
  onMagicLinkRequest?: () => void
  /**
   * Callback to execute when the password entered is incorrect
   */
  onPasswordError?: (error?: IApiErrorResponse) => void
  /**
   * Callback to execute when the password entered is correct
   */
  onPasswordSuccess?: ({ email, organizationId }: { email: string; organizationId: string }) => void
  /**
   * Callback to execute when the user clicks reset password
   */
  onResetPassword?: () => void
}

export function MainView({
  defaultEmail = '',
  onMagicLinkRequest,
  onPasswordError,
  onPasswordSuccess,
  onResetPassword,
  onViewChange,
  organization,
  redirectUrls,
  setDefaultEmail,
  termsOfServiceUrl,
}: IMainViewProps) {
  const {
    allowed_auth_methods: allowedAuthMethods,
    auth_methods: authMethods,
    organization_id: organizationId,
    organization_name: organizationName,
  } = organization
  const {
    reset_password_redirect_url: resetPasswordRedirectUrl,
    sign_in_redirect_url: signInRedirectUrl,
    sign_up_redirect_url: signUpRedirectUrl,
  } = redirectUrls
  const { authClient, signInRedirectTo } = useAuthContext()
  const navigate = useNavigate()

  const { isMagicLinkAllowed, isPasswordAllowed } = useOrganizationAuthMethods({
    allowedAuthMethods,
    authMethods,
  })

  const [signInWithPassword, { loading: loadingSignInWithPassword }] = useRequest({
    requestFn: authClient.sessions.createWithPassword,
  })

  const [sendMagicLink] = useRequest({
    requestFn: authClient.magicLinks.sendSignInLink,
  })

  const [authenticate, { loading: loadingAuthenticate }] = useRequest({
    requestFn: authClient.sessions.authenticate,
  })

  const [sendForgotPasswordMagicLink] = useRequest({
    requestFn: authClient.passwords.requestMagicLink,
  })

  const magicLinkButtonClickHandler = useCallback(
    async (email: string) => {
      onMagicLinkRequest?.()

      setDefaultEmail(email)
      await sendMagicLink({
        organization_id: organizationId,
        email,
        sign_in_redirect_url: signInRedirectUrl,
        sign_up_redirect_url: signUpRedirectUrl,
      })

      // We don't want to expose any user info so confirmation is shown regardless of response
      onViewChange('magic_link_sent')
    },
    [
      onMagicLinkRequest,
      onViewChange,
      organizationId,
      sendMagicLink,
      setDefaultEmail,
      signInRedirectUrl,
      signUpRedirectUrl,
    ]
  )

  const passwordSubmitHandler: IPasswordOrMagicLinkLoginFormProps['onSubmit'] = useCallback(
    async ({ email, password }) => {
      const { data, error } = await signInWithPassword({
        organization_id: organizationId,
        email,
        password,
      })

      if (data) {
        await authenticate()
        navigate(signInRedirectTo, { replace: true })
        onPasswordSuccess?.({ email, organizationId })
        return { success: true }
      }
      onPasswordError?.(error)
      return error
    },
    [
      signInWithPassword,
      organizationId,
      authenticate,
      navigate,
      signInRedirectTo,
      onPasswordSuccess,
      onPasswordError,
    ]
  )

  const forgotPasswordHandler = useCallback(
    async (email: string) => {
      onResetPassword?.()
      setDefaultEmail(email)
      await sendForgotPasswordMagicLink({
        organization_id: organizationId,
        email,
        reset_password_redirect_url: `${resetPasswordRedirectUrl}?email=${encodeURIComponent(email)}`,
        sign_in_redirect_url: signInRedirectUrl,
      })
      // We don't want to expose any user info so confirmation is shown regardless of response
      onViewChange('forgot_password')
    },
    [
      onViewChange,
      organizationId,
      resetPasswordRedirectUrl,
      sendForgotPasswordMagicLink,
      setDefaultEmail,
      signInRedirectUrl,
      onResetPassword,
    ]
  )

  const disableFormSubmit = loadingSignInWithPassword || loadingAuthenticate

  if (loadingAuthenticate) {
    return <RadialSpinner position='absolute' />
  }

  return (
    <AuthenticationCard
      title='Log in to Transfix'
      subheader={`Select how you want to log in to your ${organizationName} account.`}
      termsOfServiceUrl={termsOfServiceUrl}>
      <PasswordOrMagicLinkLoginForm
        defaultEmail={defaultEmail}
        onForgotPasswordClick={forgotPasswordHandler}
        onSubmit={passwordSubmitHandler}
        isMagicLinkAllowed={isMagicLinkAllowed}
        onSendMagicLinkClick={magicLinkButtonClickHandler}
        isPasswordAllowed={isPasswordAllowed}
        disabled={disableFormSubmit}
      />
    </AuthenticationCard>
  )
}
