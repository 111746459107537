import React from 'react'
import { IFetchRatesInput } from '#types/graphqlTypes'
import { minMaxTempFormatter } from '#utils/minMaxTempFormatter'
import { Tooltip } from 'dpl/components/Tooltip'
import { FORMATS } from 'dpl/constants/datetime'
import { Link, makeStyles, Typography } from 'dpl/core'
import { DryVanIcon, InfoIcon, LaneArrowIcon, ReeferIcon } from 'dpl/icons'
import moment from 'moment-timezone'
import { TRatesResponse } from '../../types'
import { LaneInformationSubHeader } from './LaneInformationSubHeader'
import { getManuallyEnteredAppointment } from './utils'

export interface ILaneInformationHeaderProps {
  inputtedLaneInfo: IFetchRatesInput | null
  laneInformation: Exclude<TRatesResponse, 'spotCostModel'>
  openStopListModal: () => void
}

const useLaneInformationHeaderStyles = makeStyles(theme => ({
  stopsHeader: {
    display: 'flex',
    columnGap: theme.spacing(1.5),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  stop: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1.5),
  },
  reeferDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  detailsSubheader: {
    marginTop: theme.spacing(1),
  },
  arrowAndStops: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stopTime: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
}))

export function LaneInformationHeader({
  inputtedLaneInfo,
  laneInformation,
  openStopListModal,
}: ILaneInformationHeaderProps) {
  const { dropoffZip, manualDropoffTime, manualPickupTime, pickupZip } =
    getManuallyEnteredAppointment(inputtedLaneInfo)

  const classes = useLaneInformationHeaderStyles()
  const { equipmentKey, reeferTempMax, reeferTempMin, stops } = laneInformation || {}

  const isReefer = equipmentKey === '53_reefer'

  const hasIntermediateStops = stops.length > 2
  const numIntermediateStops = stops.length - 2
  const [originStop] = stops || []
  const destinationStop = stops[stops.length - 1]
  const {
    city: puCity,
    date: puDate,
    postalCode: puPostalCode,
    startTime: puTime,
    startTimeZone: puTimeZone,
    stateCode: puStateCode,
  } = originStop || {}
  const {
    city: delCity,
    date: delDate,
    postalCode: delPostalCode,
    startTime: delTime,
    startTimeZone: delTimeZone,
    stateCode: delStateCode,
  } = destinationStop || {}

  const formattedPuDatetime = moment
    .tz(`${puDate} ${puTime}`, puTimeZone || '')
    .format(FORMATS.monthDayYearTimeFormat12hUppercase)
  const formattedDelDatetime = moment
    .tz(`${delDate} ${delTime}`, delTimeZone || '')
    .format(FORMATS.monthDayYearTimeFormat12hUppercase)

  const formattedTemperatureRange = minMaxTempFormatter({
    minTemp: reeferTempMin ?? 0,
    maxTemp: reeferTempMax ?? 0,
  })

  const pickupPCLabel = pickupZip || puPostalCode
  const pickupTimeLabel = manualPickupTime || formattedPuDatetime

  const dropoffPCLabel = dropoffZip || delPostalCode
  const dropoffTimeLabel = manualDropoffTime || formattedDelDatetime

  const showPickupPcTT = !pickupZip
  const showPickupTimeTT = !manualPickupTime
  const showDropoffPcTT = !dropoffZip
  const showDropoffTimeTT = !manualDropoffTime

  return (
    <div data-test='lane-information-header'>
      <div className={classes.stopsHeader}>
        <div className={classes.stop}>
          <div>
            <div className={classes.stopTime}>
              <div>
                <Typography variant='h4' component='span'>
                  {puCity}, {puStateCode}{' '}
                </Typography>
                <Typography variant='subtitle2' color='textSecondary' component='span'>
                  ({pickupPCLabel})
                </Typography>
              </div>

              {showPickupPcTT && (
                <Tooltip color='dark' title='We generated an approximated ZIP for you.'>
                  <div className={classes.tooltip}>
                    <InfoIcon size='medium' color='coolGray5' />
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={classes.stopTime}>
              <Typography variant='subtitle2' color='textSecondary' marginTop={0.5}>
                {pickupTimeLabel}
              </Typography>

              {showPickupTimeTT && (
                <Tooltip color='dark' title='We generated an approximated pickup time for you.'>
                  <div className={classes.tooltip}>
                    <InfoIcon size='medium' color='coolGray5' />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={classes.arrowAndStops}>
            <LaneArrowIcon size='xlarge' />
            {hasIntermediateStops && (
              <Link
                onClick={openStopListModal}
                component='button'
                data-test='stop-list-modal-button'>
                {numIntermediateStops} Stop{numIntermediateStops > 1 ? 's' : ''}
              </Link>
            )}
          </div>
        </div>

        <div className={classes.stop}>
          <div>
            <div className={classes.stopTime}>
              <div>
                <Typography variant='h4' component='span'>
                  {delCity}, {delStateCode}{' '}
                </Typography>
                <Typography variant='subtitle2' color='textSecondary' component='span'>
                  ({dropoffPCLabel})
                </Typography>
              </div>
              {showDropoffPcTT && (
                <Tooltip color='dark' title='We generated an approximated ZIP for you.'>
                  <div className={classes.tooltip}>
                    <InfoIcon size='medium' color='coolGray5' />
                  </div>
                </Tooltip>
              )}
            </div>

            <div className={classes.stopTime}>
              <Typography variant='subtitle2' color='textSecondary' marginTop={0.5}>
                {dropoffTimeLabel}
              </Typography>

              {showDropoffTimeTT && (
                <Tooltip color='dark' title='We generated an approximated delivery appt for you.'>
                  <div className={classes.tooltip}>
                    <InfoIcon size='medium' color='coolGray5' />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>

          {isReefer ? (
            <div className={classes.reeferDetails}>
              <ReeferIcon size='xlarge' data-test='reefer-icon' />
              <Typography variant='subtitle2' color='textSecondary'>
                ({formattedTemperatureRange})
              </Typography>
            </div>
          ) : (
            <DryVanIcon size='xlarge' data-test='dry-van-icon' />
          )}
        </div>
      </div>
      <div className={classes.detailsSubheader}>
        <LaneInformationSubHeader laneInformation={laneInformation} />
      </div>
    </div>
  )
}
