import React from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { IRateSliderProps, RateSlider } from '../RateSlider'
import { RateSliderLabel } from '../RateSlider/RateSliderLabel'
import { LABEL_BY_PERCENTILE_INDEX } from '../RateSlider/constants'

type IBaseControllerProps = ControllerProps<React.ComponentType<IRateSliderProps>>

export interface IControlledCostPercentileSliderProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  SliderProps?: Omit<IRateSliderProps, 'onChange' | 'value' | 'marks' | 'min' | 'max'>
}

const getValueText = (value: number) => `${value}th`

/**
 * Percentiles from 10 to 90, in intervals of 5
 */
const marks = [...Array(17).keys()].map((n, i) => {
  const value = 10 + n * 5
  return {
    label: (
      <RateSliderLabel
        value={value}
        labelHeader={LABEL_BY_PERCENTILE_INDEX[i]}
        getValueText={getValueText}
        showSubLabelOnMobile
      />
    ),
    value,
  }
})

export function ControlledCostPercentileSlider({
  SliderProps,
  control,
  defaultValue,
  name,
}: IControlledCostPercentileSliderProps) {
  const { valueLabelDisplay = 'auto' } = SliderProps || {}

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        const changeHandler: IRateSliderProps['onChange'] = (_, value) => {
          onChange(value)
        }
        return (
          <RateSlider
            marks={marks}
            aria-label='Cost Strategy Percentile'
            getAriaValueText={getValueText}
            valueLabelFormat={getValueText}
            valueLabelDisplay={valueLabelDisplay}
            min={5}
            max={95}
            onChange={changeHandler}
            value={value}
            name={name}
            showSubLabelOnMobile
            step={null}
            showMinMaxValueLabels={false}
            {...SliderProps}
          />
        )
      }}
    />
  )
}
