import { MutableRefObject, useCallback, useState } from 'react'
import { useAnalytics } from '#hooks/useAnalytics'
import { useToastContext } from 'dpl/components/Toast'
import { GridApiPro, useGridApiRef } from 'dpl/core'
import { IRgCarrierRateFragment } from '../graphql/RGCarrierRateFragment'
import { useUpdateRoutingGuideCarrierRatesOrderMutation } from '../graphql/UpdateRoutingGuideCarrierRatesOrderMutation'
import { getUpdateCarrierRateSequencesInput } from '../utils'

export interface IUseCarrierRatesReorderProps {
  routingGuideLaneId: string
}

export interface IUseCarrierRatesReorderState {
  dataGridApiRef: MutableRefObject<GridApiPro>
  isReordering: boolean
  reorderLoading: boolean
  saveOrder: () => void
  toggleReordering: () => void
}

export const useCarrierRatesReorder = ({
  routingGuideLaneId,
}: IUseCarrierRatesReorderProps): IUseCarrierRatesReorderState => {
  const { openToast } = useToastContext()
  const dataGridApiRef = useGridApiRef()
  const { trackEvent } = useAnalytics()

  const [isReordering, setIsReordering] = useState<boolean>(false)

  const [updateCarrierRatesOrder, { loading: reorderLoading }] =
    useUpdateRoutingGuideCarrierRatesOrderMutation()

  const toggleReordering = useCallback(() => {
    setIsReordering(prev => !prev)
  }, [])

  const saveOrder = useCallback(async () => {
    const rows = dataGridApiRef.current.getSortedRows() as IRgCarrierRateFragment[]
    const input = getUpdateCarrierRateSequencesInput({ routingGuideLaneId, rows })

    const { data } = await updateCarrierRatesOrder({
      variables: {
        input,
      },
    })
    const { updateCarrierRateSequences } = data || {}
    const { routingGuideLane, errors = [] } = updateCarrierRateSequences || {}
    const { id } = routingGuideLane || {}

    if (errors.length) {
      trackEvent('Routing Guide', 'UPDATE_CARRIER_RATE_SEQUENCE_ERROR', {
        ...input,
        mutationErrors: errors,
      })
      return
    }

    if (id) {
      trackEvent('Routing Guide', 'UPDATE_CARRIER_RATE_SEQUENCE_SUCCESS', {
        ...input,
      })
      openToast({ toastMessage: 'Carrier order updated' })
      toggleReordering()
    }
  }, [
    dataGridApiRef,
    openToast,
    routingGuideLaneId,
    toggleReordering,
    trackEvent,
    updateCarrierRatesOrder,
  ])

  return {
    dataGridApiRef,
    isReordering,
    reorderLoading,
    saveOrder,
    toggleReordering,
  }
}
