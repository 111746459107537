import { IRule } from '#components/RatesEstimatedStrategyAccordion/types'
import { IAdjustmentRulesSectionProps } from '../../RatesEstimatedStrategyAccordion/components/AdjustmentRulesSection'
import { getAdjustmentRuleAmountLabel } from './getAdjustmentRuleAmountLabel'
import { getAdjustmentRuleIcon } from './getAdjustmentRuleIcon'
import { getAdjustmentRuleLabel } from './getAdjustmentRuleLabel'

interface IGetAdjustmentRuleLineItemProps {
  rule: IRule
  carrierRate: number
}

export const getAdjustmentRuleLineItem = ({
  carrierRate,
  rule,
}: IGetAdjustmentRuleLineItemProps): IAdjustmentRulesSectionProps['rules'][number] => {
  const { adjustmentValue, id, reason, ruleAction } = rule || {}
  return {
    key: id,
    Icon: getAdjustmentRuleIcon({
      adjustmentValue,
      ruleAction,
    }),
    label: getAdjustmentRuleLabel({
      adjustmentValue,
      reason,
      ruleAction,
    }),
    amount: getAdjustmentRuleAmountLabel({
      adjustmentValue,
      carrierRate,
      ruleAction,
    }),
  }
}
