import { ISortOrder } from '#types/graphqlTypes'
import { ICustomersDataGrid } from '../types'

export const SORT_OPTIONS_CONFIG: ICustomersDataGrid['sortOptions'] = [
  {
    label: 'Recently Added',
    value: ISortOrder.desc,
  },
  {
    label: 'Oldest',
    value: ISortOrder.asc,
  },
]
