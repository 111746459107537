import React, { memo, useCallback, useMemo } from 'react'
import { useSpotRatesControlPanelContext } from '#routes/spot-rates-control-panel/context'
import { IconButton } from 'dpl/components/IconButton'
import { IOverflowMenuProps, OverflowMenu } from 'dpl/components/OverflowMenu'
import ArchiveIcon from 'dpl/icons/build/ArchiveIcon'
import EditIcon from 'dpl/icons/build/EditIcon'
import MoreIcon from 'dpl/icons/build/MoreIcon'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { TAdjustmentRulesRow } from '../../types'
import { AdjustmentRuleOverflowMenuItem } from './AdjustmentRuleOverflowMenuItem'

export interface IAdjustmentRuleOverflowMenuProps {
  /**
   * @default 'AdjustmentRuleOverflowMenu'
   */
  dataTest?: string
  /**
   * Adjustment Rule row data
   */
  row: TAdjustmentRulesRow
}

export const AdjustmentRuleOverflowMenu = memo<IAdjustmentRuleOverflowMenuProps>(
  ({ dataTest = 'AdjustmentRuleOverflowMenu', row }) => {
    const { id, isRowDisabled = false } = row || {}

    const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()
    const { openAdjustmentRuleForm, openDeactivateRuleConfirmationModal } =
      useSpotRatesControlPanelContext()

    const editHandler = useCallback(() => {
      openAdjustmentRuleForm(row)
    }, [openAdjustmentRuleForm, row])

    const deactivateHandler = useCallback(() => {
      openDeactivateRuleConfirmationModal(row)
    }, [openDeactivateRuleConfirmationModal, row])

    const options: IOverflowMenuProps['options'] = useMemo(() => {
      return [
        {
          action: editHandler,
          label: 'edit',
          itemComponent: <AdjustmentRuleOverflowMenuItem icon={EditIcon} label='Edit' />,
        },
        {
          action: deactivateHandler,
          label: 'deactivate',
          itemComponent: (
            <AdjustmentRuleOverflowMenuItem color='error' icon={ArchiveIcon} label='Deactivate' />
          ),
        },
      ]
    }, [deactivateHandler, editHandler])

    return (
      <OverflowMenu
        alignment='right'
        dataTest={dataTest}
        dropdownId={`adjustment-rule-overflow-menu-${id}`}
        anchorEl={anchorEl}
        expanded={expanded}
        onClose={handleClose}
        onSelectMenuItem={handleClose}
        options={options}
        trigger={
          <IconButton
            dataTest={`${dataTest}-button`}
            disabled={isRowDisabled}
            label='overflow-trigger'
            Icon={MoreIcon}
            onClick={handleClick}
            size='large'
            color='secondary'
          />
        }
      />
    )
  }
)
