import React, { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext, useWatch, Controller } from 'react-hook-form'
import { IVolumeTypes } from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import { IRfpLaneInfoSchema, IStopSchema } from '#routes/contract-rfp/ContractRFPDetailPage/schema'
import { StopDragPreview } from '#routes/spot-rates-tool/components/LaneInformationForm/StopDragPreview'
import { IEquipmentKeys } from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import { INLINE_MESSAGE_VARIANTS, InlineMessage, TextFieldGroup } from 'dpl'
import DraggableList, { DraggableListItem } from 'dpl/components/DraggableList'
import { DISPLAY } from 'dpl/constants'
import { Box, makeStyles, Typography, Theme, Divider } from 'dpl/core'
import { useFlagsContext } from 'flags/src/FlagsContext'
import ControlledCheckbox from 'forms/components/ControlledCheckbox'
import ControlledHiddenInput from 'forms/components/ControlledHiddenInput'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import ControlledTextField from 'forms/components/ControlledTextField'
import moment from 'moment'
import {
  DROP_TYPE_OPTIONS,
  EQUIPMENT_MAPPER,
  VOLUME_FREQUENCY_OPTIONS,
} from '../../../LaneList/grid'
import { RateGenRadioGroup } from '../../../RateGenRadioGroup'
import { StopApptInputRow } from './StopAppIntRow'
import { VolumePreferenceForm } from './VolumePreferenceForm'
import { useDriveDistanceQuery } from './graphql/DrivingDistance'

const useStyles = makeStyles<Theme, { isDynamicVolumeEnabled: boolean }>(theme => ({
  lanes: {
    margin: theme.spacing(2, 0),
  },
  info: ({ isDynamicVolumeEnabled }) => ({
    margin: theme.spacing(3, 0),
    display: isDynamicVolumeEnabled ? 'flex' : 'grid',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    columnGap: theme.spacing(2),
    gap: theme.spacing(3),
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  }),
}))

const FORM_DEFAULT_VALUES = {
  stops: [
    {
      location: null,
      type: 'pickup',
    },
    {
      location: null,
      type: 'delivery',
    },
  ],
}

interface ILaneFormProps {
  isEditMode?: boolean
  contractStartAt: string
  contractEndAt: string
}

export function LaneForm({ contractEndAt, contractStartAt, isEditMode }: ILaneFormProps) {
  const { isFlagEnabled } = useFlagsContext()
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled
  const isDynamicVolumeEnabled = isFlagEnabled('rfp_dynamic_volume')

  const classes = useStyles({ isDynamicVolumeEnabled })
  const {
    clearErrors,
    control,
    errors,
    formState: { isDirty: isFormDirty },
    getValues,
    setValue,
    trigger,
  } = useFormContext<IRfpLaneInfoSchema>()

  const {
    fields: stopFields,
    insert: insertStop,
    move: moveStops,
    remove: removeStop,
  } = useFieldArray<IStopSchema, 'laneId'>({
    control,
    name: 'stops',
    keyName: 'laneId',
  })

  const stopFieldsLength = stopFields?.length ?? 0
  const draggableListItems = useMemo(() => {
    return stopFields.map(field => ({ ...field, id: field.laneId || '' }))
  }, [stopFields])

  const moveStopRowHandler = useCallback(
    ({ sourceIndex, targetIndex }) => {
      moveStops(sourceIndex, targetIndex)
      trigger('stops')
    },
    [moveStops, trigger]
  )

  const swapStopsHandler = useCallback(() => {
    const { stops } = getValues()
    const [{ location: origin }, { location: destination }] = stops || []

    setValue('stops[0].location', destination, { shouldValidate: true })
    setValue('stops[1].location', origin, { shouldValidate: true })
  }, [getValues, setValue])

  const watchedStops = useWatch<IRfpLaneInfoSchema['stops']>({
    control,
    name: 'stops',
    defaultValue: FORM_DEFAULT_VALUES.stops,
  })
  const watchedEquipment = useWatch<IRfpLaneInfoSchema['equipment']>({
    control,
    name: 'equipment',
  })
  const watchedVolume = useWatch<IRfpLaneInfoSchema['volume']>({
    control,
    name: 'volume',
  })

  const hasEquipmentTypeForRateGen =
    watchedEquipment === IEquipmentKeys.dry_van || watchedEquipment === IEquipmentKeys.reefer

  const calculatedMiles = useWatch<IRfpLaneInfoSchema['calculatedMiles']>({
    control,
    name: 'calculatedMiles',
  })

  const { data } = useDriveDistanceQuery({
    variables: {
      points:
        watchedStops
          ?.filter(stop => stop?.location)
          .map(({ location }) => ({
            latitude: location?.latitude ?? 0,
            longitude: location?.longitude ?? 0,
          })) || [],
    },
    skip: watchedStops.filter(stop => stop?.location).length < 2,
  })
  const { drivingDistance } = data || {}
  const { miles } = drivingDistance || {}

  const { volume: volumeErrors } = errors || {}
  const {
    dynamicVolumePerMonth: dynamicVolumePerMonthErrors,
    flatVolumePerMonth: flatVolumePerMonthError,
  } = volumeErrors || {}

  const selectVolumeTypeHandler = (volumeType: IVolumeTypes) => {
    if (volumeType === IVolumeTypes.dynamicVolume) {
      clearErrors('volume.flatVolumePerMonth')
    }
    if (volumeType === IVolumeTypes.flatVolume) {
      clearErrors('volume.dynamicVolumePerMonth')
    }
  }

  const contractStartDate = moment(contractStartAt).format('YYYY-MM')
  const contractEndDate = moment(contractEndAt).format('YYYY-MM')

  useEffect(() => {
    if (miles) {
      setValue('calculatedMiles', miles)
    }
  }, [miles])

  const stopFieldsLabels = useMemo(() => {
    let pickupCount = 0
    let deliveryCount = 0
    const labels =
      watchedStops?.map(stop => {
        const { type } = stop || {}
        if (type === 'delivery') {
          deliveryCount += 1
          return `Delivery ${deliveryCount}`
        }
        pickupCount += 1
        return `Pickup ${pickupCount}`
      }) || []
    if (pickupCount === 1) labels[0] = 'Origin'
    if (deliveryCount === 1) labels[labels.length - 1] = 'Destination'
    return labels
  }, [watchedStops])

  const addStopHandler = useCallback(() => {
    const index = stopFieldsLength - 1
    insertStop(index, {
      location: null,
      type: 'pickup',
    })
  }, [insertStop, stopFieldsLength])

  const removeStopHandler = useCallback(
    index => {
      if (index === stopFieldsLength - 1) {
        // If removing the last stop, make sure the one before it is a delivery stop
        setValue(`stops[${index - 1}].type`, 'delivery')
      }
      removeStop(index)
    },
    [removeStop, setValue, stopFieldsLength]
  )

  const { icon: EquipmentIcon } = EQUIPMENT_MAPPER[watchedEquipment as IEquipmentKeys] || {}

  const editMode = [isEditMode, isRfpRateGenEnabled, isFormDirty].every(Boolean)

  const addMode = [!isEditMode, isRfpRateGenEnabled].every(Boolean)

  return (
    <>
      <div className={classes.lanes}>
        <DraggableList onDrop={moveStopRowHandler} items={draggableListItems}>
          {draggableListItems.map((stop, index) => {
            const { id } = stop || {}
            const label = stopFieldsLabels[index]
            const currentStopValues = watchedStops?.[index]
            return (
              <DraggableListItem
                key={id}
                item={stop}
                index={index}
                DraggableListItemPreview={
                  <StopDragPreview location={currentStopValues?.location} label={label} />
                }>
                <StopApptInputRow
                  stopField={stop}
                  index={index}
                  currentStopValues={currentStopValues}
                  stopFieldsLength={stopFieldsLength}
                  onSwapStops={swapStopsHandler}
                  onAddStop={addStopHandler}
                  onRemoveStop={removeStopHandler}
                  stoplabel={label}
                />
              </DraggableListItem>
            )
          })}
        </DraggableList>
      </div>
      <div>
        <Typography variant='body2' color='textSecondary'>
          Calculated Distance:{' '}
          {calculatedMiles ? `${Math.round(calculatedMiles)} Miles` : DISPLAY.empty}
        </Typography>
      </div>
      <div className={classes.info}>
        <ControlledTextField
          name='customMiles'
          control={control}
          TextFieldProps={{
            label: 'Miles (Custom)',
            error: !!errors.customMiles,
            helperText: errors.customMiles?.message,
          }}
        />
        <ControlledSelectField
          name='equipment'
          control={control}
          SelectProps={{
            label: 'Equipment',
            options: Object.entries(EQUIPMENT_MAPPER).map(([key, { label }]) => ({
              value: key,
              label,
            })),
            InputProps: {
              startAdornment: EquipmentIcon && <EquipmentIcon size='xlarge' />,
            },
            error: !!errors.equipment,
            helperText: errors.equipment?.message,
          }}
        />
        <ControlledSelectField
          name='dropType'
          control={control}
          SelectProps={{
            label: 'Drop Type',
            options: DROP_TYPE_OPTIONS,
            error: !!errors.dropType,
            helperText: errors.dropType?.message,
          }}
        />
        {!isDynamicVolumeEnabled && (
          <TextFieldGroup>
            <ControlledTextField
              name='volume.flatVolumePerMonth'
              control={control}
              TextFieldProps={{
                label: 'Volume',
                error: !!errors.volume?.flatVolumePerMonth,
                helperText: errors.volume?.flatVolumePerMonth?.message,
              }}
            />
            <ControlledSelectField
              name='volume.volumeFrequency'
              control={control}
              SelectProps={{
                options: VOLUME_FREQUENCY_OPTIONS,
                error: !!errors.volume?.volumeFrequency,
                helperText: errors.volume?.volumeFrequency?.message,
              }}
            />
          </TextFieldGroup>
        )}
      </div>
      <ControlledCheckbox
        name='isTeam'
        control={control}
        CheckboxProps={{
          label: 'Teams Required',
        }}
      />
      {isDynamicVolumeEnabled && (
        <Box display='flex' flexDirection='column' gap={3} marginY={3}>
          <Divider />
          <Typography variant='subtitle1'>Volume Frequency</Typography>
          <ControlledSelectField
            name='volume.volumeFrequency'
            control={control}
            SelectProps={{
              options: VOLUME_FREQUENCY_OPTIONS,
              error: !!errors.volume?.volumeFrequency,
              helperText: errors.volume?.volumeFrequency?.message,
            }}
          />
          <VolumePreferenceForm
            control={control}
            dataTest='volume-preference-form-body'
            volume={watchedVolume}
            trigger={trigger}
            dynamicVolumePerMonthErrors={dynamicVolumePerMonthErrors}
            flatVolumePerMonthError={flatVolumePerMonthError}
            selectVolumeTypeHandler={selectVolumeTypeHandler}
            allowDynamic={watchedVolume?.volumeFrequency === 'monthly'}
            hasEquipmentTypeForRateGen={hasEquipmentTypeForRateGen}
            contractDuration={{
              contractStartDate,
              contractEndDate,
              contractLengthMonths: moment(contractEndDate).diff(
                moment(contractStartDate),
                'months'
              ),
            }}
          />
        </Box>
      )}
      {editMode && (
        <Box marginY={3}>
          <Controller
            defaultValue='yes'
            control={control}
            name='autoGenerateRates'
            render={({ onChange }) => <RateGenRadioGroup onChange={onChange} />}
          />
        </Box>
      )}
      {addMode && !hasEquipmentTypeForRateGen && (
        <Box marginY={2}>
          <InlineMessage
            message='Rates cannot be generated for Flatbed and Specialized equipment types. Please enter rates manually for these lanes.'
            type={INLINE_MESSAGE_VARIANTS.WARNING}
          />
        </Box>
      )}
      <ControlledHiddenInput name='calculatedMiles' control={control} />
    </>
  )
}
