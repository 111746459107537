import { useCallback, useState } from 'react'
import { ISettingVersion } from '#types/graphqlTypes'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { ISpotRatesAdjustmentRuleVersionFragment } from '../components/AdjustmentRulesSection/graphql/SpotRatesAdjustmentRuleVersionFragment'
import { useSpotRatesSettingsQuery } from '../graphql/SpotRatesSettings'

export interface IUseSpotRatesControlPanelState {
  currentRule: Nullable<ISpotRatesAdjustmentRuleVersionFragment>
  isAdjustmentRuleFormOpen: boolean
  openAdjustmentRuleForm: (rule?: ISpotRatesAdjustmentRuleVersionFragment) => void
  closeAdjustmentRuleForm: () => void
  isDeactivateRuleConfirmationModalOpen: boolean
  openDeactivateRuleConfirmationModal: (rule: ISpotRatesAdjustmentRuleVersionFragment) => void
  closeDeactivateRuleConfirmationModal: () => void
  spotRatesSettings: Pick<
    ISettingVersion,
    'createdAt' | 'defaultCostPercentile' | 'defaultMarginPremium' | 'maxMarginPremium'
  > & {
    loading: boolean
  }
}

export const useSpotRatesControlPanel = (): IUseSpotRatesControlPanelState => {
  const [currentRule, setCurrentRule] =
    useState<Nullable<ISpotRatesAdjustmentRuleVersionFragment>>(null)

  const { data, loading } = useSpotRatesSettingsQuery()
  const { spotRatesOrganizationSetting } = data || {}
  const { activeSettingVersion } = spotRatesOrganizationSetting || {}
  const {
    createdAt = '',
    defaultCostPercentile,
    defaultMarginPremium,
    maxMarginPremium,
  } = activeSettingVersion || {}

  const {
    modalState: {
      adjustmentRuleForm: isAdjustmentRuleFormOpen,
      deactivateRuleConfirmationModal: isDeactivateRuleConfirmationModalOpen,
    },
    toggleModal,
  } = useModalState({
    adjustmentRuleForm: false,
    deactivateRuleConfirmationModal: false,
  })

  const openAdjustmentRuleForm = useCallback(
    (rule?: ISpotRatesAdjustmentRuleVersionFragment) => {
      setCurrentRule(rule || null)
      toggleModal('adjustmentRuleForm', true)
    },
    [toggleModal]
  )

  const closeAdjustmentRuleForm = useCallback(() => {
    setCurrentRule(null)
    toggleModal('adjustmentRuleForm', false)
  }, [toggleModal])

  const openDeactivateRuleConfirmationModal = useCallback(
    (rule: ISpotRatesAdjustmentRuleVersionFragment) => {
      setCurrentRule(rule)
      toggleModal('deactivateRuleConfirmationModal', true)
    },
    [toggleModal]
  )

  const closeDeactivateRuleConfirmationModal = useCallback(() => {
    setCurrentRule(null)
    toggleModal('deactivateRuleConfirmationModal', false)
  }, [toggleModal])

  return {
    currentRule,
    isAdjustmentRuleFormOpen,
    openAdjustmentRuleForm,
    closeAdjustmentRuleForm,
    isDeactivateRuleConfirmationModalOpen,
    openDeactivateRuleConfirmationModal,
    closeDeactivateRuleConfirmationModal,
    spotRatesSettings: {
      createdAt,
      defaultCostPercentile,
      defaultMarginPremium,
      maxMarginPremium,
      loading,
    },
  }
}
