import { FieldError } from 'react-hook-form'
import { TPasswordValidationState, TPasswordRequirementKeys } from '../types'

export const INITIAL_STATE: TPasswordValidationState = {
  letters: false,
  numbers: false,
  special: false,
  length: false,
  score: false,
}

const FULL_REQUIREMENTS: TPasswordValidationState = {
  letters: true,
  numbers: true,
  special: true,
  length: true,
  score: true,
}

interface IGetPasswordValidationStateArgs {
  /**
   * Password value
   */
  value?: string
  /**
   * Password form validation errors
   * @example
   * {
   *   "types": {
   *      "min": "Password must be at least 8 characters.",
   *      "matches": ["letters", "numbers"]
   *   }
   * }
   */
  errors?: FieldError
  /**
   * Password strength score from Stytch
   * @example 2
   */
  score?: number
}

/**
 * @returns an object indicating which password requirements are met.
 */
export const getPasswordValidationState = ({
  errors,
  score = 0,
  value,
}: IGetPasswordValidationStateArgs): TPasswordValidationState => {
  if (!value) return { ...INITIAL_STATE }

  const state = { ...FULL_REQUIREMENTS }
  if (score <= 2) state.score = false
  if (!errors) return state

  const { types: { matches = [], min } = {} } = errors
  if (min) {
    state.length = false
  }
  const matchErrors = Array.isArray(matches) ? matches : [matches]
  matchErrors.forEach(match => {
    state[match as TPasswordRequirementKeys] = false
  })

  return state
}
