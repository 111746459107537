import moment from 'moment-timezone'
import * as yup from 'yup'

function dueDateSameOrAfterToday(this: yup.TestContext) {
  const { parent } = this
  const { dueDate } = parent
  const today = moment().startOf('day')

  if (!dueDate) {
    return true
  }

  return moment(dueDate).isSameOrAfter(today)
}

export const startNewRoundFormSchema = yup.object({
  submittedDate: yup.string().nullable(),
  dueDate: yup
    .string()
    .test('after-today', 'This due date is in the past.', dueDateSameOrAfterToday)
    .nullable(),
  state: yup.object({
    value: yup.string(),
  }),
})

export interface StartNewRoundFormSchema extends yup.InferType<typeof startNewRoundFormSchema> {
  // using interface instead of type generally gives nicer editor feedback
}
