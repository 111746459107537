import React, { useCallback, useState } from 'react'
import {
  ITabsProps,
  Tabs,
  IOrganizationProfileHeaderProps,
  OrganizationProfileHeader,
  OrganizationAppCard,
  IOrganizationAppCardProps,
} from 'dpl/components'
import { Grid, makeStyles } from 'dpl/core'
import { OpenInNewTabIcon } from 'dpl/icons'
import { PageTemplate } from 'dpl/templates'

const TABS: ITabsProps['tabList'] = [{ tabId: 'overview', label: 'Overview' }]

export interface IOrganizationProfileProps extends IOrganizationProfileHeaderProps {
  /**
   * If the current user is an admin, they will have access to edit the organization profile.
   * @default false
   */
  isAdmin: boolean
  /**
   * A list of applications the organization uses.
   * @default []
   */
  apps: IOrganizationAppCardProps[]
}

const useOrganizationProfileStyles = makeStyles(theme => ({
  tabContent: {
    padding: theme.spacing(1, 2, 2),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2.25, 3, 3),
    },
  },
}))

const { VITE_ADMIN_CONSOLE_URL } = import.meta.env

export function OrganizationProfile({
  isAdmin = false,
  apps = [],
  orgActiveDate,
  orgAdminNames,
  orgName,
  orgImgSrc,
}: IOrganizationProfileProps) {
  const classes = useOrganizationProfileStyles()
  const [currentTab, setCurrentTab] = useState<string>('overview')

  const changeTabHandler: ITabsProps['onChange'] = useCallback(newActiveTabId => {
    setCurrentTab(newActiveTabId)
  }, [])

  const goToAdminConsole = useCallback(() => {
    window.open(VITE_ADMIN_CONSOLE_URL, '_blank')?.focus()
  }, [])

  return (
    <PageTemplate
      pageTitle='Organization'
      documentTitle='Organization Profile'
      PageBodyTitleProps={{
        actions: [
          {
            label: 'Admin Console',
            onClick: goToAdminConsole,
            buttonProps: { disabled: !isAdmin, startIcon: <OpenInNewTabIcon /> },
          },
        ],
      }}
      noPadding>
      <OrganizationProfileHeader
        orgName={orgName}
        orgImgSrc={orgImgSrc}
        orgAdminNames={orgAdminNames}
        orgActiveDate={orgActiveDate}
        status='active'
      />
      <Tabs activeTabId={currentTab} onChange={changeTabHandler} tabList={TABS}>
        <div className={classes.tabContent}>
          {currentTab === 'overview' && (
            <Grid container>
              {apps.map(app => (
                <Grid key={app.appName} item xs={12}>
                  <OrganizationAppCard {...app} dataTest='org-app-card' />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Tabs>
    </PageTemplate>
  )
}
