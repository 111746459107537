import { ILatLonPosition } from '../types'

export interface Transition {
  delay?: number
  duration?: number
}

type ExpressionName =
  // Types
  | 'array'
  | 'boolean'
  | 'collator'
  | 'format'
  | 'literal'
  | 'number'
  | 'object'
  | 'string'
  | 'to-boolean'
  | 'to-color'
  | 'to-number'
  | 'to-string'
  | 'typeof'
  // Feature data
  | 'feature-state'
  | 'geometry-type'
  | 'id'
  | 'line-progress'
  | 'properties'
  // Lookup
  | 'at'
  | 'get'
  | 'has'
  | 'length'
  // Decision
  | '!'
  | '!='
  | '<'
  | '<='
  | '=='
  | '>'
  | '>='
  | 'all'
  | 'any'
  | 'case'
  | 'match'
  | 'coalesce'
  // Ramps, scales, curves
  | 'interpolate'
  | 'interpolate-hcl'
  | 'interpolate-lab'
  | 'step'
  // Variable binding
  | 'let'
  | 'var'
  // String
  | 'concat'
  | 'downcase'
  | 'is-supported-script'
  | 'resolved-locale'
  | 'upcase'
  // Color
  | 'rgb'
  | 'rgba'
  // Math
  | '-'
  | '*'
  | '/'
  | '%'
  | '^'
  | '+'
  | 'abs'
  | 'acos'
  | 'asin'
  | 'atan'
  | 'ceil'
  | 'cos'
  | 'e'
  | 'floor'
  | 'ln'
  | 'ln2'
  | 'log10'
  | 'log2'
  | 'max'
  | 'min'
  | 'pi'
  | 'round'
  | 'sin'
  | 'sqrt'
  | 'tan'
  // Zoom, Heatmap
  | 'zoom'
  | 'heatmap-density'

type Expression = [ExpressionName, ...any[]]
export interface StyleFunction {
  stops?: any[][]
  property?: string
  base?: number
  type?: 'identity' | 'exponential' | 'interval' | 'categorical'
  default?: any
  colorSpace?: 'rgb' | 'lab' | 'hcl'
}
export interface LinePaint {
  'line-opacity'?: number | StyleFunction | Expression
  'line-opacity-transition'?: Transition
  'line-color'?: string | StyleFunction | Expression
  'line-color-transition'?: Transition
  'line-translate'?: number[] | Expression
  'line-translate-transition'?: Transition
  'line-translate-anchor'?: 'map' | 'viewport'
  'line-width'?: number | StyleFunction | Expression
  'line-width-transition'?: Transition
  'line-gap-width'?: number | StyleFunction | Expression
  'line-gap-width-transition'?: Transition
  'line-offset'?: number | StyleFunction | Expression
  'line-offset-transition'?: Transition
  'line-blur'?: number | StyleFunction | Expression
  'line-blur-transition'?: Transition
  'line-dasharray'?: number[] | Expression
  'line-dasharray-transition'?: Transition
  'line-pattern'?: string | Expression
  'line-pattern-transition'?: Transition
  'line-gradient'?: Expression
}

export const PREDICTED_LINE_PAINT: LinePaint = {
  'line-color': '#0D0E10',
  'line-width': 2,
  'line-blur': 1,
  'line-opacity': 1,
  'line-dasharray': [2, 4],
}

export const PREVIOUS_LINE_PAINT: LinePaint = {
  'line-color': '#697684',
  'line-width': 2,
}

export const LINE_HIGHLIGHTED_PAINT: LinePaint = {
  'line-color': '#0D0E10',
  'line-width': 5,
  'line-opacity': 1,
  'line-opacity-transition': { duration: 1 },
}

export const NORTH_AMERICA_BOUNDS: ILatLonPosition[] = [
  { lat: 13.78, lon: -135.59 },
  { lat: 55.54, lon: -50.89 },
]
