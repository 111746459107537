import { IFetchRatesInput, IStopInput } from '#types/graphqlTypes'
import { FORMATS } from 'dpl/constants/datetime'
import moment from 'moment-timezone'
import { LaneInformationFormSchema } from './laneInformationFormSchema'

const multiplierByScale: Record<string, (amount: number) => number> = {
  days: (amount: number) => amount * 24 * 60,
  hours: (amount: number) => amount * 60,
  mins: (amount: number) => amount,
}

interface MapSchemaToInputProps {
  formData: LaneInformationFormSchema
}

export function mapSchemaToInput({ formData }: MapSchemaToInputProps): IFetchRatesInput {
  const {
    advancedInputs,
    equipmentKey,
    stops = [],
    temperature,
    weight,
    pickupDate,
    pickupTime,
    deliveryDate,
    deliveryTime,
    customer,
  } = formData
  const {
    assignmentLeadTime,
    creationLeadTime,
    dropTrailerDelivery,
    dropTrailerPickup,
    palletCount,
    teamRequired,
  } = advancedInputs
  const { max, min } = temperature ?? {}
  const { amount: creationAmount, scale: creationScale } = creationLeadTime ?? {}
  const { amount: assignmentAmount, scale: assignmentScale } = assignmentLeadTime ?? {}
  const isReefer = equipmentKey === '53_reefer'

  const originDropTrailer = dropTrailerPickup ?? false
  const destinationDropTrailer = dropTrailerDelivery ?? false

  const pickupDateValue = pickupDate
  const pickupTimeValue = pickupTime
  const deliveryDateValue = deliveryDate
  const deliveryTimeValue = deliveryTime

  const { id: customerId } = customer ?? {}

  return {
    customerId: customerId || null,
    equipmentKey,
    isTeam: teamRequired ?? false,
    palletCount: palletCount ?? 0,
    weight: weight ?? 35000,
    creationLeadTimeMinutes:
      !!creationAmount || creationAmount === 0
        ? multiplierByScale[creationScale!](creationAmount!)
        : null,
    assignmentLeadTimeMinutes:
      !!assignmentAmount || assignmentAmount === 0
        ? multiplierByScale[assignmentScale!](assignmentAmount!)
        : null,
    reeferTempMax: isReefer ? max ?? 45 : null,
    reeferTempMin: isReefer ? min ?? 45 : null,
    stops: stops.map((stop, index) => {
      const { location, type } = stop || {}
      const isOrigin = index === 0
      const isDestination = index === (stops?.length ?? 0) - 1
      const stopInfo: IStopInput = {
        stopSequence: index + 1,
        stopType: type,
        /**
         * Avoid sending postal code if result is city. This
         * will ensure consistent behavior based on result type.
         */
        postalCode: location?.resultType === 'City' ? null : location?.postalCode,
        city: location?.city,
        stateCode: location?.stateCode,
        startTimeZone: location?.timezone,
        stopLatitude: location?.latitude,
        stopLongitude: location?.longitude,
        /**
         * Following values can be set only for origin and destination.
         */
        isDropTrailer: false,
        date: null,
        startTime: null,
      }

      if (isOrigin) {
        stopInfo.isDropTrailer = originDropTrailer
        stopInfo.date = pickupDateValue ? moment(pickupDateValue).format(FORMATS.yearFirst) : null
        stopInfo.startTime = pickupTimeValue
          ? moment(pickupTimeValue).tz(location?.timezone!).format(FORMATS.militaryTimeFormat)
          : null
      } else if (isDestination) {
        stopInfo.isDropTrailer = destinationDropTrailer
        stopInfo.date = deliveryDateValue
          ? moment(deliveryDateValue).format(FORMATS.yearFirst)
          : null
        stopInfo.startTime = deliveryTimeValue
          ? moment(deliveryTimeValue).tz(location?.timezone!).format(FORMATS.militaryTimeFormat)
          : null
      }

      return stopInfo
    }),
  }
}
