import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigateBack = () => {
  const navigate = useNavigate()

  const navigateBack = useCallback(
    ({ path }: { path: string }) => {
      if (window.history.length >= 2) {
        navigate(-1)
      } else {
        navigate(path, { replace: true })
      }
    },
    [navigate]
  )

  return navigateBack
}
