import { RouteObject } from 'react-router-dom'
import { MODULES_BY_PERMISSION } from '#constants/modules'
import { DEFAULT_PROTECTED_ROUTES, ROUTES_BY_PERMISSION } from '#constants/routes'
import { IProductModules } from '#types/graphqlTypes'
import { IUserPermissions } from 'auth/common/context/PermissionsContext/types'

interface IGetUserEnabledRoutesProps {
  /**
   * Active modules for the org.
   */
  activeProductModules?: IProductModules[]
  /**
   * The users permissions.
   */
  userPermissions: Record<keyof IUserPermissions, boolean>
}

/**
 * Get the routes for the enabled user permissions and active product modules.
 *
 * @param userPermissions - The user permissions.
 * @returns The routes for the enabled permissions.
 *
 * @example getUserEnabledRoutes({ userPermissions: { 'routing_guide.read_app': true } })
 */
export const getUserEnabledRoutes = ({
  activeProductModules,
  userPermissions,
}: IGetUserEnabledRoutesProps): RouteObject[] => {
  const routesPermissions: (keyof IUserPermissions)[] = Object.keys(
    ROUTES_BY_PERMISSION
  ) as (keyof IUserPermissions)[]

  const userEnabledRoutes: RouteObject[] = []

  routesPermissions.forEach(permission => {
    const module = MODULES_BY_PERMISSION[permission]
    const isModuleEnabled =
      module && activeProductModules ? activeProductModules.includes(module) : false
    if (!isModuleEnabled) {
      return
    }

    const isPermissionEnabled = userPermissions[permission]
    if (!isPermissionEnabled) {
      return
    }

    const permissionRoutes = ROUTES_BY_PERMISSION[permission]

    if (permissionRoutes) {
      permissionRoutes.forEach(route => {
        userEnabledRoutes.push(route)
      })
    }
  })

  return DEFAULT_PROTECTED_ROUTES.concat(userEnabledRoutes)
}
