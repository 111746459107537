import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#/constants'
import DataGrid, { DataGridIconCell, IDataGridProps } from 'dpl/components/DataGrid'
import { Card, CardContent, CardHeader } from 'dpl/core'
import RightArrowIcon from 'dpl/icons/build/RightArrowIcon'
import { PageTemplate } from 'dpl/templates'

interface IReportRow {
  name: string
  description: string
  id: string
}

const MOCKED_REPORTS: IReportRow[] = [
  {
    name: 'Cost Model Training',
    id: 'mocked-id',
    description:
      'Provides an overview of model training validation errors to help identify and address issues efficiently.',
  },
]

const COLUMNS: IDataGridProps<IReportRow>['columns'] = [
  { field: 'name', headerName: 'Report Name' },
  { field: 'description', headerName: 'Report Description', flex: 1 },
  {
    field: 'arrow',
    headerName: '',
    renderCell: () => <DataGridIconCell icon={RightArrowIcon} IconProps={{ size: 'medium' }} />,
  },
]

export function Reports() {
  const navigate = useNavigate()
  const rowClickHandler = useCallback(
    params => {
      const { row: { id } = {} } = params || {}
      navigate({ pathname: `${PROTECTED_PATHS.reports}/${id}` })
    },
    [navigate]
  )

  return (
    <PageTemplate pageTitle='Reports'>
      <Card>
        <CardHeader title='All Reports' />
        <CardContent>
          <DataGrid
            columns={COLUMNS}
            rows={MOCKED_REPORTS}
            autosizeOnMount
            onRowClick={rowClickHandler}
          />
        </CardContent>
      </Card>
    </PageTemplate>
  )
}
