import React from 'react'
import { DataGridFilterCustomer } from '#components/DataGridFilterCustomer/DataGridFilterCustomer'
import { DataGridFilterOrganizationUser } from '#components/DataGridFilterOrganizationUser'
import { DataGridFilterOriginDestinationLocation } from '#components/DataGridFilterOriginDestinationLocation/DataGridFilterOriginDestinationLocation'
import { IEquipmentKeys } from '#types/graphqlTypes'
import { IRoutingGuideLanesDataGrid } from '../types'

export const FILTERS_OPTIONS_CONFIG: IRoutingGuideLanesDataGrid['filtersConfig'] = {
  displayId: {
    type: 'textInput',
    placeholder: 'Lane ID',
    label: 'Lane ID',
  },
  location: {
    type: 'custom',
    FilterComponent: DataGridFilterOriginDestinationLocation,
  },
  laneManager: {
    type: 'custom',
    FilterComponent: props => (
      <DataGridFilterOrganizationUser
        {...props}
        AutocompleteOrganizationUserProps={{
          placeholder: 'Lane Manager',
          organizationUserPermissions: ['routing_guide.create_lane'],
        }}
      />
    ),
  },
  customer: {
    type: 'custom',
    FilterComponent: DataGridFilterCustomer,
  },
  equipments: {
    type: 'multiSelect',
    label: 'Equipment',
    options: [
      { label: 'Dry Van', value: IEquipmentKeys.dry_van },
      { label: 'Reefer', value: IEquipmentKeys.reefer },
      { label: 'Flatbed', value: IEquipmentKeys.open_deck },
      { label: 'Specialized', value: IEquipmentKeys.specialized },
    ],
  },
}
