import { IRuleTypeEnum } from '#types/rates'
import * as yup from 'yup'
import { ADJUSTMENT_TYPES } from '../../types'
import { emptyStringToNull } from '../utils'
import { valueNotZero } from '../utils/valueNotZero'

const REQUIRED_PERCENTAGE_MESSAGE = 'You must enter a percentage amount.'
const MIN_NEGATIVE_100_MESSAGE = 'Value cannot be less than -100%.'
const MAX_100_MESSAGE = 'Value cannot exceed 100%.'

export const RateStrategySchema = yup.object({
  adjustmentType: yup.string<ADJUSTMENT_TYPES>().required('You must select an adjustment type.'),
  costRuleType: yup
    .string<Exclude<IRuleTypeEnum, IRuleTypeEnum.doNotBid>>()
    .nullable()
    .when('adjustmentType', {
      is: ADJUSTMENT_TYPES.cost,
      then: (schema: yup.Schema) => schema.required('You must select a cost strategy.'),
    }),
  costBasePercentile: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .when(['costRuleType', 'adjustmentType'], ([costRuleType, adjustmentType], schema) => {
      if (adjustmentType === ADJUSTMENT_TYPES.cost && costRuleType === IRuleTypeEnum.base) {
        return schema.required('You must select a cost percentile.')
      }
      return schema
    }),
  costAdditiveShiftPercentage: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .when(['costRuleType', 'adjustmentType'], ([costRuleType, adjustmentType], schema) => {
      if (adjustmentType === ADJUSTMENT_TYPES.cost && costRuleType === IRuleTypeEnum.additive) {
        return schema
          .required(REQUIRED_PERCENTAGE_MESSAGE)
          .test('valueNotZero', REQUIRED_PERCENTAGE_MESSAGE, valueNotZero)
          .min(-100, MIN_NEGATIVE_100_MESSAGE)
          .max(100, MAX_100_MESSAGE)
      }
      return schema
    }),
  marginRuleType: yup
    .string<IRuleTypeEnum>()
    .nullable()
    .when('adjustmentType', {
      is: ADJUSTMENT_TYPES.margin,
      then: (schema: yup.Schema) => schema.required('You must select a margin strategy.'),
    }),
  marginBasePercentage: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .when(['marginRuleType', 'adjustmentType'], ([marginRuleType, adjustmentType], schema) => {
      if (adjustmentType === ADJUSTMENT_TYPES.margin && marginRuleType === IRuleTypeEnum.base) {
        return schema
          .required(REQUIRED_PERCENTAGE_MESSAGE)
          .min(1, 'Value must be at least 1%.')
          .max(100, MAX_100_MESSAGE)
      }
      return schema
    }),
  marginAdditivePercentage: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .when(['marginRuleType', 'adjustmentType'], ([marginRuleType, adjustmentType], schema) => {
      if (adjustmentType === ADJUSTMENT_TYPES.margin && marginRuleType === IRuleTypeEnum.additive) {
        return schema
          .required(REQUIRED_PERCENTAGE_MESSAGE)
          .test('valueNotZero', REQUIRED_PERCENTAGE_MESSAGE, valueNotZero)
          .min(-100, MIN_NEGATIVE_100_MESSAGE)
          .max(100, MAX_100_MESSAGE)
      }
      return schema
    }),
})

export type TRateStrategySchema = yup.InferType<typeof RateStrategySchema>
