import React, { memo, useCallback, useState } from 'react'
import { SubmitHandler, SubmitErrorHandler } from 'react-hook-form'
import { getApolloErrorMessages } from 'dpl/components/ApolloErrorMessages'
import { FormModal, IModalAction, IFormModalProps } from 'dpl/components/Modals'
import Box from 'dpl/core/Box'
import { useAnalytics } from '../../../../../hooks/useAnalytics'
import { IError } from '../../../../../types/graphqlTypes'
import { RoutingGuideLaneFormBody } from './components/RoutingGuideLaneFormBody'
import { useCreateRoutingGuideLaneMutation } from './graphql/CreateRoutingGuideLaneMutaton'
import { useRoutingGuideLaneForm } from './hooks'
import { TRoutingGuideLaneSchema } from './schema'
import { getCreateRoutingGuideLaneInput } from './utils'

export interface IRoutingGuideLaneFormModalProps extends Pick<IFormModalProps, 'open' | 'onClose'> {
  /**
   * Callback to be called when the routing guide is created successfully
   * @param newRoutingGuideLaneId The id of the newly created RoutingGuideLane
   * @returns
   */
  onSuccess: (newRoutingGuideLaneId: string) => void
  /**
   * @default 'RoutingGuideLaneFormModal'
   */
  dataTest?: string
}

export const RoutingGuideLaneFormModal = memo<IRoutingGuideLaneFormModalProps>(
  ({ dataTest = 'RoutingGuideLaneFormModal', onClose, onSuccess, open }) => {
    const [mutationErrors, setMutationErrors] = useState<IError[]>([])

    const { trackEvent } = useAnalytics()
    const {
      control,
      errors: formValidationErrors,
      handleSubmit,
      setValue,
    } = useRoutingGuideLaneForm({})
    const [createRGLane, { error: apolloErrors, loading }] = useCreateRoutingGuideLaneMutation()

    const errorMessages = getApolloErrorMessages({ apolloErrors, mutationErrors })

    const closeHandler = useCallback(() => {
      setMutationErrors([])
      onClose()
    }, [onClose])

    const submitHandler = useCallback<SubmitHandler<TRoutingGuideLaneSchema>>(
      async formValues => {
        setMutationErrors([])
        const { data } = await createRGLane({
          variables: {
            input: getCreateRoutingGuideLaneInput({ formValues }),
          },
        })
        const { createRoutingGuideLane } = data || {}
        const { routingGuideLane, errors = [] } = createRoutingGuideLane || {}
        const { id: newRoutingGuideLaneId } = routingGuideLane || {}

        if (errors.length) {
          trackEvent('Routing Guide', 'CREATE_ROUTING_GUIDE_LANE_ERROR', {
            formValues,
            mutationErrors: errors,
          })
          setMutationErrors(errors)
        }

        if (newRoutingGuideLaneId) {
          trackEvent('Routing Guide', 'CREATE_ROUTING_GUIDE_LANE_SUCCESS', {
            formValues,
            newRoutingGuideLaneId,
          })
          onSuccess(newRoutingGuideLaneId)
        }
      },
      [createRGLane, onSuccess, trackEvent]
    )

    const formInvalidHandler = useCallback<SubmitErrorHandler<TRoutingGuideLaneSchema>>(
      validationErrors => {
        trackEvent('Routing Guide', 'CREATE_ROUTING_GUIDE_LANE_FORM_VALIDATION_ERROR', {
          formErrors: validationErrors,
        })
      },
      [trackEvent]
    )

    const actions: IModalAction[] = [
      {
        label: 'Cancel',
        disabled: loading,
        type: 'dismiss',
        dataTest: `${dataTest}-cancel`,
        action: closeHandler,
      },
      {
        label: 'Add Lane',
        disabled: loading,
        type: 'confirm',
        dataTest: `${dataTest}-submit`,
        action: handleSubmit(submitHandler, formInvalidHandler),
      },
    ]

    return (
      <FormModal
        title='Add New Lane'
        actions={actions}
        dataTest={dataTest}
        open={open}
        onClose={closeHandler}
        errors={errorMessages}
        includeCloseIconButton>
        <Box mt={1}>
          <RoutingGuideLaneFormBody
            control={control}
            errors={formValidationErrors}
            setValue={setValue}
            dataTest={dataTest}
          />
        </Box>
      </FormModal>
    )
  }
)
