import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAnalytics } from '#hooks/useAnalytics'
import { usePermissionsContext } from 'auth/common/context'
import { useToastContext } from 'dpl/components/Toast'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags/src/FlagsContext'
import compact from 'lodash/compact'
import { useActivateCustomerMutation } from '../graphql/ActivateCustomer'
import { ICustomerFragment } from '../graphql/CustomerFragment'
import { useDeactivateCustomerMutation } from '../graphql/DeactivateCustomer'
import { useRoutingGuideLanesCountQuery } from '../graphql/RoutingGuideLanesCount'
import { useSearchCustomersQuery } from '../graphql/SearchCustomers'

const ERROR_MESSAGE_REACTIVATE = 'Error reactivating customer'
const ERROR_MESSAGE_DEACTIVATE = 'Error deactivating customer'

export const useCustomersActions = () => {
  const { trackEvent } = useAnalytics()

  const [activateCustomer] = useActivateCustomerMutation()
  const [deactivateCustomer] = useDeactivateCustomerMutation()

  const { id: customerIdParam = '' } = useParams()
  const { openToast } = useToastContext()
  const { isFlagEnabled } = useFlagsContext()
  const { userPermissions } = usePermissionsContext()

  const isUpdateCustomerStatusEnabled = useMemo(
    () => isFlagEnabled('update_customer_status'),
    [isFlagEnabled]
  )

  const isPermittedToReactivateCustomer = useMemo(
    () => userPermissions['shipper_management.update_organization_shipper'],
    [userPermissions]
  )

  const { modalState: reactivateModalState, toggleModal } = useModalState({
    isAccessRestrictedModalOpen: false,
    isFeatureUnavailableModalOpen: false,
  })

  const closeReactivateAccessRestrictedModal = () =>
    toggleModal('isAccessRestrictedModalOpen', false)

  const closeReactivateFeatureUnavailabledModal = () =>
    toggleModal('isFeatureUnavailableModalOpen', false)

  const {
    data: searchCustomersData,
    loading: isLoadingCustomersData,
    refetch: refetchSearchCustomers,
  } = useSearchCustomersQuery({
    fetchPolicy: 'cache-and-network',
  })
  const { searchOrganizationShippers } = searchCustomersData || {}
  const { nodes } = searchOrganizationShippers || {}
  const customerRows = compact<ICustomerFragment>(nodes)

  const deactivateCustomerHandler = async () => {
    const { data, errors } = await deactivateCustomer({
      variables: { input: { id: customerIdParam } },
    })

    const { deactivateOrganizationShipper } = data || {}
    const { organizationShipper } = deactivateOrganizationShipper || {}
    const { id: deactivateOrgShipperId } = organizationShipper || {}

    if (errors?.length) {
      openToast({
        toastMessage: ERROR_MESSAGE_DEACTIVATE,
        multiLine: true,
        toastType: 'alert',
      })

      trackEvent('Customer', 'DEACTIVATE_CUSTOMER_ERROR', {
        mutationErrors: errors,
        orgShipperId: customerIdParam,
      })
      return
    }
    if (deactivateOrgShipperId) {
      openToast({
        toastMessage: 'Customer successfully deactivated',
      })

      trackEvent('Customer', 'DEACTIVATE_CUSTOMER_SUCCESS', {
        orgShipperId: deactivateOrgShipperId,
      })
    }
  }

  const { data: rgCountData, loading: isRGCountLoading } = useRoutingGuideLanesCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: { customerId: customerIdParam },
    },
  })
  const { routingGuideLanes } = rgCountData || {}
  const { totalCount = 0 } = routingGuideLanes || {}
  const isCustomerAssignedRG = !!totalCount

  const reactivateCustomerHandler = async ({ id }: ICustomerFragment) => {
    if (!isUpdateCustomerStatusEnabled) {
      toggleModal('isFeatureUnavailableModalOpen', true)
      return
    }

    if (!isPermittedToReactivateCustomer) {
      toggleModal('isAccessRestrictedModalOpen', true)
      return
    }
    const { data, errors } = await activateCustomer({ variables: { input: { id } } })
    const { activateOrganizationShipper } = data || {}
    const { organizationShipper } = activateOrganizationShipper || {}
    const { id: activatedOrgShipperId } = organizationShipper || {}

    if (errors?.length) {
      openToast({
        toastMessage: ERROR_MESSAGE_REACTIVATE,
        multiLine: true,
        toastType: 'alert',
      })

      trackEvent('Customer', 'REACTIVATE_CUSTOMER_ERROR', {
        mutationErrors: errors,
        orgShipperId: id,
      })
      return
    }
    if (activatedOrgShipperId) {
      openToast({
        toastMessage: 'Customer successfully reactivated',
      })

      trackEvent('Customer', 'REACTIVATE_CUSTOMER_SUCCESS', {
        orgShipperId: activatedOrgShipperId,
      })
    }
  }
  return {
    reactivateCustomerHandler,
    deactivateCustomerHandler,
    customerRows,
    isCustomerAssignedRG,
    isRGCountLoading,
    isLoadingCustomersData,
    reactivateModalState,
    closeReactivateAccessRestrictedModal,
    closeReactivateFeatureUnavailabledModal,
    refetchSearchCustomers,
  }
}
