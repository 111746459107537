import { useCallback, useState } from 'react'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags'
import { IRgCarrierRateFragment } from '../graphql/RGCarrierRateFragment'

export interface IUseRoutingGuideDetailsModalsState {
  modalState: {
    AddCarrierFormModal: boolean
    DeactivateCarrierLaneRateModal: boolean
    EditCarrierLaneRateModal: boolean
    FeatureUnavailableModal: boolean
  }
  selectedCarrierRate: Nullable<IRgCarrierRateFragment>
  closeCarrierFormModal: () => void
  toggleAddCarrierFormModal: () => void
  toggleDeactivateCarrierLaneRateModal: (carrierRate?: IRgCarrierRateFragment) => void
  toggleEditCarrierLaneRateModal: (carrierRate?: IRgCarrierRateFragment) => void
  toggleFeatureUnavailableModal: () => void
}

export const useRoutingGuideDetailsModals = (): IUseRoutingGuideDetailsModalsState => {
  const { isFlagEnabled } = useFlagsContext()
  const isRGCarrierRateCreateEnabled = isFlagEnabled('routing_guide_carrier_rate_create')

  const [selectedCarrierRate, setSelectedCarrierRate] = useState<Nullable<IRgCarrierRateFragment>>()

  const { modalState, toggleModal } = useModalState({
    AddCarrierFormModal: false,
    DeactivateCarrierLaneRateModal: false,
    EditCarrierLaneRateModal: false,
    FeatureUnavailableModal: false,
  })

  const closeCarrierFormModal = useCallback(() => {
    if (modalState.AddCarrierFormModal) {
      toggleModal('AddCarrierFormModal', false)
    }
    if (modalState.EditCarrierLaneRateModal) {
      toggleModal('EditCarrierLaneRateModal', false)
    }
  }, [modalState.AddCarrierFormModal, modalState.EditCarrierLaneRateModal, toggleModal])

  const toggleAddCarrierFormModal = useCallback(() => {
    setSelectedCarrierRate(null)
    if (isRGCarrierRateCreateEnabled) {
      toggleModal('AddCarrierFormModal')
    } else {
      toggleModal('FeatureUnavailableModal')
    }
  }, [isRGCarrierRateCreateEnabled, toggleModal])

  const toggleDeactivateCarrierLaneRateModal = useCallback(
    (carrierRate?: IRgCarrierRateFragment) => {
      setSelectedCarrierRate(carrierRate)
      toggleModal('DeactivateCarrierLaneRateModal')
    },
    [toggleModal]
  )

  const toggleEditCarrierLaneRateModal = useCallback(
    (carrierRate?: IRgCarrierRateFragment) => {
      setSelectedCarrierRate(carrierRate)
      toggleModal('EditCarrierLaneRateModal')
    },
    [toggleModal]
  )

  const toggleFeatureUnavailableModal = useCallback(() => {
    toggleModal('FeatureUnavailableModal')
  }, [toggleModal])

  return {
    modalState,
    selectedCarrierRate,
    closeCarrierFormModal,
    toggleAddCarrierFormModal,
    toggleDeactivateCarrierLaneRateModal,
    toggleEditCarrierLaneRateModal,
    toggleFeatureUnavailableModal,
  }
}
