import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getDuration } from '#routes/contract-rfp/utils/utils'
import {
  IContractRfp,
  IContractRfpStates,
  IContractRfpVersion,
  IOrganizationShipper,
} from '#types/graphqlTypes'
import {
  brandColors,
  DatePicker,
  FormModal,
  INLINE_MESSAGE_VARIANTS,
  InlineMessage,
  RadioGroup,
  Tag,
  TAG_SIZE,
  TAG_STYLE,
} from 'dpl'
import { FORMATS } from 'dpl/constants'
import { Box, Collapse, makeStyles, Typography } from 'dpl/core'
import { CalendarIcon, CustomerIcon, LaneArrowIcon } from 'dpl/icons'
import moment from 'moment'

export interface ICurrentVersion
  extends Pick<IContractRfpVersion, 'dueAt' | 'number' | 'submittedAt'> {
  lanes: Pick<IContractRfpVersion['lanes'], 'totalCount'>
}
export interface IStateChangeRFP
  extends Pick<IContractRfp, 'startAt' | 'endAt' | 'name' | 'displayId' | 'id' | 'state'> {
  organizationShipper: Pick<IOrganizationShipper, 'name'>
  currentVersion: ICurrentVersion
}

export interface IStateChangeModalProps {
  newState: IContractRfpStates
  rfp: IStateChangeRFP
  onClose: () => void
  onConfirm: (
    item: IStateChangeRFP,
    columnId: IContractRfpStates,
    submittedAt?: string,
    dueAt?: string
  ) => void
}

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  rfpInfo: {
    padding: theme.spacing(2),
    border: `1px solid ${brandColors.coolGray3}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  iconLabel: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  updateContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
  warning: {
    marginTop: theme.spacing(2),
  },
}))

const COMPONENT_MAP: Record<
  IContractRfpStates,
  {
    Badge: () => JSX.Element
    description: (params: { roundNumber: number }) => string
  }
> = {
  [IContractRfpStates.planning]: {
    Badge: () => (
      <Tag
        size={TAG_SIZE.xsmall}
        label={<Typography variant='caption'>Plan</Typography>}
        tagStyle={TAG_STYLE.accentBlue}
        outlineOnly
      />
    ),
    description: ({ roundNumber }) =>
      `This action starts Round ${roundNumber} of the RFP. Previous round(s) will be uneditable.`,
  },
  [IContractRfpStates.in_progress]: {
    Badge: () => (
      <Tag
        size={TAG_SIZE.xsmall}
        label={<Typography variant='caption'>In Progress</Typography>}
        tagStyle={TAG_STYLE.warning}
      />
    ),
    description: ({ roundNumber }) =>
      `This action starts Round ${roundNumber} of the RFP. Previous round(s) will be uneditable.`,
  },
  [IContractRfpStates.closed]: {
    Badge: () => (
      <Tag
        size={TAG_SIZE.xsmall}
        label={<Typography variant='caption'>Closed</Typography>}
        tagStyle={TAG_STYLE.dark}
      />
    ),
    description: () => 'This action makes the RFP viewable, but it cannot be edited or reopened.',
  },
  [IContractRfpStates.submitted]: {
    Badge: () => (
      <Tag
        size={TAG_SIZE.xsmall}
        label={<Typography variant='caption'>Submitted</Typography>}
        tagStyle={TAG_STYLE.infoLight}
      />
    ),
    description: () => 'This action makes the RFP uneditable.',
  },
  [IContractRfpStates.archived]: {
    Badge: () => (
      <Tag
        size={TAG_SIZE.xsmall}
        label={<Typography variant='caption'>Archived</Typography>}
        tagStyle={TAG_STYLE.disabled}
      />
    ),
    description: () => '',
  },
}

const SUBMITTED_RADIO_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]

export function StateChangeModal({ newState, onClose, onConfirm, rfp }: IStateChangeModalProps) {
  const navigate = useNavigate()
  const [wasSubmitted, setWasSubmitted] = useState('yes')
  const { rfpId = '' } = useParams()
  const classes = useStyles()
  const { Badge, description } = COMPONENT_MAP[newState]
  const startAt = moment(rfp.startAt).format('MMM D, YYYY')
  const endAt = moment(rfp.endAt).format('MMM D, YYYY')
  const duration = getDuration(rfp.startAt, rfp.endAt)
  const [submittedDate, setSubmittedDate] = useState(moment())
  const [dueAt, setDueAt] = useState(
    rfp.currentVersion?.dueAt ? moment(rfp.currentVersion.dueAt) : moment()
  )
  const { currentVersion } = rfp || {}
  const { number } = currentVersion
  const isCurrentRoundSubmitted = Boolean(currentVersion?.submittedAt)

  /**
   * Prevent transition to submitted/closed with empty rounds.
   */
  const isNoLanesFlow =
    !rfp.currentVersion.lanes.totalCount &&
    [IContractRfpStates.submitted, IContractRfpStates.closed].includes(newState) &&
    wasSubmitted !== 'no'

  return (
    <FormModal
      open
      onClose={onClose}
      maxWidth='md'
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          ButtonProps: {
            variant: 'text',
          },
        },
        {
          label: isNoLanesFlow ? 'Continue to Import Lanes' : 'Continue',
          action() {
            if (isNoLanesFlow) {
              if (rfpId) {
                onClose()
              } else {
                navigate(`${rfp.displayId}`)
              }

              return
            }

            if ([IContractRfpStates.planning, IContractRfpStates.in_progress].includes(newState)) {
              onConfirm(rfp, newState, undefined, dueAt.toISOString())
            } else {
              onConfirm(rfp, newState, submittedDate.toISOString(), rfp.currentVersion?.dueAt)
            }
          },
        },
      ]}
      title={
        <div className={classes.title}>
          <Typography variant='h4'>Move RFP-{rfp.displayId} To</Typography>
          <LaneArrowIcon size='large' />
          <Badge />
        </div>
      }>
      <div>
        <Typography variant='body1'>{description({ roundNumber: number + 1 })}</Typography>
        <div className={classes.rfpInfo}>
          <Typography variant='subtitle1'>{rfp.name}</Typography>
          <div className={classes.iconLabel}>
            <CustomerIcon size='large' color='coolGray5' />
            <Typography variant='body2' color={brandColors.coolGray5}>
              {rfp.organizationShipper.name}
            </Typography>
          </div>
          <div className={classes.iconLabel}>
            <CalendarIcon size='large' color='coolGray5' />
            <Typography variant='body2' color={brandColors.coolGray5}>
              {startAt} - {endAt} ({duration})
            </Typography>
          </div>
        </div>
        {newState === IContractRfpStates.submitted && (
          <div className={classes.updateContainer}>
            <Typography variant='subtitle1'>Update your RFP</Typography>
            <DatePicker
              value={submittedDate}
              label='Submitted Date *'
              onChange={newVal => newVal && setSubmittedDate(newVal)}
              disabled={isNoLanesFlow}
              dataTest='submitted-date-input'
            />
          </div>
        )}
        {[IContractRfpStates.planning, IContractRfpStates.in_progress].includes(newState) && (
          <div className={classes.updateContainer}>
            <Typography variant='subtitle1'>Update your RFP</Typography>
            <DatePicker
              value={dueAt}
              label='Due Date'
              onChange={newVal => newVal && setDueAt(newVal)}
              minDate={moment()}
              dataTest='due-date-input'
            />
          </div>
        )}
        <Box display={isCurrentRoundSubmitted ? 'none' : 'block'}>
          <Typography variant='caption' pb={0.5} color={brandColors.coolGray5}>
            Did you submit Round {currentVersion?.number} of this RFP?
          </Typography>
          <RadioGroup
            big
            options={SUBMITTED_RADIO_OPTIONS}
            onOptionChange={e => setWasSubmitted(e.target.value)}
            checkedValue={wasSubmitted}
          />
          <Collapse in={wasSubmitted === 'yes'}>
            <Box pt={3}>
              <DatePicker
                label={`Submitted Date (Round ${currentVersion?.number})`}
                clearable={false}
                format={FORMATS.readableFormat}
                value={submittedDate}
                disabled={isNoLanesFlow}
                onChange={newVal => newVal && setSubmittedDate(newVal)}
              />
            </Box>
          </Collapse>
        </Box>
        {isNoLanesFlow && (
          <InlineMessage
            className={classes.warning}
            message='No lanes have been added to this Round. Please import the lanes that were submitted to change the status.'
            type={INLINE_MESSAGE_VARIANTS.WARNING}
          />
        )}
      </div>
    </FormModal>
  )
}
