import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { CurrencyInput, ICurrencyInputProps } from 'dpl/components/CurrencyInput'

type IBaseControllerProps = ControllerProps<React.ComponentType<ICurrencyInputProps>>
export type TTransformFunction = (value: string) => number

export interface IControlledCurrencyInputProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue' | 'disabled'> {
  CurrencyInputProps?: Partial<ICurrencyInputProps>
  transformFunction?: TTransformFunction
}

const DEFAULT_TRANSFORM_FUNCTION: TTransformFunction = (a: string) => Number(a)

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledCurrencyInput
 *   name='rate'
 *   control={control}
 *   CurrencyInputProps={{
 *     label: 'Rate',
 *     placeholder: '$',
 *   }}
 * />
 * ```
 */
const ControlledCurrencyInput = memo<IControlledCurrencyInputProps>(
  ({
    name,
    control,
    disabled = false,
    defaultValue = '',
    CurrencyInputProps = {},
    transformFunction = DEFAULT_TRANSFORM_FUNCTION,
  }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onBlur, onChange, value }) => {
          const changeHandler = ({ value }: { value: string }) => {
            if (value) {
              onChange(transformFunction(value))
            } else {
              onChange()
            }
          }

          const clearHandler = () => {
            onChange('')
          }

          return (
            <CurrencyInput
              {...CurrencyInputProps}
              onValueChange={changeHandler}
              onBlur={onBlur}
              onClear={clearHandler}
              value={value}
              disabled={disabled}
            />
          )
        }}
      />
    )
  }
)

export default ControlledCurrencyInput
