import React from 'react'
import { Button, Typography, Box } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import moment from 'moment'

interface SubmittedInfoProps {
  laneCount: number
  submittedAt: string
  isCurrentRound: boolean
  restartRoundHandler: (event: React.SyntheticEvent<HTMLButtonElement>) => void
}

export function SubmittedInfo({
  isCurrentRound,
  laneCount,
  restartRoundHandler,
  submittedAt,
}: SubmittedInfoProps) {
  if (isCurrentRound && laneCount === 0) {
    return null
  }

  if (isCurrentRound && laneCount > 0 && !submittedAt) {
    return (
      <Box display='inline-flex' marginTop={1}>
        <Button variant='text' size='xsmall' onClick={restartRoundHandler} fullWidth={false}>
          Restart Round
        </Button>
      </Box>
    )
  }

  return (
    <Typography variant='caption' color={brandColors.coolGray5}>
      {submittedAt ? `Submitted ${moment(submittedAt).format('MMM D, YYYY')}` : 'Not Submitted'}
    </Typography>
  )
}
