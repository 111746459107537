import React from 'react'
import { FunctionalTag, IFunctionalTagProps, TAG_SIZE, TAG_STYLE, Tag } from 'dpl/components/Tag'
import capitalize from 'lodash/capitalize'

const tagStyleByStatus: Record<string, TAG_STYLE> = {
  submitted: TAG_STYLE.info,
  won: TAG_STYLE.success,
}

interface IStatusSelectTagProps {
  status: Nullable<string>
  onSelect: IFunctionalTagProps['onSelect']
}

export function StatusSelectTag({ onSelect, status }: IStatusSelectTagProps) {
  const statusLabel = capitalize(status ?? '')
  const tagStyle = tagStyleByStatus[status ?? '']
  const options = [
    {
      label: 'Submitted',
      value: 'submitted',
      style: tagStyleByStatus.submitted,
    },
    {
      label: 'Won',
      value: 'won',
      style: tagStyleByStatus.won,
    },
  ]

  if (status === 'submitted') {
    return (
      <FunctionalTag
        value={status}
        size={TAG_SIZE.small}
        options={options}
        onSelect={onSelect}
        dataTest='StatusFunctionalTag'
      />
    )
  }
  return <Tag label={statusLabel} tagStyle={tagStyle} size={TAG_SIZE.small} dataTest='WonTag' />
}
