import React from 'react'
import { makeStyles, Typography } from 'dpl/core'
import QuoteIcon from 'dpl/icons/build/QuoteIcon'
import { brandColors } from 'dpl/theme'

const useEmptyLaneInformationStateStyles = makeStyles(theme => ({
  root: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundColor: brandColors.coolGray1,
    width: theme.spacing(9),
    height: theme.spacing(9),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    '& > .MuiSvgIcon-root': {
      // Custom icon size per designs
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}))

export function EmptyLaneInformationState() {
  const classes = useEmptyLaneInformationStateStyles()
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        <QuoteIcon color='coolGray5' />
      </div>
      <Typography variant='h3' fontWeight={500} textAlign='center' marginTop={3}>
        Enter lane information above to get started{' '}
      </Typography>
    </div>
  )
}
