import { IRule } from '#components/RatesEstimatedCarrierRateAccordion/types'
import { IAdjustmentRuleLineItem } from '#components/RatesEstimatedStrategyAccordion/types'
import { getAdjustmentRuleAmountLabel } from './getAdjustmentRuleAmountLabel'
import { getAdjustmentRuleIcon } from './getAdjustmentRuleIcon'
import { getAdjustmentRuleLabel } from './getAdjustmentRuleLabel'

interface IGetAdjustmentRuleLineItemProps {
  rule: IRule
  baseCost: number
}

/**
 * Returns the line item for an adjustment rule based on the rule and base cost.
 *
 * @returns {IAdjustmentRuleLineItem} The line item props for the adjustment rule4
 *
 */
export const getAdjustmentRuleLineItem = ({
  baseCost,
  rule,
}: IGetAdjustmentRuleLineItemProps): IAdjustmentRuleLineItem => {
  const { adjustmentValue, id, reason, ruleAction } = rule || {}
  return {
    key: id,
    Icon: getAdjustmentRuleIcon({
      adjustmentValue,
      ruleAction,
    }),
    label: getAdjustmentRuleLabel({
      adjustmentValue,
      reason,
      ruleAction,
    }),
    amount: getAdjustmentRuleAmountLabel({
      adjustmentValue,
      baseCost,
      ruleAction,
    }),
  }
}
