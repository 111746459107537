import { useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ResetPasswordFormSchema, TResetPasswordFormSchema } from '../schema'

interface IUseResetPasswordFormProps
  extends Omit<UseFormOptions<TResetPasswordFormSchema>, 'resolver'> {}

export const useResetPasswordForm = ({
  defaultValues = {},
  ...props
}: IUseResetPasswordFormProps) => {
  return useForm<TResetPasswordFormSchema>({
    resolver: yupResolver(ResetPasswordFormSchema),
    defaultValues: ResetPasswordFormSchema.cast(defaultValues),
    mode: 'onChange',
    criteriaMode: 'all',
    ...props,
  })
}
