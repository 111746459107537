import { ICreateQuoteInput, IStopInput } from '#types/graphqlTypes'
import { IEstimate } from '#types/rates'
import { TRatesResponse } from '../../../types'
import { TSaveQuoteFormSchema } from '../schema/saveQuoteFormSchema'

interface MapSchemaToInputProps {
  formData: TSaveQuoteFormSchema
  ratesResponse: TRatesResponse
  rateEstimate: IEstimate
}

export function mapSchemaToInput({
  formData,
  rateEstimate,
  ratesResponse,
}: MapSchemaToInputProps): ICreateQuoteInput {
  const { communicationMethod, customer, date, referenceNumber, shipmentNumber } = formData
  const { id: organizationShipperId } = customer || {}
  const {
    assignmentLeadTimeMinutes,
    creationLeadTimeMinutes,
    equipmentKey,
    isTeam,
    palletCount,
    reeferTempMax,
    reeferTempMin,
    stops: ratesResponseStops,
    weight,
  } = ratesResponse
  const { carrierRate: estimatedCarrierRate, marginValue: margin } = rateEstimate
  const dateObj = date ? new Date(date) : new Date()
  const submittedDate = dateObj.toISOString()
  const stops: IStopInput[] = ratesResponseStops.map(
    ({
      appointmentStartAtDatetime,
      city,
      date,
      isDropTrailer,
      postalCode,
      startTime,
      startTimeZone,
      stateCode,
      stopSequence,
      stopType,
    }) => ({
      appointmentStartAtDatetime,
      city,
      date,
      isDropTrailer,
      isoCountryCode: 'US',
      postalCode,
      startTime,
      startTimeZone,
      stateCode,
      stopSequence,
      stopType,
    })
  )

  return {
    assignmentLeadTimeMinutes,
    bidAmount: estimatedCarrierRate + margin,
    communicationMethod,
    creationLeadTimeMinutes,
    customerReference: referenceNumber,
    equipmentKey: equipmentKey!,
    estimatedCarrierRate,
    isTeam,
    margin,
    organizationShipperId,
    palletCount,
    rateType: 'all_in',
    reeferTempMax,
    reeferTempMin,
    shipmentNumber,
    source: 'rates_tool',
    status: shipmentNumber ? 'won' : 'submitted',
    stops,
    submittedDate,
    weight,
  }
}
