import React from 'react'
import SvgIcon from '../components/SvgIcon'
import { MarginAdditiveNegativeSvg } from '../icons'

function MarginAdditiveNegativeIcon(props) {
  return (
    <SvgIcon
      className='RogersSvgIcon-MarginAdditiveNegative'
      component={MarginAdditiveNegativeSvg}
      {...props}
    />
  )
}

export default MarginAdditiveNegativeIcon
