import { IRuleTypeEnum } from '#types/rates'
import { COST_LABELS_BY_PERCENTILE } from '../../constants'

export interface IFormatCostStrategyArgs {
  costRuleType: Nullable<Exclude<IRuleTypeEnum, IRuleTypeEnum.doNotBid>>
  costBasePercentile: Nullable<number>
  costAdditiveShiftPercentage: Nullable<number>
}

export const formatCostStrategy = ({
  costAdditiveShiftPercentage,
  costBasePercentile,
  costRuleType,
}: IFormatCostStrategyArgs) => {
  if (costRuleType === IRuleTypeEnum.base) {
    const costLabel = costBasePercentile ? COST_LABELS_BY_PERCENTILE[costBasePercentile] : ''
    const formattedBaseStrategy = `${costBasePercentile}th Percentile${costLabel ? ` (${costLabel})` : ''}`
    return costBasePercentile ? formattedBaseStrategy : ''
  }
  if (costRuleType === IRuleTypeEnum.additive) {
    if (!costAdditiveShiftPercentage) return ''
    const formattedAdditiveStrategy = `${costAdditiveShiftPercentage > 0 ? '+' : ''}${costAdditiveShiftPercentage}% Shift`
    return formattedAdditiveStrategy
  }
  return ''
}
