import React, { memo, PropsWithChildren } from 'react'
import { currencyFormatter } from '#utils/currencyFormatter'
import { usePermissionsContext } from 'auth/common/context'
import { DISPLAY } from 'dpl/constants'
import { Box, Skeleton } from 'dpl/core'
import Typography from 'dpl/core/Typography'
import { useFlagsContext } from 'flags/src/FlagsContext'

export interface IDataGridContractMarginProps {
  marginAmount: Nullable<number>
  marginPercentage: Nullable<number>
  status: Nullable<string>
  color: string
}

export const DataGridContractMarginCell = memo<PropsWithChildren<IDataGridContractMarginProps>>(
  ({ color, marginAmount, marginPercentage, status }) => {
    const { isFlagEnabled } = useFlagsContext()
    const { userPermissions } = usePermissionsContext()
    const isFFEnabled = isFlagEnabled('rfp_rate_gen')
    const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled

    const pendingRateGen = [status === 'pending', isRfpRateGenEnabled].every(Boolean)

    if (pendingRateGen) {
      return <Skeleton variant='rectangular' width={80} />
    }

    return (
      <Box display='flex' flexDirection='column' flex={1}>
        {!marginAmount && !marginPercentage ? (
          <Box width='100%'>
            <Typography color={color}>-</Typography>
          </Box>
        ) : (
          <>
            <Box width='100%'>
              <Typography color={color}>{currencyFormatter(marginAmount ?? 0)}</Typography>
            </Box>
            <Typography variant='caption' color={color}>
              {marginPercentage ? `${(marginPercentage * 100).toFixed(2)}%` : DISPLAY.empty}
            </Typography>
          </>
        )}
      </Box>
    )
  }
)
