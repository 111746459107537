import React, { memo, useCallback, useState } from 'react'
import { Avatar, IconButton } from 'dpl'
import { makeStyles, Typography, Box, Stack, Skeleton } from 'dpl/core'
import { CustomerIcon, DryVanIcon, EditIcon, LaneArrowIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme/colors'
import { useCarrierRatesReorderContext } from '../../context'
import { RoutingGuideManagerFormModal } from '../../forms/RoutingGuideManagerFormModal'
import { IRgLaneManagerFragment } from '../../graphql/RGLaneManagerFragment'

export interface ILaneDetailsSectionProps {
  destination: string
  equipmentName: string
  laneManager?: IRgLaneManagerFragment
  origin: string
  orgShipperName: string
  routingGuideLaneId?: string
  isLoading: boolean
}

const useLaneDetailsSectionStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 3, 2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 3, 2),
    },
    '&.reordering': {
      opacity: 0.5,
    },
  },
  divider: {
    display: 'none',
    width: 1,
    backgroundColor: brandColors.coolGray2,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  laneDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(2.5),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  editIcon: {
    color: brandColors.skyBlue6,
    marginTop: theme.spacing(-1),
  },
}))

export const LaneDetailsSection = memo(
  ({
    destination,
    equipmentName,
    isLoading,
    laneManager,
    orgShipperName,
    origin,
    routingGuideLaneId,
  }: ILaneDetailsSectionProps) => {
    const classes = useLaneDetailsSectionStyles()

    const { isReordering } = useCarrierRatesReorderContext()

    const { user } = laneManager || {}
    const { firstName = '', lastName = '' } = user || {}
    const laneManagerName = `${firstName} ${lastName}`

    const [isLaneManagerModalOpen, setIsLaneManagerModalOpen] = useState<boolean>(false)

    const toggleLaneManagerEditModal = useCallback(
      () => setIsLaneManagerModalOpen(prev => !prev),
      []
    )

    return (
      <div className={`${classes.root} ${isReordering ? 'reordering' : ''}`}>
        <Stack direction='row' gap={1.5}>
          {isLoading ? (
            <Skeleton width={150} height={32} />
          ) : (
            <Typography variant='h3'>{origin}</Typography>
          )}

          <Box display='flex' alignItems='center' justifyContent='center'>
            <LaneArrowIcon size='xlarge' />
          </Box>
          {isLoading ? (
            <Skeleton width={150} height={32} />
          ) : (
            <Typography variant='h3'>{destination}</Typography>
          )}
        </Stack>
        <div className={classes.laneDetails}>
          <div>
            <Typography variant='caption' color='textSecondary' component='div'>
              Equipment
            </Typography>
            <div className={classes.item}>
              <DryVanIcon size='xlarge' color='coolGray5' />
              {isLoading ? (
                <Skeleton width={60} height={32} />
              ) : (
                <Typography variant='body1'>
                  {equipmentName === 'Open Deck' ? 'Flatbed' : equipmentName}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.divider} />
          <div>
            <Typography variant='caption' color='textSecondary' component='div'>
              Customer
            </Typography>
            <div className={classes.item}>
              <CustomerIcon size='xlarge' color='coolGray5' />
              {isLoading ? (
                <Skeleton width={100} height={32} />
              ) : (
                <Typography variant='body1'>{orgShipperName}</Typography>
              )}
            </div>
          </div>
          <div className={classes.divider} />
          <div>
            <Typography variant='caption' color='textSecondary' component='div'>
              Lane Manager
            </Typography>
            <div className={classes.item} data-test='lane-manager-display'>
              <Stack direction='row' gap={1} alignItems='center'>
                <Avatar name={isLoading ? 'unassigned' : laneManagerName} size='xsmall' />
                {isLoading ? (
                  <Skeleton width={150} height={32} />
                ) : (
                  <Typography variant='body1'>{laneManagerName}</Typography>
                )}
                <IconButton
                  Icon={EditIcon}
                  onClick={toggleLaneManagerEditModal}
                  size='large'
                  color='secondary'
                  className={classes.editIcon}
                  disabled={isLoading || isReordering}
                  dataTest='edit-lane-manager-button'
                />
              </Stack>
            </div>
          </div>
        </div>
        {laneManager && (
          <RoutingGuideManagerFormModal
            open={isLaneManagerModalOpen}
            onClose={toggleLaneManagerEditModal}
            defaultLaneManager={laneManager}
            routingGuideLaneId={routingGuideLaneId}
          />
        )}
      </div>
    )
  }
)
