import React, { memo, useCallback, useState } from 'react'
import {
  AutocompleteLocationBase,
  IAutocompleteLocationBaseProps,
} from 'dpl/components/AutocompleteLocationBase'
import { IAutocompleteLocationBaseResult } from 'dpl/components/AutocompleteLocationBase/types'
import { IGeocodingAllowedCountryEnum } from 'dpl/components/SelectCountry/types'
import { createFilterOptions } from 'dpl/core'
import { CA_STATE_OPTIONS, US_STATE_OPTIONS } from './constants'

export interface IAutocompleteStateProps
  extends Omit<
    IAutocompleteLocationBaseProps,
    'options' | 'onInputChange' | 'countryOptions' | 'filterOptions'
  > {}

const getStateOptions = (country: IGeocodingAllowedCountryEnum) =>
  country === 'US' ? US_STATE_OPTIONS : CA_STATE_OPTIONS

/**
 * This overrides the default filtering from the DPL component
 * @see https://github.com/transfixio/wilson/blob/main/packages/dpl/components/Autocomplete/Autocomplete.tsx#L50
 */
const FILTER_OPTIONS = createFilterOptions<IAutocompleteLocationBaseResult>()

export const AutocompleteState = memo<IAutocompleteStateProps>(
  ({
    defaultCountry = IGeocodingAllowedCountryEnum.US,
    label = 'State',
    dataTest = 'AutocompleteState',
    ...props
  }) => {
    const [options, setOptions] = useState(getStateOptions(defaultCountry))

    const inputValueChangeHandler = useCallback<IAutocompleteLocationBaseProps['onInputChange']>(
      (inputValue, country) => {
        if (inputValue !== '') {
          setOptions(getStateOptions(country))
        }
      },
      []
    )
    return (
      <AutocompleteLocationBase
        dataTest={dataTest}
        label={label}
        options={options}
        onInputChange={inputValueChangeHandler}
        defaultCountry={defaultCountry}
        getOptionLabel={option => option?.id || ''}
        countryOptions={[IGeocodingAllowedCountryEnum.US, IGeocodingAllowedCountryEnum.CA]}
        filterOptions={FILTER_OPTIONS}
        {...props}
      />
    )
  }
)
