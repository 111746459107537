import { currencyFormatter } from '#utils/currencyFormatter'

/**
 * This will help us avoid the cumulative error
 * when dealing with floating point in js.
 *
 * **NOTE:** this is meant to be used for UI purposes alone, the
 * currency values sent to the BE should always be whole.
 * @param currencyAmounts array of numbers representing currency
 * @returns sum of all formatted as currency
 */
export function sumCurrency(currencyAmounts: number[]) {
  let totalSum = 0

  currencyAmounts.forEach(amount => {
    totalSum += Number(amount.toFixed(2))
  })

  return currencyFormatter(totalSum)
}
