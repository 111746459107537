import React, { memo } from 'react'
import { Tag, TAG_SIZE, TAG_STYLE } from 'dpl/components/Tag'
import { Box, Button } from 'dpl/core'
import capitalize from 'lodash/capitalize'

export interface ISpotQuoteCardActionProps {
  hasQuotesEnabled: boolean
  onSaveQuoteClick: () => void
  quoteStatus: Nullable<string>
  quoteId: Nullable<string>
}

const tagStyleByStatus: Record<string, TAG_STYLE> = {
  submitted: TAG_STYLE.info,
  won: TAG_STYLE.success,
}

export const SpotQuoteCardAction = memo<ISpotQuoteCardActionProps>(
  ({ hasQuotesEnabled, onSaveQuoteClick, quoteId, quoteStatus }) => {
    if (!hasQuotesEnabled) {
      return null
    }

    const tagStyle = tagStyleByStatus[quoteStatus ?? ''] ?? TAG_STYLE.dark
    const tagLabel = quoteStatus ? capitalize(quoteStatus) : 'Not Submitted'

    return (
      <Box display='flex' alignItems='center' gap={1.5}>
        <Tag size={TAG_SIZE.small} label={tagLabel} tagStyle={tagStyle} />
        {quoteId ? (
          <Button
            href={`/quotes/${quoteId}`}
            target='_blank'
            variant='text'
            size='small'
            data-test='view-quote-button'>
            View Quote
          </Button>
        ) : (
          <Button onClick={onSaveQuoteClick} data-test='save-quote-button' size='small'>
            Save Quote
          </Button>
        )}
      </Box>
    )
  }
)
