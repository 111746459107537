import React, { memo, useCallback } from 'react'
import Autocomplete, { IAutocompleteProps } from 'dpl/components/Autocomplete'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import { IOrganizationCarrierRelationshipState } from '../../types/graphqlTypes'
import { AutocompleteOrganizationCarrierOption } from './components'
import { useAutocompleteOrganizationCarrierLazyQuery } from './graphql/AutocompleteOrganizationCarrier'
import { IOrganizationCarrierResultFragment } from './graphql/OrganizationCarrierResultFragment'

const DEBOUNCE_MS = 250
const DEFAULT_ORGANIZATION_CARRIER_STATES: IOrganizationCarrierRelationshipState[] = [
  IOrganizationCarrierRelationshipState.active,
  IOrganizationCarrierRelationshipState.pending,
]

interface IAutocompleteBaseProps extends IAutocompleteProps<IOrganizationCarrierResultFragment> {}

export interface IAutocompleteOrganizationCarrierProps
  extends Omit<
    IAutocompleteBaseProps,
    | 'freeSolo'
    | 'getOptionLabel'
    | 'inputValue'
    | 'isOptionEqualToValue'
    | 'loading'
    | 'options'
    | 'onInputChange'
    | 'renderOption'
  > {
  /**
   * The states of the organization carrier relationship to search by
   * @default ['active', 'pending']
   */
  organizationCarrierStates?: IOrganizationCarrierRelationshipState[]
  /**
   * @default 'AutocompleteOrganizationCarrier'
   */
  dataTest?: string
}

export const AutocompleteOrganizationCarrier = memo<IAutocompleteOrganizationCarrierProps>(
  ({
    dataTest = 'AutocompleteOrganizationCarrier',
    organizationCarrierStates = DEFAULT_ORGANIZATION_CARRIER_STATES,
    ...props
  }) => {
    const [search, { data, loading }] = useAutocompleteOrganizationCarrierLazyQuery({
      variables: { searchTerm: '', states: organizationCarrierStates },
    })

    const { searchOrganizationCarriers } = data || {}
    const { nodes = [] } = searchOrganizationCarriers || {}
    const organizationCarriers = compact<IOrganizationCarrierResultFragment>(nodes)

    const searchByTerm = useCallback(
      (searchTerm: string) => {
        search({ variables: { searchTerm } })
      },
      [search]
    )

    const inputValueChangeHandler = debounce<NonNullable<IAutocompleteBaseProps['onInputChange']>>(
      (_, value) => {
        searchByTerm(value)
      },
      DEBOUNCE_MS
    )

    return (
      <Autocomplete<IOrganizationCarrierResultFragment>
        dataTest={dataTest}
        freeSolo={false}
        getOptionLabel={option => {
          const { carrier } = option || {}
          const { legalName } = carrier || {}
          return `${legalName}`
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={loading}
        options={organizationCarriers}
        onInputChange={inputValueChangeHandler}
        renderOption={(optionProps, option, { inputValue }) => {
          return (
            <li {...optionProps}>
              <AutocompleteOrganizationCarrierOption
                inputValue={inputValue}
                option={option}
                dataTest={`${dataTest}-${option.id}`}
              />
            </li>
          )
        }}
        {...props}
      />
    )
  }
)
