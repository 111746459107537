import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { ISliderProps, Slider } from 'dpl/components/Slider'

type IBaseControllerProps = ControllerProps<React.ComponentType<ISliderProps>>

export interface IControlledSliderProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  SliderProps: Omit<ISliderProps, 'onChange' | 'value'>
}

export const ControlledSlider = memo<IControlledSliderProps>(
  ({ SliderProps, control, defaultValue, name }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          const changeHandler: ISliderProps['onChange'] = (_, value) => {
            onChange(value)
          }
          return <Slider {...SliderProps} onChange={changeHandler} value={value} name={name} />
        }}
      />
    )
  }
)
