import { DefaultValues, useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IRateType } from '../../../../../../types/rateType'
import { RoutingGuideCarrierSchema, TRoutingGuideCarrierSchema } from '../schema'
import { IAvailabilityType } from '../types'

export const getDefaultValues = (values: DefaultValues<TRoutingGuideCarrierSchema>) => {
  return RoutingGuideCarrierSchema.cast(
    {
      organizationCarrier: null,
      carrierUserEmail: {
        inputValue: '',
        value: null,
      },
      rateType: IRateType.allIn,
      availability: IAvailabilityType.anyDay,
      ...values,
    },
    { assert: false }
  )
}

export interface IUseRoutingGuideCarrierFormProps
  extends Omit<UseFormOptions<TRoutingGuideCarrierSchema>, 'resolver'> {}

export const useRoutingGuideCarrierForm = ({
  defaultValues = {},
  ...rest
}: IUseRoutingGuideCarrierFormProps) => {
  return useForm<TRoutingGuideCarrierSchema>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: yupResolver(RoutingGuideCarrierSchema),
    ...rest,
  })
}
