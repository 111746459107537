import React, { memo, PropsWithChildren, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { ITabsProps, Tabs } from 'dpl/components/Tabs'
import { ITabProps } from 'dpl/components/Tabs/components/Tab'
import { makeStyles } from 'dpl/core/styles'
import { useFlagsContext } from 'flags'
import { useCarrierRatesReorderContext } from '../../context'

const useRoutingGuideLaneDetailsTabsStyles = makeStyles(theme => ({
  tabs: {
    '& .MuiTabs-root': {
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 3),
      },
    },
    '&.reordering .MuiTabs-root': {
      opacity: 0.5,
    },
  },
}))

export interface IRoutingGuideLaneDetailsTabsProps {
  /**
   * @default 'RoutingGuideLaneDetailsTabs'
   */
  dataTest?: string
  /**
   * @default false
   */
  isLoading?: boolean
  /**
   * The number of carrier rates
   */
  numberOfCarrierRates?: number
}

const CARRIER_TAB: ITabProps = { tabId: 'carriers', label: 'Carriers' }
const ACTIVITY_TAB: ITabProps = { tabId: 'activity', label: 'Activity' }

export const RoutingGuideLaneDetailsTabs = memo<
  PropsWithChildren<IRoutingGuideLaneDetailsTabsProps>
>(
  ({
    children,
    dataTest = 'RoutingGuideLaneDetailsTabs',
    isLoading = false,
    numberOfCarrierRates,
  }) => {
    const navigate = useNavigate()
    const { id = '', tab = '' } = useParams()
    const { isFlagEnabled } = useFlagsContext()
    const isRGActivityTabEnabled = isFlagEnabled('routing_guide_activity_tab')

    const classes = useRoutingGuideLaneDetailsTabsStyles()

    const { isReordering } = useCarrierRatesReorderContext()

    const changeTabHandler: ITabsProps['onChange'] = useCallback(
      newActiveTabId => {
        if (isReordering) {
          return
        }
        navigate(`${PROTECTED_PATHS.routingGuideLanes}/${id}/${newActiveTabId}`, { replace: true })
      },
      [isReordering, navigate, id]
    )

    const tabList = useMemo(
      () =>
        isRGActivityTabEnabled
          ? [
              {
                ...CARRIER_TAB,
                count: numberOfCarrierRates || undefined,
                disabled: isLoading,
              },
              {
                ...ACTIVITY_TAB,
                disabled: isLoading,
              },
            ]
          : [
              {
                ...CARRIER_TAB,
                count: numberOfCarrierRates || undefined,
                disabled: isLoading,
              },
            ],
      [isRGActivityTabEnabled, numberOfCarrierRates, isLoading]
    )

    return (
      <Tabs
        activeTabId={tab}
        data-test={dataTest}
        onChange={changeTabHandler}
        className={`${classes.tabs} ${isReordering ? 'reordering' : ''}`}
        tabList={tabList}>
        {children}
      </Tabs>
    )
  }
)
