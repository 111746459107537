import React from 'react'
import { DataGridFilterCustomer } from '#components/DataGridFilterCustomer'
import { DataGridFilterOrganizationUser } from '#components/DataGridFilterOrganizationUser'
import { IRuleTypeEnum } from '#types/rates'
import { AdjustmentRuleLocationFilter } from './AdjustmentRuleLocationFilter'
import { IAdjustmentRulesDataGridFilters } from './types'

interface IGetAdjustmentRulesFiltersConfigProps {
  isDoNotBidRulesEnabled: boolean
}

export const getAdjustmentRulesFiltersConfig = ({
  isDoNotBidRulesEnabled,
}: IGetAdjustmentRulesFiltersConfigProps): NonNullable<
  IAdjustmentRulesDataGridFilters['filtersConfig']
> => ({
  pickup: {
    type: 'custom',
    FilterComponent: props => (
      <AdjustmentRuleLocationFilter dataTest='pickup' label='Pickup' {...props} />
    ),
  },
  delivery: {
    type: 'custom',
    FilterComponent: props => (
      <AdjustmentRuleLocationFilter dataTest='delivery' label='Delivery' {...props} />
    ),
  },
  customer: {
    type: 'custom',
    FilterComponent: DataGridFilterCustomer,
  },
  createdBy: {
    type: 'custom',
    FilterComponent: props => (
      <DataGridFilterOrganizationUser
        {...props}
        AutocompleteOrganizationUserProps={{
          placeholder: 'Created by',
        }}
      />
    ),
  },
  ruleType: {
    label: 'Rule Type',
    type: 'singleSelect',
    options: [
      { label: 'Base', value: IRuleTypeEnum.base },
      { label: 'Additive', value: IRuleTypeEnum.additive },
      ...(isDoNotBidRulesEnabled ? [{ label: 'Do not bid', value: IRuleTypeEnum.doNotBid }] : []),
    ],
  },
})
