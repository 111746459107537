import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { IconButton } from 'dpl/components/IconButton'
import { Typography, Divider, makeStyles, Box, Button, Collapse } from 'dpl/core'
import { EditIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme/colors'
import { SECTIONS_STEP_NUMBER } from '../../constants'
import { TAdjustmentRuleFormSteps } from '../../types'

export interface IStepSectionProps {
  /**
   * Title of step rendered at top of section
   * @example 'Rate Strategy'
   */
  title: string
  /**
   * The current step index
   * @example 0
   */
  stepIndex: number
  /**
   * The list of states for each form step
   */
  formSteps: TAdjustmentRuleFormSteps
  onContinue: () => void
  onEdit: () => void
  dataTest?: string
}

const useStepSectionStyles = makeStyles(theme => ({
  divider: {
    borderColor: brandColors.coolGray2,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  activeDivider: {
    borderColor: brandColors.coolGray4,
  },
  editButton: {
    color: theme.palette.primary.main,
    marginTop: `-${theme.spacing(0.5)}`,
    marginBottom: `-${theme.spacing(0.5)}`,
    marginRight: `-${theme.spacing(1)}`,
  },
}))

export function StepSection({
  children,
  dataTest = 'StepSection',
  formSteps,
  onContinue,
  onEdit,
  stepIndex,
  title,
}: PropsWithChildren<IStepSectionProps>) {
  const classes = useStepSectionStyles()
  const currentState = formSteps?.[stepIndex]
  const isStepBeforeReview = stepIndex === SECTIONS_STEP_NUMBER.review - 1
  const dividerClassnames = classNames(classes.divider, {
    [classes.activeDivider]: currentState === 'active',
  })

  return (
    <div data-test={dataTest}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography
          variant='h4'
          color={currentState === 'inactive' ? brandColors.coolGray4 : 'textPrimary'}>
          {title}
        </Typography>
        {currentState === 'review' && (
          <IconButton
            Icon={EditIcon}
            size='large'
            color='secondary'
            onClick={onEdit}
            className={classes.editButton}
            dataTest={`${dataTest}-EditButton`}
          />
        )}
      </Box>
      <Collapse in={currentState !== 'inactive'} unmountOnExit data-test={`${dataTest}-body`}>
        {children}
      </Collapse>
      {currentState === 'active' && (
        <Box marginTop={3}>
          <Button
            size='large'
            color='secondary'
            onClick={onContinue}
            data-test={`${dataTest}-ContinueButton`}>
            {isStepBeforeReview ? 'Continue to Review' : 'Continue'}
          </Button>
        </Box>
      )}
      <Divider className={dividerClassnames} />
    </div>
  )
}
