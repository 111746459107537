import { IRuleTypeEnum } from '../../../../../../types/rates'
import { TAdjustmentRulesRow } from '../../components/AdjustmentRulesDataGrid'
import { ISpotRatesAdjustmentRuleVersionFragment } from '../../graphql/SpotRatesAdjustmentRuleVersionFragment'

export const separateRulesByAction = (
  rules: Nullable<Nullable<ISpotRatesAdjustmentRuleVersionFragment>[]>
) => {
  const baseRules: TAdjustmentRulesRow[] = []
  const additiveRules: TAdjustmentRulesRow[] = []
  const doNotBidRules: TAdjustmentRulesRow[] = []
  if (rules?.length) {
    rules.forEach(rule => {
      const { ruleAction } = rule || {}
      if (!rule) return
      if (ruleAction === IRuleTypeEnum.base) {
        baseRules.push(rule)
      }
      if (ruleAction === IRuleTypeEnum.additive) {
        additiveRules.push(rule)
      }
      if (ruleAction === IRuleTypeEnum.doNotBid) {
        doNotBidRules.push(rule)
      }
    })
  }
  return {
    baseRules,
    additiveRules,
    doNotBidRules,
  }
}
