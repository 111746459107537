import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ControlledAutocompleteOrganizationUser } from '#components'
import { useCurrentOrgUserQuery } from '#routes/routing-guide/RoutingGuideLanesPage/forms/RoutingGuideLaneFormModal/components/ControlledLaneManagerField/graphql/CurrentOrgUser'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormModal, INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import { Box } from 'dpl/core'
import { TUpdateAssigneeFormSchema, UpdateAssigneeFormSchema } from './UpdateAssigneeFormSchema'

export interface IUpdateAssigneeModalProps {
  currentAssignee: Nullable<TUpdateAssigneeFormSchema['assignee']>
  onClose: () => void
  onConfirm: (newValue: TUpdateAssigneeFormSchema['assignee']) => Promise<Error | null>
}

export function UpdateAssigneeModal({
  currentAssignee,
  onClose,
  onConfirm,
}: IUpdateAssigneeModalProps) {
  const methods = useForm<TUpdateAssigneeFormSchema>({
    defaultValues: {
      assignee: currentAssignee || undefined,
    },
    resolver: yupResolver(UpdateAssigneeFormSchema),
  })
  const [error, setError] = useState<string>('')
  const { control, handleSubmit, setValue } = methods || {}
  const { loading } = useCurrentOrgUserQuery({
    onCompleted: ({ currentSession }) => {
      const { organizationUser } = currentSession || {}
      setValue('assignee', currentAssignee || organizationUser)
    },
  })

  return (
    <FormModal
      open
      onClose={onClose}
      maxWidth='md'
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          ButtonProps: {
            variant: 'text',
          },
        },
        {
          label: 'Save',
          action: handleSubmit(async data => {
            setError('')
            const error = await onConfirm(data.assignee)
            if (error) setError(error.message)
          }),
        },
      ]}
      title='Edit Assignee'>
      <FormProvider {...methods}>
        {error && (
          <Box mb={3}>
            <InlineMessage message={error} type={INLINE_MESSAGE_VARIANTS.ALERT} />
          </Box>
        )}
        <ControlledAutocompleteOrganizationUser
          control={control}
          name='assignee'
          AutocompleteOrganizationUserProps={{
            disabled: loading,
          }}
        />
      </FormProvider>
    </FormModal>
  )
}
