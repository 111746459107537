import { DataGridFilterCustomer } from '#components/DataGridFilterCustomer/DataGridFilterCustomer'
import { DataGridFilterOriginDestinationLocation } from '#components/DataGridFilterOriginDestinationLocation'
import { ISortOrder } from '#types/graphqlTypes'
import { DashboardIcon, EmailIcon, PhoneIcon, SearchListIcon } from 'dpl/icons'
import SvgIcon from 'dpl/icons/components/SvgIcon'
import { UserFilter } from './UserFilter'

/**
 * type gymnastics to comply with DPL's Datagrid Type definition. 🤸
 */
const textInputType = 'textInput' as const
const customType = 'custom' as const
const singleSelectType = 'singleSelect' as const
const switchType = 'switch' as const

export const QUOTES_FILTERS_CONFIG = {
  externalId: {
    type: textInputType,
    placeholder: 'External Ref. or Shipment #',
    label: 'External Ref. or Shipment #',
  },
  location: {
    type: customType,
    FilterComponent: DataGridFilterOriginDestinationLocation,
  },
  customer: {
    type: customType,
    FilterComponent: DataGridFilterCustomer,
  },
  user: {
    type: customType,
    FilterComponent: UserFilter,
  },
  equipment: {
    type: singleSelectType,
    label: 'Equipment',
    options: [
      { label: 'Dry Van', value: '53_dry_van' },
      { label: 'Reefer', value: '53_reefer' },
    ],
  },
  stops: {
    type: singleSelectType,
    label: 'Stops',
    options: [
      { label: 'Single PU/DEL', value: 'single' },
      { label: 'Multiple PU/DEL', value: 'multiple' },
    ],
  },
  dropType: {
    type: singleSelectType,
    label: 'Drop Type',
    options: [
      { label: 'Live Live', value: 'Live Live' },
      { label: 'Drop Live', value: 'Drop Live' },
      { label: 'Live Drop', value: 'Live Drop' },
      { label: 'Drop Drop', value: 'Drop Drop' },
    ],
  },
  won: {
    type: switchType,
    label: 'Won',
  },
}

export const QUOTES_FILTERS_CONFIG_WITH_SOURCE = {
  ...QUOTES_FILTERS_CONFIG,
  source: {
    type: singleSelectType,
    label: 'Source',
    options: [
      { label: 'Spot Rates Tool', value: 'rates_tool' },
      { label: 'Spot Quote API', value: 'spot_quotes_api' },
    ],
  },
}

export const SORT_OPTIONS_CONFIG = [
  {
    label: 'Most Recent',
    value: ISortOrder.desc,
  },
  {
    label: 'Oldest',
    value: ISortOrder.asc,
  },
]

export const COMMUNICATION_METHOD_ICONS: Record<string, typeof SvgIcon> = {
  customer_portal: DashboardIcon,
  phone: PhoneIcon,
  email: EmailIcon,
  load_board: SearchListIcon,
}
