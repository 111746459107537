import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useHomeRouteHref } from '#hooks'
import { useAuthContext } from 'auth/b2b'
import { EmailTag } from 'auth/common/components/EmailTag'
import { useToastContext } from 'dpl'
import { Card, CardContent, CardHeader, makeStyles, Stack, Typography } from 'dpl/core'
import { TransfixLogoIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme/colors'
import debounce from 'lodash/debounce'
import { useRequest } from 'network'
import { useUserDetailsQuery } from '../../../../graphql/UserDetails'
import { IOnboardFormProps, OnboardForm } from './components'
import { useOnboardCurrentUserMutation } from './graphql/OnboardCurrentUser'

const useOnboardCardStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    border: 'none',
    width: '100%',
    borderRadius: 0,

    [theme.breakpoints.up('md')]: {
      borderRadius: theme.spacing(1.5),
      maxWidth: 500,
      minWidth: 500,
      border: `1px solid ${theme.palette.divider}`,
    },
  },
}))

export function OnboardCard() {
  const classes = useOnboardCardStyles()
  const navigate = useNavigate()
  const { authClient } = useAuthContext()
  const { openToast } = useToastContext()
  const [onboardCurrentUser, { loading }] = useOnboardCurrentUserMutation()
  const { data } = useUserDetailsQuery()
  const { currentSession } = data ?? {}
  const { user } = currentSession ?? {}
  const { emailAddress, firstName, lastName } = user ?? {}
  const homeRouteHref = useHomeRouteHref()

  const [getPasswordStrength, { data: passwordStrengthData }] = useRequest({
    requestFn: authClient.passwords.checkStrength,
  })
  const { score: passwordStrengthScore } = passwordStrengthData || {}

  const debouncedGetStrength = debounce((value: string) => {
    if (value) getPasswordStrength({ password: value, email_address: emailAddress || null })
  }, 250)

  const passwordChangeHandler = useCallback(
    ({ target: { value = '' } }) => {
      debouncedGetStrength(value)
    },
    [debouncedGetStrength]
  )

  const submitHandler = useCallback<IOnboardFormProps['onSubmit']>(
    async input => {
      const { data } = await onboardCurrentUser({
        variables: {
          input,
        },
      })
      const { onboardCurrentUser: payload } = data ?? {}
      const { errors } = payload ?? {}
      if (errors && errors.length > 0) {
        openToast({
          toastMessage: errors[0].message,
          toastType: 'alert',
        })
      } else {
        await authClient.sessions.authenticate()
        navigate(homeRouteHref, { replace: true })
      }
    },
    [authClient.sessions, homeRouteHref, navigate, onboardCurrentUser, openToast]
  )

  const defaultValues = useMemo(
    () => ({
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    }),
    [firstName, lastName]
  )

  return (
    <Card className={classes.card} data-test='onboard-card'>
      <CardHeader
        title={
          <Stack spacing={2}>
            <TransfixLogoIcon size='xxlarge' />
            <EmailTag email={emailAddress ?? ''} />
            <Typography color={brandColors.coolGray8} variant='h2'>
              Let&apos;s set up your profile
            </Typography>
          </Stack>
        }
        subheader={
          <Typography color={brandColors.coolGray8} variant='body1'>
            This will only take a couple minutes
          </Typography>
        }
      />

      <CardContent>
        <OnboardForm
          defaultValues={defaultValues}
          isLoading={loading}
          passwordStrengthScore={passwordStrengthScore}
          onPasswordChange={passwordChangeHandler}
          onSubmit={submitHandler}
        />
      </CardContent>
    </Card>
  )
}
