import React, { Dispatch, SetStateAction, useMemo, useCallback } from 'react'
import { usePermissionsContext } from 'auth/common/context'
import { AccessRestrictedModal } from 'dpl'
import { OverflowMenu } from 'dpl/components/OverflowMenu'
import { Box, Button, Typography } from 'dpl/core'
import DropdownDownIcon from 'dpl/icons/build/DropdownDownIcon'
import DropdownUpIcon from 'dpl/icons/build/DropdownUpIcon'
import { brandColors } from 'dpl/theme/colors'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { IContractRfpNodeFragment } from '../../../graphql/ContractRFPNodeFragment'
import { RestartRoundModal } from './RestartRoundModal'
import { SubmittedInfo } from './SubmittedInfo'

interface RoundsDropdownProps {
  rounds: IContractRfpNodeFragment['versions']
  currentRoundId: string
  selectedRoundId: string
  setSelectedRoundId: Dispatch<SetStateAction<string | undefined>>
  contractId: string
  displayId: number
}

export function RoundsDropdown({
  contractId,
  currentRoundId,
  displayId,
  rounds,
  selectedRoundId,
  setSelectedRoundId,
}: RoundsDropdownProps) {
  const { createToggleHandler, modalState, toggleModal } = useModalState({
    restrictedModal: false,
    restartRoundModal: false,
  })
  const { userPermissions } = usePermissionsContext()
  const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()
  const DropdownIcon = expanded ? DropdownUpIcon : DropdownDownIcon
  const selectedRound = rounds?.find(round => round?.id === selectedRoundId)
  const latestLabel = selectedRoundId === currentRoundId && rounds.length > 1 ? ' (Latest)' : ''

  const restartRoundHandler = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()

      if (!userPermissions['contract_rfp.update_rfp_versions']) {
        toggleModal('restrictedModal', true)
        return
      }

      toggleModal('restartRoundModal', true)
      setSelectedRoundId(currentRoundId)
      handleClose()
    },
    [currentRoundId, handleClose, setSelectedRoundId]
  )

  const options = useMemo(() => {
    if (!rounds) return []

    return orderBy(rounds, 'number', 'desc').map(round => ({
      label: `round-${round?.number}`,
      itemComponent: (
        <Box
          data-test={`round-item-round-${round?.number}`}
          minWidth='220px'
          display='flex'
          flexDirection='column'>
          <Typography variant='subtitle2'>Round {round?.number}</Typography>
          <Typography variant='caption' color={brandColors.coolGray5}>
            Created {moment(round?.createdAt).format('MMM D, YYYY')}
          </Typography>
          <SubmittedInfo
            isCurrentRound={round.id === currentRoundId}
            laneCount={round.lanes.totalCount ?? 0}
            submittedAt={round.submittedAt ?? ''}
            restartRoundHandler={restartRoundHandler}
          />
        </Box>
      ),
      action: () => {
        setSelectedRoundId(round?.id)
      },
      id: round.id,
    }))
  }, [currentRoundId, restartRoundHandler, rounds, setSelectedRoundId])

  if (!rounds) return null

  if (options.length < 2 && rounds[0].lanes.totalCount === 0) {
    return (
      <Box p={0.75}>
        <Typography variant='button' color={brandColors.coolGray5}>
          Round 1
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <OverflowMenu
        dataTest='rounds-list'
        options={options}
        dropdownId='rfp-rounds'
        anchorEl={anchorEl}
        expanded={expanded}
        onClose={handleClose}
        onSelectMenuItem={handleClose}
        selectedOptionId={selectedRoundId}
        trigger={
          <Button
            onClick={handleClick}
            variant='text'
            size='xsmall'
            endIcon={<DropdownIcon size='large' />}>
            Round {selectedRound?.number}
            {latestLabel}
          </Button>
        }
      />
      <RestartRoundModal
        open={modalState.restartRoundModal}
        onClose={createToggleHandler('restartRoundModal', false)}
        roundNumber={options.length}
        currentRoundId={currentRoundId}
        contractId={contractId}
        displayId={displayId}
      />
      {modalState.restrictedModal && (
        <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
      )}
    </>
  )
}
