export interface IIsSuggestedValueOverriddenProps {
  quotedCostPercentile: Nullable<number>
  quotedCostShiftPercentage: Nullable<number>
  suggestedCostPercentile: Nullable<number>
  suggestedCostShiftPercentage: Nullable<number>
}

/**
 * @param quotedCostPercentile The actual cost percentile used to determine the quoted rate
 * @param quotedCostShiftPercentage The actual cost shift percentage used to determine the quoted rate
 * @param suggestedCostPercentile The suggested cost shift percentage based on applied rules and threshold limits
 * @param suggestedCostShiftPercentage The suggested cost shift percentage based on applied rules and threshold limits
 * @returns Returns true if costPercentile is present and the actual values used are equal to the suggested
 */
export const isSuggestedValueOverridden = ({
  quotedCostPercentile,
  quotedCostShiftPercentage,
  suggestedCostPercentile,
  suggestedCostShiftPercentage,
}: IIsSuggestedValueOverriddenProps) => {
  if (!quotedCostPercentile) return false
  return (
    quotedCostPercentile !== suggestedCostPercentile ||
    quotedCostShiftPercentage !== suggestedCostShiftPercentage
  )
}
