export const getDecodedData = (dataRaw: string): Record<string, any> => {
  let data = {}

  try {
    data = JSON.parse(decodeURI(dataRaw))
  } catch (e) {
    // TODO: set up error logging in wilson
    // log('rogers', e, { extra: { dataRaw } })
  }

  return data
}
