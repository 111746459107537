import React, { memo, useMemo } from 'react'
import { DataGridFilters } from 'dpl/components/DataGrid/components/DataGridFilters'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { getAdjustmentRulesFiltersConfig } from './adjustmentRulesFiltersConfig'
import { DEFAULT_FILTER_VALUES, SORT_OPTIONS_CONFIG } from './constants'
import { IAdjustmentRulesDataGridFilters } from './types'

export interface IAdjustmentRulesFiltersProps
  extends Pick<IAdjustmentRulesDataGridFilters, 'defaultSortValue' | 'onFilterOrSortChange'> {
  /**
   * @default 'AdjustmentRulesFilters'
   */
  dataTest?: string
}

export const AdjustmentRulesFilters = memo<IAdjustmentRulesFiltersProps>(
  ({ dataTest = 'AdjustmentRulesFilters', defaultSortValue, onFilterOrSortChange }) => {
    const { isFlagEnabled } = useFlagsContext()
    const isDoNotBidRulesEnabled = isFlagEnabled('spot_rates_control_panel_rules_dnb')

    const filtersConfig = useMemo(
      () => getAdjustmentRulesFiltersConfig({ isDoNotBidRulesEnabled }),
      [isDoNotBidRulesEnabled]
    )

    return (
      <DataGridFilters
        dataTest={dataTest}
        defaultFilterValues={DEFAULT_FILTER_VALUES}
        defaultSortValue={defaultSortValue}
        filtersConfig={filtersConfig}
        onFilterOrSortChange={onFilterOrSortChange}
        sortOptions={SORT_OPTIONS_CONFIG}
        visibleFiltersLimit={2}
      />
    )
  }
)
