import React, { useMemo, useState } from 'react'
import { LaneMapCard } from '#components/LaneMapCard'
import { RatesSegmentedToggle } from '#components/RatesSegmentedToggle'
import { DEFAULT_MARGIN_PERCENTAGE } from '#constants/rates'
import { useAnalytics } from '#hooks'
import { IQueryFetchContractRatesArgs } from '#types/graphqlTypes'
import { IEstimate } from '#types/rates'
import { useToastContext } from 'dpl'
import { Box, Grid, Typography } from 'dpl/core'
import { PageTemplate } from 'dpl/templates'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { EstimateCard } from '../EstimateCard'
import { useFetchContractRatesLazyQuery } from '../graphql/FetchContractRatesQuery'
import { EmptyLaneInformationResults, LoadingContractRatesToolResults } from './components'
import { LaneInformationHeader } from './components/LaneInformationHeader/LaneInformationHeader'
import { ProjectedMarginCard } from './components/ProjectedMarginCard'
import { ContractLaneInformationForm } from './forms/ContractLaneInformationForm/ContractLaneInformationForm'
import { getContractDurationFromMonthlyCost } from './utils/getContractDurationFromMonthlyCost'

export function ContractRatesToolPage() {
  const { isFlagEnabled } = useFlagsContext()

  const isProjectedMarginEnabled = useMemo(
    () => isFlagEnabled('contract_rates_tool_projected_margin'),
    [isFlagEnabled]
  )

  const { openToast } = useToastContext() || {}
  const { trackEvent } = useAnalytics()
  const [contractLaneFormData, setContractLaneFormData] = useState<IQueryFetchContractRatesArgs>()
  const [fetchContractRatesQuery, { data, loading: isFetchContractRatesLoading }] =
    useFetchContractRatesLazyQuery()

  const { fetchContractRates } = data || {}
  const {
    equipmentKey,
    isFlatVolume,
    isTeam,
    marketCosts,
    miles,
    reeferTempMax,
    reeferTempMin,
    stops,
  } = fetchContractRates || {}
  const showResult = !isFetchContractRatesLoading && !!fetchContractRates
  const showEmptyState = !isFetchContractRatesLoading && !fetchContractRates
  const { marketSimAvg = {} } = marketCosts || {}
  const monthlyCost = marketSimAvg?.monthlyCost ?? []

  const contractDuration = getContractDurationFromMonthlyCost(monthlyCost)

  const [rateEstimate, setRateEstimate] = useState<IEstimate>({
    carrierRate: 0,
    marginPercentage: DEFAULT_MARGIN_PERCENTAGE,
    marginValue: 0,
  })

  const onSubmitHandler = async (formData: IQueryFetchContractRatesArgs) => {
    setContractLaneFormData(formData)
    try {
      const { data } = await fetchContractRatesQuery({
        fetchPolicy: 'network-only',
        variables: formData,
        onError: apolloErrors => {
          openToast({
            toastMessage: 'Something went wrong. Please try again.',
            toastType: 'alert',
          })
          trackEvent('Contract Rates Tool', 'GENERATE_CONTRACT_RATE_ERROR', {
            apolloErrors,
          })
        },
      })

      const { fetchContractRates: fetchContractRatesResponse } = data || {}

      if (fetchContractRatesResponse) {
        trackEvent('Contract Rates Tool', 'GENERATE_CONTRACT_RATE_SUCCESS', {
          formData,
          fetchContractRatesResponse,
        })
      }
    } catch (error) {
      openToast({
        toastMessage: 'Something went wrong. Please try again.',
        toastType: 'alert',
      })
      trackEvent('Contract Rates Tool', 'RUNTIME_ERROR', {
        error,
      })
    }
  }

  return (
    <PageTemplate pageTitle='Contract Rates' dataTest='contract-rates-tool-route'>
      <Box display='flex' flexDirection='column'>
        <Box
          display='flex'
          alignItems='center'
          gap={2}
          justifyContent='space-between'
          marginBottom={1.5}>
          <Box display='flex' alignItems='center' gap={2.5} flexWrap='wrap'>
            <Typography variant='h4'>Lane Information</Typography>
            <RatesSegmentedToggle currentTool='contractRatesTool' />
          </Box>
        </Box>
        <Box marginBottom={3}>
          <ContractLaneInformationForm
            isLoading={isFetchContractRatesLoading}
            onSubmit={data => {
              onSubmitHandler(data)
            }}
          />
        </Box>
        {showEmptyState && <EmptyLaneInformationResults />}
        {isFetchContractRatesLoading && <LoadingContractRatesToolResults />}
        {showResult && (
          <div>
            <Box pb={4}>
              <LaneInformationHeader
                contractDuration={contractDuration}
                isTeam={isTeam}
                isFlatVolume={isFlatVolume}
                stops={stops}
                reeferTempMin={reeferTempMin}
                reeferTempMax={reeferTempMax}
                equipmentKey={equipmentKey}
                contractLaneFormData={contractLaneFormData}
              />
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <EstimateCard
                  contractDuration={contractDuration}
                  rateEstimate={rateEstimate}
                  setRateEstimate={setRateEstimate}
                  marketSimAvg={marketSimAvg}
                />
                {isProjectedMarginEnabled && (
                  <Grid mt={2}>
                    <ProjectedMarginCard
                      contractDuration={contractDuration}
                      rateEstimate={rateEstimate}
                      marketSimAvg={marketSimAvg}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                <LaneMapCard stops={stops ?? []} miles={miles!} />
              </Grid>
            </Grid>
          </div>
        )}
      </Box>
    </PageTemplate>
  )
}
