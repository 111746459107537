/* eslint-disable no-console */
import { useCallback, useState, useEffect, useRef, RefObject } from 'react'
import isFunction from 'lodash/isFunction'

export function useToggle<T extends HTMLElement>(
  initialState?: boolean,
  eventListener?: (expanded: boolean) => void,
  /**
   * Function that determines if the click listener should prevent
   * toggling the state and running the eventListener prop.
   * Can be used to prevent toggle when a portal is open and clicked on
   */
  preventToggle?: (e: MouseEvent) => void
): [boolean, (effect?: Function, ...args: any[]) => void, RefObject<T>] {
  const [isActive, setActive] = useState(initialState || false)

  const wrapperRef = useRef<T>(null)

  const listener = useCallback(
    (e: MouseEvent) => {
      const shouldPreventToggle = preventToggle && preventToggle(e)
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target as Node) &&
        !shouldPreventToggle
      ) {
        const newValue = !isActive
        if (eventListener) {
          eventListener(newValue)
        }
        setActive(newValue)
      }
    },
    [preventToggle, isActive, eventListener]
  )

  const toggleWithEffect = useCallback(
    (effect?: Function, ...args: any[]) => {
      if (effect && isFunction(effect)) {
        effect(...args)
      }

      setActive(!isActive)
    },
    [isActive]
  )

  useEffect(() => {
    if (isActive) window.addEventListener('mousedown', listener)

    return () => {
      window.removeEventListener('mousedown', listener)
    }
  }, [isActive, listener])

  /* Handle ref not being set */
  useEffect(() => {
    if (isActive && !wrapperRef.current) {
      console.warn("The ref isn't attached to a React element")
    }
  }, [isActive])

  return [isActive, toggleWithEffect, wrapperRef]
}
