import { IDaysOfWeek } from '../../../../../../types/daysOfWeek'
import { IRgCarrierRateFragment } from '../../../graphql/RGCarrierRateFragment'
import { TRoutingGuideCarrierSchema } from '../schema'
import { IAvailabilityType } from '../types'

export const getRoutingGuideCarrierSchemaAvailability = (
  carrierRate: Pick<IRgCarrierRateFragment, 'routingGuideCarrierCapacity'>
): Pick<TRoutingGuideCarrierSchema, 'availability' | 'availabilityDays'> => {
  const { routingGuideCarrierCapacity } = carrierRate || {}
  const {
    fridayCapacity,
    mondayCapacity,
    saturdayCapacity,
    sundayCapacity,
    thursdayCapacity,
    tuesdayCapacity,
    wednesdayCapacity,
  } = routingGuideCarrierCapacity || {}

  const availableDays = [
    { hasCapacity: mondayCapacity, day: IDaysOfWeek.mon },
    { hasCapacity: tuesdayCapacity, day: IDaysOfWeek.tue },
    { hasCapacity: wednesdayCapacity, day: IDaysOfWeek.wed },
    { hasCapacity: thursdayCapacity, day: IDaysOfWeek.thu },
    { hasCapacity: fridayCapacity, day: IDaysOfWeek.fri },
    { hasCapacity: saturdayCapacity, day: IDaysOfWeek.sat },
    { hasCapacity: sundayCapacity, day: IDaysOfWeek.sun },
  ].filter(({ hasCapacity }) => hasCapacity)

  if (availableDays.length === 7) {
    return {
      availability: IAvailabilityType.anyDay,
      availabilityDays: [],
    }
  }

  return {
    availability: IAvailabilityType.specificDays,
    availabilityDays: availableDays.map(({ day }) => day),
  }
}
