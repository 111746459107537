import React from 'react'
import { Card, CardContent, makeStyles, Typography } from 'dpl/core'
import { RoutingGuideUnavailableIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme'

const useRoutingGuideUnavailableCardStyles = makeStyles(theme => ({
  root: {
    border: 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundColor: brandColors.coolGray1,
    width: theme.spacing(9),
    height: theme.spacing(9),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    '& > .MuiSvgIcon-root': {
      // Custom icon size per designs
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}))

export function RoutingGuideUnavailableCard() {
  const classes = useRoutingGuideUnavailableCardStyles()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.iconContainer}>
          <RoutingGuideUnavailableIcon color='coolGray5' />
        </div>
        <div>
          <Typography variant='h4' fontWeight={500} textAlign='center' marginBottom={1}>
            Lane no longer available
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}
