import React from 'react'
import { Link } from 'dpl/core'

export interface ILinkProps {
  name: string
  link: string
}

export function WebLink({ link, name }: ILinkProps) {
  return (
    <div>
      <Link href={link} target='_blank' rel='noreferrer'>
        {name || link}
      </Link>
    </div>
  )
}
