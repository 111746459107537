import { useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordOrMagicLinkLoginFormSchema, TPasswordOrMagicLinkLoginFormSchema } from '../schema'

interface IUsePasswordFormProps
  extends Omit<UseFormOptions<TPasswordOrMagicLinkLoginFormSchema>, 'resolver'> {}

export const usePasswordOrMagicLinkLoginForm = ({
  defaultValues = {},
  ...props
}: IUsePasswordFormProps) => {
  return useForm<TPasswordOrMagicLinkLoginFormSchema>({
    resolver: yupResolver(PasswordOrMagicLinkLoginFormSchema),
    defaultValues: PasswordOrMagicLinkLoginFormSchema.cast(defaultValues),
    ...props,
  })
}
