import React, { useEffect } from 'react'
import { Controller, UseFormMethods, useWatch } from 'react-hook-form'
import { FormHelperText } from 'dpl/components/FormHelperText'
import { SegmentedToggleButton } from 'dpl/components/SegmentedToggleButton'
import { ISliderProps } from 'dpl/components/Slider'
import { Tooltip } from 'dpl/components/Tooltip'
import { Collapse, makeStyles, Stack } from 'dpl/core'
import Box from 'dpl/core/Box'
import Typography from 'dpl/core/Typography'
import InfoIcon from 'dpl/icons/build/InfoIcon'
import { ControlledSlider } from 'forms/components/ControlledSlider'
import ControlledSwitch from 'forms/components/ControlledSwitch'
import { AdvancedInputsSchema } from '../../laneInformationFormSchema'

const LEAD_TIME_OPTIONS = [
  { value: 'days', label: 'Days' },
  { value: 'hours', label: 'Hours' },
]

const SLIDER_PROPS: ISliderProps = {
  min: 0,
  max: 24,
  step: 0.5,
  valueLabelDisplay: 'auto',
}

const useLeadTimeSectionStyles = makeStyles(theme => ({
  switch: {
    transform: `translate(${theme.spacing(1.25)})`,
    '& label': {
      marginRight: 0,
    },
  },
}))

const getLeadTimeLabel = (leadTime: Nullable<AdvancedInputsSchema['creationLeadTime']>) => {
  const { amount, scale } = leadTime || {}
  if (Number(amount) === 1) {
    return scale === 'days' ? '1 day' : '1 hour'
  }
  return `${amount} ${scale}`
}

export interface ILeadTimeSectionProps
  extends Pick<UseFormMethods<AdvancedInputsSchema>, 'control' | 'errors' | 'trigger'> {}

export function LeadTimeSection({ control, errors, trigger }: ILeadTimeSectionProps) {
  const classes = useLeadTimeSectionStyles()

  const { assignmentLeadTime, creationLeadTime, leadTimeSwitch } = useWatch<
    Pick<AdvancedInputsSchema, 'assignmentLeadTime' | 'creationLeadTime' | 'leadTimeSwitch'>
  >({
    control,
    name: ['leadTimeSwitch', 'assignmentLeadTime', 'creationLeadTime'],
  })
  const { amount: assignmentLeadTimeAmount, scale: assignmentLeadTimeScale } =
    assignmentLeadTime || {}
  const { amount: creationLeadTimeAmount, scale: creationLeadTimeScale } = creationLeadTime || {}
  const assignmentLeadTimeLabel = getLeadTimeLabel(assignmentLeadTime)
  const creationLeadTimeLabel = getLeadTimeLabel(creationLeadTime)

  /**
   * Trigger validation when lead time fields change
   */
  useEffect(() => {
    trigger('assignmentLeadTime')
  }, [
    assignmentLeadTimeAmount,
    assignmentLeadTimeScale,
    creationLeadTimeAmount,
    creationLeadTimeScale,
    trigger,
  ])

  return (
    <div>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center' gap={0.5}>
          <Typography variant='subtitle1'>Lead Time</Typography>
          <Tooltip
            title={
              <Box maxWidth={180}>
                <Typography variant='caption' component='div' align='center'>
                  Creation: Amount of time expected between creation/receipt of shipment tender and
                  the start of the pickup appointment window.
                  <br />
                  Assignment: Amount of time expected between carrier assignment and the start of
                  the pickup appointment window.
                </Typography>
              </Box>
            }
            placement='top'>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <InfoIcon size='medium' color='coolGray5' />
            </Box>
          </Tooltip>
        </Box>
        <div className={classes.switch}>
          <ControlledSwitch
            name='leadTimeSwitch'
            control={control}
            SwitchProps={{
              dataTest: 'lead-time-switch',
            }}
          />
        </div>
      </Box>
      <Collapse in={Boolean(leadTimeSwitch)} unmountOnExit>
        <Stack spacing={2}>
          <Typography variant='body1' component='div' data-test='creation-lead-time-label'>
            Creation Lead Time ({creationLeadTimeLabel})
          </Typography>
          <Controller
            control={control}
            name='creationLeadTime.scale'
            render={({ onChange, value }) => (
              <SegmentedToggleButton
                value={value}
                onChange={onChange}
                options={LEAD_TIME_OPTIONS}
                dataTest='creation-lead-time-scale'
              />
            )}
          />
          <ControlledSlider
            control={control}
            name='creationLeadTime.amount'
            SliderProps={{
              ...SLIDER_PROPS,
              dataTest: 'creation-lead-time-slider',
            }}
          />
          <Typography variant='body1' component='div' data-test='assignment-lead-time-label'>
            Assignment Lead Time ({assignmentLeadTimeLabel})
          </Typography>
          <Controller
            control={control}
            name='assignmentLeadTime.scale'
            render={({ onChange, value }) => (
              <SegmentedToggleButton
                value={value}
                onChange={onChange}
                options={LEAD_TIME_OPTIONS}
                dataTest='assignment-lead-time-scale'
              />
            )}
          />
          <ControlledSlider
            control={control}
            name='assignmentLeadTime.amount'
            SliderProps={{
              ...SLIDER_PROPS,
              dataTest: 'assignment-lead-time-slider',
            }}
          />
          <Collapse in={Boolean(errors.assignmentLeadTime)}>
            <FormHelperText data-test='lead-time-errors' hasError>
              {errors.assignmentLeadTime?.message}
            </FormHelperText>
          </Collapse>
        </Stack>
      </Collapse>
    </div>
  )
}
