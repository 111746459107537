import { useAirhornContext } from 'airhorn'
import { TSolutionsConsoleEvents } from '../constants/events'

export const useAnalytics = () => {
  const { clearUser, hasLoadedAirhorn, identifyUser, trackEvent } =
    useAirhornContext<TSolutionsConsoleEvents>()
  return {
    hasLoadedAirhorn,
    trackEvent,
    identifyUser,
    clearUser,
  }
}
