import React, { useCallback, useMemo, useState } from 'react'
import { SubmitHandler, SubmitErrorHandler } from 'react-hook-form'
import { getApolloErrorMessages } from 'dpl/components/ApolloErrorMessages'
import { FormModal, IFormModalProps, IModalAction } from 'dpl/components/Modals'
import { useAnalytics } from '../../../../../hooks/useAnalytics'
import { IError } from '../../../../../types/graphqlTypes'
import { IRgCarrierRateFragment } from '../../graphql/RGCarrierRateFragment'
import { RoutingGuideCarrierFormBody } from './components/RoutingGuideCarrierFormBody'
import { useCreateRoutingGuideCarrierRateMutation } from './graphql/CreateRoutingGuideCarrierRateMutation'
import { useUpdateRoutingGuideCarrierRateMutation } from './graphql/UpdateRoutingGuideCarrierRateMutation'
import { useRoutingGuideCarrierForm } from './hooks'
import { TRoutingGuideCarrierSchema } from './schema'
import { getRoutingGuideCarrierSchema, getUpdateRoutingGuideCarrierRateInput } from './utils'
import { getCreateRoutingGuideCarrierRateInput } from './utils/getCreateRoutingGuideCarrierRateInput'

export interface IRoutingGuideCarrierFormModalProps extends Pick<IFormModalProps, 'onClose'> {
  /**
   * ID of the Routing Guide Lane
   */
  routingGuideLaneId: string
  /**
   * Carrier Rate to be updated
   */
  carrierRate?: Nullable<IRgCarrierRateFragment>
  /**
   * Callback to be called when a carrier is added to the routing guide lane successfully
   * @returns
   */
  onCreateSuccess: () => void
  /**
   * Callback to be called when a carrier is updated successfully
   * @returns
   */
  onUpdateSuccess: () => void
  /**
   * @default 'RoutingGuideCarrierFormModal'
   */
  dataTest?: string
}

export function RoutingGuideCarrierFormModal({
  carrierRate,
  dataTest = 'RoutingGuideCarrierFormModal',
  onClose,
  onCreateSuccess,
  onUpdateSuccess,
  routingGuideLaneId,
}: IRoutingGuideCarrierFormModalProps) {
  const { trackEvent } = useAnalytics()
  const [mutationErrors, setMutationErrors] = useState<IError[]>([])

  const preFillFormValues = useMemo(() => {
    if (!carrierRate) return {}
    return getRoutingGuideCarrierSchema(carrierRate)
  }, [carrierRate])

  const isUpdateMode = useMemo(() => Boolean(carrierRate), [carrierRate])

  const { id: routingGuideCarrierRateId } = carrierRate || {}

  const {
    control,
    errors: formValidationErrors,
    handleSubmit,
    setValue,
  } = useRoutingGuideCarrierForm({
    defaultValues: preFillFormValues,
  })
  const [createRGCarrierRate, { error: createApolloErrors, loading: createLoading }] =
    useCreateRoutingGuideCarrierRateMutation()
  const [updateRGCarrierRate, { error: updateApolloErrors, loading: updateLoading }] =
    useUpdateRoutingGuideCarrierRateMutation()

  const errorMessages = getApolloErrorMessages({
    apolloErrors: createApolloErrors || updateApolloErrors,
    mutationErrors,
  })
  const loading = useMemo(() => createLoading || updateLoading, [createLoading, updateLoading])

  const closeHandler = useCallback(() => {
    setMutationErrors([])
    onClose()
  }, [onClose])

  const createHandler = useCallback<SubmitHandler<TRoutingGuideCarrierSchema>>(
    async formValues => {
      setMutationErrors([])
      const { data } = await createRGCarrierRate({
        variables: {
          input: getCreateRoutingGuideCarrierRateInput({ routingGuideLaneId, formValues }),
        },
      })
      const { createRoutingGuideCarrierRate } = data || {}
      const { carrierRate, errors = [] } = createRoutingGuideCarrierRate || {}
      const { id: newCarrierRateId } = carrierRate || {}

      if (errors.length) {
        trackEvent('Routing Guide', 'ADD_CARRIER_TO_ROUTING_GUIDE_LANE_ERROR', {
          formValues,
          mutationErrors: errors,
        })
        setMutationErrors(errors)
        return
      }

      if (newCarrierRateId) {
        trackEvent('Routing Guide', 'ADD_CARRIER_TO_ROUTING_GUIDE_LANE_SUCCESS', {
          formValues,
          newCarrierRateId,
        })
        onCreateSuccess()
      }
    },
    [createRGCarrierRate, onCreateSuccess, routingGuideLaneId, trackEvent]
  )

  const updateHandler = useCallback<SubmitHandler<TRoutingGuideCarrierSchema>>(
    async formValues => {
      setMutationErrors([])
      if (!routingGuideCarrierRateId) return

      const { data } = await updateRGCarrierRate({
        variables: {
          input: getUpdateRoutingGuideCarrierRateInput({
            formValues,
            routingGuideCarrierRateId,
          }),
        },
      })
      const { updateRoutingGuideCarrierRate } = data || {}
      const { carrierRate, errors = [] } = updateRoutingGuideCarrierRate || {}
      const { id: carrierRateId } = carrierRate || {}

      if (errors.length) {
        trackEvent('Routing Guide', 'UPDATE_CARRIER_RATE_ERROR', {
          formValues,
          mutationErrors: errors,
        })
        setMutationErrors(errors)
        return
      }

      if (carrierRateId) {
        trackEvent('Routing Guide', 'UPDATE_CARRIER_RATE_SUCCESS', {
          formValues,
          carrierRateId,
        })
        onUpdateSuccess()
      }
    },
    [onUpdateSuccess, routingGuideCarrierRateId, trackEvent, updateRGCarrierRate]
  )

  const submitHandler = useCallback<SubmitHandler<TRoutingGuideCarrierSchema>>(
    formValues => {
      setMutationErrors([])
      if (isUpdateMode) {
        updateHandler(formValues)
      } else {
        createHandler(formValues)
      }
    },
    [createHandler, isUpdateMode, updateHandler]
  )

  const formInvalidHandler = useCallback<SubmitErrorHandler<TRoutingGuideCarrierSchema>>(
    validationErrors => {
      if (isUpdateMode) {
        trackEvent('Routing Guide', 'UPDATE_CARRIER_RATE_VALIDATION_ERROR', {
          formErrors: validationErrors,
        })
      } else {
        trackEvent('Routing Guide', 'ADD_CARRIER_TO_ROUTING_GUIDE_LANE_VALIDATION_ERROR', {
          formErrors: validationErrors,
        })
      }
    },
    [isUpdateMode, trackEvent]
  )

  const actions: IModalAction[] = useMemo(
    () => [
      {
        label: 'Cancel',
        disabled: loading,
        type: 'dismiss',
        dataTest: `${dataTest}-cancel`,
        action: closeHandler,
      },
      {
        label: isUpdateMode ? 'Save Changes' : 'Add Carrier',
        disabled: loading,
        type: 'confirm',
        dataTest: `${dataTest}-submit`,
        action: handleSubmit(submitHandler, formInvalidHandler),
      },
    ],
    [closeHandler, dataTest, formInvalidHandler, handleSubmit, isUpdateMode, loading, submitHandler]
  )

  return (
    <FormModal
      title={isUpdateMode ? 'Edit Carrier Info' : 'Add Carrier'}
      actions={actions}
      dataTest={dataTest}
      open
      onClose={closeHandler}
      errors={errorMessages}
      includeCloseIconButton>
      <RoutingGuideCarrierFormBody
        control={control}
        errors={formValidationErrors}
        setValue={setValue}
        dataTest={dataTest}
        isUpdateMode={isUpdateMode}
      />
    </FormModal>
  )
}
