import * as yup from 'yup'
import { AutocompleteLocationSchema } from '../../../../../../components/ControlledAutocompleteLocation/schema'
import { organizationShipperSchema } from '../../../../../../components/ControlledAutocompleteOrganizationShipper/organizationShipperSchema'
import { AutocompleteOrganizationUserSchema } from '../../../../../../components/ControlledAutocompleteOrganizationUser/schema'
import { IEquipment } from '../../../../../../types/equipment'

export const RoutingGuideLaneSchema = yup.object({
  /**
   * Origin location for the lane.
   *
   * TODO: This will use the AutocompleteLocationSchema defined in CAPS-5155
   */
  origin: AutocompleteLocationSchema.nullable()
    .default(null)
    .required('You must select an origin city'),
  /**
   * Destination location for the lane.
   *
   * TODO: This will use the AutocompleteLocationSchema defined in CAPS-5155
   */
  destination: AutocompleteLocationSchema.nullable()
    .default(null)
    .required('You must select a destination city'),
  /**
   * Equipment type for the lane.
   */
  equipment: yup
    .mixed<IEquipment>()
    .default(IEquipment.dryVan)
    .required('You must select an equipment type'),
  /**
   * Customer for the lane.
   *
   * TODO: This will use the AutocompleteShipperSchema defined in CAPS-5156
   */
  customer: organizationShipperSchema
    .nullable()
    .default(null)
    .required('You must select a customer'),
  /**
   * The lane manager assigned to the lane.
   */
  laneManager: AutocompleteOrganizationUserSchema.nullable()
    .default(null)
    .required('You must assign a lane manager'),
})

export type TRoutingGuideLaneSchema = yup.InferType<typeof RoutingGuideLaneSchema>
