import turfAlong from '@turf/along'
import turfLength from '@turf/length'
import { ILatLonPosition } from '../types'

export const getArcGeoJSON = (
  start: ILatLonPosition,
  stop: ILatLonPosition,
  { arcSmoothness = 300 }: { arcSmoothness?: number } = {}
): GeoJSON.Feature<GeoJSON.LineString> => {
  const geometry: GeoJSON.LineString = {
    type: 'LineString',
    coordinates: [
      [start.lon, start.lat],
      [stop.lon, stop.lat],
    ],
  }

  const lane: GeoJSON.Feature<GeoJSON.LineString> = {
    type: 'Feature',
    geometry,
    properties: {},
  }

  const lineDistance: number = turfLength(lane)
  const arc: number[][] = []

  for (let i = 0; i < lineDistance; i += lineDistance / arcSmoothness) {
    const segment = turfAlong(lane, i)
    arc.push(segment.geometry.coordinates)
  }

  geometry.coordinates = arc

  return lane
}
