import { IRuleTypeEnum } from '#types/rates'
import { IRule } from '../types'

interface IGetBaseCostPercentileProps {
  defaultCostPercentile: number
  rules: IRule[]
}

interface IGetBaseCostPercentileReturn {
  percentile: number
  isDefaultCostPercentile: boolean
}

/**
 * Returns the base cost percentile for a provided set of rules.
 * If no base rule is found, the default cost percentile is returned.
 *
 * @returns {number} The base cost percentile
 *
 * @example getBaseCostPercentile({ rules: [], defaultCostPercentile: 50 }) // { percentile: 50, isDefaultCostPercentile: true }
 * @example getBaseCostPercentile({ rules: [{ ruleAction: 'base', adjustmentValue: 60 }], defaultCostPercentile: 50 }) // { percentile: 60, isDefaultCostPercentile: false }
 */
export const getBaseCostPercentile = ({
  defaultCostPercentile,
  rules,
}: IGetBaseCostPercentileProps): IGetBaseCostPercentileReturn => {
  // If there are no rules, return the default cost percentile
  if (rules.length === 0) {
    return {
      percentile: defaultCostPercentile,
      isDefaultCostPercentile: true,
    }
  }

  // Locate the base adjustment rule
  const baseAdjustmentRule = rules.find(({ ruleAction }) => ruleAction === IRuleTypeEnum.base)

  // If there is no base adjustment rule, return the default cost percentile
  if (!baseAdjustmentRule) {
    return {
      percentile: defaultCostPercentile,
      isDefaultCostPercentile: true,
    }
  }

  const { adjustmentValue: baseCostPercentile } = baseAdjustmentRule || {}

  // Return the base cost percentile
  return {
    percentile: baseCostPercentile || 0,
    isDefaultCostPercentile: false,
  }
}
