import React, { useCallback, useState } from 'react'
import { DataGridFilterOriginDestinationLocation } from '#components/DataGridFilterOriginDestinationLocation'
import { StopListModal } from '#components/StopListModal'
import { getLaneLiveAndDrop } from '#routes/contract-rfp/utils'
import {
  IContractRfpVersionLaneRateRateTypes,
  IContractRfpVersionLaneSortBy,
  IEquipmentKeys,
} from '#types/graphqlTypes'
import { getStopsWithRelativeSequence } from '#utils/getStopsWithRelativeSequence'
import { IUserPermissions } from 'auth/common/context/PermissionsContext/types'
import {
  brandColors,
  DataGridAdvancedTextCell,
  DataGridIconCell,
  IconButton,
  IDataGridFilterProps,
  IDataGridProps,
  ITooltipProps,
  OverflowMenu,
  Tooltip,
} from 'dpl'
import { DISPLAY } from 'dpl/constants'
import { Box, GridRenderCellParams, Link, Typography } from 'dpl/core'
import {
  DeleteIcon,
  DryVanIcon,
  EditIcon,
  FlatBedIcon,
  InfoIcon,
  LaneArrowIcon,
  MoreIcon,
  ReeferIcon,
  SpecializedDeprecatedIcon,
  ViewOnIcon,
} from 'dpl/icons'
import { ISvgIconProps } from 'dpl/icons/components/SvgIcon'
import { TIconComponent } from 'dpl/icons/types'
import { useMenuToggle } from 'dpl/utils/hooks/useMenuToggle'
import { useFlagsContext } from 'flags'
import orderBy from 'lodash/orderBy'
import startCase from 'lodash/startCase'
import { calculateRate, isCustomRate } from '../EditLaneSideNav/components/Estimate/utils'
import { DataGridFilterRangeWithOptions, IDataGridFilterRangeWithOptions } from './components'
import { DataGridContractMarginCell } from './components/DataGridContractMarginCell'
import { DataGridContractRateCell } from './components/DataGridContractRateCell'
import { ILane } from './utils'

const defaultTooltipProps = {
  placement: 'top' as ITooltipProps['placement'],
}
const defaultIconProps = {
  size: 'medium' as ISvgIconProps['size'],
}

export const EQUIPMENT_MAPPER: Record<
  IEquipmentKeys,
  {
    icon: TIconComponent
    label: string
  }
> = {
  [IEquipmentKeys.dry_van]: {
    icon: DryVanIcon,
    label: 'Dry Van',
  },
  [IEquipmentKeys.reefer]: {
    icon: ReeferIcon,
    label: 'Reefer',
  },
  [IEquipmentKeys.specialized]: {
    icon: SpecializedDeprecatedIcon,
    label: 'Specialized',
  },
  [IEquipmentKeys.open_deck]: {
    icon: FlatBedIcon,
    label: 'Flat Bed',
  },
}

export interface IGetColumnsParams {
  isDynamicVolumeEnabled: boolean
  onEditLane: (lane: ILane) => void
  onDeleteLane: (lane: ILane) => void
  onRestrictAccess: () => void
  userPermissions: IUserPermissions
  readOnly: boolean
}

export function getColumns({
  isDynamicVolumeEnabled,
  onDeleteLane,
  onEditLane,
  onRestrictAccess,
  readOnly,
  userPermissions,
}: IGetColumnsParams): IDataGridProps<ILane>['columns'] {
  return [
    { field: 'externalId', headerName: 'Lane ID', flex: 100, minWidth: 100 },
    {
      field: 'pickup',
      headerName: 'Lane',
      minWidth: 180,
      flex: 180,
      renderCell({ value }: GridRenderCellParams<ILane, ILane['pickup']>) {
        const { city, postalCode, stateCode } = value || {}
        return (
          <Typography variant='body2'>
            {city}, {stateCode} ({postalCode})
          </Typography>
        )
      },
    },
    {
      field: 'laneArrowMultiStop',
      headerName: '',
      flex: 56,
      minWidth: 56,
      renderCell: function Render({
        value,
      }: GridRenderCellParams<ILane, ILane['laneArrowMultiStop']>) {
        const { numStops, stops } = value || {}
        const hasMultiStops = numStops && numStops > 2
        const numIntermediateStops = hasMultiStops ? numStops - 2 : 0
        const [isModalOpen, setIsModalOpen] = useState(false)
        const sortedStops = orderBy(stops, 'stopSequence', 'asc')
        const stopList = getStopsWithRelativeSequence(sortedStops)
        const closeModal = useCallback(() => {
          setIsModalOpen(prev => !prev)
        }, [])
        const openModal = useCallback(e => {
          e.preventDefault()
          e.stopPropagation()
          setIsModalOpen(prev => !prev)
        }, [])

        return (
          <DataGridAdvancedTextCell alignment='center'>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <LaneArrowIcon size='large' />
              {hasMultiStops && (
                <Link
                  variant='caption'
                  color='textSecondary'
                  underline='always'
                  component='button'
                  onClick={openModal}
                  data-test='multi-stop-modal-button'>
                  {numIntermediateStops} Stops
                </Link>
              )}
            </Box>
            <StopListModal stops={stopList} open={isModalOpen} onClose={closeModal} />
          </DataGridAdvancedTextCell>
        )
      },
    },
    {
      field: 'delivery',
      headerName: '',
      minWidth: 180,
      flex: 180,
      renderCell({ value }: GridRenderCellParams<ILane, ILane['delivery']>) {
        const { city, postalCode, stateCode } = value || {}
        return (
          <Typography variant='body2'>
            {city}, {stateCode} ({postalCode})
          </Typography>
        )
      },
    },
    {
      field: 'miles',
      headerName: 'Miles',
      flex: 85,
      minWidth: 85,
      renderCell({ value }: GridRenderCellParams<ILane, ILane['miles']>) {
        return <Typography variant='body2'>{value ? Math.round(value) : '-'}</Typography>
      },
    },
    {
      field: 'equipment',
      headerName: 'Equipment/Drop Type',
      flex: 131,
      minWidth: 131,
      renderCell({ value }: GridRenderCellParams<ILane, ILane['equipment']>) {
        const { equipment: { key } = {}, isDeliveryDropTrailer, isPickupDropTrailer } = value || {}
        const { icon, label } = EQUIPMENT_MAPPER[key as IEquipmentKeys]

        return (
          <DataGridIconCell
            icon={icon}
            iconTooltipTitle={label}
            IconProps={defaultIconProps}
            TooltipProps={defaultTooltipProps}>
            <Typography variant='body2' whiteSpace='nowrap' margin={-1}>
              {getLaneLiveAndDrop(isPickupDropTrailer || false, isDeliveryDropTrailer || false)}
            </Typography>
          </DataGridIconCell>
        )
      },
    },
    {
      field: 'volume',
      headerName: isDynamicVolumeEnabled ? 'Total Volume' : 'Volume',
      flex: 90,
      minWidth: 90,
      renderCell: function Render({ row, value }: GridRenderCellParams<ILane, ILane['volume']>) {
        const { isDynamic, totalVolume, volumeAmount, volumeFrequency } = value || {}
        const { rate } = row || {}
        const { source } = rate || {}

        if (!totalVolume && source === 'auto_rate_gen' && isDynamicVolumeEnabled) {
          return (
            <DataGridAdvancedTextCell>
              <Box display='flex' alignItems='center' gap={1}>
                <Typography color='textSecondary'>{DISPLAY.empty}</Typography>
                <Tooltip title='Volume: Rate generated using 10 Monthly'>
                  <Box display='flex' alignItems='center'>
                    <InfoIcon color='coolGray4' size='large' />
                  </Box>
                </Tooltip>
              </Box>
            </DataGridAdvancedTextCell>
          )
        }

        if (!volumeAmount && !volumeFrequency && isDynamicVolumeEnabled) {
          return (
            <DataGridAdvancedTextCell>
              <Typography>{DISPLAY.empty}</Typography>
            </DataGridAdvancedTextCell>
          )
        }

        if (isDynamicVolumeEnabled && isDynamic) {
          return (
            <DataGridAdvancedTextCell
              bottomCaption={
                volumeFrequency ? `Dynamic ${startCase(volumeFrequency)}` : DISPLAY.empty
              }>
              <Typography>{totalVolume || volumeAmount || DISPLAY.empty}</Typography>
            </DataGridAdvancedTextCell>
          )
        }

        if (!isDynamicVolumeEnabled) {
          return (
            <DataGridAdvancedTextCell
              bottomCaption={volumeFrequency ? startCase(volumeFrequency) : DISPLAY.empty}>
              <Typography>{volumeAmount || DISPLAY.empty}</Typography>
            </DataGridAdvancedTextCell>
          )
        }
        return (
          <DataGridAdvancedTextCell
            bottomCaption={
              volumeFrequency
                ? `${volumeAmount ?? ''} ${startCase(volumeFrequency)}`.trim()
                : DISPLAY.empty
            }>
            <Typography>{totalVolume || volumeAmount || DISPLAY.empty}</Typography>
          </DataGridAdvancedTextCell>
        )
      },
    },
    {
      field: 'rate',
      headerName: 'Contract Rate',
      flex: 118,
      minWidth: 118,
      align: 'right',
      headerAlign: 'right',
      renderCell({ row, value }: GridRenderCellParams<ILane, ILane['rate']>) {
        const {
          carrierRate,
          createdAt,
          fuelRate,
          rateType: rateTypeProp,
          shipperRate,
          source,
        } = value || {}
        const { margin, miles, rategenMetadata } = row || {}
        const { status } = rategenMetadata || {}
        const { marginAmount, marginPercentage } = margin || {}
        const rateType = rateTypeProp as IContractRfpVersionLaneRateRateTypes
        const isCustom = isCustomRate({
          shipperRate,
          fuelRate,
          marginAmount,
          rateType,
        })
        const { contractRate } = calculateRate({
          carrierRate,
          fuelRate,
          marginAmount,
          marginPercentage,
          miles,
          rateType,
          shipperRate,
          strategy: isCustom ? 'custom' : 'calculated',
        })
        const rateTypeLabel = startCase(rateType || '')

        return (
          <DataGridContractRateCell
            rateTypeLabel={rateTypeLabel}
            contractRate={contractRate}
            status={status}
            source={source}
            createdAt={createdAt}
          />
        )
      },
    },
    {
      field: 'margin',
      headerName: 'Margin',
      flex: 90,
      minWidth: 90,
      align: 'right',
      headerAlign: 'right',
      renderCell({ row, value }: GridRenderCellParams<ILane, ILane['margin']>) {
        const { rategenMetadata } = row || {}
        const { status } = rategenMetadata || {}
        const { marginAmount = 0, marginPercentage } = value || {}
        const color = (marginAmount ?? 0) < 0 ? brandColors.error : brandColors.green3
        return (
          <DataGridContractMarginCell
            status={status}
            color={color}
            marginAmount={marginAmount}
            marginPercentage={marginPercentage}
          />
        )
      },
    },
    {
      field: 'edit',
      headerName: '',
      width: 64,
      renderCell: function Render({ row }) {
        const Icon = readOnly ? ViewOnIcon : EditIcon

        return (
          <IconButton
            data-test='edit-lane-button'
            Icon={Icon}
            size='large'
            color='secondary'
            onClick={() => onEditLane(row)}
          />
        )
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 52,
      renderCell: function Render({ row }) {
        const { anchorEl, expanded, handleClick, handleClose } = useMenuToggle()

        return (
          <OverflowMenu
            dataTest='lane-overflow-menu'
            dropdownId='lane-overflow-menu'
            anchorEl={anchorEl}
            expanded={expanded}
            onClose={handleClose}
            onSelectMenuItem={handleClose}
            options={[
              {
                action: () => {
                  if (!userPermissions['contract_rfp.delete_rfp_version_lanes']) {
                    onRestrictAccess()
                    return
                  }

                  onDeleteLane(row)
                },
                itemComponent: (
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    data-test='overflow-menu-delete-option'>
                    <DeleteIcon size='large' color='error1' />
                    <Typography ml={1} color={brandColors.error1}>
                      Delete Lane
                    </Typography>
                  </Box>
                ),
                label: 'archive',
              },
            ]}
            trigger={
              readOnly ? (
                <Tooltip title='Action unavailable for this RFP status'>
                  <span>
                    <IconButton
                      label='overflow-trigger'
                      Icon={MoreIcon}
                      onClick={handleClick}
                      size='xlarge'
                      color='secondary'
                      disabled={readOnly}
                    />
                  </span>
                </Tooltip>
              ) : (
                <IconButton
                  label='overflow-trigger'
                  Icon={MoreIcon}
                  onClick={handleClick}
                  size='xlarge'
                  color='secondary'
                  disabled={readOnly}
                />
              )
            }
          />
        )
      },
    },
  ]
}

export const SORT_OPTIONS_DEPRECATED = [
  {
    label: 'As Imported',
    value: IContractRfpVersionLaneSortBy.import_order as string,
  },
  { label: 'Rate (High-Low)', value: `${IContractRfpVersionLaneSortBy.rate}:desc` },
  { label: 'Rate (Low-High)', value: `${IContractRfpVersionLaneSortBy.rate}:asc` },
  { label: 'Volume (High-Low)', value: `${IContractRfpVersionLaneSortBy.volume}:desc` },
  { label: 'Volume (Low-High)', value: `${IContractRfpVersionLaneSortBy.volume}:asc` },
]

export const SORT_OPTIONS = [
  {
    label: 'As Imported',
    value: IContractRfpVersionLaneSortBy.import_order as string,
  },
  { label: 'Rate (High-Low)', value: `${IContractRfpVersionLaneSortBy.rate}:desc` },
  { label: 'Rate (Low-High)', value: `${IContractRfpVersionLaneSortBy.rate}:asc` },
  {
    label: 'Volume (High-Low)',
    value: `${IContractRfpVersionLaneSortBy.volume_total_amount}:desc`,
  },
  { label: 'Volume (Low-High)', value: `${IContractRfpVersionLaneSortBy.volume_total_amount}:asc` },
]

export const VOLUME_FREQUENCY_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' },
]

export const RATE_TYPE_OPTIONS = [
  { label: 'All-In', value: 'all_in' },
  { label: 'Linehaul', value: 'linehaul' },
]

export const DROP_TYPE_OPTIONS = [
  { label: 'Live-Live', value: 'live_live' },
  { label: 'Live-Drop', value: 'live_drop' },
  { label: 'Drop-Live', value: 'drop_live' },
  { label: 'Drop-Drop', value: 'drop_drop' },
]

export const FILTERS_CONFIG: IDataGridProps<ILane>['filtersConfig'] = {
  laneId: {
    type: 'textInput',
    label: 'Lane ID',
    placeholder: 'Lane ID',
  },
  location: {
    type: 'custom',
    FilterComponent: DataGridFilterOriginDestinationLocation,
  },
  equipmentType: {
    type: 'multiSelect',
    label: 'Equipment',
    options: Object.entries(EQUIPMENT_MAPPER).map(([key, { label }]) => ({
      label,
      value: key,
    })),
  },
  dropType: {
    type: 'multiSelect',
    label: 'Drop Type',
    options: DROP_TYPE_OPTIONS,
  },
  volume: {
    type: 'custom',
    FilterComponent: null,
    FilterMenuComponent: function Render(
      props: IDataGridFilterProps<IDataGridFilterRangeWithOptions>
    ) {
      const { isFlagEnabled } = useFlagsContext()
      const isDynamicVolumeEnabled = isFlagEnabled('rfp_dynamic_volume')

      return (
        <DataGridFilterRangeWithOptions
          {...props}
          label={isDynamicVolumeEnabled ? 'Total Volume' : 'Volume'}
          maxValueLabel='Max Volume Amount'
          minValueLabel='Min Volume Amount'
          blankLabel='Volume Amount Blank'
          options={isDynamicVolumeEnabled ? undefined : VOLUME_FREQUENCY_OPTIONS}
        />
      )
    },
  },
  teamRequired: {
    type: 'toggleButton',
    label: 'Team Required',
  },
  rate: {
    type: 'custom',
    FilterComponent: null,
    FilterMenuComponent: (props: IDataGridFilterProps<IDataGridFilterRangeWithOptions>) => (
      <DataGridFilterRangeWithOptions
        {...props}
        label='Rate'
        maxValueLabel='Max Rate'
        minValueLabel='Min Rate'
        blankLabel='Rate Blank'
        options={RATE_TYPE_OPTIONS}
      />
    ),
  },
}
