export const PROTECTED_PATHS = {
  home: '/',
  userProfile: '/my-profile',
  organizationProfile: '/organization',
  quotes: '/quotes',
  routingGuideLanes: '/routing-guide',
  routingGuideLaneCreate: '/routing-guide/create',
  contractRatesTool: '/rates-contract',
  spotRatesTool: '/rates-spot',
  rates: '/rates',
  spotRatesControlPanel: '/spot-rates-control-panel',
  onboard: '/onboard',
  customers: '/customers',
  customerCreate: '/customers/create',
  customerEdit: '/customers/edit',
  customerDeactivate: '/customers/deactivate',
  contractRFPs: '/contract-rfps',
  reports: '/reports',
}

export const UNPROTECTED_PATHS = {
  authenticate: '/authenticate',
  login: '/login',
  resetPassword: '/reset-password',
  magicLink: '/login/magic-link',
}
