import React from 'react'
import { IActivityEventTypesEnum, ICarrier } from '#types/graphqlTypes'
import { Typography } from 'dpl/core'

type IDataGridEventTypeCellProps = {
  carrier?: Pick<ICarrier, 'legalName'> | null
  eventType: IActivityEventTypesEnum
}

export function DataGridEventTypeCell({ carrier, eventType }: IDataGridEventTypeCellProps) {
  const { legalName } = carrier || {}
  let displayEventType = '--'

  if (eventType === IActivityEventTypesEnum.created_lane) {
    displayEventType = 'Created lane'
  } else if (eventType === IActivityEventTypesEnum.updated_lane) {
    displayEventType = 'Updated lane info'
  } else if (eventType === IActivityEventTypesEnum.deactivated_carrier) {
    displayEventType = `Deactivated ${legalName || 'carrier'}`
  } else if (eventType === IActivityEventTypesEnum.added_carrier) {
    displayEventType = `Added ${legalName || 'carrier'}`
  } else if (eventType === IActivityEventTypesEnum.updated_carrier) {
    displayEventType = `Updated ${legalName || 'carrier'} info`
  } else if (eventType === IActivityEventTypesEnum.reordered_carriers) {
    displayEventType = 'Updated carrier order'
  }

  return <Typography variant='body2'>{displayEventType}</Typography>
}

export default DataGridEventTypeCell
