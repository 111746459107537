import React from 'react'
import { useForm, FieldErrors } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ModalBase, IModalAction } from 'dpl/components/Modals'
import { Box, Typography, Divider, FormHelperText, makeStyles } from 'dpl/core'
import ControlledCheckboxGroupInput from 'forms/components/ControlledCheckboxGroupInput'
import ControlledSwitch from 'forms/components/ControlledSwitch'
import { FormIf } from 'forms/components/FormIf'
import { ContractAdvancedInputsSchema, IContractAdvancedInputsSchema } from '../../schema'

interface ContractAdvancedInputsProps {
  contractAdvancedInputs: IContractAdvancedInputsSchema
  onClose: () => void
  isOpen: boolean
  onSubmit: (contractAdvancedInputs: IContractAdvancedInputsSchema) => void
}

const useStyles = makeStyles(theme => ({
  switch: {
    transform: `translate(${theme.spacing(1.25)})`,
    '& label': {
      marginRight: 0,
    },
  },
}))

export function ContractAdvancedInputsModal({
  contractAdvancedInputs,
  isOpen,
  onClose,
  onSubmit,
}: ContractAdvancedInputsProps) {
  const classes = useStyles()
  const { control, errors, handleSubmit } = useForm<IContractAdvancedInputsSchema>({
    resolver: yupResolver(ContractAdvancedInputsSchema),
    defaultValues: contractAdvancedInputs,
  })
  const { dropTrailer: dropTrailerError } = errors || {}
  const { message: dropTrailerErrorMessage } = (dropTrailerError as FieldErrors) || {}

  const actions: IModalAction[] = [
    {
      label: 'Cancel',
      type: 'dismiss',
      action: onClose,
      ButtonProps: { variant: 'text' },
    },
    {
      label: 'Save Changes',
      type: 'confirm',
      action: handleSubmit(values => {
        onSubmit(values)
        onClose()
      }),
    },
  ]

  return (
    <ModalBase
      maxWidth='xs'
      fullWidth
      actions={actions}
      open={isOpen}
      title='Advanced Inputs'
      onClose={onClose}>
      <Typography variant='body2'>
        The more inputs you add, the more accurate your data will be.
      </Typography>
      <Box display='flex' flexDirection='column' gap={1.5} pt={3}>
        <Box display='flex' alignItems='center'>
          <Box flexGrow={1}>
            <Typography variant='subtitle1'>Team Required</Typography>
          </Box>
          <Box display='flex' justifyContent='flex-end' className={classes.switch}>
            <ControlledSwitch
              name='teamRequired'
              control={control}
              SwitchProps={{
                dataTest: 'teamRequired-switch',
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box display='flex' alignItems='center'>
            <Box flexGrow={1}>
              <Typography variant='subtitle1'>Drop Trailer</Typography>
            </Box>
            <Box display='flex' justifyContent='flex-end' className={classes.switch}>
              <ControlledSwitch
                name='dropTrailerSwitch'
                control={control}
                SwitchProps={{
                  dataTest: 'dropTrailerSwitch',
                }}
              />
            </Box>
          </Box>
          <FormIf
            control={control}
            name='dropTrailerSwitch'
            when={(value: boolean) => value === true}>
            <Box paddingLeft={2}>
              <ControlledCheckboxGroupInput
                control={control}
                name='dropTrailer'
                CheckboxProps={{ dataTest: 'dropTrailer-checkbox' }}
                options={[
                  { label: 'First Pickup', id: 'firstPickup' },
                  { label: 'Last Delivery', id: 'lastDelivery' },
                ]}
                defaultValue={contractAdvancedInputs.dropTrailer}
              />
              {errors?.dropTrailer && (
                <FormHelperText error>{dropTrailerErrorMessage}</FormHelperText>
              )}
            </Box>
          </FormIf>
        </Box>
      </Box>
    </ModalBase>
  )
}
