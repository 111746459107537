import React, { memo, PropsWithChildren } from 'react'
import { currencyFormatter } from '#utils/currencyFormatter'
import { usePermissionsContext } from 'auth/common/context'
import { brandColors, Tooltip } from 'dpl'
import {
  DataGridAdvancedTextCell,
  IDataGridAdvancedTextCellProps,
} from 'dpl/components/DataGrid/cells'
import { FORMATS } from 'dpl/constants'
import { Skeleton } from 'dpl/core'
import Typography from 'dpl/core/Typography'
import { AIIcon, AlertIcon } from 'dpl/icons'
import { useFlagsContext } from 'flags/src/FlagsContext'
import isNil from 'lodash/isNil'
import moment from 'moment'

export interface IDataGridContractRateProps extends IDataGridAdvancedTextCellProps {
  rateTypeLabel: string
  contractRate: Nullable<number>
  status: Nullable<string>
  source: Nullable<string>
  createdAt: Nullable<string>
}

export const DataGridContractRateCell = memo<PropsWithChildren<IDataGridContractRateProps>>(
  ({ contractRate, createdAt, rateTypeLabel, source, status }) => {
    const { isFlagEnabled } = useFlagsContext()
    const { userPermissions } = usePermissionsContext()
    const isFFEnabled = isFlagEnabled('rfp_rate_gen')
    const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled

    const rate = !isNil(contractRate) ? currencyFormatter(contractRate) : '-'

    const pendingRateGen = [status === 'pending', isNil(contractRate), isRfpRateGenEnabled].every(
      Boolean
    )

    const failedRateGen = [status === 'failed', isNil(contractRate), isRfpRateGenEnabled].every(
      Boolean
    )

    const successRateGen = [
      source === 'auto_rate_gen',
      !isNil(contractRate),
      isRfpRateGenEnabled,
    ].every(Boolean)

    if (pendingRateGen) {
      return <Skeleton variant='rectangular' width={80} />
    }

    if (failedRateGen) {
      return (
        <DataGridAdvancedTextCell alignment='end' bottomCaption={rateTypeLabel}>
          <Typography color={brandColors.coolGray4}>
            <Tooltip
              title='Rate generation failed. This could be due to the lane criteria or an unexpected error. Please try again, or manually enter a rate.'
              placement='top'>
              <span>
                <AlertIcon size='medium' /> Unavailable
              </span>
            </Tooltip>
          </Typography>
        </DataGridAdvancedTextCell>
      )
    }

    if (successRateGen) {
      const lastRateGenerated = moment(createdAt).format(FORMATS.monthDayYearTimeFormat12hUppercase)
      const successTitle = `Rate generated on ${lastRateGenerated} using default settings.`
      return (
        <DataGridAdvancedTextCell alignment='end' bottomCaption={rateTypeLabel}>
          <Typography>
            <Tooltip title={successTitle} placement='top'>
              <span>
                <AIIcon size='medium' /> {rate}
              </span>
            </Tooltip>
          </Typography>
        </DataGridAdvancedTextCell>
      )
    }
    return (
      <DataGridAdvancedTextCell alignment='end' bottomCaption={rateTypeLabel}>
        <Typography>{rate}</Typography>
      </DataGridAdvancedTextCell>
    )
  }
)
