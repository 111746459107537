import React, { memo } from 'react'
import {
  ControlledSelectField,
  IControlledSelectFieldProps,
} from 'forms/components/ControlledSelectField'
import { IRateType } from '../../types/rateType'

export interface IControlledRateTypeSelectFieldProps
  extends Pick<IControlledSelectFieldProps<IRateType>, 'control' | 'name'> {
  SelectProps?: Omit<IControlledSelectFieldProps<IRateType>['SelectProps'], 'options' | 'label'>
}

const OPTIONS: IControlledSelectFieldProps<IRateType>['SelectProps']['options'] = [
  {
    label: 'All-In',
    value: IRateType.allIn,
  },
  {
    label: 'Linehaul',
    value: IRateType.linehaul,
  },
]

export const ControlledRateTypeSelectField = memo<IControlledRateTypeSelectFieldProps>(
  ({ SelectProps, control, name }) => {
    return (
      <ControlledSelectField
        control={control}
        name={name}
        SelectProps={{
          ...SelectProps,
          label: 'Rate Type',
          options: OPTIONS,
        }}
      />
    )
  }
)
