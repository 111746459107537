import React, { memo, useCallback, useState } from 'react'
import { SubmitHandler, FieldErrors } from 'react-hook-form'
import { getApolloErrorMessages } from 'dpl/components/ApolloErrorMessages'
import { FormModal, IFormModalProps, IModalAction } from 'dpl/components/Modals'
import { useToastContext } from 'dpl/components/Toast'
import {
  ControlledAutocompleteOrganizationUser,
  TAutocompleteOrganizationUserSchema,
} from '../../../../../components'
import { useAnalytics } from '../../../../../hooks/useAnalytics'
import { IError, IOrganizationUserStatus } from '../../../../../types/graphqlTypes'
import { useUpdateRoutingGuideLaneManagerMutation } from '../../graphql/UpdateRoutingGuideLaneManager'
import { useRoutingGuideManagerForm } from './hooks'
import { TRoutingGuideManagerSchema } from './schema'

export interface IRoutingGuideManagerFormModalProps
  extends Pick<IFormModalProps, 'open' | 'onClose'> {
  /**
   * @default 'RoutingGuideManagerFormModal'
   */
  dataTest?: string
  defaultLaneManager: TAutocompleteOrganizationUserSchema
  routingGuideLaneId?: string
}

export const RoutingGuideManagerFormModal = memo<IRoutingGuideManagerFormModalProps>(
  ({
    dataTest = 'RoutingGuideManagerFormModal',
    defaultLaneManager,
    onClose,
    open,
    routingGuideLaneId = '',
  }) => {
    const { openToast } = useToastContext()
    const { id: defaultLaneManagerOrgUserId } = defaultLaneManager || {}
    const { control, errors, handleSubmit, reset } = useRoutingGuideManagerForm({
      defaultValues: {
        laneManager: defaultLaneManager,
      },
    })
    const { laneManager: laneManagerError } = errors || {}
    const { message: laneManagerErrorMessage } = (laneManagerError as FieldErrors) || {}
    const [updateRGLManager, { error: apolloErrors }] = useUpdateRoutingGuideLaneManagerMutation()
    const [mutationErrors, setMutationErrors] = useState<IError[]>([])
    const errorMessages = getApolloErrorMessages({ apolloErrors, mutationErrors })
    const { trackEvent } = useAnalytics()

    const closeHandler = () => {
      setMutationErrors([])
      onClose()
    }

    const submitHandler: SubmitHandler<TRoutingGuideManagerSchema> = useCallback(
      async formValues => {
        setMutationErrors([])
        const {
          laneManager: { id: newLaneManagerId = '' },
        } = formValues || {}
        // Don't trigger a BE update when form value is same as the existing lane manager
        if (newLaneManagerId === defaultLaneManagerOrgUserId) {
          onClose()
          return
        }

        const { data } = await updateRGLManager({
          variables: { input: { laneManagerId: newLaneManagerId, routingGuideLaneId } },
        })

        const { updateRoutingGuideLaneManager } = data || {}
        const { routingGuideLane, errors = [] } = updateRoutingGuideLaneManager || {}
        const { id: rglId, laneManager } = routingGuideLane || {}

        if (errors.length) {
          trackEvent('Routing Guide', 'UPDATE_ROUTING_GUIDE_LANE_MANAGER_ERROR', {
            routingGuideLaneId: rglId,
            newLaneManagerId,
            mutationErrors: errors,
          })
          setMutationErrors(errors)
          return
        }

        if (rglId) {
          trackEvent('Routing Guide', 'UPDATE_ROUTING_GUIDE_LANE_MANAGER', {
            routingGuideLaneId: rglId,
            newLaneManagerId,
          })
          openToast({ toastMessage: 'Lane manager successfully updated.' })
          reset({
            laneManager,
          })
          onClose()
        }
      },
      [
        defaultLaneManagerOrgUserId,
        onClose,
        openToast,
        reset,
        routingGuideLaneId,
        trackEvent,
        updateRGLManager,
      ]
    )

    const actions: IModalAction[] = [
      {
        label: 'Update',
        type: 'confirm',
        action: () => {
          handleSubmit(submitHandler)()
        },
        dataTest: 'update-lane-manager-submit-button',
      },
    ]

    return (
      <FormModal
        title='Edit Lane Manager'
        actions={actions}
        dataTest={dataTest}
        open={open}
        onClose={closeHandler}
        errors={errorMessages}
        maxWidth='xs'
        includeCloseIconButton>
        <ControlledAutocompleteOrganizationUser
          control={control}
          name='laneManager'
          AutocompleteOrganizationUserProps={{
            error: !!laneManagerErrorMessage,
            helperText: laneManagerErrorMessage,
            dataTest: `${dataTest}-laneManager`,
            organizationUserStatuses: [IOrganizationUserStatus.active],
            organizationUserPermissions: ['routing_guide.create_lane'],
          }}
        />
      </FormModal>
    )
  }
)
