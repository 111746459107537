import {
  IUpdateCarrierRateSequencesInput,
  IUpdateCarrierRateSequenceNumbersInput,
} from '#types/graphqlTypes'
import { IRgCarrierRateFragment } from '../graphql/RGCarrierRateFragment'

interface IGetUpdateCarrierRateSequencesInputProps {
  routingGuideLaneId: string
  rows: IRgCarrierRateFragment[]
}

export const getUpdateCarrierRateSequencesInput = ({
  routingGuideLaneId,
  rows,
}: IGetUpdateCarrierRateSequencesInputProps): IUpdateCarrierRateSequencesInput => {
  const sequenceNumberUpdates: IUpdateCarrierRateSequenceNumbersInput[] = rows.map(({ id }, i) => ({
    routingGuideCarrierRateId: id,
    sequenceNumber: i + 1,
  }))

  return {
    routingGuideLaneId,
    sequenceNumberUpdates,
  }
}
