import React from 'react'
import { Grid, Typography } from 'dpl/core'
import { DEFAULT_EMPTY_ACTIVITY_STRING } from '../components'

export interface ICreatedLaneEventJson {
  lane?: string[]
  equipment?: string[]
  customer?: string[]
  lane_manager?: string[]
}

export const getCreatedLaneActivityDetail = (rowEventJson: ICreatedLaneEventJson) => {
  const {
    customer = DEFAULT_EMPTY_ACTIVITY_STRING,
    equipment = DEFAULT_EMPTY_ACTIVITY_STRING,
    lane = DEFAULT_EMPTY_ACTIVITY_STRING,
    lane_manager: laneManager = DEFAULT_EMPTY_ACTIVITY_STRING,
  } = rowEventJson || {}
  const equipmentFormatted = equipment[0] === 'Open Deck' ? 'Flatbed' : equipment
  return (
    <Grid container padding={2}>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Lane</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          {lane}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Equipment</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          {equipmentFormatted}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Customer</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          {customer}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Lane Manager</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Typography variant='caption' color='textSecondary'>
          {laneManager}
        </Typography>
      </Grid>
    </Grid>
  )
}
