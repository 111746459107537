import React, { memo, PropsWithChildren, ReactNode, useMemo } from 'react'
import classNames from 'classnames'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Divider,
  makeStyles,
  Stack,
  Typography,
} from 'dpl/core'
import { SettingsOnIcon, SettingsOverrideIcon } from 'dpl/icons'
import DropdownRightIcon from 'dpl/icons/build/DropdownRightIcon'
import { brandColors } from 'dpl/theme/colors'
import {
  IAdjustmentRulesSectionProps,
  AdjustmentRulesSection,
} from './components/AdjustmentRulesSection'
import { IAdjustmentRulesSectionStateEnum } from './components/AdjustmentRulesSection/types'

export interface IRatesEstimatedStrategyAccordionProps
  extends Pick<AccordionProps, 'disabled' | 'className'>,
    Pick<IAdjustmentRulesSectionProps, 'onOverride' | 'onUseRules'> {
  /**
   * The amount to display on the right side of the accordion
   */
  amount: string
  /**
   * @default 'RatesEstimatedStrategyAccordion'
   */
  dataTest?: string
  /**
   * The description to display under the amount
   */
  description: ReactNode
  /**
   * The title of the accordion
   */
  title: string
  /**
   * The applied adjustment rules to display in the accordion
   * @default []
   */
  appliedRules?: IAdjustmentRulesSectionProps['rules']
  /**
   * The applied adjustment rules to display in the accordion
   * @default []
   */
  notAppliedRules?: IAdjustmentRulesSectionProps['rules']
  /**
   * Indicates if applied rules have been overridden
   * @optional
   * @default false
   */
  areAppliedRulesOverridden?: boolean
  /**
   * Indicates if applied rules have been unlocked. This happens when the Override button is clicked.
   * @optional
   * @default false
   */
  areAppliedRulesUnlocked?: boolean
  /**
   * When true, rate is not shown and accordion is disabled.
   * @optional
   * @default false
   */
  isDoNotBid?: boolean
  /**
   * When true, accordion is read only and rules CTA is not rendered. Saved rates on Rates Tool & Quotes Tool are read only.
   */
  isReadOnly: boolean
}

const useRatesEstimatedStrategyAccordionStyles = makeStyles(theme => ({
  root: {
    '&::before': {
      content: 'none',
    },
  },
  summaryContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    gap: theme.spacing(1),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  title: {
    '& > div, & > svg': {
      display: 'inline',
      verticalAlign: 'middle',
    },
    '& > svg': {
      marginLeft: theme.spacing(0.5),
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-end',
      textAlign: 'right',
    },
  },
}))

export const RatesEstimatedStrategyAccordion = memo<
  PropsWithChildren<IRatesEstimatedStrategyAccordionProps>
>(
  ({
    amount,
    appliedRules,
    areAppliedRulesOverridden = false,
    areAppliedRulesUnlocked = false,
    children,
    className = '',
    dataTest = 'RatesEstimatedStrategyAccordion',
    description,
    disabled: disabledProp,
    isDoNotBid = false,
    isReadOnly,
    notAppliedRules,
    onOverride,
    onUseRules,
    title,
  }) => {
    const classes = useRatesEstimatedStrategyAccordionStyles()
    const hasAppliedRules = appliedRules && appliedRules.length > 0
    const disabled = disabledProp || isDoNotBid

    const Icon = useMemo(() => {
      if (!hasAppliedRules || disabled) return null
      return areAppliedRulesOverridden ? SettingsOverrideIcon : SettingsOnIcon
    }, [areAppliedRulesOverridden, hasAppliedRules, disabled])

    const rootClasses = classNames(classes.root, { [className]: !!className })

    return (
      <Accordion data-test={dataTest} variant='filled' disabled={disabled} className={rootClasses}>
        <AccordionSummary
          expandIcon={<DropdownRightIcon size='large' />}
          data-test={`${dataTest}-Summary`}>
          <div className={classes.summaryContent}>
            <div className={classes.title}>
              <Typography component='div' variant='subtitle1' color='textSecondary'>
                {title}
              </Typography>
              {Icon && <Icon color='gray2' size='large' data-test={Icon.name} />}
            </div>
            <div className={`${classes.description} AccordionSummary-description`}>
              {isDoNotBid && (
                <Typography variant='subtitle1' color='textSecondary'>
                  No Rate Available
                </Typography>
              )}
              {!isDoNotBid && (
                <>
                  <Typography
                    variant='subtitle1'
                    color='textSecondary'
                    data-test={`${dataTest}-amount`}>
                    {amount}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color='textSecondary'
                    data-test={`${dataTest}-description`}>
                    {description}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails data-test={`${dataTest}-Details`}>
          <Stack gap={1.5} divider={<Divider sx={{ borderColor: brandColors.coolGray2 }} />}>
            {hasAppliedRules && (
              <Stack
                gap={1.5}
                px={3}
                divider={<Divider sx={{ borderColor: brandColors.coolGray2 }} />}>
                <AdjustmentRulesSection
                  dataTest={`${dataTest}-applied-rules`}
                  rules={appliedRules}
                  variant={IAdjustmentRulesSectionStateEnum.applied}
                  overridden={areAppliedRulesOverridden}
                  unlocked={areAppliedRulesUnlocked}
                  onOverride={isReadOnly ? undefined : onOverride}
                  onUseRules={isReadOnly ? undefined : onUseRules}
                />
                {notAppliedRules && notAppliedRules.length && (
                  <AdjustmentRulesSection
                    dataTest={`${dataTest}-not-applied-rules`}
                    rules={notAppliedRules}
                    variant={IAdjustmentRulesSectionStateEnum.notApplied}
                  />
                )}
              </Stack>
            )}
            {children}
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  }
)
