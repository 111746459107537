import React, { memo, useCallback, useMemo, useState } from 'react'
import { IRateEstimateSliderProps, RateEstimateSlider } from '#components/RateSlider'
import {
  IRatesEstimatedStrategyAccordionProps,
  RatesEstimatedStrategyAccordion,
} from '#components/RatesEstimatedStrategyAccordion'
import { ICostModel } from '#types/costModel'
import { currencyFormatter } from '#utils/currencyFormatter'
import { CarrierRatePercentileDescription } from './components/CarrierRatePercentileDescription'
import { IRule } from './types'
import {
  getAppliedAdjustmentRulesLineItems,
  getBaseCostPercentile,
  getNotAppliedAdjustmentRulesLineItems,
  getPercentileAmount,
  isSuggestedValueOverridden,
} from './utils'

export interface IRatesEstimatedCarrierRateAccordionProps
  extends Pick<IRatesEstimatedStrategyAccordionProps, 'isDoNotBid' | 'isReadOnly'> {
  /**
   * The cost model to use for the carrier rate
   */
  costModel: Nullable<ICostModel>
  /**
   * @default 'RatesEstimatedCarrierRateAccordion'
   */
  dataTest?: string
  /**
   * The organizations default cost percentile
   * @example 50
   */
  defaultCostPercentile: number
  /**
   * @default []
   */
  appliedRules: IRule[]
  /**
   * @default []
   */
  matchedRules: IRule[]
  /**
   * Boolean indicated if the raw cost is below the min cost limit
   */
  isBelowMinCost: boolean
  /**
   * Boolean indicated if the raw cost is above the max cost limit
   */
  isAboveMaxCost: boolean
  /**
   * The raw cost percentile.
   * The raw cost percentile may differ from the suggested cost percentile
   * if the min or max cost limits are reached.
   */
  rawCostPercentile: number
  /**
   * The raw cost shift percentage.
   * The raw cost shift percentage may differ from the suggested cost shift percentage
   * if the min or max cost limits are reached.
   */
  rawCostShiftPercentage: number
  /**
   * The suggested cost percentile
   * @example 50
   */
  suggestedCostPercentile: number
  /**
   * The suggested cost shift percentage
   * @example 0.02
   */
  suggestedCostShiftPercentage: number
  /**
   * The callback to handle when the user overrides the applied rules
   * If there are no applied rules, callback is called with false.
   * @param isRulesOverridden - true if the user has overridden the applied rules, false if they are using the applied rules
   */
  onRulesOverride?: (isRulesOverridden: boolean) => void
  /**
   * The callback to handle the slider change event
   */
  onSliderChange?: (value: number) => void
  /**
   * The value of the slider
   * @default 0
   */
  sliderValue?: IRateEstimateSliderProps['value']
  /**
   * The cost percentile used when determining the quoted rate.
   * @example 10
   */
  quotedCostPercentile?: Nullable<number>
  /**
   * The cost shift percentage used when determining the quoted rate.
   * @example 0.1
   */
  quotedCostShiftPercentage?: Nullable<number>
}

export const RatesEstimatedCarrierRateAccordion = memo<IRatesEstimatedCarrierRateAccordionProps>(
  ({
    costModel,
    dataTest = 'RatesEstimatedCarrierRateAccordion',
    defaultCostPercentile,
    onSliderChange,
    appliedRules = [],
    matchedRules = [],
    suggestedCostPercentile,
    suggestedCostShiftPercentage,
    sliderValue = 0,
    onRulesOverride,
    isDoNotBid,
    isReadOnly,
    quotedCostPercentile,
    quotedCostShiftPercentage,
    isBelowMinCost,
    isAboveMaxCost,
    rawCostPercentile,
    rawCostShiftPercentage,
  }) => {
    const hasAppliedRules = useMemo(() => appliedRules.length > 0, [appliedRules])

    const [initialValue] = useState(sliderValue)
    const [isSliderActive, setIsSliderActive] = useState(!hasAppliedRules)
    const [isSuggestedOverridden, setIsSuggestedOverridden] = useState(
      isSuggestedValueOverridden({
        suggestedCostPercentile,
        suggestedCostShiftPercentage,
        quotedCostPercentile,
        quotedCostShiftPercentage,
      })
    )

    const carrierRateTotal = useMemo<string>(
      () => (sliderValue ? currencyFormatter(sliderValue) : '$0'),
      [sliderValue]
    )

    const { isDefaultCostPercentile: isBaseTheDefaultCostPercentile } = useMemo(
      () => getBaseCostPercentile({ defaultCostPercentile, rules: appliedRules }),
      [defaultCostPercentile, appliedRules]
    )

    const baseCost = useMemo(
      () =>
        getPercentileAmount({
          costModel,
          percentile: rawCostPercentile,
        }),
      [costModel, rawCostPercentile]
    )

    const appliedRuleLineItems = useMemo(() => {
      return getAppliedAdjustmentRulesLineItems({
        baseCost,
        baseCostPercentile: suggestedCostPercentile,
        costModel,
        isBaseTheDefaultCostPercentile,
        isBelowMinCostLimit: isBelowMinCost,
        isAboveMaxCostLimit: isAboveMaxCost,
        rules: appliedRules,
      })
    }, [
      baseCost,
      costModel,
      isAboveMaxCost,
      isBaseTheDefaultCostPercentile,
      isBelowMinCost,
      appliedRules,
      suggestedCostPercentile,
    ])

    const notAppliedRuleLineItems = useMemo(() => {
      return getNotAppliedAdjustmentRulesLineItems({
        costModel,
        rules: matchedRules,
      })
    }, [costModel, matchedRules])

    const sliderValueChangeHandler = useCallback<NonNullable<IRateEstimateSliderProps['onChange']>>(
      (_, value) => {
        const newValue = value as number
        const isSuggestedValueOverridden =
          isBelowMinCost || isAboveMaxCost || newValue !== initialValue
        setIsSuggestedOverridden(isSuggestedValueOverridden)
        onRulesOverride?.(hasAppliedRules && isSuggestedValueOverridden)
        onSliderChange?.(value as number)
      },
      [
        hasAppliedRules,
        initialValue,
        isAboveMaxCost,
        isBelowMinCost,
        onRulesOverride,
        onSliderChange,
      ]
    )

    const overrideClickHandler = useCallback(() => {
      setIsSliderActive(true)
    }, [])

    const useRulesClickHandler = useCallback(() => {
      setIsSuggestedOverridden(false)
      setIsSliderActive(false)
      onRulesOverride?.(false)
      onSliderChange?.(initialValue)
    }, [initialValue, onRulesOverride, onSliderChange])

    const disableAccordion = !costModel

    return (
      <RatesEstimatedStrategyAccordion
        dataTest={dataTest}
        title='Est. Carrier Rate (including fuel)'
        amount={carrierRateTotal}
        description={
          <CarrierRatePercentileDescription
            baseCostPercentile={rawCostPercentile}
            costModel={costModel}
            defaultCostPercentile={defaultCostPercentile}
            hasAppliedRules={hasAppliedRules}
            isAboveMaxCostLimit={isAboveMaxCost}
            isBelowMinCostLimit={isBelowMinCost}
            isSuggestedOverridden={isSuggestedOverridden}
            rulesCumulativeAdditivePercentage={rawCostShiftPercentage}
            sliderValue={sliderValue}
            suggestedCostPercentile={suggestedCostPercentile}
            suggestedCostShiftPercentage={suggestedCostShiftPercentage}
          />
        }
        isDoNotBid={isDoNotBid}
        appliedRules={appliedRuleLineItems}
        notAppliedRules={notAppliedRuleLineItems}
        areAppliedRulesUnlocked={isSliderActive}
        areAppliedRulesOverridden={isSuggestedOverridden}
        onOverride={overrideClickHandler}
        onUseRules={useRulesClickHandler}
        disabled={disableAccordion}
        isReadOnly={isReadOnly}>
        <RateEstimateSlider
          costModel={costModel}
          disabled={!isSliderActive || isReadOnly}
          onChange={sliderValueChangeHandler}
          value={sliderValue}
        />
      </RatesEstimatedStrategyAccordion>
    )
  }
)
