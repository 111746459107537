import React, { memo, useCallback, useMemo } from 'react'
import { FeatureUnavailableModal } from 'dpl/components/Modals/FeatureUnavailableModal'
import { useToastContext } from 'dpl/components/Toast'
import { Button, Card } from 'dpl/core'
import Typography from 'dpl/core/Typography'
import { makeStyles } from 'dpl/core/styles'
import AddIcon from 'dpl/icons/build/AddIcon'
import { brandColors } from 'dpl/theme/colors'
import { useFlagsContext } from 'flags'
import { useCarrierRatesReorderContext } from '../../context'
import { RoutingGuideCarrierFormModal } from '../../forms/RoutingGuideCarrierFormModal'
import { IRgCarrierRateFragment } from '../../graphql/RGCarrierRateFragment'
import { useRoutingGuideDetailsModals } from '../../hooks'
import { CarrierListEmptyCard } from '../CarrierListEmptyCard'
import { CarrierRatesReorderButtons } from '../CarrierRatesReorderButtons'
import { DeactivateCarrierLaneRateModal } from '../DeactivateCarrierLaneRateModal'
import { getActionColumnDef, RoutingGuideCarrierDataGrid } from '../RoutingGuideCarrierDataGrid'

const useCarriersSectionStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2, 6),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0),
    },
  },
  carriersHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(0),
    },
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    '& > button:first-of-type': {
      order: 2,
      [theme.breakpoints.up('md')]: {
        order: 0,
      },
    },
  },
  card: {
    '&.reordering': {
      borderColor: brandColors.skyBlue3,
    },
  },
}))

export interface ICarriersSectionProps {
  /**
   * The id of the routing guide lane
   */
  routingGuideLaneId: string
  /**
   * @default []
   */
  carrierRates: Nullable<IRgCarrierRateFragment[]>
  /**
   * @default false
   */
  isLoading?: boolean
  /**
   * @default 'CarriersSection'
   */
  dataTest?: string
  /**
   * Callback to refetch the data
   */
  onRefetch: () => void
}

export const CarriersSection = memo<ICarriersSectionProps>(
  ({
    dataTest = 'CarriersSection',
    isLoading = false,
    carrierRates = [],
    routingGuideLaneId,
    onRefetch,
  }) => {
    const classes = useCarriersSectionStyles()
    const { openToast } = useToastContext()
    const { isFlagEnabled } = useFlagsContext()
    const isRGCarrierRateEditEnabled = isFlagEnabled('routing_guide_carrier_rate_edit')
    const isRGCarrierRateReorderEnabled = isFlagEnabled('routing_guide_carrier_rate_reorder')

    const { isReordering, toggleReordering } = useCarrierRatesReorderContext()
    const {
      closeCarrierFormModal,
      modalState,
      selectedCarrierRate,
      toggleAddCarrierFormModal,
      toggleDeactivateCarrierLaneRateModal,
      toggleEditCarrierLaneRateModal,
      toggleFeatureUnavailableModal,
    } = useRoutingGuideDetailsModals()
    const { id: selectedCarrierRateId } = selectedCarrierRate || {}

    const actionColumn = useMemo(
      () =>
        getActionColumnDef({
          isRGCarrierRateEditEnabled,
          onDeactivateClick: toggleDeactivateCarrierLaneRateModal,
          onEditClick: toggleEditCarrierLaneRateModal,
        }),
      [
        isRGCarrierRateEditEnabled,
        toggleDeactivateCarrierLaneRateModal,
        toggleEditCarrierLaneRateModal,
      ]
    )

    const addCarrierSuccessHandler = useCallback(() => {
      openToast({ toastMessage: 'Carrier successfully activated.' })
      toggleAddCarrierFormModal()
      onRefetch()
    }, [openToast, onRefetch, toggleAddCarrierFormModal])

    const editCarrierSuccessHandler = useCallback(() => {
      openToast({ toastMessage: 'Carrier info successfully updated.' })
      toggleEditCarrierLaneRateModal()
    }, [openToast, toggleEditCarrierLaneRateModal])

    const showEmptyCard = useMemo(() => {
      if (isLoading) {
        return false
      }
      return !carrierRates || carrierRates?.length === 0
    }, [isLoading, carrierRates])

    const showDataGrid = useMemo(() => {
      if (isLoading) {
        return true
      }
      if (!carrierRates) {
        return false
      }
      return carrierRates.length > 0
    }, [isLoading, carrierRates])

    const showEditOrderButton = useMemo(() => {
      if (!isRGCarrierRateReorderEnabled) {
        return false
      }
      if (!carrierRates) {
        return false
      }
      return carrierRates.length > 1
    }, [carrierRates, isRGCarrierRateReorderEnabled])

    const showAddCarrierButton = useMemo(() => {
      if (!carrierRates) {
        return false
      }
      if (carrierRates.length === 0) {
        return false
      }
      return carrierRates.length <= 4
    }, [carrierRates])

    return (
      <div className={classes.container} data-test={dataTest}>
        <div className={classes.carriersHeader}>
          <Typography variant='h4' component='div'>
            Carriers
          </Typography>
          <div className={classes.buttons}>
            {isReordering ? (
              <CarrierRatesReorderButtons dataTest={dataTest} />
            ) : (
              <>
                {showEditOrderButton && (
                  <Button
                    size='small'
                    variant='text'
                    onClick={toggleReordering}
                    disabled={isLoading}
                    data-test={`${dataTest}-edit-order-button`}>
                    Edit Order
                  </Button>
                )}
                {showAddCarrierButton && (
                  <Button
                    size='small'
                    onClick={toggleAddCarrierFormModal}
                    startIcon={<AddIcon size='large' />}
                    disabled={isLoading}
                    data-test={`${dataTest}-add-carrier-button`}>
                    Add Carrier
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {showEmptyCard && (
          <CarrierListEmptyCard
            dataTest={`${dataTest}-empty-card`}
            onAddCarrierClick={toggleAddCarrierFormModal}
          />
        )}
        {showDataGrid && carrierRates && (
          <Card
            className={`${classes.card} ${isReordering ? 'reordering' : ''}`}
            data-test={`${dataTest}-card`}>
            <RoutingGuideCarrierDataGrid
              actionColumn={actionColumn}
              rows={carrierRates}
              loading={isLoading}
              rowReordering={isReordering}
            />
          </Card>
        )}
        <FeatureUnavailableModal
          open={modalState.FeatureUnavailableModal}
          onClose={toggleFeatureUnavailableModal}
        />
        {(modalState.AddCarrierFormModal || modalState.EditCarrierLaneRateModal) && (
          <RoutingGuideCarrierFormModal
            routingGuideLaneId={routingGuideLaneId}
            carrierRate={selectedCarrierRate}
            onClose={closeCarrierFormModal}
            onCreateSuccess={addCarrierSuccessHandler}
            onUpdateSuccess={editCarrierSuccessHandler}
          />
        )}
        <DeactivateCarrierLaneRateModal
          open={modalState.DeactivateCarrierLaneRateModal}
          onClose={toggleDeactivateCarrierLaneRateModal}
          selectedCarrierRateId={selectedCarrierRateId}
        />
      </div>
    )
  }
)
