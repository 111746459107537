import React from 'react'
import { ConfirmationModal, IConfirmationModalProps, IModalAction } from 'dpl'
import MapMarker, { TStopType } from 'dpl/components/MapMarker'
import { FORMATS } from 'dpl/constants/datetime'
import { Box, Typography } from 'dpl/core'
import moment from 'moment-timezone'
import { TBaseStop, TStopWithRelativeSequence } from '../../routes/spot-rates-tool/types'

export interface IStopListModalProps<Stop extends TBaseStop>
  extends Omit<IConfirmationModalProps, 'title'> {
  stops: TStopWithRelativeSequence<Stop>[]
}

export function StopListModal<Stop extends TBaseStop>({
  onClose,
  open,
  stops,
}: IStopListModalProps<Stop>) {
  const actions: IModalAction[] = [
    {
      type: 'confirm',
      label: 'Close',
      action: onClose,
    },
  ]

  return (
    <ConfirmationModal
      maxWidth='sm'
      open={open}
      onClose={onClose}
      includeCloseIconButton={false}
      closeOnBackdropClick
      actions={actions}>
      <Box display='flex' flexDirection='column' gap={3} data-test='stop-list-modal'>
        {stops.map(stop => {
          const {
            city,
            date,
            postalCode,
            relativeStopSequence,
            startTime,
            startTimeZone,
            stateCode,
            stopSequence,
            stopType,
          } = stop || {}
          const formattedDatetime = moment
            .tz(`${date} ${startTime}`, startTimeZone || '')
            .format(FORMATS.monthDayYearTimeFormat12hUppercase)
          return (
            <Box key={stopSequence} display='flex' gap={1.5} alignItems='center'>
              <MapMarker sequence={relativeStopSequence} type={stopType as TStopType} />
              <Box display='flex' flexDirection='column'>
                <div>
                  <Typography variant='body2' component='span'>
                    {city}, {stateCode}{' '}
                  </Typography>
                  {postalCode && (
                    <Typography variant='body2' component='span'>
                      ({postalCode})
                    </Typography>
                  )}
                </div>
                {date && (
                  <Typography variant='caption' color='textSecondary'>
                    {formattedDatetime}
                  </Typography>
                )}
              </Box>
            </Box>
          )
        })}
      </Box>
    </ConfirmationModal>
  )
}
