import React from 'react'
import { AuthenticationCard, IAuthenticationCardProps } from '../AuthenticationCard'
import { EmailForm, IEmailFormProps } from '../EmailForm'

export interface IMagicLinkExpiredCardProps
  extends Pick<IAuthenticationCardProps, 'termsOfServiceUrl'> {
  /**
   * Email that magic link was sent to
   */
  onSubmit: IEmailFormProps['onSubmit']
}

export function MagicLinkExpiredCard({ onSubmit, termsOfServiceUrl }: IMagicLinkExpiredCardProps) {
  return (
    <AuthenticationCard
      dataTest='magic-link-expired-card'
      title='This login link has expired.'
      subheader='Enter your email to send a new link.'
      termsOfServiceUrl={termsOfServiceUrl}>
      <EmailForm onSubmit={onSubmit} buttonText='Resend one-time login link' />
    </AuthenticationCard>
  )
}
