import { emptyStringToNull } from '#routes/spot-rates-control-panel/components/AdjustmentRuleFormSidePanel/schema/utils'
import * as yup from 'yup'
import { StopSchema } from './StopSchema'
import { VolumeSchema } from './VolumeSchema'

export const RfpLaneInfoSchema = yup.object({
  /**
   * Stops is an array of stop locations and type for the lane.
   */
  stops: yup.array(StopSchema).required('You must select a location.'),
  customMiles: yup.number().transform(emptyStringToNull).nullable(),
  equipment: yup.string().required('You must select an equipment type.'),
  dropType: yup.string().required('You must select a drop type.'),
  isTeam: yup.boolean(),
  calculatedMiles: yup.number().transform(emptyStringToNull).nullable(),
  autoGenerateRates: yup.string(),
  /**
   * Selected volume type and volume amounts for the contract duration.
   */
  volume: VolumeSchema.required('You must select a volume type.'),
})

export interface IRfpLaneInfoSchema extends yup.InferType<typeof RfpLaneInfoSchema> {}
