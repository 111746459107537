import React, { memo } from 'react'
import { REGION_LABELS } from '#routes/spot-rates-control-panel/constants'
import { IRegionsEnum } from '#routes/spot-rates-control-panel/types'
import Autocomplete, {
  AutocompleteHighlightText,
  IAutocompleteProps,
} from 'dpl/components/Autocomplete'
import { Stack, Typography } from 'dpl/core'

export interface IAutocompleteRegionProps
  extends Pick<
    IAutocompleteProps<IRegionsEnum>,
    'onChange' | 'placeholder' | 'renderStartAdornment' | 'size' | 'value'
  > {
  /**
   * @default 'AutocompleteRegion'
   */
  dataTest?: string
}

const OPTIONS: IRegionsEnum[] = [
  IRegionsEnum.NORTHEAST,
  IRegionsEnum.MID_ATLANTIC,
  IRegionsEnum.SOUTHEAST,
  IRegionsEnum.MIDWEST,
  IRegionsEnum.NORTHERN_MIDWEST,
  IRegionsEnum.CENTRAL,
  IRegionsEnum.SOUTH,
  IRegionsEnum.SOUTHWEST,
  IRegionsEnum.WEST,
]

export const AutocompleteRegion = memo<IAutocompleteRegionProps>(
  ({ dataTest = 'AutocompleteRegion', ...rest }) => {
    return (
      <Autocomplete
        data-test={dataTest}
        filterOptions={(options, { inputValue }) =>
          options.filter(
            option =>
              `${REGION_LABELS[option].label}`.toLowerCase().includes(inputValue.toLowerCase()) ||
              `${REGION_LABELS[option].description}`
                .toLowerCase()
                .includes(inputValue.toLowerCase())
          )
        }
        getOptionLabel={option => `${REGION_LABELS[option].label}`}
        noOptionsText='No matching regions'
        options={OPTIONS}
        renderOption={(props, option, { inputValue }) => (
          <li {...props}>
            <Stack>
              <Typography variant='body2' className='selectOptionLabel' component='div'>
                <AutocompleteHighlightText
                  inputValue={inputValue}
                  label={`${`${REGION_LABELS[option].label}`}`}
                />
              </Typography>
              {`${REGION_LABELS[option].description}` && (
                <Typography
                  variant='caption'
                  color='textSecondary'
                  className='selectOptionDescription'
                  component='div'>
                  <AutocompleteHighlightText
                    inputValue={inputValue}
                    label={`${REGION_LABELS[option].description}`}
                  />
                </Typography>
              )}
            </Stack>
          </li>
        )}
        {...rest}
      />
    )
  }
)
