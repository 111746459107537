import { DeepPartial } from 'react-hook-form'
import { formatCostStrategy, formatMarginStrategy } from '#routes/spot-rates-control-panel/utils'
import { IRuleTypeEnum } from '#types/rates'
import capitalize from 'lodash/capitalize'
import { RULE_TYPE_LABELS } from '../../../../../../constants'
import { TRateStrategySchema } from '../../../../schema/RateStrategySchema'
import { ADJUSTMENT_TYPES } from '../../../../types'

export const formatRateStrategyReviewValues = ({
  adjustmentType,
  costAdditiveShiftPercentage,
  costBasePercentile,
  costRuleType,
  marginAdditivePercentage,
  marginBasePercentage,
  marginRuleType,
}: DeepPartial<TRateStrategySchema>) => {
  const formattedAdjustmentType = capitalize(adjustmentType || '')
  if (adjustmentType === ADJUSTMENT_TYPES.cost) {
    const formattedRuleType = RULE_TYPE_LABELS[costRuleType!]
    const formattedStrategy = formatCostStrategy({
      costAdditiveShiftPercentage,
      costBasePercentile,
      costRuleType,
    })
    return `${formattedAdjustmentType} ${formattedRuleType}: ${formattedStrategy}`
  }
  if (adjustmentType === ADJUSTMENT_TYPES.margin) {
    const formattedRuleType =
      marginRuleType === IRuleTypeEnum.doNotBid ? '' : ` ${RULE_TYPE_LABELS[marginRuleType!]}`
    const formattedStrategy = formatMarginStrategy({
      marginRuleType,
      marginAdditivePercentage,
      marginBasePercentage,
    })
    return `${formattedAdjustmentType}${formattedRuleType}: ${formattedStrategy}`
  }
  return ''
}
