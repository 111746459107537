import React, { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { ISpotRatesControlPanelContextState } from '../../context'
import { ISpotRatesAdjustmentRuleVersionFragment } from '../AdjustmentRulesSection/graphql/SpotRatesAdjustmentRuleVersionFragment'
import { AdjustmentRuleFormSidePanelBody } from './components/AdjustmentRuleFormSidePanelBody/AdjustmentRuleFormSidePanelBody'
import { DEFAULT_FORM_STEPS_STATE, EDIT_FORM_STEPS_STATE } from './constants'
import { AdjustmentRuleFormStepsProvider } from './context/AdjustmentRuleFormStepsProvider'
import { IUseAdjustmentRuleFormProps, useAdjustmentRuleForm } from './hooks'
import { mapAdjustmentRuleToFormSchema } from './utils/mapAdjustmentRuleToFormSchema/mapAdjustmentRuleToFormSchema'

export interface IAdjustmentRuleFormSidePanelProps {
  /**
   * The current adjustment rule
   * @optional
   */
  adjustmentRule?: Nullable<ISpotRatesAdjustmentRuleVersionFragment>
  /**
   * Default cost percentile. This value is used as the default value
   * for the costBasePercentile field in the form if a adjustmentRule
   * is not provided.
   * @optional
   */
  defaultCostPercentile?: ISpotRatesControlPanelContextState['spotRatesSettings']['defaultCostPercentile']
  /**
   * Function called when side panel is closed
   */
  onClose: () => void
}

export function AdjustmentRuleFormSidePanel({
  adjustmentRule,
  defaultCostPercentile,
  onClose,
}: IAdjustmentRuleFormSidePanelProps) {
  const { organizationAdjustmentRule } = adjustmentRule || {}
  const { id: organizationAdjustmentRuleId } = organizationAdjustmentRule || {}

  const formDefaultValues = useMemo<IUseAdjustmentRuleFormProps['defaultValues']>(() => {
    return mapAdjustmentRuleToFormSchema(adjustmentRule, defaultCostPercentile)
  }, [defaultCostPercentile, adjustmentRule])

  const methods = useAdjustmentRuleForm({
    defaultValues: formDefaultValues,
    shouldUnregister: false,
  })

  const initialFormSteps = organizationAdjustmentRuleId
    ? EDIT_FORM_STEPS_STATE
    : DEFAULT_FORM_STEPS_STATE

  return (
    <FormProvider {...methods}>
      <AdjustmentRuleFormStepsProvider formSteps={initialFormSteps}>
        <AdjustmentRuleFormSidePanelBody
          onClose={onClose}
          organizationAdjustmentRuleId={organizationAdjustmentRuleId}
        />
      </AdjustmentRuleFormStepsProvider>
    </FormProvider>
  )
}
