import { EQUIPMENT_TYPE_LABELS } from '#routes/spot-rates-control-panel/components/AdjustmentRuleFormSidePanel/components/AdjustmentRuleFactorsSection/constants'
import { EQUIPMENT_TYPES } from '#routes/spot-rates-control-panel/components/AdjustmentRuleFormSidePanel/types'
import { ISpotRatesAdjustmentRuleVersionFragment } from '#routes/spot-rates-control-panel/components/AdjustmentRulesSection/graphql/SpotRatesAdjustmentRuleVersionFragment'
import { IFactorTypes, IFactorCell } from '../../../types'

export const getFactorsCellValue = ({
  factors,
  organizationShipper,
}: Pick<ISpotRatesAdjustmentRuleVersionFragment, 'factors' | 'organizationShipper'>) => {
  const { customerFactor, deliveryGeoFactor, equipmentFactor, laneFactor, pickupGeoFactor } =
    factors || {}

  const factorProperties: IFactorCell[] = []
  if (customerFactor && organizationShipper) {
    const { name } = organizationShipper
    factorProperties.push({
      type: IFactorTypes.customer,
      value: [name],
    })
  }
  if (laneFactor || pickupGeoFactor || deliveryGeoFactor) {
    const { deliveryValues: laneDeliveryValues, pickupValues: lanePickupValues } = laneFactor || {}
    const { values: pickupGeoValues } = pickupGeoFactor || {}
    const { values: deliveryGeoValues } = deliveryGeoFactor || {}
    const puValues = lanePickupValues || pickupGeoValues
    const delValues = laneDeliveryValues || deliveryGeoValues
    if (puValues?.length) {
      factorProperties.push({
        type: IFactorTypes.pickupLocation,
        value: puValues,
      })
    }
    if (delValues?.length) {
      factorProperties.push({
        type: IFactorTypes.deliveryLocation,
        value: delValues,
      })
    }
  }
  if (equipmentFactor) {
    const { equipmentKey } = equipmentFactor || {}
    const name = EQUIPMENT_TYPE_LABELS[equipmentKey as EQUIPMENT_TYPES]
    factorProperties.push({
      type:
        equipmentKey === EQUIPMENT_TYPES.dryVan
          ? IFactorTypes.dryVanEquipment
          : IFactorTypes.reeferEquipment,
      value: [name],
    })
  }
  return factorProperties
}
