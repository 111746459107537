import React, { useState, useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { Badge } from 'dpl/components/Badge'
import { ToggleButton } from 'dpl/components/ToggleButton'
import { Box } from 'dpl/core'
import { DownArrowIcon } from 'dpl/icons'
import {
  DEFAULT_ADVANCED_INPUTS,
  IContractAdvancedInputsSchema,
  IContractLaneInformationSchema,
} from '../../schema'
import { ContractAdvancedInputsModal } from './ContractAdvancedInputsModal'

interface ContractAdvancedInputsToggleProps {
  control: Control<IContractLaneInformationSchema>
  updateAdvancedInputs: (
    contractAdvancedInputs: IContractLaneInformationSchema['contractAdvancedInputs']
  ) => void
}

export function ContractAdvancedInputsToggle({
  control,
  updateAdvancedInputs,
}: ContractAdvancedInputsToggleProps) {
  const [isOpen, setIsOpen] = useState(false)
  const contractAdvancedInputs = useWatch<IContractAdvancedInputsSchema>({
    control,
    name: 'contractAdvancedInputs',
    defaultValue: DEFAULT_ADVANCED_INPUTS,
  })

  const numSelectedInputs = useMemo(() => {
    let count = 0
    if (contractAdvancedInputs.teamRequired) count += 1
    if (contractAdvancedInputs.dropTrailerSwitch) count += 1
    return count
  }, [contractAdvancedInputs])

  return (
    <Box display='flex' alignItems='center'>
      <ToggleButton
        dataTest='open-advanced-inputs-modal'
        label={
          <>
            Advanced Inputs
            {numSelectedInputs > 0 && (
              <Badge color='blue' label={`${numSelectedInputs}`} dataTest='advanced-inputs-badge' />
            )}
          </>
        }
        data-test='open-advanced-inputs-modal'
        onClick={() => setIsOpen(true)}
        endIcon={DownArrowIcon}
        selected={false}
        value
      />

      {isOpen && (
        <ContractAdvancedInputsModal
          onSubmit={updateAdvancedInputs}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          contractAdvancedInputs={contractAdvancedInputs}
        />
      )}
    </Box>
  )
}
