import React, { useCallback, useMemo, useState } from 'react'
import { useAnalytics } from '#hooks'
import RfpTemplateXlsx from '#root/public/assets/rfp_template.xlsx'
import RfpTemplateWithDynamicVolumeXlsx from '#root/public/assets/rfp_template_with_dynamic_volume.xlsx'
import { IError, IImportContractRfpVersionLanesValidationSeverities } from '#types/graphqlTypes'
import { FetchResult } from '@apollo/client'
import { usePermissionsContext } from 'auth/common/context'
import {
  AccessRestrictedModal,
  Dropzone,
  IDropzoneProps,
  IFileObject,
  INLINE_MESSAGE_VARIANTS,
  InlineMessage,
  brandColors,
  RadialSpinner,
  TAlertType,
} from 'dpl'
import { RadioGroup } from 'dpl/components/RadioGroup'
import { TextField } from 'dpl/components/TextField'
import { useToastContext } from 'dpl/components/Toast'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Stack,
  Theme,
  Typography,
  Divider,
  MenuItem,
} from 'dpl/core'
import { DownloadIcon } from 'dpl/icons/build'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags'
import { GraphQLError } from 'graphql'
import orderBy from 'lodash/orderBy'
import { IContractRfpNodeFragment } from '../../graphql/ContractRFPNodeFragment'
import { IImportRfpMutation } from '../../graphql/ImportRfpMutation'
import { IImportRfpStatusQuery } from '../../graphql/ImportRfpStatus'
import { IImportHandlerProps } from '../../hooks'
import { RateGenBanner } from '../RateGenBanner'
import { RateGenRadioGroup } from '../RateGenRadioGroup'
import { AttachedFile } from './components'
import { ValidationMessages } from './components/ValidationMessages'
import { ACCEPTED_FILES } from './constants'
import { useCopyPreviousRoundMutation } from './graphql/CopyPreviousRoundMutation'

const DROPZONE_ALERTS: TAlertType[] = ['error']

const useImportRFPCardStyles = makeStyles<Theme, { isDisabled: boolean }>(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '682px',
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  downloadTemplateButton: {
    marginBottom: theme.spacing(2),
  },
  importRfpButton: {
    marginTop: theme.spacing(2),
  },
  inlineMessage: {
    marginTop: theme.spacing(2),
  },
  dropzoneError: {
    color: brandColors.error1,
  },
  dropzoneLink: {
    cursor: 'pointer',
    color: brandColors.skyBlue6,
    '&:focus, &:hover': {
      color: brandColors.skyBlue7,
    },
  },
  dropzoneRoot: ({ isDisabled }) => ({
    minHeight: '150px',
    pointerEvents: isDisabled ? 'none' : undefined,
  }),
  dropzoneIcon: {
    // Doing this because 'xxlarge' is too large and 'xlarge' isn't large enough to match the design.
    width: '2rem !important',
    height: '2rem !important',
  },
  continueButton: {
    marginTop: theme.spacing(3),
  },
}))

export interface IImportRFPCardProps {
  onImport: ({
    autoGenerateRates,
    file,
    persist,
  }: IImportHandlerProps) => Promise<FetchResult<IImportRfpMutation>>
  importStatus: Nullable<IImportRfpStatusQuery['importContractRfpVersionLanesStatus']>
  validating: boolean
  rounds: IContractRfpNodeFragment['versions']
  onCheckStatus: () => Promise<void>
  rfp: IContractRfpNodeFragment
  resetImportStatus: () => void
}

export function ImportRFPCard({
  importStatus,
  onCheckStatus,
  onImport,
  resetImportStatus,
  rfp,
  rounds: roundsProp,
  validating,
}: IImportRFPCardProps) {
  const { trackEvent } = useAnalytics()
  const { isFlagEnabled } = useFlagsContext()
  const isDynamicVolumeEnabled = isFlagEnabled('rfp_dynamic_volume')

  const [currentRound, previousRounds] = useMemo(() => {
    const [currentRound, ...previousRounds] = orderBy(roundsProp, 'number', 'desc')
    return [currentRound, previousRounds]
  }, [roundsProp])
  const contractRfpVersionNumber = currentRound?.number
  const { userPermissions } = usePermissionsContext()
  const classes = useImportRFPCardStyles({
    isDisabled: !userPermissions['contract_rfp.create_rfp_version_lanes'],
  })
  const { openToast } = useToastContext() || {}
  const [file, setFile] = useState<IFileObject | null>(null)
  const [dropzoneError, setDropzoneError] = useState<string | null>(null)
  const { completed = false, errors: importStatusErrors, validations } = importStatus ?? {}
  const hasValidationErrors =
    validations &&
    validations.some(
      ({ severity }) => severity === IImportContractRfpVersionLanesValidationSeverities.error
    )

  const importable = Boolean(file) && completed && !hasValidationErrors

  const isFirstRound = contractRfpVersionNumber === 1

  const cardTitle = useMemo(() => {
    if (file && completed) {
      return 'Upload Complete'
    }

    if (file && validating) {
      return 'Checking your file...'
    }

    if (isFirstRound) {
      return 'Upload your RFP to Start Round 1'
    }

    return `Import Round ${contractRfpVersionNumber}`
  }, [completed, file, validating, isFirstRound, contractRfpVersionNumber])

  const { createToggleHandler, modalState } = useModalState({
    restrictedModal: false,
  })

  const clearFileHandler = useCallback(() => {
    resetImportStatus()
    setFile(null)
  }, [resetImportStatus])

  const handleImportRfpErrors = useCallback(
    (networkErrors: readonly GraphQLError[] | undefined, resolverErrors: IError[] | undefined) => {
      if (networkErrors?.length) {
        setDropzoneError('Something went wrong. Please try again.')
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          errors: networkErrors,
        })
      } else if (resolverErrors?.length) {
        setDropzoneError(resolverErrors[0].message)
        trackEvent('Contract RFPs', 'IMPORT_CSV_ERROR', {
          errors: networkErrors,
        })
      }
      trackEvent('Contract RFPs', 'IMPORT_CSV_SUCCESS')
    },
    [trackEvent]
  )
  const [rateGenOption, setRateGenOption] = useState('yes')

  const importRfpClickHandler = useCallback(async () => {
    if (file) {
      const { data, errors: networkErrors } = await onImport({
        file,
        persist: true,
        autoGenerateRates: rateGenOption === 'yes',
      })
      const { importContractRfpVersionLanes } = data ?? {}
      const { errors: resolverErrors } = importContractRfpVersionLanes ?? {}
      handleImportRfpErrors(networkErrors, resolverErrors)
    }
  }, [file, handleImportRfpErrors, onImport, rateGenOption])

  const fileChangeHandler: IDropzoneProps['onFileChange'] = async ([file]) => {
    setDropzoneError(null)

    const { data, errors: networkErrors } = await onImport({
      file,
      persist: false,
      autoGenerateRates: rateGenOption === 'yes',
    })
    const { importContractRfpVersionLanes } = data ?? {}
    const { errors: resolverErrors, success } = importContractRfpVersionLanes ?? {}
    handleImportRfpErrors(networkErrors, resolverErrors)

    if (success) {
      setFile(file)
    }
  }

  const dropRejectedHandler: IDropzoneProps['onDropRejected'] = ([fileRejection]) => {
    const [error] = fileRejection.errors
    if (error.code === 'file-invalid-type') {
      setDropzoneError('Unsupported file type. Please upload a CSV.')
    }
  }

  const [importOption, setImportOption] = useState('copy')
  const [importFromRoundId, setImportFromRoundId] = useState(previousRounds[0]?.id ?? '')

  const showDropzone = (importOption === 'upload' && !file) || (isFirstRound && !file)
  const showAttachedFile = (importOption === 'upload' && file) || (isFirstRound && file)
  const showValidationErrors = file && (importOption === 'upload' || isFirstRound)
  const showRoundsSelect = importOption === 'copy' && contractRfpVersionNumber > 2
  const showPreviousRoundCopyCTA = importOption === 'copy' && !isFirstRound

  const [rateGenForCopyOption, setRateGenForCopyOption] = useState('yes')

  const [copyPreviousRound, { loading: isCopyPreviousRoundLoading }] = useCopyPreviousRoundMutation(
    {
      variables: {
        input: {
          fromContractRfpVersionId: importFromRoundId,
          autoGenerateRates: rateGenForCopyOption === 'yes',
        },
      },
      onError: apolloError => {
        openToast({
          toastMessage: apolloError.message,
          toastType: 'alert',
        })
      },
    }
  )

  const showRateGenBanner = rateGenOption === 'yes' || rateGenForCopyOption === 'yes'

  const areFieldsDisabled = isCopyPreviousRoundLoading

  const copyPreviousRoundHandler = async () => {
    try {
      const { data } = await copyPreviousRound()
      const { duplicateContractRfpVersionLanes } = data || {}
      const { errors: mutationErrors } = duplicateContractRfpVersionLanes || {}

      if (mutationErrors?.length) {
        const error = mutationErrors.map(({ message }) => message).join(', ')

        openToast({
          toastMessage: error,
          toastType: 'alert',
          multiLine: true,
        })
        trackEvent('Contract RFPs', 'COPY_PREVIOUS_ROUND_ERROR', {
          errors: mutationErrors,
          variables: {
            fromContractRfpVersionId: importFromRoundId,
          },
        })
        return
      }

      /**
       * if no errors are present, trigger status check.
       * This will prompt loading state while it finishes in the background.
       */
      onCheckStatus()

      trackEvent('Contract RFPs', 'COPY_PREVIOUS_ROUND_SUCCESS', {
        variables: {
          fromContractRfpVersionId: importFromRoundId,
        },
      })
    } catch (error) {
      openToast({
        toastMessage: 'Something went wrong. Please try again.',
        toastType: 'alert',
      })
      trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
        errors: [error],
        variables: {
          fromContractRfpVersionId: importFromRoundId,
        },
      })
    }
  }

  /**
   * Show loader if file is still validating on the BE but the user reloaded the page.
   * Even though file data will be lost, this will let the user know that something's
   * happening in the meantime.
   */
  if (!file && validating) {
    return <RadialSpinner position='relative' size='large' />
  }

  return (
    <Card className={classes.card} data-test='import-rfp-card'>
      <CardHeader title={cardTitle} />
      <CardContent>
        {!isFirstRound && !validating && !completed && (
          <Box>
            <Typography variant='body1' color={brandColors.coolGray5} pb={1}>
              How do you want to populate this round?
            </Typography>
            <RadioGroup
              big
              options={[
                {
                  composed: true,
                  value: 'copy',
                  label: (
                    <>
                      <Typography variant='body1' fontWeight={600}>
                        Copy Previous Round
                      </Typography>
                      <Typography variant='body2'>
                        {contractRfpVersionNumber > 2
                          ? 'Select round to import lanes from'
                          : 'Import lanes from Round 1'}
                      </Typography>
                    </>
                  ),
                },
                {
                  composed: true,
                  value: 'upload',
                  label: (
                    <>
                      <Typography variant='body1' fontWeight={600}>
                        Upload RFP
                      </Typography>
                      <Typography variant='body2'>Import lanes by uploading a CSV</Typography>
                    </>
                  ),
                },
              ]}
              onOptionChange={e => setImportOption(e.target.value)}
              checkedValue={importOption}
              disabled={areFieldsDisabled}
            />
            <Box py={3}>
              <Divider />
            </Box>
            {importOption === 'copy' && (
              <Box mt={1}>
                <RateGenRadioGroup onChange={value => setRateGenForCopyOption(value)} />
              </Box>
            )}
          </Box>
        )}
        {showRoundsSelect && (
          // Magic number coming from spec 🪄✨
          <Box maxWidth={300}>
            <TextField
              select
              label='Round to Copy'
              value={importFromRoundId}
              disabled={areFieldsDisabled}
              onChange={event => {
                setImportFromRoundId(event.target.value)
              }}>
              {previousRounds.map(round => (
                <MenuItem key={round.id} value={round.id}>
                  Round {round.number}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        {showPreviousRoundCopyCTA && (
          <Button
            className={classes.continueButton}
            size='small'
            disabled={areFieldsDisabled}
            onClick={copyPreviousRoundHandler}>
            Continue
          </Button>
        )}
        {showAttachedFile && (
          <>
            <AttachedFile clearFile={clearFileHandler} file={file} loading={validating} />
            {showRateGenBanner && (
              <Box mt={2}>
                <RateGenBanner />
              </Box>
            )}
          </>
        )}
        {showDropzone && (
          <>
            <Typography className={classes.description} variant='body2'>
              Simply download our template, add RFP lanes to it, convert your Excel to CSV, and
              upload it here. We&apos;ll check the file and fill in any missing geographic and
              mileage data.
            </Typography>
            <RateGenRadioGroup
              description='Fill in the template’s required fields to instantly generate rates using'
              onChange={value => setRateGenOption(value)}
            />
            <Button
              href={isDynamicVolumeEnabled ? RfpTemplateWithDynamicVolumeXlsx : RfpTemplateXlsx}
              download='RFP Template.xlsx'
              className={classes.downloadTemplateButton}
              size='small'
              endIcon={<DownloadIcon />}>
              Download Template
            </Button>

            <Box
              onClick={
                userPermissions['contract_rfp.create_rfp_version_lanes']
                  ? undefined
                  : createToggleHandler('restrictedModal', true)
              }>
              <Dropzone
                acceptedFiles={ACCEPTED_FILES}
                classes={{
                  root: classes.dropzoneRoot,
                  icon: classes.dropzoneIcon,
                }}
                dropZoneText={
                  <Stack alignItems='center'>
                    <Typography color={brandColors.coolGray5} variant='body2' textAlign='center'>
                      Drag and drop the file here or{' '}
                      <span className={classes.dropzoneLink}>select file</span>
                    </Typography>
                    <Typography color={brandColors.coolGray5} variant='caption'>
                      Supported file type: CSV
                    </Typography>
                  </Stack>
                }
                error={Boolean(dropzoneError)}
                showAlerts={DROPZONE_ALERTS}
                onDropRejected={dropRejectedHandler}
                onFileChange={fileChangeHandler}
              />
            </Box>
          </>
        )}
        {dropzoneError && (
          <Typography className={classes.dropzoneError} variant='caption'>
            {dropzoneError}
          </Typography>
        )}
        {showValidationErrors && <ValidationMessages validations={validations ?? []} rfp={rfp} />}
        {Boolean(importStatusErrors?.length) && (
          <InlineMessage
            className={classes.inlineMessage}
            type={INLINE_MESSAGE_VARIANTS.ALERT}
            fullWidth
            message={
              <Box>
                {importStatusErrors?.map(message => (
                  <Typography variant='subtitle2' key={message.substring(0, 10)}>
                    {message}
                  </Typography>
                ))}
              </Box>
            }
          />
        )}
        {showAttachedFile && importable && (
          <Button className={classes.importRfpButton} onClick={importRfpClickHandler}>
            Import RFP
          </Button>
        )}
      </CardContent>
      {modalState.restrictedModal && (
        <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
      )}
    </Card>
  )
}
