import { IMapPosition } from '../../../types'

/**
 * Returns an array based on the type of shadow layer we're using
 */
export const getPositionsWithShadow = (
  positions: IMapPosition[],
  options: { shadowType?: 'all' | 'latest' }
): IMapPosition[] => {
  const { shadowType = 'latest' } = options || {}

  // only return first index if `latest`
  if (shadowType === 'latest') {
    return positions.slice(0, 1)
  }

  return positions
}
