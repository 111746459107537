import { IDataGridProps, TDataGridColDef } from 'dpl/components/DataGrid'
import { ISpotRatesAdjustmentRuleVersionFragment } from '../../graphql/SpotRatesAdjustmentRuleVersionFragment'

/**
 * Type for the rows in the data grid
 */
export type TAdjustmentRulesRow = ISpotRatesAdjustmentRuleVersionFragment & {
  isRowDisabled?: boolean
}

/**
 * Type for the columns in the data grid
 */
export type TAdjustmentRulesColumn = TDataGridColDef<TAdjustmentRulesRow>

export interface IAdjustmentRulesDataGrid extends IDataGridProps<TAdjustmentRulesRow> {}

export enum IFactorTypes {
  pickupLocation = 'pickupLocation',
  deliveryLocation = 'deliveryLocation',
  dryVanEquipment = 'dryVanEquipment',
  reeferEquipment = 'reeferEquipment',
  customer = 'customer',
}

export interface IFactorCell {
  type: IFactorTypes
  value: string[]
}
