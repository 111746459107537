import React, { useState } from 'react'
import { IContractRfpNodeFragment } from '#routes/contract-rfp/ContractRFPDetailPage/graphql/ContractRFPNodeFragment'
import { usePermissionsContext } from 'auth/common/context'
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval'
import { Box, Typography, Collapse, Button, Divider } from 'dpl/core'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { MessageProps } from './types'

interface LabelProps {
  column?: string
  rfp: IContractRfpNodeFragment
}

const LABEL_BY_TYPE: Record<string, (props: LabelProps) => JSX.Element> = {
  inclusion: ({ column }) => (
    <Typography variant='body2'>
      There is formatting that does not match the template in the <strong>{column}</strong> column.
      Please correct the formatting.
    </Typography>
  ),
  blank: ({ column }) => (
    <Typography variant='body2'>
      There is missing information in the <strong>{column}</strong> column. Please fill in missing
      details.
    </Typography>
  ),
  custom_rate_must_be_all_in: () => (
    <Typography variant='body2'>
      All-in rate amount is entered in the <strong>all_in_rate_amount</strong> column, but linehaul
      is selected in the <strong>rate_type</strong> column. Please remove all-in rate data or change
      the rate type to all-in.
    </Typography>
  ),
  all_in_fuel_rate: () => (
    <Typography variant='body2'>
      Fuel is entered in the <strong>fuel_rate_per_mile</strong> column, but all-in is selected in
      the <strong>rate_type</strong> column. Please remove fuel data or change the rate type to
      linehaul.
    </Typography>
  ),
  match_state_postal_code: ({ column }) => (
    <Typography variant='body2'>
      City and state combination does not match the ZIP Code. Either remove the{' '}
      <strong>{`${column}_zipcode`}</strong> or verify <strong>{`${column}_city`}</strong> and{' '}
      <strong>{`${column}_state`}</strong> to match the <strong>{`${column}_zipcode`}</strong>.
    </Typography>
  ),
  geolocate_city_state: ({ column }) => (
    <Typography variant='body2'>
      ZIP Code could not be calculated as the <strong>{`${column}_city`}</strong> and{' '}
      <strong>{`${column}_state`}</strong> do not match in <strong>{`${column}_city`}</strong>,{' '}
      <strong>{`${column}_state`}</strong>.
    </Typography>
  ),
  geolocate_postal_code: ({ column }) => (
    <Typography variant='body2'>
      There is an invalid ZIP Code in <strong>{`${column}_zipcode`}</strong> column.
    </Typography>
  ),
  missing_stop_data: ({ column }) => (
    <Typography variant='body2'>
      There is missing required information for <strong>{`${column}_city`}</strong> and{' '}
      <strong>{`${column}_state`}</strong> column, or <strong>{`${column}_zipcode`}</strong> column.
    </Typography>
  ),
  geolocate_low_confidence: ({ column }) => (
    <Typography variant='body2'>
      There is unfamiliar city, state, ZIP Code combinations in the{' '}
      <strong>{`${column}_city`}</strong>, <strong>{`${column}_state`}</strong>,{' '}
      <strong>{`${column}_zipcode`}</strong> columns. Please check the details entered.
    </Typography>
  ),
  rategen_warnings: () => (
    <Typography variant='body2'>
      Rate generation is not available for some of the lanes provided. Please manually set rates for
      these lanes
    </Typography>
  ),
  volume_contract_period_mismatch: ({ column, rfp: { endAt, startAt } }) => {
    const contractLength = eachMonthOfInterval({
      start: new Date(startAt),
      end: new Date(endAt),
    }).length

    return (
      <Typography variant='body2'>
        Volume entered in the <strong>{column}</strong> column does not match the number of months
        in the contract. Please enter volume for {contractLength} months or enter a single volume
        amount that will be applied to all months in the contract.
      </Typography>
    )
  },
  dynamic_volume_not_supported: ({ column }) => (
    <Typography variant='body2'>
      Volume was entered per month in the <strong>{column}</strong> column, but monthly was not
      selected in the volume_frequency column. Please update the frequency to monthly or enter a
      single volume amount number
    </Typography>
  ),
}

export function Message({ column, rfp, rowCount, rows, type }: MessageProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { isFlagEnabled } = useFlagsContext()
  const isRfpDynamicVolumeEnabled = isFlagEnabled('rfp_dynamic_volume')
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled

  if (
    ['volume_contract_period_mismatch', 'dynamic_volume_not_supported'].includes(type) &&
    !isRfpDynamicVolumeEnabled
  ) {
    return null
  }

  if (type === 'rategen_warnings' && !isRfpRateGenEnabled) {
    return null
  }

  const Label = LABEL_BY_TYPE[type]

  if (!Label) {
    return null
  }

  return (
    <Box pt={2}>
      <Label column={column} rfp={rfp} />
      <Collapse in={isOpen}>
        <ul>
          <li>Issue found in row {rows}</li>
        </ul>
      </Collapse>
      <Button variant='text' size='xsmall' onClick={() => setIsOpen(currentValue => !currentValue)}>
        {isOpen ? 'Close Details' : `View ${rowCount} Rows Affected`}
      </Button>
      <Box pt={2}>
        <Divider />
      </Box>
    </Box>
  )
}
