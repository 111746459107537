interface IFormatPercentileLabelProps {
  percentile: number
  isDefault: boolean
  shiftPercentage?: number
  showCustomOrDefaultLabel?: boolean
}

/**
 * Returns the formatted percentile label
 *
 * @returns {string} The formatted percentile label
 *
 * @example formatPercentileLabel({ percentile: 50, isDefault: false, shiftPercentage: 0.02 }) // '50th (+2%)'
 * @example formatPercentileLabel({ percentile: 50, isDefault: false, shiftPercentage: -0.02 }) // '50th (-2%)'
 * @example formatPercentileLabel({ percentile: 50, isDefault: true }) // '50th (Default)'
 * @example formatPercentileLabel({ percentile: 50, isDefault: false }) // '50th (Custom)'
 * @example formatPercentileLabel({ percentile: 50, isDefault: false, showCustomOrDefaultLabel: false }) // '50th'
 */
export const formatPercentileLabel = ({
  isDefault,
  percentile,
  shiftPercentage,
  showCustomOrDefaultLabel = true,
}: IFormatPercentileLabelProps): string => {
  if (shiftPercentage) {
    const shiftPercentagePrefix = shiftPercentage > 0 ? '+' : ''
    return `${percentile}th (${shiftPercentagePrefix}${shiftPercentage * 100}%)`
  }

  if (showCustomOrDefaultLabel) {
    return `${percentile}th ${isDefault ? '(Default)' : '(Custom)'}`
  }

  return `${percentile}th`
}
