import React, { memo, useMemo, useEffect } from 'react'
import { IRuleTypeEnum } from '#types/rates'
import { usePermissionsContext } from 'auth/common/context'
import DataGrid from 'dpl/components/DataGrid'
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  Typography,
  Skeleton,
  useGridApiRef,
} from 'dpl/core'
import { ADJUSTMENT_RULES_DATA_GRID_AUTOSIZE_OPTIONS } from './adjustmentRulesDataGridAutosizeOptions'
import {
  ADJUSTMENT_RULES_DATA_GRID_COLUMNS,
  ADJUSTMENT_RULES_DATA_GRID_COLUMNS_WITH_ACTIONS,
} from './adjustmentRulesDataGridColumns'
import { IAdjustmentRulesDataGrid } from './types'
import { isAdjustmentRuleExpiredOrDeprecated } from './utils'

type TAdjustmentRulesDataGridPickedProps = 'loading' | 'rows'

export interface IAdjustmentRulesDataGridCardProps
  extends Pick<IAdjustmentRulesDataGrid, TAdjustmentRulesDataGridPickedProps> {
  /**
   * @default 'AdjustmentRulesDataGridCard'
   */
  dataTest?: string
  /**
   * Rule action, used to determine appropriate title and empty state message
   * @example 'base_additive'
   */
  ruleAction: IRuleTypeEnum
}

const TITLE = {
  [IRuleTypeEnum.base]: 'Base Rules',
  [IRuleTypeEnum.additive]: 'Additive Rules',
  [IRuleTypeEnum.doNotBid]: 'Do Not Bid Rules',
}

const EMPTY_STATE_MESSAGE = {
  [IRuleTypeEnum.base]: 'No base rules added.',
  [IRuleTypeEnum.additive]: 'No additive rules added.',
  [IRuleTypeEnum.doNotBid]: 'No Do Not Bid rules added.',
}

const useAdjustmentRulesDataGridCardStyles = makeStyles(theme => ({
  root: {
    '& .MuiCardHeader-root': {
      padding: theme.spacing(3, 2, 0),
    },
    '& .MuiCardContent-root': {
      padding: theme.spacing(1, 2, 3),
    },
  },
}))

export const AdjustmentRulesDataGridCard = memo<IAdjustmentRulesDataGridCardProps>(
  ({ dataTest = 'AdjustmentRulesDataGridCard', loading, rows, ruleAction }) => {
    const { userPermissions } = usePermissionsContext()
    const canUpdateRules = userPermissions['rates_tool.update_spot_adjustment_rules']

    const classes = useAdjustmentRulesDataGridCardStyles()

    const dataGridApiRef = useGridApiRef()

    const title = TITLE[ruleAction]
    const emptyStateMessage = EMPTY_STATE_MESSAGE[ruleAction]

    const columns = useMemo(() => {
      return canUpdateRules
        ? ADJUSTMENT_RULES_DATA_GRID_COLUMNS_WITH_ACTIONS
        : ADJUSTMENT_RULES_DATA_GRID_COLUMNS
    }, [canUpdateRules])

    const processedRows = useMemo(() => {
      return rows?.map(row => {
        const { endAt, organizationAdjustmentRule } = row || {}
        const { deprecatedAt } = organizationAdjustmentRule || {}
        return {
          ...row,
          isRowDisabled: isAdjustmentRuleExpiredOrDeprecated({ deprecatedAt, endAt }),
        }
      })
    }, [rows])

    /**
     * This useEffect is used to autosize the columns when the data is loaded.
     * Re-applying the autosize options prevents the grid from resizing when the data is updated.
     */
    useEffect(() => {
      if (loading) return
      if (!processedRows) return
      if (processedRows.length === 0) return
      dataGridApiRef.current.autosizeColumns(ADJUSTMENT_RULES_DATA_GRID_AUTOSIZE_OPTIONS)
    }, [dataGridApiRef, loading, processedRows])

    if (loading) {
      return <Skeleton variant='rectangular' height={200} />
    }

    return (
      <Card className={classes.root} data-test={dataTest}>
        <CardHeader title={title} />
        <CardContent>
          {processedRows && processedRows.length > 0 ? (
            <DataGrid
              apiRef={dataGridApiRef}
              autosizeOnMount
              autosizeOptions={ADJUSTMENT_RULES_DATA_GRID_AUTOSIZE_OPTIONS}
              columns={columns}
              dataTest={`${dataTest}-grid`}
              loading={loading}
              rows={processedRows}
              hideFooter
            />
          ) : (
            <Typography variant='body2' data-test={`${dataTest}-empty`}>
              {emptyStateMessage}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }
)
