import { IMonthlyVolumeCost } from '#types/graphqlTypes'

export type TContractDuration = {
  contractStartDate: string
  contractEndDate: string
  contractLengthMonths: number
}

export const getContractDurationFromMonthlyCost = (
  monthlyCost: IMonthlyVolumeCost[]
): TContractDuration => {
  const duplicatedMonthlyCost = [...monthlyCost]

  const sortedMonthlyCostByDate = duplicatedMonthlyCost?.sort((a, b) => {
    const dateA = new Date(a.month).getTime()
    const dateB = new Date(b.month).getTime()
    return dateA - dateB
  })

  const [firstCost, ...restOfCosts] = sortedMonthlyCostByDate || []

  const contractStartDate = firstCost?.month

  const contractEndDate = restOfCosts.length
    ? restOfCosts[restOfCosts.length - 1].month
    : contractStartDate

  const contractLengthMonths = monthlyCost.length || 0

  return {
    contractStartDate,
    contractEndDate,
    contractLengthMonths,
  }
}
