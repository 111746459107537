import React, { useMemo, useState } from 'react'
import { RateDifference } from '#components/RateDifference'
import { useAnalytics } from '#hooks'
import { IPercentiles } from '#routes/contract-rates-tool/BandChart/BandChart'
import { BarChart } from '#routes/contract-rates-tool/BarChart'
import { IMarketForecastFragment } from '#routes/contract-rates-tool/graphql/MarketSimFragment'
import { IEstimate } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { brandColors } from 'dpl'
import { Box, Card, CardContent, CardHeader, Grid, Typography } from 'dpl/core'
import { BarChartIcon } from 'dpl/icons'
import moment from 'moment'
import { getMarginDifferenceValues, TContractDuration } from '../../utils'
import { ProjectedMarginToggle } from './components/ProjectedMarginToggle/ProjectedMarginToggle'

export enum IMarginBy {
  monthly = 'monthly',
  per_load = 'per_load',
}

interface ProjectedMarginCardProps {
  contractDuration: TContractDuration
  marketSimAvg: IMarketForecastFragment
  rateEstimate: IEstimate
}

export function ProjectedMarginCard({
  contractDuration,
  marketSimAvg,
  rateEstimate,
}: ProjectedMarginCardProps) {
  const { trackEvent } = useAnalytics()

  const { monthlyCost: monthlyCostProp, volumeWtdAvgCost: volumeWtdAvgCostProp } = marketSimAvg
  const monthlyCost = monthlyCostProp!
  const volumeWtdAvgCost = volumeWtdAvgCostProp!
  const {
    carrierRate: volumeWtdAvgCarrierRate,
    marginPercentage: selectedMarginPercentage,
    marginValue: volumeWtdAvgMarginValue,
  } = rateEstimate
  const volumeWtdAvgContractRate = volumeWtdAvgCarrierRate * (selectedMarginPercentage + 1)

  const [marginBy, setMarginBy] = useState<IMarginBy>(IMarginBy.per_load)

  const percentileByValue = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { __typename, ...percentiles } = volumeWtdAvgCost
    const swapped = Object.entries(percentiles).map(([key, value]) => [
      currencyFormatter(value),
      key,
    ])

    return Object.fromEntries(swapped)
  }, [volumeWtdAvgCost])

  const selectedPercentile = (percentileByValue[currencyFormatter(volumeWtdAvgCarrierRate)] ??
    'cost50') as keyof IPercentiles

  const getMonthlyRows = () => {
    const rows = monthlyCost.map(({ costModelRange, month, volume }) => {
      const monthCarrierRate = costModelRange[selectedPercentile]!
      const isPerLoad = marginBy === IMarginBy.per_load
      const { isPositive, marginDifference, marginDifferencePercentage } =
        getMarginDifferenceValues({
          monthCarrierRate,
          volumeWtdAvgCarrierRate,
          volumeWtdAvgContractRate,
          volume,
          isPerLoad,
        })

      return (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          paddingY={2}
          key={month}>
          <Box>
            <Typography variant='body1'>{moment(month).format('MMMM ‘YY')}</Typography>
            <Typography variant='caption' color={brandColors.coolGray5}>
              {volume} loads
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignContent='flex-end' alignItems='flex-end'>
            <Typography
              variant='subtitle1'
              color={isPositive ? brandColors.green3 : brandColors.error1}>
              <RateDifference value={marginDifference} dataTest='margin-value' />
            </Typography>
            <Typography
              variant='caption'
              color={isPositive ? brandColors.green3 : brandColors.error1}>
              <RateDifference
                value={Number(marginDifferencePercentage)}
                dataTest='margin-percentage'
                isPercentage
              />
            </Typography>
          </Box>
        </Box>
      )
    })
    return rows
  }
  return (
    <Card>
      <CardHeader
        subheader={
          <Grid container justifyContent='space-between'>
            <Grid item>
              <BarChartIcon /> <Typography variant='overline'>Projected Margin</Typography>
            </Grid>
            <Grid justifyContent='flex-end' alignItems='flex-end' item>
              <ProjectedMarginToggle
                marginBy={marginBy}
                onChange={value => {
                  trackEvent('Contract Rates Tool', 'TOGGLE_PROJECTED_MARGIN_MONTHLY_OR_PER_LOAD', {
                    value: `Toggle to ${value}`,
                  })
                  setMarginBy(value)
                }}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container spacing={3} marginBottom={2}>
          <Grid item xs={12}>
            <BarChart
              carrierRate={volumeWtdAvgCarrierRate}
              contractDuration={contractDuration}
              marketSimAvg={marketSimAvg}
              marginPercentage={selectedMarginPercentage}
              marginValue={volumeWtdAvgMarginValue}
              isPerLoad={marginBy === IMarginBy.per_load}
            />
          </Grid>
        </Grid>
        {getMonthlyRows()}
      </CardContent>
    </Card>
  )
}
