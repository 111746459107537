import moment from 'moment'
import { TODAY } from '../constants'

interface IIsAdjustmentRuleExpiredOrDeprecatedProps {
  deprecatedAt: Nullable<string>
  endAt: Nullable<string>
}

export const isAdjustmentRuleExpiredOrDeprecated = ({
  deprecatedAt,
  endAt,
}: IIsAdjustmentRuleExpiredOrDeprecatedProps) => {
  const endDate = moment(endAt).utc()
  const ended = endDate.isBefore(TODAY.utc(), 'day')
  return !!deprecatedAt || ended
}
