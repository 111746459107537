import React, { useCallback } from 'react'
import { Button } from 'dpl/core'
import { useAuthContext } from '../../../hooks'

export interface ISsoButtonProps {
  /**
   * The Stytch ID of the SSO connection.
   */
  connectionId: string
  /**
   * Url for redirecting after sign in
   */
  signInRedirectUrl?: string
  /**
   * Url for redirecting after sign up
   */
  signUpRedirectUrl?: string
}

export function SsoButton({ connectionId, signInRedirectUrl, signUpRedirectUrl }: ISsoButtonProps) {
  const { authClient } = useAuthContext()

  const clickHandler = useCallback(() => {
    authClient.stytch.sso.start({
      connection_id: connectionId,
      login_redirect_url: signInRedirectUrl,
      signup_redirect_url: signUpRedirectUrl,
    })
  }, [authClient.stytch.sso, connectionId, signInRedirectUrl, signUpRedirectUrl])

  return (
    <Button
      data-test='sso-button'
      color='primary'
      variant='outlined'
      fullWidth
      onClick={clickHandler}>
      Continue with Single Sign-On
    </Button>
  )
}
