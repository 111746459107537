import React, { MouseEvent } from 'react'
import { Box, Button } from '../../../core'
import CalendarIcon from '../../../icons/build/CalendarIcon'
import CloseIcon from '../../../icons/build/CloseIcon'
import DownArrowIcon from '../../../icons/build/DownArrowIcon'
import { IconButton } from '../../IconButton'

interface IDateRangePickerLabelProps {
  /**
   * @example 'Pickup Date'
   */
  label: string
  className: string
  /**
   * @default false
   */
  showClearAction?: boolean
  onClick: () => void
  onClear?: (event: MouseEvent<HTMLElement>) => void
}

export function DateRangePickerButton({
  className,
  label,
  onClear,
  onClick,
  showClearAction = false,
}: IDateRangePickerLabelProps) {
  return (
    <Button
      variant='outlined'
      className={className}
      onClick={onClick}
      data-test='DateRangePickerButton'
      startIcon={<CalendarIcon size='large' />}
      endIcon={
        <Box display='flex' alignItems='center'>
          {showClearAction && onClear ? (
            <IconButton
              noPadding
              color='secondary'
              size='large'
              Icon={CloseIcon}
              onClick={onClear}
            />
          ) : (
            <DownArrowIcon size='large' color='skyBlue6' />
          )}
        </Box>
      }>
      {label}
    </Button>
  )
}
