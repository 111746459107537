import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { DiscoveredOrganization } from '@stytch/vanilla-js'
import { useAuthContext, useOrganizationAuthMethods } from '../../hooks'
import { IDiscoveredOrganizationListItemProps } from './DiscoveredOrganizationListItem'

export function useOrganizationRedirect(
  discoveredOrganization: DiscoveredOrganization,
  onOrganizationSelect: IDiscoveredOrganizationListItemProps['onOrganizationSelect']
) {
  const { organization } = discoveredOrganization
  const {
    allowed_auth_methods: allowedAuthMethods,
    auth_methods: authMethods,
    organization_id: organizationId,
  } = organization
  const { isMagicLinkAllowed } = useOrganizationAuthMethods({ authMethods, allowedAuthMethods })
  const navigate = useNavigate()
  const { authClient, signInRedirectTo } = useAuthContext()

  const redirect = useCallback(async () => {
    if (isMagicLinkAllowed) {
      await authClient.sessions.exchangeIntermediateSession({ organization_id: organizationId })
      await authClient.sessions.authenticate()
      navigate(signInRedirectTo, { replace: true })
    } else {
      onOrganizationSelect(discoveredOrganization)
    }
  }, [
    authClient.sessions,
    discoveredOrganization,
    isMagicLinkAllowed,
    navigate,
    onOrganizationSelect,
    organizationId,
    signInRedirectTo,
  ])

  return { redirect }
}
