import React, { memo, useCallback, useState } from 'react'
import { SubmitHandler, useWatch } from 'react-hook-form'
import { ControlledCostPercentileSlider } from '#components/ControlledCostPercentileSlider'
import { IError, ISettingVersion } from '#types/graphqlTypes'
import { ICostStrategyEnum } from '#types/rates'
import { getApolloErrorMessages } from 'dpl/components/ApolloErrorMessages'
import { FormModal, IConfirmationModalProps } from 'dpl/components/Modals'
import { useToastContext } from 'dpl/components/Toast'
import { Box, Typography } from 'dpl/core'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import { useAnalytics } from '../../../../hooks'
import { COST_LABELS_BY_PERCENTILE, SUGGESTED_COST_PERCENTILES } from '../../constants'
import { useUpdateCostSettingsMutation } from './graphql/UpdateCostSettingsMutation'
import { useCostSettingsForm } from './hooks/useCostSettingsForm'
import { ICostSettingsFormSchema } from './schema'
import { getCostSettingsFormSchema, getUpdateCostSettingsInput } from './utils'

export interface ICostSettingsModalProps extends Pick<IConfirmationModalProps, 'onClose'> {
  /**
   * The default cost percentile
   * @example 45
   */
  defaultCostPercentile?: ISettingVersion['defaultCostPercentile']
  /**
   * The default cost strategy label. If custom percentile value, this is undefined.
   * @example ICostStrategyEnum.Balanced
   */
  costStrategyLabel?: ICostStrategyEnum
  /**
   * @default 'CostSettingsModal'
   */
  dataTest?: string
}

interface ISelectFieldLabelProps {
  label: string
  description?: string
}

function SelectFieldLabel({ description, label }: ISelectFieldLabelProps) {
  return (
    <div>
      <Typography variant='body2'>{label}</Typography>
      {description && (
        <Typography variant='caption' color='textSecondary'>
          {description}
        </Typography>
      )}
    </div>
  )
}

const STRATEGY_OPTIONS = SUGGESTED_COST_PERCENTILES.map(percentile => {
  const label = COST_LABELS_BY_PERCENTILE[percentile]
  return {
    value: label,
    label: <SelectFieldLabel label={label} description={`${percentile}th Percentile`} />,
  }
}).concat({
  value: ICostStrategyEnum.Customized,
  label: <SelectFieldLabel label='Customized' description='Select your own percentile' />,
})

const DEFAULT_COST_PERCENTILE = 50

export const CostSettingsModal = memo<ICostSettingsModalProps>(
  ({ costStrategyLabel, dataTest = 'CostSettingsModal', defaultCostPercentile, onClose }) => {
    const { openToast } = useToastContext()
    const { trackEvent } = useAnalytics()

    const [mutationErrors, setMutationErrors] = useState<IError[]>([])

    const { control, handleSubmit } = useCostSettingsForm({
      defaultValues: getCostSettingsFormSchema({
        costStrategyLabel,
        defaultCostPercentile,
      }),
    })

    const { defaultStrategy: currentCostStrategy, percentile: currentPercentile } = useWatch({
      control,
    })

    const [updateCostSettings, { error: updateCostApolloErrors, loading }] =
      useUpdateCostSettingsMutation({
        refetchQueries: ['SpotRatesSettings'],
      })

    const errorMessages = getApolloErrorMessages({
      apolloErrors: updateCostApolloErrors,
      mutationErrors,
    })

    const submitHandler = useCallback<SubmitHandler<ICostSettingsFormSchema>>(
      async formValues => {
        setMutationErrors([])
        const { data } = await updateCostSettings({
          variables: {
            input: getUpdateCostSettingsInput(formValues),
          },
        })
        const { createSpotRatesSettingVersion } = data || {}
        const { settingVersion, errors = [] } = createSpotRatesSettingVersion || {}
        const { id: settingVersionId } = settingVersion || {}

        if (errors.length) {
          trackEvent('Spot Rates Control Panel', 'UPDATE_COST_SETTING_ERROR', {
            formValues,
            mutationErrors: errors,
          })
          setMutationErrors(errors)
          return
        }
        if (settingVersionId) {
          trackEvent('Spot Rates Control Panel', 'UPDATE_COST_SETTING_SUCCESS', {
            formValues,
            settingVersion,
          })
          openToast({
            toastMessage: 'Cost settings successfully updated.',
          })
          onClose()
        }
      },
      [onClose, openToast, trackEvent, updateCostSettings]
    )

    const actions: IConfirmationModalProps['actions'] = [
      {
        label: 'Cancel',
        action: onClose,
        disabled: loading,
        dataTest: `${dataTest}-cancel`,
        ButtonProps: {
          variant: 'text',
        },
      },
      {
        label: 'Save',
        disabled: loading,
        dataTest: `${dataTest}-save`,
        action: handleSubmit(submitHandler),
      },
    ]

    const percentileSliderLabel = `Percentile (${currentPercentile ?? DEFAULT_COST_PERCENTILE}th)`

    return (
      <FormModal
        open
        actions={actions}
        dataTest={dataTest}
        onClose={onClose}
        errors={errorMessages}
        maxWidth='xs'
        title='Default Cost Base'
        titleVariant='h4'>
        <Box display='flex' flexDirection='column' gap={2}>
          <ControlledSelectField
            name='defaultStrategy'
            control={control}
            SelectProps={{
              label: 'Default Strategy',
              variant: 'filled',
              dataTest: `${dataTest}-defaultStrategy`,
              options: STRATEGY_OPTIONS,
              SelectProps: {
                renderValue: value => <SelectFieldLabel label={value as string} />,
              },
            }}
          />
          {currentCostStrategy === 'Customized' && (
            <Box marginY={1}>
              <ControlledCostPercentileSlider
                name='percentile'
                control={control}
                SliderProps={{
                  label: percentileSliderLabel,
                }}
              />
            </Box>
          )}
        </Box>
      </FormModal>
    )
  }
)
