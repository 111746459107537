import React, { memo, useCallback } from 'react'
import {
  Autocomplete,
  AutocompleteHighlightText,
  IAutocompleteProps,
} from 'dpl/components/Autocomplete'
import Typography from 'dpl/core/Typography'
import compact from 'lodash/compact'
import { DEFAULT_USER_ROLES, DEFAULT_USER_TYPES, EMPTY_RESULTS } from './constants'
import {
  IAutocompleteOrganizationCarrierUserEmailQueryVariables,
  useAutocompleteOrganizationCarrierUserEmailLazyQuery,
} from './graphql/AutocompleteOrganizationCarrierUserEmail'
import { IOrganizationCarrierUserEmailFragment } from './graphql/OrganizationCarrierUserEmailResultFragment'

interface IAutocompleteBaseProps
  extends IAutocompleteProps<IOrganizationCarrierUserEmailFragment, true> {}

export interface IAutocompleteOrganizationCarrierUserEmailProps
  extends Omit<
    IAutocompleteBaseProps,
    | 'dataTest'
    | 'freeSolo'
    | 'options'
    | 'loading'
    | 'isOptionEqualToValue'
    | 'getOptionLabel'
    | 'renderOption'
  > {
  /**
   * The ID of the organization carrier to search for users.
   */
  organizationCarrierId: IAutocompleteOrganizationCarrierUserEmailQueryVariables['carrierId']
  /**
   * The user roles to search for.
   *
   * @default ['admin', 'employee']
   */
  userRoles?: IAutocompleteOrganizationCarrierUserEmailQueryVariables['roles']
  /**
   * The user types to search for.
   *
   * @default ['dispatcher', 'owner_operator']
   */
  userTypes?: IAutocompleteOrganizationCarrierUserEmailQueryVariables['userTypes']
  /**
   * @default 'AutocompleteOrganizationCarrierUserEmail'
   */
  dataTest?: string
}

export const AutocompleteOrganizationCarrierUserEmail =
  memo<IAutocompleteOrganizationCarrierUserEmailProps>(
    ({
      dataTest = 'AutocompleteOrganizationCarrierUserEmail',
      organizationCarrierId,
      userRoles = DEFAULT_USER_ROLES,
      userTypes = DEFAULT_USER_TYPES,
      onInputChange,
      ...props
    }) => {
      const [search, { data, loading }] = useAutocompleteOrganizationCarrierUserEmailLazyQuery()

      const { searchOrganizationCarrierUsers } = data || {}
      const { nodes = EMPTY_RESULTS } = searchOrganizationCarrierUsers || {}
      const organizationCarrierUsers = compact<IOrganizationCarrierUserEmailFragment>(nodes)

      const searchByTerm = useCallback(
        (searchTerm: string) => {
          search({
            variables: {
              searchTerm,
              carrierId: organizationCarrierId,
              roles: userRoles,
              userTypes,
            },
          })
        },
        [organizationCarrierId, search, userRoles, userTypes]
      )

      const inputValueChangeHandler = useCallback<
        NonNullable<IAutocompleteBaseProps['onInputChange']>
      >(
        (event, value, reason) => {
          searchByTerm(value)
          if (onInputChange) {
            onInputChange(event, value, reason)
          }
        },
        [onInputChange, searchByTerm]
      )

      return (
        <Autocomplete
          dataTest={dataTest}
          freeSolo
          options={organizationCarrierUsers}
          loading={loading}
          onInputChange={inputValueChangeHandler}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option
            }
            const { consumerUser } = option || {}
            const { user } = consumerUser || {}
            const { emailAddress } = user || {}
            return emailAddress
          }}
          renderOption={(optionProps, option, { inputValue: autocompleteInputValue }) => {
            const { consumerUser } = option || {}
            const { user } = consumerUser || {}
            const { emailAddress } = user || {}
            return (
              <li {...optionProps}>
                <Typography variant='body1' component='div' data-test={`${dataTest}-${option.id}`}>
                  <AutocompleteHighlightText
                    label={emailAddress}
                    inputValue={autocompleteInputValue}
                  />
                </Typography>
              </li>
            )
          }}
          {...props}
        />
      )
    }
  )
