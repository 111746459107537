import { IMapMarkerProps } from 'dpl/components/MapMarker'
import GeoJSON from 'geojson'

export enum POSITION_TYPE {
  PICKUP = 'pickup',
  DROPOFF = 'dropoff',
  LAST_PING = 'last-ping',
  LAST_PING_SELECTED = 'last-ping-selected',
  LAST_PING_DESELECTED = 'last-ping-deselected',
  LAST_PING_LATE_DESELECTED = 'last-ping-late-deselected',
  LAST_PING_LATE = 'last-ping-late',
  LAST_PING_OUTDATED = 'last-ping-outdated',
  LAST_PING_OUTDATED_DESELECTED = 'last-ping-outdated-deselected',
  PREVIOUS_PING = 'previous-ping',
  PREVIOUS_PING_SELECTED = 'previous-ping-selected',
  SHADOW = 'shadow',
  PING = 'ping',
  TRUCK_LOCATION = 'truck-location',
  TRUCK_LOCATION_INACTIVE = 'truck-location-inactive',
  DELIVERED = 'delivered',
  STOPPED = 'stopped',
}

export interface ILatLonPosition {
  /**
   * @example 25.761681
   */
  lat: number
  /**
   * @example -80.191788
   */
  lon: number
}

export interface IMapPosition {
  /**
   * Optional unique identifier
   */
  id?: string | number
  coords: ILatLonPosition
  /**
   * @example 1
   */
  fadingFactor?: number
  /**
   * @example '90210'
   */
  zip?: string
  /**
   * @example 'New York'
   */
  city?: string
  /**
   * @example 'NY'
   */
  state?: string
  /**
   * @example POSITION_TYPE.PING
   */
  type?: POSITION_TYPE
  onClick?: () => void
  /**
   * Optional prop
   */
  data?: GeoJSON.GeoJsonProperties
}

export interface IMapStopProps extends IMapMarkerProps {
  /**
   * @example { lat: 25.761681, lon: -80.191788 }
   */
  coords: ILatLonPosition
}

export interface IMapLane {
  id: string
  /**
   * @example { lat: 25.761681, lon: -80.191788 }
   */
  start?: IMapPosition
  /**
   * @example { lat: 25.761681, lon: -80.191788 }
   */
  stop?: IMapPosition
}

export type TLayer<T> = T & { layerId: string }

export enum ROUTE_STYLE {
  straight = 'straight',
  curvy = 'curvy',
}

export interface IMapRouteGroup {
  id: string
  route: IMapPosition[]
  routeType: string
}

export type WithId<T> = T & { data: { id: string | number } }
