import { IDaysOfWeek } from '../../../../../../types/daysOfWeek'
import { ICarrierCapacityInput } from '../../../../../../types/graphqlTypes'
import { TRoutingGuideCarrierSchema } from '../schema'
import { IAvailabilityType } from '../types'

export interface IGetCarrierCapacityInputProps
  extends Pick<TRoutingGuideCarrierSchema, 'availability' | 'availabilityDays'> {}

export function getCarrierCapacityInput({
  availability,
  availabilityDays,
}: IGetCarrierCapacityInputProps): ICarrierCapacityInput {
  if (availability === IAvailabilityType.anyDay) {
    return {
      sundayCapacity: true,
      mondayCapacity: true,
      tuesdayCapacity: true,
      wednesdayCapacity: true,
      thursdayCapacity: true,
      fridayCapacity: true,
      saturdayCapacity: true,
    }
  }

  return {
    sundayCapacity: availabilityDays.includes(IDaysOfWeek.sun),
    mondayCapacity: availabilityDays.includes(IDaysOfWeek.mon),
    tuesdayCapacity: availabilityDays.includes(IDaysOfWeek.tue),
    wednesdayCapacity: availabilityDays.includes(IDaysOfWeek.wed),
    thursdayCapacity: availabilityDays.includes(IDaysOfWeek.thu),
    fridayCapacity: availabilityDays.includes(IDaysOfWeek.fri),
    saturdayCapacity: availabilityDays.includes(IDaysOfWeek.sat),
  }
}
