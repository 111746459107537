import { IRuleTypeEnum } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { DISPLAY } from 'dpl/constants'

interface IGetAdjustmentRuleAmountLabelProps {
  adjustmentValue: Nullable<number>
  baseCost: Nullable<number>
  ruleAction: Nullable<string>
}

/**
 * Returns the label for an adjustment rule amount based on the adjustment amount and rule action.
 *
 * @returns {string} The label for the adjustment rule amount
 *
 * @example getAdjustmentRuleAmountLabel({ baseCost: 100, adjustmentValue: 60, ruleAction: 'baseline_adjustment' }) // $100.00
 * @example getAdjustmentRuleAmountLabel({ baseCost: 100, adjustmentValue: 0.02, ruleAction: 'additive_adjustment' }) // +$2.00
 * @example getAdjustmentRuleAmountLabel({ baseCost: 100, adjustmentValue: -0.03, ruleAction: 'additive_adjustment' }) // -$3.00
 */
export const getAdjustmentRuleAmountLabel = ({
  adjustmentValue,
  baseCost,
  ruleAction,
}: IGetAdjustmentRuleAmountLabelProps) => {
  if (!baseCost) {
    return DISPLAY.empty
  }

  if (ruleAction === IRuleTypeEnum.base) {
    return currencyFormatter(baseCost)
  }

  if (!adjustmentValue) {
    return DISPLAY.empty
  }

  const shiftAmount = baseCost * adjustmentValue

  // If the shift is positive, prepend a plus sign
  if (shiftAmount > 0) {
    return `+${currencyFormatter(shiftAmount)}`
  }

  return currencyFormatter(shiftAmount)
}
