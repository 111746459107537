import { numericFormatter } from 'react-number-format'

const CURRENCY_PROPS = {
  thousandSeparator: ',',
  decimalScale: 2,
  prefix: '$',
  fixedDecimalScale: true,
  allowNegative: true,
}

export function currencyFormatter(currencyValue: number): string {
  const roundedCurrencyValue = currencyValue.toFixed(2)

  return numericFormatter(roundedCurrencyValue, CURRENCY_PROPS)
}
