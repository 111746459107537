import { useMemo } from 'react'
import { getUserEnabledNavLists } from '#utils/getUserEnabledNavLists'
import { usePermissionsContext } from 'auth/common/context'
import { useUserDetailsContext } from '../context/UserDetailsContext'

export const useHomeRouteHref = () => {
  const { activeProductModules } = useUserDetailsContext()
  const { userPermissions } = usePermissionsContext()

  const { href: homeRouteHref } = useMemo(() => {
    const userEnabledNavLists =
      getUserEnabledNavLists({ activeProductModules, userPermissions }) || []
    const [firstNavList] = userEnabledNavLists
    /**
     * When positioned, Home should be the first positioned nav list.
     */
    const homeNavList =
      userEnabledNavLists.find(({ position }) => position && position === 1) ?? firstNavList
    const { navListItems: firstNavListItems } = homeNavList || {}
    const [homeRoute] = firstNavListItems || []

    return homeRoute ?? {}
  }, [activeProductModules, userPermissions])

  return homeRouteHref || '/'
}
