import React, { memo } from 'react'
import { IActivityEventTypesEnum } from '#types/graphqlTypes'
import { Box, Typography } from 'dpl/core'
import { TRoutingGuideActivityRow } from '../../types'
import { getAddedCarrierActivityDetail, getUpdatedLaneActivityDetail } from '../../utils'
import { getCreatedLaneActivityDetail } from '../../utils/getCreatedLaneActivityDetail'
import { getDeactivatedCarrierActivityDetail } from '../../utils/getDeactivatedCarrierActivityDetail'
import { getReorderedCarriersActivityDetail } from '../../utils/getReorderedCarriersActivityDetail'
import { getUpdatedCarrierActivityDetail } from '../../utils/getUpdatedCarrierActivityDetail'

export const DEFAULT_EMPTY_ACTIVITY_STRING = 'N/A'
export interface IActivityDetailsProps {
  row: TRoutingGuideActivityRow
}

export const ActivityDetails = memo<IActivityDetailsProps>(({ row: rowProp }) => {
  const { eventJson, eventType } = rowProp

  const getEventTypeActivityDetails = (
    eventType: IActivityEventTypesEnum,
    rowEventJson: string
  ) => {
    let parsedJson = {}
    try {
      parsedJson = JSON.parse(rowEventJson)
    } catch (e) {
      console.error('Error parsing eventJson', e) // eslint-disable-line no-console
    }

    if (eventType === IActivityEventTypesEnum.created_lane) {
      const createdLaneActivityDetail = getCreatedLaneActivityDetail(parsedJson)
      return createdLaneActivityDetail
    }

    if (eventType === IActivityEventTypesEnum.added_carrier) {
      const addedCarrierActivityDetail = getAddedCarrierActivityDetail(parsedJson)
      return addedCarrierActivityDetail
    }

    if (eventType === IActivityEventTypesEnum.updated_carrier) {
      const updatedCarrierActivityDetail = getUpdatedCarrierActivityDetail(parsedJson)
      return updatedCarrierActivityDetail
    }

    if (eventType === IActivityEventTypesEnum.updated_lane) {
      // TODO: update styling to vertically center first grid text with second grid text
      const updatedLaneActivityDetail = getUpdatedLaneActivityDetail(parsedJson)
      return updatedLaneActivityDetail
    }

    if (eventType === IActivityEventTypesEnum.reordered_carriers) {
      const reorderedCarriersActivityDetail = getReorderedCarriersActivityDetail(parsedJson)
      return reorderedCarriersActivityDetail
    }

    if (eventType === IActivityEventTypesEnum.deactivated_carrier) {
      const deactivatedCarrierActivityDetail = getDeactivatedCarrierActivityDetail(parsedJson)
      return deactivatedCarrierActivityDetail
    }

    return <Typography variant='caption'>No details available {eventType} </Typography>
  }

  const formattedActivityDetails = getEventTypeActivityDetails(eventType, eventJson)
  return <Box data-test='activity-detail-panel-content'>{formattedActivityDetails}</Box>
})
