import { EQUIPMENT_KEYS } from '../../../../../../constants/equipmentKeys'
import {
  ICountries,
  ICountrySubdivisionCodes,
  ICreateRoutingGuideLaneInput,
} from '../../../../../../types/graphqlTypes'
import { TRoutingGuideLaneSchema } from '../schema'

export interface IGetCreateRoutingGuideLaneInputProps {
  formValues: TRoutingGuideLaneSchema
}

export const getCreateRoutingGuideLaneInput = ({
  formValues,
}: IGetCreateRoutingGuideLaneInputProps): ICreateRoutingGuideLaneInput => {
  const { customer, destination, equipment, laneManager, origin } = formValues || {}
  const {
    city: originCity = '',
    countryCode: originCountryCode = '',
    stateCode: originStateCode = '',
  } = origin || {}
  const {
    city: destinationCity = '',
    countryCode: destinationCountryCode = '',
    stateCode: destinationStateCode = '',
  } = destination || {}
  const { id: organizationShipperId } = customer || {}
  const { id: laneManagerId = '' } = laneManager || {}

  return {
    pickupLocation: {
      city: originCity || '',
      countryCode: originCountryCode as ICountries,
      stateCode: originStateCode as ICountrySubdivisionCodes,
    },
    deliveryLocation: {
      city: destinationCity || '',
      countryCode: destinationCountryCode as ICountries,
      stateCode: destinationStateCode as ICountrySubdivisionCodes,
    },
    equipmentKey: EQUIPMENT_KEYS[equipment],
    laneManagerId,
    organizationShipperId: organizationShipperId || '',
  }
}
