import { IVolumeSchema } from '../schema'

export interface IGetTotalLoadsProps
  extends Pick<IVolumeSchema, 'flatVolumePerMonth' | 'dynamicVolumePerMonth'> {
  contractDurationLength: number
}

export const getTotalLoads = ({
  contractDurationLength,
  dynamicVolumePerMonth,
  flatVolumePerMonth,
}: IGetTotalLoadsProps) => {
  const totalFlatLoads = flatVolumePerMonth ? contractDurationLength * flatVolumePerMonth : 0

  const totalDynamicLoads = dynamicVolumePerMonth?.reduce((totalLoads, { volume }) => {
    return totalLoads + volume
  }, 0)
  return {
    totalFlatLoads,
    totalDynamicLoads,
  }
}
