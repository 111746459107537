import React, { useCallback, useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { TextField } from 'dpl'
import { InputAdornment, makeStyles, Typography } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import {
  REASON_MAX_CHARACTER_COUNT,
  REASON_MIN_CHARACTER_COUNT,
  SECTIONS_STEP_NUMBER,
} from '../../constants'
import { useAdjustmentRuleFormContext, useAdjustmentRuleFormStepsContext } from '../../hooks'
import { TAdjustmentRuleFormSchema } from '../../schema'

const THIS_STEP = SECTIONS_STEP_NUMBER.reason
const NEXT_STEP = THIS_STEP + 1

const useAdjustmentRuleReasonSectionStyles = makeStyles(theme => ({
  textField: {
    '& .Mui-error': {
      color: brandColors.error1,
    },
  },
  inputField: {
    ...theme.typography.h3,
    [theme.breakpoints.up('md')]: {
      '& input': {
        padding: theme.spacing(0, 0, 0, 1),
      },
    },
  },
}))

const LETTERS_AND_NUMBERS_REGEX = /^[a-zA-Z0-9 ]*$/

export function AdjustmentRuleReasonSection() {
  const classes = useAdjustmentRuleReasonSectionStyles()
  const { control, errors, trigger } = useAdjustmentRuleFormContext()
  const { continueClickHandler, formSteps } = useAdjustmentRuleFormStepsContext()
  const isActive = formSteps[THIS_STEP] === 'active'

  const currentReasonValue = useWatch<TAdjustmentRuleFormSchema['reason']>({
    name: 'reason',
    control,
  })

  const enterKeyDownHandler = useCallback(
    event => {
      if (event.key === 'Enter') trigger('reason')
    },
    [trigger]
  )

  /**
   * Handles setting the next step's active state and
   * triggers validation on the reason input based on character count
   */
  useEffect(() => {
    if (!currentReasonValue) return
    if (currentReasonValue === '') return
    if (currentReasonValue.length >= REASON_MIN_CHARACTER_COUNT && isActive) {
      continueClickHandler(NEXT_STEP)
      return
    }
    if (formSteps[NEXT_STEP] !== 'inactive') {
      trigger('reason')
    }
  }, [continueClickHandler, currentReasonValue, formSteps, isActive, trigger])

  return (
    <Controller
      name='reason'
      control={control}
      render={({ onBlur, onChange, value: val }) => {
        const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
          const {
            target: { value = '' },
          } = e || {}
          if (value === '' || LETTERS_AND_NUMBERS_REGEX.test(value)) {
            onChange(value)
          }
        }

        const blurHandler = () => {
          onBlur()
        }

        return (
          <TextField
            dataTest='reason-input'
            name='reason'
            id='reason'
            variant='standard'
            placeholder='Describe reason for rule'
            error={!!errors?.reason?.message}
            clearable={false}
            className={classes.textField}
            onKeyDown={enterKeyDownHandler}
            value={val}
            onBlur={blurHandler}
            onChange={changeHandler}
            InputProps={{
              autoFocus: true,
              className: classes.inputField,
              fullWidth: true,
              margin: 'none',
              disableUnderline: true,
              multiline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography variant='caption' color={brandColors.coolGray4}>
                    {currentReasonValue?.length || 0}/{REASON_MAX_CHARACTER_COUNT}
                  </Typography>
                </InputAdornment>
              ),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              maxLength: REASON_MAX_CHARACTER_COUNT,
            }}
          />
        )
      }}
    />
  )
}
