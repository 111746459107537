import { ICreateSpotRatesSettingVersionInput } from '#types/graphqlTypes'
import { TMarginSettingsSchema } from '../schema'

export function getCreateSpotRatesSettingVersionInput(
  formValues: TMarginSettingsSchema
): ICreateSpotRatesSettingVersionInput {
  const {
    defaultMarginPremium,
    isSettingMaxMarginPremium,
    maxMarginPremium: maxMarginPremiumFormValues,
  } = formValues || {}
  const maxMarginPremium = maxMarginPremiumFormValues || 0

  return {
    defaultMarginPremium: defaultMarginPremium / 100,
    maxMarginPremium: isSettingMaxMarginPremium ? maxMarginPremium / 100 : null,
  }
}
