import React, { memo, useCallback, useMemo } from 'react'
import { REGION_LABELS } from '#routes/spot-rates-control-panel/constants'
import { IRegionsEnum } from '#routes/spot-rates-control-panel/types'
import { ConfirmationModal, IConfirmationModalProps } from 'dpl/components/Modals/ConfirmationModal'
import { Chip, Typography } from 'dpl/core'
import CustomerIcon from 'dpl/icons/build/CustomerIcon'
import DeliveryIcon from 'dpl/icons/build/DeliveryIcon'
import DryVanIcon from 'dpl/icons/build/DryVanIcon'
import PickupIcon from 'dpl/icons/build/PickupIcon'
import ReeferIcon from 'dpl/icons/build/ReeferIcon'
import { TIconComponent } from 'dpl/icons/types'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { IFactorCell, IFactorTypes } from '../../types'

const ICONS: Record<IFactorTypes, TIconComponent> = {
  pickupLocation: PickupIcon,
  deliveryLocation: DeliveryIcon,
  dryVanEquipment: DryVanIcon,
  reeferEquipment: ReeferIcon,
  customer: CustomerIcon,
}

export interface IAdjustmentRuleFactorProps {
  /**
   * @default 'AdjustmentRuleFactor'
   */
  dataTest?: string
  /**
   * The factors of the adjustment rule
   */
  factor: IFactorCell
  /**
   * Boolean indicating if the adjustment rule is deprecated or expired
   */
  isRowDisabled: boolean
}

export const AdjustmentRuleFactor = memo<IAdjustmentRuleFactorProps>(
  ({ dataTest = 'AdjustmentRuleFactor', factor, isRowDisabled = false }) => {
    const { type, value } = factor || {}
    const [firstValue] = value || []
    const label = REGION_LABELS[firstValue as IRegionsEnum]?.label || firstValue

    const isClickable = useMemo(() => value.length > 1, [value])

    const { modalState, toggleModal } = useModalState({
      viewMoreModal: false,
    })

    const { viewMoreModal: isViewMoreOpen } = modalState || {}

    const toggleModalViewMore = useCallback(() => {
      toggleModal('viewMoreModal')
    }, [toggleModal])

    const actions: IConfirmationModalProps['actions'] = useMemo(
      () => [
        {
          label: 'Close',
          action: toggleModalViewMore,
        },
      ],
      [toggleModalViewMore]
    )

    const Icon = ICONS[type]

    return (
      <>
        <Chip
          key={type}
          clickable={isClickable}
          data-test={dataTest}
          onClick={isClickable ? toggleModalViewMore : undefined}
          label={
            isClickable ? (
              <span>
                {`${firstValue}, `}
                <u>{`+${value.length - 1} more`}</u>
              </span>
            ) : (
              label
            )
          }
          variant='outlined'
          color={isRowDisabled ? 'default' : 'secondary'}
          size='small'
          style={{ marginRight: 4 }}
          icon={<Icon size='medium' />}
        />
        {isViewMoreOpen && (
          <ConfirmationModal
            open
            actions={actions}
            onClose={() => {}}
            dataTest={`${dataTest}-modal`}>
            <Typography variant='body2'>{value.join(', ')}</Typography>
          </ConfirmationModal>
        )}
      </>
    )
  }
)
