import { REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import * as yup from 'yup'

/**
 * Volume amount per month for dynamic volume type.
 */

export const DynamicVolumeSchema = yup.object({
  date: yup.string().required(),
  volume: yup.number().required(REQUIRED_MESSAGE),
})

export interface IDynamicVolumeSchema extends yup.InferType<typeof DynamicVolumeSchema> {}
