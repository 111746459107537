import React, { memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants'
import { ISortOrder } from '#types/graphqlTypes'
import DataGrid from 'dpl/components/DataGrid'
import { makeStyles } from 'dpl/core'
import { QueryParamProvider } from 'dpl/providers'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { COLUMNS, DEPRECATED_COLUMNS, FILTERS_CONFIG } from './constants'
import { IArchivedContractRFPsDataGrid } from './types'

type TArchivedContractRFPsDataGridPickedProps =
  | 'loading'
  | 'onFilterOrSortChange'
  | 'rows'
  | 'onPaginationModelChange'
  | 'paginationModel'
  | 'rowCount'

export interface IArchivedContractRFPsDataGridProps
  extends Pick<IArchivedContractRFPsDataGrid, TArchivedContractRFPsDataGridPickedProps> {}

const useArchivedContractRFPsDataGridStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    width: '100%',
  },
}))

export const ArchivedContractRFPsDataGrid = memo<IArchivedContractRFPsDataGridProps>(
  ({ loading, onFilterOrSortChange, onPaginationModelChange, paginationModel, rowCount, rows }) => {
    const classes = useArchivedContractRFPsDataGridStyles()
    const { isFlagEnabled } = useFlagsContext()
    const isMultipleTeamMembersEnabled = isFlagEnabled('rfp_multiple_team_members')
    const navigate = useNavigate()

    /**
     * Handles the click event on a data grid row.
     *
     * @param row - The data grid row object containing an `id` property.
     */
    const handleRowClick = useCallback<NonNullable<IArchivedContractRFPsDataGrid['onRowClick']>>(
      params => {
        const { row } = params ?? {}
        const { displayId } = row ?? {}
        navigate(`${PROTECTED_PATHS.contractRFPs}/RFP-${displayId}`)
      },
      [navigate]
    )

    const columns = useMemo(
      () => (isMultipleTeamMembersEnabled ? COLUMNS : DEPRECATED_COLUMNS),
      [isMultipleTeamMembersEnabled]
    )

    return (
      <div className={classes.container}>
        <QueryParamProvider>
          <DataGrid
            filtersConfig={FILTERS_CONFIG}
            dataTest='archived-contract-rfps-data-grid'
            rows={rows}
            defaultSortValue={ISortOrder.desc}
            onFilterOrSortChange={onFilterOrSortChange}
            columns={columns}
            loading={loading}
            onRowClick={handleRowClick}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[50]}
            paginationMode='server'
            rowCount={rowCount}
            paginationModel={paginationModel}
            visibleFiltersLimit={3}
          />
        </QueryParamProvider>
      </div>
    )
  }
)
