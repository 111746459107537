import React, { memo, useCallback } from 'react'
import { AutocompleteRfp, AutocompleteRfpProps } from '#components/AutocompleteRfp'
import { IAutocompleteRfpFragment } from '#components/AutocompleteRfp/graphql/AutocompleteRfp'
import { IDataGridFilterProps } from 'dpl/components/DataGrid'

export interface IDataGridFilterRfpNameProps
  extends IDataGridFilterProps<Nullable<IAutocompleteRfpFragment>> {
  /**
   * @default 'DataGridFilterRfpName'
   */
  dataTest?: string
}

export const DataGridFilterRfpName = memo<IDataGridFilterRfpNameProps>(
  ({ dataTest = 'DataGridFilterRfpName', name, onChange, value: valueProp, variant }) => {
    const changeHandler = useCallback<NonNullable<AutocompleteRfpProps['onChange']>>(
      (_, newValue) => {
        const { displayId, id, name: rfpName, organizationShipper } = newValue || {}
        const { id: customerId, name: customerName } = organizationShipper || {}
        const value: Nullable<IAutocompleteRfpFragment> = newValue
          ? {
              organizationShipper: {
                id: customerId || '',
                name: customerName || '',
              },
              name: rfpName || '',
              displayId: displayId || 0,
              id: id || '',
              __typename: 'ContractRFP',
            }
          : null

        onChange({
          name,
          value,
        })
      },
      [name, onChange]
    )

    return (
      <div style={{ minWidth: '250px' }}>
        <AutocompleteRfp
          dataTest={dataTest}
          value={(valueProp as IAutocompleteRfpFragment) || null}
          onChange={changeHandler}
          placeholder='RFP Name or ID'
          size={variant === 'menu' ? 'medium' : 'small'}
        />
      </div>
    )
  }
)
