import React from 'react'
import { DataGridFilterCustomer } from '#components/DataGridFilterCustomer'
import { DataGridFilterOrganizationUser } from '#components/DataGridFilterOrganizationUser'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { IArchivedContractRFPsDataGrid } from '../types'

export const FILTERS_CONFIG: IArchivedContractRFPsDataGrid['filtersConfig'] = {
  searchTerm: {
    type: 'textInput',
    placeholder: 'RFP Name or ID',
    label: 'RFP Name or ID',
  },
  customer: {
    type: 'custom',
    FilterComponent: DataGridFilterCustomer,
  },
  assignee: {
    type: 'custom',
    FilterComponent: function Render(props) {
      const { isFlagEnabled } = useFlagsContext()
      const isMultipleTeamMembersEnabled = isFlagEnabled('rfp_multiple_team_members')

      return (
        <DataGridFilterOrganizationUser
          {...props}
          AutocompleteOrganizationUserProps={
            isMultipleTeamMembersEnabled
              ? { label: 'Team Member', placeholder: 'Team Member' }
              : { label: 'Assignee', placeholder: 'Assignee' }
          }
        />
      )
    },
  },
}
