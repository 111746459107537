import { useState } from 'react'
import { IUserDetailsQuery, useUserDetailsQuery } from '../graphql/UserDetails'
import { IOrganizationProductModuleStatus, IProductModules } from '../types/graphqlTypes'
import { useAnalytics } from './useAnalytics'

export interface IUseUserDetailsProps {
  /**
   * @default false
   */
  skip?: boolean
}

export interface IUseUserDetailsState {
  activeProductModules?: IProductModules[]
  hasLoaded: boolean
  loading: boolean
  userEmail?: string
  userFullName?: string
  userId?: string
  organizationUser?: IUserDetailsQuery['currentSession']['organizationUser']
  user?: IUserDetailsQuery['currentSession']['user']
}

export const useUserDetails = ({
  skip = false,
}: IUseUserDetailsProps = {}): IUseUserDetailsState => {
  const { identifyUser } = useAnalytics()

  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  const { data, loading } = useUserDetailsQuery({
    skip,
    onCompleted: data => {
      setHasLoaded(true)
      const { currentSession } = data || {}
      const { organizationUser, user } = currentSession || {}
      const { id: organizationUserId, organization } = organizationUser || {}
      const { id: organizationId, name: organizationName } = organization || {}
      const { emailAddress, firstName, id: userId, lastName } = user || {}
      const name = [firstName, lastName].join(' ').trim()

      identifyUser({
        id: userId,
        email: emailAddress,
        ...(name ? { name } : {}),
        organizationUserId,
        organization: {
          id: organizationId,
          name: organizationName,
        },
      })
    },
  })

  const { currentSession } = data || {}
  const { organizationUser, user } = currentSession || {}
  const { organization } = organizationUser || {}
  const { productModules = [] } = organization || {}
  const { emailAddress: userEmail, firstName, id: userId, lastName } = user || {}
  const userFullName = [firstName, lastName].join(' ').trim()

  // Filter out the active product modules
  const activeProductModules = productModules
    .filter(({ status }) => status === IOrganizationProductModuleStatus.active)
    .map(({ productModule }) => productModule)

  return {
    activeProductModules,
    hasLoaded,
    loading,
    userEmail,
    userFullName,
    userId,
    organizationUser,
    user,
  }
}
