import { ISettingVersion } from '#types/graphqlTypes'
import { TMarginSettingsSchema } from '../schema'

interface IGetMarginSettingsSchemaProps {
  defaultMarginPremium?: ISettingVersion['defaultMarginPremium']
  maxMarginPremium?: ISettingVersion['maxMarginPremium']
}

export const getMarginSettingsSchema = ({
  defaultMarginPremium,
  maxMarginPremium,
}: IGetMarginSettingsSchemaProps): TMarginSettingsSchema | undefined => {
  if (!defaultMarginPremium) {
    return undefined
  }

  return {
    defaultMarginPremium: defaultMarginPremium * 100,
    isSettingMaxMarginPremium: Boolean(maxMarginPremium),
    maxMarginPremium: maxMarginPremium ? maxMarginPremium * 100 : null,
  }
}
