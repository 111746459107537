import React from 'react'
import { RouteObject } from 'react-router-dom'
import { PROTECTED_PATHS, UNPROTECTED_PATHS } from '#constants/paths'
import { Authenticate } from '#routes/authenticate'
import { ContractRatesToolPage } from '#routes/contract-rates-tool/ContractRatesToolPage'
import {
  ArchivedContractRFPsPage,
  ContractRFPDetailPage,
  ContractRFPsPage,
} from '#routes/contract-rfp'
import { CustomersPage } from '#routes/customers/CustomersPage'
import { Home } from '#routes/home'
import { Login } from '#routes/login'
import { LoginOrganization } from '#routes/login_organization'
import { Onboard } from '#routes/onboard'
import { OrganizationProfile } from '#routes/organization_profile'
import { Quotes } from '#routes/quotes'
import { RatesRedirect } from '#routes/rates-redirect'
import { ReportDetail } from '#routes/report-detail'
import { Reports } from '#routes/reports'
import { ResetPassword } from '#routes/reset_password'
import { RoutingGuideLaneDetailsPage, RoutingGuideLanesPage } from '#routes/routing-guide'
import { SpotRatesControlPanel } from '#routes/spot-rates-control-panel'
import { SpotRatesTool } from '#routes/spot-rates-tool'
import { UserProfile } from '#routes/user_profile'
import { IUserPermissions } from 'auth/common/context/PermissionsContext/types'
import { PageNotFound } from 'dpl/templates/PageNotFound'
import { GraphiQL } from 'network'

/**
 * Default Protected Routes
 */
export const DEFAULT_PROTECTED_ROUTES: RouteObject[] = [
  {
    path: PROTECTED_PATHS.home,
    element: <Home />,
  },
  {
    path: PROTECTED_PATHS.userProfile,
    element: <UserProfile />,
  },
  {
    path: PROTECTED_PATHS.organizationProfile,
    element: <OrganizationProfile />,
  },
  {
    path: PROTECTED_PATHS.customers,
    element: <CustomersPage />,
  },
  {
    path: PROTECTED_PATHS.customerCreate,
    element: <CustomersPage />,
  },
  {
    path: `${PROTECTED_PATHS.customerEdit}/:id`,
    element: <CustomersPage />,
  },
  {
    path: `${PROTECTED_PATHS.customerDeactivate}/:id`,
    element: <CustomersPage />,
  },
]

/**
 * Reporting
 */
export const REPORTING_ROUTES: RouteObject[] = [
  {
    path: PROTECTED_PATHS.reports,
    element: <Reports />,
  },
  {
    path: `${PROTECTED_PATHS.reports}/:id`,
    element: <ReportDetail />,
  },
]

/**
 * Authentication Routes
 */
export const AUTH_ROUTES: RouteObject[] = [
  {
    path: UNPROTECTED_PATHS.authenticate,
    element: <Authenticate />,
  },
  {
    path: UNPROTECTED_PATHS.login,
    element: <Login />,
  },
  {
    path: UNPROTECTED_PATHS.magicLink,
    element: <Login isMagicLinkInvalid />,
  },
  {
    path: `${UNPROTECTED_PATHS.login}/:organizationSlug`,
    element: <LoginOrganization />,
  },
]

/**
 * GraphiQL Route
 */
export const GRAPHI_QL_ROUTE: RouteObject = {
  path: '/graphiql',
  element: <GraphiQL />,
}

/**
 * Not Found Route
 */
export const NOT_FOUND_ROUTE: RouteObject = {
  path: '*',
  element: <PageNotFound />,
}

/**
 * Onboard Route
 */
export const ONBOARD_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.onboard,
  element: <Onboard />,
}

/**
 * Reset Password Route
 */
export const RESET_PASSWORD_ROUTE: RouteObject = {
  path: UNPROTECTED_PATHS.resetPassword,
  element: <ResetPassword />,
}

/**
 * Spot Rates Routes
 */
const RATES_REDIRECT_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.rates,
  element: <RatesRedirect />,
}

const SPOT_RATES_TOOL_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.spotRatesTool,
  element: <SpotRatesTool />,
}

export const SPOT_RATES_CONTROL_PANEL_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.spotRatesControlPanel,
  element: <SpotRatesControlPanel />,
}

/**
 * Contract Rates Routes
 */
export const CONTRACT_RATES_TOOL_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.contractRatesTool,
  element: <ContractRatesToolPage />,
}

/**
 * Routing Guide Routes
 */
export const ROUTING_GUIDE_LANE_CREATE_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.routingGuideLaneCreate,
  element: <RoutingGuideLanesPage />,
}

export const ROUTING_GUIDE_LANE_DETAILS_ROUTE: RouteObject = {
  path: `${PROTECTED_PATHS.routingGuideLanes}/:id/:tab?`,
  element: <RoutingGuideLaneDetailsPage />,
}

export const ROUTING_GUIDE_LANES_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.routingGuideLanes,
  element: <RoutingGuideLanesPage />,
}

const QUOTES_ROUTE = {
  path: `${PROTECTED_PATHS.quotes}/:quoteId?`,
  element: <Quotes />,
}

/**
 * Contract RFPs Routes
 */
export const CONTRACT_RFPS_ROUTE: RouteObject = {
  path: PROTECTED_PATHS.contractRFPs,
  element: <ContractRFPsPage />,
}

export const CONTRACT_RFP_DETAIL_ROUTE: RouteObject = {
  path: `${PROTECTED_PATHS.contractRFPs}/:rfpId`,
  element: <ContractRFPDetailPage />,
}

export const ARCHIVED_CONTRACT_RFPS_ROUTE: RouteObject = {
  path: `${PROTECTED_PATHS.contractRFPs}/archived`,
  element: <ArchivedContractRFPsPage />,
}

/**
 * list of routes that will be added via user permission.
 * keep alphabetically sorted!!
 */
export const ROUTES_BY_PERMISSION: {
  [K in keyof IUserPermissions]?: RouteObject[]
} = {
  'contract_rates_tool.read_app': [CONTRACT_RATES_TOOL_ROUTE, RATES_REDIRECT_ROUTE],
  'contract_rfp.read_app': [
    CONTRACT_RFPS_ROUTE,
    ARCHIVED_CONTRACT_RFPS_ROUTE,
    CONTRACT_RFP_DETAIL_ROUTE,
  ],
  'quotes.read_app': [QUOTES_ROUTE],
  'rates_tool.read_app': [
    SPOT_RATES_TOOL_ROUTE,
    SPOT_RATES_CONTROL_PANEL_ROUTE,
    RATES_REDIRECT_ROUTE,
  ],
  'reporting.read_app': REPORTING_ROUTES,
  'routing_guide.read_app': [
    ROUTING_GUIDE_LANES_ROUTE,
    ROUTING_GUIDE_LANE_CREATE_ROUTE,
    ROUTING_GUIDE_LANE_DETAILS_ROUTE,
  ],
}
