import React, { memo, useMemo } from 'react'
import { Box, ITypographyProps, Typography } from 'dpl/core'
import moment from 'moment'
import { TAdjustmentRulesRow } from '../../types'
import { IAdjustmentRuleTimeframeStatus } from './constants'
import { getAdjustmentRuleTimeframeStatus } from './utils'

export interface IAdjustmentRuleTimeframeProps
  extends Pick<TAdjustmentRulesRow, 'startAt' | 'endAt' | 'organizationAdjustmentRule'> {
  /**
   * @default 'AdjustmentRuleTimeframe'
   */
  dataTest?: string
}

export const AdjustmentRuleTimeframe = memo<IAdjustmentRuleTimeframeProps>(
  ({ dataTest = 'AdjustmentRuleTimeframe', endAt, organizationAdjustmentRule, startAt }) => {
    const { deprecatedAt } = organizationAdjustmentRule || {}

    const status = useMemo<IAdjustmentRuleTimeframeStatus>(
      () => getAdjustmentRuleTimeframeStatus({ deprecatedAt, endAt, startAt }),
      [deprecatedAt, endAt, startAt]
    )

    const timeframeLabelColor = useMemo<ITypographyProps['color']>(() => {
      if (
        status === IAdjustmentRuleTimeframeStatus.expired ||
        status === IAdjustmentRuleTimeframeStatus.deactivated
      ) {
        return 'textSecondary'
      }
      return 'textPrimary'
    }, [status])

    const timeframeLabel = useMemo(() => {
      const startDate = moment(startAt).utc().format('MMM DD, YYYY')

      if (!endAt) {
        return `${startDate} – indefinitely`
      }

      const endDate = moment(endAt).utc().format('MMM DD, YYYY')
      return `${startDate} – ${endDate}`
    }, [endAt, startAt])

    const deactivatedDateLabel = useMemo(() => {
      if (!deprecatedAt) {
        return ''
      }
      const deprecatedDate = moment(deprecatedAt).utc().format('MMM DD, YYYY')
      return `Deactivated: ${deprecatedDate}`
    }, [deprecatedAt])

    return (
      <Box display='flex' flexDirection='column' gap={0.5} data-test={dataTest}>
        <Typography
          data-test={`${dataTest}-timeframe`}
          component='div'
          variant='body2'
          color={timeframeLabelColor}>
          {timeframeLabel}
        </Typography>
        {deactivatedDateLabel && (
          <Typography
            data-test={`${dataTest}-deactivatedDate`}
            component='div'
            variant='caption'
            color='textSecondary'>
            {deactivatedDateLabel}
          </Typography>
        )}
      </Box>
    )
  }
)
