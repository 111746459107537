import { IVolumeTypes } from '#routes/contract-rates-tool/ContractRatesToolPage/types'
import { IQueryFetchContractRatesArgs } from '#types/graphqlTypes'
import { DEFAULT_FLAT_VOLUME_PER_MONTH } from '../constants'
import { IContractDurationSchema, IVolumeSchema } from '../schema'
import { getDateVolumeRange, transformMonthYearRangeToQueryFormatted } from './getDateVolumeRange'

interface IMapVolumeSchemaToQuery {
  contractDurationSchemaInputs: IContractDurationSchema
  volumeSchemaInputs: IVolumeSchema
}

export function mapVolumeSchemaToQuery({
  contractDurationSchemaInputs,
  volumeSchemaInputs,
}: IMapVolumeSchemaToQuery): Pick<IQueryFetchContractRatesArgs, 'monthlyVolume'> {
  const { dynamicVolumePerMonth, flatVolumePerMonth, volumeType } = volumeSchemaInputs
  const { contractEndDate, contractStartDate } = contractDurationSchemaInputs

  const monthYearRange =
    volumeType === IVolumeTypes.flatVolume
      ? getDateVolumeRange({
          startDate: contractStartDate,
          endDate: contractEndDate,
          volume: flatVolumePerMonth || DEFAULT_FLAT_VOLUME_PER_MONTH,
        })
      : dynamicVolumePerMonth || []

  const formattedMonthlyVolume = transformMonthYearRangeToQueryFormatted({ monthYearRange })
  //   TODO: check if length of formattedMonthlyVolume is length of contractDuration - send track event?

  return {
    monthlyVolume: formattedMonthlyVolume,
  }
}
