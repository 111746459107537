import React, { useState, ChangeEvent, useCallback } from 'react'
import { minMaxTempFormatter } from '#utils/minMaxTempFormatter'
import { Radio } from 'dpl/components/Radio'
import { ISliderProps, Slider } from 'dpl/components/Slider'
import { Box, Typography } from 'dpl/core'
import { ToggleButtonPopper } from './ToggleButtonPopper'
import { LaneInformationFormSchema } from './laneInformationFormSchema'

const MIN_TEMP = -50
const MIN_TEMP_LABEL = '-50ºF'
const MAX_TEMP = 75
const MAX_TEMP_LABEL = '75ºF'
const DEFAULT_TEMP = 45
const EQUIPMENT_TYPE_KEY_LABEL: Record<string, string> = {
  '53_reefer': 'Reefer',
  '53_van': 'Dry Van',
}

interface IEquipmentInputMenuProps {
  equipmentKey: LaneInformationFormSchema['equipmentKey']
  temperature: LaneInformationFormSchema['temperature']
  onChange: (args: { equipmentKey: string; minTemp: number | null; maxTemp: number | null }) => void
}

export function EquipmentInputMenu({
  equipmentKey,
  onChange,
  temperature: currentTemperatureValue,
}: IEquipmentInputMenuProps) {
  const [isReefer, setIsReefer] = useState(equipmentKey === '53_reefer')

  const currentMaxTemp = currentTemperatureValue?.max ?? DEFAULT_TEMP
  const currentMinTemp = currentTemperatureValue?.min ?? DEFAULT_TEMP
  const formattedCurrentTemperature = minMaxTempFormatter({
    minTemp: currentMinTemp,
    maxTemp: currentMaxTemp,
  })
  const toggleButtonLabel = `${EQUIPMENT_TYPE_KEY_LABEL[equipmentKey]}${equipmentKey === '53_reefer' ? ` (${formattedCurrentTemperature})` : ''}`

  const [sliderTemperature, setSliderTemperature] = useState<number[]>([
    currentMinTemp,
    currentMaxTemp,
  ])
  const [sliderMinTemp, sliderMaxTemp] = sliderTemperature
  const formattedSliderTemperature = minMaxTempFormatter({
    minTemp: sliderMinTemp,
    maxTemp: sliderMaxTemp,
  })
  const sliderLabel = `Temperature (${formattedSliderTemperature})`

  const equipmentSelectHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setIsReefer(value === '53_reefer')
  }

  const temperatureChangeHandler: ISliderProps['onChange'] = (_, newValue) => {
    setSliderTemperature(newValue as number[])
  }

  const commitEquipmentValues = useCallback(() => {
    let minTemp = null
    let maxTemp = null
    if (isReefer) {
      minTemp = sliderMinTemp
      maxTemp = sliderMaxTemp
    }
    const equipmentKey = isReefer ? '53_reefer' : '53_van'
    onChange({ equipmentKey, minTemp, maxTemp })
  }, [isReefer, onChange, sliderMaxTemp, sliderMinTemp])

  return (
    <ToggleButtonPopper label={toggleButtonLabel} onClose={commitEquipmentValues}>
      <Box display='flex' gap={2} flexDirection='column'>
        <Typography variant='caption' color='textSecondary' component='div'>
          Equipment
        </Typography>
        <Radio
          label='Reefer'
          value='53_reefer'
          checked={isReefer}
          onChange={equipmentSelectHandler}
        />
        {isReefer && (
          <Slider
            min={MIN_TEMP}
            minLabel={MIN_TEMP_LABEL}
            max={MAX_TEMP}
            maxLabel={MAX_TEMP_LABEL}
            value={sliderTemperature}
            label={sliderLabel}
            onChange={temperatureChangeHandler}
            valueLabelFormat={value => `${value}°`}
          />
        )}
        <Radio
          label='Dry Van'
          value='53_van'
          checked={!isReefer}
          onChange={equipmentSelectHandler}
        />
      </Box>
    </ToggleButtonPopper>
  )
}
