import React, { useState, SetStateAction, Dispatch, useCallback, useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import { RateDifference } from '#components/RateDifference'
import { RateEstimateSlider, IRateSliderProps } from '#components/RateSlider'
import { DEFAULT_MARGIN_PERCENTAGE } from '#constants/rates'
import { useAnalytics } from '#hooks'
import { IEstimate } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { sumCurrency } from '#utils/sumCurrency'
import { IconButton, Switch } from 'dpl'
import NumericFormat from 'dpl/components/NumericFormat'
import { TextFieldGroup } from 'dpl/components/TextFieldGroup'
import { Tooltip } from 'dpl/components/Tooltip'
import {
  Box,
  Slide,
  Card,
  CardContent,
  CardHeader,
  Typography,
  SlideProps,
  makeStyles,
} from 'dpl/core'
import HelpIcon from 'dpl/icons/build/HelpIcon'
import InfoIcon from 'dpl/icons/build/InfoIcon'
import QuoteIcon from 'dpl/icons/build/QuoteIcon'
import { brandColors } from 'dpl/theme/colors'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { BandChart } from '../BandChart'
import { contractDurationFormatter } from '../ContractRatesToolPage/forms/ContractLaneInformationForm/utils'
import { TContractDuration } from '../ContractRatesToolPage/utils/getContractDurationFromMonthlyCost'
import { IMarketForecastFragment } from '../graphql/MarketSimFragment'
import { RateForecastInfoModal } from './components/RateForecastInfoModal'

interface EstimateCardProps {
  contractDuration: TContractDuration
  marketSimAvg: IMarketForecastFragment
  rateEstimate: IEstimate
  setRateEstimate: Dispatch<SetStateAction<IEstimate>>
}

const useEstimateCardStyles = makeStyles(theme => ({
  icon: {
    marginTop: `-${theme.spacing(0.5)}`,
    marginBottom: `-${theme.spacing(0.5)}`,
    marginRight: `-${theme.spacing(1)}`,
    color: brandColors.coolGray5,
  },
}))

export function EstimateCard({
  contractDuration,
  marketSimAvg,
  rateEstimate,
  setRateEstimate,
}: EstimateCardProps) {
  const { trackEvent } = useAnalytics()
  const { volumeWtdAvgCost } = marketSimAvg
  const formattedContractDuration = contractDuration
    ? contractDurationFormatter(contractDuration)
    : '--'

  const [showVolatility, setShowVolatility] = useState(false)
  const [slideDirection, setSlideDirection] = useState<SlideProps['direction']>('up')
  const contractRateValue = sumCurrency([rateEstimate.carrierRate, rateEstimate.marginValue])
  const classes = useEstimateCardStyles()
  const { modalState, toggleModal } = useModalState({
    infoModal: false,
  })
  const { infoModal } = modalState || {}

  const closeInfoModalHandler = useCallback(() => {
    toggleModal('infoModal', false)
  }, [toggleModal])

  const openInfoModalHandler = useCallback(() => {
    toggleModal('infoModal', true)
    trackEvent('Contract Rates Tool', 'OPEN_RATE_FORECAST_INFO_MODAL')
  }, [toggleModal])

  const percentileByValue = useMemo(() => {
    const { ...percentiles } = volumeWtdAvgCost ?? {}
    const swapped = Object.entries(percentiles).map(([key, value]) => [value, key])
    return Object.fromEntries(swapped)
  }, [volumeWtdAvgCost])

  const rateChangeHandler: IRateSliderProps['onChange'] = useCallback(
    (_, carrierRate) => {
      setRateEstimate(({ marginPercentage }) => ({
        carrierRate,
        marginPercentage,
        marginValue: marginPercentage * carrierRate,
      }))
      setSlideDirection(carrierRate > rateEstimate.carrierRate ? 'down' : 'up')
      trackEvent('Contract Rates Tool', 'ADJUST_CONTRACT_RATE_VALUE', {
        field: `Slider ${percentileByValue[carrierRate]} Percentile`,
        value: carrierRate,
      })
    },
    [percentileByValue, rateEstimate.carrierRate, setRateEstimate, trackEvent]
  )

  const marginPercentageHandler = useCallback(
    percentage => {
      const marginPercentage = (percentage ?? 0) / 100

      setRateEstimate(({ carrierRate }) => ({
        carrierRate,
        marginPercentage,
        marginValue: marginPercentage * carrierRate,
      }))
      setSlideDirection(marginPercentage > rateEstimate.marginPercentage ? 'down' : 'up')
      trackEvent('Contract Rates Tool', 'ADJUST_CONTRACT_RATE_VALUE', {
        field: 'Margin Percentage Input',
        value: marginPercentage,
      })
    },
    [rateEstimate.marginPercentage, setRateEstimate, trackEvent]
  )

  const marginAmountHandler = useCallback(
    amount => {
      const marginValue = amount ?? 0

      setRateEstimate(({ carrierRate }) => ({
        carrierRate,
        marginPercentage: marginValue / carrierRate,
        marginValue,
      }))
      setSlideDirection(marginValue > rateEstimate.marginValue ? 'down' : 'up')
      trackEvent('Contract Rates Tool', 'ADJUST_CONTRACT_RATE_VALUE', {
        field: 'Margin Amount Input',
        value: marginValue,
      })
    },
    [rateEstimate.marginValue, setRateEstimate, trackEvent]
  )

  const showVolatilityHandler = () => {
    trackEvent('Contract Rates Tool', 'TOGGLE_BAND_CHART_VOLATILITY_SWITCH', {
      showVolatility: !showVolatility,
    })
    setShowVolatility(value => !value)
  }

  /**
   * useEffectOnce to set estimate initial values on mount.
   */
  useEffectOnce(() => {
    const carrierRate = volumeWtdAvgCost?.cost50 ?? 0 // CCM50 = Balanced rate strategy

    setRateEstimate({
      carrierRate,
      marginPercentage: DEFAULT_MARGIN_PERCENTAGE,
      marginValue: DEFAULT_MARGIN_PERCENTAGE * carrierRate,
    })
  })

  return (
    <Card>
      <CardHeader
        subheader={
          <Box display='flex' alignItems='center'>
            <QuoteIcon />
            <Typography variant='overline'>Estimates (per load)</Typography>
          </Box>
        }
      />
      <CardContent>
        <Box display='flex' flexDirection='column' gap={1} pb={3}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            gap={1}
            alignItems='center'>
            <Typography variant='h3' fontWeight={500}>
              Contract Rate
            </Typography>
            <Typography variant='h3' fontWeight={500} overflow='hidden'>
              <Slide key={contractRateValue} in direction={slideDirection} timeout={300}>
                <div>{contractRateValue}</div>
              </Slide>
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            gap={1}
            alignItems='center'>
            <Typography variant='subtitle2' color='textSecondary'>
              Est. Carrier Rate
            </Typography>
            <Typography variant='subtitle2' data-test='carrier-rate-value'>
              {currencyFormatter(rateEstimate.carrierRate)}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            gap={1}
            alignItems='center'>
            <Typography variant='subtitle2' color='textSecondary'>
              Est. Margin
            </Typography>
            <Typography variant='subtitle2' color='textSecondary'>
              <RateDifference value={rateEstimate.marginValue} dataTest='margin-value' />
            </Typography>
          </Box>
        </Box>
        <Box bgcolor={brandColors.coolGray1} borderRadius={1} p={2}>
          <Typography variant='subtitle1' marginBottom={2}>
            Adjust the rate and premium to see how they impact the quote
          </Typography>
          <Box display='flex' gap={1} pb={2}>
            <Typography variant='caption' color='textSecondary' whiteSpace='nowrap'>
              Est. Carrier Rate
            </Typography>
            <Box borderBottom={`1px dotted ${brandColors.coolGray5}`} flexGrow={2} />
            <Typography variant='subtitle2' display='flex'>
              {currencyFormatter(rateEstimate.carrierRate)}
              <Tooltip
                color='dark'
                title='Aggressive: 20th percentile, Balanced: 50th percentile, and Conservative: 80th percentile.'>
                <Box display='flex' alignItems='center' gap={0.5}>
                  <InfoIcon size='small' color='coolGray5' />
                </Box>
              </Tooltip>
            </Typography>
          </Box>
          <RateEstimateSlider
            costModel={volumeWtdAvgCost}
            onChange={rateChangeHandler}
            value={rateEstimate.carrierRate}
          />
          <Box bgcolor={brandColors.white} mt={2}>
            <TextFieldGroup
              Component={NumericFormat}
              label='Premium'
              fields={[
                {
                  value: rateEstimate.marginPercentage * 100,
                  name: 'percentage',
                  allowNegative: true,
                  decimalScale: 2,
                  suffix: '%',
                  placeholder: '%',
                  allowLeadingZeros: false,
                  type: 'tel',
                  onValueChange: ({ floatValue }) => {
                    marginPercentageHandler(floatValue)
                  },
                  dataTest: 'margin-percentage-input',
                },
                {
                  value: rateEstimate.marginValue,
                  name: 'amount',
                  allowNegative: true,
                  decimalScale: 2,
                  prefix: '$',
                  placeholder: '$',
                  allowLeadingZeros: false,
                  type: 'tel',
                  onValueChange: ({ floatValue }) => {
                    marginAmountHandler(floatValue)
                  },
                  dataTest: 'margin-value-input',
                  fixedDecimalScale: true,
                },
              ]}
            />
          </Box>
        </Box>
        <Box pt={3} display='flex' flexDirection='column' gap={3}>
          <Box display='flex' flexDirection='column'>
            <Box
              width='100%'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              flexWrap='wrap'>
              <Box display='flex' flexDirection='row' alignItems='center' pr={1}>
                <Typography variant='h4'>Forecasted Carrier Rates </Typography>
                <IconButton
                  Icon={HelpIcon}
                  size='large'
                  className={classes.icon}
                  color='secondary'
                  onClick={openInfoModalHandler}
                />
              </Box>
              <Box>
                <Switch
                  checked={showVolatility}
                  onChange={showVolatilityHandler}
                  label='Show Rate Range'
                />
              </Box>
            </Box>
            <Typography variant='caption' color={brandColors.coolGray5}>
              {formattedContractDuration}
            </Typography>
          </Box>

          <RateForecastInfoModal open={infoModal} onClose={closeInfoModalHandler} />
          <BandChart
            showVolatility={showVolatility}
            marginPercentage={rateEstimate.marginPercentage}
            carrierRate={rateEstimate.carrierRate}
            marketSimAvg={marketSimAvg}
          />
        </Box>
      </CardContent>
    </Card>
  )
}
