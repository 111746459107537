import { ICostStrategyEnum, IRuleTypeEnum } from '#types/rates'
import { IRegionOption, IRegionsEnum } from './types'

export const SUGGESTED_COST_PERCENTILES = [20, 50, 80]

export const COST_LABELS_BY_PERCENTILE: Record<number, ICostStrategyEnum> = {
  20: ICostStrategyEnum.Aggressive,
  50: ICostStrategyEnum.Balanced,
  80: ICostStrategyEnum.Conservative,
}

export const COST_PERCENTILES_BY_LABEL: Record<ICostStrategyEnum, Nullable<number>> = {
  [ICostStrategyEnum.Aggressive]: 20,
  [ICostStrategyEnum.Balanced]: 50,
  [ICostStrategyEnum.Conservative]: 80,
  [ICostStrategyEnum.Customized]: null,
}

export const RULE_TYPE_LABELS = {
  [IRuleTypeEnum.base]: 'Base',
  [IRuleTypeEnum.additive]: 'Additive',
  [IRuleTypeEnum.doNotBid]: 'Do Not Bid',
}

export const REGION_LABELS: Record<IRegionsEnum, IRegionOption> = {
  [IRegionsEnum.NORTHEAST]: {
    label: 'Northeast',
    value: IRegionsEnum.NORTHEAST,
    description:
      'Connecticut, Maine, Massachusetts, New Hampshire, New Jersey, Rhode Island, Vermont',
  },
  [IRegionsEnum.MID_ATLANTIC]: {
    label: 'Mid-Atlantic',
    value: IRegionsEnum.MID_ATLANTIC,
    description: 'Delaware, New York, Pennsylvania',
  },
  [IRegionsEnum.EAST]: {
    label: 'East',
    value: IRegionsEnum.EAST,
    description: 'Maryland, N. Carolina, S. Carolina, Virginia, West Virginia',
  },
  [IRegionsEnum.SOUTHEAST]: {
    label: 'Southeast',
    value: IRegionsEnum.SOUTHEAST,
    description: 'Alabama, Florida, Georgia, Mississippi, Tennessee ',
  },
  [IRegionsEnum.MIDWEST]: {
    label: 'Midwest',
    value: IRegionsEnum.MIDWEST,
    description: 'Indiana, Kentucky, Michigan, Ohio',
  },
  [IRegionsEnum.NORTHERN_MIDWEST]: {
    label: 'Northern Midwest',
    value: IRegionsEnum.NORTHERN_MIDWEST,
    description: 'Iowa, Minnesota, Montana, N. Dakota, S. Dakota, Wisconsin',
  },
  [IRegionsEnum.CENTRAL]: {
    label: 'Central',
    value: IRegionsEnum.CENTRAL,
    description: 'Illinois, Kansas, Missouri, Nebraska',
  },
  [IRegionsEnum.SOUTH]: {
    label: 'South',
    value: IRegionsEnum.SOUTH,
    description: 'Arkansas, Louisiana, Oklahoma, Texas',
  },
  [IRegionsEnum.SOUTHWEST]: {
    label: 'Southwest',
    value: IRegionsEnum.SOUTHWEST,
    description: 'Arizona, Colorado, Idaho, Nevada, New Mexico, Utah, Wyoming',
  },
  [IRegionsEnum.WEST]: {
    label: 'West',
    value: IRegionsEnum.WEST,
    description: 'California, Oregon, Washington',
  },
}

export const REGION_OPTIONS: IRegionOption[] = Object.values(IRegionsEnum).map(
  region => REGION_LABELS[region]
)
