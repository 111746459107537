import React from 'react'
import { AutocompleteHighlightText } from 'dpl/components/Autocomplete'
import { Stack, Typography } from 'dpl/core'
import { IOrganizationShipperItemFragment } from './graphql/OrganizationShipperFragment'

export interface OrganizationShipperOptionProps {
  inputValue: string
  option: IOrganizationShipperItemFragment
  /**
   * @default 'AutocompleteOrganizationCarrierOption'
   */
  dataTest?: string
}

export function OrganizationShipperOption({
  dataTest = 'organization-shipper-option',
  inputValue,
  option,
}: OrganizationShipperOptionProps) {
  const { externalId, name } = option || {}

  return (
    <Stack data-test={dataTest} gap={0.25}>
      <Typography component='div' variant='body1'>
        <AutocompleteHighlightText label={`${name}`} inputValue={inputValue} />
      </Typography>
      <Typography component='div' variant='caption' color='textSecondary'>
        <AutocompleteHighlightText label={`ID ${externalId}`} inputValue={inputValue} />
      </Typography>
    </Stack>
  )
}
