import React, { memo } from 'react'
import { Avatar } from 'dpl/components/Avatar'
import { Box, Typography } from 'dpl/core'
import { TAdjustmentRulesRow } from '../../types'

export interface IAdjustmentRuleCreatedByProps
  extends Pick<TAdjustmentRulesRow, 'organizationAdjustmentRule' | 'isRowDisabled'> {
  /**
   * @default 'AdjustmentRuleCreatedBy'
   */
  dataTest?: string
}

export const AdjustmentRuleCreatedBy = memo<IAdjustmentRuleCreatedByProps>(
  ({ dataTest = 'AdjustmentRuleCreatedBy', isRowDisabled = false, organizationAdjustmentRule }) => {
    const { createdByOrganizationUser } = organizationAdjustmentRule || {}
    const { user } = createdByOrganizationUser || {}
    const { fullName } = user || {}

    return (
      <Box display='flex' alignItems='center' gap={1.5} data-test={dataTest}>
        <Avatar name={`${fullName}`} size='xsmall' dataTest={`${dataTest}-avatar`} hideTooltip />
        <Typography
          variant='body2'
          component='div'
          data-test={`${dataTest}-name`}
          color={isRowDisabled ? 'textSecondary' : 'textPrimary'}>
          {fullName}
        </Typography>
      </Box>
    )
  }
)
