import React, { memo } from 'react'
import { makeStyles, Typography, Button } from 'dpl/core'
import { useBreakpoint } from 'dpl/hooks'
import { AddIcon, DryVanIcon } from 'dpl/icons'
import { brandColors } from 'dpl/theme/colors'

export interface ICarrierListEmptyCardProps {
  /**
   * toggles open the add carrier to routing guide form
   */
  onAddCarrierClick: () => void
  /**
   * @default 'CarrierListEmptyCard'
   */
  dataTest?: string
}

const useCarrierListEmptyCardStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    backgroundColor: brandColors.skyBlue0,
    border: `1px solid ${brandColors.skyBlue2}`,
    borderRadius: theme.spacing(0.5),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: '50%',
    backgroundColor: brandColors.skyBlue2,
    flexShrink: 0,
  },
  icon: {
    width: `${theme.spacing(4)} !important`,
    height: `${theme.spacing(4)} !important`,
    color: brandColors.skyBlue7,
  },
  textContainer: { marginBottom: theme.spacing(2) },
}))

export const CarrierListEmptyCard = memo(
  ({ dataTest = 'CarrierListEmptyCard', onAddCarrierClick }: ICarrierListEmptyCardProps) => {
    const classes = useCarrierListEmptyCardStyles()
    const { isMobile } = useBreakpoint()

    return (
      <div data-test={dataTest} className={classes.root}>
        <div className={classes.iconContainer}>
          <DryVanIcon className={classes.icon} />
        </div>
        <div>
          <div className={classes.textContainer}>
            <Typography variant='subtitle1' marginBottom={1}>
              No Carriers Added
            </Typography>
            <Typography variant='body2'>
              Add carriers to activate this routing guide and leverage the power of auto-offers.
            </Typography>
          </div>
          <Button
            size='medium'
            onClick={onAddCarrierClick}
            startIcon={<AddIcon size='large' />}
            fullWidth={isMobile}>
            Add Carrier
          </Button>
        </div>
      </div>
    )
  }
)
