import { ICostModel } from '#types/costModel'
import get from 'lodash/get'

interface IGetPercentileAmountProps {
  costModel: Nullable<ICostModel>
  percentile: number
}

/**
 * Returns the cost amount for the specified percentile.
 *
 * @returns {number} The cost amount for the specified percentile
 *
 * @example getPercentileAmount({ costModel: { cost25: 250, cost50: 500, cost75: 750 }, percentile: 50 }) // 500
 */
export const getPercentileAmount = ({
  costModel,
  percentile,
}: IGetPercentileAmountProps): number => {
  return get(costModel, `cost${percentile}`, 0)
}
