import React from 'react'
import { makeStyles, Typography } from 'dpl/core'
import ControlledTextField from 'forms/components/ControlledTextField'
import { useCustomerFormContext } from './hooks'

const useCustomerFormStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    flex: 1,
  },
}))

const EXTERNAL_ID_HELPER_TEXT =
  'This is how your customer is identified within your organization. The identifier must be unique and may include only letters, numbers, underscores, and dashes.'

export interface ICustomerFormProps {
  disableForm: boolean
}

export function CustomerForm({ disableForm }: ICustomerFormProps) {
  const { control, errors } = useCustomerFormContext()
  const classes = useCustomerFormStyles()

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1'>Customer Details</Typography>
      <ControlledTextField
        name='name'
        control={control}
        TextFieldProps={{
          label: 'Name',
          required: true,
          error: !!errors.name,
          helperText: errors?.name?.message,
          dataTest: 'input-name',
          disabled: disableForm,
        }}
      />
      <div>
        <ControlledTextField
          name='externalId'
          control={control}
          TextFieldProps={{
            label: 'External ID',
            required: true,
            error: !!errors.externalId,
            helperText: errors?.externalId?.message || EXTERNAL_ID_HELPER_TEXT,
            dataTest: 'input-externalId',
            disabled: disableForm,
          }}
        />
      </div>
    </div>
  )
}
