import React from 'react'
import { ConfirmationModal, IConfirmationModalProps } from 'dpl/components/Modals'
import { Typography } from 'dpl/core'

interface ICloseFormConfirmationModalProps {
  /**
   * Action taken when confirming closing out of form
   */
  onConfirm: () => void
  /**
   * Action taken when canceling close
   */
  onCancel: () => void
}

const DATA_TEST = 'CloseAdjustmentRuleFormConfirmationModal'

export function CloseFormConfirmationModal({
  onCancel,
  onConfirm,
}: ICloseFormConfirmationModalProps) {
  const actions: IConfirmationModalProps['actions'] = [
    {
      label: 'No, stay',
      ButtonProps: {
        variant: 'text',
      },
      action: onCancel,
      dataTest: `${DATA_TEST}-stay`,
    },
    {
      label: 'Yes, close',
      action: onConfirm,
      dataTest: `${DATA_TEST}-close`,
    },
  ]

  return (
    <ConfirmationModal
      dataTest={DATA_TEST}
      open
      title='Are you sure you want to close this?'
      onClose={onCancel}
      actions={actions}>
      <Typography variant='body2'>
        None of the information entered will be saved and you’ll have to re-enter everything.
      </Typography>
    </ConfirmationModal>
  )
}
