import React, { memo, useCallback } from 'react'
import { brandColors } from 'dpl'
import DataGrid from 'dpl/components/DataGrid'
import { Box, DataGridProps, useGridApiRef } from 'dpl/core'
import { ActivityDetails } from './components'
import { ROUTING_GUIDE_ACTIVITY_COLUMNS } from './constants'
import { IRoutingGuideActivityDataGrid } from './types'

type TRoutingGuideActivityDataGridPickedProps =
  | 'loading'
  | 'onPaginationModelChange'
  | 'paginationModel'
  | 'rows'
  | 'rowCount'
export interface IRoutingGuideActivityDataGridProps
  extends Pick<IRoutingGuideActivityDataGrid, TRoutingGuideActivityDataGridPickedProps> {}

export const RoutingGuideActivityDataGrid = memo<IRoutingGuideActivityDataGridProps>(
  ({ loading, rowCount, rows: rowsProp = [], onPaginationModelChange, paginationModel }) => {
    const apiRef = useGridApiRef()
    const getDetailPanelContent = React.useCallback<
      NonNullable<DataGridProps['getDetailPanelContent']>
    >(({ row }) => <ActivityDetails row={row} />, [])

    const onRowClick = useCallback<NonNullable<IRoutingGuideActivityDataGrid['onRowClick']>>(
      params => {
        apiRef.current.toggleDetailPanel(params.id)
      },
      [apiRef]
    )
    const columns = ROUTING_GUIDE_ACTIVITY_COLUMNS

    return (
      <Box height='auto' width='100%' border={`1px solid ${brandColors.coolGray3}`}>
        <DataGrid
          apiRef={apiRef}
          columns={columns}
          dataTest='routing-guide-activity-data-grid'
          loading={loading}
          rowCount={rowCount}
          rows={rowsProp}
          onPaginationModelChange={onPaginationModelChange}
          onRowClick={onRowClick}
          pageSizeOptions={[50]}
          paginationMode='server'
          paginationModel={paginationModel}
          getDetailPanelContent={getDetailPanelContent}
        />
      </Box>
    )
  }
)
