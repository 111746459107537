import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { Switch, ISwitchProps } from 'dpl/components/Switch'

type IBaseControllerProps = ControllerProps<React.ComponentType<ISwitchProps>>

export interface IControlledSwitchProps extends Pick<IBaseControllerProps, 'control' | 'name'> {
  defaultValue?: boolean
  SwitchProps?: Partial<Omit<ISwitchProps, 'checked' | 'onBlur' | 'onChange'>>
}

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledSwitch
 *   name='facility.locality'
 *   control={control}
 * />
 * ```
 */
const ControlledSwitch = memo<IControlledSwitchProps>(
  ({ control, defaultValue, name, SwitchProps = {} }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onBlur, onChange, value: checked }) => {
          const changeHandler: ISwitchProps['onChange'] = (_, checked) => {
            onChange(checked)
          }

          return (
            <Switch onChange={changeHandler} checked={checked} onBlur={onBlur} {...SwitchProps} />
          )
        }}
      />
    )
  }
)
export default ControlledSwitch
