import React, { useCallback } from 'react'
import { NumericFormat, NumericFormatProps as INumericFormatProps } from 'react-number-format'
import { TextField } from 'dpl/components'
import { makeStyles, type TextFieldProps } from 'dpl/core'
import { StepperArrowsAdornment } from './StepperArrowsAdornment'

interface INumericFormatCustomProps {
  numericFormatProps?: Omit<INumericFormatProps, 'displayType' | 'getInputRef'>
}

const NumericFormatCustom = React.forwardRef<INumericFormatProps, INumericFormatCustomProps>(
  function NumericFormatCustom({ numericFormatProps = {}, ...props }, ref) {
    return <NumericFormat {...props} getInputRef={ref} {...numericFormatProps} />
  }
)

export type INumberFieldProps = Omit<TextFieldProps, 'onChange'> & {
  /**
   * Value of the field
   */
  value?: number | null
  /**
   * Callback fired when value changes
   */
  onChange?: (value: number | null) => void
  /**
   * Changes the increment by which the up/down arrow buttons will update the value
   * @default 1
   */
  step?: number
  /**
   * Maximum number that the value can be
   * If the value is greater than or equal to the max, the increment action is disabled
   */
  max?: number
  /**
   * Minimum number that the value can be
   * If the value is less than or equal to the min, the decrement action is disabled
   */
  min?: number
  /**
   * Props to pass to the <NumbericFormat /> component
   */
  NumericFormatProps?: INumericFormatCustomProps['numericFormatProps']
  /**
   * @default 'NumberField'
   */
  dataTest?: string
}

const useNumberFieldStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      paddingRight: theme.spacing(0.75),
    },
  },
  inputLabel: {
    // This is so the label doesn't overlap with the StepperArrows
    paddingRight: theme.spacing(3),
  },
}))

const NumberField = React.forwardRef<HTMLDivElement, INumberFieldProps>(
  function NumberField(props, ref) {
    const classes = useNumberFieldStyles()
    const {
      disabled = false,
      max = Infinity,
      min = -Infinity,
      NumericFormatProps,
      onChange,
      size = 'medium',
      InputProps = {},
      step = 1,
      value,
      inputProps = {},
      dataTest,
      InputLabelProps = {},
      ...rest
    } = props

    const increment = useCallback(() => {
      const newValue = Number(value || 0) + step
      if (newValue <= max) {
        onChange?.(newValue)
      }
    }, [max, step, value, onChange])

    const decrement = useCallback(() => {
      const newValue = Number(value || 0) - step
      if (newValue >= min) {
        onChange?.(newValue)
      }
    }, [min, step, value, onChange])

    const numericFormatProps: INumericFormatProps = {
      max,
      min,
      value,
      // Allow to increment with ArrowUp and decrement with ArrowDown
      onKeyDown: event => {
        if (event.key === 'ArrowUp') {
          increment()
        } else if (event.key === 'ArrowDown') {
          decrement()
        }
      },
      onValueChange: values => {
        const { floatValue } = values
        onChange?.(floatValue ?? null)
      },
      ...NumericFormatProps,
    }

    return (
      <TextField
        {...rest}
        dataTest={dataTest}
        ref={ref}
        value={value ?? ''}
        disabled={disabled}
        size={size}
        className={classes.root}
        InputProps={{
          inputComponent: NumericFormatCustom as any,
          endAdornment: (
            <StepperArrowsAdornment
              onIncrement={increment}
              onDecrement={decrement}
              decrementDisabled={disabled || (value ?? 0) - step < min}
              incrementDisabled={disabled || (value ?? 0) + step > max}
              size={size}
            />
          ),
          ...InputProps,
        }}
        InputLabelProps={{
          className: classes.inputLabel,
          ...InputLabelProps,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ ...inputProps, numericFormatProps, inputMode: 'decimal' }}
      />
    )
  }
)

export default NumberField
