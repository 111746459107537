import { IAdjustmentTypeEnum } from '#types/graphqlTypes'
import { IRuleTypeEnum } from '../../../../../../types/rates'
import { decimalToPercentFormatter } from '../../../../../../utils/decimalToPercentFormatter'
import { ISpotRatesAdjustmentRuleVersionFragment } from '../../../AdjustmentRulesSection/graphql/SpotRatesAdjustmentRuleVersionFragment'
import { TRateStrategySchema } from '../../schema/RateStrategySchema'
import { ADJUSTMENT_TYPES } from '../../types'

export const mapRateStrategytoFormSchema = (
  rule: Nullable<
    Pick<
      ISpotRatesAdjustmentRuleVersionFragment,
      'adjustmentType' | 'adjustmentValue' | 'ruleAction'
    >
  >,
  defaultCostPercentile?: Nullable<number>
): TRateStrategySchema => {
  const { adjustmentType, adjustmentValue, ruleAction } = rule || {}

  const costRuleType = adjustmentType === IAdjustmentTypeEnum.cost ? ruleAction : null
  const marginRuleType = adjustmentType === IAdjustmentTypeEnum.margin ? ruleAction : null

  const costBasePercentile =
    costRuleType === IRuleTypeEnum.base ? adjustmentValue : defaultCostPercentile

  const adjustmentValuePercent = decimalToPercentFormatter(adjustmentValue || 0, false) as number

  const costAdditiveShiftPercentage =
    costRuleType === IRuleTypeEnum.additive ? adjustmentValuePercent : null
  const marginBasePercentage = marginRuleType === IRuleTypeEnum.base ? adjustmentValuePercent : null
  const marginAdditivePercentage =
    marginRuleType === IRuleTypeEnum.additive ? adjustmentValuePercent : null

  const rateStrategy: TRateStrategySchema = {
    adjustmentType: (adjustmentType || '') as ADJUSTMENT_TYPES,
    costRuleType: (costRuleType || IRuleTypeEnum.base) as TRateStrategySchema['costRuleType'],
    costBasePercentile: costBasePercentile || null,
    costAdditiveShiftPercentage,
    marginRuleType: (marginRuleType || IRuleTypeEnum.base) as TRateStrategySchema['marginRuleType'],
    marginBasePercentage,
    marginAdditivePercentage,
  }

  return rateStrategy
}
