import React, { useCallback, useMemo } from 'react'
import { useAuthContext, useAuthMember } from 'auth/b2b/hooks'
import { getAuthApiErrorMessage } from 'auth/common/utils/getAuthApiErrorMessage'
import DefaultCompany from 'dpl/assets/DefaultCompany.svg'
import { Spinner } from 'dpl/components/Spinner'
import { useToastContext } from 'dpl/components/Toast'
import compact from 'lodash/compact'
import { useRequest } from 'network'
import { getAdminModuleRoles, getProductModuleRoles } from 'user-profile'
import { AUTH_REDIRECT_URLS } from '../login_organization/LoginOrganization'
import UserProfile from './UserProfile'
import { useUserProfileQuery } from './graphql/UserProfile'

const TRANSFIX_ORG_NAME = 'Transfix'

const PLACEHOLDER_PROPS = {
  userInfo: {
    activeSinceDate: '2023-10-15T04:00:00Z',
  },
  isTransfixAdmin: true,
  isOrgAdmin: false,
}

function UserProfileContainer() {
  const { openToast } = useToastContext() || {}

  const { authClient } = useAuthContext() || {}
  const { member } = useAuthMember() || {}
  const { organization_id: organizationId = '' } = member || {}

  const { data, loading } = useUserProfileQuery()
  const { currentSession, productModules: productModulesList } = data || {}
  const { organizationUser, user } = currentSession || {}
  const { emailAddress: email = '', firstName = '', lastName = '', phoneNumber } = user || {}
  const {
    id: organizationUserId,
    organization,
    organizationTeams = [],
    statusChangedAt,
  } = organizationUser || {}
  const { name: organizationName = '', productModules = [], thumbLogo } = organization || {}
  const { url: image } = thumbLogo || {}

  const moduleAccess = useMemo(
    () => ({
      adminModuleRoles: getAdminModuleRoles({
        isTransfixUser: organizationName === TRANSFIX_ORG_NAME,
        organizationTeams,
      }),
      productModuleRoles: getProductModuleRoles({
        organizationTeams,
        productModules: compact(
          productModules.map(({ productModule }) =>
            productModulesList?.find(({ key }) => key === productModule)
          )
        ),
      }),
    }),
    [organizationName, organizationTeams, productModules]
  )

  const userInfo = useMemo(() => {
    return {
      organizationUserId: organizationUserId || '',
      firstName: firstName || '',
      lastName: lastName || '',
      fullName: firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName || '',
      email,
      phoneNumber: phoneNumber || '',
      statusChangedAt: statusChangedAt || '',
      status: 'active',
    }
  }, [email, firstName, lastName, organizationUserId, phoneNumber])

  const organizationInfo = useMemo(() => {
    return {
      name: organizationName,
      image: image || DefaultCompany,
    }
  }, [image, organizationName])

  const [sendResetPasswordEmail] = useRequest({
    requestFn: authClient.passwords.requestMagicLink,
  })

  const resetPasswordHandler = useCallback(
    async (e, resend?: boolean) => {
      const { data, error } = await sendResetPasswordEmail({
        email,
        organization_id: organizationId,
        ...AUTH_REDIRECT_URLS,
        reset_password_redirect_url: `${AUTH_REDIRECT_URLS.reset_password_redirect_url}?email=${encodeURIComponent(email)}`,
      })

      if (data && resend) {
        openToast({ toastMessage: 'Password email resent' })
      } else if (error) {
        openToast({ toastMessage: getAuthApiErrorMessage(error?.errors), toastType: 'alert' })
      }
    },
    [email, openToast, organizationId, sendResetPasswordEmail]
  )

  if (loading) {
    return <Spinner />
  }

  return (
    <UserProfile
      {...PLACEHOLDER_PROPS}
      userInfo={userInfo}
      organizationInfo={organizationInfo}
      onResetPassword={resetPasswordHandler}
      moduleAccess={moduleAccess}
    />
  )
}

export default UserProfileContainer
