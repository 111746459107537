import React from 'react'
import { CardHeader, CardHeaderProps as ICardHeaderProps, Box } from 'dpl/core'
import TransfixLogoIcon from 'dpl/icons/build/TransfixLogoIcon'

const CARD_HEADER_PROPS: ICardHeaderProps = {
  titleTypographyProps: {
    variant: 'h2',
  },
  subheaderTypographyProps: {
    variant: 'subtitle1',
  },
}

export interface IAuthenticationCardHeaderProps {
  title?: ICardHeaderProps['title']
  subheader?: ICardHeaderProps['subheader']
  showLogo?: boolean
  className?: string
}

function TitleWithLogo({ title }: Pick<IAuthenticationCardHeaderProps, 'title'>) {
  return (
    <Box display='flex' flexDirection='column' gap={3}>
      <TransfixLogoIcon size='xxlarge' />
      {title}
    </Box>
  )
}

export function AuthenticationCardHeader({
  className,
  showLogo,
  subheader,
  title: titleProp,
}: IAuthenticationCardHeaderProps) {
  const title = showLogo ? TitleWithLogo({ title: titleProp }) : titleProp

  return (
    <CardHeader className={className} {...CARD_HEADER_PROPS} title={title} subheader={subheader} />
  )
}
