import React, { memo } from 'react'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import { makeStyles } from 'dpl/core/styles'
import { useSpotRatesControlPanelContext } from '../../context'
import { CostSettingsCard } from '../CostSettingsCard'
import { MarginSettingsCard } from '../MarginSettingsCard'

export interface ISpotRatesSettingsSectionProps {
  /**
   * @default 'SpotRatesSettingsSection'
   */
  dataTest?: string
}

const useSpotRatesSettingsSectionStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& > div': {
      flex: 1,
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
}))

export const SpotRatesSettingsSection = memo<ISpotRatesSettingsSectionProps>(
  ({ dataTest = 'SpotRatesSettingsSection' }) => {
    const classes = useSpotRatesSettingsSectionStyles()

    const { userPermissions } = usePermissionsContext()
    const canEditSettings = userPermissions['rates_tool.update_spot_setting_version']

    const { spotRatesSettings } = useSpotRatesControlPanelContext()
    const { createdAt, defaultCostPercentile, defaultMarginPremium, loading, maxMarginPremium } =
      spotRatesSettings

    return (
      <div data-test={dataTest} className={classes.grid}>
        <div>
          <CostSettingsCard
            isLoading={loading}
            defaultCostPercentile={defaultCostPercentile}
            updatedAt={createdAt || ''}
            canEdit={canEditSettings}
          />
        </div>
        <div>
          <MarginSettingsCard
            isLoading={loading}
            defaultMarginPremium={defaultMarginPremium}
            maxMarginPremium={maxMarginPremium}
            updatedAt={createdAt || ''}
            canEdit={canEditSettings}
          />
        </div>
      </div>
    )
  }
)
