import React from 'react'
import SvgIcon from '../components/SvgIcon'
import { RoutingGuideUnavailableSvg } from '../icons'

function RoutingGuideUnavailableIcon(props) {
  return (
    <SvgIcon
      className='RogersSvgIcon-RoutingGuideUnavailable'
      component={RoutingGuideUnavailableSvg}
      {...props}
    />
  )
}

export default RoutingGuideUnavailableIcon
