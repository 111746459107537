import React, { useCallback, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { FORMATS } from 'dpl/constants/datetime'
import { Typography, Grid, Box } from 'dpl/core'
import CalendarIcon from 'dpl/icons/build/CalendarIcon'
import { DatePickersProvider } from 'dpl/providers/DatePickersProvider'
import ControlledCheckbox from 'forms/components/ControlledCheckbox'
import ControlledDatePicker from 'forms/components/ControlledDatePicker'
import get from 'lodash/get'
import moment from 'moment'
import { hasAdjustmentRuleStarted } from '../../../AdjustmentRulesSection/components/AdjustmentRulesDataGrid/cells/utils/hasAdjustmentRuleStarted/hasAdjustmentRuleStarted'
import { SECTIONS_STEP_NUMBER } from '../../constants'
import { useAdjustmentRuleFormStepsContext, useAdjustmentRuleFormContext } from '../../hooks'
import { TAdjustmentRuleFormSchema } from '../../schema'
import { StepSection } from '../StepSection'

const THIS_STEP = SECTIONS_STEP_NUMBER.timeframe
const NEXT_STEP = THIS_STEP + 1

const END_DATE_HELPER_TEXT = 'Until 11:59 PM ET'
const getEndDateHelperText = (errorMessage: string | undefined, noEndDate: boolean | undefined) => {
  if (noEndDate) return END_DATE_HELPER_TEXT
  if (errorMessage) return errorMessage
  return END_DATE_HELPER_TEXT
}

export interface IAdjustmentRuleTimeframeSectionProps {
  isEditMode?: boolean
}

export function AdjustmentRuleTimeframeSection({
  isEditMode,
}: IAdjustmentRuleTimeframeSectionProps) {
  const { clearErrors, control, errors, setValue } = useAdjustmentRuleFormContext()
  const { continueClickHandler, editClickHandler, formSteps } = useAdjustmentRuleFormStepsContext()
  const currentState = formSteps[THIS_STEP]
  const todaysDate = moment()

  const continueHandler = useCallback(() => {
    continueClickHandler(NEXT_STEP)
  }, [continueClickHandler])

  const editHandler = useCallback(() => {
    editClickHandler(THIS_STEP, NEXT_STEP)
  }, [editClickHandler])

  const watchedValues = useWatch<Pick<TAdjustmentRuleFormSchema, 'timeframe'>>({
    name: ['timeframe.startDate', 'timeframe.noEndDate', 'timeframe.endDate'],
    control,
  })

  const startDate = get(watchedValues, 'timeframe.startDate')
  const momentStartDate = startDate ? moment(startDate) : undefined
  const noEndDate = get(watchedValues, 'timeframe.noEndDate')
  const endDate = get(watchedValues, 'timeframe.endDate')

  const noEndDateChangeHandler = useCallback(
    e => {
      if (e.currentTarget?.checked) {
        setValue('timeframe.endDate', null)
        clearErrors('timeframe.endDate')
      }
    },
    [setValue, clearErrors]
  )

  const startDateHelperMessage = get(errors, 'timeframe.startDate.message') || 'Begins 12:00 AM ET'
  const endDateHelperMessage = getEndDateHelperText(
    get(errors, 'timeframe.endDate.message'),
    noEndDate
  )

  const formattedValues = useMemo(() => {
    const formattedStartDate = moment(startDate).utc().format(FORMATS.monthDayYear)
    const formattedEndDate = noEndDate
      ? 'indefinitely'
      : moment(endDate).utc().format(FORMATS.monthDayYear)
    return `${formattedStartDate} - ${formattedEndDate}`
  }, [endDate, noEndDate, startDate])

  const minEndDate = useMemo(() => {
    if (momentStartDate?.isBefore(todaysDate)) return todaysDate
    return momentStartDate
  }, [todaysDate, momentStartDate])

  const disableStartDate = useMemo(
    () => hasAdjustmentRuleStarted({ startAt: startDate }) && !!isEditMode,
    [isEditMode, startDate]
  )

  return (
    <DatePickersProvider>
      <StepSection
        title='Timeframe'
        stepIndex={THIS_STEP}
        formSteps={formSteps}
        onContinue={continueHandler}
        onEdit={editHandler}>
        {currentState === 'active' && (
          <div>
            <Typography variant='body2' color='textSecondary' marginTop={1.5}>
              Shipments that pick up within this time period will be affected.
            </Typography>

            <Grid container marginTop={1} columnSpacing={1.5} rowSpacing={3}>
              <Grid item xs={6}>
                <ControlledDatePicker
                  name='timeframe.startDate'
                  control={control}
                  DatePickerProps={{
                    label: 'Start Date',
                    required: false,
                    minDate: todaysDate,
                    error: !!get(errors, 'timeframe.startDate.message'),
                    helperText: startDateHelperMessage,
                    dataTest: 'startDatePicker',
                    disabled: disableStartDate,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledDatePicker
                  name='timeframe.endDate'
                  control={control}
                  DatePickerProps={{
                    label: 'End Date',
                    required: false,
                    minDate: minEndDate,
                    error: noEndDate ? undefined : !!get(errors, 'timeframe.endDate.message'),
                    helperText: endDateHelperMessage,
                    disabled: !!noEndDate,
                    dataTest: 'endDatePicker',
                  }}
                />
              </Grid>
              <Grid item marginLeft={0.5}>
                <ControlledCheckbox
                  name='timeframe.noEndDate'
                  control={control}
                  CheckboxProps={{
                    label: 'No specified end date',
                    onChange: noEndDateChangeHandler,
                    dataTest: 'noEndDateCheckbox',
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {currentState === 'review' && (
          <Box
            display='flex'
            gap={1.5}
            alignItems='center'
            marginTop={3}
            data-test='timeframeReview'>
            <CalendarIcon size='xlarge' color='coolGray5' />
            <Typography variant='body2'>{formattedValues}</Typography>
          </Box>
        )}
      </StepSection>
    </DatePickersProvider>
  )
}
