export const SOLUTIONS_CONSOLE_EVENTS = {
  'Embedded Forms': {
    CREATE_CUSTOMER_SUCCESS: 'Successfully created customer',
    CREATE_CUSTOMER_ERROR: 'Error creating customer',
  },
  'Organization Login': {
    REQUEST_LOGIN_MAGIC_LINK: 'Request login magic link',
    LOGIN_WITH_PASSWORD_SUCCESS: 'Successfully logged in with password',
    LOGIN_WITH_PASSWORD_ERROR: 'Error logging in with password and email',
  },
  'Discovery Login': {
    REQUEST_NEW_LOGIN_MAGIC_LINK: 'Request new login magic link',
    REQUEST_LOGIN_MAGIC_LINK: 'Request login magic link',
  },
  'Reset Password': {
    REQUEST_FORGOT_PASSWORD: 'Request forgot password magic link',
    REQUEST_RESET_PASSWORD: 'Request password reset magic link',
    CHANGE_PASSWORD_SUCCESS: 'Successfully submitted new password',
    CHANGE_PASSWORD_ERROR: 'Error submitting new password',
  },
  Authenticate: {
    DISCOVERY_TOKEN_SUCCESS: 'Successfully authenticated discovery token',
    DISCOVERY_TOKEN_ERROR: 'Error authenticating discovery token',
    MULTI_TENANT_TOKEN_SUCCESS: 'Successfully authenticated multi tenant token',
    MULTI_TENANT_TOKEN_ERROR: 'Error authenticating multi tenant token',
    SSO_TOKEN_SUCCESS: 'Successfully authenticated SSO token',
    SSO_TOKEN_ERROR: 'Error authenticating SSO token',
  },
  'Routing Guide': {
    CREATE_ROUTING_GUIDE_LANE_SUCCESS: 'Successfully created routing guide lane',
    CREATE_ROUTING_GUIDE_LANE_ERROR: 'Error creating routing guide lane',
    CREATE_ROUTING_GUIDE_LANE_FORM_VALIDATION_ERROR:
      'Form validation error when creating routing guide lane',
    ADD_CARRIER_TO_ROUTING_GUIDE_LANE_SUCCESS: 'Successfully added carrier to routing guide lane',
    ADD_CARRIER_TO_ROUTING_GUIDE_LANE_ERROR: 'Error adding carrier to routing guide lane',
    ADD_CARRIER_TO_ROUTING_GUIDE_LANE_VALIDATION_ERROR:
      'Form validation error when adding carrier to routing guide lane',
    UPDATE_CARRIER_RATE_SUCCESS: 'Successfully updated carrier rate',
    UPDATE_CARRIER_RATE_ERROR: 'Error updating carrier rate',
    UPDATE_CARRIER_RATE_VALIDATION_ERROR: 'Form validation error when updating carrier rate',
    UPDATE_CARRIER_RATE_SEQUENCE_SUCCESS:
      'Successfully updated routing guide carrier rate sequence',
    UPDATE_CARRIER_RATE_SEQUENCE_ERROR: 'Error updating routing guide carrier rate sequence',
    DEACTIVATE_CARRIER_LANE_RATE_SUCCESS: 'Successfully deactivated carrier lane rate',
    DEACTIVATE_CARRIER_LANE_RATE_ERROR: 'Error deactivating carrier lane rate',
    UPDATE_ROUTING_GUIDE_LANE_MANAGER: 'Successfully updated routing guide lane manager',
    UPDATE_ROUTING_GUIDE_LANE_MANAGER_ERROR: 'Error updating routing guide lane manager',
  },
  'Contract Rates Tool': {
    GENERATE_CONTRACT_RATE_SUCCESS: 'Generated a contract rate',
    GENERATE_CONTRACT_RATE_ERROR: 'Errored generating a contract rate',
    RUNTIME_ERROR: 'Errored on runtime',
    ADJUST_CONTRACT_RATE_VALUE: 'Adjust contract rate volume weighted average value',
    OPEN_RATE_FORECAST_INFO_MODAL: 'Open band chart rate forecast info modal',
    TOGGLE_BAND_CHART_VOLATILITY_SWITCH: 'Toggle band chart volatility switch',
    BAND_CHART_TOOLTIP: 'Tooltip shown in band chart',
    BAR_CHART_MONTH_CONTEXT: 'Bar chart month context hover',
    TOGGLE_PROJECTED_MARGIN_MONTHLY_OR_PER_LOAD:
      'Toggle projected margin view to monthly or per load',
    TOGGLE_SPOT_CONTRACT_SWITCH: 'Toggle spot-contract switch',
  },
  'Rates Tool': {
    GENERATE_RATE_SUCCESS: 'Generated a rate',
    GENERATE_RATE_ERROR: 'Errored generating a rate',
    RUNTIME_ERROR: 'Errored on runtime',
    CREATE_QUOTE_SUCCESS: 'Created a quote',
    CREATE_QUOTE_ERROR: 'Errored creating a quote',
    ADJUST_RATE_VALUE: 'Adjust rate value',
    OPEN_CONTROL_PANEL: 'Open Spot Rates Control Panel',
  },
  'Spot Rates Control Panel': {
    UPDATE_COST_SETTING_SUCCESS: 'Successfully updated cost setting',
    UPDATE_COST_SETTING_ERROR: 'Error updating cost setting',
    UPDATE_MARGIN_SETTING_SUCCESS: 'Successfully updated margin setting',
    UPDATE_MARGIN_SETTING_ERROR: 'Error updated margin setting',
    RUNTIME_ERROR: 'Errored on runtime',
    CREATE_ADJUSTMENT_RULE_SUCCESS: 'Successfully created adjustment rule',
    CREATE_ADJUSTMENT_RULE_ERROR: 'Error creating adjustment rule',
    UPDATE_ADJUSTMENT_RULE_SUCCESS: 'Successfully updated adjustment rule',
    UPDATE_ADJUSTMENT_RULE_ERROR: 'Error updating adjustment rule',
    ADJUSTMENT_RULE_FORM_VALIDATION_ERROR:
      'Form validation error when creating/updating adjustment rule',
    DEACTIVATE_ADJUSTMENT_RULE_SUCCESS: 'Successfully deactivating adjustment rule',
    DEACTIVATE_ADJUSTMENT_RULE_ERROR: 'Error deactivating adjustment rule',
  },
  'Quotes Tool': {
    MARK_AS_WON_ERROR: 'Error marking quote as won',
    MARK_AS_WON_SUCCESS: 'Marked quote as won',
    RUNTIME_ERROR: 'Errored on runtime',
    UPDATE_QUOTE_SUCCESS: 'Updated quote',
    UPDATE_QUOTE_ERROR: 'Error updating quote',
  },
  Customer: {
    CREATE_CUSTOMER_SUCCESS: 'Successfully created customer',
    CREATE_CUSTOMER_ERROR: 'Error creating customer',
    UPDATE_CUSTOMER_SUCCESS: 'Successfully updated customer',
    UPDATE_CUSTOMER_ERROR: 'Error updating customer',
    DEACTIVATE_CUSTOMER_SUCCESS: 'Successfully deactivated customer',
    DEACTIVATE_CUSTOMER_ERROR: 'Error deactivating customer',
    REACTIVATE_CUSTOMER_SUCCESS: 'Successfully reactivated customer',
    REACTIVATE_CUSTOMER_ERROR: 'Error reactivating customer',
  },
  'Contract RFPs': {
    CREATE_CONTRACT_RFP_SUCCESS: 'Successfully created contract RFP',
    CREATE_CONTRACT_RFP_ERROR: 'Error creating contract RFP',
    RUNTIME_ERROR: 'Errored on runtime',
    UPDATE_CONTRACT_RFP_SUCCESS: 'Successfully updated contract RFP',
    UPDATE_CONTRACT_RFP_ERROR: 'Error updating contract RFP',
    CREATE_NEW_ROUND_SUCCESS: 'Successfully created new round',
    CREATE_NEW_ROUND_ERROR: 'Error creating new round',
    RESTART_ROUND_SUCCESS: 'Successfully restarted round',
    RESTART_ROUND_ERROR: 'Error restarting round',
    ARCHIVE_CONTRACT_RFP_SUCCESS: 'Successfully archived contract RFP',
    ARCHIVE_CONTRACT_RFP_ERROR: 'Error archiving contract RFP',
    DOWNLOAD_RFP_SUCCESS: 'Successfully downloaded RFP',
    UPDATE_LANE_RATE_SUCCESS: 'Successfully updated lane',
    UPDATE_LANE_RATE_ERROR: 'Error updating lane',
    UPDATE_LANE_INFO_SUCCESS: 'Successfully updated lane info',
    UPDATE_LANE_INFO_ERROR: 'Error updating lane info',
    CREATE_LANE_SUCCESS: 'Successfully created lane',
    CREATE_LANE_ERROR: 'Error creating lane',
    DELETE_LANE_SUCCESS: 'Successfully deleted lane',
    DELETE_LANE_ERROR: 'Error deleting lane',
    UPDATE_STATE_RFP_SUCCESS: 'Successfully updated state RFP',
    UPDATE_STATE_RFP_ERROR: 'Error updating state RFP',
    ATTACH_FILE_SUCCESS: 'Successfully attached file',
    ATTACH_FILE_ERROR: 'Error attaching file',
    DELETE_FILE_SUCCESS: 'Successfully deleted file',
    DELETE_FILE_ERROR: 'Error deleting file',
    DOWNLOAD_FILE_SUCCESS: 'Successfully downloaded file',
    ADD_LINK_SUCCESS: 'Successfully added link',
    ADD_LINK_ERROR: 'Error adding link',
    EDIT_LINK_SUCCESS: 'Successfully edited link',
    EDIT_LINK_ERROR: 'Error editing link',
    DELETE_LINK_SUCCESS: 'Successfully deleted link',
    DELETE_LINK_ERROR: 'Error deleting link',
    COPY_PREVIOUS_ROUND_SUCCESS: 'Successfully copied previous round',
    COPY_PREVIOUS_ROUND_ERROR: 'Error copying previous round',
    IMPORT_CSV_SUCCESS: 'Successfully imported CSV',
    IMPORT_CSV_ERROR: 'Error importing CSV',
    REORDER_RFP_SUCCESS: 'Successfully reordered RFP',
    REORDER_RFP_ERROR: 'Error reordering RFP',
  },
} as const

export type TSolutionsConsoleEvents = typeof SOLUTIONS_CONSOLE_EVENTS
