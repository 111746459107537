import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LaneMapCard } from '#components/LaneMapCard'
import { StopListModal } from '#components/StopListModal'
import { PROTECTED_PATHS } from '#constants/paths'
import { DEFAULT_MARGIN_PERCENTAGE } from '#constants/rates'
import { useAnalytics } from '#hooks/useAnalytics'
import { IFetchRatesInput, ICreateQuoteInput } from '#types/graphqlTypes'
import { IEstimate } from '#types/rates'
import { getStopsWithRelativeSequence } from '#utils/getStopsWithRelativeSequence'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import { useToastContext } from 'dpl/components/Toast'
import { Box, Grid } from 'dpl/core'
import SettingsIcon from 'dpl/icons/build/SettingsIcon'
import { PageTemplate } from 'dpl/templates'
import { useFlagsContext } from 'flags'
import {
  LaneInformationHeader,
  LoadingLaneInformationState,
  EmptyLaneInformationState,
  LaneInformationForm,
  SaveQuoteModal,
  SpotQuoteCard,
} from './components'
import { DetailedData } from './demo-enhancements/DetailedData'
import { LaneScorecard } from './demo-enhancements/LaneScorecard'
import { useCreateQuoteMutation } from './graphql/CreateQuoteMutation'
import { useFetchRatesMutation } from './graphql/FetchRatesMutation'
import { getInitialRateEstimates } from './utils'

export function SpotRatesTool() {
  const navigate = useNavigate()
  const { userPermissions } = usePermissionsContext()
  const { isFlagEnabled } = useFlagsContext()
  const isControlPanelEnabled = isFlagEnabled('spot_rates_control_panel')
  const isDemoFlagEnabled = isFlagEnabled('rates_demo_enhancements')
  const hasQuotesEnabled = userPermissions['quotes.read_app']
  const hasDemoEnhancementsEnabled =
    userPermissions['demo.read_rates_tool_enhancements'] && isDemoFlagEnabled
  const { openToast } = useToastContext() || {}
  const { trackEvent } = useAnalytics()

  const [fetchRatesMutation, { data, loading: isFetchRatesMutationLoading }] =
    useFetchRatesMutation()
  const { fetchRates } = data || {}
  const { ratesResponse } = fetchRates || {}
  const { spotCostModel, stops = [], rateAdjustments } = ratesResponse || {}
  const { miles } = spotCostModel || {}
  const showEmptyState = !isFetchRatesMutationLoading && !data

  const [laneInfo, setLaneInfo] = useState<IFetchRatesInput | null>(null)

  const [isStopListModalOpen, setIsStopListModalOpen] = useState<boolean>(false)
  const [isSaveQuoteModalOpen, setIsSaveQuoteModalOpen] = useState(false)

  const [showQuoteSection, setShowQuoteSection] = useState<boolean>(false)
  const [isCarrierRateRulesOverridden, setIsCarrierRateRulesOverridden] = useState<boolean>(false)
  const [isMarginPremiumRulesOverridden, setIsMarginPremiumRulesOverridden] =
    useState<boolean>(false)
  const [isDoNotBidRulesOverridden, setIsDoNotBidRulesOverridden] = useState<boolean>(false)
  const [rateEstimate, setRateEstimate] = useState<IEstimate>({
    carrierRate: 0,
    marginPercentage: DEFAULT_MARGIN_PERCENTAGE,
    marginValue: 0,
  })

  const [
    createQuoteMutation,
    {
      data: createQuoteData,
      loading: isCreateQuoteMutationLoading,
      reset: resetCreateQuoteMutation,
    },
  ] = useCreateQuoteMutation()
  const { createQuote } = createQuoteData || {}
  const { organizationQuote } = createQuote || {}
  const { id: quoteId, status: quoteStatus } = organizationQuote || {}

  const onSubmitHandler = async (formData: IFetchRatesInput) => {
    try {
      setShowQuoteSection(false)
      resetCreateQuoteMutation()
      setLaneInfo(formData)
      const { data } = await fetchRatesMutation({
        variables: {
          input: formData,
        },
        onError: apolloErrors => {
          openToast({
            toastMessage: 'Something went wrong. Please try again.',
            toastType: 'alert',
          })
          trackEvent('Rates Tool', 'RUNTIME_ERROR', {
            apolloErrors,
          })
        },
      })
      const { fetchRates } = data || {}
      const { errors: mutationErrors, ratesResponse } = fetchRates || {}

      if (mutationErrors?.length) {
        const error = mutationErrors.map(({ message }) => message).join(', ')
        openToast({
          toastMessage: error,
          toastType: 'alert',
          multiLine: true,
        })
        trackEvent('Rates Tool', 'GENERATE_RATE_ERROR', {
          formData,
          error,
        })
      }

      if (ratesResponse) {
        trackEvent('Rates Tool', 'GENERATE_RATE_SUCCESS', {
          formData,
          ratesResponse,
        })
        const { rateAdjustments, spotCostModel } = ratesResponse || {}
        setRateEstimate(getInitialRateEstimates({ costModel: spotCostModel, rateAdjustments }))
        setShowQuoteSection(true)
      }
    } catch (error) {
      openToast({
        toastMessage: 'Something went wrong. Please try again.',
        toastType: 'alert',
      })
      trackEvent('Rates Tool', 'RUNTIME_ERROR', {
        error,
      })
    }
  }

  const stopsWithRelativeSequence = useMemo(() => {
    return getStopsWithRelativeSequence(stops)
  }, [stops])

  const toggleStopListModal = useCallback(() => {
    setIsStopListModalOpen(state => !state)
  }, [])

  const createQuoteHandler = async (formData: ICreateQuoteInput) => {
    try {
      const { data } = await createQuoteMutation({
        variables: {
          input: formData,
        },
        onError: apolloErrors => {
          openToast({
            toastMessage: 'Something went wrong. Please try again.',
            toastType: 'alert',
          })
          trackEvent('Rates Tool', 'RUNTIME_ERROR', {
            apolloErrors,
          })
        },
      })
      const { createQuote } = data || {}
      const { errors: mutationErrors, organizationQuote } = createQuote || {}

      if (mutationErrors?.length) {
        const error = mutationErrors.map(({ message }) => message).join(', ')
        openToast({
          toastMessage: error,
          toastType: 'alert',
          multiLine: true,
        })
        trackEvent('Rates Tool', 'CREATE_QUOTE_ERROR', {
          formData,
          error,
        })
      }

      if (organizationQuote) {
        trackEvent('Rates Tool', 'CREATE_QUOTE_SUCCESS', {
          formData,
          organizationQuote,
        })
        setIsSaveQuoteModalOpen(false)
      }
    } catch (error) {
      openToast({
        toastMessage: 'Something went wrong. Please try again.',
        toastType: 'alert',
      })
      trackEvent('Rates Tool', 'RUNTIME_ERROR', {
        error,
      })
    }
  }

  const pageBodyTitleProps = isControlPanelEnabled
    ? {
        actions: [
          {
            dataTest: 'control-panel-button',
            label: 'Control Panel',
            buttonProps: {
              startIcon: <SettingsIcon size='large' />,
            },
            onClick: () => {
              navigate(PROTECTED_PATHS.spotRatesControlPanel)
              trackEvent('Rates Tool', 'OPEN_CONTROL_PANEL')
            },
          },
        ],
      }
    : undefined

  return (
    <PageTemplate
      pageTitle='Spot Rates'
      dataTest='route-rates'
      PageBodyTitleProps={pageBodyTitleProps}>
      <Box display='flex' flexDirection='column'>
        <LaneInformationForm onSubmit={onSubmitHandler} />
        {showEmptyState && <EmptyLaneInformationState />}
        <div>
          {isFetchRatesMutationLoading && <LoadingLaneInformationState />}{' '}
          {showQuoteSection && ratesResponse && (
            <div data-test='rates-quote-result'>
              <LaneInformationHeader
                inputtedLaneInfo={laneInfo}
                laneInformation={ratesResponse}
                openStopListModal={toggleStopListModal}
              />
              <Grid container paddingTop={3} spacing={3}>
                <Grid item xs={12} md={7}>
                  <SpotQuoteCard
                    hasQuotesEnabled={hasQuotesEnabled}
                    isDoNotBidRulesOverridden={isDoNotBidRulesOverridden}
                    onCarrierRateRulesOverride={setIsCarrierRateRulesOverridden}
                    onDoNotBidRulesOverride={setIsDoNotBidRulesOverridden}
                    onMarginPremiumRulesOverride={setIsMarginPremiumRulesOverridden}
                    onSaveQuoteClick={() => setIsSaveQuoteModalOpen(true)}
                    quoteId={quoteId}
                    quoteStatus={quoteStatus}
                    rateAdjustments={rateAdjustments}
                    rateEstimate={rateEstimate}
                    setRateEstimate={setRateEstimate}
                    spotCostModel={spotCostModel}
                  />
                  {hasDemoEnhancementsEnabled && <DetailedData />}
                </Grid>
                <Grid item xs={12} md={5}>
                  <LaneMapCard stops={stops} miles={miles ?? 0} />
                  {hasDemoEnhancementsEnabled && <LaneScorecard stops={stops} />}
                </Grid>
              </Grid>
              <SaveQuoteModal
                open={isSaveQuoteModalOpen}
                onClose={() => setIsSaveQuoteModalOpen(false)}
                ratesResponse={ratesResponse}
                rateEstimate={rateEstimate}
                isLoading={isCreateQuoteMutationLoading}
                isAdjustmentRulesOverridden={
                  isCarrierRateRulesOverridden || isMarginPremiumRulesOverridden
                }
                isDoNotBidRulesOverridden={isDoNotBidRulesOverridden}
                onSubmit={createQuoteHandler}
              />
            </div>
          )}
        </div>
      </Box>
      <StopListModal
        open={isStopListModalOpen}
        onClose={toggleStopListModal}
        stops={stopsWithRelativeSequence}
      />
    </PageTemplate>
  )
}
