import moment from 'moment'
import { TODAY } from '../../../constants'
import { hasAdjustmentRuleStarted } from '../../utils/hasAdjustmentRuleStarted'
import { IAdjustmentRuleTimeframeStatus } from '../constants'

interface IGetAdjustmentRuleTimeframeStatusProps {
  startAt: Nullable<string>
  endAt?: Nullable<string>
  deprecatedAt?: Nullable<string>
}

export const getAdjustmentRuleTimeframeStatus = ({
  deprecatedAt,
  endAt,
  startAt,
}: IGetAdjustmentRuleTimeframeStatusProps) => {
  const hasStarted = hasAdjustmentRuleStarted({ startAt })

  if (deprecatedAt) return IAdjustmentRuleTimeframeStatus.deactivated
  if (!hasStarted) return IAdjustmentRuleTimeframeStatus.notActiveYet

  const hasEnded = moment(endAt).utc().isBefore(TODAY.utc(), 'day')

  if (!hasEnded) return IAdjustmentRuleTimeframeStatus.active

  return IAdjustmentRuleTimeframeStatus.expired
}
