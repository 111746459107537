import React from 'react'
import { Outlet, To, useSearchParams, Navigate } from 'react-router-dom'
import { useAuthContext, useAuthMemberSession } from '../hooks'

export interface IUnprotectedLayoutProps {
  /**
   * The location to redirect user to if they are already authenticated.
   * If there is a `next_route` param in the url, then the user will be redirected there instead.
   * @example '/home'
   */
  redirectTo?: To
}

/**
 * @description {B2B SaaS Authentication} Renders nothing, and has a check to see if an authenticated session exists.
 * This will confirm that a user is signed in. If an authenticated session exists, then the user will be
 * redirected to the route specified in the `next_route` search parameter or to the specified `redirectTo` prop.
 */
export function UnprotectedLayout({ redirectTo: redirectToProp }: IUnprotectedLayoutProps) {
  const { session } = useAuthMemberSession()
  const { signInRedirectTo } = useAuthContext()

  const [searchParams] = useSearchParams()
  const nextRouteParam = searchParams.get('next_route') ?? ''
  const redirectTo = nextRouteParam || redirectToProp || signInRedirectTo

  if (session && redirectTo) {
    return <Navigate to={redirectTo} />
  }

  return <Outlet />
}
