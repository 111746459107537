/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useState } from 'react'
import { makeStyles } from 'dpl/core/styles'
import ViewOffIcon from 'dpl/icons/build/ViewOffIcon'
import ViewOnIcon from 'dpl/icons/build/ViewOnIcon'
import { brandColors } from 'dpl/theme/colors'

const useMapboxViewStyles = makeStyles({
  container: {
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, .3)',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    top: 10,
    right: 10,
    bottom: 'auto',
    left: 'auto',
  },
  buttonStyle: {
    backgroundColor: '#f9f9f9',
    opacity: 0.95,
    transition: 'background-color 0.16s ease-out',
    cursor: 'pointer',
    border: 0,
    outline: 0,
    padding: 3,
    height: 26,
    width: 26,
    color: brandColors.black,
    '&:hover': {
      backgroundColor: brandColors.white,
      opacity: 1,
    },
  },
  iconStyle: {
    height: '100%',
    width: '100%',
  },
})

export interface IMapboxViewProps {
  onViewChange: (view: boolean) => void
}

export function ViewControl({ onViewChange }: IMapboxViewProps) {
  const [isViewEnabled, setViewEnabled] = useState<boolean>(false)

  const clickHandler = useCallback(() => {
    onViewChange(!isViewEnabled)
    setViewEnabled(prevState => !prevState)
  }, [onViewChange, isViewEnabled])

  const classes = useMapboxViewStyles()

  return (
    <div className={classes.container}>
      <div className={classes.buttonStyle} onClick={clickHandler}>
        {isViewEnabled ? (
          <ViewOnIcon className={classes.iconStyle} size='large' />
        ) : (
          <ViewOffIcon className={classes.iconStyle} size='large' />
        )}
      </div>
    </div>
  )
}
