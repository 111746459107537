import React, { memo, useCallback, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { IError, ISettingVersion } from '#types/graphqlTypes'
import { getApolloErrorMessages } from 'dpl/components/ApolloErrorMessages'
import { FormModal, IFormModalProps } from 'dpl/components/Modals'
import { useToastContext } from 'dpl/components/Toast'
import { Box, Typography } from 'dpl/core'
import { useFlagsContext } from 'flags'
import ControlledCheckbox from 'forms/components/ControlledCheckbox'
import ControlledPercentageInput from 'forms/components/ControlledPercentageInput'
import { FormIf } from 'forms/components/FormIf'
import { useAnalytics } from '../../../../hooks'
import { useUpdateMarginSettingsMutation } from './graphql/UpdateMarginSettingsMutation'
import { useMarginSettingsForm } from './hooks'
import { TMarginSettingsSchema } from './schema'
import { getCreateSpotRatesSettingVersionInput, getMarginSettingsSchema } from './utils'

export interface IMarginSettingsModalProps extends Pick<IFormModalProps, 'onClose'> {
  /**
   * The default margin percentage to be prefilled in the form
   */
  defaultMarginPremium?: ISettingVersion['defaultMarginPremium']
  /**
   * The max margin premium to be prefilled in the form
   */
  maxMarginPremium?: ISettingVersion['maxMarginPremium']
  /**
   * @default 'MarginSettingsModal'
   */
  dataTest?: string
}

export const MarginSettingsModal = memo<IMarginSettingsModalProps>(
  ({ dataTest = 'MarginSettingsModal', defaultMarginPremium, maxMarginPremium, onClose }) => {
    const { trackEvent } = useAnalytics()
    const { openToast } = useToastContext()
    const { isFlagEnabled } = useFlagsContext()
    const isAdjustmentRulesEnabled = isFlagEnabled('spot_rates_control_panel_rules')

    const [mutationErrors, setMutationErrors] = useState<IError[]>([])

    const { control, errors, handleSubmit } = useMarginSettingsForm({
      defaultValues: getMarginSettingsSchema({ defaultMarginPremium, maxMarginPremium }),
    })
    const {
      defaultMarginPremium: defaultMarginPremiumErrors,
      maxMarginPremium: maxMarginPremiumErrors,
    } = errors || {}
    const { message: defaultMarginPremiumErrorMessage } = defaultMarginPremiumErrors || {}
    const { message: maxMarginPremiumErrorMessage } = maxMarginPremiumErrors || {}

    const [updateMarginSettings, { error: updateMarginApolloErrors, loading }] =
      useUpdateMarginSettingsMutation({
        refetchQueries: ['SpotRatesSettings'],
      })

    const errorMessages = getApolloErrorMessages({
      apolloErrors: updateMarginApolloErrors,
      mutationErrors,
    })

    const submitHandler = useCallback<SubmitHandler<TMarginSettingsSchema>>(
      async formValues => {
        setMutationErrors([])
        const { data } = await updateMarginSettings({
          variables: {
            input: getCreateSpotRatesSettingVersionInput(formValues),
          },
        })
        const { createSpotRatesSettingVersion } = data || {}
        const { settingVersion, errors = [] } = createSpotRatesSettingVersion || {}
        const { id: settingVersionId } = settingVersion || {}

        if (errors.length) {
          trackEvent('Spot Rates Control Panel', 'UPDATE_MARGIN_SETTING_ERROR', {
            formValues,
            mutationErrors: errors,
          })
          setMutationErrors(errors)
          return
        }
        if (settingVersionId) {
          trackEvent('Spot Rates Control Panel', 'UPDATE_MARGIN_SETTING_SUCCESS', {
            formValues,
            settingVersion,
          })
          openToast({
            toastMessage: 'Margin settings successfully updated.',
          })
          onClose()
        }
      },
      [onClose, openToast, trackEvent, updateMarginSettings]
    )

    const actions: IFormModalProps['actions'] = [
      {
        label: 'Cancel',
        action: onClose,
        disabled: loading,
        dataTest: `${dataTest}-cancel`,
        ButtonProps: {
          variant: 'text',
        },
      },
      {
        label: 'Save',
        disabled: loading,
        dataTest: `${dataTest}-save`,
        action: handleSubmit(submitHandler),
      },
    ]
    return (
      <FormModal
        open
        actions={actions}
        dataTest={dataTest}
        onClose={onClose}
        errors={errorMessages}
        title='Default Margin Base'
        titleVariant='h4'
        maxWidth='xs'>
        <Box display='flex' flexDirection='column' gap={1}>
          <ControlledPercentageInput
            control={control}
            name='defaultMarginPremium'
            PercentageInputProps={{
              label: '% Amount',
              variant: 'filled',
              decimalScale: 0,
              dataTest: `${dataTest}-defaultMarginPremium`,
              error: !!defaultMarginPremiumErrorMessage,
              helperText: defaultMarginPremiumErrorMessage,
            }}
          />
          {isAdjustmentRulesEnabled && (
            <div>
              <ControlledCheckbox
                control={control}
                name='isSettingMaxMarginPremium'
                CheckboxProps={{
                  label: 'Set max margin limit',
                  dataTest: `${dataTest}-isSettingMaxMarginLimit`,
                }}
              />
              <Box my={1} ml={3}>
                <Typography variant='body2' component='p'>
                  When multiple rules are applied, set the max amount of combined margin allowed.
                </Typography>
              </Box>
              <FormIf control={control} name='isSettingMaxMarginPremium' when>
                <ControlledPercentageInput
                  control={control}
                  name='maxMarginPremium'
                  PercentageInputProps={{
                    label: '% Amount',
                    variant: 'filled',
                    decimalScale: 0,
                    dataTest: `${dataTest}-maxMarginPremium`,
                    error: !!maxMarginPremiumErrorMessage,
                    helperText: maxMarginPremiumErrorMessage,
                  }}
                />
              </FormIf>
            </div>
          )}
        </Box>
      </FormModal>
    )
  }
)
