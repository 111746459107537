import { DISPLAY } from '../constants/display'

interface ICapitalizeProps {
  str?: string
  limit?: number
  /**
   * @default false
   */
  all?: boolean
}

export const capitalize = ({ all = false, limit, str }: ICapitalizeProps): string => {
  if (!str) return DISPLAY.empty
  if (all) return str.toUpperCase()
  const allWords: string[] = str.toLowerCase().split(' ')
  const capitalizedWords = allWords.map(
    word => `${word.charAt(0).toUpperCase()}${word.slice(1, limit || word.length)}`
  )
  return capitalizedWords.join(' ')
}
