import * as yup from 'yup'
import { IOrganizationCarrierRelationshipState } from '../../../types/graphqlTypes'

export const AutocompleteOrganizationCarrierSchema = yup.object({
  id: yup.string(),
  carrier: yup.object({
    id: yup.string(),
    dbaName: yup.string().nullable(),
    dotNumber: yup.number(),
    legalName: yup.string().nullable(),
    mcNumber: yup.number().nullable(),
  }),
  state: yup.mixed<IOrganizationCarrierRelationshipState>(),
})

export type TAutocompleteOrganizationCarrierSchema = yup.InferType<
  typeof AutocompleteOrganizationCarrierSchema
>
