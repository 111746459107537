import React, { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSpotRatesControlPanelContext } from '#routes/spot-rates-control-panel/context'
import { IAdjustmentTypeEnum } from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import { ITabsProps, Tabs } from 'dpl/components/Tabs'
import { Box, Button, capitalize, makeStyles, Typography } from 'dpl/core'
import AddIcon from 'dpl/icons/build/AddIcon'
import { QueryParamProvider } from 'dpl/providers'
import { AdjustmentRulesTab, IAdjustmentRulesTabProps } from './components/AdjustmentRulesTab'
import { useSpotRatesAdjustmentRulesLazyQuery } from './graphql/SpotRatesAdjustmentRules'
import { getSpotRatesAdjustmentRulesQueryVariables } from './utils/getSpotRatesAdjustmentRulesQueryVariables'

const TAB_IDS = ['cost', 'margin']

const useAdjustmentRulesSectionStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: theme.spacing(1.5),
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}))

export function AdjustmentRulesSection() {
  const { userPermissions } = usePermissionsContext()
  const canUpdateRules = userPermissions['rates_tool.update_spot_adjustment_rules']

  const classes = useAdjustmentRulesSectionStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = searchParams.get('adjustmentType') || TAB_IDS[0]
  const ruleTypeFilter = searchParams.get('ruleType')

  const { openAdjustmentRuleForm, spotRatesSettings } = useSpotRatesControlPanelContext()
  const { loading } = spotRatesSettings || {}

  const [fetchCostAdjustmentRules, { data: costData, error: costError, loading: costLoading }] =
    useSpotRatesAdjustmentRulesLazyQuery()
  const { spotRatesAdjustmentRuleVersions: costRuleVersions } = costData || {}
  const { nodes: costAdjustmentRules, totalCount: costCount } = costRuleVersions || {}

  const [
    fetchMarginAdjustmentRules,
    { data: marginData, error: marginError, loading: marginLoading },
  ] = useSpotRatesAdjustmentRulesLazyQuery()
  const { spotRatesAdjustmentRuleVersions: marginRuleVersions } = marginData || {}
  const { nodes: marginAdjustmentRules, totalCount: marginCount } = marginRuleVersions || {}

  const tabListConfig = TAB_IDS.map(tabId => ({
    tabId,
    label: capitalize(tabId),
    count: tabId === 'cost' ? costCount : marginCount,
  }))

  const addAdjustmentRuleHandler = useCallback(() => {
    openAdjustmentRuleForm()
  }, [openAdjustmentRuleForm])

  const changeTabHandler: ITabsProps['onChange'] = useCallback(
    newActiveTabId => {
      setSearchParams(prev => {
        prev.set('adjustmentType', newActiveTabId)
        return prev
      })
    },
    [setSearchParams]
  )

  const filterChangeHandler = useCallback<
    NonNullable<IAdjustmentRulesTabProps['onFilterOrSortChange']>
  >(
    params => {
      const variables = getSpotRatesAdjustmentRulesQueryVariables(params)
      fetchCostAdjustmentRules({
        variables: { ...variables, adjustmentType: IAdjustmentTypeEnum.cost },
      })
      fetchMarginAdjustmentRules({
        variables: { ...variables, adjustmentType: IAdjustmentTypeEnum.margin },
      })
    },
    [fetchCostAdjustmentRules, fetchMarginAdjustmentRules]
  )

  return (
    <QueryParamProvider>
      <Box marginTop={6}>
        <div className={classes.header}>
          <Typography variant='h4'>Adjustment Rules</Typography>
          {canUpdateRules && (
            <Button
              data-test='AddAdjustmentRuleButton'
              startIcon={<AddIcon size='large' />}
              size='small'
              disabled={loading}
              onClick={addAdjustmentRuleHandler}>
              Add Adjustment
            </Button>
          )}
        </div>
        <Box marginTop={1.5}>
          <Tabs activeTabId={activeTab} onChange={changeTabHandler} tabList={tabListConfig}>
            <AdjustmentRulesTab
              adjustmentType={activeTab === 'cost' ? 'cost' : 'margin'}
              onFilterOrSortChange={filterChangeHandler}
              rules={activeTab === 'cost' ? costAdjustmentRules : marginAdjustmentRules}
              ruleType={ruleTypeFilter}
              loading={activeTab === 'cost' ? costLoading : marginLoading}
              error={activeTab === 'cost' ? costError : marginError}
            />
          </Tabs>
        </Box>
      </Box>
    </QueryParamProvider>
  )
}
