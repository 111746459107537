import { REQUIRED_MESSAGE } from 'forms/utils/validationMessages'
import * as yup from 'yup'

export const CustomerFormSchema = yup.object({
  name: yup.string().required(REQUIRED_MESSAGE),
  externalId: yup
    .string()
    .transform(value => value.trim()) // Trim the value before validation
    .required(REQUIRED_MESSAGE)
    .matches(/^\S*$/, 'This field cannot contain spaces'),
})

export type TCustomerFormSchema = yup.InferType<typeof CustomerFormSchema>
