import { ICostModel } from '#types/costModel'
import { DISPLAY } from 'dpl/constants'
import get from 'lodash/get'
import { formatPercentileLabel } from './formatPercentileLabel'

export interface IGetCarrierRatePercentileLabelProps {
  baseCostPercentile: number
  costModel: Nullable<ICostModel>
  defaultCostPercentile: number
  hasAppliedRules: boolean
  isOutsideOfCostLimits: boolean
  isSuggestedOverridden: boolean
  rulesCumulativeAdditivePercentage: number
  sliderValue: number
  suggestedCostPercentile: number
  suggestedCostShiftPercentage: number
}

/**
 * Returns the carrier rate percentile label based on the slider value or suggested rate
 * If the suggested rate has not been overwrote, return the suggested cost percentile and suggested cost shift percentage
 * If the suggested rate has been overwrote, return the percentile from the slider
 *
 * @returns {string} The carrier rate percentile label
 *
 */
export const getCarrierRatePercentileLabel = ({
  baseCostPercentile,
  costModel,
  defaultCostPercentile,
  hasAppliedRules,
  isOutsideOfCostLimits,
  isSuggestedOverridden,
  rulesCumulativeAdditivePercentage,
  sliderValue,
  suggestedCostPercentile,
  suggestedCostShiftPercentage,
}: IGetCarrierRatePercentileLabelProps): string => {
  if (!costModel) return ''

  /**
   * If the suggested rate has not been overwrote and the cost is outside of the limits,
   * return the base cost percentile and the cumulative additive percentage
   */
  if (!isSuggestedOverridden && isOutsideOfCostLimits) {
    if (!baseCostPercentile) {
      return DISPLAY.empty
    }

    return formatPercentileLabel({
      isDefault: hasAppliedRules ? false : baseCostPercentile === defaultCostPercentile,
      percentile: baseCostPercentile,
      shiftPercentage: rulesCumulativeAdditivePercentage,
    })
  }

  /**
   * If the suggested rate has not been overwrote,
   * return the suggested cost percentile and suggested cost shift percentage
   */
  if (!isSuggestedOverridden) {
    if (!suggestedCostPercentile) {
      return DISPLAY.empty
    }

    return formatPercentileLabel({
      isDefault: hasAppliedRules ? false : suggestedCostPercentile === defaultCostPercentile,
      showCustomOrDefaultLabel: hasAppliedRules
        ? false
        : suggestedCostPercentile === defaultCostPercentile,
      percentile: suggestedCostPercentile,
      shiftPercentage: suggestedCostShiftPercentage,
    })
  }

  /**
   * If the suggested rate has been overwrote, return the percentile from the slider
   */

  // Get the current percentile key based on the slider value
  const sliderPercentileKey = Object.keys(costModel).find(
    key => get(costModel, key, null) === sliderValue
  )

  // If there is no key, return an empty string
  if (!sliderPercentileKey) {
    return DISPLAY.empty
  }

  const sliderPercentile = Number(sliderPercentileKey.replace('cost', ''))

  // Return the percentile label
  return formatPercentileLabel({
    isDefault: false, // If the suggested has been overridden, we don't consider it default ever.
    percentile: sliderPercentile,
  })
}
