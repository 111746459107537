import { ADJUSTMENT_TYPES } from '../../types'

export const ADJUSTMENT_TYPE_OPTIONS = [
  {
    value: ADJUSTMENT_TYPES.cost,
    label: 'Cost',
  },
  {
    value: ADJUSTMENT_TYPES.margin,
    label: 'Margin',
  },
]

/**
 * FORM NAMES
 */
export const ADJUSTMENT_TYPE_NAME = 'rateStrategy.adjustmentType'
export const COST_RULE_TYPE_NAME = 'rateStrategy.costRuleType'
export const COST_BASE_PERCENTILE_NAME = 'rateStrategy.costBasePercentile'
export const COST_ADDITIVE_SHIFT_NAME = 'rateStrategy.costAdditiveShiftPercentage'
export const MARGIN_RULE_TYPE_NAME = 'rateStrategy.marginRuleType'
export const MARGIN_BASE_PERCENTAGE_NAME = 'rateStrategy.marginBasePercentage'
export const MARGIN_ADDITIVE_PERCENTAGE_NAME = 'rateStrategy.marginAdditivePercentage'
