import { IRateStrategyAccordionStateEnum } from '../../../types/rates'

export interface IGetInitialStateArgs {
  hasAppliedRules: boolean
  isReadOnly: boolean
}

export const getInitialState = ({
  hasAppliedRules,
  isReadOnly,
}: IGetInitialStateArgs): IRateStrategyAccordionStateEnum => {
  if (isReadOnly) {
    return hasAppliedRules
      ? IRateStrategyAccordionStateEnum.useRules
      : IRateStrategyAccordionStateEnum.defaultReadOnly
  }
  if (hasAppliedRules) {
    return IRateStrategyAccordionStateEnum.useRules
  }
  return IRateStrategyAccordionStateEnum.default
}
