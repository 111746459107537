import TrimbleMaps from '@trimblemaps/trimblemaps-js'
import { IMapLane, IMapPosition, IMapStopProps, IMapRouteGroup } from '../types'
import { getMapFitBounds } from './getMapFitBounds'

const getTrimbleLngLatBounds = (
  [neLon, neLat]: [number, number],
  [swLon, swLat]: [number, number]
): TrimbleMaps.LngLatBounds => {
  const ne = new TrimbleMaps.LngLat(neLon, neLat)
  const sw = new TrimbleMaps.LngLat(swLon, swLat)
  return new TrimbleMaps.LngLatBounds(sw, ne)
}

/**
 * Returns bounds for a given area
 * @example
 * ```
 * getTrimbleLngLatBounds([ ... ]) // { sw: -72.775, ne: 41.748 }
 * ```
 */
export const getTrimbleFitBounds = (
  mapLanes: IMapLane[] = [],
  mapPositions: IMapPosition[] = [],
  mapStops: IMapStopProps[] = [],
  mapLines: IMapRouteGroup[] = []
): TrimbleMaps.LngLatBounds => {
  const [northEast, southWest] = getMapFitBounds(mapLanes, mapPositions, mapStops, mapLines)
  return getTrimbleLngLatBounds(northEast, southWest)
}
