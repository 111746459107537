import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { IToggleButtonGroupProps, ToggleButtonGroup } from 'dpl/components/ToggleButtonGroup'

type IBaseControllerProps = ControllerProps<React.ComponentType<IToggleButtonGroupProps>>

export interface IControlledToggleButtonGroupProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  ToggleButtonGroupProps: Omit<IToggleButtonGroupProps, 'onChange' | 'value'>
}

export const ControlledToggleButtonGroup = memo<IControlledToggleButtonGroupProps>(
  ({ ToggleButtonGroupProps, control, defaultValue, name }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          const { options = [], ...rest } = ToggleButtonGroupProps || {}
          const changeHandler: IToggleButtonGroupProps['onChange'] = (_, value) => {
            onChange(value)
          }
          return (
            <ToggleButtonGroup options={options} {...rest} onChange={changeHandler} value={value} />
          )
        }}
      />
    )
  }
)
