import { useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CostSettingsFormSchema, ICostSettingsFormSchema } from '../schema'

interface IUseCostSettingsFormProps
  extends Omit<UseFormOptions<ICostSettingsFormSchema>, 'resolver'> {}

export const useCostSettingsForm = ({
  defaultValues = {},
  ...props
}: IUseCostSettingsFormProps) => {
  return useForm<ICostSettingsFormSchema>({
    resolver: yupResolver(CostSettingsFormSchema),
    defaultValues: CostSettingsFormSchema.cast(defaultValues),
    ...props,
  })
}
