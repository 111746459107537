import React from 'react'
import { makeStyles, Skeleton } from 'dpl/core'

const useLoadingContractRatesToolResultsStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(3),
    gridTemplateAreas: `"header header"
    "left right"`,
    '& span:first-child': {
      gridArea: 'header',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '7fr 5fr',
      gap: theme.spacing(3),
    },
  },
  skeleton: {
    borderRadius: theme.spacing(0.5),
  },
}))

export function LoadingContractRatesToolResults() {
  const classes = useLoadingContractRatesToolResultsStyles()
  return (
    <div className={classes.root}>
      <Skeleton variant='rectangular' height={70} className={classes.skeleton} />
      <Skeleton component='div' variant='rectangular' height={400} className={classes.skeleton} />
      <Skeleton variant='rectangular' height={400} className={classes.skeleton} />
    </div>
  )
}
