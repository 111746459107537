import React, { memo, useEffect, useMemo } from 'react'
import DataGrid from 'dpl/components/DataGrid'
import { GridActionsColDef as MuiGridActionsColDef } from 'dpl/core'
import { useCarrierRatesReorderContext } from '../../context'
import {
  ROUTING_GUIDE_CARRIER_AUTOSIZE_OPTIONS,
  ROUTING_GUIDE_CARRIER_COLUMNS,
  ROUTING_GUIDE_CARRIER_INITIAL_STATE,
  ROUTING_GUIDE_CARRIER_SEQUENCE_COLUMN,
} from './constants'
import { IRoutingGuideCarrierDataGrid } from './types'
import { getRoutingGuideCarrierDataGridRows } from './utils'

type TRoutingGuideCarrierDataGridPickedProps = 'loading' | 'rows' | 'rowReordering'
export interface IRoutingGuideCarrierDataGridProps
  extends Pick<IRoutingGuideCarrierDataGrid, TRoutingGuideCarrierDataGridPickedProps> {
  actionColumn: MuiGridActionsColDef
}

export const RoutingGuideCarrierDataGrid = memo<IRoutingGuideCarrierDataGridProps>(
  ({ actionColumn, loading, rowReordering, rows: rowsProp }) => {
    const { isReordering, reorderLoading } = useCarrierRatesReorderContext()

    const { dataGridApiRef: apiRef } = useCarrierRatesReorderContext()
    const rows = useMemo(
      () => getRoutingGuideCarrierDataGridRows(rowsProp),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rowsProp, isReordering]
    )
    const columns = useMemo(() => {
      if (isReordering) {
        return ROUTING_GUIDE_CARRIER_COLUMNS
      }
      return [
        ...ROUTING_GUIDE_CARRIER_SEQUENCE_COLUMN,
        ...ROUTING_GUIDE_CARRIER_COLUMNS,
        actionColumn,
      ]
    }, [actionColumn, isReordering])

    useEffect(() => {
      if (loading) return
      if (!rows) return
      if (rows.length === 0) return
      apiRef.current.autosizeColumns(ROUTING_GUIDE_CARRIER_AUTOSIZE_OPTIONS)
    }, [apiRef, loading, rows, isReordering])

    return (
      <DataGrid
        apiRef={apiRef}
        autosizeOnMount
        autosizeOptions={ROUTING_GUIDE_CARRIER_AUTOSIZE_OPTIONS}
        dataTest='routing-guide-carrier-data-grid'
        rowReordering={rowReordering}
        rows={rows}
        columns={columns}
        loading={loading || reorderLoading}
        hideFooter
        initialState={ROUTING_GUIDE_CARRIER_INITIAL_STATE}
      />
    )
  }
)
