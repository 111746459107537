import React from 'react'
import { IModalAction, IModalBaseProps } from 'dpl/components/Modals'
import { ConfirmationModal } from 'dpl/components/Modals/ConfirmationModal'
import Typography from 'dpl/core/Typography'
import { DefaultRateSettingInfo } from '../DefaultRateSettingInfo/DefaultRateSettingInfo'

interface IDefaultSettingsModal extends Pick<IModalBaseProps, 'onClose' | 'open'> {}

export function DefaultSettingsModal({ onClose, open }: IDefaultSettingsModal) {
  const actions: IModalAction[] = [
    {
      label: 'Close',
      action: onClose,
      ButtonProps: {
        variant: 'contained',
      },
    },
  ]

  return (
    <ConfirmationModal onClose={onClose} title='Default Settings' open={open} actions={actions}>
      <Typography variant='body1'>
        Rates will be generated using these default cost and margin settings, and can be adjusted
        after import.
      </Typography>
      <DefaultRateSettingInfo />
    </ConfirmationModal>
  )
}
