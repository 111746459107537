import React, { useCallback } from 'react'
import {
  Checkbox,
  DataGridRadioGroup,
  ICheckboxProps,
  IDataGridFilterOption,
  IDataGridRadioGroupProps,
  ITextFieldProps,
  TextField,
} from 'dpl'
import { makeStyles } from 'dpl/core'
import { MinusIcon } from 'dpl/icons'
import { IDataGridFilterRangeWithOptions } from '../DataGridRangeWithOptionsInput'

export interface IRangeWithOptionsInputProps {
  /**
   * Callback function that is called when the filter is changed
   */
  onChange: (value: IDataGridFilterRangeWithOptions) => void
  value: IDataGridFilterRangeWithOptions
  options?: IDataGridFilterOption[]
  blankLabel?: string
  minValueLabel: string
  maxValueLabel: string
}

const useStyles = makeStyles(theme => ({
  range: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    margin: theme.spacing(2, 0, 1.5, 0),
  },
}))

export function RangeWithOptionsInput({
  blankLabel,
  maxValueLabel,
  minValueLabel,
  onChange,
  options,
  value: valueProp,
}: IRangeWithOptionsInputProps) {
  const classes = useStyles()

  const { blank, max, min, value } = valueProp || {}

  const radioChangeHandler: IDataGridRadioGroupProps['onChange'] = useCallback(
    ({ value: newValue }) => {
      onChange({
        ...valueProp,
        value: newValue,
      })
    },
    [valueProp]
  )

  const minChangeHandler: ITextFieldProps['onChange'] = useCallback(
    e => {
      const {
        target: { value = '' },
      } = e || {}
      onChange({
        ...valueProp,
        min: parseInt(value, 10),
      })
    },
    [valueProp]
  )

  const maxChangeHandler: ITextFieldProps['onChange'] = useCallback(
    e => {
      const {
        target: { value = '' },
      } = e || {}
      onChange({
        ...valueProp,
        max: parseInt(value, 10),
      })
    },
    [valueProp]
  )

  const blankChangeHandler: ICheckboxProps['onChange'] = useCallback(
    e => {
      const {
        target: { checked },
      } = e || {}
      onChange({
        ...valueProp,
        blank: checked,
      })
    },
    [valueProp]
  )

  return (
    <div>
      {options && (
        <DataGridRadioGroup
          variant='menu'
          name='custom'
          onChange={radioChangeHandler}
          value={value || ''}
          options={options}
        />
      )}
      <div className={classes.range}>
        <TextField
          size='small'
          value={min}
          onChange={minChangeHandler}
          type='number'
          placeholder={minValueLabel}
        />
        <MinusIcon size='large' />
        <TextField
          size='small'
          value={max}
          onChange={maxChangeHandler}
          type='number'
          placeholder={maxValueLabel}
        />
      </div>
      {blankLabel && (
        <Checkbox checked={blank || false} onChange={blankChangeHandler} label={blankLabel} />
      )}
    </div>
  )
}
