import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultValues, UnpackNestedValue } from 'react-hook-form'
import {
  PasswordStrengthValidation,
  getPasswordValidationState,
} from 'auth/common/components/PasswordStrengthValidation'
import { Button, Grid, Stack } from 'dpl/core'
import { ControlledPasswordField } from 'forms/components/ControlledPasswordField'
import { ControlledTextField } from 'forms/components/ControlledTextField'
import { useOnboardForm } from './hooks'
import { TOnboardFormSchema } from './schema'

export interface IOnboardFormProps {
  /**
   * Callback fired to submit password reset.
   * Returns true if successful. Else, returns api errors.
   */
  onSubmit: (data: UnpackNestedValue<TOnboardFormSchema>) => Promise<void> | void
  /**
   * Callback fired on password change.
   * This will be used to check the password strength as user inputs password.
   */
  onPasswordChange: ChangeEventHandler<HTMLInputElement>
  /**
   * Strength of password as determined by Stytch, from 0 to 4
   * @see https://stytch.com/docs/guides/passwords/strength-policy
   * @example 2
   */
  passwordStrengthScore?: number
  /**
   * If loading, submit button is disabled
   */
  isLoading?: boolean
  defaultValues?: Omit<DefaultValues<TOnboardFormSchema>, 'password'>
}

export function OnboardForm({
  defaultValues,
  isLoading,
  onPasswordChange,
  onSubmit,
  passwordStrengthScore,
}: IOnboardFormProps) {
  const { firstName, lastName } = defaultValues ?? {}
  const methods = useOnboardForm({
    defaultValues: {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      password: '',
    },
  })
  const { control, errors, handleSubmit, setValue, watch } = methods
  const { password: passwordValue } = watch()
  const { password: passwordErrors } = errors || {}

  const [showPasswordStrength, setShowPasswordStrength] = useState<boolean>(false)

  /**
   * <PasswordStrengthValidation /> is not displayed until the password field is focused
   */
  const passwordFieldFocusHandler = useCallback(() => {
    setShowPasswordStrength(true)
  }, [])

  const passwordValidationState = useMemo(() => {
    return getPasswordValidationState({
      errors: passwordErrors,
      score: passwordStrengthScore,
      value: passwordValue,
    })
  }, [passwordErrors, passwordValue, passwordStrengthScore])

  // Set firstName default value after it's been loaded.
  useEffect(() => {
    if (firstName) {
      setValue('firstName', firstName)
    }
  }, [firstName, setValue])

  // Set lastName default value after it's been loaded.
  useEffect(() => {
    if (lastName) {
      setValue('lastName', lastName)
    }
  }, [lastName, setValue])

  const submitHandler = handleSubmit(onSubmit)

  return (
    <form data-test='onboard-form' onSubmit={submitHandler}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            name='firstName'
            TextFieldProps={{
              id: 'first-name',
              dataTest: 'first-name-field',
              autoComplete: 'given-name',
              error: Boolean(errors.firstName?.message),
              helperText: errors.firstName?.message,
              label: 'First Name',
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <ControlledTextField
            control={control}
            name='lastName'
            TextFieldProps={{
              id: 'last-name',
              dataTest: 'last-name-field',
              autoComplete: 'family-name',
              error: Boolean(errors.lastName?.message),
              helperText: errors.lastName?.message,
              label: 'Last Name',
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={2}>
            <ControlledPasswordField
              control={control}
              name='password'
              PasswordFieldProps={{
                id: 'password',
                dataTest: 'password-field',
                autoComplete: 'password',
                error: Boolean(errors.password?.message),
                label: 'Password',
                placeholder: 'Enter password',
                onChange: onPasswordChange,
                onFocus: passwordFieldFocusHandler,
              }}
            />

            {showPasswordStrength && (
              <PasswordStrengthValidation
                score={passwordStrengthScore}
                validationState={passwordValidationState}
              />
            )}

            <Button fullWidth type='submit' data-test='submit-button' disabled={isLoading}>
              Continue
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  )
}
