import {
  AutocompleteLocationSchema,
  TAutocompleteLocationSchema,
} from '#components/ControlledAutocompleteLocation/schema/AutocompleteLocationSchema'
import { PillSelectSearchableOrganizationShipperSchema } from '#components/ControlledPillSelectSearchableOrganizationShipper/schema'
import { FORMATS } from 'dpl/constants/datetime'
import moment from 'moment-timezone'
import * as yup from 'yup'

const emptyStringToNull = (value: string, originalValue: string | boolean) => {
  if (originalValue === '' || originalValue === false) {
    return null
  }
  return value
}

function checkMinLessThanMax(this: yup.TestContext) {
  const { parent } = this
  const { max = null, min = null } = parent

  // avoid validation if there's no sufficient info
  if (min === null || max === null) return true

  return min <= max
}

function checkMaxPresent(this: yup.TestContext) {
  const { parent } = this
  const { max = null, min = null } = parent

  if (min === null) return true

  return !(Number.isInteger(min) && max === null)
}

function checkMinPresent(this: yup.TestContext) {
  const { parent } = this
  const { max = null, min = null } = parent

  if (max === null) return true

  return !(Number.isInteger(max) && min === null)
}

const multiplierByScale: Record<string, number> = {
  mins: 1,
  hours: 60,
  days: 24 * 60,
}

function checkCreationAfterAssignment(this: yup.TestContext) {
  const { parent } = this
  const { assignmentLeadTime, creationLeadTime } = parent
  const { amount: creationAmount, scale: creationScale } = creationLeadTime || {}
  const { amount: assignmentAmount, scale: assignmentScale } = assignmentLeadTime || {}

  if (!creationAmount || !assignmentAmount) {
    return true
  }

  return (
    creationAmount * multiplierByScale[creationScale] >=
    assignmentAmount * multiplierByScale[assignmentScale]
  )
}

function dropTrailerValidation(value: Nullable<boolean>, context: yup.TestContext) {
  const { parent } = context || {}
  const { dropTrailerDelivery, dropTrailerPickup } = parent
  return value ? dropTrailerDelivery || dropTrailerPickup : true
}

export const advancedInputsSchema = yup.object({
  leadTimeSwitch: yup.boolean().transform(emptyStringToNull).nullable(),
  creationLeadTime: yup
    .object({
      scale: yup.string(),
      amount: yup.number().transform(emptyStringToNull).nullable(),
    })
    .optional()
    .nullable(),
  assignmentLeadTime: yup
    .object({
      scale: yup.string(),
      amount: yup.number().transform(emptyStringToNull).nullable(),
    })
    .optional()
    .nullable()
    .test(
      'creation-after-assignment',
      'Assignment lead time cannot exceed creation lead time.',
      checkCreationAfterAssignment
    ),
  palletCount: yup
    .number()
    .integer()
    .lessThan(91, 'Pallet amount cannot exceed 90.')
    .transform(emptyStringToNull)
    .nullable(),
  palletSwitch: yup.boolean().transform(emptyStringToNull).nullable(),
  teamRequired: yup.boolean().transform(emptyStringToNull).nullable(),
  dropTrailerPickup: yup.boolean().transform(emptyStringToNull).nullable(),
  dropTrailerDelivery: yup.boolean().transform(emptyStringToNull).nullable(),
  dropTrailerSwitch: yup
    .boolean()
    .nullable()
    .test('drop-trailer', 'You must indicate where drop trailer will be.', dropTrailerValidation),
})

export interface AdvancedInputsSchema extends yup.InferType<typeof advancedInputsSchema> {
  // using interface instead of type generally gives nicer editor feedback
}

function valueNotNull(value: unknown) {
  return value !== null
}

function stopLocationNotNull(value: TAutocompleteLocationSchema | null, context: yup.TestContext) {
  const {
    createError,
    from,
    // @ts-ignore index is missing in yup.TestContext type
    options: { index },
  } = context || {}

  const stops = from?.find(ancestor => !!ancestor?.value?.stops)?.value?.stops // gets stops from ancestor tree
  const stopsLength = stops?.length ?? 2
  const isNull = !valueNotNull(value)

  if (!isNull) return true

  if (index === 0) {
    return createError({
      message: 'You must select an origin.',
    })
  }

  if (index === stopsLength - 1) {
    return createError({
      message: 'You must select a destination.',
    })
  }

  return createError({
    message: 'You must select a location.',
  })
}

function splitPickupBeforeSplitDelivery(this: yup.TestContext) {
  const { parent } = this
  const { deliveryDate, deliveryTime, pickupDate, pickupTime, stops = [] } = parent
  const { location: originLocation } = stops[0] ?? {}
  const { location: destinationLocation } = stops[stops.length - 1] ?? {}
  const { timezone: originTimezone = '' } = originLocation ?? {}
  const { timezone: destinationTimezone = '' } = destinationLocation ?? {}

  if (pickupDate && pickupTime && deliveryDate && deliveryTime) {
    const pickupDateString = moment(pickupDate).format(FORMATS.yearFirst)
    const pickupTimeString = moment(pickupTime)
      .tz(originTimezone)
      .format(FORMATS.militaryTimeFormat)
    const deliveryDateString = moment(deliveryDate).format(FORMATS.yearFirst)
    const deliveryTimeString = moment(deliveryTime)
      .tz(destinationTimezone)
      .format(FORMATS.militaryTimeFormat)

    const pickupAppt = moment.tz(`${pickupDateString} ${pickupTimeString}`, originTimezone)
    const deliveryAppt = moment.tz(
      `${deliveryDateString} ${deliveryTimeString}`,
      destinationTimezone
    )

    return pickupAppt.isBefore(deliveryAppt)
  }

  /**
   * Date are timezone-less, we can compare them directly.
   */
  if (pickupDate && deliveryDate) {
    return moment(pickupDate).isSameOrBefore(deliveryDate)
  }

  return true
}

const StopSchema = yup.object({
  /**
   * Making it nullable and then checking for it not to be null manually
   * helps us with type check and field validation at the object level.
   */
  location: AutocompleteLocationSchema.nullable().test('not-null', stopLocationNotNull),
  type: yup.string().required(),
})

export const laneInformationFormSchema = yup.object({
  stops: yup.array(StopSchema),
  equipmentKey: yup.string().required('You must select an equipment type.'),
  pickupDate: yup.string().nullable(),
  pickupTime: yup.string().nullable(),
  deliveryDate: yup
    .string()
    .nullable()
    .test(
      'pickup-before-delivery',
      'Delivery Date cannot be before Pickup Date.',
      splitPickupBeforeSplitDelivery
    ),
  deliveryTime: yup.string().nullable(),
  weight: yup
    .number()
    .moreThan(0, 'Weight must be greater than 0 lbs.')
    .lessThan(100000, 'Weight cannot exceed 100k lbs.')
    .transform(emptyStringToNull)
    .nullable(),
  customer: PillSelectSearchableOrganizationShipperSchema.nullable(),
  advancedInputs: advancedInputsSchema,
  temperature: yup
    .object({
      min: yup
        .number()
        .integer()
        .moreThan(-51, 'Min values cannot be below -50°.')
        .test('is-min-set', 'You must enter a Min °F Degree.', checkMinPresent)
        .test(
          'is-min-less-than-max',
          'Min °F Degree amount cannot exceed Max °F Degree.',
          checkMinLessThanMax
        )
        .transform(emptyStringToNull)
        .nullable(),
      max: yup
        .number()
        .integer()
        .lessThan(76, 'Max values cannot exceed 75°.')
        .test('is-max-set', 'You must enter a Max °F Degree.', checkMaxPresent)
        .test('is-min-less-than-max', '', checkMinLessThanMax)
        .transform(emptyStringToNull)
        .nullable(),
    })
    .optional(),
})

export interface IStopSchema extends yup.InferType<typeof StopSchema> {}

export interface LaneInformationFormSchema extends yup.InferType<typeof laneInformationFormSchema> {
  // using interface instead of type generally gives nicer editor feedback
}
