import React, { ComponentType } from 'react'
import { Controller, ControllerProps, ControllerRenderProps } from 'react-hook-form'
import { TimePicker, TimePickerProps } from 'dpl/components/TimePicker'

type IBaseControllerProps = ControllerProps<ComponentType<TimePickerProps>>

export interface ControlledTimePickerProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  TimePickerProps?: Partial<Omit<TimePickerProps, keyof ControllerRenderProps<Record<string, any>>>>
  onChange?: TimePickerProps['onChange']
}

export function ControlledTimePicker({
  TimePickerProps,
  control,
  defaultValue,
  name,
  onChange: onChangeProp,
}: ControlledTimePickerProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => {
        const changeHandler: TimePickerProps['onChange'] = value => {
          onChange(value)
          if (onChangeProp) onChangeProp(value)
        }
        const onClear = () => {
          onChange(null)
        }

        return (
          <TimePicker
            {...TimePickerProps}
            name={name}
            onChange={changeHandler}
            onClear={onClear}
            value={value}
          />
        )
      }}
    />
  )
}

export default ControlledTimePicker
