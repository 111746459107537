import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import {
  AutocompleteOrganizationCarrier,
  IAutocompleteOrganizationCarrierProps,
} from '../AutocompleteOrganizationCarrier'
import { TAutocompleteOrganizationCarrierSchema } from './schema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IAutocompleteOrganizationCarrierProps>,
  TAutocompleteOrganizationCarrierSchema
>

export interface IControlledAutocompleteOrganizationCarrierProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteOrganizationCarrierProps?: Omit<IAutocompleteOrganizationCarrierProps, 'value'>
}

export const ControlledAutocompleteOrganizationCarrier =
  memo<IControlledAutocompleteOrganizationCarrierProps>(
    ({ AutocompleteOrganizationCarrierProps, control, defaultValue, name }) => {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ onChange, value }) => {
            const { onChange: onChangeProp } = AutocompleteOrganizationCarrierProps || {}
            const changeHandler: IAutocompleteOrganizationCarrierProps['onChange'] = (
              event,
              newValue,
              reason,
              details
            ) => {
              onChange(newValue)
              if (onChangeProp) {
                onChangeProp(event, newValue, reason, details)
              }
            }
            return (
              <AutocompleteOrganizationCarrier
                {...AutocompleteOrganizationCarrierProps}
                onChange={changeHandler}
                value={value}
              />
            )
          }}
        />
      )
    }
  )
