import React, { memo } from 'react'
import { CustomerDrawer } from '#routes/customers/CustomersPage/forms/CustomerDrawer/CustomerDrawer'
import { useCustomerModal } from '#routes/customers/CustomersPage/hooks'
import { AccessRestrictedModal, FeatureUnavailableModal } from 'dpl'

export const EditCustomer = memo(() => {
  const { closeModal, isAccessRestrictedModalOpen, isFeatureUnavailableModalOpen, isModalOpen } =
    useCustomerModal({ isEditing: true })
  return (
    <div>
      <CustomerDrawer isEditing open={isModalOpen} onClose={closeModal} />
      <AccessRestrictedModal open={isAccessRestrictedModalOpen} onClose={closeModal} />
      <FeatureUnavailableModal open={isFeatureUnavailableModalOpen} onClose={closeModal} />
    </div>
  )
})
