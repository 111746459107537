import Color from 'color'
import { ISliderProps, Slider } from 'dpl/components/Slider'
import { styled } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'

export interface IRateSliderProps extends ISliderProps {
  /**
   * If true, value sublabel is shown on mobile screens
   * @default false
   */
  showSubLabelOnMobile?: boolean
}

/**
 * A styled component used in Rates Tools
 */
export const RateSlider = styled(Slider, {
  name: 'MuiSlider',
  slot: 'root',
})<IRateSliderProps>(({ showSubLabelOnMobile, theme }) => ({
  color: brandColors.skyBlue5,
  height: theme.spacing(1),
  '& .MuiSlider-track': {
    border: 'none',
  },
  '&:not(.Mui-disabled) .MuiSlider-rail': {
    backgroundColor: brandColors.skyBlue7,
    opacity: 0.38,
  },
  '& .MuiSlider-mark': {
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    borderRadius: '50%',
    backgroundColor: brandColors.white,
  },
  '& .MuiSlider-thumb': {
    border: `2px solid ${brandColors.white}`,
  },
  '&:not(.Mui-disabled) .MuiSlider-thumb': {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: `0 0 0 ${theme.spacing(1)} ${Color(brandColors.skyBlue6).fade(0.7)}`,
    },
  },
  '&.Mui-disabled .MuiSlider-thumb': {
    '&::before': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    color: theme.palette.text.primary,
  },
  '& .MuiSlider-valueLabel': {
    padding: theme.spacing(0.5),
  },

  // Moves the Balanced label above the slider
  '& .MuiSlider-markLabel:has(.Balanced)': {
    top: showSubLabelOnMobile ? '-35px' : '-20px',
    bottom: showSubLabelOnMobile ? '-35px' : '-20px',
    [theme.breakpoints.up('md')]: {
      top: '-35px',
      bottom: '-35px',
    },
  },
  // Adjusts margins to account for labels
  '&:has(.Balanced)': {
    marginTop: showSubLabelOnMobile ? '35px' : '20px',
    marginBottom: showSubLabelOnMobile ? '35px' : '20px',
    [theme.breakpoints.up('md')]: {
      marginTop: '35px',
      marginBottom: '35px',
    },
  },
}))
