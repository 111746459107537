import { ICarrierUserRoles, ICarrierUserUserTypes } from '../../types/graphqlTypes'
import { IAutocompleteOrganizationCarrierUserEmailQueryVariables } from './graphql/AutocompleteOrganizationCarrierUserEmail'
import { IOrganizationCarrierUserEmailFragment } from './graphql/OrganizationCarrierUserEmailResultFragment'

export const EMPTY_RESULTS: IOrganizationCarrierUserEmailFragment[] = []

export const DEFAULT_USER_ROLES: IAutocompleteOrganizationCarrierUserEmailQueryVariables['roles'] =
  [ICarrierUserRoles.admin, ICarrierUserRoles.employee]

export const DEFAULT_USER_TYPES: IAutocompleteOrganizationCarrierUserEmailQueryVariables['userTypes'] =
  [ICarrierUserUserTypes.dispatcher, ICarrierUserUserTypes.owner_operator]
