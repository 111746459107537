import { ISpotRatesAdjustmentRuleVersionFragment } from '../../../AdjustmentRulesSection/graphql/SpotRatesAdjustmentRuleVersionFragment'
import { TAdjustmentRuleFormSchema } from '../../schema'
import { TFactorsSchema } from '../../schema/FactorsSchema'
import { TRateStrategySchema } from '../../schema/RateStrategySchema'
import { TTimeframeSchema } from '../../schema/TimeframeSchema'
import { mapFactorsToFormSchema } from './mapFactorsToFormSchema'
import { mapRateStrategytoFormSchema } from './mapRateStrategytoFormSchema'
import { mapTimeframeToFormSchema } from './mapTimeframetoFormSchema'

export const mapAdjustmentRuleToFormSchema = (
  rule: Nullable<ISpotRatesAdjustmentRuleVersionFragment>,
  defaultCostPercentile: Nullable<number>
): TAdjustmentRuleFormSchema => {
  const { reason } = rule || {}

  const rateStrategy: TRateStrategySchema = mapRateStrategytoFormSchema(rule, defaultCostPercentile)

  const timeframe: TTimeframeSchema = mapTimeframeToFormSchema(rule)

  const factors: TFactorsSchema = mapFactorsToFormSchema(rule)

  return {
    reason: reason || '',
    rateStrategy,
    timeframe,
    factors,
  }
}
