import React, { createContext, MutableRefObject, PropsWithChildren, useContext } from 'react'
import { GridApiPro } from 'dpl/core'
import {
  IUseCarrierRatesReorderProps,
  IUseCarrierRatesReorderState,
  useCarrierRatesReorder,
} from '../hooks'

const CarrierRatesReorderContext = createContext<IUseCarrierRatesReorderState>({
  dataGridApiRef: { current: {} } as MutableRefObject<GridApiPro>,
  isReordering: false,
  reorderLoading: false,
  saveOrder: () => {},
  toggleReordering: () => {},
})

export function CarrierRatesReorderProvider({
  children,
  routingGuideLaneId,
}: PropsWithChildren<IUseCarrierRatesReorderProps>) {
  const state = useCarrierRatesReorder({ routingGuideLaneId })
  return (
    <CarrierRatesReorderContext.Provider value={state}>
      {children}
    </CarrierRatesReorderContext.Provider>
  )
}

export const useCarrierRatesReorderContext = (): IUseCarrierRatesReorderState =>
  useContext(CarrierRatesReorderContext)
