import React, { memo, useMemo } from 'react'
import { Box, Typography } from 'dpl/core'
import EditIcon from 'dpl/icons/build/EditIcon'
import NotesIcon from 'dpl/icons/build/NotesIcon'
import QuoteIcon from 'dpl/icons/build/QuoteIcon'

export interface ISpotQuoteCardSubheaderProps {
  hasQuotesEnabled: boolean
  quoteId?: Nullable<string>
}

export const SpotQuoteCardSubheader = memo<ISpotQuoteCardSubheaderProps>(
  ({ hasQuotesEnabled, quoteId }) => {
    const Icon = useMemo(() => {
      if (!hasQuotesEnabled) {
        return QuoteIcon
      }
      return quoteId ? NotesIcon : EditIcon
    }, [hasQuotesEnabled, quoteId])

    const label = useMemo(() => {
      if (!hasQuotesEnabled) {
        return 'Estimates'
      }
      return quoteId ? `QUOTE #${quoteId}` : 'Draft'
    }, [hasQuotesEnabled, quoteId])

    return (
      <Box display='flex' alignItems='center' gap={0.5}>
        <Icon size='medium' />
        <Typography variant='overline'>{label}</Typography>
      </Box>
    )
  }
)
