import { FORMATS } from 'dpl/constants/datetime'
import moment from 'moment'
import { ISpotRatesAdjustmentRuleVersionFragment } from '../../../AdjustmentRulesSection/graphql/SpotRatesAdjustmentRuleVersionFragment'
import { TTimeframeSchema } from '../../schema/TimeframeSchema'

export const mapTimeframeToFormSchema = (
  rule: Nullable<Pick<ISpotRatesAdjustmentRuleVersionFragment, 'startAt' | 'endAt'>>
): TTimeframeSchema => {
  const { endAt, startAt } = rule || {}

  const timeframe: TTimeframeSchema = {
    startDate: startAt ? moment(startAt).utc().format(FORMATS.dateFormat) : '',
    endDate: endAt ? moment(endAt).utc().format(FORMATS.dateFormat) : '',
    noEndDate: !!startAt && !endAt,
  }

  return timeframe
}
