import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { DatePicker, IDatePickerProps } from 'dpl/components/DatePicker'
import { MaterialUiPickersDate } from 'dpl/core/pickers'

type IBaseControllerProps = ControllerProps<React.ComponentType<IDatePickerProps>>
export interface IControlledDatePickerProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  onChange?: (changedDate: MaterialUiPickersDate) => void
  transform?: (value: MaterialUiPickersDate) => string
  DatePickerProps?: Omit<IDatePickerProps, 'onChange' | 'value'> & {
    dataTest?: string
  }
}

export const transformToIsoString = (value: MaterialUiPickersDate) => {
  if (typeof value === 'string' || value === null) {
    return value
  }

  return value?.toISOString()
}

const ControlledDatePicker = memo<IControlledDatePickerProps>(
  ({ control, name, defaultValue, transform, onChange: userOnChange, DatePickerProps = {} }) => (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, value: val }) => {
        const changeHandler: IDatePickerProps['onChange'] = changedDate => {
          if (changedDate) {
            const value = transform ? transform(changedDate) : changedDate
            onChange(value)

            if (userOnChange) {
              userOnChange(changedDate)
            }
          }
        }

        const clearHandler = () => {
          onChange(null)

          if (userOnChange) {
            userOnChange(null)
          }
        }

        return (
          <DatePicker
            onBlur={onBlur}
            onChange={changeHandler}
            onClear={clearHandler}
            required
            value={val}
            {...DatePickerProps}
          />
        )
      }}
    />
  )
)

export default ControlledDatePicker
