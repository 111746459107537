import React, { useMemo } from 'react'
import { RatesEstimatedCarrierRateAccordion } from '#components/RatesEstimatedCarrierRateAccordion'
import { RatesEstimatedMarginAccordion } from '#components/RatesEstimatedMarginAccordion'
import { ICostModel } from '#types/costModel'
import { IEstimate } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { getAdjustmentRulesByType } from '#utils/getAdjustmentRulesByType/getAdjustmentRulesByType'
import { InlineMessage } from 'dpl/components/InlineMessage'
import { Box, Stack, Typography } from 'dpl/core'
import { RatesDoNotBidMessage } from '../../../../../components'
import { IFetchRatesMutation } from '../../../../spot-rates-tool/graphql/FetchRatesMutation'

export interface ISpotQuoteDetailsProps {
  overrideReason: Nullable<string>
  /**
   * The final margin premium dollar value used for the quote
   * @example 45.21
   */
  marginValue: number
  /**
   * The final carrier rate used for the quote
   * @example 4500.34
   */
  carrierRateValue: number
  /**
   * The cost percentile used to determine the final carrier rate.
   * @example 10
   */
  costPercentile?: Nullable<number>
  /**
   * The cost shift percentage used to determine the final carrier rate.
   * @example 0.1
   */
  costShiftPercentage?: Nullable<number>
  /**
   * The cost model generated when rate was fetched. This is used to populate the cost slider.
   */
  spotCostModel: Nullable<ICostModel>
  /**
   * Rate adjustments (rules, default settings, suggestions, etc) that were used at the time the quote was saved.
   */
  rateAdjustments: NonNullable<
    NonNullable<IFetchRatesMutation['fetchRates']>['ratesResponse']
  >['rateAdjustments']
  /**
   * @optional
   * @default 'SpotQuoteDetails'
   */
  dataTest?: string
}

export function SpotQuoteDetails({
  carrierRateValue,
  costPercentile,
  costShiftPercentage,
  dataTest = 'SpotQuoteDetails',
  marginValue,
  overrideReason,
  rateAdjustments,
  spotCostModel,
}: ISpotQuoteDetailsProps) {
  const {
    appliedRules,
    matchedRules,
    maxCostLimitReached: isAboveMaxCost,
    minCostLimitReached: isBelowMinCost,
    organizationSetting,
    rawCostPercentile,
    rawCostShiftPercentage,
    rawMarginPremium,
    suggestedCostPercentile,
    suggestedCostShiftPercentage,
    suggestedMarginPremium,
  } = rateAdjustments || {}
  const { activeSettingVersion } = organizationSetting || {}
  const { defaultCostPercentile, defaultMarginPremium } = activeSettingVersion || {}

  const {
    costRules: appliedCostRules,
    doNotBidRules,
    marginRules: appliedMarginRules,
  } = useMemo(() => getAdjustmentRulesByType(appliedRules || []), [appliedRules])

  const { costRules: matchedCostRules, marginRules: matchedMarginRules } = useMemo(
    () => getAdjustmentRulesByType(matchedRules || []),
    [matchedRules]
  )

  const doNotBidReason = useMemo(() => {
    const [doNotBidRule] = doNotBidRules || []
    const { reason } = doNotBidRule || {}
    return reason
  }, [doNotBidRules])

  const rateEstimate: IEstimate = useMemo(() => {
    return {
      carrierRate: carrierRateValue,
      marginValue,
      marginPercentage: marginValue / carrierRateValue,
    }
  }, [carrierRateValue, marginValue])

  const overrideMessage = useMemo(() => {
    return `Override reason: ${overrideReason}`
  }, [overrideReason])

  const doNotBidRuleOverridden = !!doNotBidReason
  const appliedRulesOverridden = !!overrideReason && !doNotBidReason

  return (
    <div data-test={dataTest}>
      {doNotBidRuleOverridden && (
        <Stack spacing={2} pb={2}>
          <InlineMessage
            dataTest={`${dataTest}-overrideMessage`}
            message={overrideMessage}
            fullWidth
          />
          <RatesDoNotBidMessage dataTest={`${dataTest}-dnbMessage`} reason={doNotBidReason} />
        </Stack>
      )}
      <Stack pt={1.5} spacing={1.5}>
        <Box display='flex' justifyContent='space-between' data-test={`${dataTest}-quote`}>
          <Typography variant='h3' fontWeight={500}>
            Spot Quote
          </Typography>
          <Typography variant='h3' fontWeight={500}>
            {currencyFormatter(carrierRateValue + marginValue)}
          </Typography>
        </Box>
        {appliedRulesOverridden && (
          <InlineMessage
            dataTest={`${dataTest}-overrideMessage`}
            message={overrideMessage}
            fullWidth
          />
        )}
        <RatesEstimatedCarrierRateAccordion
          dataTest={`${dataTest}-CarrierRateAccordion`}
          isReadOnly
          isDoNotBid={false}
          isBelowMinCost={isBelowMinCost || false}
          isAboveMaxCost={isAboveMaxCost || false}
          costModel={spotCostModel}
          sliderValue={rateEstimate.carrierRate}
          defaultCostPercentile={Number(defaultCostPercentile)}
          rawCostPercentile={rawCostPercentile || 0}
          rawCostShiftPercentage={rawCostShiftPercentage || 0}
          suggestedCostPercentile={suggestedCostPercentile || 0}
          suggestedCostShiftPercentage={suggestedCostShiftPercentage || 0}
          quotedCostPercentile={costPercentile}
          quotedCostShiftPercentage={costShiftPercentage}
          appliedRules={appliedCostRules}
          matchedRules={matchedCostRules}
        />
        <RatesEstimatedMarginAccordion
          dataTest={`${dataTest}-MarginAccordion`}
          isReadOnly
          isDoNotBid={false}
          rateEstimate={rateEstimate}
          appliedRules={appliedMarginRules}
          matchedRules={matchedMarginRules}
          suggestedMarginPremium={suggestedMarginPremium}
          defaultMarginPremium={defaultMarginPremium || 0}
          rawMarginPremium={rawMarginPremium}
        />
      </Stack>
    </div>
  )
}
