import React, { createContext, PropsWithChildren, useContext } from 'react'
import { AdjustmentRuleFormSidePanel } from '#routes/spot-rates-control-panel/components/AdjustmentRuleFormSidePanel'
import { DeactivateAdjustmentRuleConfirmationModal } from '../components/DeactivateAdjustmentRuleConfirmationModal'
import { IUseSpotRatesControlPanelState, useSpotRatesControlPanel } from '../hooks'

export interface ISpotRatesControlPanelContextState extends IUseSpotRatesControlPanelState {}

const SpotRatesControlPanelContext = createContext<ISpotRatesControlPanelContextState>({
  currentRule: null,
  isAdjustmentRuleFormOpen: false,
  openAdjustmentRuleForm: () => {},
  closeAdjustmentRuleForm: () => {},
  isDeactivateRuleConfirmationModalOpen: false,
  openDeactivateRuleConfirmationModal: () => {},
  closeDeactivateRuleConfirmationModal: () => {},
  spotRatesSettings: {
    createdAt: '',
    defaultCostPercentile: null,
    defaultMarginPremium: null,
    maxMarginPremium: null,
    loading: true,
  },
})

export function SpotRatesControlPanelProvider({ children }: PropsWithChildren<{}>) {
  const value = useSpotRatesControlPanel()
  const {
    closeAdjustmentRuleForm,
    closeDeactivateRuleConfirmationModal,
    currentRule,
    isAdjustmentRuleFormOpen,
    isDeactivateRuleConfirmationModalOpen,
    spotRatesSettings,
  } = value || {}
  const { organizationAdjustmentRule } = currentRule || {}
  const { id: organizationAdjustmentRuleId } = organizationAdjustmentRule || {}

  const { defaultCostPercentile } = spotRatesSettings || {}

  return (
    <SpotRatesControlPanelContext.Provider value={value}>
      {children}
      {isAdjustmentRuleFormOpen && (
        <AdjustmentRuleFormSidePanel
          onClose={closeAdjustmentRuleForm}
          defaultCostPercentile={defaultCostPercentile}
          adjustmentRule={currentRule}
        />
      )}
      {isDeactivateRuleConfirmationModalOpen && organizationAdjustmentRuleId && (
        <DeactivateAdjustmentRuleConfirmationModal
          onClose={closeDeactivateRuleConfirmationModal}
          organizationAdjustmentRuleId={organizationAdjustmentRuleId}
        />
      )}
    </SpotRatesControlPanelContext.Provider>
  )
}

export const useSpotRatesControlPanelContext = (): ISpotRatesControlPanelContextState =>
  useContext(SpotRatesControlPanelContext)
