import React, { memo, useCallback, useState } from 'react'
import { AutocompleteLocation, IAutocompleteLocationProps } from '#components/AutocompleteLocation'
import { ILocationResultFragment } from '#components/AutocompleteLocation/graphql/LocationResultFragment'
import { AutocompleteState, IAutocompleteStateProps } from '#components/AutocompleteState'
import {
  AutocompleteRegion,
  IAutocompleteRegionProps,
} from '#routes/spot-rates-control-panel/components/AutocompleteRegion'
import { IRegionsEnum } from '#routes/spot-rates-control-panel/types'
import { IGeocodingResultTypeEnum } from '#types/graphqlTypes'
import { IAutocompleteProps } from 'dpl/components/Autocomplete'
import { IDataGridFilterProps } from 'dpl/components/DataGrid/components/DataGridFilters/components/types'
import { ISelectFieldProps, SelectField } from 'dpl/components/SelectField'
import { makeStyles, Theme } from 'dpl/core/styles'
import SearchIcon from 'dpl/icons/build/SearchIcon'
import {
  GEOGRAPHIC_TYPE_SELECT_OPTIONS,
  IAdjustmentRuleLocationFilterGeographicType,
} from './constants'
import { IAdjustmentRuleLocationFilter } from './types'

const DEFAULT_AUTOCOMPLETE_PROPS: Pick<
  IAutocompleteProps<any>,
  'placeholder' | 'renderStartAdornment'
> = {
  placeholder: 'Search',
  renderStartAdornment: () => <SearchIcon size='xlarge' />,
}

export interface IAdjustmentRuleLocationFilterProps
  extends IDataGridFilterProps<IAdjustmentRuleLocationFilter> {
  /**
   * @default 'AdjustmentRuleLocationFilter'
   */
  dataTest?: string
  /**
   * The label to display on the filter.
   */
  label?: string
}

const useAdjustmentRuleLocationFilterStyles = makeStyles<
  Theme,
  Pick<IDataGridFilterProps<IAdjustmentRuleLocationFilter>, 'variant'>
>(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(0.5),

    '& > div': {
      flex: ({ variant }) => (variant === 'menu' ? '0 0 50%' : 'unset'),
      minWidth: ({ variant }) => (variant === 'menu' ? 'auto' : '200px'),
    },
  },
}))

export const AdjustmentRuleLocationFilter = memo<IAdjustmentRuleLocationFilterProps>(
  ({ dataTest = 'AdjustmentRuleLocationFilter', label, name, onChange, value, variant }) => {
    const classes = useAdjustmentRuleLocationFilterStyles({ variant })
    const { geographicType: geographicTypeValue = '', value: geographicValue } = value || {}

    // We keep geographicType state locally here to prevent api call until a geographic value is selected
    const [currentGeographicType, setCurrentGeographicType] = useState(geographicTypeValue)
    const [currentValue, setCurrentValue] = useState(geographicValue)

    const geographicTypeChangeHandler = useCallback<NonNullable<ISelectFieldProps['onChange']>>(
      e => {
        const { target } = e || {}
        const { value: newVal } = target || {}
        setCurrentGeographicType(newVal)
        setCurrentValue('')
      },
      []
    )

    const regionChangeHandler = useCallback<NonNullable<IAutocompleteRegionProps['onChange']>>(
      (_, newVal) => {
        const value = newVal ? `${newVal}` : ''
        setCurrentValue(value)
        onChange({ name, value: { geographicType: 'region', value } })
      },
      [name, onChange]
    )

    const stateChangeHandler = useCallback<NonNullable<IAutocompleteStateProps['onChange']>>(
      (_, newVal) => {
        const { stateCode } = newVal || {}
        const value = stateCode || ''
        setCurrentValue(value)
        onChange({ name, value: { geographicType: 'state', value } })
      },
      [name, onChange]
    )

    const zipChangeHandler = useCallback<NonNullable<IAutocompleteLocationProps['onChange']>>(
      (_, newVal) => {
        const { postalCode: newValPostalCode } = newVal || {}
        const value = newValPostalCode || ''
        setCurrentValue(value)
        onChange({ name, value: { geographicType: 'zipCode', value } })
      },
      [name, onChange]
    )

    return (
      <div className={classes.root} data-test={dataTest}>
        <div>
          <SelectField
            dataTest={`${dataTest}-geographic-type`}
            label={label}
            onChange={geographicTypeChangeHandler}
            options={GEOGRAPHIC_TYPE_SELECT_OPTIONS}
            size={variant === 'menu' ? 'medium' : 'small'}
            value={currentGeographicType}
          />
        </div>
        <div>
          {currentGeographicType === IAdjustmentRuleLocationFilterGeographicType.region && (
            <AutocompleteRegion
              dataTest={`${dataTest}-region`}
              onChange={regionChangeHandler}
              size={variant === 'menu' ? 'medium' : 'small'}
              value={currentValue ? (currentValue as IRegionsEnum) : null}
              {...DEFAULT_AUTOCOMPLETE_PROPS}
            />
          )}
          {currentGeographicType === IAdjustmentRuleLocationFilterGeographicType.state && (
            <AutocompleteState
              dataTest={`${dataTest}-state`}
              label=''
              onChange={stateChangeHandler}
              size={variant === 'menu' ? 'medium' : 'small'}
              value={currentValue ? { id: currentValue } : null}
              {...DEFAULT_AUTOCOMPLETE_PROPS}
            />
          )}
          {currentGeographicType === IAdjustmentRuleLocationFilterGeographicType.zipCode && (
            <AutocompleteLocation
              dataTest={`${dataTest}-zip`}
              getOptionLabel={option => option.postalCode || ''}
              isOptionEqualToValue={(option, value) => option?.postalCode === value?.postalCode}
              onChange={zipChangeHandler}
              resultTypes={[IGeocodingResultTypeEnum.PostalCode]}
              size={variant === 'menu' ? 'medium' : 'small'}
              value={
                currentValue ? ({ postalCode: currentValue } as ILocationResultFragment) : null
              }
              {...DEFAULT_AUTOCOMPLETE_PROPS}
            />
          )}
        </div>
      </div>
    )
  }
)
