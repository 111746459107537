import { ICarrierRateRateTypes } from '../../../../../../types/graphqlTypes'
import { IRateType } from '../../../../../../types/rateType'
import { IRgCarrierRateFragment } from '../../../graphql/RGCarrierRateFragment'
import { TRoutingGuideCarrierSchema } from '../schema'
import { getRoutingGuideCarrierSchemaAvailability } from './getRoutingGuideCarrierSchemaAvailability'

export const getRoutingGuideCarrierSchema = (
  carrierRate: IRgCarrierRateFragment
): TRoutingGuideCarrierSchema => {
  const { carrierUser, organizationCarrierRelationship, rate, rateType } = carrierRate || {}
  const { consumerUser } = carrierUser || {}
  const { user } = consumerUser || {}
  const { emailAddress: carrierUserEmail } = user || {}

  return {
    organizationCarrier: organizationCarrierRelationship,
    carrierUserEmail: {
      value: carrierUser,
      inputValue: carrierUserEmail,
    },
    rateType: rateType === ICarrierRateRateTypes.all_in ? IRateType.allIn : IRateType.linehaul,
    rate,
    ...getRoutingGuideCarrierSchemaAvailability(carrierRate),
  }
}
