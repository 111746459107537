import { DefaultValues, useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IEquipment } from '../../../../../../types/equipment'
import { RoutingGuideLaneSchema, TRoutingGuideLaneSchema } from '../schema'

export const getDefaultValues = (values: DefaultValues<TRoutingGuideLaneSchema>) => {
  return RoutingGuideLaneSchema.cast(
    {
      origin: null,
      destination: null,
      equipment: IEquipment.dryVan,
      laneManager: null,
      ...values,
    },
    {
      assert: false,
    }
  )
}

export interface IUseRoutingGuideLaneFormProps
  extends Omit<UseFormOptions<TRoutingGuideLaneSchema>, 'resolver'> {}

export const useRoutingGuideLaneForm = ({
  defaultValues = {},
  ...rest
}: IUseRoutingGuideLaneFormProps) => {
  return useForm<TRoutingGuideLaneSchema>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: yupResolver(RoutingGuideLaneSchema),
    ...rest,
  })
}
