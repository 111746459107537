import React, { memo } from 'react'
import {
  ControlledToggleButtonGroup,
  IControlledToggleButtonGroupProps,
} from 'forms/components/ControlledToggleButtonGroup'
import { IDaysOfWeek } from '../../types/daysOfWeek'

export interface IControlledDaysOfWeekToggleButtonGroupProps
  extends Omit<IControlledToggleButtonGroupProps, 'ToggleButtonGroupProps'> {
  ToggleButtonGroupProps?: Omit<
    IControlledToggleButtonGroupProps['ToggleButtonGroupProps'],
    'options'
  >
}

const OPTIONS: IControlledToggleButtonGroupProps['ToggleButtonGroupProps']['options'] = [
  { label: 'Sun', value: IDaysOfWeek.sun },
  { label: 'Mon', value: IDaysOfWeek.mon },
  { label: 'Tue', value: IDaysOfWeek.tue },
  { label: 'Wed', value: IDaysOfWeek.wed },
  { label: 'Thu', value: IDaysOfWeek.thu },
  { label: 'Fri', value: IDaysOfWeek.fri },
  { label: 'Sat', value: IDaysOfWeek.sat },
]

export const ControlledDaysOfWeekToggleButtonGroup =
  memo<IControlledDaysOfWeekToggleButtonGroupProps>(({ ToggleButtonGroupProps, ...props }) => {
    return (
      <ControlledToggleButtonGroup
        {...props}
        ToggleButtonGroupProps={{
          ...ToggleButtonGroupProps,
          options: OPTIONS,
        }}
      />
    )
  })
