import React, { MouseEventHandler, useCallback, useMemo } from 'react'
import { Button, makeStyles, SelectProps as MuiSelectProps, Popover } from '../../core'
import CloseIcon from '../../icons/build/CloseIcon'
import DownArrowIcon from '../../icons/build/DownArrowIcon'
import UpArrowIcon from '../../icons/build/UpArrowIcon'
import { TIconComponent } from '../../icons/types'
import { brandColors } from '../../theme/colors'
import { IAutocompleteListProps, AutocompleteList } from '../AutocompleteList'
import { IconButton } from '../IconButton'

export interface IPillSelectSearchableProps<Value>
  extends Pick<MuiSelectProps<Value>, 'label'>,
    Omit<IAutocompleteListProps<Value>, 'autoFocus' | 'dataTest' | 'onChange' | 'size'> {
  /**
   * Whether the select should display a clear button when a value is selected
   * @default true
   */
  clearable?: boolean
  /**
   * @default 'PillSelectSearchable'
   */
  dataTest?: string
  /**
   * The icon to display at the start of the select
   */
  icon?: TIconComponent
  /**
   * The function to call when the value of the select changes
   * @param selectedValue The value of the selected option
   */
  onChange?: (selectedValue: Nullable<Value>) => void
  /**
   * The label to display for the search input
   */
  searchLabel?: IAutocompleteListProps<Value>['label']
}

const usePillSelectSearchableStyles = makeStyles(theme => ({
  clearButton: {
    color: brandColors.skyBlue6,
  },
  searchableMenu: {
    minWidth: 250,
    '& .MuiAutocomplete-root': {
      padding: theme.spacing(2),
      border: `1px solid ${brandColors.coolGray3}`,
    },
  },
}))

export function PillSelectSearchable<Value>({
  clearable = true,
  dataTest = 'PillSelectSearchable',
  getOptionLabel,
  icon: IconComponent,
  label: labelProp,
  onChange,
  searchLabel,
  value,
  ...props
}: IPillSelectSearchableProps<Value>) {
  const classes = usePillSelectSearchableStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const showClearButton = useMemo(() => {
    if (!clearable) return false

    return Boolean(value)
  }, [clearable, value])

  const openPopoverHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const closePopoverHandler = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }, [anchorEl])

  const changeHandler = useCallback<NonNullable<IAutocompleteListProps<Value>['onChange']>>(
    (_, newValue, reason) => {
      if (reason === 'selectOption') {
        onChange?.(newValue)
        setAnchorEl(null)
      }
    },
    [onChange]
  )

  const clearHandler = useCallback<MouseEventHandler>(
    e => {
      e.stopPropagation()
      onChange?.(null)
    },
    [onChange]
  )

  const open = Boolean(anchorEl)
  const id = open ? 'searchable-select' : undefined

  const ArrowIcon = open ? UpArrowIcon : DownArrowIcon

  return (
    <div>
      <Button
        data-test={dataTest}
        variant='pill'
        size='xsmall'
        aria-describedby={id}
        onClick={openPopoverHandler}
        className={`${value ? 'hasValue' : ''} ${open ? 'popover-open' : ''}`}
        startIcon={
          IconComponent ? <IconComponent size='large' data-test={`${dataTest}-icon`} /> : null
        }
        endIcon={
          showClearButton ? (
            <IconButton
              Icon={CloseIcon}
              onClick={clearHandler}
              size='large'
              color='secondary'
              dataTest={`${dataTest}-clear`}
              className={classes.clearButton}
              noPadding
            />
          ) : (
            <ArrowIcon size='large' color='skyBlue6' data-test={`${dataTest}-arrow`} />
          )
        }>
        {value ? getOptionLabel?.(value) : labelProp}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopoverHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { className: classes.searchableMenu } }}>
        <AutocompleteList
          autoFocus
          dataTest={`${dataTest}-searchable-list`}
          getOptionLabel={getOptionLabel}
          label={searchLabel}
          onChange={changeHandler}
          size='small'
          value={value || null}
          {...props}
        />
      </Popover>
    </div>
  )
}
