import moment from 'moment'
import * as yup from 'yup'

export const TimeframeSchema = yup.object({
  startDate: yup.string().required('Start date is required.'),
  noEndDate: yup.boolean(),
  endDate: yup
    .string()
    .nullable()
    .when('noEndDate', {
      is: (value: string) => !value,
      then: (schema: yup.Schema) =>
        schema.required('End date is required unless checkbox is selected below.'),
    })
    .test('endDateAfterStartDate', 'End date must be after start date.', (value, context) => {
      const { parent } = context || {}
      const { noEndDate, startDate } = parent || {}
      if (!value) return true
      return noEndDate ? true : moment(value).isSameOrAfter(moment(startDate))
    }),
})

export type TTimeframeSchema = yup.InferType<typeof TimeframeSchema>
