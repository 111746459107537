import React, { memo, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { IOrganizationShipperSortBy } from '#types/graphqlTypes'
import { AccessRestrictedModal, FeatureUnavailableModal } from 'dpl/components/Modals'
import isEmpty from 'lodash/isEmpty'
import { CustomersDataGrid } from '../CustomersDataGrid/CustomersDataGrid'
import { CUSTOMERS_COLUMNS } from '../CustomersDataGrid/constants/customersColumns'
import { ICustomersDataGrid, ICustomersDataGridFilters } from '../CustomersDataGrid/types'
import { getActionColumnDef } from '../CustomersDataGrid/utils'
import { DeactivateCustomer } from './components/DeactivateCustomer'
import { EditCustomer } from './components/EditCustomer'
import { ICustomerFragment } from './graphql/CustomerFragment'
import { useCustomersActions } from './hooks/useCustomerStatusAction'

export const CustomersPageContent = memo(() => {
  const navigate = useNavigate()

  const {
    closeReactivateAccessRestrictedModal,
    closeReactivateFeatureUnavailabledModal,
    customerRows,
    isLoadingCustomersData,
    reactivateCustomerHandler,
    reactivateModalState,
    refetchSearchCustomers,
  } = useCustomersActions()

  const [filters, setFilters] = useState<ICustomersDataGridFilters>({
    searchTerm: null,
  })

  const hasSearchFilters = useMemo(() => !isEmpty(filters), [filters])

  const clickEditCustomerHandler = (row: ICustomerFragment) => {
    const { id } = row
    navigate(`${PROTECTED_PATHS.customerEdit}/${id}`)
  }

  const clickDeactivateCustomerHandler = (row: ICustomerFragment) => {
    const { id } = row
    navigate(`${PROTECTED_PATHS.customerDeactivate}/${id}`)
  }

  const actionColumn = getActionColumnDef({
    onDeactivateClick: clickDeactivateCustomerHandler,
    onEditClick: clickEditCustomerHandler,
    onReactivateClick: reactivateCustomerHandler,
  })

  const columns = [...CUSTOMERS_COLUMNS, actionColumn]

  const handleFilterOrSortChange = useCallback<
    NonNullable<ICustomersDataGrid['onFilterOrSortChange']>
  >(
    params => {
      const { searchTerm, sort: sortOrder } = params

      setFilters({ searchTerm })
      refetchSearchCustomers({
        searchTerm,
        filters: {
          sortBy: IOrganizationShipperSortBy.created_at,
          sortOrder,
        },
      })
    },
    [refetchSearchCustomers]
  )

  const showDataGrid = useMemo(() => {
    if (isLoadingCustomersData) {
      return true
    }

    if (hasSearchFilters) {
      return true
    }
    return customerRows.length > 0
  }, [isLoadingCustomersData, customerRows, hasSearchFilters])

  if (!showDataGrid) {
    return null
  }

  return (
    <div>
      <CustomersDataGrid
        rows={customerRows}
        loading={isLoadingCustomersData}
        columns={columns}
        onFilterOrSortChange={handleFilterOrSortChange}
      />
      <EditCustomer />
      <DeactivateCustomer />
      <AccessRestrictedModal
        open={reactivateModalState.isAccessRestrictedModalOpen}
        onClose={closeReactivateAccessRestrictedModal}
      />
      <FeatureUnavailableModal
        open={reactivateModalState.isFeatureUnavailableModalOpen}
        onClose={closeReactivateFeatureUnavailabledModal}
      />
    </div>
  )
})
