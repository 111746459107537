import { brandColors } from 'dpl/theme/colors'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import { POSITION_TYPE } from '../types'

const DEFAULT_CIRCLE_PROPERTIES: TrimbleMaps.CirclePaint = {
  'circle-radius': 8,
  'circle-color': brandColors.activeBlue,
  'circle-stroke-width': 0,
  'circle-stroke-color': brandColors.white,
  'circle-opacity': 1,
}

export const BASE_STYLES_BY_TYPE: Record<POSITION_TYPE, TrimbleMaps.CirclePaint> = {
  [POSITION_TYPE.PICKUP]: {
    'circle-color': '#FFF',
    'circle-stroke-color': '#00C28A',
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.DROPOFF]: {
    'circle-color': '#00C28A',
  },
  [POSITION_TYPE.SHADOW]: {
    'circle-color': '#000',
    'circle-blur': 1.2,
    'circle-opacity': 0.5,
    'circle-radius': 13,
    'circle-translate': [0, 6],
  },
  [POSITION_TYPE.LAST_PING]: {
    'circle-color': brandColors.activeBlue,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.LAST_PING_SELECTED]: {
    'circle-color': brandColors.gold2,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.PREVIOUS_PING]: {
    'circle-color': brandColors.activeBlue,
    'circle-radius': 5,
    'circle-stroke-width': 1,
    'circle-stroke-color': brandColors.white,
  },
  [POSITION_TYPE.PREVIOUS_PING_SELECTED]: {
    'circle-color': brandColors.gold2,
    'circle-stroke-width': 2,
    'circle-radius': 5,
  },
  [POSITION_TYPE.TRUCK_LOCATION]: {
    'circle-color': brandColors.activeBlue,
    'circle-stroke-width': 2,
    'circle-opacity': 0.6,
    // make circles larger as the user zooms from z12 to z22
    'circle-radius': {
      base: 1.75,
      stops: [
        [12, 10],
        [22, 100],
      ],
    },
  },
  [POSITION_TYPE.TRUCK_LOCATION_INACTIVE]: {
    'circle-color': brandColors.coolGray5,
    'circle-stroke-width': 2,
    'circle-opacity': 0.8,
    // make circles larger as the user zooms from z12 to z22
    'circle-radius': {
      base: 1.75,
      stops: [
        [12, 10],
        [22, 100],
      ],
    },
  },
  [POSITION_TYPE.LAST_PING_DESELECTED]: {
    'circle-color': brandColors.infoLight,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.LAST_PING_LATE]: {
    'circle-color': brandColors.error,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.LAST_PING_LATE_DESELECTED]: {
    'circle-color': brandColors.errorLight,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.LAST_PING_OUTDATED]: {
    'circle-color': brandColors.warning,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.LAST_PING_OUTDATED_DESELECTED]: {
    'circle-color': brandColors.warningLight,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.PING]: {
    'circle-radius': 8,
  },
  [POSITION_TYPE.DELIVERED]: {
    'circle-color': brandColors.coolGray5,
    'circle-stroke-width': 2,
  },
  [POSITION_TYPE.STOPPED]: {
    'circle-color': brandColors.error,
    'circle-stroke-width': 2,
  },
}

export const getPositionPaint = (
  positionType: POSITION_TYPE = POSITION_TYPE.PING
): TrimbleMaps.CirclePaint => {
  const paintProps = get(BASE_STYLES_BY_TYPE, positionType, {})
  return {
    ...DEFAULT_CIRCLE_PROPERTIES,
    ...paintProps,
  }
}

export interface IGetDefaultPositionLayerProps {
  /**
   * Fade positions as they get older in timeline
   * @default false
   */
  fadePositions?: boolean
}

/**
 * @example getMatchExpression(
 *   'type', // property to look at
 *   [
 *     [POSITION_TYPE.LAST_PING, 'orange'], // if true, then 'orange'
 *     [POSITION_TYPE.PING, 'red'] // if true, then 'red'
 *   ],
 *   'blue' // default
 * )
 */
const getMatchExpression = (args: {
  key: string
  cases: [any, any][]
  defaultValue: any
}): TrimbleMaps.Expression => {
  const { key, cases = [], defaultValue } = args || {}
  const flattenedCases = flatten(cases)
  return ['match', ['get', key], ...flattenedCases, defaultValue]
}

export const getDefaultPositionLayerStyle = ({
  fadePositions = false,
}: IGetDefaultPositionLayerProps = {}): TrimbleMaps.CirclePaint => {
  const paintProps: TrimbleMaps.CirclePaint = {
    'circle-opacity': getMatchExpression({
      key: 'type',
      cases: [
        [POSITION_TYPE.SHADOW, 0.5],
        [POSITION_TYPE.TRUCK_LOCATION, 0.6],
        [POSITION_TYPE.TRUCK_LOCATION_INACTIVE, 0.8],
      ],
      defaultValue: 1,
    }),
    'circle-stroke-color': getMatchExpression({
      key: 'type',
      cases: [[POSITION_TYPE.PICKUP, '#00C28A']],
      defaultValue: brandColors.white,
    }),
    'circle-stroke-width': getMatchExpression({
      key: 'type',
      cases: [
        [POSITION_TYPE.LAST_PING, 2],
        [POSITION_TYPE.LAST_PING_SELECTED, 2],
        [POSITION_TYPE.PREVIOUS_PING_SELECTED, 2],
        [POSITION_TYPE.PREVIOUS_PING, 1],
        [POSITION_TYPE.TRUCK_LOCATION, 2],
        [POSITION_TYPE.TRUCK_LOCATION_INACTIVE, 2],
        [POSITION_TYPE.LAST_PING_DESELECTED, 2],
        [POSITION_TYPE.LAST_PING_LATE, 2],
        [POSITION_TYPE.LAST_PING_LATE_DESELECTED, 2],
        [POSITION_TYPE.LAST_PING_OUTDATED, 2],
        [POSITION_TYPE.LAST_PING_OUTDATED_DESELECTED, 2],
        [POSITION_TYPE.DELIVERED, 2],
        [POSITION_TYPE.STOPPED, 2],
      ],
      defaultValue: 0,
    }),
    'circle-radius': getMatchExpression({
      key: 'type',
      cases: [
        [POSITION_TYPE.PREVIOUS_PING, 5],
        [POSITION_TYPE.PREVIOUS_PING_SELECTED, 5],
        [POSITION_TYPE.PING, 8],
      ],
      defaultValue: 8,
    }),
    'circle-color': getMatchExpression({
      key: 'type',
      cases: [
        [POSITION_TYPE.PICKUP, brandColors.white],
        [POSITION_TYPE.DROPOFF, '#00C28A'],
        [POSITION_TYPE.SHADOW, brandColors.black],
        [POSITION_TYPE.LAST_PING, brandColors.activeBlue],
        [POSITION_TYPE.LAST_PING_SELECTED, brandColors.gold2],
        [POSITION_TYPE.PREVIOUS_PING, brandColors.activeBlue],
        [POSITION_TYPE.PREVIOUS_PING_SELECTED, brandColors.gold2],
        [POSITION_TYPE.TRUCK_LOCATION, brandColors.activeBlue],
        [POSITION_TYPE.TRUCK_LOCATION_INACTIVE, brandColors.coolGray5],
        [POSITION_TYPE.LAST_PING_DESELECTED, brandColors.infoLight],
        [POSITION_TYPE.LAST_PING_LATE, brandColors.error],
        [POSITION_TYPE.LAST_PING_LATE_DESELECTED, brandColors.errorLight],
        [POSITION_TYPE.LAST_PING_OUTDATED, brandColors.warning],
        [POSITION_TYPE.LAST_PING_OUTDATED_DESELECTED, brandColors.warningLight],
        [POSITION_TYPE.PING, brandColors.activeBlue],
        [POSITION_TYPE.DELIVERED, brandColors.coolGray5],
        [POSITION_TYPE.STOPPED, brandColors.error],
      ],
      defaultValue: brandColors.gold2,
    }),
  }

  /**
   * Calculates an opacity value of 1-(index*0.05), with a minimum of 0.5
   * @note {index} is passed as a property when configuring the source
   * @see https://developer.trimblemaps.com/maps-sdk/style-spec/expressions/#math
   */
  if (fadePositions) {
    paintProps['circle-opacity'] = ['max', ['-', 1, ['*', ['get', 'index'], 0.15]], 0.35]
  }

  return paintProps
}
