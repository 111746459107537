import { useCallback, useEffect, useState } from 'react'
import { FetchResult } from '@apollo/client'
import { IFileObject } from 'dpl'
import { IImportRfpMutation, useImportRfpMutation } from '../graphql/ImportRfpMutation'
import { IImportRfpStatusQuery, useImportRfpStatusLazyQuery } from '../graphql/ImportRfpStatus'

const POLL_INTERVAL = 5000 // 5 seconds

export interface IImportHandlerProps {
  file: IFileObject
  persist: boolean
  autoGenerateRates: boolean
}

export type TImportHandler = ({
  autoGenerateRates,
  file,
  persist,
}: IImportHandlerProps) => Promise<FetchResult<IImportRfpMutation>>

export interface IUseRfpImporterProps {
  contractRfpVersionId?: string
  onImportSuccess: Function
  currentRoundLaneCount: number
}

export interface IUseRfpImporterResult {
  importHandler: TImportHandler
  checkImportStatusHandler: () => Promise<void>
  importStatus: Nullable<IImportRfpStatusQuery['importContractRfpVersionLanesStatus']>
  importing: boolean
  validating: boolean
  isFirstLoadDone: boolean
  resetImportStatus: () => void
}

export const useRfpImporter = ({
  contractRfpVersionId = '',
  currentRoundLaneCount,
  onImportSuccess,
}: IUseRfpImporterProps): IUseRfpImporterResult => {
  const [importStatus, setImportStatus] =
    useState<Nullable<IImportRfpStatusQuery['importContractRfpVersionLanesStatus']>>(null)
  const [importing, setImporting] = useState(false)
  const [validating, setValidating] = useState(false)
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)

  const [importRfp] = useImportRfpMutation()
  const [fetchImportRfpStatus] = useImportRfpStatusLazyQuery({
    initialFetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: {
      contractRfpVersionId,
    },
  })

  useEffect(() => {
    if (importing || validating) {
      const interval = setInterval(async () => {
        const { data, error } = await fetchImportRfpStatus({
          initialFetchPolicy: 'no-cache',
          fetchPolicy: 'no-cache',
          nextFetchPolicy: 'no-cache',
        })

        if (error) return

        const { importContractRfpVersionLanesStatus } = data ?? {}
        const { completed } = importContractRfpVersionLanesStatus ?? {}

        if (importContractRfpVersionLanesStatus) {
          setImportStatus(importContractRfpVersionLanesStatus)
        }

        if (completed) {
          if (importing) {
            await onImportSuccess()
            // reset import status to allow for new import in case the user reset the round.
            setImportStatus(null)
          }
          setImporting(false)
          setValidating(false)
        }
      }, POLL_INTERVAL)

      return () => clearInterval(interval) // Cleanup on unmount
    }

    return () => {}
  }, [fetchImportRfpStatus, importing, onImportSuccess, validating])

  const checkImportStatusHandler = useCallback(async () => {
    try {
      setIsFirstLoadDone(false)
      const { data } = await fetchImportRfpStatus()
      const { importContractRfpVersionLanesStatus } = data ?? {}

      if (!importContractRfpVersionLanesStatus) {
        /**
         * disregard any action if there's no response
         */
        return
      }

      const { completed, persist } = importContractRfpVersionLanesStatus
      const isImporting = Boolean(persist) && !completed
      const isValidating = !completed && !persist

      if (isImporting || isValidating) {
        /**
         * Resume import/validating loading state, if needed.
         */
        setImporting(isImporting)
        setValidating(isValidating)
      } else {
        setImporting(false)
        setValidating(false)
        await onImportSuccess()
      }
    } finally {
      setIsFirstLoadDone(true)
    }
  }, [fetchImportRfpStatus, onImportSuccess])

  /**
   * useEffect to resume import loading state on initial load.
   */
  useEffect(() => {
    if (contractRfpVersionId && currentRoundLaneCount === 0 && !isFirstLoadDone) {
      checkImportStatusHandler()
    }
  }, [checkImportStatusHandler, contractRfpVersionId, currentRoundLaneCount, isFirstLoadDone])

  const importHandler = useCallback<TImportHandler>(
    async ({ autoGenerateRates, file, persist }) => {
      const result = await importRfp({
        variables: {
          input: {
            contractRfpVersionId,
            file: file.data as string,
            filename: file.file.name,
            persist,
            autoGenerateRates,
          },
        },
      })
      const { data } = result
      const { importContractRfpVersionLanes } = data ?? {}
      const { success } = importContractRfpVersionLanes ?? {}

      if (data) {
        setImportStatus(null)
      }

      if (success) {
        if (persist) {
          setImporting(true)
        } else {
          setValidating(true)
        }
      }

      return result
    },
    [contractRfpVersionId, importRfp]
  )

  const resetImportStatus = useCallback(() => {
    setImportStatus(null)
    setImporting(false)
    setValidating(false)
  }, [])

  return {
    checkImportStatusHandler,
    importHandler,
    importStatus,
    importing,
    validating,
    isFirstLoadDone,
    resetImportStatus,
  }
}
