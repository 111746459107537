import React from 'react'
import { Divider, InputAdornment, makeStyles, Stack } from '../../core'
import { Theme } from '../../core/styles'
import DropdownDownIcon from '../../icons/build/DropdownDownIcon'
import DropdownUpIcon from '../../icons/build/DropdownUpIcon'
import { brandColors } from '../../theme'
import { IconButton } from '../IconButton'
import { ITextFieldProps } from '../TextField'

export interface IStepperArrowsAdornmentProps {
  /**
   * @default 'StepperArrowsAdornment'
   */
  dataTest?: string
  /**
   * Callback fired when up arrow is clicked
   */
  onIncrement: () => void
  /**
   * Callback fired when down arrow is clicked
   */
  onDecrement: () => void
  /**
   * Disables increment button when true
   * @default false
   */
  incrementDisabled: boolean
  /**
   * Disables decrement button when true
   * @default false
   */
  decrementDisabled: boolean
  /**
   * Size of the text field will determine the size of the adornment
   */
  size: ITextFieldProps['size']
}

const useStepperArrowsAdornmentStyles = makeStyles<
  Theme,
  { size: IStepperArrowsAdornmentProps['size']; disabled: boolean }
>(theme => ({
  buttons: {
    border: ({ disabled }) =>
      `1px solid ${disabled ? brandColors.coolGray4 : brandColors.coolGray5}`,
    borderRadius: theme.spacing(0.5),
    '& button': {
      padding: ({ size }) => (size === 'medium' ? theme.spacing(0.375) : theme.spacing(0.125)),
      color: brandColors.coolGray8,
    },
  },
  divider: {
    borderColor: ({ disabled }) => (disabled ? brandColors.coolGray4 : brandColors.coolGray5),
  },
}))

export function StepperArrowsAdornment({
  dataTest = 'StepperArrowsAdornment',
  decrementDisabled = false,
  incrementDisabled = false,
  onDecrement,
  onIncrement,
  size,
}: IStepperArrowsAdornmentProps) {
  const classes = useStepperArrowsAdornmentStyles({
    size,
    disabled: decrementDisabled && incrementDisabled,
  })

  return (
    <InputAdornment position='end' data-test={dataTest}>
      <Stack className={classes.buttons}>
        <IconButton
          aria-label='Increment value'
          dataTest='IncrementArrowButton'
          onClick={onIncrement}
          Icon={DropdownUpIcon}
          disabled={incrementDisabled}
          color='secondary'
          tabIndex={-1}
        />
        <Divider className={classes.divider} />
        <IconButton
          aria-label='Decrement value'
          dataTest='DecrementArrowButton'
          onClick={onDecrement}
          Icon={DropdownDownIcon}
          disabled={decrementDisabled}
          color='secondary'
          tabIndex={-1}
        />
      </Stack>
    </InputAdornment>
  )
}
