import React, { useCallback } from 'react'
import { DiscoveredOrganization } from '@stytch/vanilla-js'
import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText } from 'dpl/core'
import { RightArrowIcon } from 'dpl/icons/build'
import { useOrganizationRedirect } from './useOrganizationRedirect'

const VALID_MEMBERSHIPS = ['active_member', 'invited_member']

export interface IDiscoveredOrganizationListItemProps {
  discoveredOrganization: DiscoveredOrganization
  onOrganizationSelect: (discoveredOrganization: DiscoveredOrganization) => Promise<void> | void
}

export function DiscoveredOrganizationListItem({
  discoveredOrganization,
  onOrganizationSelect,
}: IDiscoveredOrganizationListItemProps) {
  const { membership, organization } = discoveredOrganization
  const {
    organization_id: organizationId,
    organization_logo_url: organizationLogoUrl,
    organization_name: organizationName,
  } = organization
  const { type } = membership

  const { redirect } = useOrganizationRedirect(discoveredOrganization, onOrganizationSelect)
  const handleClick = useCallback(() => {
    redirect()
  }, [redirect])

  return (
    <ListItem disablePadding data-cy={organizationId}>
      <ListItemButton disabled={!VALID_MEMBERSHIPS.includes(type)} onClick={handleClick}>
        <ListItemAvatar>
          <Avatar alt={organizationName} variant='square' src={organizationLogoUrl}>
            {/* Fallback to first letter of organization's name */}
            {organizationName.charAt(0)}
          </Avatar>
        </ListItemAvatar>

        <ListItemText primary={organizationName} />

        <RightArrowIcon />
      </ListItemButton>
    </ListItem>
  )
}
