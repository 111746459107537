import React from 'react'
import { IStop } from '#types/graphqlTypes'
import { currencyFormatter } from '#utils/currencyFormatter'
import LinearProgress from 'dpl/components/LinearProgress'
import { Box, Card, CardHeader, makeStyles, Rating, Typography, CardContent } from 'dpl/core'
import StarIcon from 'dpl/icons/build/StarIcon'
import { brandColors, avatarColors } from 'dpl/theme/colors'
import CarrierCostGraphSvg from './carrier-cost-history-graph.svg?react'
import DayToggleSvg from './day-toggle.svg?react'
import ZipMarketToggleSvg from './zip-market-toggle.svg?react'

const RATING_VALUE = 3.7
const LOADS_COVERED_VALUE = 3.1
const CARRIER_DIVERSITY_VALUE = 3.8

const ORIGIN_CARRIERS = [
  {
    name: 'West Express',
    rate: 1500,
  },
  {
    name: 'Unit Trucking LLC',
    rate: 1475,
  },
]

const DESTINATION_CARRIERS = [
  {
    name: 'White Bear Inc',
    rate: 1380,
  },
  {
    name: 'Freight Express',
    rate: 1400,
  },
  {
    name: 'Twenty Transport LLC',
    rate: 1600,
  },
]

const getProgress = ({ max, value }: Record<string, number>): number => {
  const progress = (value * 100) / max
  return progress
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  loadsCoveredProgress: {
    '&.MuiLinearProgress-root': {
      backgroundColor: brandColors.coolGray3,
      '& .MuiLinearProgress-bar': {
        backgroundColor: avatarColors.orange,
      },
    },
  },
  carrierDiversityProgress: {
    '&.MuiLinearProgress-root': {
      backgroundColor: brandColors.coolGray3,
    },
  },
  svgGraph: {
    width: '100%',
    height: 'auto',
  },
}))

interface LaneScorecardProps {
  stops: Pick<IStop, 'city' | 'stateCode'>[]
}

export function LaneScorecard({ stops = [] }: LaneScorecardProps) {
  const classes = useStyles()
  const { city: originCity, stateCode: originStateCode } = stops[0] ?? {}
  const { city: destinationCity, stateCode: destinationStateCode } = stops[stops.length - 1] ?? {}

  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={
          <Box display='flex' alignItems='center' gap={0.5}>
            <StarIcon />
            <Typography variant='overline'>Your lane Scorecard</Typography>
          </Box>
        }
      />
      <CardContent>
        <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
          <Typography variant='h1'>{RATING_VALUE}</Typography>
          <Box display='flex' flexDirection='column' gap={1}>
            <Rating name='read-only' value={RATING_VALUE} precision={0.1} readOnly />
            <Typography variant='caption' color='textSecondary'>
              Based on the below data
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          pt={3}
          pb={1}>
          <Typography variant='body1'>Loads Covered</Typography>
          <Typography variant='h4'>{LOADS_COVERED_VALUE}</Typography>
        </Box>
        <LinearProgress
          className={classes.loadsCoveredProgress}
          variant='determinate'
          value={getProgress({ value: LOADS_COVERED_VALUE, max: 5 })}
        />
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          py={2}>
          <Typography variant='overline'>Cost History</Typography>
          <DayToggleSvg />
        </Box>
        <CarrierCostGraphSvg className={classes.svgGraph} />
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          pb={1}
          pt={2}>
          <Typography variant='body1'>Carrier Diversity</Typography>
          <Typography variant='h4'>{CARRIER_DIVERSITY_VALUE}</Typography>
        </Box>
        <LinearProgress
          className={classes.carrierDiversityProgress}
          variant='determinate'
          value={getProgress({ value: CARRIER_DIVERSITY_VALUE, max: 5 })}
        />
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          pt={3}
          pb={2}>
          <Typography variant='overline'>Active Carriers</Typography>
          <ZipMarketToggleSvg />
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          pb={2}>
          <Typography variant='subtitle1'>
            PU - {originCity}, {originStateCode}
          </Typography>
          <Typography variant='subtitle1'>Last Ran Rate</Typography>
        </Box>
        {ORIGIN_CARRIERS.map(({ name, rate }, index) => (
          <Box
            key={name}
            bgcolor={index % 2 === 0 ? brandColors.coolGray1 : ''}
            display='flex'
            flexDirection='row'
            gap={1}
            alignItems='center'
            justifyContent='space-between'
            p={0.5}>
            <Typography variant='body1'>{name}</Typography>
            <Typography variant='body1'>{currencyFormatter(rate)}</Typography>
          </Box>
        ))}
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          py={2}>
          <Typography variant='subtitle1'>
            DO - {destinationCity}, {destinationStateCode}
          </Typography>
          <Typography variant='subtitle1'>Last Ran Rate</Typography>
        </Box>
        {DESTINATION_CARRIERS.map(({ name, rate }, index) => (
          <Box
            key={name}
            bgcolor={index % 2 === 0 ? brandColors.coolGray1 : ''}
            display='flex'
            flexDirection='row'
            gap={1}
            alignItems='center'
            justifyContent='space-between'
            p={0.5}>
            <Typography variant='body1'>{name}</Typography>
            <Typography variant='body1'>{currencyFormatter(rate)}</Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  )
}
