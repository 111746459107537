import React from 'react'
import SvgIcon from '../components/SvgIcon'
import { ClockAlertOutlineSvg } from '../icons'

function ClockAlertOutlineIcon(props) {
  return (
    <SvgIcon
      className='RogersSvgIcon-ClockAlertOutline'
      component={ClockAlertOutlineSvg}
      {...props}
    />
  )
}

export default ClockAlertOutlineIcon
