import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import {
  ElementDragType,
  BaseEventPayload,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import { Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import { brandColors } from '../../theme/colors'
import { DraggableListContext, IDraggableListContextValue, TDraggableListItem } from './components'

export interface IOnDropProps<T> {
  reorderedList: TDraggableListItem<T>[]
  sourceIndex: number
  targetIndex: number
}
export interface IDraggableListProps<T> {
  /**
   * List of items to be shown in DraggableList
   */
  items: TDraggableListItem<T>[]
  dataTest?: string
  onDrop: ({ reorderedList, sourceIndex, targetIndex }: IOnDropProps<T>) => void
}

function DraggableList<T>({
  children,
  dataTest = 'DraggableList',
  items,
  onDrop,
}: PropsWithChildren<IDraggableListProps<T>>) {
  // Used to separate instances of DraggableList from others so you can't drop onto different instances
  const instanceId = Symbol('draggable-list-instance')

  const reorderItem = useCallback(
    ({
      closestEdgeOfTarget,
      indexOfTarget,
      startIndex,
    }: {
      startIndex: number
      indexOfTarget: number
      closestEdgeOfTarget: Edge | null
    }) => {
      const destinationIndex = getReorderDestinationIndex({
        startIndex,
        closestEdgeOfTarget,
        indexOfTarget,
        axis: 'vertical',
      })

      if (destinationIndex === startIndex) {
        // If there would be no change, we skip the update
        return
      }

      const reorderedList = reorder({
        list: items,
        startIndex,
        finishIndex: destinationIndex,
      })

      onDrop({ reorderedList, sourceIndex: startIndex, targetIndex: destinationIndex })
    },
    [items, onDrop]
  )

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return source.data.instanceId === instanceId
      },
      onDrop({ location, source }: BaseEventPayload<ElementDragType>) {
        const target = location.current.dropTargets[0]
        if (!target) {
          return
        }

        const sourceData = source.data
        const targetData = target.data

        const indexOfSource = items.findIndex(item => item.id === sourceData.id)
        const indexOfTarget = items.findIndex(item => item.id === targetData.id)
        if (indexOfTarget < 0 || indexOfSource < 0 || indexOfSource === indexOfTarget) {
          return
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData)

        reorderItem({ closestEdgeOfTarget, indexOfTarget, startIndex: indexOfSource })

        // Flashes background of dropped item
        const element = document.querySelector(`[data-draggable-id="${sourceData.id}"]`)
        if (element instanceof HTMLElement) {
          element.animate([{ backgroundColor: brandColors.skyBlue0 }, {}], {
            duration: 1000,
            iterations: 1,
          })
        }
      },
    })
  }, [instanceId, items, reorderItem])

  const listLength = useMemo(() => items.length, [items.length])

  const contextValue: IDraggableListContextValue = useMemo(() => {
    return {
      reorderItem,
      instanceId,
      listLength,
    }
  }, [reorderItem, instanceId, listLength])

  return (
    <DraggableListContext.Provider value={contextValue}>
      <div data-test={dataTest}>{children}</div>
    </DraggableListContext.Provider>
  )
}

export default DraggableList
