import { IRuleTypeEnum } from '#types/rates'
import { RULE_TYPE_LABELS } from '../../constants'

interface IFormatMarginStrategyArgs {
  marginRuleType: Nullable<IRuleTypeEnum>
  marginBasePercentage: Nullable<number>
  marginAdditivePercentage: Nullable<number>
}

export const formatMarginStrategy = ({
  marginAdditivePercentage,
  marginBasePercentage,
  marginRuleType,
}: IFormatMarginStrategyArgs) => {
  if (marginRuleType === IRuleTypeEnum.doNotBid) return RULE_TYPE_LABELS[IRuleTypeEnum.doNotBid]
  if (marginRuleType === IRuleTypeEnum.base) {
    return marginBasePercentage ? `${marginBasePercentage}% Premium` : ''
  }
  if (marginRuleType === IRuleTypeEnum.additive) {
    if (!marginAdditivePercentage) return ''
    const formattedAdditiveStrategy = `${marginAdditivePercentage > 0 ? '+' : ''}${marginAdditivePercentage}% Premium`
    return formattedAdditiveStrategy
  }
  return ''
}
