import { STATES } from 'dpl/constants/countriesStates'

export const US_STATE_OPTIONS = STATES.US.map(({ abbr, name }) => ({
  stateCode: abbr,
  id: name,
}))

export const CA_STATE_OPTIONS = STATES.CA.map(({ abbr, name }) => ({
  stateCode: abbr,
  id: name,
}))
