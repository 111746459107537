import React, { memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { ISortOrder } from '#types/graphqlTypes'
import DataGrid from 'dpl/components/DataGrid'
import { makeStyles } from 'dpl/core'
import { QueryParamProvider } from 'dpl/providers/QueryParamProvider'
import { useFlagsContext } from 'flags'
import { ROUTING_GUIDE_LANE_COLUMNS } from './constants/routingGuideLaneColumns'
import { FILTERS_OPTIONS_CONFIG } from './constants/routingGuideLaneDataGridFiltersOptionsConfig'
import { SORT_OPTIONS_CONFIG } from './constants/routingGuideLaneDataGridSortOptions'
import { IRoutingGuideLanesDataGrid } from './types'

type TRoutingGuideLanesDataGridPickedProps =
  | 'loading'
  | 'onFilterOrSortChange'
  | 'rows'
  | 'onPaginationModelChange'
  | 'paginationModel'
  | 'rowCount'

export interface IRoutingGuideLanesDataGridProps
  extends Pick<IRoutingGuideLanesDataGrid, TRoutingGuideLanesDataGridPickedProps> {}

const useRoutingGuideLanesDataGridStyles = makeStyles(() => ({
  listContainer: {
    width: '100%',
  },
}))

export const RoutingGuideLanesDataGrid = memo<IRoutingGuideLanesDataGridProps>(
  ({ loading, onFilterOrSortChange, onPaginationModelChange, paginationModel, rowCount, rows }) => {
    const { isFlagEnabled } = useFlagsContext()
    const isRoutingGuideLaneSearchandFiltersEnabled = isFlagEnabled(
      'routing_guide_lane_search_filters'
    )

    const classes = useRoutingGuideLanesDataGridStyles()
    const navigate = useNavigate()

    /**
     * Handles the click event on a data grid row.
     *
     * @param row - The data grid row object containing an `id` property.
     */
    const handleRowClick = useCallback<NonNullable<IRoutingGuideLanesDataGrid['onRowClick']>>(
      params => {
        const { row } = params || {}
        const { id } = row || {}
        navigate(`${PROTECTED_PATHS.routingGuideLanes}/${id}/carriers`)
      },
      [navigate]
    )

    const filtersOptions = useMemo(
      () => (isRoutingGuideLaneSearchandFiltersEnabled ? FILTERS_OPTIONS_CONFIG : undefined),
      [isRoutingGuideLaneSearchandFiltersEnabled]
    )

    const sortOptions = useMemo(
      () => (isRoutingGuideLaneSearchandFiltersEnabled ? SORT_OPTIONS_CONFIG : undefined),
      [isRoutingGuideLaneSearchandFiltersEnabled]
    )

    return (
      <div className={classes.listContainer}>
        <QueryParamProvider>
          <DataGrid
            filtersConfig={filtersOptions}
            dataTest='routing-guide-lanes-data-grid'
            rows={rows}
            sortOptions={sortOptions}
            defaultSortValue={ISortOrder.desc}
            onFilterOrSortChange={onFilterOrSortChange}
            columns={ROUTING_GUIDE_LANE_COLUMNS}
            loading={loading}
            onRowClick={handleRowClick}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[50]}
            paginationMode='server'
            rowCount={rowCount}
            paginationModel={paginationModel}
            visibleFiltersLimit={3}
          />
        </QueryParamProvider>
      </div>
    )
  }
)
