import { emptyStringToNull } from '#routes/spot-rates-control-panel/components/AdjustmentRuleFormSidePanel/schema/utils'
import * as yup from 'yup'
import { RfpLaneInfoSchema } from './RfpLaneInfoSchema'
import { RfpRatesSchema } from './RfpRatesSchema'

export const RfpAddLaneSchema = yup
  .object({
    externalId: yup.string().transform(emptyStringToNull).nullable(),
  })
  .concat(RfpRatesSchema.concat(RfpLaneInfoSchema))

export interface IRfpAddLaneSchema extends yup.InferType<typeof RfpAddLaneSchema> {}
