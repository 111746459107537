import { isNaN } from 'lodash/fp'

export const emptyStringToNull = (value: string, originalValue: string | boolean) => {
  if (isNaN(value)) {
    return null
  }
  if (originalValue === '' || originalValue === false) {
    return null
  }
  return value
}
