import React from 'react'
import { IEquipmentKeys } from '#types/graphqlTypes'
import { usePermissionsContext } from 'auth/common/context'
import { AccessRestrictedModal, IconButton, Tooltip } from 'dpl'
import { makeStyles, Typography } from 'dpl/core'
import { EditIcon, InfoIcon, LaneArrowIcon, LocationPinIcon } from 'dpl/icons'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { IRfpLaneFragment } from '../../../LaneList/graphql/RfpLane'
import { EQUIPMENT_MAPPER } from '../../../LaneList/grid'
import { VolumeDescription } from './VolumeDescription'

export interface ILaneInformationProps {
  lane: IRfpLaneFragment
  onEdit: () => void
  readOnly: boolean
}

const useStyles = makeStyles(theme => ({
  centerElements: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    flexWrap: 'wrap',
  },
  tooltipIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
  laneInfo: {
    marginTop: theme.spacing(2),
  },
  subInfo: {
    marginTop: theme.spacing(1),
  },
  root: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  editButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

export function LaneInformation({ lane, onEdit, readOnly }: ILaneInformationProps) {
  const classes = useStyles()
  const { userPermissions } = usePermissionsContext()
  const { createToggleHandler, modalState, toggleModal } = useModalState({
    restrictedModal: false,
  })

  const dropType = [
    lane?.isPickupDropTrailer && 'Pickup',
    lane?.isDeliveryDropTrailer && 'Delivery',
  ]
    .filter(Boolean)
    .join(' & ')

  const { icon: EquipmentIcon, label: equipmentLabel } = lane?.equipment.key
    ? EQUIPMENT_MAPPER[lane?.equipment.key as IEquipmentKeys]
    : EQUIPMENT_MAPPER.dry_van

  return (
    <div className={classes.root}>
      <Typography variant='overline' color='textSecondary' className={classes.centerElements}>
        <LocationPinIcon /> Lane Information
      </Typography>
      {!readOnly && (
        <IconButton
          className={classes.editButton}
          Icon={EditIcon}
          size='xlarge'
          color='secondary'
          onClick={() => {
            if (!userPermissions['contract_rfp.update_rfp_version_lanes']) {
              toggleModal('restrictedModal', true)
              return
            }
            onEdit()
          }}
        />
      )}
      <div className={classes.laneInfo}>
        <div className={classes.centerElements}>
          <Typography variant='h4'>
            {lane?.pickupLocation.city}, {lane?.pickupLocation.stateCode}
          </Typography>
          <Typography variant='subtitle2' color='textSecondary'>
            ({lane?.pickupLocation.postalCode})
          </Typography>
          <InfoIcon color='coolGray5' />
          <LaneArrowIcon className={classes.arrow} size='large' />
        </div>
        <div className={classes.centerElements}>
          <Typography variant='h4'>
            {lane?.deliveryLocation.city}, {lane?.deliveryLocation.stateCode}
          </Typography>
          <Typography variant='subtitle2' color='textSecondary'>
            ({lane?.deliveryLocation.postalCode})
          </Typography>
          <InfoIcon color='coolGray5' />
          <Tooltip title={equipmentLabel}>
            <span className={classes.tooltipIcon}>
              <EquipmentIcon size='large' />
            </span>
          </Tooltip>
        </div>
        <div className={classes.subInfo}>
          <div className={classes.centerElements}>
            {lane?.customMiles ? (
              <Typography variant='subtitle2' color='textSecondary'>
                Custom Distance: {Math.round(lane.customMiles)} Miles
              </Typography>
            ) : (
              <>
                <Typography variant='subtitle2' color='textSecondary'>
                  Calculated Distance: {Math.round(lane?.calculatedMiles ?? 0)} Miles
                </Typography>
                <Tooltip title='Distance is calculated based on ZIP to ZIP.'>
                  <span className={classes.tooltipIcon}>
                    <InfoIcon color='coolGray5' />
                  </span>
                </Tooltip>
              </>
            )}

            <Typography variant='subtitle2' color='textSecondary'>
              ·
            </Typography>
            {dropType && (
              <>
                <Typography variant='subtitle2' color='textSecondary'>
                  Drop Trailer: {dropType}
                </Typography>
                <Typography variant='subtitle2' color='textSecondary'>
                  ·
                </Typography>
              </>
            )}
            <VolumeDescription lane={lane} />
            {lane?.isTeam && (
              <>
                <Typography variant='subtitle2' color='textSecondary'>
                  ·
                </Typography>
                <Typography variant='subtitle2' color='textSecondary'>
                  Team Required
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
      {modalState.restrictedModal && (
        <AccessRestrictedModal onClose={createToggleHandler('restrictedModal', false)} open />
      )}
    </div>
  )
}
