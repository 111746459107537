import { useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MarginSettingsSchema, TMarginSettingsSchema } from '../schema'

interface IUseMarginSettingsFormProps
  extends Omit<UseFormOptions<TMarginSettingsSchema>, 'resolver'> {}

export const useMarginSettingsForm = ({
  defaultValues = {},
  ...props
}: IUseMarginSettingsFormProps) => {
  return useForm<TMarginSettingsSchema>({
    resolver: yupResolver(MarginSettingsSchema),
    defaultValues: MarginSettingsSchema.cast(defaultValues),
    ...props,
  })
}
