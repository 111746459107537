import { createContext } from 'react'
import { DEFAULT_FORM_STEPS_STATE } from '../constants'
import { TAdjustmentRuleFormSteps } from '../types'

export const AdjustmentRuleFormStepsContext = createContext<{
  activeStepIndex: number
  formSteps: TAdjustmentRuleFormSteps
  continueClickHandler: (newStep: number) => void
  editClickHandler: (newStep: number, currentStep: number) => void
}>({
  activeStepIndex: 0,
  formSteps: DEFAULT_FORM_STEPS_STATE,
  continueClickHandler: () => {},
  editClickHandler: () => {},
})
