import React, { useCallback, useState, SetStateAction, Dispatch, useMemo } from 'react'
import { RateDifference } from '#components/RateDifference'
import { RateEstimateSlider, IRateSliderProps } from '#components/RateSlider'
import { RatesDoNotBidMessage } from '#components/RatesDoNotBidMessage'
import {
  IRatesEstimatedCarrierRateAccordionProps,
  RatesEstimatedCarrierRateAccordion,
} from '#components/RatesEstimatedCarrierRateAccordion'
import { RatesEstimatedMarginAccordion } from '#components/RatesEstimatedMarginAccordion'
import { useAnalytics } from '#hooks/useAnalytics'
import { IEstimate } from '#types/rates'
import { currencyFormatter } from '#utils/currencyFormatter'
import { getAdjustmentRulesByType } from '#utils/getAdjustmentRulesByType/getAdjustmentRulesByType'
import { sumCurrency } from '#utils/sumCurrency'
import NumericFormat from 'dpl/components/NumericFormat'
import { TextFieldGroup } from 'dpl/components/TextFieldGroup'
import { Tooltip } from 'dpl/components/Tooltip'
import { CardContent, makeStyles, Typography, Slide, SlideProps, Stack } from 'dpl/core'
import InfoIcon from 'dpl/icons/build/InfoIcon'
import { brandColors } from 'dpl/theme/colors'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { WinPercentageEstimate } from '../../../demo-enhancements/WinPercentageEstimate'
import { TRatesResponse } from '../../../types'

const useSpotQuoteCardContentStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 3, 3, 3),
      '&:last-child': {
        paddingBottom: theme.spacing(3),
      },
    },
  },
  lineItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  widgetControls: {
    marginTop: theme.spacing(2),
    backgroundColor: brandColors.coolGray1,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  dottedSeparator: {
    borderBottom: `1px dotted ${brandColors.coolGray5}`,
    top: '-2px',
    position: 'relative',
    flexBasis: '100%',
  },
  rateTooltipIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  slider: {
    marginTop: theme.spacing(3),
  },
  premiumInputs: {
    backgroundColor: brandColors.white,
    marginTop: theme.spacing(3),
  },
}))

export interface ISpotQuoteCardContentProps {
  /**
   * Callback to handle when the user overrides the carrier rate adjustment rules.
   */
  onCarrierRateRulesOverride: (isRulesOverridden: boolean) => void
  /**
   * Callback to handle when the user overrides the do not bid adjustment rules.
   */
  onDoNotBidRulesOverride: (isRulesOverridden: boolean) => void
  /**
   * Callback to handle when the user overrides the margin premium adjustment rules.
   */
  onMarginPremiumRulesOverride: (isRulesOverridden: boolean) => void
  /**
   * The status of the quote.
   */
  quoteStatus?: Nullable<string>
  /**
   * The rate adjustments.
   */
  rateAdjustments: TRatesResponse['rateAdjustments']
  /**
   * The rate estimate.
   */
  rateEstimate: IEstimate
  /**
   * The callback to handle when the user updates the rate estimate.
   */
  setRateEstimate: Dispatch<SetStateAction<IEstimate>>
  /**
   * The spot cost model.
   */
  spotCostModel: TRatesResponse['spotCostModel']
}

export function SpotQuoteCardContent({
  onCarrierRateRulesOverride,
  onDoNotBidRulesOverride,
  onMarginPremiumRulesOverride,
  quoteStatus,
  rateAdjustments,
  rateEstimate,
  setRateEstimate,
  spotCostModel,
}: ISpotQuoteCardContentProps) {
  const { isFlagEnabled } = useFlagsContext()
  const isSpotRatesRulesVisEnabled = isFlagEnabled('spot_rates_rules_visibility')

  const { trackEvent } = useAnalytics()
  const classes = useSpotQuoteCardContentStyles()
  const spotQuoteValue = sumCurrency([rateEstimate.carrierRate, rateEstimate.marginValue])
  const isQuote = !!quoteStatus
  const [slideDirection, setSlideDirection] = useState<SlideProps['direction']>('up')

  const {
    appliedRules,
    matchedRules,
    maxCostLimitReached: isAboveMaxCost,
    minCostLimitReached: isBelowMinCost,
    organizationSetting,
    rawCostPercentile,
    rawCostShiftPercentage,
    rawMarginPremium,
    suggestedCostPercentile,
    suggestedCostShiftPercentage,
    suggestedMarginPremium,
  } = rateAdjustments || {}
  const { activeSettingVersion } = organizationSetting || {}
  const { defaultCostPercentile, defaultMarginPremium } = activeSettingVersion || {}

  const {
    costRules: appliedCostRules,
    doNotBidRules,
    marginRules: appliedMarginRules,
  } = useMemo(() => getAdjustmentRulesByType(appliedRules || []), [appliedRules])

  const doNotBidRule = doNotBidRules?.[0]
  const { reason: doNotBidReason } = doNotBidRule || {}
  const [isDoNotBid, setIsDoNotBid] = useState(!!doNotBidRule)

  const { costRules: matchedCostRules, marginRules: matchedMarginRules } = useMemo(
    () => getAdjustmentRulesByType(matchedRules || []),
    [matchedRules]
  )

  const overrideDnbHandler = useCallback(() => {
    setIsDoNotBid(false)
    onDoNotBidRulesOverride(true)
  }, [onDoNotBidRulesOverride])

  const percentileByValue = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { miles: _, ...percentiles } = spotCostModel ?? {}
    const swapped = Object.entries(percentiles).map(([key, value]) => [value, key])

    return Object.fromEntries(swapped)
  }, [spotCostModel])

  const rateChangeHandler: IRateSliderProps['onChange'] = useCallback(
    (_, carrierRate) => {
      setRateEstimate(({ marginPercentage }) => ({
        carrierRate,
        marginPercentage,
        marginValue: marginPercentage * carrierRate,
      }))
      setSlideDirection(carrierRate > rateEstimate.carrierRate ? 'down' : 'up')
      trackEvent('Rates Tool', 'ADJUST_RATE_VALUE', {
        field: `Slider ${percentileByValue[carrierRate]} Percentile`,
        value: carrierRate,
      })
    },
    [percentileByValue, rateEstimate.carrierRate, setRateEstimate, trackEvent]
  )

  const accordionRateChangeHandler = useCallback<
    NonNullable<IRatesEstimatedCarrierRateAccordionProps['onSliderChange']>
  >(
    carrierRate => {
      setRateEstimate(({ marginPercentage }) => ({
        carrierRate,
        marginPercentage,
        marginValue: marginPercentage * carrierRate,
      }))
      setSlideDirection(carrierRate > rateEstimate.carrierRate ? 'down' : 'up')
      trackEvent('Rates Tool', 'ADJUST_RATE_VALUE', {
        field: `Slider ${percentileByValue[carrierRate]} Percentile`,
        value: carrierRate,
      })
    },
    [percentileByValue, rateEstimate.carrierRate, setRateEstimate, trackEvent]
  )

  const marginPercentageHandler = useCallback(
    percentage => {
      const marginPercentage = (percentage ?? 0) / 100

      setRateEstimate(({ carrierRate }) => ({
        carrierRate,
        marginPercentage,
        marginValue: marginPercentage * carrierRate,
      }))
      setSlideDirection(marginPercentage > rateEstimate.marginPercentage ? 'down' : 'up')
      trackEvent('Rates Tool', 'ADJUST_RATE_VALUE', {
        field: 'Margin Percentage Input',
        value: marginPercentage,
      })
    },
    [rateEstimate.marginPercentage, setRateEstimate, trackEvent]
  )

  const marginAmountHandler = useCallback(
    amount => {
      const marginValue = amount ?? 0

      setRateEstimate(({ carrierRate }) => ({
        carrierRate,
        marginPercentage: marginValue / carrierRate,
        marginValue,
      }))
      setSlideDirection(marginValue > rateEstimate.marginValue ? 'down' : 'up')
      trackEvent('Rates Tool', 'ADJUST_RATE_VALUE', {
        field: 'Margin Amount Input',
        value: marginValue,
      })
    },
    [rateEstimate.marginValue, setRateEstimate, trackEvent]
  )

  return (
    <CardContent className={classes.cardContent}>
      {!!doNotBidRule && (
        <Stack marginBottom={2}>
          <RatesDoNotBidMessage
            reason={doNotBidReason || ''}
            stacked={isDoNotBid}
            showCTA={isDoNotBid}
            onClick={overrideDnbHandler}
          />
        </Stack>
      )}
      <div className={classes.lineItems}>
        <div className={classes.lineItem}>
          <Typography variant='h3' fontWeight={500}>
            Spot Quote
          </Typography>
          {isDoNotBid && (
            <Typography variant='h4' color={brandColors.coolGray4}>
              No Rate Available
            </Typography>
          )}
          {!isDoNotBid && (
            <Typography variant='h3' fontWeight={500} overflow='hidden'>
              <Slide key={spotQuoteValue} in direction={slideDirection} timeout={300}>
                <span data-test='spot-quote-value'>{spotQuoteValue}</span>
              </Slide>
            </Typography>
          )}
        </div>
        {!isSpotRatesRulesVisEnabled && (
          <>
            <div className={classes.lineItem}>
              <Typography variant='subtitle2' color='textSecondary'>
                Est. Carrier Rate (including fuel)
              </Typography>
              <Typography variant='subtitle2' color='textSecondary' data-test='carrier-rate-value'>
                {currencyFormatter(rateEstimate.carrierRate)}
              </Typography>
            </div>
            <div className={classes.lineItem}>
              <Typography variant='subtitle2' color='textSecondary'>
                Est. Margin
              </Typography>
              <Typography variant='subtitle2' color='textSecondary'>
                <RateDifference value={rateEstimate.marginValue} dataTest='margin-value' />
              </Typography>
            </div>
          </>
        )}
      </div>
      {isSpotRatesRulesVisEnabled && (
        <Stack pt={1.5} spacing={1.5}>
          <RatesEstimatedCarrierRateAccordion
            isDoNotBid={isDoNotBid}
            isAboveMaxCost={isAboveMaxCost || false}
            isBelowMinCost={isBelowMinCost || false}
            costModel={spotCostModel}
            sliderValue={rateEstimate.carrierRate}
            onSliderChange={accordionRateChangeHandler}
            defaultCostPercentile={Number(defaultCostPercentile)}
            rawCostPercentile={rawCostPercentile || 0}
            rawCostShiftPercentage={rawCostShiftPercentage || 0}
            suggestedCostPercentile={suggestedCostPercentile || 0}
            suggestedCostShiftPercentage={suggestedCostShiftPercentage || 0}
            appliedRules={appliedCostRules}
            matchedRules={matchedCostRules}
            isReadOnly={isQuote}
            onRulesOverride={onCarrierRateRulesOverride}
          />
          <RatesEstimatedMarginAccordion
            rateEstimate={rateEstimate}
            onAmountChange={marginAmountHandler}
            onPercentageChange={marginPercentageHandler}
            isReadOnly={isQuote}
            appliedRules={appliedMarginRules}
            matchedRules={matchedMarginRules}
            isDoNotBid={isDoNotBid}
            suggestedMarginPremium={suggestedMarginPremium}
            defaultMarginPremium={defaultMarginPremium || 0}
            rawMarginPremium={rawMarginPremium}
            onRulesOverride={onMarginPremiumRulesOverride}
          />
        </Stack>
      )}
      {!isSpotRatesRulesVisEnabled && (
        <div className={classes.widgetControls}>
          <Typography variant='subtitle1' marginBottom={2}>
            Adjust the rate and premium to see how they impact the quote
          </Typography>
          <div className={classes.lineItem}>
            <Typography variant='caption' color='textSecondary' whiteSpace='nowrap'>
              Est. Carrier Rate
            </Typography>
            <div className={classes.dottedSeparator} />
            <Typography
              variant='caption'
              color='textSecondary'
              display='flex'
              data-test='widget-carrier-rate-value'>
              {currencyFormatter(rateEstimate.carrierRate)}
              <Tooltip
                color='dark'
                title='Aggressive: 20th percentile, Balanced: 50th percentile, and Conservative: 80th percentile.'>
                <div className={classes.rateTooltipIcon}>
                  <InfoIcon size='small' color='coolGray5' />
                </div>
              </Tooltip>
            </Typography>
          </div>
          <div className={classes.slider}>
            <RateEstimateSlider
              costModel={spotCostModel}
              onChange={rateChangeHandler}
              disabled={isQuote}
              value={rateEstimate.carrierRate}
            />
          </div>
          <div className={classes.premiumInputs}>
            <TextFieldGroup
              Component={NumericFormat}
              label='Premium'
              fields={[
                {
                  value: rateEstimate.marginPercentage * 100,
                  name: 'percentage',
                  allowNegative: true,
                  decimalScale: 2,
                  suffix: '%',
                  placeholder: '%',
                  allowLeadingZeros: false,
                  type: 'tel',
                  onValueChange: ({ floatValue }) => {
                    marginPercentageHandler(floatValue)
                  },
                  dataTest: 'margin-percentage-input',
                  disabled: isQuote,
                },
                {
                  value: rateEstimate.marginValue,
                  name: 'amount',
                  allowNegative: true,
                  decimalScale: 2,
                  prefix: '$',
                  placeholder: '$',
                  allowLeadingZeros: false,
                  type: 'tel',
                  onValueChange: ({ floatValue }) => {
                    marginAmountHandler(floatValue)
                  },
                  dataTest: 'margin-value-input',
                  fixedDecimalScale: true,
                  disabled: isQuote,
                },
              ]}
            />
          </div>
        </div>
      )}
      {/* Demo Enhancement */}
      <WinPercentageEstimate marginPct={rateEstimate.marginPercentage} />
    </CardContent>
  )
}
