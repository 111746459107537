import React, { memo, SyntheticEvent } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { Checkbox, ICheckboxProps } from 'dpl/components/Checkbox'

type IBaseControllerProps = ControllerProps<React.ComponentType<ICheckboxProps>>

export interface IControlledCheckboxProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  CheckboxProps?: Partial<ICheckboxProps>
}

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledCheckbox
 *   control={control}
 *   defaultValue={defaultValue}
 *   name={name}
 *   CheckboxProps={{
 *     label: 'Unit type',
 *     options: unitTypeOptions,
 *  }}
 * />
 * ```
 */
export const ControlledCheckbox = memo<IControlledCheckboxProps>(
  ({ control, name, defaultValue = false, CheckboxProps = {} }) => {
    const {
      className,
      dataTest,
      disabled = false,
      indeterminate,
      label,
      onChange: onChangeProp,
      required,
      // rest of the available props from HTMLInputElement
      ...props
    } = CheckboxProps || {}
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={Boolean(defaultValue)}
        render={({ onChange, value }) => {
          const changeHandler = (val: SyntheticEvent<HTMLInputElement>) => {
            onChange(val.currentTarget?.checked)

            if (onChangeProp) {
              onChangeProp(val)
            }
          }

          return (
            <Checkbox
              checked={value}
              indeterminate={indeterminate}
              defaultChecked={Boolean(defaultValue)}
              name={name}
              label={label}
              onChange={changeHandler}
              dataTest={dataTest}
              required={required}
              className={className}
              disabled={disabled}
              {...props}
            />
          )
        }}
      />
    )
  }
)

export default ControlledCheckbox
