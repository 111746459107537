import { isNaN } from 'lodash/fp'
import * as yup from 'yup'

export const MarginSettingsSchema = yup.object({
  /**
   * The default margin premium for the spot rates.
   */
  defaultMarginPremium: yup
    .number()
    .integer('The default margin base must be a whole number')
    .transform(val => (isNaN(val) ? null : val))
    .moreThan(0, 'The default margin base must be more than 0%')
    .max(100, 'The default margin base cannot exceed 100%')
    .required('You must provide a default percentage amount'),
  /**
   * Whether the user is setting a maximum margin premium.
   */
  isSettingMaxMarginPremium: yup.boolean(),
  /**
   * The maximum margin premium for the spot rates.
   */
  maxMarginPremium: yup
    .number()
    .integer('The max margin amount must be a whole number')
    .transform(val => (isNaN(val) ? null : val))
    .nullable()
    .when('isSettingMaxMarginPremium', {
      is: true,
      then: yup =>
        yup
          .moreThan(0, 'The max margin limit must be more than 0%')
          .max(100, 'The default margin base cannot exceed 100%')
          .required('You must provide a maximum percentage amount'),
    }),
})

export type TMarginSettingsSchema = yup.InferType<typeof MarginSettingsSchema>
