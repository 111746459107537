import React, { useCallback, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { Typography, Box } from 'dpl/core'
import { PriceIcon } from 'dpl/icons'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import get from 'lodash/get'
import { SECTIONS_STEP_NUMBER } from '../../constants'
import { useAdjustmentRuleFormContext, useAdjustmentRuleFormStepsContext } from '../../hooks'
import { TAdjustmentRuleFormSchema } from '../../schema'
import { ADJUSTMENT_TYPES } from '../../types'
import { StepSection } from '../StepSection'
import { CostStrategyInputs, MarginStrategyInputs } from './components'
import {
  ADJUSTMENT_TYPE_NAME,
  COST_RULE_TYPE_NAME,
  COST_BASE_PERCENTILE_NAME,
  COST_ADDITIVE_SHIFT_NAME,
  MARGIN_RULE_TYPE_NAME,
  MARGIN_BASE_PERCENTAGE_NAME,
  MARGIN_ADDITIVE_PERCENTAGE_NAME,
  ADJUSTMENT_TYPE_OPTIONS,
} from './constants'
import { formatRateStrategyReviewValues } from './utils/formatRateStrategyReviewValues'

const THIS_STEP = SECTIONS_STEP_NUMBER.rateStrategy
const NEXT_STEP = THIS_STEP + 1

export function AdjustmentRuleRateStrategySection() {
  const { control, errors } = useAdjustmentRuleFormContext()
  const { continueClickHandler, editClickHandler, formSteps } = useAdjustmentRuleFormStepsContext()
  const currentState = formSteps[THIS_STEP]

  const watchedValues = useWatch<Pick<TAdjustmentRuleFormSchema, 'rateStrategy'>>({
    name: [
      ADJUSTMENT_TYPE_NAME,
      COST_RULE_TYPE_NAME,
      COST_BASE_PERCENTILE_NAME,
      COST_ADDITIVE_SHIFT_NAME,
      MARGIN_RULE_TYPE_NAME,
      MARGIN_BASE_PERCENTAGE_NAME,
      MARGIN_ADDITIVE_PERCENTAGE_NAME,
    ],
    control,
  })

  const adjustmentType = get(watchedValues, ADJUSTMENT_TYPE_NAME)
  const marginRuleType = get(watchedValues, MARGIN_RULE_TYPE_NAME)
  const marginBasePercentage = get(watchedValues, MARGIN_BASE_PERCENTAGE_NAME)
  const marginAdditivePercentage = get(watchedValues, MARGIN_ADDITIVE_PERCENTAGE_NAME)
  const costRuleType = get(watchedValues, COST_RULE_TYPE_NAME)
  const costBasePercentile = get(watchedValues, COST_BASE_PERCENTILE_NAME)
  const costAdditiveShiftPercentage = get(watchedValues, COST_ADDITIVE_SHIFT_NAME)

  const continueHandler = useCallback(() => {
    continueClickHandler(NEXT_STEP)
  }, [continueClickHandler])

  const editHandler = useCallback(() => {
    editClickHandler(THIS_STEP, NEXT_STEP)
  }, [editClickHandler])

  const formattedValues = useMemo(() => {
    return formatRateStrategyReviewValues({
      adjustmentType,
      costAdditiveShiftPercentage,
      costBasePercentile,
      costRuleType,
      marginAdditivePercentage,
      marginBasePercentage,
      marginRuleType,
    })
  }, [
    adjustmentType,
    costAdditiveShiftPercentage,
    costBasePercentile,
    costRuleType,
    marginAdditivePercentage,
    marginBasePercentage,
    marginRuleType,
  ])

  return (
    <StepSection
      title='Rate Strategy'
      stepIndex={THIS_STEP}
      formSteps={formSteps}
      onContinue={continueHandler}
      onEdit={editHandler}>
      {currentState === 'active' && (
        <div>
          <Typography variant='body2' color='textSecondary' marginTop={1.5}>
            Select the adjustment type to define the rate strategy that will be used in place of the
            defaults.
          </Typography>

          <Box marginTop={4} display='flex' flexDirection='column' gap={3}>
            <ControlledSelectField
              name={ADJUSTMENT_TYPE_NAME}
              control={control}
              SelectProps={{
                options: ADJUSTMENT_TYPE_OPTIONS,
                label: 'Adjustment Type',
                error: !!errors?.rateStrategy?.adjustmentType?.message,
                helperText: errors?.rateStrategy?.adjustmentType?.message,
                dataTest: 'adjustmentType-select',
              }}
            />

            {adjustmentType === ADJUSTMENT_TYPES.cost && (
              <CostStrategyInputs
                costRuleType={costRuleType}
                costBasePercentile={costBasePercentile}
              />
            )}

            {adjustmentType === ADJUSTMENT_TYPES.margin && (
              <MarginStrategyInputs marginRuleType={marginRuleType} />
            )}
          </Box>
        </div>
      )}
      {currentState === 'review' && (
        <Box display='flex' gap={1.5} alignItems='center' marginTop={3}>
          <PriceIcon size='xlarge' color='coolGray5' />
          <Typography variant='body2' data-test='AdjustmentRuleRateStrategy-Review'>
            {formattedValues}
          </Typography>
        </Box>
      )}
    </StepSection>
  )
}
