import { useCallback, useState } from 'react'
import { useAnalytics } from '#hooks'
import { useToastContext } from 'dpl/components/Toast'
import { TAdjustmentRuleFormSchema } from '../../../schema'
import {
  ICreateSpotRatesAdjustmentRuleMutationVariables,
  useCreateSpotRatesAdjustmentRuleMutation,
} from '../graphql/CreateSpotRatesAdjustmentRuleMutation'
import {
  IUpdateSpotRatesAdjustmentRuleMutationVariables,
  useUpdateSpotRatesAdjustmentRuleMutation,
} from '../graphql/UpdateSpotRatesAdjustmentRuleMutation'
import { mapErrorMessages } from '../utils/mapErrorMessages'

interface IUseAdjustmentRuleFormMutationsProps {
  onSuccess: () => void
}

export const useAdjustmentRuleFormMutations = ({
  onSuccess,
}: IUseAdjustmentRuleFormMutationsProps) => {
  const { trackEvent } = useAnalytics()
  const { openToast } = useToastContext()
  const [errorMessages, setErrorMessages] = useState<string>()

  const [createAdjustmentRuleMutation, { loading: createRuleLoading }] =
    useCreateSpotRatesAdjustmentRuleMutation()

  const createAdjustmentRule = useCallback(
    (
      variables: ICreateSpotRatesAdjustmentRuleMutationVariables,
      formData: TAdjustmentRuleFormSchema
    ) => {
      setErrorMessages('')
      createAdjustmentRuleMutation({
        variables,
        refetchQueries: ['SpotRatesAdjustmentRules'],
        onCompleted: data => {
          const { createOrganizationAdjustmentRule } = data || {}
          const { errors } = createOrganizationAdjustmentRule || {}
          if (errors?.length) {
            trackEvent('Spot Rates Control Panel', 'CREATE_ADJUSTMENT_RULE_ERROR', {
              errors,
              variables,
              formData,
            })
            const errorMessages = mapErrorMessages(errors)
            setErrorMessages(errorMessages)
          } else {
            trackEvent('Spot Rates Control Panel', 'CREATE_ADJUSTMENT_RULE_SUCCESS', {
              data,
              variables,
              formData,
            })
            openToast({ toastMessage: 'Adjustment rule successfully added' })
            onSuccess()
          }
        },
        onError: apolloError => {
          trackEvent('Spot Rates Control Panel', 'RUNTIME_ERROR', {
            apolloError,
            variables,
            formData,
          })
          setErrorMessages('Something went wrong. Please try again.')
        },
      })
    },
    [onSuccess, createAdjustmentRuleMutation, openToast, trackEvent]
  )

  const [updateAdjustmentRuleMutation, { loading: updateRuleLoading }] =
    useUpdateSpotRatesAdjustmentRuleMutation()

  const updateAdjustmentRule = useCallback(
    (
      variables: IUpdateSpotRatesAdjustmentRuleMutationVariables,
      formData: TAdjustmentRuleFormSchema
    ) => {
      setErrorMessages('')
      updateAdjustmentRuleMutation({
        variables,
        refetchQueries: ['SpotRatesAdjustmentRules'],
        onCompleted: data => {
          const { updateOrganizationAdjustmentRule } = data || {}
          const { errors } = updateOrganizationAdjustmentRule || {}
          if (errors?.length) {
            trackEvent('Spot Rates Control Panel', 'UPDATE_ADJUSTMENT_RULE_ERROR', {
              errors,
              variables,
              formData,
            })
            const errorMessages = mapErrorMessages(errors)
            setErrorMessages(errorMessages)
          } else {
            trackEvent('Spot Rates Control Panel', 'UPDATE_ADJUSTMENT_RULE_SUCCESS', {
              data,
              variables,
              formData,
            })
            openToast({ toastMessage: 'Adjustment rule updated' })
            onSuccess()
          }
        },
        onError: apolloError => {
          trackEvent('Spot Rates Control Panel', 'RUNTIME_ERROR', {
            apolloError,
            variables,
            formData,
          })
          setErrorMessages('Something went wrong. Please try again.')
        },
      })
    },
    [onSuccess, openToast, trackEvent, updateAdjustmentRuleMutation]
  )

  return {
    errorMessages,
    loading: createRuleLoading || updateRuleLoading,
    createAdjustmentRule,
    updateAdjustmentRule,
  }
}
