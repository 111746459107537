import React, { memo } from 'react'
import ControlledCurrencyInput, {
  IControlledCurrencyInputProps,
} from 'forms/components/ControlledCurrencyInput'
import { IRateType } from '../../../../../../../types/rateType'

export interface IControlledRateInputProps
  extends Pick<IControlledCurrencyInputProps, 'control' | 'name'> {
  CurrencyInputProps?: Omit<
    IControlledCurrencyInputProps['CurrencyInputProps'],
    'label' | 'placeholder'
  >
  rateType: IRateType
}

export const ControlledRateInput = memo<IControlledRateInputProps>(
  ({ CurrencyInputProps, control, name, rateType }) => {
    return (
      <ControlledCurrencyInput
        control={control}
        name={name}
        CurrencyInputProps={{
          label: rateType === IRateType.allIn ? 'All-In Rate' : 'Linehaul Rate',
          placeholder: '$',
          decimalScale: 0,
          ...CurrencyInputProps,
        }}
      />
    )
  }
)
