import React, { useMemo } from 'react'
import { IContractRfpNodeFragment } from '#routes/contract-rfp/ContractRFPDetailPage/graphql/ContractRFPNodeFragment'
import {
  IImportContractRfpVersionLanesValidation,
  IImportContractRfpVersionLanesValidationSeverities,
} from '#types/graphqlTypes'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl/components/InlineMessage'
import { Box, Typography, Card, CardContent, makeStyles } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { Message } from './Message'
import { MessageProps } from './types'

const useStyles = makeStyles(() => ({
  warningsCard: {
    color: brandColors.coolGray5,
  },
}))
/**
 * utility to help gather consequent row numbers in ranges
 * f.i: ['1', '5-7', '9']
 */
const getRangedRows = (validations: IImportContractRfpVersionLanesValidation[]) => {
  const ranges: string[] = []
  let rangeStart = 0
  let rangeEnd = 0

  validations.forEach((validation, index) => {
    const { rowId } = validation
    const currentRow = Number(rowId)
    const estimatedNext = rangeEnd + 1

    if (index === 0) {
      // start new range
      rangeStart = currentRow
      rangeEnd = currentRow
    } else if (currentRow === estimatedNext) {
      // continue accumulating
      rangeEnd = currentRow
    } else {
      // Add previous range to result
      ranges.push(rangeStart === rangeEnd ? `${rangeStart}` : `${rangeStart}-${rangeEnd}`)

      // start new range
      rangeStart = currentRow
      rangeEnd = currentRow
    }
  })

  /**
   * Add last range.
   */
  ranges.push(rangeStart === rangeEnd ? `${rangeStart}` : `${rangeStart}-${rangeEnd}`)

  return ranges.join(', ')
}

const ORDER_BY_TYPE: Record<string, number> = {
  inclusion: 1,
  blank: 2,
  all_in_fuel_rate: 3,
  custom_rate_must_be_all_in: 4,
  match_state_postal_code: 5,
  geolocate_city_state: 6,
  geolocate_postal_code: 7,
  missing_stop_data: 8,
  volume_contract_period_mismatch: 9,
  dynamic_volume_not_supported: 10,
}

const LAST_PRIORITY = 9

function formatValidations(
  rfp: IContractRfpNodeFragment,
  validations: IImportContractRfpVersionLanesValidation[]
) {
  const validationsByColumnType = groupBy(
    validations,
    ({ column, severity, type }) => `${column ?? ''}+${type}+${severity}`
  )
  const errors: MessageProps[] = []
  const warnings: MessageProps[] = []

  Object.entries(validationsByColumnType).forEach(([columnTypeKey, validationsList]) => {
    const [column, type, severity] = columnTypeKey.split('+')
    const orderedValidations = orderBy(validationsList, ({ rowId }) => Number(rowId))
    const rangedRows = getRangedRows(orderedValidations)
    const messageInfo: MessageProps = {
      id: columnTypeKey,
      column,
      type,
      rows: rangedRows,
      rowCount: validationsList.length,
      rfp,
    }

    if (severity === IImportContractRfpVersionLanesValidationSeverities.error) {
      errors.push(messageInfo)
    } else if (severity === IImportContractRfpVersionLanesValidationSeverities.warning) {
      warnings.push(messageInfo)
    }
  })

  return {
    errors: orderBy(errors, ({ type }) => ORDER_BY_TYPE[type] ?? LAST_PRIORITY),
    warnings,
  }
}

interface ValidationErrorsProps {
  validations: IImportContractRfpVersionLanesValidation[]
  rfp: IContractRfpNodeFragment
}

export function ValidationMessages({ rfp, validations }: ValidationErrorsProps) {
  const { errors, warnings } = useMemo(() => {
    return formatValidations(rfp, validations)
  }, [rfp, validations])
  const hasErrors = errors.length > 0
  const hasWarnings = warnings.length > 0
  const classes = useStyles()

  if (!(hasErrors || hasWarnings)) {
    return null
  }

  return (
    <Box display='flex' flexDirection='column' gap={3} pt={2}>
      {hasErrors && (
        <InlineMessage
          type={INLINE_MESSAGE_VARIANTS.ALERT}
          fullWidth
          message={
            <Box>
              <Typography variant='subtitle2'>
                Some lanes in your file are missing critical information or need updating. Please
                review the list below and upload a new CSV file:
              </Typography>
              {errors.map(error => (
                <Message key={`${error.column}${error.type}`} {...error} />
              ))}
            </Box>
          }
        />
      )}
      {hasWarnings && (
        <Card className={classes.warningsCard}>
          <CardContent>
            <Typography variant='subtitle2'>
              There are a few callouts in your file that can be edited now or after import:
            </Typography>
            {warnings.map(warning => (
              <Message key={warning.id} {...warning} />
            ))}
          </CardContent>
        </Card>
      )}
    </Box>
  )
}
