import React, { useCallback, useState } from 'react'
import { usePermissionsContext } from 'auth/common/context'
import classNames from 'classnames'
import { brandColors, IRadioGroupProps, RadioGroup } from 'dpl'
import { Link, makeStyles } from 'dpl/core'
import Typography from 'dpl/core/Typography'
import AIIcon from 'dpl/icons/build/AIIcon'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { DefaultSettingsModal } from '../DefaultSettingsModal'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(3),
    borderRadius: 8,
    marginBottom: theme.spacing(3),
    backgroundColor: brandColors.coolGray1,
  },
  isHighlighted: {
    backgroundColor: brandColors.skyBlue1,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  radioGroup: {
    marginTop: theme.spacing(2),
  },
  icon: {
    borderRadius: '50%',
    width: theme.spacing(6),
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: brandColors.coolGray3,
    marginRight: theme.spacing(1),
  },
  isIconHighlighted: {
    backgroundColor: brandColors.skyBlue2,
  },
}))

const RADIO_OPTIONS = [
  { value: 'yes', label: 'Yes, generate rates' },
  { value: 'no', label: 'No, don’t generate rates' },
]
const [firstOption] = RADIO_OPTIONS

interface IRateGenRadioGroup {
  onChange: (value: string) => void
  description?: string
}
export function RateGenRadioGroup({
  description = 'Rates will be generated using',
  onChange,
}: IRateGenRadioGroup) {
  const { isFlagEnabled } = useFlagsContext()
  const { userPermissions } = usePermissionsContext()
  const isFFEnabled = isFlagEnabled('rfp_rate_gen')
  const isRfpRateGenEnabled = userPermissions['contract_rates_tool.read_app'] && isFFEnabled

  const [option, setOption] = useState(firstOption.value)

  const { modalState, toggleModal } = useModalState({
    defaultSettingsModal: false,
  })

  const openModalHandler = () => {
    toggleModal('defaultSettingsModal', true)
  }

  const closeModalHandler = () => {
    toggleModal('defaultSettingsModal', false)
  }

  const classes = useStyles()

  const containerClasses = classNames(classes.container, {
    [classes.isHighlighted]: option === firstOption.value,
  })

  const iconClasses = classNames(classes.icon, {
    [classes.isIconHighlighted]: option === firstOption.value,
  })

  const optionChangeHandler: IRadioGroupProps['onOptionChange'] = useCallback(
    (event, checked) => {
      const { target } = event || {}
      const { value } = target || {}
      if (checked) {
        setOption(value)
        onChange(value)
      }
    },
    [onChange]
  )

  if (!isRfpRateGenEnabled) {
    return null
  }

  return (
    <>
      <div className={containerClasses}>
        <div>
          <Typography variant='subtitle1'>Generate Rates Instantly</Typography>
          <Typography variant='body2'>
            {description}{' '}
            <Link variant='subtitle2' onClick={openModalHandler} component='span'>
              default settings
            </Link>
            , overwriting any entered rates.
          </Typography>
          <div className={classes.radioGroup}>
            <RadioGroup
              options={RADIO_OPTIONS}
              onOptionChange={optionChangeHandler}
              checkedValue={option}
            />
          </div>
        </div>
        <div className={classes.iconContainer}>
          <div className={iconClasses}>
            <AIIcon size='xlarge' color={option === firstOption.value ? 'info' : 'coolGray5'} />
          </div>
        </div>
      </div>
      <DefaultSettingsModal open={modalState.defaultSettingsModal} onClose={closeModalHandler} />
    </>
  )
}
