import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { FunctionalTag, IFunctionalTagProps, IFunctionalTagOption } from 'dpl/components/Tag'

type IBaseControllerProps = ControllerProps<React.ComponentType<IFunctionalTagProps>>

type OptionValue = string | number

export interface IControlledFunctionalTagProps<T extends OptionValue = string>
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  onChange?: (selectedOption: IFunctionalTagOption<T>) => void
  FunctionalTagProps?: Partial<IFunctionalTagProps>
}

/**
 * Helper component for use with React Hook Form
 *
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledFunctionalTag
 *   control={control}
 *   defaultValue={defaultValue}
 *   name={name}
 *   FunctionalTagProps={{
 *     options: functionalTagOptions,
 *  }}
 * />
 * ```
 */
const ControlledFunctionalTag = memo<IControlledFunctionalTagProps>(
  ({ control, name, defaultValue, onChange: userOnChange, FunctionalTagProps = {} }) => (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value: selectedValue }) => {
        const changeHandler = (selectedOption: IFunctionalTagOption<string>) => {
          if (userOnChange) {
            userOnChange(selectedOption)
          }
          onChange(selectedOption)
        }

        return (
          <FunctionalTag
            options={[]}
            value={selectedValue.value}
            onSelect={changeHandler}
            {...FunctionalTagProps}
          />
        )
      }}
    />
  )
)

export default ControlledFunctionalTag
