import React, { useCallback } from 'react'
import { useWatch } from 'react-hook-form'
import { IRuleTypeEnum } from '#types/rates'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl/components/InlineMessage'
import { Typography, Box } from 'dpl/core'
import { CustomerIcon, DeliveryIcon, DryVanIcon, PickupIcon, SearchIcon } from 'dpl/icons'
import { ControlledSelectField } from 'forms/components/ControlledSelectField'
import get from 'lodash/get'
import { ControlledAutocompleteOrganizationShipper } from '../../../../../../components/ControlledAutocompleteOrganizationShipper'
import { SECTIONS_STEP_NUMBER } from '../../constants'
import { useAdjustmentRuleFormStepsContext, useAdjustmentRuleFormContext } from '../../hooks'
import { TAdjustmentRuleFormSchema } from '../../schema'
import { ADJUSTMENT_TYPES } from '../../types'
import {
  ADJUSTMENT_TYPE_NAME,
  COST_RULE_TYPE_NAME,
  MARGIN_RULE_TYPE_NAME,
} from '../AdjustmentRuleRateStrategySection/constants'
import { StepSection } from '../StepSection'
import { FactorSwitch, LocationFactorInputs, FactorsReview } from './components'
import {
  CUSTOMER_FACTOR_NAME,
  DELIVERY_LOCATION_FACTOR_NAME,
  PICKUP_LOCATION_FACTOR_NAME,
  EQUIPMENT_FACTOR_NAME,
  EQUIPMENT_TYPE_OPTIONS,
} from './constants'
import { getFactorsState } from './utils/getFactorsState'

const THIS_STEP = SECTIONS_STEP_NUMBER.factors
const NEXT_STEP = THIS_STEP + 1

export function AdjustmentRuleFactorsSection() {
  const { control, errors } = useAdjustmentRuleFormContext()
  const { continueClickHandler, editClickHandler, formSteps } = useAdjustmentRuleFormStepsContext()
  const currentState = formSteps[THIS_STEP]

  const continueHandler = useCallback(() => {
    continueClickHandler(NEXT_STEP)
  }, [continueClickHandler])

  const editHandler = useCallback(() => {
    editClickHandler(THIS_STEP, NEXT_STEP)
  }, [editClickHandler])

  const factorsErrorMessage = get(errors, 'factors.message')
  const customerErrorMessage = get(errors, `${CUSTOMER_FACTOR_NAME}.value.message`)
  const equipmentErrorMessage = get(errors, `${EQUIPMENT_FACTOR_NAME}.value.message`)

  const watchedValues = useWatch<Pick<TAdjustmentRuleFormSchema, 'factors' | 'rateStrategy'>>({
    name: [
      PICKUP_LOCATION_FACTOR_NAME,
      DELIVERY_LOCATION_FACTOR_NAME,
      CUSTOMER_FACTOR_NAME,
      EQUIPMENT_FACTOR_NAME,
      ADJUSTMENT_TYPE_NAME,
      COST_RULE_TYPE_NAME,
      MARGIN_RULE_TYPE_NAME,
    ],
    control,
  })
  const adjustmentType = get(watchedValues, ADJUSTMENT_TYPE_NAME) || {}
  const costRuleType = get(watchedValues, COST_RULE_TYPE_NAME) || {}
  const marginRuleType = get(watchedValues, MARGIN_RULE_TYPE_NAME) || {}

  const isAdditiveRule =
    adjustmentType === ADJUSTMENT_TYPES.cost
      ? costRuleType === IRuleTypeEnum.additive
      : marginRuleType === IRuleTypeEnum.additive

  const pickupLocation = get(watchedValues, PICKUP_LOCATION_FACTOR_NAME) || {}
  const deliveryLocation = get(watchedValues, DELIVERY_LOCATION_FACTOR_NAME) || {}

  const { customerState, deliveryLocationState, equipmentState, pickupLocationState } =
    getFactorsState(watchedValues, isAdditiveRule)

  return (
    <StepSection
      title='Factors'
      stepIndex={THIS_STEP}
      formSteps={formSteps}
      onContinue={continueHandler}
      onEdit={editHandler}>
      {currentState === 'active' && (
        <Box display='flex' flexDirection='column' gap={1.5}>
          <Typography variant='body2' color='textSecondary' marginTop={1.5}>
            {isAdditiveRule
              ? 'Choose one factor. You may choose Pickup and Delivery together to indicate a lane.'
              : 'Select all that apply.'}
          </Typography>

          {factorsErrorMessage && (
            <InlineMessage
              message={factorsErrorMessage}
              type={INLINE_MESSAGE_VARIANTS.ALERT}
              dataTest='factorsErrorMessage'
            />
          )}

          <Box marginTop={1.5} display='flex' flexDirection='column'>
            <FactorSwitch
              Icon={CustomerIcon}
              factorName={CUSTOMER_FACTOR_NAME}
              label='Customer'
              showFactor={customerState.included}
              disabled={customerState.disabled}>
              <ControlledAutocompleteOrganizationShipper
                name={`${CUSTOMER_FACTOR_NAME}.value`}
                control={control}
                enableCreate
                AutocompleteOrganizationShipperProps={{
                  placeholder: 'Customer',
                  StartIcon: SearchIcon,
                  error: !!customerErrorMessage,
                  helperText: customerErrorMessage,
                }}
              />
            </FactorSwitch>
            <FactorSwitch
              Icon={PickupIcon}
              factorName={PICKUP_LOCATION_FACTOR_NAME}
              label='Pickup Location'
              showFactor={pickupLocationState.included}
              disabled={pickupLocationState.disabled}>
              <LocationFactorInputs
                factorName={PICKUP_LOCATION_FACTOR_NAME}
                values={pickupLocation}
              />
            </FactorSwitch>
            <FactorSwitch
              Icon={DeliveryIcon}
              factorName={DELIVERY_LOCATION_FACTOR_NAME}
              label='Delivery Location'
              showFactor={deliveryLocationState.included}
              disabled={deliveryLocationState.disabled}>
              <LocationFactorInputs
                factorName={DELIVERY_LOCATION_FACTOR_NAME}
                values={deliveryLocation}
              />
            </FactorSwitch>
            <FactorSwitch
              Icon={DryVanIcon}
              factorName={EQUIPMENT_FACTOR_NAME}
              label='Equipment'
              showFactor={equipmentState.included}
              disabled={equipmentState.disabled}>
              <ControlledSelectField
                name={`${EQUIPMENT_FACTOR_NAME}.value`}
                control={control}
                SelectProps={{
                  label: 'Equipment Type',
                  options: EQUIPMENT_TYPE_OPTIONS,
                  error: !!equipmentErrorMessage,
                  helperText: equipmentErrorMessage,
                  dataTest: `${EQUIPMENT_FACTOR_NAME}-select`,
                }}
              />
            </FactorSwitch>
          </Box>
        </Box>
      )}

      {currentState === 'review' && <FactorsReview values={watchedValues} />}
    </StepSection>
  )
}
