import { DefaultValues, useForm, UseFormOptions, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContractLaneInformationSchema, IContractLaneInformationSchema } from '../schema'

export const getDefaultValues = (values: DefaultValues<IContractLaneInformationSchema>) => {
  return ContractLaneInformationSchema.cast({
    ...values,
  })
}

export interface IContractLaneInformationProps
  extends Omit<UseFormOptions<IContractLaneInformationSchema>, 'resolver'> {}

export const useContractLaneInformationForm = ({
  defaultValues = {},
  ...rest
}: IContractLaneInformationProps) => {
  return useForm<IContractLaneInformationSchema>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: yupResolver(ContractLaneInformationSchema),
    ...rest,
  })
}

export const useContractLaneInformationFormContext = () =>
  useFormContext<IContractLaneInformationSchema>()
