import moment from 'moment'
import { DEFAULT_YYYY_MM_FORMAT } from '../constants'
import { IContractDurationSchema } from '../schema'

export function getContractDurationStartEndDates(
  months: number
): Pick<IContractDurationSchema, 'contractStartDate' | 'contractEndDate'> {
  const nextMonth = moment().add(1, 'month').format(DEFAULT_YYYY_MM_FORMAT)
  const endDate = moment().add(months, 'months').format(DEFAULT_YYYY_MM_FORMAT)
  return { contractStartDate: nextMonth, contractEndDate: endDate }
}

interface IContractDurationValues {
  contractStartDate: string
  contractEndDate: string
  contractLengthMonths: number
}

export function getContractDurationValues(months: number): IContractDurationValues {
  const { contractEndDate, contractStartDate } = getContractDurationStartEndDates(months)
  return {
    contractStartDate,
    contractEndDate,
    contractLengthMonths: months,
  }
}
