import { ISortOrder } from '#types/graphqlTypes'
import { IRoutingGuideLanesDataGrid } from '../types'

export const SORT_OPTIONS_CONFIG: IRoutingGuideLanesDataGrid['sortOptions'] = [
  {
    label: 'Most Recent',
    value: ISortOrder.desc,
  },
  {
    label: 'Oldest',
    value: ISortOrder.asc,
  },
]
