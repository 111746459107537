import React, { memo } from 'react'
import DryVanIcon from 'dpl/icons/build/DryVanIcon'
import {
  ControlledSelectField,
  IControlledSelectFieldProps,
} from 'forms/components/ControlledSelectField'
import { IEquipment } from '../../types/equipment'

export interface IControlledEquipmentSelectFieldProps
  extends Pick<IControlledSelectFieldProps<IEquipment>, 'control' | 'name'> {
  SelectProps?: Omit<IControlledSelectFieldProps<IEquipment>['SelectProps'], 'options'>
}

export const OPTIONS: IControlledSelectFieldProps<IEquipment>['SelectProps']['options'] = [
  {
    label: 'Dry Van',
    value: IEquipment.dryVan,
  },
  {
    label: 'Reefer',
    value: IEquipment.reefer,
  },
  {
    label: 'Flatbed',
    value: IEquipment.flatbed,
  },
  {
    label: 'Specialized',
    value: IEquipment.specialized,
  },
]

export const ControlledEquipmentSelectField = memo<IControlledEquipmentSelectFieldProps>(
  ({ SelectProps, control, name }) => {
    return (
      <ControlledSelectField
        control={control}
        name={name}
        SelectProps={{
          options: OPTIONS,
          ...SelectProps,
          label: 'Equipment',
          InputProps: {
            startAdornment: <DryVanIcon size='xlarge' />,
          },
        }}
      />
    )
  }
)
