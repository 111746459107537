import React, { memo, useMemo } from 'react'
import { ISortOrder, Maybe } from '#types/graphqlTypes'
import { IRuleTypeEnum } from '#types/rates'
import { ApolloError } from '@apollo/client'
import { getApolloErrorMessages } from 'dpl/components/ApolloErrorMessages/utils'
import { INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl/components/InlineMessage'
import { Stack } from 'dpl/core'
import { useFlagsContext } from 'flags/src/FlagsContext'
import { separateRulesByAction } from '../../utils/separateRulesByAction'
import { AdjustmentRulesDataGridCard, TAdjustmentRulesRow } from '../AdjustmentRulesDataGrid'
import { AdjustmentRulesFilters, IAdjustmentRulesFiltersProps } from '../AdjustmentRulesFilters'

export interface IAdjustmentRulesTabProps
  extends Pick<IAdjustmentRulesFiltersProps, 'onFilterOrSortChange'> {
  /**
   * @default 'AdjustmentRulesTab'
   */
  dataTest?: string
  /**
   * The adjustment rule type
   */
  adjustmentType: 'cost' | 'margin'
  /**
   * The adjustment rules
   */
  rules: Maybe<Maybe<TAdjustmentRulesRow>[]>
  /**
   * The current rule type filter applied to the rules query
   * Determines which DataGrid to show in the tab
   * @example 'do_not_bid'
   */
  ruleType?: Nullable<string>
  loading?: boolean
  error?: ApolloError
}

export const AdjustmentRulesTab = memo<IAdjustmentRulesTabProps>(
  ({
    adjustmentType,
    dataTest = 'AdjustmentRulesTab',
    error,
    loading,
    onFilterOrSortChange,
    ruleType,
    rules,
  }) => {
    const { isFlagEnabled } = useFlagsContext()
    const isDoNotBidRulesEnabled = isFlagEnabled('spot_rates_control_panel_rules_dnb')

    const rulesByRuleAction = useMemo(() => {
      return separateRulesByAction(rules)
    }, [rules])

    const { additiveRules, baseRules, doNotBidRules } = rulesByRuleAction

    const showBaseRulesCard = useMemo(
      () => !ruleType || ruleType === IRuleTypeEnum.base,
      [ruleType]
    )

    const showAdditiveRulesCard = useMemo(
      () => !ruleType || ruleType === IRuleTypeEnum.additive,
      [ruleType]
    )

    const showDnbRulesCard = useMemo(
      () =>
        (!ruleType || ruleType === IRuleTypeEnum.doNotBid) &&
        adjustmentType === 'margin' &&
        isDoNotBidRulesEnabled,
      [adjustmentType, isDoNotBidRulesEnabled, ruleType]
    )

    return (
      <div data-test={dataTest}>
        <Stack gap={3} marginTop={2}>
          {error && (
            <InlineMessage
              type={INLINE_MESSAGE_VARIANTS.ALERT}
              message={getApolloErrorMessages({ apolloErrors: error })}
            />
          )}
          <AdjustmentRulesFilters
            defaultSortValue={ISortOrder.asc}
            onFilterOrSortChange={onFilterOrSortChange}
          />
          {showBaseRulesCard && (
            <AdjustmentRulesDataGridCard
              rows={baseRules}
              ruleAction={IRuleTypeEnum.base}
              loading={loading}
              dataTest='BaseAdjustmentRulesDataGridCard'
            />
          )}
          {showAdditiveRulesCard && (
            <AdjustmentRulesDataGridCard
              rows={additiveRules}
              ruleAction={IRuleTypeEnum.additive}
              loading={loading}
              dataTest='AdditiveAdjustmentRulesDataGridCard'
            />
          )}
          {showDnbRulesCard && (
            <AdjustmentRulesDataGridCard
              rows={doNotBidRules}
              ruleAction={IRuleTypeEnum.doNotBid}
              loading={loading}
              dataTest='DnbAdjustmentRulesDataGridCard'
            />
          )}
        </Stack>
      </div>
    )
  }
)
