import { useAnalytics } from '#hooks'
import { IWebLinkInput } from '#types/graphqlTypes'
import { useUpdateRfpMutation } from '../../graphql/UpdateRfp'

export interface IUseFilesProps {
  contractId: string
  webLinks: IWebLinkInput[]
}

export interface ICallbackProps extends IWebLinkInput {
  index?: number
}

export interface IDeleteProps {
  index?: number
}

export const useFiles = ({ contractId, webLinks }: IUseFilesProps) => {
  const [update] = useUpdateRfpMutation()
  const { trackEvent } = useAnalytics()

  const editLink = async ({ index: editIndex, name, url: link }: ICallbackProps) => {
    const input = {
      id: contractId,
      webLinks: (webLinks ?? []).map(({ name: linkName, url }, index) => ({
        name: (index === editIndex ? name : linkName) || '',
        url: index === editIndex ? link : url,
      })),
    }

    const { data } = await update({
      variables: {
        input,
      },
      onError: apolloError => {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          apolloError,
          item: { id: contractId },
          variables: input,
        })
      },
      refetchQueries: ['Files'],
    })
    const { upsertContractRfp } = data || {}
    const { errors } = upsertContractRfp || {}

    if (errors?.length) {
      trackEvent('Contract RFPs', 'EDIT_LINK_ERROR', {
        errors,
        item: { id: contractId },
        variables: input,
      })

      return errors[0].message
    }

    trackEvent('Contract RFPs', 'EDIT_LINK_SUCCESS', {
      item: { id: contractId },
      variables: input,
    })

    return null
  }

  const addLink = async ({ name, url: link }: ICallbackProps) => {
    const input = {
      id: contractId,
      webLinks: [
        ...(webLinks ?? []).map(({ name, url }) => ({
          name,
          url,
        })),
        {
          name: name || '',
          url: link,
        },
      ],
    }

    const { data } = await update({
      variables: {
        input,
      },
      onError: apolloError => {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          apolloError,
          item: { id: contractId },
          variables: input,
        })
      },
      refetchQueries: ['Files'],
    })
    const { upsertContractRfp } = data || {}
    const { errors } = upsertContractRfp || {}

    if (errors?.length) {
      trackEvent('Contract RFPs', 'ADD_LINK_ERROR', {
        errors,
        item: { id: contractId },
        variables: input,
      })
      return errors[0].message
    }

    trackEvent('Contract RFPs', 'ADD_LINK_SUCCESS', {
      item: { id: contractId },
      variables: input,
    })

    return null
  }

  const deleteLink = async ({ index: deleteIndex }: IDeleteProps) => {
    const input = {
      id: contractId,
      webLinks: webLinks
        .filter((_, index) => index !== deleteIndex)
        .map(({ name, url }) => ({
          name,
          url,
        })),
    }

    const { data } = await update({
      variables: {
        input,
      },
      onError: apolloError => {
        trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
          apolloError,
          item: { id: contractId },
          variables: input,
        })
      },
      refetchQueries: ['Files'],
    })
    const { upsertContractRfp } = data || {}
    const { errors } = upsertContractRfp || {}

    if (errors?.length) {
      trackEvent('Contract RFPs', 'DELETE_LINK_ERROR', {
        errors,
        item: { id: contractId },
        variables: input,
      })

      return errors[0].message
    }

    trackEvent('Contract RFPs', 'DELETE_LINK_SUCCESS', {
      item: { id: contractId },
      variables: input,
    })

    return null
  }

  return {
    editLink,
    addLink,
    deleteLink,
  }
}
