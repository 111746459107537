import React, { memo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { AutocompleteLocation, IAutocompleteLocationProps } from '../AutocompleteLocation'
import { TAutocompleteLocationSchema } from './schema'

type IBaseControllerProps = ControllerProps<
  React.ComponentType<IAutocompleteLocationProps>,
  TAutocompleteLocationSchema
>

export interface IControlledAutocompleteLocationProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  AutocompleteLocationProps?: Omit<IAutocompleteLocationProps, 'onChange' | 'value'>
  onChange?: (e: React.SyntheticEvent, value: IAutocompleteLocationProps['value']) => void
}

export const ControlledAutocompleteLocation = memo<IControlledAutocompleteLocationProps>(
  ({ AutocompleteLocationProps, control, defaultValue, name, onChange: onChangeProp }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          const changeHandler: IAutocompleteLocationProps['onChange'] = (e, newValue) => {
            onChange(newValue)
            if (onChangeProp) onChangeProp(e, value)
          }
          return (
            <AutocompleteLocation
              {...AutocompleteLocationProps}
              onChange={changeHandler}
              value={value}
            />
          )
        }}
      />
    )
  }
)
