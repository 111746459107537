import React, { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ControlledAutocompleteOrganizationUser } from '#components'
import {
  IContractRfpUserAssignment,
  IContractRfpUserAssignmentInput,
  IContractRfpUserAssignmentRoles,
  IOrganizationUser,
  IOrganizationUserStatus,
  IUser,
} from '#types/graphqlTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormModal, INLINE_MESSAGE_VARIANTS, InlineMessage } from 'dpl'
import { Box, Stack } from 'dpl/core'
import {
  TUpdateUserAssignmentsFormSchema,
  UpdateUserAssignmentsFormSchema,
} from './UpdateUserAssignmentsFormSchema'

export interface IUpdateUserAssignmentsModalProps {
  userAssignments: (Pick<IContractRfpUserAssignment, 'role'> & {
    organizationUser: Pick<IOrganizationUser, 'id'> & {
      user: Pick<IUser, 'id' | 'firstName' | 'lastName'>
    }
  })[]
  onClose: () => void
  onConfirm: (userAssignmentsInput: IContractRfpUserAssignmentInput[]) => Promise<Error | null>
}

export function UpdateUserAssignmentsModal({
  onClose,
  onConfirm,
  userAssignments,
}: IUpdateUserAssignmentsModalProps) {
  const { defaultAdditionalTeamMember, defaultAssignee } = useMemo(
    () => ({
      defaultAssignee: userAssignments.find(
        ({ role }) => role === IContractRfpUserAssignmentRoles.assignee
      )?.organizationUser,
      defaultAdditionalTeamMember: userAssignments
        .filter(({ role }) => role === IContractRfpUserAssignmentRoles.additional_team_member)
        .map(({ organizationUser }) => organizationUser),
    }),
    [userAssignments]
  )

  const methods = useForm<TUpdateUserAssignmentsFormSchema>({
    mode: 'all',
    defaultValues: {
      assignee: defaultAssignee,
      additionalTeamMember1: defaultAdditionalTeamMember[0] || null,
      additionalTeamMember2: defaultAdditionalTeamMember[1] || null,
    },
    resolver: yupResolver(UpdateUserAssignmentsFormSchema),
  })
  const [error, setError] = useState<string>('')
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods || {}

  const submitHandler = handleSubmit(
    async ({ additionalTeamMember1, additionalTeamMember2, assignee }) => {
      setError('')

      const userAssignmentsInput: IContractRfpUserAssignmentInput[] = []

      if (assignee?.id) {
        userAssignmentsInput.push({
          role: IContractRfpUserAssignmentRoles.assignee,
          organizationUserId: assignee.id,
        })
      }

      if (additionalTeamMember1?.id) {
        userAssignmentsInput.push({
          role: IContractRfpUserAssignmentRoles.additional_team_member,
          organizationUserId: additionalTeamMember1.id,
        })
      }

      if (additionalTeamMember2?.id) {
        userAssignmentsInput.push({
          role: IContractRfpUserAssignmentRoles.additional_team_member,
          organizationUserId: additionalTeamMember2.id,
        })
      }

      const error = await onConfirm(userAssignmentsInput)
      if (error) setError(error.message)
    }
  )

  return (
    <FormModal
      open
      onClose={onClose}
      maxWidth='md'
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          ButtonProps: {
            variant: 'text',
          },
        },
        {
          label: 'Save',
          action: submitHandler,
        },
      ]}
      title='Edit Team'>
      <FormProvider {...methods}>
        <Stack gap={3}>
          {error && (
            <Box>
              <InlineMessage message={error} type={INLINE_MESSAGE_VARIANTS.ALERT} />
            </Box>
          )}
          <ControlledAutocompleteOrganizationUser
            defaultValue={defaultAssignee}
            control={control}
            name='assignee'
            AutocompleteOrganizationUserProps={{
              label: 'Assignee',
              placeholder: 'Select',
              required: true,
              organizationUserStatuses: [IOrganizationUserStatus.active],
              error: Boolean(errors?.assignee),
              helperText: errors?.assignee?.message,
            }}
          />
          <ControlledAutocompleteOrganizationUser
            defaultValue={defaultAdditionalTeamMember}
            control={control}
            name='additionalTeamMember1'
            AutocompleteOrganizationUserProps={{
              label: 'Additional Team Member (Optional)',
              placeholder: 'Select',
              organizationUserStatuses: [IOrganizationUserStatus.active],
            }}
          />
          <ControlledAutocompleteOrganizationUser
            defaultValue={defaultAdditionalTeamMember}
            control={control}
            name='additionalTeamMember2'
            AutocompleteOrganizationUserProps={{
              label: 'Additional Team Member (Optional)',
              placeholder: 'Select',
              organizationUserStatuses: [IOrganizationUserStatus.active],
            }}
          />
        </Stack>
      </FormProvider>
    </FormModal>
  )
}
