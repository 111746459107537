import React, { useCallback, useState, useMemo } from 'react'
import debounce from 'lodash/debounce'
import Box from '../../core/Box'
import Typography from '../../core/Typography'
import { brandColors } from '../../theme/colors'
import { Autocomplete, AutocompleteHighlightText, IAutocompleteProps } from '../Autocomplete'
import SelectCountry, {
  IGeocodingAllowedCountryEnum,
  ISelectCountryProps,
  TSelectCountryChangeHandler,
} from '../SelectCountry'
import { DEBOUNCE_MS, DEFAULT_COUNTRY_OPTIONS } from './constants'
import { IAutocompleteLocationBaseResult } from './types'

export interface IAutocompleteLocationBaseProps<
  TLocationValue extends IAutocompleteLocationBaseResult = IAutocompleteLocationBaseResult,
  TMultiple extends boolean | undefined = false,
> extends Pick<
    IAutocompleteProps<TLocationValue, false, TMultiple>,
    | 'error'
    | 'getOptionLabel'
    | 'helperText'
    | 'isOptionEqualToValue'
    | 'label'
    | 'loading'
    | 'multiple'
    | 'onChange'
    | 'options'
    | 'placeholder'
    | 'renderOption'
    | 'renderStartAdornment'
    | 'size'
    | 'value'
    | 'filterOptions'
    | 'required'
    | 'noOptionsText'
    | 'type'
    | 'groupBy'
  > {
  /**
   * The list of countries to show in the country select.
   * @default ['US', 'CA', 'MX']
   */
  countryOptions?: ISelectCountryProps['countries']
  /**
   * @default 'AutocompleteLocationBase'
   */
  dataTest?: string
  /**
   * The default country to use.
   * @default 'US'
   */
  defaultCountry?: IGeocodingAllowedCountryEnum
  /**
   * If `true`, the country select will be shown and `renderStartAdornment` will be ignored.
   *
   * @default false
   */
  enableCountrySelect?: boolean
  /**
   * Callback to handle input changes.
   *
   * @param {string} inputValue - the value the user has entered
   * @param {IGeocodingAllowedCountryEnum} country - the country that is currently selected
   * @returns
   */
  onInputChange: (inputValue: string, country: IGeocodingAllowedCountryEnum) => void
  /**
   * Function to map the input value depending on the case.
   * @default identity
   */
  inputValueMapper?: (inputValue: string) => string
}

const defaultGetOptionLabel = (option: IAutocompleteLocationBaseResult) =>
  `${option.city}, ${option.stateCode}`

const defaultInputValueMapper = (value: string) => value

export function AutocompleteLocationBase<
  TLocationValue extends IAutocompleteLocationBaseResult = IAutocompleteLocationBaseResult,
  TMultiple extends boolean | undefined = false,
>({
  countryOptions = DEFAULT_COUNTRY_OPTIONS,
  dataTest = 'AutocompleteLocationBase',
  defaultCountry = IGeocodingAllowedCountryEnum.US,
  enableCountrySelect = false,
  error,
  getOptionLabel = defaultGetOptionLabel,
  helperText,
  isOptionEqualToValue,
  label,
  loading,
  multiple,
  onChange,
  onInputChange,
  options,
  placeholder,
  renderOption,
  renderStartAdornment,
  size,
  value,
  required,
  filterOptions,
  noOptionsText,
  inputValueMapper = defaultInputValueMapper,
  type,
  groupBy,
}: IAutocompleteLocationBaseProps<TLocationValue, TMultiple>) {
  const [currentCountry, setCurrentCountry] = useState<IGeocodingAllowedCountryEnum>(defaultCountry)
  const [inputValue, setInputValue] = useState('')
  const countryChangeHandler = useCallback<TSelectCountryChangeHandler>(value => {
    setCurrentCountry(value)
  }, [])

  const debouncedOnInputChange = useMemo(() => {
    return debounce(onInputChange, DEBOUNCE_MS)
  }, [onInputChange])

  const inputValueChangeHandler = useCallback(
    (event, rawValue) => {
      /**
       * The event hint us a user interaction vs an internal component one.
       * We only map user input, and leave internal interaction untouched.
       */
      if (event) {
        const value = inputValueMapper(rawValue)

        setInputValue(value)
        debouncedOnInputChange(value, currentCountry)
      } else {
        setInputValue(rawValue)
      }
    },
    [currentCountry, debouncedOnInputChange, inputValueMapper]
  )

  return (
    <Autocomplete
      dataTest={dataTest}
      error={error}
      getOptionLabel={getOptionLabel}
      helperText={helperText}
      isOptionEqualToValue={isOptionEqualToValue || ((option, value) => option.id === value.id)}
      label={label}
      loading={loading}
      multiple={multiple}
      onChange={onChange}
      onInputChange={inputValueChangeHandler}
      inputValue={inputValue}
      options={options}
      placeholder={placeholder}
      filterOptions={filterOptions}
      required={required}
      renderOption={
        renderOption ||
        ((optionProps, option, { inputValue }) => (
          <li {...optionProps} data-test={`${dataTest}-${option.id}`}>
            <AutocompleteHighlightText inputValue={inputValue} label={getOptionLabel(option)} />
          </li>
        ))
      }
      renderGroup={params => (
        <li key={params.key}>
          <Box pl={2} py={0.5} position='sticky' top='0px' bgcolor={brandColors.white}>
            <Typography variant='caption' color={brandColors.coolGray5}>
              {params.group}
            </Typography>
          </Box>
          <Box component='ul' p={0}>
            {params.children}
          </Box>
        </li>
      )}
      renderStartAdornment={
        enableCountrySelect
          ? () => (
              <SelectCountry
                dataTest={`${dataTest}-select-country`}
                countries={countryOptions}
                value={currentCountry}
                onChange={countryChangeHandler}
              />
            )
          : renderStartAdornment
      }
      size={size}
      value={value}
      noOptionsText={noOptionsText}
      type={type}
      groupBy={groupBy}
    />
  )
}
