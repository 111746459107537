import React, { useCallback } from 'react'
import {
  ConfirmationModal,
  getApolloErrorMessages,
  IConfirmationModalProps,
  IModalAction,
  useToastContext,
} from 'dpl'
import { Typography } from 'dpl/core'
import { useAnalytics } from '../../../../../hooks/useAnalytics'
import { useDeactivateCarrierLaneRateMutation } from '../../graphql/DeactivateCarrierLaneRate'

export interface IDeactivateCarrierLaneRateModalProps
  extends Pick<IConfirmationModalProps, 'actions' | 'open' | 'onClose'> {
  /**
   * Id of the carrier lane rate being deactivated
   */
  selectedCarrierRateId?: string
}

export function DeactivateCarrierLaneRateModal({
  onClose,
  open,
  selectedCarrierRateId = '',
}: IDeactivateCarrierLaneRateModalProps) {
  const { openToast } = useToastContext()
  const [deactivateCarrierRate, { error: apolloErrors }] = useDeactivateCarrierLaneRateMutation()
  const { trackEvent } = useAnalytics()

  const confirmHandler = useCallback(async () => {
    const { data } = await deactivateCarrierRate({
      variables: { input: { id: selectedCarrierRateId } },
      refetchQueries: ({ data }) => {
        const { deactivateRoutingGuideCarrierRate } = data || {}
        const { result } = deactivateRoutingGuideCarrierRate || {}
        if (result) {
          return ['RoutingGuideLane']
        }
        return []
      },
    })

    const { deactivateRoutingGuideCarrierRate } = data || {}
    const { result, errors: mutationErrors = [] } = deactivateRoutingGuideCarrierRate || {}
    const errorMessages = getApolloErrorMessages({ apolloErrors, mutationErrors })

    if (mutationErrors.length) {
      trackEvent('Routing Guide', 'DEACTIVATE_CARRIER_LANE_RATE_ERROR', {
        carrierLaneRateId: selectedCarrierRateId,
        mutationErrors: errorMessages,
      })
      openToast({ toastMessage: errorMessages, toastType: 'alert' })
      return
    }
    if (result) {
      trackEvent('Routing Guide', 'DEACTIVATE_CARRIER_LANE_RATE_SUCCESS', {
        carrierLaneRateId: selectedCarrierRateId,
      })
      openToast({ toastMessage: 'Carrier successfully deactivated.' })
      onClose()
    }
  }, [deactivateCarrierRate, selectedCarrierRateId, apolloErrors, trackEvent, openToast, onClose])

  const actions: IModalAction[] = [
    {
      label: 'No, Cancel',
      action: onClose,
      type: 'dismiss',
    },
    {
      label: 'Yes, Deactivate',
      action: confirmHandler,
      type: 'delete',
    },
  ]
  return (
    <ConfirmationModal
      open={open}
      title='Deactivate Lane Rate?'
      onClose={onClose}
      actions={actions}
      includeCloseIconButton>
      <Typography variant='body1'>
        Deactivating this carrier&apos;s lane rate will end the duration of this agreement. Are you
        sure you want to do that?
      </Typography>
    </ConfirmationModal>
  )
}
