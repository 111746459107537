import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PROTECTED_PATHS } from '#constants/paths'
import { usePermissionsContext } from 'auth/common/context/PermissionsContext'
import { useNavigateBack } from 'dpl/hooks'
import { useFlagsContext } from 'flags/src/FlagsContext'

export interface IUseCustomerModalProps {
  isEditing?: boolean
}

export interface IUseCustomerModalState {
  isModalOpen: boolean
  isAccessRestrictedModalOpen: boolean
  isFeatureUnavailableModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const useCustomerModal = ({ isEditing = false }): IUseCustomerModalState => {
  const navigate = useNavigate()
  const navigateBack = useNavigateBack()

  const { pathname } = useLocation()
  const isPathnameCustomerCreate = useMemo(
    () => pathname === PROTECTED_PATHS.customerCreate,
    [pathname]
  )

  const isPathnameCustomerEdit = useMemo(
    () => pathname.includes(PROTECTED_PATHS.customerEdit),
    [pathname]
  )

  const { isFlagEnabled } = useFlagsContext()
  const isCustomerCreateEnabled = useMemo(() => isFlagEnabled('create_customer'), [isFlagEnabled])
  const isCustomerEditEnabled = useMemo(() => isFlagEnabled('edit_customer'), [isFlagEnabled])
  const { userPermissions } = usePermissionsContext()
  const isPermittedToCreateCustomer = useMemo(
    () => userPermissions['shipper_management.create_organization_shipper'],
    [userPermissions]
  )

  const isPermittedToEditCustomer = useMemo(
    () => userPermissions['shipper_management.update_organization_shipper'],
    [userPermissions]
  )

  const modalStates: Pick<
    IUseCustomerModalState,
    'isModalOpen' | 'isAccessRestrictedModalOpen' | 'isFeatureUnavailableModalOpen'
  > = useMemo(() => {
    if (isEditing ? !isPathnameCustomerEdit : !isPathnameCustomerCreate) {
      return {
        isModalOpen: false,
        isAccessRestrictedModalOpen: false,
        isFeatureUnavailableModalOpen: false,
      }
    }

    if (isEditing ? !isCustomerEditEnabled : !isCustomerCreateEnabled) {
      return {
        isModalOpen: false,
        isAccessRestrictedModalOpen: false,
        isFeatureUnavailableModalOpen: true,
      }
    }

    if (isEditing ? !isPermittedToEditCustomer : !isPermittedToCreateCustomer) {
      return {
        isModalOpen: false,
        isAccessRestrictedModalOpen: true,
        isFeatureUnavailableModalOpen: false,
      }
    }

    return {
      isModalOpen: true,
      isAccessRestrictedModalOpen: false,
      isFeatureUnavailableModalOpen: false,
    }
  }, [
    isEditing,
    isPathnameCustomerCreate,
    isPathnameCustomerEdit,
    isPermittedToCreateCustomer,
    isPermittedToEditCustomer,
    isCustomerCreateEnabled,
    isCustomerEditEnabled,
  ])

  const openModal = useCallback(() => {
    if (isEditing) {
      return navigate(PROTECTED_PATHS.customerEdit)
    }
    return navigate(PROTECTED_PATHS.customerCreate)
  }, [navigate, isEditing])

  const closeModal = () => {
    navigateBack({ path: PROTECTED_PATHS.customers })
  }

  return {
    ...modalStates,
    openModal,
    closeModal,
  }
}
