import React, { memo, useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { UNPROTECTED_PATHS } from '#constants/paths'
import { IOrganizationSignInProps, OrganizationSignIn } from 'auth/b2b/components'
import { TRedirectUrls } from 'auth/b2b/components/types'
import { useOrganizationSlug } from 'auth/b2b/hooks'
import { RadialSpinner } from 'dpl/components/RadialSpinner'
import { makeStyles } from 'dpl/core'
import { useAnalytics } from '../../hooks/useAnalytics'
import { TERMS_OF_SERVICE_URL } from '../login/constants'

const useLoginOrganizationStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(6),
    },
  },
}))

export const AUTH_REDIRECT_URLS: TRedirectUrls = {
  reset_password_redirect_url: `${import.meta.env.VITE_ROOT}${UNPROTECTED_PATHS.resetPassword}`,
  sign_in_redirect_url: `${import.meta.env.VITE_ROOT}${UNPROTECTED_PATHS.authenticate}`,
  sign_up_redirect_url: `${import.meta.env.VITE_ROOT}${UNPROTECTED_PATHS.authenticate}`,
}

export const LoginOrganization = memo(() => {
  const classes = useLoginOrganizationStyles()
  const { identifyUser, trackEvent } = useAnalytics()
  const { organizationSlug } = useParams()
  const { loading, organization } = useOrganizationSlug(organizationSlug)
  const [searchParams] = useSearchParams()
  const defaultEmail = searchParams.get('email') ?? undefined
  const loginHref = `${UNPROTECTED_PATHS.login}/${organizationSlug}`

  const magicLinkRequestHandler = useCallback(() => {
    trackEvent('Organization Login', 'REQUEST_LOGIN_MAGIC_LINK')
  }, [trackEvent])

  const passwordIncorrectHandler = useCallback<
    NonNullable<IOrganizationSignInProps['onPasswordError']>
  >(
    error => {
      trackEvent('Organization Login', 'LOGIN_WITH_PASSWORD_ERROR', { error })
    },
    [trackEvent]
  )

  const passwordSuccessHandler = useCallback<
    NonNullable<IOrganizationSignInProps['onPasswordSuccess']>
  >(
    ({ email, organizationId }) => {
      identifyUser({ email, organization: { id: organizationId } })
      trackEvent('Organization Login', 'LOGIN_WITH_PASSWORD_SUCCESS')
    },
    [identifyUser, trackEvent]
  )

  const resetPasswordHandler = useCallback(() => {
    trackEvent('Reset Password', 'REQUEST_FORGOT_PASSWORD')
  }, [trackEvent])

  return (
    <div className={classes.root} data-test='route-org-login'>
      {loading && !organization && <RadialSpinner position='absolute' />}

      {!loading && organization && (
        <OrganizationSignIn
          defaultEmail={defaultEmail}
          organization={organization}
          loginHref={loginHref}
          redirectUrls={AUTH_REDIRECT_URLS}
          termsOfServiceUrl={TERMS_OF_SERVICE_URL}
          onMagicLinkRequest={magicLinkRequestHandler}
          onPasswordError={passwordIncorrectHandler}
          onPasswordSuccess={passwordSuccessHandler}
          onResetPassword={resetPasswordHandler}
        />
      )}

      {!loading && !organization && <p>Not Found</p>}
    </div>
  )
})
