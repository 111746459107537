import React, { useMemo } from 'react'
import { StopListModal } from '#components/StopListModal'
import { IContractRatesStopInput, IQueryFetchContractRatesArgs } from '#types/graphqlTypes'
import { getStopsWithRelativeSequence } from '#utils/getStopsWithRelativeSequence'
import { minMaxTempFormatter } from '#utils/minMaxTempFormatter'
import { Tooltip } from 'dpl/components'
import { Link } from 'dpl/core'
import Typography from 'dpl/core/Typography'
import makeStyles from 'dpl/core/styles/makeStyles'
import { DryVanIcon, InfoIcon, LaneArrowIcon, ReeferIcon } from 'dpl/icons/build'
import { useModalState } from 'dpl/utils/hooks/useModalState'
import isBoolean from 'lodash/isBoolean'
import { contractDurationFormatter } from '../../forms/ContractLaneInformationForm/utils'
import { TContractDuration } from '../../utils/getContractDurationFromMonthlyCost'

export interface ILaneInformationHeaderProps {
  stops?: IContractRatesStopInput[]
  isTeam?: boolean
  isFlatVolume?: boolean
  contractDuration?: TContractDuration
  equipmentKey?: string
  reeferTempMin?: number | null
  reeferTempMax?: number | null
  contractLaneFormData?: IQueryFetchContractRatesArgs
}

const useLaneInformationHeaderStyles = makeStyles(theme => ({
  stopsHeader: {
    display: 'flex',
    columnGap: theme.spacing(1.5),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  stop: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1.5),
  },
  reeferDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  detailsSubheader: {
    marginTop: theme.spacing(1),
  },
  arrowAndStops: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stopTime: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
}))

export function LaneInformationHeader({
  contractDuration,
  contractLaneFormData,
  equipmentKey,
  isFlatVolume,
  isTeam = false,
  reeferTempMax,
  reeferTempMin,
  stops = [],
}: ILaneInformationHeaderProps) {
  const classes = useLaneInformationHeaderStyles()
  const [pickup] = stops
  const delivery = stops[stops.length - 1]
  const isReefer = equipmentKey === '53_reefer'
  const formattedContractDuration = contractDuration
    ? contractDurationFormatter(contractDuration)
    : '--'

  const formattedTemperatureRange = minMaxTempFormatter({
    minTemp: reeferTempMin ?? 0,
    maxTemp: reeferTempMax ?? 0,
  })

  const { stops: stopsFormData = [] } = contractLaneFormData || {}
  const [pickupFormData] = stopsFormData
  const deliveryFormData = stopsFormData[stops.length - 1]
  const { postalCode: pickupFormDataPostalCode } = pickupFormData
  const { postalCode: deliveryFormDataPostalCode } = deliveryFormData

  const {
    city: pickupCity,
    isDropTrailer: pickupIsDropTrailer,
    postalCode: pickupPostalCode,
    stateCode: pickupStateCode,
  } = pickup || {}
  const {
    city: deliveryCity,
    isDropTrailer: deliveryIsDropTrailer,
    postalCode: deliveryPostalCode,
    stateCode: deliveryStateCode,
  } = delivery || {}

  const pickupZipcode = pickupFormDataPostalCode || pickupPostalCode
  const deliveryZipcode = deliveryFormDataPostalCode || deliveryPostalCode

  const showPickupPcToolTip = !pickupFormDataPostalCode
  const showDeliveryPcToolTip = !deliveryFormDataPostalCode

  const hasIntermediateStops = stops.length > 2
  const numIntermediateStops = stops.length - 2

  const { createToggleHandler, modalState } = useModalState({
    stopListModal: false,
  })
  const { stopListModal } = modalState
  const toggleStopListModal = createToggleHandler('stopListModal')

  const stopsWithRelativeSequence = useMemo(() => {
    return getStopsWithRelativeSequence(stops)
  }, [stops])

  return (
    <div data-test='lane-information-header'>
      <div className={classes.stopsHeader}>
        <div className={classes.stop}>
          <div>
            <div className={classes.stopTime}>
              <div>
                <Typography variant='h4' component='span'>
                  {pickupCity}, {pickupStateCode}{' '}
                </Typography>
                {pickupZipcode && (
                  <Typography variant='subtitle2' color='textSecondary' component='span'>
                    ({pickupZipcode})
                  </Typography>
                )}
              </div>
              {showPickupPcToolTip && (
                <Tooltip color='dark' title='We generated an approximated ZIP for you.'>
                  <div className={classes.tooltip}>
                    <InfoIcon size='medium' color='coolGray5' />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={classes.arrowAndStops}>
            <LaneArrowIcon size='xlarge' />
            {hasIntermediateStops && (
              <>
                <Link
                  onClick={toggleStopListModal}
                  component='button'
                  data-test='stop-list-modal-button'>
                  {numIntermediateStops} Stop{numIntermediateStops > 1 ? 's' : ''}
                </Link>
                <StopListModal
                  open={stopListModal}
                  onClose={toggleStopListModal}
                  stops={stopsWithRelativeSequence}
                />
              </>
            )}
          </div>
        </div>
        <div className={classes.stop}>
          <div>
            <div className={classes.stopTime}>
              <div>
                <Typography variant='h4' component='span'>
                  {deliveryCity}, {deliveryStateCode}{' '}
                </Typography>
                {deliveryZipcode && (
                  <Typography variant='subtitle2' color='textSecondary' component='span'>
                    ({deliveryZipcode})
                  </Typography>
                )}
              </div>
              {showDeliveryPcToolTip && (
                <Tooltip color='dark' title='We generated an approximated ZIP for you.'>
                  <div className={classes.tooltip}>
                    <InfoIcon size='medium' color='coolGray5' />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          {isReefer ? (
            <div className={classes.reeferDetails}>
              <ReeferIcon size='xlarge' data-test='reefer-icon' />
              <Typography variant='subtitle2' color='textSecondary'>
                ({formattedTemperatureRange})
              </Typography>
            </div>
          ) : (
            <DryVanIcon size='xlarge' data-test='dry-van-icon' />
          )}{' '}
        </div>
      </div>
      <div className={classes.detailsSubheader}>
        <Typography variant='subtitle2' color='textSecondary'>
          {formattedContractDuration}
          {isBoolean(isFlatVolume) && <> · Volume: {isFlatVolume ? 'Flat' : 'Dynamic'}</>}
          {isTeam && <> · Team Required</>}
          {pickupIsDropTrailer && <> · Drop Trailer: Pickup</>}
          {deliveryIsDropTrailer && <> · Drop Trailer: Delivery</>}
        </Typography>
      </div>
    </div>
  )
}
