import * as GeoJSON from 'geojson'
import { IMapStopProps } from '../types'
import { getGeoJSONFeatureStop } from './getGeoJSONFeatureStop'

export function getGeoJSONFeatureStopCollection<P extends IMapStopProps>(
  stops: P[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry, P> {
  return {
    type: 'FeatureCollection',
    features: stops.map(getGeoJSONFeatureStop),
  }
}
