import { Control, FieldValues, useWatch } from 'react-hook-form'
import { isWhenFunction } from './typeGuards'

export interface IFormIfProps<TFieldValues extends FieldValues, V extends any> {
  control: Control<TFieldValues>
  /**
   * @example 'firstName'
   */
  name: string
  /**
   * When truthy, displays children property
   * @example 'Jane'
   * @example value => ['Jane', 'Grete'].includes(value)
   */
  when: V | ((val: V) => boolean)
  /**
   * @example <ControlledTextField name="firstName" control={control} />
   */
  children: JSX.Element
  /**
   * What to display when condition is falsey
   * @default null
   */
  otherwise?: JSX.Element | null
}

/**
 * Conditionally hide and show values/components based on the value of a field
 *
 * @example
 * ```
 * // Pass a value to show/hide if is equal
 * <FormIf control={control} name='firstName' when='Jean'>
 *  <ControlledTextField control={control} name='lastName' />
 * </FormIf>
 * ```
 * @example
 * ```
 * // Pass a function to determine if the field should be shown
 * <FormIf
 *  control={control}
 *  name='firstName'
 *  when={val === ['Jane', 'Erik'].includes(val)}>
 *   <ControlledTextField control={control} name='lastName' />
 * </FormIf>
 * ```
 */
export const FormIf = <TFieldValues extends FieldValues, V extends any>({
  children,
  control,
  name,
  otherwise = null,
  when,
}: IFormIfProps<TFieldValues, V>) => {
  const watched = useWatch({
    control,
    name,
  })

  if (!isWhenFunction(when) && when !== watched) {
    return otherwise
  }

  if (isWhenFunction(when) && !when(watched)) {
    return otherwise
  }

  return children
}
