import React from 'react'
import { Box, Grid, Typography } from 'dpl/core'
import { LaneArrowIcon } from 'dpl/icons'
import { DEFAULT_EMPTY_ACTIVITY_STRING } from '../components'

export interface IUpdatedLaneEventJson {
  lane_manager?: string[]
}
export const getUpdatedLaneActivityDetail = (rowEventJson: IUpdatedLaneEventJson) => {
  const { lane_manager: laneManager } = rowEventJson
  const [laneManagerBefore, laneManagerAfter] = laneManager || [
    DEFAULT_EMPTY_ACTIVITY_STRING,
    DEFAULT_EMPTY_ACTIVITY_STRING,
  ]

  return (
    <Grid container padding={2}>
      <Grid item xs={2} sx={{ textAlign: 'left' }}>
        <Typography variant='caption'>Lane Manager</Typography>
      </Grid>
      <Grid item xs={9} sx={{ textAlign: 'left' }}>
        <Box display='flex'>
          <Typography variant='caption' color='textSecondary'>
            {laneManagerBefore}
          </Typography>
          <Typography variant='caption' color='textSecondary' ml={1} mr={1}>
            <LaneArrowIcon />
          </Typography>
          <Typography variant='caption' color='textSecondary'>
            {laneManagerAfter}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
