import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from '#hooks'
import { useStateTransitionMutation } from '#routes/contract-rfp/ContractRFPsPage/graphql/StateTransition'
import { RFP_READABLE_STATES, TRANSITIONS } from '#routes/contract-rfp/utils'
import { IContractRfpStates } from '#types/graphqlTypes'
import { useToastContext } from 'dpl'
import startCase from 'lodash/startCase'
import { IStateChangeModalProps, IStateChangeRFP } from './StateChangeModal'
import { shouldCreateNewVersion } from './utils'

export function useStateChangeModal() {
  const location = useLocation()
  const { openToast } = useToastContext()
  const [move] = useStateTransitionMutation()
  const { trackEvent } = useAnalytics()
  const [moveRfp, setMoveRfp] = useState<{
    item: IStateChangeRFP
    newColumnId: IContractRfpStates
  } | null>(null)
  const transitionRFP = useCallback(
    async (item: IStateChangeRFP, columnId: string, submittedAt?: string, dueAt?: string) => {
      const variables = {
        input: {
          id: item.id,
          transition: TRANSITIONS[columnId as IContractRfpStates],
          submittedAt,
        },
        upsertVersionInput: {
          contractRfpId: item.id,
          submittedAt,
          dueAt,
        },
        upsertNewVersion: shouldCreateNewVersion(item.state, columnId as IContractRfpStates),
        isDifferentState: true,
      }
      const path = /\/contract-rfps\/RFP-\d+/.test(location.pathname)
        ? 'contract-rfp-detail'
        : 'contract-rfps'

      const { data } = await move({
        variables,
        onError: apolloError => {
          openToast({
            toastMessage: `There was an error transitioning the RFP-${item.displayId} to ${RFP_READABLE_STATES[columnId as IContractRfpStates]}`,
            toastType: 'alert',
          })
          trackEvent('Contract RFPs', 'RUNTIME_ERROR', {
            apolloError,
            item,
            variables,
            path,
            from: startCase(item.state),
            to: startCase(columnId),
          })
        },
        refetchQueries: ['ContractRfps', 'ContractRfp'],
      })

      const { errors } = data?.transitionContractRfpState || {}

      if (errors?.length) {
        openToast({
          toastMessage: `There was an error transitioning the RFP-${item.displayId} to ${RFP_READABLE_STATES[columnId as IContractRfpStates]}`,
          toastType: 'alert',
        })

        trackEvent('Contract RFPs', 'UPDATE_STATE_RFP_ERROR', {
          variables,
          item,
          errors,
          path,
          from: startCase(item.state),
          to: startCase(columnId),
        })
      } else {
        openToast({
          toastMessage: `RFP-${item.displayId} Moved to ${RFP_READABLE_STATES[columnId as IContractRfpStates]}`,
        })

        trackEvent('Contract RFPs', 'UPDATE_STATE_RFP_SUCCESS', {
          variables,
          item,
          path,
          from: startCase(item.state),
          to: startCase(columnId),
        })
      }
    },
    [move]
  )

  const changeRfpStatus = useCallback(
    async (item: IStateChangeRFP, columnId: string) => {
      if (
        [IContractRfpStates.submitted, IContractRfpStates.closed].includes(
          columnId as IContractRfpStates
        )
      ) {
        setMoveRfp({ item, newColumnId: columnId as IContractRfpStates })
        return
      }
      if (shouldCreateNewVersion(item.state, columnId as IContractRfpStates)) {
        setMoveRfp({ item, newColumnId: columnId as IContractRfpStates })
        return
      }

      transitionRFP(item, columnId)
    },
    [transitionRFP]
  )

  const confirmHandler: IStateChangeModalProps['onConfirm'] = useCallback(
    async (item, columnId, submittedAt, dueAt) => {
      await transitionRFP(item, columnId, submittedAt, dueAt)
      setMoveRfp(null)
    },
    [transitionRFP]
  )

  const closeHandler = useCallback(() => setMoveRfp(null), [setMoveRfp])

  return {
    moveRfp,
    closeHandler,
    confirmHandler,
    changeRfpStatus,
  }
}
