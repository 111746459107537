import { IGeocodingResultTypeEnum } from '#types/graphqlTypes'
import * as yup from 'yup'

export const AutocompleteLocationSchema = yup.object({
  id: yup.string(),
  address: yup.string().nullable(),
  city: yup.string().nullable(),
  coordinates: yup.array(yup.number()),
  country: yup.string().nullable(),
  countryCode: yup.string().nullable(),
  latitude: yup.number().nullable(),
  longitude: yup.number().nullable(),
  postalCode: yup.string().nullable(),
  resultType: yup.mixed<IGeocodingResultTypeEnum>(),
  state: yup.string().nullable(),
  stateCode: yup.string().nullable(),
  timezone: yup.string().nullable(),
})

export type TAutocompleteLocationSchema = yup.InferType<typeof AutocompleteLocationSchema>
