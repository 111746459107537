import React, { createContext, PropsWithChildren, useContext } from 'react'
import { IUseUserDetailsState, IUseUserDetailsProps, useUserDetails } from '#hooks/useUserDetails'
import { useAuthMemberSession } from 'auth/b2b/hooks'

const UserDetailsContext = createContext<IUseUserDetailsState>({
  hasLoaded: false,
  loading: false,
})

export function UserDetailsProvider({ children }: PropsWithChildren<IUseUserDetailsProps>) {
  const { session } = useAuthMemberSession()
  const state = useUserDetails({ skip: !session })
  return <UserDetailsContext.Provider value={state}>{children}</UserDetailsContext.Provider>
}

export const useUserDetailsContext = (): IUseUserDetailsState => useContext(UserDetailsContext)
