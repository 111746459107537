import { IContractRfpStates } from '#types/graphqlTypes'

export function shouldCreateNewVersion(
  currentState: IContractRfpStates,
  newState: IContractRfpStates
) {
  if (newState === IContractRfpStates.planning && currentState === IContractRfpStates.submitted) {
    return true
  }
  if (
    newState === IContractRfpStates.in_progress &&
    currentState === IContractRfpStates.submitted
  ) {
    return true
  }

  return false
}
