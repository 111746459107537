import React from 'react'
import SvgIcon from '../components/SvgIcon'
import { CostAdditiveNegativeSvg } from '../icons'

function CostAdditiveNegativeIcon(props) {
  return (
    <SvgIcon
      className='RogersSvgIcon-CostAdditiveNegative'
      component={CostAdditiveNegativeSvg}
      {...props}
    />
  )
}

export default CostAdditiveNegativeIcon
