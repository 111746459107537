import React from 'react'
import { Box, Typography } from 'dpl/core'
import { brandColors } from 'dpl/theme/colors'
import { IStopSchema } from './laneInformationFormSchema/laneInformationFormSchema'

interface IStopDragPreviewProps {
  location: IStopSchema['location'] | undefined
  label: string
}

export function StopDragPreview({ label, location }: IStopDragPreviewProps) {
  const { city, postalCode, stateCode } = location || {}
  return (
    <Box paddingX={1} paddingY={0.5} borderRadius='2px' bgcolor={brandColors.white}>
      <Typography variant='button'>
        {label}
        {location && `: ${city}, ${stateCode}`}
        {postalCode && ` (${postalCode})`}
      </Typography>
    </Box>
  )
}
