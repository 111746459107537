import React from 'react'
import { IOrganizationShipper } from '#types/graphqlTypes'
import { GridActionsCellItem, GridActionsColDef } from 'dpl/core'
import { ArchiveIcon, EditIcon, RefreshIcon } from 'dpl/icons'

const ACTION_LABEL_REACTIVATE = 'Reactivate'
const ACTION_LABEL_DEACTIVATE = 'Deactivate'

interface IGetActionsUtilArgs {
  onEditClick: (row: IOrganizationShipper) => void
  onDeactivateClick: (row: IOrganizationShipper) => void
  onReactivateClick: (row: IOrganizationShipper) => void
}

const getActionsUtil = ({
  onDeactivateClick,
  onEditClick,
  onReactivateClick,
}: IGetActionsUtilArgs): GridActionsColDef['getActions'] =>
  function getActions({ id, row }) {
    const { deprecatedAt } = row || {}

    const editClickHandler = () => onEditClick(row)

    const deactivateClickHandler = () => {
      onDeactivateClick(row)
    }

    const reactivateClickHandler = () => {
      onReactivateClick(row)
    }

    const isActive = !deprecatedAt

    if (isActive) {
      return [
        <GridActionsCellItem
          key={`${id}-edit`}
          icon={<EditIcon size='large' color='coolGray8' />}
          label='Edit'
          onClick={editClickHandler}
          showInMenu
          data-test='customer-edit-button'
        />,
        <GridActionsCellItem
          key={`${id}-trigger-modal`}
          icon={<ArchiveIcon size='large' color='coolGray8' />}
          label={ACTION_LABEL_DEACTIVATE}
          onClick={deactivateClickHandler}
          showInMenu
        />,
      ]
    }
    return [
      <GridActionsCellItem
        key={`${id}-trigger-modal`}
        icon={<RefreshIcon size='large' color='coolGray8' />}
        label={ACTION_LABEL_REACTIVATE}
        onClick={reactivateClickHandler}
        showInMenu
      />,
    ]
  }

export const getActionColumnDef = ({
  onDeactivateClick,
  onEditClick,
  onReactivateClick,
}: IGetActionsUtilArgs): GridActionsColDef => {
  return {
    field: 'actions',
    type: 'actions',
    width: 50,
    getActions: getActionsUtil({
      onEditClick,
      onDeactivateClick,
      onReactivateClick,
    }),
  }
}
