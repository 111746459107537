import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  DropTargetEventPayloadMap,
  DropTargetGetFeedbackArgs,
  ElementDragType,
  EventPayloadMap,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { useDraggableListContext } from './DraggableListContext'
import { DropIndicator } from './DropIndicator'
import { ListItem } from './ListItem'

type TItemStateType = 'idle' | 'preview' | 'is-dragging' | 'is-dragging-over'
type TItemState = {
  type: TItemStateType
  closestEdge?: Edge | null
}
const idle: TItemState = { type: 'idle' }
const draggingState: TItemState = { type: 'is-dragging' }

export type TDraggableListItem<T> = T & {
  id: string
}
export interface IDraggableListItemProps<T> {
  /**
   * Disables draggable
   * @default false
   */
  isDragDisabled?: boolean
  /**
   * ClassName for ListItem
   * @optional
   */
  className?: string
  /**
   * Item data
   */
  item: TDraggableListItem<T>
  /**
   * Preview shown when item is being dragged
   * If not provided, then the ListItem is shown
   * @optional
   */
  DraggableListItemPreview?: ReactElement
  /**
   * Index of item in list
   */
  index: number
  dataTest?: string
}

function attachDraggableDataToItem<T>({
  index,
  instanceId,
  item,
}: {
  item: T
  index: number
  instanceId: symbol
}) {
  return {
    ...item,
    index,
    instanceId,
  }
}

export function DraggableListItem<T>({
  DraggableListItemPreview,
  children,
  className,
  dataTest = 'DraggableListItem',
  index,
  isDragDisabled = false,
  item,
}: PropsWithChildren<IDraggableListItemProps<T>>) {
  const ref = useRef<HTMLDivElement | null>(null)
  const { instanceId } = useDraggableListContext()
  const [state, setState] = useState<TItemState>(idle)
  const [previewContainer, setPreviewContainer] = useState<{
    container: HTMLElement
    rect: DOMRect
  }>()

  useEffect(() => {
    const element = ref.current
    if (!element || isDragDisabled) return () => {}
    return combine(
      draggable({
        element,
        getInitialData() {
          return attachDraggableDataToItem({ index, instanceId, item })
        },
        onGenerateDragPreview({
          nativeSetDragImage,
          source,
        }: EventPayloadMap<ElementDragType>['onGenerateDragPreview']) {
          const rect = source.element.getBoundingClientRect()
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: '16px',
              y: '8px',
            }),
            render({ container }) {
              setPreviewContainer({ container, rect })
              setState({ type: 'preview' })
              return () => setState(draggingState)
            },
          })
        },
        onDragStart() {
          setState(draggingState)
        },
        onDrop() {
          setState(idle)
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }: DropTargetGetFeedbackArgs<ElementDragType>) {
          // Don't allow dropping element onto a list that is not part of this instance
          if (source.data.instanceId !== instanceId) return false
          // Don't allow dropping element on itself
          if (source.element === element) {
            return false
          }
          return true
        },
        getData({ input }: DropTargetGetFeedbackArgs<ElementDragType>) {
          return attachClosestEdge(item, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          })
        },
        getIsSticky() {
          return true
        },
        onDragEnter({ self }: DropTargetEventPayloadMap<ElementDragType>['onDragEnter']) {
          const closestEdge = extractClosestEdge(self.data)
          setState({ type: 'is-dragging-over', closestEdge })
        },
        onDrag({ self }: DropTargetEventPayloadMap<ElementDragType>['onDrag']) {
          const closestEdge = extractClosestEdge(self.data)
          // Prevents re-rendering and updating react state if nothing has changed.
          setState(current => {
            if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
              return current
            }
            return { type: 'is-dragging-over', closestEdge }
          })
        },
        onDragLeave() {
          setState(idle)
        },
        onDrop() {
          setState(idle)
        },
      })
    )
  }, [index, instanceId, isDragDisabled, item])

  const DragPreview = DraggableListItemPreview ?? (
    <div
      style={{
        boxSizing: 'border-box',
        width: previewContainer?.rect.width,
        height: previewContainer?.rect.height,
      }}>
      <ListItem itemId={item.id} index={index}>
        {children}
      </ListItem>
    </div>
  )

  return (
    <>
      <div style={{ position: 'relative' }} data-test={`${dataTest}-${index}`}>
        <ListItem
          index={index}
          itemId={item.id}
          ref={ref}
          isDragging={state.type === 'is-dragging'}
          className={className}
          isDisabled={isDragDisabled}>
          {children}
        </ListItem>
        {state.type === 'is-dragging-over' && state.closestEdge ? (
          <DropIndicator edge={state.closestEdge} />
        ) : null}
      </div>
      {state.type === 'preview' && previewContainer?.container
        ? createPortal(DragPreview, previewContainer.container)
        : null}
    </>
  )
}
